import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { generateUUID } from "../../components/Functions";
import api from "../../services/api";
import * as UserActions from "../../redux/actions/user";
import { ContentDelimiter, Flex, isMobile } from "./../../helpers/styles";
import Header from "./../../components/Header";
import Footer from "./../../components/Footer";
import TextInput from "../../components/Inputs/TextInput";
import Button from "../../components/Button";
import CheckBox from "../../components/Inputs/CheckBox";
import AlertModal from "../../components/AlertModal";
import SectionTitle from "../../components/SectionTitle";
import HeroImage from "../../assets/images/signup-hero.png";
import EyeIcon from "../../assets/images/eye-icon.png";
import EyeIconCut from "../../assets/images/eye-icon-cut.png";
import { isValidCPF, openPrivacyNTerms, validatePassword } from "../../helpers";
import PhoneVerificationModal from "../../components/PhoneVerificationModal";

const Signup = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [modal, setModal] = useState(undefined);
    const [phoneVerificationModal, setPhoneVerificationModal] = useState(undefined);

    const handleChange = (e) => {
        let {
            target: { name, value, checked, type },
        } = e;

        let newValues = { ...values };

        if (name !== undefined) {
            if (value !== undefined && type !== "checkbox") {
                if (["email", "password", "confirm_password"].includes(name) && value) {
                    value = value.trim();
                }

                newValues[name] = value;
            } else if (type === "checkbox") {
                newValues[name] = checked;
            }
        }

        setValues((prevValues) => {
            return {
                ...prevValues,
                ...newValues,
            };
        });

        canChangeStep(false, true, newValues);
    };

    const canChangeStep = (scroll = false, ignoreUndefined = false, checkValues = values) => {
        let canChange = true;

        let newErrors = { ...errors };

        const firstStepRequireds = [
            "first_name",
            "last_name",
            "cpf",
            "phone_number",
            "email",
            "password",
            "confirm_password",
        ];

        firstStepRequireds.forEach((requiredField) => {
            if (
                (!Object.keys(checkValues).includes(requiredField) && !ignoreUndefined) ||
                checkValues[requiredField] === ""
            ) {
                newErrors[requiredField] = true;
            } else if (!newErrors[requiredField] || checkValues[requiredField] !== undefined) {
                newErrors[requiredField] = false;
            }
        });

        canChange = firstStepRequireds.every((requiredField) => {
            const invalidField = document.querySelector(`[name=${requiredField}]`);

            if (invalidField && scroll) {
                invalidField.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }

            return (
                Object.keys(checkValues).includes(requiredField) &&
                checkValues[requiredField] !== undefined &&
                checkValues[requiredField] !== ""
            );
        });

        if (
            !checkValues.password ||
            !checkValues.confirm_password ||
            checkValues.password !== checkValues.confirm_password ||
            !validatePassword(checkValues.password)
        ) {
            canChange = false;
        }

        const birth_date = values.birth_date && moment(values.birth_date, "dd/mm/yyyy");
        const user_age = birth_date && moment().diff(birth_date, "years");

        if (
            !values.birth_date ||
            values.birth_date.length < 10 ||
            !birth_date ||
            !birth_date.isValid() ||
            user_age < 5 ||
            user_age > 150
        ) {
            newErrors.birth_date = true;

            canChange = false;

            const invalidField = document.querySelector(`[name=birth_date]`);

            if (invalidField && scroll) {
                invalidField.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            } else newErrors.birth_date = false;
        }

        setErrors({ ...newErrors });

        return canChange;
    };

    const handleSignup = async () => {
        if (!canChangeStep(true)) return;

        let newErrors = {
            ...errors,
            first_name: !values.first_name,
            email: !values.email,
            last_name: !values.last_name,
            phone_number:
                !values.phone_number ||
                values.phone_number.replace(/_/g, "").replace(/-/g, "").replace(/\(/g, "").replace(/\)/g, "").length <
                10,
            birth_date: !values.birth_date,
            cpf: !isValidCPF(values.cpf),
        };

        setErrors((prevErrors) => {
            return {
                ...prevErrors,
                ...newErrors,
            };
        });

        if (Object.values(newErrors).find((item) => item === true) !== undefined) {
            return;
        }

        setLoading(true);

        let signupResponse = await api
            .post("/profiles", {
                ...values,
                phone_country_code: values.phone_country_code || "55",
                phone_code_area:
                    values.phone_number && values.phone_number.length > 0 && values.phone_number[0] === "("
                        ? values.phone_number.match(/^\((\d{2})\)/)[1]
                        : values.phone_number.substring(0, 2),
                phone_number:
                    values.phone_number && values.phone_number[0] === "("
                        ? values.phone_number.substring(5).replace(/\s/g, "").replace(/_/g, "").replace(/-/g, "")
                        : values.phone_number.substring(2),
                source: "sign_up",
            })
            .catch((err) => {
                setLoading(false);
                return err.response;
            });

        setLoading(false);

        if (signupResponse.status === 200) {
            // eslint-disable-next-line
            dataLayer.push({ ecommerce: null });

            // eslint-disable-next-line
            dataLayer.push({ event: "lead-complete-registration" });

            // eslint-disable-next-line
            let userSession = window.userCurrent(user);
            let userSessionServerData = window.userCurrentServerData(user);
            let event_id = generateUUID();
            let event_time = Math.round(Date.now() / 1000);

            window.fbq("track", "CompleteRegistration", { registration_source: "cadastro" }, { eventID: event_id });

            await api
                .post("/facebook_ads_event", {
                    data: [
                        {
                            event_name: "CompleteRegistration",
                            event_id: event_id,
                            event_time: event_time,
                            event_source_url: window.location.href,
                            action_source: "website",
                            user_data: userSessionServerData,
                            custom_data: {
                                registration_source: "cadastro",
                            },
                        },
                    ],
                })
                .then((res) => console.log("EVENTO Subscribe", res.data))
                .catch((res) => res.response);

            event_id = generateUUID();
            let dados = [
                {
                    event_name: "Lead",
                    event_time: Math.round(Date.now() / 1000),
                    event_id: event_id,
                    event_source_url: window.location.href,
                    action_source: "website",
                    user_data: userSession,
                    custom_data: {},
                },
            ];

            let fbHashedEvent = { ...dados[0] };
            fbHashedEvent.user_data = { ...userSession };

            window.fbq("track", "Lead", fbHashedEvent, {
                eventID: event_id,
                user_data: { ...userSession },
            });
            api.post("/facebook_ads_event", {
                data: dados,
            })
                .then((res) => console.log("EVENTO Subscribe", res.data))
                .catch((res) => res.response);

            // eslint-disable-next-line
            dataLayer.push({ ecommerce: null });
            // eslint-disable-next-line
            dataLayer.push({
                event: "virtualPageview",
                page: document.title,
                type: "SellerSignUp",
                environment: process.env.REACT_APP_ENV,
                user: [
                    {
                        name: values.first_name + " " + values.last_name,
                        username: user.username,
                        user_id: user.id,
                        email: values.email,
                        language: "pt-br",
                        returning: JSON.parse(localStorage.getItem("accepted_cookies")) ? true : false,
                    },
                ],
            });

            dispatch(
                UserActions.login({
                    token: signupResponse.headers.token,
                    ...signupResponse.data,
                })
            );
            dispatch(UserActions.refreshProfile());

            if (signupResponse.data.needs_phone_verification === true) {
                setPhoneVerificationModal({
                    show: true,
                    phone: values.phone_number,
                });
            }
        } else {
            console.error(signupResponse);

            setModal({
                show: true,
                message:
                    signupResponse && signupResponse.data && signupResponse.data.message
                        ? signupResponse.data.message
                        : "Erro inesperado. Aguarde um instante e tente outra vez!",
            });
        }
    };

    return (
        <>
            <Header
                navigationItems={[
                    { label: "Compre", action: () => navigate("/categorias/novidades") },
                    { label: "Quero vender", action: () => navigate("/desapegue") },
                    { label: "Peça seu box", action: () => navigate("/box") },
                    { label: "Lojas Influencers", action: () => navigate("/lojas-influencers") },
                    { label: "Blog", action: () => navigate("/blog") },
                    { label: "Outlet", action: () => navigate("/outlet") },
                    { label: "Sale", action: () => navigate("/promocoes") },
                ]}
            />
            <ContentDelimiter>
                <Flex
                    width={isMobile ? "100%" : "910px"}
                    alignItems="flex-start"
                    margin={isMobile ? "0" : "87px auto 54px auto"}
                    direction={isMobile ? "column" : "row"}
                >
                    <Flex width="100%" mobile>
                        <SectionTitle margin="30px 0">Cadastro por e-mail</SectionTitle>
                    </Flex>

                    <Flex width={isMobile ? "100%" : undefined} margin={isMobile ? "0" : "0px 82px 0px 0px"}>
                        <Image
                            src={HeroImage}
                            width={isMobile ? "auto" : "385px"}
                            height={isMobile ? "320px" : "auto"}
                        />
                    </Flex>

                    <Flex
                        as="form"
                        width={isMobile ? "100%" : "440px"}
                        padding={isMobile ? "0 20px" : undefined}
                        direction="column"
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        onSubmit={handleSignup}
                        boxSizing="border-box"
                    >
                        <Flex direction="column" alignItems="flex-start" justifyContent="flex-start" desktop>
                            <Title>Cadastro por e-mail</Title>
                            <Separator />
                        </Flex>

                        <TextInput
                            placeholder="Nome"
                            margin="0 0 20px 0"
                            name="first_name"
                            value={values.first_name}
                            onChange={handleChange}
                            error={errors.first_name}
                        />

                        <TextInput
                            placeholder="Sobrenome"
                            margin="0 0 20px 0"
                            name="last_name"
                            value={values.last_name}
                            onChange={handleChange}
                            error={errors.last_name}
                        />

                        <TextInput
                            mask="999.999.999-**"
                            placeholder="CPF"
                            margin="0 0 20px 0"
                            name="cpf"
                            value={values.cpf}
                            onChange={handleChange}
                            error={errors.cpf}
                        />

                        <TextInput
                            placeholder="Celular (XX) XXXXX-XXXX"
                            margin="0 0 20px 0"
                            name="phone_number"
                            value={values.phone_number}
                            onChange={(e) => {
                                e.target.value = e.target.value
                                    .replace(/\D+/g, "")
                                    .replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4");
                                handleChange(e);
                            }}
                            error={errors.phone_number}
                        />

                        <TextInput
                            margin="0 0 20px 0"
                            placeholder="Data de nascimento (DD/MM/AAAA)"
                            type="date"
                            name="birth_date"
                            mask="99/99/9999"
                            maskPlaceholder="dd/mm/yyyy"
                            value={values.birth_date}
                            onChange={handleChange}
                            error={errors.birth_date}
                        />

                        <TextInput
                            placeholder="E-mail"
                            margin="0 0 20px 0"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            error={errors.email}
                        />

                        <TextInput
                            type={showPassword ? "text" : "password"}
                            placeholder="Senha"
                            margin="0 0 20px 0"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            error={
                                errors.password ||
                                (values.password && values.password !== "" && !validatePassword(values.password))
                            }
                            append={
                                <EyeButton
                                    onClick={() => setShowPassword(!showPassword)}
                                    src={showPassword ? EyeIconCut : EyeIcon}
                                    alt="Olho - Mostrar senha"
                                />
                            }
                        />

                        <TextInput
                            type={showPassword ? "text" : "password"}
                            placeholder="Confirmar Senha"
                            margin="0"
                            name="confirm_password"
                            value={values.confirm_password}
                            onChange={handleChange}
                            error={
                                errors.confirm_password ||
                                (values.confirm_password &&
                                    values.confirm_password !== "" &&
                                    (!validatePassword(values.password) ||
                                        (values.password !== "" && values.password !== values.confirm_password)))
                            }
                            append={
                                <EyeButton
                                    onClick={() => setShowPassword(!showPassword)}
                                    src={showPassword ? EyeIconCut : EyeIcon}
                                    alt="Olho - Mostrar senha"
                                />
                            }
                        />

                        <PasswordHint>
                            <strong>Fique de olho!</strong>
                            <br />
                            <br />
                            Sua senha deve ter no minímo 6 caracteres, sendo ao menos uma letra maiúscula, um número e
                            um caractere especial.
                        </PasswordHint>

                        <CheckBox
                            margin="0 0 26px 0"
                            name="newsletter"
                            checked={values.newsletter}
                            onChange={handleChange}
                        >
                            <Text>Oba! Quero receber as novidades e promoções Re Petit por e-mail.</Text>
                        </CheckBox>

                        <Text>
                            Ao criar minha conta eu concordo que li e aceito os{" "}
                            <Link onClick={() => openPrivacyNTerms("terms")}>Termos e Condições</Link> e{" "}
                            <Link onClick={() => openPrivacyNTerms("privacy")}>Política de Privacidade</Link>.
                        </Text>

                        <Flex
                            alignItems="center"
                            justifyContent="flex-start"
                            width="100%"
                            direction={isMobile ? "column" : "row"}
                            margin={isMobile ? "0 0 30px 0" : undefined}
                        >
                            <Button
                                width={isMobile ? "100%" : "200px"}
                                dark
                                filled
                                margin="0"
                                value="Criar conta"
                                onClick={handleSignup}
                                disabled={loading}
                            />
                            <Button
                                padding="15px 44px 12px 44px"
                                dark
                                link
                                margin="0"
                                value="Voltar"
                                onClick={() => navigate("/login")}
                                disabled={loading}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </ContentDelimiter>

            <Footer mini />

            {modal && (
                <AlertModal
                    show={modal.show}
                    title={modal.title}
                    message={modal.message}
                    callback={() => setModal({ ...modal, show: false })}
                />
            )}

            {phoneVerificationModal && (
                <PhoneVerificationModal
                    show={phoneVerificationModal.show}
                    phone={phoneVerificationModal.phone}
                    navigateToHome={true}
                    callback={() => setPhoneVerificationModal({ ...phoneVerificationModal, show: false, navigateToHome: true })}
                />
            )}
        </>
    );
};

const Image = styled.img`
    margin: 0 auto;
`;

const Title = styled.h1`
    margin: 0px;
    margin-bottom: 24px;
    text-transform: uppercase;

    font-family: "SackersGothicStd";
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: 4px;
    text-align: left;
    color: #34343b;
`;

const Separator = styled.hr`
    width: 40px;
    height: 3px;
    margin: 0px;
    margin-bottom: 29px;
    background-color: #e4858c;
    border: none;
`;

const Text = styled.p`
    flex: 1;
    margin: 0 0 36px 0;

    font-family: "Graphik";
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    a {
        color: #34343b;
    }
`;

const EyeButton = styled.img`
    cursor: pointer;
`;

const PasswordHint = styled.p`
    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    strong {
        font-weight: 600;
    }
`;

export default Signup;
