import React from "react";

import styled from "styled-components";
import { isMobileMediaQuery } from "../../helpers/styles";
import OutletItem from "../OutletItem";

const ProductsGrid = ({ brands }) => {
    return (
        <Container>
            {brands &&
                brands.map((brand, index) => (
                    <OutletItem
                        key={`brand_${index}`}
                        slug={brand.slug}
                        image={brand.image_url}
                    />
                ))}
        </Container>
    );
};

const Container = styled.div`
    margin-bottom: 40px;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 43px;
    width: 100%;

    ${isMobileMediaQuery} {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 20px;
    }
`;

export default ProductsGrid;