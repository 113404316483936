import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { Flex, isMobile } from "../../helpers/styles";

import { ReactComponent as ChevronDownIcon } from "../../assets/images/chevron-down.svg";
import { ReactComponent as BackButton } from "../../assets/images/bt-voltar.svg";

const ExpandableContainer = ({
    title,
    mobile,
    full,
    value,
    highlight,
    secondary,
    ...props
}) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (value !== isOpen) setIsOpen(value);

        // eslint-disable-next-line
    }, [value]);

    const handleClick = () => {
        props.onChange && props.onChange(!isOpen);
        setIsOpen(!isOpen);
    };

    return mobile && !isMobile ? (
        props.children
    ) : (
        <Container
            isOpen={isOpen}
            full={full}
            padding={props.padding}
            width={props.width}
            background={props.background}
            onClick={props.onClick}
            secondary={secondary}
        >
            <Flex
                alignItems="center"
                justifyContent="space-between"
                onClick={handleClick}
                width="calc(100% - 8px)"
                margin="0 auto"
            >
                <Title highlight={highlight}>{title}</Title>
                {!props.onClick && <ChevronDownIcon />}
            </Flex>
            {isOpen && (
                <HideContainer direction="column" unhide={isOpen}>
                    <SubMenuTitle
                        background="#F7F9F9"
                        padding="12px 20px"
                        width="calc(100% - 40px)"
                    >
                        <BackButton onClick={handleClick} />
                        <h1>{title}</h1>
                    </SubMenuTitle>
                    {props.children}
                </HideContainer>
            )}
        </Container>
    );
};

const Container = styled.div`
    ${({ width }) => `width: ${width || "100%"};`};
    ${({ padding }) => padding && `padding: ${padding};`};

    /* &:not(:last-of-type) > ${Flex}:first-child {
        border-bottom: 1px solid #e6e7e9;
    } */

    & > ${Flex} > svg {
        transform: rotateZ(-90deg);
    }

    ${(props) =>
        props.full &&
        `
            width: calc(100% + 8px);
            margin-left: -20px;
            padding: 0px 16px;
    `};

    &:hover > ${Flex}:first-of-type > h1,
    &:active > ${Flex}:first-of-type > h1 {
        color: #f37f8a;
    }

    ${({ secondary }) =>
        secondary &&
        css`
            background-color: #7ca8af;
            & > ${Flex}:first-of-type > h1 {
                color: #fff;
            }
        `}
`;

const Title = styled.h1`
    font-family: "SackersGothicStd";
    font-size: 12px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: 2.4px;
    text-align: left;
    color: #676778;
    max-width: 95%
        ${({ highlight }) =>
            highlight &&
            css`
                color: #f37f8a;
            `};
`;

const HideContainer = styled(Flex)`
    transition: 0.35s all ease-in-out;
    position: absolute;
    width: 100vw;
    top: 0px;
    left: 0%;

    ${(props) =>
        props.unhide &&
        `
        top: 0px;
        left: 100%;
    `}
`;

const SubMenuTitle = styled(Flex)`
    & > h1 {
        margin: 0;
        margin-left: auto;

        font-family: "SackersGothicStd";
        font-size: 16px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.25;
        letter-spacing: 3.2px;
        text-align: right;
        color: #676778;
    }
`;

export default ExpandableContainer;
