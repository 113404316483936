import styled, { createGlobalStyle } from "styled-components";
import { MainFontFace } from "./fonts";

export const colors = {};

export const breakpoints = {
    xs: 576,
    mobile: 760,
    sm: 768,
    md: 1024,
    lg: 1200,
    xl: 1400,
};

export const sizes = {
    header: 74,
};

export var isMobile = window.innerWidth <= breakpoints.mobile;

export const isMobileMediaQuery = `@media screen and (max-width: ${breakpoints.mobile}px)`;
export const notMobileMediaQuery = `@media screen and (min-width: ${breakpoints.mobile + 1}px)`;

export const GlobalStyle = createGlobalStyle`
    ${MainFontFace}

    *, *:active, *:focus {
        outline: none !important;
    }

    #root {
        width: 100%;
        min-width: 100vw;
    }
    
    .app {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        margin-top: 0px;
    }
    
    body {        
        font-family: 'Graphik', sans-serif;
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 0px;
        padding: 0px;
        font-size: 16px;
        max-width: 100vw;
        overflow-x: hidden;
        min-height: 100vh;
        background-color: #fff;

        ${notMobileMediaQuery} {
            min-width: 1222px;
            @media screen and (max-width: 1300px) {
                overflow-x: auto;
            }
        }
    }

    h1,h2,h3,h4,h5,h6 {
        font-family: 'DMSerifDisplay', sans-serif;
    }
`;

export const ContentDelimiter = styled.div`
    width: 100%;
    margin: auto;
    max-width: 1222px;
    position: relative;
`;

export const Flex = styled.div`
    position: relative;
    display: ${({ display }) => (display ? display : "flex")};
    flex-wrap: ${({ wrap }) => (wrap ? wrap : "wrap")};
    flex-grow: ${({ grow }) => (grow ? grow : "initial")};
    ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf};`};
    align-items: ${({ alignItems }) => (alignItems ? alignItems : "center")};
    justify-content: ${({ justifyContent }) => (justifyContent ? justifyContent : "center")};
    ${({ background }) => background && `background: ${background}`};
    ${({ backgroundColor }) => backgroundColor && `background-color: ${backgroundColor}`};
    ${({ border }) => border && `border: ${border}`};
    ${({ boxShadow }) => boxShadow && `box-shadow: ${boxShadow}`};
    ${({ margin }) => margin && `margin: ${margin}`};
    ${({ padding }) => padding && `padding: ${padding}`};
    ${({ width }) => width && `width: ${width}`};
    ${({ height }) => height && `height: ${height}`};
    ${({ minWidth }) => minWidth && `min-width: ${minWidth}`};
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
    ${({ minHeight }) => minHeight && `min-height: ${minHeight}`};
    ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}`};
    ${({ position }) => position && `position: ${position}`};
    ${({ zIndex }) => zIndex && `z-index: ${zIndex}`};
    ${({ boxSizing }) => boxSizing && `box-sizing: ${boxSizing}`};
    ${({ flex }) => flex && `flex: ${flex}`};
    ${({ direction }) => direction && `flex-direction: ${direction}`};
    ${({ order }) => order && `order: ${order}`};
    ${({ overflow }) => overflow && `overflow: ${overflow}`};
    ${({ columnGap }) => columnGap && `column-gap: ${columnGap};`};
    ${({ rowGap }) => rowGap && `row-gap: ${rowGap};`};
    ${({ transition }) => transition && `transition: ${transition}`};
    ${({ textDecoration }) => textDecoration && `text-decoration: ${textDecoration}`};
    ${({ onClick }) => onClick && "cursor: pointer"};
    ${({ cursor }) => cursor && `cursor: ${cursor}`};

    ${(props) =>
        props.alignLast &&
        `
        &::after {
            content: "";
            flex: auto;
        }
    `}

    ${(props) =>
        props.desktop &&
        `
        ${isMobileMediaQuery} {
            display:none !important;
        }
    `}

    ${(props) =>
        props.mobile &&
        `
        ${notMobileMediaQuery} {
            display:none !important;
        }
    `}
`;

export const UnderlineTitle = styled.h4`
    font-family: SackersGothicStd;
    font-size: 32px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-transform: uppercase;
    color: ${({ color }) => color && `color: ${color}`};
`;

export const BannerWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px`};
`;
