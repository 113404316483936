import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Flex, isMobile, isMobileMediaQuery } from "./../../helpers/styles";
import api from "../../services/api";
import Header from "./../../components/Header";
import Footer from "../../components/Footer";
import InfluencersGrid from "../../components/InfluencersGrid";
import Loading from "../../components/Loading";

const Influencers = () => {
    const [influencersData, setInfluencers] = useState([]);
    const getInfluencers = async () => {
        try {
            const response = await api.get("/stores");
            setInfluencers(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getInfluencers();
    }, []);

    const navigate = useNavigate();

    const handleInfluencerStore = (influencer_slug) => {
        navigate(`/loja/${influencer_slug}`);
    };

    return (
        <>
            <Header
                navigationItems={[
                    { label: "Compre", action: () => navigate("/categorias/novidades") },
                    { label: "Quero vender", action: () => navigate("/desapegue") },
                    { label: "Peça seu box", action: () => navigate("/box") },
                    { label: "Lojas Influencers", action: () => navigate("/lojas-influencers") },
                    { label: "Blog", action: () => navigate("/blog") },
                    { label: "Outlet", action: () => navigate("/outlet") },
                    { label: "Sale", action: () => navigate("/promocoes") },
                ]}
            />

            <Helmet>
                <title>Re Petit: Lojas Influencers</title>
                <meta property="og:url" content={window.location.href} />
            </Helmet>

            <Flex desktop minWidth="1222px" background="#fff">
                <Flex
                    width="1222px"
                    minHeight="230px"
                    direction="column"
                    padding="32px"
                    margin="auto 0 auto 0"
                    alignItems="flex-start"
                    background="url(https://images.repetit.com.br/home_produtos/dest_908x230_meninas.jpg) center / cover no-repeat"
                    boxSizing="border-box"
                >
                    <Flex
                        alignItems="flex-start"
                        direction="column"
                        background="#fff"
                        padding="32px 24px"
                        minWidth="30%"
                        maxWidth="50%"
                    >
                        <HeaderTitle>Lojas Influencers</HeaderTitle>

                        <HeaderText>
                            Descubra as famílias especiais que circulam com a gente. Juntamos um time de influencers que
                            embarcaram com a Re Petit no consumo consciente. Aproveite a oportunidade comprar peças
                            únicas em excelente estado. E não esqueça que todo mundo pode ter e divulgar sua loja de
                            desapegos na Re Petit.
                        </HeaderText>
                    </Flex>
                </Flex>
            </Flex>

            <Flex alignItems="flex-start" direction="column" mobile background="#F7F9F9" padding="16px 0px">
                <Flex width="100%" direction="column" margin="auto auto 16px auto" alignItems="center">
                    <HeaderTitle>Lojas Influencers</HeaderTitle>
                </Flex>

                <img
                    width="100%"
                    height="auto"
                    src="https://images.repetit.com.br/home_produtos/dest_908x230_meninas.jpg"
                    alt="Criança feliz correndo com braços abertos"
                />

                <Flex
                    width="calc(100% - 80px)"
                    direction="column"
                    margin="30px auto 28px auto"
                    alignItems="center"
                    padding="0px 40px"
                >
                    <HeaderText>
                        Descubra as famílias especiais que circulam com a gente. Juntamos um time de influencers que
                        embarcaram com a Re Petit no consumo consciente. Aproveite a oportunidade comprar peças únicas
                        em excelente estado. E não esqueça que todo mundo pode ter e divulgar sua loja de desapegos na
                        Re Petit.
                    </HeaderText>
                </Flex>
            </Flex>

            <Flex
                direction="column"
                width={isMobile ? "100%" : "1220px"}
                margin={isMobile ? "10px auto 0px auto" : "40px auto 0px auto"}
            >
                <Flex width="100%" alignItems={isMobile && "flex-start"} direction={isMobile && "column"}>
                    {!influencersData ? (
                        <Loading />
                    ) : (
                        <InfluencersGrid influencers={influencersData} handleClick={handleInfluencerStore} />
                    )}
                </Flex>
            </Flex>

            <Footer />
        </>
    );
};

const HeaderTitle = styled.h1`
    margin: 0px;
    margin-bottom: 28px;

    font-family: "DMSerifDisplay";
    font-size: 38px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.95;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    ${isMobileMediaQuery} {
        font-size: 32px;
        text-align: center;
        margin: 0px;
    }

    :only-child {
        margin: 0px;
    }
`;

const HeaderText = styled.p`
    margin: 0px;

    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    ${isMobileMediaQuery} {
        font-size: 15px;
        text-align: center;
    }
`;

export default Influencers;
