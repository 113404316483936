import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { Flex, isMobile } from "../../helpers/styles";

import { ReactComponent as ChevronDownIcon } from "../../assets/images/chevron-down.svg";

const ExpandableContainer = ({
    title,
    mobile,
    full,
    value,
    bodyPadding,
    ...props
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [borderBottom, setBorderBottom] = useState(false);

    useEffect(() => {
        if (value !== isOpen) setIsOpen(value);
        if (props.borderBottom !== borderBottom) setBorderBottom(true);
        // eslint-disable-next-line
    }, [value]);

    const handleClick = () => {
        props.onChange && props.onChange(!isOpen);
        setIsOpen(!isOpen);
    };

    return mobile && !isMobile ? (
        props.children
    ) : (
        <Container
            isOpen={isOpen}
            borderBottom={borderBottom}
            full={full}
            margin={props.margin}
            padding={props.padding}
            width={props.width}
            borderBox={props.borderBox}
            background={props.background}
            activeBackground={props.activeBackground}
            boxSizing={props.boxSizing}
        >
            <Flex
                alignItems="center"
                justifyContent={
                    props.alignTitle ? props.alignTitle : "space-between"
                }
                onClick={handleClick}
                width="calc(100% - 20px)"
                margin="0 auto"
                padding={props.titlePadding}
                boxSizing={props.titleBoxSizing}
            >
                <Title>{title}</Title>
                <ChevronDownIcon />
            </Flex>
            <CollapseArea
                maxHeight={props.maxHeight}
                bodyPadding={bodyPadding}
                overFlowY={props.overFlowY}
                height={props.height}
                marginBottom={props.marginBottom}
                isOpen={isOpen}
            >
                {props.children}
            </CollapseArea>
        </Container>
    );
};

export default ExpandableContainer;

const Container = styled.div`
    ${({ width }) => `width: ${width || "100%"};`};
    background-color: ${(props) =>
        props.isOpen
            ? props.activeBackground
                ? props.activeBackground
                : ""
            : props.background
            ? props.background
            : "#f7f9f9"};
    transition: 0.25s background-color ease-in-out;
    ${({ margin }) => margin && `margin: ${margin};`};
    ${({ padding }) => padding && `padding: ${padding};`};
    ${({ borderBox }) => borderBox && `box-sizing: border-box;`};

    ${(props) =>
        props.borderBottom &&
        `
        &:not(:last-of-type) > ${Flex}:first-child {
            border-bottom: 1px solid #e6e7e9;
        }
    `};

    ${(props) =>
        props.isOpen &&
        `
            & > div > svg:first-of-type {
                transform: scaleY(-1);
            }
    `};

    ${(props) =>
        props.full &&
        `
            width: calc(100% + 8px);
            margin-left: -20px;
            padding: 0px 16px;
    `};
`;

const Title = styled.h1`
    font-family: "SackersGothicStd";
    font-size: 12px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: 2.4px;
    text-align: left;
    color: #676778;
`;

const CollapseArea = styled.div`
    max-height: 0px;
    transition: 0.25s all ease-in-out;
    overflow: hidden;
    padding: 0px 5px;

    ${(props) =>
        props.isOpen &&
        css`
            padding: ${props.bodyPadding ? props.bodyPadding : ""};
            max-height: ${props.maxHeight ? props.maxHeight : "500px"};
            margin-bottom: ${props.marginBottom ? props.marginBottom : "0px"};
            overflow-y: ${props.overFlowY ? props.overFlowY : "hidden"};
            height: ${props.height ? props.height : ""};
        `}
`;