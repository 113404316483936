import React from "react";
import styled, { css } from "styled-components";

// import { Container } from './styles';

function TextArea({
    type,
    name,
    id,
    value,
    append,
    placeholder,
    onChange,
    rows,
    cols,
    error,
    errorMessage,
    ...props
}) {
    return (
        <Container error={error} errorMessage={errorMessage} {...props}>
            <textarea
                type={type ? type : "text"}
                name={name}
                id={id}
                placeholder={placeholder}
                onChange={onChange}
                rows={rows}
                cols={cols}
            >
                {props.children || value}
            </textarea>
            {append && <Append>{append}</Append>}
        </Container>
    );
}

const Container = styled.div`
    position: relative;
    display: flex;
    background-color: #fff;
    border: 1px solid #cacad1;
    height: auto;
    padding: 13px 17px;
    box-sizing: border-box;
    border-radius: 16px;
    width: ${(props) => (props.width ? props.width : "100%")};
    ${({ flex }) => flex && `flex: ${flex};`};
    ${({ margin }) => margin && `margin: ${margin};`};
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`};

    ${({ outlined }) => outlined && `background-color: transparent;`};

    ${({ error, errorMessage }) =>
        (error || errorMessage) &&
        css`
            border-color: red !important;
        `};

    ${({ bottomLined }) =>
        bottomLined &&
        css`
            background-color: transparent;
            border: 0;
            border-bottom: 1px solid black;
        `};

    & > textarea {
        min-width: 100%;
        min-height: 20px;
        height: 100%;
        border: 0px;

        font-family: "Graphik";
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.75px;
        text-align: left;
        color: #676777;
        background-color: transparent;
       
        ::placeholder {
            color: #676777;
            opacity: 0.7;
        }
    }

    ${({ special }) =>
        special &&
        css`
        height: 38px;
        padding-top: 0px;
        padding-bottom: 0px;
        box-sizing: content-box;

        & > textarea {
            text-transform: capitalize;
            font-family: "DMSerifDisplay";
            font-size: 28px;
            font-weight: normal;
            font-stretch: normal;
            font-style: italic;
            line-height: 0.75;
            letter-spacing: normal;
            text-align: center;
            color: #000;

            ::placeholder {
                color: #cacad0;
                text-transform: initial;
            }
            
        `};
`;

const Append = styled.div`
    position: absolute;
    right: 18px;
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default TextArea;
