/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import { useSelector } from "react-redux";
import api from "../../services/api";
import { Flex, isMobile } from "../../helpers/styles";
import Button from "../../components/Button";
import SectionTitle from "../../components/SectionTitle";
import TextInput from "../../components/Inputs/TextInput";
import LoadingSpinner from "../../components/LoadingSpinner";
import SelectInput from "../../components/Inputs/SelectInput";
import ModalSuccess from "../../assets/images/modal-success-hero.jpg";
import PromoModal from "../../components/PromoModal";
import checkIcon from "./../../assets/images/icon-check.png";
import scheduleIcon from "./../../assets/images/icon-schedule.png";
import ReactTooltip from "react-tooltip";

const MeusCreditos = ({ modal, setModal, item_id, history, ...props }) => {
    const user = useSelector((state) => state.user);
    const [pageState, setPageState] = useState(undefined);
    const [sendingWithdrawRequest, setSendingWithdrawRequest] = useState(false);
    const [isLoadingPixRegistration, setIsLoadingPixRegistration] = useState(false);
    const [statementYear, setStatementYear] = useState(moment().format("Y"));
    const [statementMonth, setStatementMonth] = useState(moment().format("M"));
    const [loadingStatement, setLoadingStatement] = useState(false);
    const [transactions, setTransactions] = useState(null);
    const [errors, setErrors] = useState({});
    const [showPromoModal, setShowPromoModal] = useState(false);
    const [items, setItems] = useState(undefined);
    const [values, setValues] = useState({
        status: undefined,
        donate_unfit_items: undefined,
    });

    useEffect(() => {
        setPageState("home");

        // eslint-disable-next-line
    }, [history.pathname, item_id]);

    useEffect(() => {
        window.scrollTo({ top: 0 });

        // eslint-disable-next-line
    }, [pageState]);

    useEffect(() => {
        loadStatement();

        // eslint-disable-next-line
    }, [statementMonth, statementYear]);

    const handleChange = async (e) => {
        const {
            target: { name, value, checked, type },
        } = e;

        let newValues = { ...values };

        if (name !== undefined) {
            if (value !== undefined && (type !== "checkbox" || type === "radio")) {
                newValues = {
                    ...newValues,
                    [name]: value,
                };
            } else if (checked !== undefined) {
                newValues = {
                    ...newValues,
                    [name]: checked,
                };
            }
        }

        if (name === "withdraw_value") {
            newValues[name] = value.replace(/(?:(?!,|[0-9]).)+/g, "");

            const withdrawValue = parseFloat(newValues.withdraw_value.replace(",", "."));
            const minimumAmount = 5;
            const availableAmount = user.balance || 0;
            const withdraw_tax = user.withdraw_tax || 0;

            if (withdrawValue < minimumAmount) {
                setErrors((currState) => ({
                    ...currState,
                    withdraw_value: `O valor mínimo para saque é de R$ ${minimumAmount}`,
                }));
            } else if (withdrawValue > availableAmount || (withdrawValue > availableAmount - 3 && withdraw_tax > 0)) {
                setErrors((currState) => ({
                    ...currState,
                    withdraw_value: `Você precisa digitar um valor menor ou igual a R$ ${availableAmount - withdraw_tax
                        }`,
                }));
            } else {
                setErrors((currState) => ({
                    ...currState,
                    withdraw_value: false,
                }));
            }
        }

        if (name === "pix_type") {
            newValues["pix_key"] = "";
        }

        if (name === "pix_key") {
            if (!values["pix_type"]) setErrors((err) => ({ ...err, pix_key: true }));
            else setErrors((err) => ({ ...err, pix_key: false }));
        }

        setValues((prevState) => ({ ...prevState, ...newValues }));
    };

    const loadItems = async () => {
        setItems(null);

        const response = await api.get(`/profiles/entries/items`).catch((err) => err.response);

        if (response.status === 200) {
            setItems({
                ...response.data,
            });
        } else {
            setItems({
                items: [],
                pagination: {},
            });
        }
    };

    const loadStatement = async () => {
        if (!statementMonth || statementMonth < 1 || statementMonth > 12 || !statementYear) {
            return;
        }

        setLoadingStatement(true);

        const response = await api
            .get(`/profiles/wallet/report/${statementYear}/${statementMonth}`)
            .catch((err) => err.response);

        setLoadingStatement(false);

        if (response.status === 200 && response.data) {
            setTransactions(response.data.report || []);
        } else {
            setTransactions([]);
        }
    };

    const handleSubmitPixRegistration = async () => {
        setIsLoadingPixRegistration(true);
        const user_pix_address = {
            pix_address_key_kind: values.pix_type,
            pix_address_key:
                values.pix_type !== "email" && values.pix_type !== "evp"
                    ? values.pix_key.replace(/[\/\-. ()]/g, "")
                    : values.pix_key,
        };

        const response = await api
            .put("/profiles/me", { ...user, user_pix_address: { ...user_pix_address } })
            .catch((err) => err.response);

        if (response.status === 200) {
            setModal({
                show: true,
                image: ModalSuccess,
                title: "Yeah!",
                message: "Chave PIX cadastrada com sucesso!",
                callback: () => {
                    window.location.reload();
                    setModal({ ...modal, show: false });
                },
            });
        } else {
            setModal({
                show: true,
                message:
                    response && response.data && response.data.message
                        ? response.data.message
                        : "Erro inesperado. Aguarde um instante e tente outra vez!",
            });
        }
        setIsLoadingPixRegistration(false);
    };

    const handleSendWithdrawRequest = async () => {
        setSendingWithdrawRequest(true);

        const withdrawPayload = {
            amount: parseFloat(values.withdraw_value.replace(/\./g, "").replace(",", ".")),
        };

        const widthdraw_response = await api
            .post("/profiles/withdraws/pix", withdrawPayload)
            .catch((err) => err.response);

        setSendingWithdrawRequest(false);

        if (widthdraw_response.status === 200) {
            setModal({
                show: true,
                image: ModalSuccess,
                title: "Yeah!",
                message: "Solicitação de saque enviada com sucesso!",
                callback: () => {
                    setModal({ ...modal, show: false });
                    window.location.reload();
                },
            });
        } else {
            console.error(widthdraw_response);

            setModal({
                show: true,
                message:
                    widthdraw_response && widthdraw_response.data && widthdraw_response.data.message
                        ? widthdraw_response.data.message
                        : "Erro inesperado. Aguarde um instante e tente outra vez!",
            });
        }
    };

    const handlePixKeyMask = (pix_type) => {
        switch (pix_type) {
            case "cpf":
                return "999.999.999-99";
            case "cnpj":
                return "99.999.999/9999-99";
            case "phone":
                return "(99) 9 9999-9999";
            default:
                return undefined;
        }
    };

    const handlePixTypeDescription = (pix_type) => {
        switch (pix_type) {
            case "cpf":
                return "CPF";
            case "cnpj":
                return "CNPJ";
            case "phone":
                return "Telefone";
            case "email":
                return "E-mail";
            case "evp":
                return "Chave Aleatória";
            default:
                return undefined;
        }
    };

    const handlePixValueFormatted = (pix_type, pix_value) => {
        switch (pix_type) {
            case "cpf":
                return pix_value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
            case "cnpj":
                return pix_value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
            case "phone":
                return pix_value.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4");
            default:
                return pix_value;
        }
    };

    return (
        <>
            {(pageState === undefined || pageState === "loading") && (
                <>
                    <LoadingSpinner dark size={24} />
                </>
            )}

            {pageState === "home" && (
                <>
                    <>
                        <SectionTitle margin="35px 0 30px 0">crédito em conta</SectionTitle>

                        <CardBox
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            direction="column"
                            width="fill-available"
                            margin={isMobile ? "0 20px" : "0"}
                            noBorder
                            boxSizing="border-box"
                            padding="22px 20px"
                        >
                            <TextSection margin="0">
                                USE SEU SALDO PARA <Link to="/novidades">COMPRAR</Link> NA RE PETIT E AUTOMATICAMENTE
                                GANHE 10% DE DESCONTO. APROVEITE!
                            </TextSection>

                            <Separator light width="calc(100% + 40px)" margin="4px 0px 20px -20px" />

                            <CardBoxTitle>
                                SALDO PARA SAQUE: R$
                                {(user.balance || 0).toLocaleString("pt-BR", {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })}
                            </CardBoxTitle>
                        </CardBox>

                        <Flex direction="column" alignItems="flex-end" margin={isMobile ? "0 20px" : "0px"}>
                            <TextSection margin="20px 0px 0px 0px">
                                É cobrada uma taxa de R$2,00 para cada saque realizado
                            </TextSection>
                            <Flex direction={isMobile ? "column" : "row"} width="100%" columnGap="16px" rowGap="16px">
                                <TextInput
                                    flex={1}
                                    placeholder="Valor R$"
                                    type="curreny"
                                    name="withdraw_value"
                                    value={values.withdraw_value}
                                    error={errors.withdraw_value}
                                    disabled={!(user.balance > 0)}
                                    onBlur={() =>
                                        setValues((values) => ({
                                            ...values,
                                            withdraw_value: values.withdraw_value
                                                ? parseFloat(
                                                    values.withdraw_value.replace(/\./g, "").replace(",", ".")
                                                ).toLocaleString("pt-BR", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })
                                                : 0,
                                        }))
                                    }
                                    onChange={handleChange}
                                />
                                <Button
                                    dark
                                    width={isMobile ? "100%" : "200px"}
                                    maxHeight="initial"
                                    value="efetuar saque"
                                    onClick={handleSendWithdrawRequest}
                                    disabled={
                                        (user.balance && user.balance <= 0) ||
                                        sendingWithdrawRequest ||
                                        !values.withdraw_value ||
                                        values.withdraw_value <= 0 ||
                                        errors.withdraw_value ||
                                        !user.has_user_pix_address
                                    }
                                    loading={sendingWithdrawRequest}
                                />
                            </Flex>
                            {errors.withdraw_value && <ErrorText>{errors.withdraw_value}</ErrorText>}

                            {!user.has_user_pix_address && (
                                <>
                                    <Flex width="100%" justifyContent="flex-start">
                                        <p>Para efetuar o saque é necessário informar uma chave PIX</p>
                                    </Flex>

                                    <Flex
                                        direction={isMobile ? "column" : "row"}
                                        width="100%"
                                        columnGap="16px"
                                        rowGap="16px"
                                    >
                                        <SelectInput
                                            borderRadius="16px"
                                            height="47px"
                                            width={isMobile ? "100%" : "200px"}
                                            placeholder="Tipo de chave"
                                            options={[
                                                {
                                                    label: "CPF",
                                                    value: "cpf",
                                                },
                                                {
                                                    label: "CNPJ",
                                                    value: "cnpj",
                                                },
                                                {
                                                    label: "Telefone",
                                                    value: "phone",
                                                },
                                                {
                                                    label: "E-mail",
                                                    value: "email",
                                                },
                                                {
                                                    label: "Chave Aleatória",
                                                    value: "evp",
                                                },
                                            ]}
                                            name="pix_type"
                                            value={values.pix_type}
                                            onChange={handleChange}
                                        />
                                        <TextInput
                                            mask={handlePixKeyMask(values.pix_type)}
                                            height="47px"
                                            flex={1}
                                            placeholder={`Chave`}
                                            name="pix_key"
                                            disabled={!values.pix_type}
                                            value={values.pix_key}
                                            error={errors.pix_key}
                                            onChange={handleChange}
                                        />
                                        <Button
                                            dark
                                            width={isMobile ? "100%" : "200px"}
                                            maxHeight="47px"
                                            value="Cadastrar"
                                            onClick={() => {
                                                setModal({
                                                    show: true,
                                                    modalHeight: "320px",
                                                    image: ModalSuccess,
                                                    title: "Deseja confirmar chave PIX?",
                                                    message: `Chave ${handlePixTypeDescription(values.pix_type)}: ${values.pix_key
                                                        }`,
                                                    button: "Confirmar",
                                                    buttonAction: () => {
                                                        handleSubmitPixRegistration();
                                                        setModal({ ...modal, show: false });
                                                    },
                                                    callback: () => {
                                                        setModal({ ...modal, show: false });
                                                    },
                                                });
                                            }}
                                            loading={isLoadingPixRegistration}
                                            disabled={!values.pix_key || errors.pix_key || !values.pix_type}
                                        />
                                    </Flex>
                                </>
                            )}

                            <p>
                                <b>IMPORTANTE: </b> Por uma questão de segurança a chave PIX cadastrada só poderá ser
                                alterada entrando em contato com nossa equipe.
                            </p>

                            {/* {user.user_bank_accounts.length === 0 && (
                                <TextSection>
                                    Para realizar saques você precisa cadastrar seus dados bancários em{" "}
                                    <Link to="/minha-conta/cadastro"> MEU CADASTRO />. Certifique-se de preencher
                                    corretamente sua conta corrente e agência, uma vez confirmado os dados não podem ser
                                    alterados. Caso haja erro de cadastro a transferência não poderá ser efetuada.
                                </TextSection>
                            )} */}
                        </Flex>

                        {/* {user.user_bank_accounts &&
                            user.user_bank_accounts.map((bank_account) => (
                                <CardBox
                                    alignItems="flex-start"
                                    justifyContent="flex-start"
                                    direction="column"
                                    width="fill-available"
                                    margin={isMobile ? "0 20px" : "0px"}
                                    boxSizing="border-box"
                                    key={bank_account.id}
                                >
                                    <CardBoxTitle>
                                        {bank_account.bank.code} - Banco {bank_account.bank.name}
                                    </CardBoxTitle>

                                    <TextSection margin="0">
                                        AG: {bank_account.agency}
                                        <br />
                                        CC: {bank_account.account}
                                        <br />
                                        {user.first_name} {user.last_name}
                                        <br />
                                        CPF:{" "}
                                        {user.cpf.toString().replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}
                                        <br />
                                    </TextSection>
                                </CardBox>
                            ))} */}
                        {user.user_pix_address && (
                            <CardBox
                                alignItems="flex-start"
                                justifyContent="flex-start"
                                direction="column"
                                width="fill-available"
                                margin={isMobile ? "0 20px" : "0px"}
                                boxSizing="border-box"
                                key={user.user_pix_address.pix_address_key}
                            >
                                <CardBoxTitle>Chave PIX</CardBoxTitle>

                                <TextSection margin="0">
                                    Chave:{" "}
                                    {handlePixValueFormatted(
                                        user.user_pix_address.pix_address_key_kind,
                                        user.user_pix_address.pix_address_key
                                    )}
                                    <br />
                                    Tipo: {handlePixTypeDescription(user.user_pix_address.pix_address_key_kind)}
                                    <br />
                                </TextSection>
                            </CardBox>
                        )}
                    </>

                    <>
                        <SectionTitle margin="35px 0 0 0">Extrato</SectionTitle>

                        <Flex
                            width="100%"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            background="#F7F9F9"
                            direction="column"
                            margin="40px 0 20px 0"
                            padding={isMobile ? "0 20px" : null}
                            boxSizing="border-box"
                        >
                            <Flex width="100%">
                                <CardBoxTitle
                                    direction="row"
                                    margin={isMobile ? "29px auto 25px 20px" : "29px auto 25px 37px"}
                                >
                                    Meu Histórico de transações
                                </CardBoxTitle>

                                <SelectInput
                                    width={isMobile ? "calc(50% - 27px)" : "200px"}
                                    margin={isMobile ? "0px 13px 20px 20px" : "29px 0px 25px auto"}
                                    placeholder="Ano"
                                    name="statement_year"
                                    value={statementYear}
                                    disabled={loadingStatement}
                                    options={[...Array(5).keys()].reduce((prev, curr, index) => {
                                        const currYear = parseInt(moment().format("Y")) - index;

                                        if (currYear < 2019) return prev;

                                        prev = [
                                            ...prev,
                                            {
                                                label: currYear.toString(),
                                                value: currYear,
                                            },
                                        ];

                                        return prev;
                                    }, [])}
                                    onChange={(e) => {
                                        setStatementYear(e.target.value);
                                        if (parseInt(e.target.value) < parseInt(moment().format("Y"))) {
                                            setStatementMonth(12);
                                        } else {
                                            setStatementMonth(moment().format("M"));
                                        }
                                    }}
                                />

                                <SelectInput
                                    width={isMobile ? "calc(50% - 27px)" : "200px"}
                                    margin={isMobile ? "0px 20px 20px 0px" : "29px 37px 25px 16px"}
                                    placeholder="Mês"
                                    name="statement_month"
                                    value={statementMonth}
                                    disabled={loadingStatement}
                                    options={[
                                        {
                                            label: "Janeiro",
                                            value: 1,
                                            disabled:
                                                moment().format("M") < 1 && statementYear === moment().format("Y"),
                                        },
                                        {
                                            label: "Fevereiro",
                                            value: 2,
                                            disabled:
                                                moment().format("M") < 2 && statementYear === moment().format("Y"),
                                        },
                                        {
                                            label: "Março",
                                            value: 3,
                                            disabled:
                                                moment().format("M") < 3 && statementYear === moment().format("Y"),
                                        },
                                        {
                                            label: "Abril",
                                            value: 4,
                                            disabled:
                                                moment().format("M") < 4 && statementYear === moment().format("Y"),
                                        },
                                        {
                                            label: "Maio",
                                            value: 5,
                                            disabled:
                                                moment().format("M") < 5 && statementYear === moment().format("Y"),
                                        },
                                        {
                                            label: "Junho",
                                            value: 6,
                                            disabled:
                                                moment().format("M") < 6 && statementYear === moment().format("Y"),
                                        },
                                        {
                                            label: "Julho",
                                            value: 7,
                                            disabled:
                                                moment().format("M") < 7 && statementYear === moment().format("Y"),
                                        },
                                        {
                                            label: "Agosto",
                                            value: 8,
                                            disabled:
                                                moment().format("M") < 8 && statementYear === moment().format("Y"),
                                        },
                                        {
                                            label: "Setembro",
                                            value: 9,
                                            disabled:
                                                moment().format("M") < 9 && statementYear === moment().format("Y"),
                                        },
                                        {
                                            label: "Outubro",
                                            value: 10,
                                            disabled:
                                                moment().format("M") < 10 && statementYear === moment().format("Y"),
                                        },
                                        {
                                            label: "Novembro",
                                            value: 11,
                                            disabled:
                                                moment().format("M") < 11 && statementYear === moment().format("Y"),
                                        },
                                        {
                                            label: "Dezembro",
                                            value: 12,
                                            disabled:
                                                moment().format("M") < 12 && statementYear === moment().format("Y"),
                                        },
                                    ]}
                                    onChange={(e) => setStatementMonth(e.target.value)}
                                />
                            </Flex>

                            <HistoryTable>
                                <thead>
                                    <tr>
                                        <th>Data</th>
                                        <th>Descrição</th>
                                        <th>Status</th>
                                        <th>Valor</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(!transactions || transactions.length === 0) && !loadingStatement && (
                                        <tr>
                                            <td colSpan="3">Nenhuma transação realizada</td>
                                        </tr>
                                    )}

                                    {loadingStatement && (
                                        <tr>
                                            <td colSpan="3">Carregando...</td>
                                        </tr>
                                    )}

                                    {!loadingStatement &&
                                        transactions &&
                                        transactions.map((item, index) => (
                                            <tr key={item.id} className={item.type}>
                                                <td>{item.date ? moment(item.date).format("DD/MM/YYYY") : "-"}</td>
                                                <td>{item.description}</td>
                                                <td className="text-center">
                                                    {item.released ? (
                                                        <img height="25px" src={checkIcon} alt="Icon check" />
                                                    ) : item.released === false ? (
                                                        <span>
                                                            <ReactTooltip className="tooltip" />
                                                            <span
                                                                data-tip={
                                                                    "Previsão do agendamento: " +
                                                                    moment(item.expected_date).format("DD/MM/YYYY")
                                                                }
                                                            >
                                                                <img
                                                                    height="25px"
                                                                    src={scheduleIcon}
                                                                    alt="Icon schedule"
                                                                />
                                                            </span>
                                                        </span>
                                                    ) : null}
                                                </td>
                                                <td>
                                                    R$ {item.type === "out" && "-"}
                                                    {(item.amount || 0).toLocaleString("pt-BR", {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })}
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </HistoryTable>
                        </Flex>
                    </>
                </>
            )}
            {items?.items && values.promotedItems && (
                <PromoModal
                    items={items.items}
                    promotedItems={values.promotedItems}
                    show={showPromoModal}
                    setModal={setModal}
                    callback={(status) => setShowPromoModal(status)}
                    successCallback={() => loadItems()}
                />
            )}
        </>
    );
};

const FooterText = styled.p`
    margin: ${(props) => props.margin || "34px auto 0 auto"};
    max-width: ${(props) => props.maxWidth || "100%"};
    font-family: "Graphik";
    font-size: 13px;
    line-height: 1.25;
    text-align: left;
    color: #34343b;
    box-sizing: border-box;
    ${(props) => props.danger && "color: red;"};
`;

const Separator = styled.hr`
    background-color: ${(props) => (props.light ? "#fff" : "#e5e5e8")};
    margin: ${(props) => props.margin};
    width: ${(props) => props.width || "100%"};
    height: 1px;
    border: none;
`;

const CardBoxTitle = styled.h2`
    margin: ${({ margin }) => margin || "0px 0px 16px 0px"};
    text-transform: uppercase;
    font-size: 15px;
    font-family: "Graphik";
    font-weight: 600;
    color: #34343b;

    :only-child,
    :last-child {
        margin: 0px;
    }
`;

const CardBox = styled(Flex)`
    padding: ${(props) => (props.padding ? props.padding : "34px 28px")};
    background-color: #f7f9f9;
    box-shadow: 0px 1px 3px 0 rgba(52, 52, 59, 0.1);
    border-bottom: ${(props) => !props.noBorder && "2px solid #59cd8e"};
    position: relative;
`;

const EditCard = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 36px;
    text-decoration: underline;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.28px;
    text-align: left;
    color: #676777;
`;

const TextSection = styled.p`
    margin: ${({ margin }) => margin || "0px 0px 16px 0px"};
    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #676777;
    white-space: pre-wrap;

    a,
    strong {
        color: #676777;
        font-weight: 600;
        ${(props) => props.danger && "color: red;"};
    }

    ${(props) => props.danger && "color: red;"};
`;

const HistoryTable = styled.table`
    width: 100%;
    padding: 0px;
    text-transform: uppercase;

    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #676777;

    border-spacing: 0px;

    tr:nth-child(even) {
        background-color: #f7f9f9;
    }
    tr:nth-child(odd) {
        background-color: #f3f5f5;
    }

    .in td:last-child {
        color: #79c992;
    }
    .out td:last-child {
        color: #ff4200;
    }

    tr:first-child td {
        padding-top: 23px;
    }
    tr:last-child td {
        padding-bottom: 23px;
    }

    td,
    th {
        padding: 10px 5px;

        :only-child {
            text-align: center;
        }
        :last-child:not(:only-child) {
            text-align: right;
        }
    }

    th {
        background-color: #313139;
        font-weight: 600;
        color: #f7f9f9;
        font-size: 15px;
        font-family: "Graphik";
    }
    .text-center {
        text-align: center;
    }
    .tooltip {
        padding: 12px 20px;
    }
`;

const ErrorText = styled.p`
    color: red;
    margin-bottom: 0px;
`;

export default MeusCreditos;
