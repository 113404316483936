import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import api from "../../services/api";
import * as UserActions from "../../redux/actions/user";
import { Flex, isMobile, isMobileMediaQuery } from "../../helpers/styles";
import { capitalize, identifyZipCode, validatePassword } from "../../helpers";
import Button from "../../components/Button";
import TextInput from "../../components/Inputs/TextInput";
import SelectInput from "../../components/Inputs/SelectInput";
import ProfileForm from "../../components/ProfileForm";
import PlusIcon from "../../assets/images/icon-plus-mini.png";
import RemoveIcon from "../../assets/images/icon-remove-mini.png";
import EyeIcon from "../../assets/images/eye-icon.png";
import EyeIconCut from "../../assets/images/eye-icon-cut.png";
import ModalSuccess from "../../assets/images/modal-success-hero.jpg";
import PromoModal from "../../components/PromoModal";

const MeuCadastro = ({ steps, currentStep, setCurrentStep, modal, setModal, ...props }) => {
    const dispatch = useDispatch();
    const address_kinds = { billing: "Cobrança", shipping: "Entrega" };

    const user = useSelector((state) => state.user) || {};
    const main_address = (user.user_addresses && user.user_addresses[0]) || {};

    const [showPromoModal, setShowPromoModal] = useState(false);
    const [items, setItems] = useState(undefined);
    const [sendingRequest, setSendingRequest] = useState(false);
    const [banks, setBanks] = useState([]);
    const [errors, setErrors] = useState({});
    const [values, setValues] = useState({
        ...user,
        birth_date: user.birth_date && moment(user.birth_date, "YYYY-MM-DD").format("DD/MM/YYYY"),
        ...{
            phone_number: user.phone_number && `${user.phone_code_area}${user.phone_number}`,
        },

        ...main_address,
        ...{
            zip_code: main_address.zip,
            house_number: main_address.number,
        },

        billing_address: "same_address",
        customer_children: user.customer_children || [],
        user_addresses: user.user_addresses || [],
        user_bank_accounts: user.user_bank_accounts || [],
        has_user_pix_address: user.has_user_pix_address || false,
        user_pix_address: user.user_pix_address || {},
    });

    const [showUserPixForm, setShowUserPixForm] = useState(user.has_user_pix_address ?? false);

    const [changingPassword, setChangingPassword] = useState(false);
    const [showPassword, setShowPassword] = useState(null);
    const [oldPassword, setOldPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [confirmNewPassword, setConfirmNewPassword] = useState(null);
    const [sendingPasswordChangeRequest, setSendingPasswordChangeRequest] = useState(false);

    useEffect(() => {
        loadBanks();
    }, []);

    const loadBanks = async () => {
        const banks_response = await api.get("/banks").catch((err) => err.response);

        if (banks_response.status === 200) {
            setBanks(banks_response.data);
        }
    };

    const handleChange = async (e) => {
        let {
            target: { name, value, checked, type },
        } = e;

        let newValues = { ...values };

        if (name !== undefined) {
            if (value !== undefined && (type !== "checkbox" || type === "radio")) {
                newValues = {
                    ...newValues,
                    [name]: value,
                };
            } else if (checked !== undefined) {
                newValues = {
                    ...newValues,
                    [name]: checked,
                };
            }
        }

        setValues((prevState) => {
            return { ...prevState, ...newValues };
        });

        canChangeStep(false, true, newValues);
    };

    const loadItems = async () => {
        setItems(null);

        const response = await api.get(`/profiles/entries/items`).catch((err) => err.response);

        if (response.status === 200) {
            setItems({
                ...response.data,
            });
        } else {
            setItems({
                items: [],
                pagination: {},
            });
        }
    };

    const deleteUserPixAddress = () => {
        let newValues = { ...values };
        if (newValues.user_pix_address) newValues.user_pix_address = {};
        setValues((prevState) => {
            return { ...prevState, ...newValues };
        });
    };

    const handleUserPixAddress = (e) => {
        const {
            target: { name, value },
        } = e;

        let newValues = { ...values };

        if (name === "pix_address_key_kind") newValues.user_pix_address[name] = value;
        else if (name === "pix_address_key") newValues.user_pix_address[name] = value;

        setValues((prevState) => {
            return { ...prevState, ...newValues };
        });

        canChangeStep(false, true, newValues);
    };

    const handleChangeItem = async (e, items, index) => {
        let {
            target: { name, value, checked, type },
        } = e;

        let newValues = { ...values };
        let newItems = newValues[items] || [];
        let newItem = newItems[index] || {};

        if (name !== undefined) {
            if (value !== undefined && (type !== "checkbox" || type === "radio")) {
                newItem[name] = value;
            } else if (checked !== undefined) {
                newValues[name] = checked;
            }
        }

        setValues((prevState) => {
            return { ...prevState, ...newValues };
        });

        canChangeStep(false, true, newValues);
    };

    const handleZipCode = async ({ target: { value, name } }) => {
        let newValues = { ...values };

        if (value && value.replace(/_/g, "").replace(/-/g, "").length >= 8) {
            let zipCodeResponse = await identifyZipCode(value);

            if (zipCodeResponse.street) {
                setErrors((prevState) => {
                    return {
                        ...prevState,
                        zip_code: false,
                    };
                });
            }

            newValues.street = zipCodeResponse.logradouro;
            newValues.state = zipCodeResponse.uf;
            newValues.city = zipCodeResponse.localidade;
            newValues.neighborhood = zipCodeResponse.bairro;
        }

        setValues((prevState) => {
            return { ...prevState, ...newValues };
        });
    };

    const canChangeStep = (scroll = false, ignoreUndefined = false, checkValues = values) => {
        let canChange = true;

        let newErrors = { ...errors };

        switch (currentStep + 1) {
            case 1:
                const firstStepRequireds = [
                    "email",
                    "password",
                    "confirm_password",
                    "first_name",
                    "last_name",
                    "phone_number",
                    "cpf",
                ];

                firstStepRequireds.forEach((requiredField) => {
                    if (
                        (!Object.keys(checkValues).includes(requiredField) && !ignoreUndefined) ||
                        checkValues[requiredField] === ""
                    ) {
                        newErrors[requiredField] = true;
                    } else if (!newErrors[requiredField] || checkValues[requiredField] !== undefined) {
                        newErrors[requiredField] = false;
                    }
                });

                canChange = firstStepRequireds.every((requiredField) => {
                    const invalidField = document.querySelector(`[name=${requiredField}]`);

                    if (invalidField && scroll) {
                        invalidField.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        });
                    }

                    return (
                        Object.keys(checkValues).includes(requiredField) &&
                        checkValues[requiredField] !== undefined &&
                        checkValues[requiredField] !== ""
                    );
                });

                if (
                    !checkValues.password ||
                    !checkValues.confirm_password ||
                    checkValues.password !== checkValues.confirm_password ||
                    !validatePassword(checkValues.password)
                ) {
                    canChange = false;
                }

                const birth_date = moment(checkValues.birth_date, "dd/mm/yyyy");
                const user_age = birth_date && moment().diff(birth_date, "years");

                if (
                    checkValues.birth_date.length < 10 ||
                    !birth_date ||
                    !birth_date.isValid() ||
                    user_age < 5 ||
                    user_age > 150
                ) {
                    newErrors.birth_date = true;

                    canChange = false;

                    const invalidField = document.querySelector(`[name=birth_date]`);

                    if (invalidField && scroll) {
                        invalidField.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        });
                    }
                }

                break;

            case 2:
                canChange = checkValues.box_type_id > 0;
                break;

            case 3:
                const thirdStepRequireds = [
                    "likes_design",
                    "top_needs",
                    "bottom_needs",
                    "essential_pieces",
                    "shoe_needs",
                    "accessory_needs",
                    "brands",
                    "special_occasion",
                    "send_items_for_sale",
                    "surprise_expected",
                    "accept",
                ];

                canChange = thirdStepRequireds.every((requiredField) => {
                    const invalidField = document.querySelector(`[name=${requiredField}]`);

                    if (invalidField && scroll) {
                        invalidField.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        });
                    }

                    return (
                        Object.keys(checkValues).includes(requiredField) &&
                        checkValues[requiredField] !== undefined &&
                        checkValues[requiredField] !== ""
                    );
                });

                if (checkValues.accept !== true) canChange = false;

                break;

            case 4:
                canChange = checkValues.accept_terms === true;

                break;

            default:
                break;
        }

        setErrors({ ...newErrors });

        return canChange;
    };

    const handleNextStep = () => {
        if (currentStep !== steps.length) setCurrentStep(currentStep + 1);
        const hasAddedPixInfo =
            !user.has_user_pix_address &&
            values.user_pix_address &&
            values.user_pix_address.pix_address_key_kind &&
            values.user_pix_address.pix_address_key;

        if (hasAddedPixInfo)
            setModal({
                show: true,
                modalHeight: "320px",
                image: ModalSuccess,
                title: "Deseja confirmar chave PIX?",
                message: `Chave ${handlePixTypeDescription(values.user_pix_address.pix_address_key_kind)}: ${
                    values.user_pix_address.pix_address_key
                }`,
                button: "Confirmar",
                buttonAction: () => {
                    setModal({ ...modal, show: false });
                    handleSaveProfile();
                },
                callback: () => {
                    setModal({ ...modal, show: false });
                },
            });
        else handleSaveProfile();
    };

    const handlePreviousStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        } else if (currentStep <= 1) {
            setCurrentStep(undefined);
        }
    };

    const handleSaveProfile = async () => {
        const bank_account = values.user_bank_accounts[0];
        const hasBankAccount = !!(
            bank_account &&
            bank_account.account_type &&
            bank_account.bank_id &&
            bank_account.agency &&
            bank_account.agency_digit &&
            bank_account.account &&
            bank_account.account_digit
        );

        let user_payload = {
            cpf: values.cpf,
            birth_date: values.birth_date,
            email: values.email,
            first_name: values.first_name,
            last_name: values.last_name,
            phone_country_code: values.phone_country_code || "55",
            phone_code_area:
                values.phone_number && values.phone_number.length > 0 && values.phone_number[0] === "("
                    ? values.phone_number.match(/^\((\d{2})\)/)[1]
                    : values.phone_number.substring(0, 2),
            phone_number:
                values.phone_number && values.phone_number[0] === "("
                    ? values.phone_number.substring(5).replace(/\s/g, "").replace(/_/g, "").replace(/-/g, "")
                    : values.phone_number.substring(2),
            newsletter: values.newsletter,
            user_addresses: [
                {
                    kind: main_address.kind,
                    street: values.street,
                    number: values.house_number,
                    neighborhood: values.neighborhood,
                    additional_info: values.additional_info,
                    city: values.city,
                    state: values.state,
                    zip: values.zip_code,
                    id: main_address.id,
                },
                ...values.user_addresses.slice(1),
            ],
            customer_children: values.customer_children,
            user_bank_accounts: hasBankAccount ? values.user_bank_accounts : user.user_bank_accounts ?? [],
            user_pix_address: values.user_pix_address &&
                values.user_pix_address.pix_address_key_kind &&
                values.user_pix_address.pix_address_key && {
                    pix_address_key_kind: values.user_pix_address.pix_address_key_kind,
                    pix_address_key:
                        values.user_pix_address.pix_address_key_kind !== "email" &&
                        values.user_pix_address.pix_address_key_kind !== "evp"
                            ? values.user_pix_address.pix_address_key.replace(/[-. ()]/g, "")
                            : values.user_pix_address.pix_address_key,
                },
        };

        if (
            values.user_addresses.length === 0 &&
            !values.street &&
            !values.house_number &&
            !values.neighborhood &&
            !values.additional_info &&
            !values.city &&
            !values.state &&
            !values.zip_code
        ) {
            user_payload.user_addresses = [];
        }

        setSendingRequest(true);
        let saveResponse = await api.put("/profiles/me", user_payload).catch((err) => err.response);
        setSendingRequest(false);

        if (saveResponse.status === 200) {
            if (currentStep === steps.length) setCurrentStep(undefined);

            dispatch(UserActions.refreshProfile());
        } else {
            console.error(saveResponse);

            setModal({
                show: true,
                message:
                    saveResponse && saveResponse.data && saveResponse.data.message
                        ? saveResponse.data.message
                        : "Erro inesperado. Aguarde um instante e tente outra vez!",
            });
        }
    };

    const handleAddItem = (item) => {
        setValues((prevState) => {
            return {
                ...prevState,
                [item]: [...prevState[item], {}],
            };
        });
    };

    const handleRemoveItem = (item, index) => {
        setValues((prevState) => {
            return {
                ...prevState,
                [item]: [...prevState[item].filter((item, filterIndex) => filterIndex !== index)],
            };
        });
    };

    const userHasFilledAdress = () => {
        if (
            values.street &&
            values.house_number &&
            values.neighborhood &&
            values.city &&
            values.state &&
            values.zip_code
        )
            return true;
        else return false;
    };

    const handleChangePassword = async () => {
        setSendingPasswordChangeRequest(true);

        let passwordChangeResponse = await api
            .put("/auth/change_password", {
                current_password: oldPassword,
                new_password: newPassword,
            })
            .catch((err) => err.response);

        setSendingPasswordChangeRequest(false);

        if (passwordChangeResponse.status === 204) {
            setCurrentStep(undefined);
            handleCancelPasswordChange();

            dispatch(UserActions.refreshProfile());

            setModal({
                show: true,
                image: ModalSuccess,
                title: "Yeah!",
                message: "Senha trocada com sucesso!",
                callback: () => {
                    setModal({ ...modal, show: false });
                },
            });
        } else {
            console.error(passwordChangeResponse);

            setModal({
                show: true,
                message:
                    passwordChangeResponse && passwordChangeResponse.data && passwordChangeResponse.data.message
                        ? passwordChangeResponse.data.message
                        : "Erro inesperado. Aguarde um instante e tente outra vez!",
            });
        }
    };

    const handleCancelPasswordChange = () => {
        setChangingPassword(false);
        setOldPassword(null);
        setNewPassword(null);
        setConfirmNewPassword(null);
    };

    let password = newPassword || "";

    const caseRule =
        password !== "" &&
        (!password.match(/[A-Z]*/g) ||
            password.match(/[A-Z]*/g).filter((item) => item !== null && item !== "").length === 0 ||
            !password.match(/[a-a]*/g) ||
            password.match(/[a-z]*/g).filter((item) => item !== null && item !== "").length === 0);

    const numberRule =
        password !== "" &&
        (!password.match(/[0-9]*/g) ||
            password.match(/[0-9]*/g).filter((item) => item !== null && item !== "").length === 0);

    const specialCharRule =
        password !== "" &&
        (!password.match(/[![\]@#$%^&*(),.?":;{}=+|<>_-]/g) ||
            password.match(/[![\]@#$%^&*(),.?":;{}=+|<>_-]/g).filter((item) => item !== null && item !== "").length ===
                0);

    const lengthRule = password !== "" && password.length < 6;

    const handlePixKeyMask = (pix_type) => {
        switch (pix_type) {
            case "cpf":
                return "999.999.999-99";
            case "cnpj":
                return "99.999.999/9999-99";
            case "phone":
                return "(99) 9 9999-9999";
            default:
                return undefined;
        }
    };

    const handlePixTypeDescription = (pix_type) => {
        switch (pix_type) {
            case "cpf":
                return "CPF";
            case "cnpj":
                return "CNPJ";
            case "phone":
                return "Telefone";
            case "email":
                return "E-mail";
            case "evp":
                return "Chave Aleatória";
            default:
                return undefined;
        }
    };

    const handlePixValueFormatted = (pix_type, pix_value) => {
        switch (pix_type) {
            case "cpf":
                return pix_value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
            case "cnpj":
                return pix_value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
            case "phone":
                return pix_value.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4");
            default:
                return pix_value;
        }
    };

    const userHasNotPixInfo =
        !values.user_pix_address ||
        !values.user_pix_address.length === 0 ||
        (values.user_pix_address && !values.user_pix_address.pix_address_key);

    return (
        <Flex
            width="100%"
            justifyContent="flex-start"
            alignItems="flex-start"
            padding={isMobile ? "0 20px" : undefined}
            direction="column"
            boxSizing="border-box"
        >
            {(!currentStep || currentStep > 1) && (
                <>
                    <SectionTitle margin={currentStep >= 1 && "30px auto 40px 0px"}>
                        1.1 Meus dados cadastrais
                    </SectionTitle>

                    <CardBox
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        direction="column"
                        width="fill-available"
                        margin="0px 0px 20px 0px"
                    >
                        <EditCard onClick={() => setCurrentStep(1)}>Editar</EditCard>

                        <CardBoxTitle>
                            {values.first_name} {values.last_name}
                        </CardBoxTitle>

                        <TextSection margin="0">
                            Email: {values.email}
                            <br />
                            Telefone:
                            {values.phone_number ? (
                                <>
                                    +{values.phone_country_code} ({values.phone_code_area}){" "}
                                    {values.phone_number
                                        .substring(2)
                                        .toString()
                                        .replace(/(\d{4,5})(\d{4})/, "$1-$2")}
                                </>
                            ) : (
                                "-"
                            )}
                            <br />
                            Data de nascimento: {values.birth_date || "-"}
                            <br />
                            CPF: {(values.cpf || "-").toString().replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}
                            <br />
                        </TextSection>
                    </CardBox>

                    <SectionTitle>1.2 Dados de entrega</SectionTitle>

                    {(!values.user_addresses || values.user_addresses.length === 0) && !userHasFilledAdress() && (
                        <CardBox
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            direction="column"
                            width="fill-available"
                            margin="0px 0px 20px 0px"
                        >
                            <EditCard onClick={() => setCurrentStep(1)}>Editar</EditCard>

                            <CardBoxTitle>Nenhum endereço cadastrado</CardBoxTitle>
                        </CardBox>
                    )}

                    {(user.user_addresses.length > 0 || userHasFilledAdress()) && (
                        <CardBox
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            direction="column"
                            width="fill-available"
                            margin="0px 0px 20px 0px"
                        >
                            <EditCard onClick={() => setCurrentStep(1)}>Editar</EditCard>

                            <CardBoxTitle>Endereço {address_kinds[main_address.kind] || ""}</CardBoxTitle>

                            <TextSection margin="0">
                                {values.street}, {values.house_number}{" "}
                                {values.additional_info && " - " + values.additional_info}
                                <br />
                                {values.neighborhood && values.neighborhood + " - "} {values.city} - {values.state}
                                <br />
                                CEP: {values.zip_code}
                                <br />
                            </TextSection>
                        </CardBox>
                    )}

                    {values.user_addresses &&
                        values.user_addresses.slice(1).map((address) => (
                            <CardBox
                                alignItems="flex-start"
                                justifyContent="flex-start"
                                direction="column"
                                width="fill-available"
                                margin="0px 0px 20px 0px"
                                key={address.id}
                            >
                                <EditCard onClick={() => setCurrentStep(1)}>Editar</EditCard>

                                <CardBoxTitle>Endereço {address_kinds[address.kind] || ""}</CardBoxTitle>

                                <TextSection margin="0">
                                    {address.street}, {address.number}{" "}
                                    {address.additional_info && " - " + address.additional_info}
                                    <br />
                                    {address.neighborhood && address.neighborhood + " - "} {address.city} -{" "}
                                    {address.state}
                                    <br />
                                    CEP: {address.zip}
                                    <br />
                                </TextSection>
                            </CardBox>
                        ))}
                </>
            )}

            {(!currentStep || currentStep > 2) && (
                <>
                    <SectionTitle>2. dados dos meus filhos</SectionTitle>

                    {(!values.customer_children || values.customer_children.length === 0) && (
                        <CardBox
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            direction="column"
                            width="fill-available"
                            margin="0px 0px 20px 0px"
                        >
                            <EditCard onClick={() => setCurrentStep(2)}>Editar</EditCard>

                            <CardBoxTitle>Nenhuma criança cadastrada</CardBoxTitle>
                        </CardBox>
                    )}

                    {values.customer_children &&
                        values.customer_children.map((child) => (
                            <CardBox
                                alignItems="flex-start"
                                justifyContent="flex-start"
                                direction="column"
                                width="fill-available"
                                margin="0px 0px 20px 0px"
                                key={child.id}
                            >
                                <EditCard onClick={() => setCurrentStep(2)}>Editar</EditCard>

                                <CardBoxTitle>{child.name}</CardBoxTitle>

                                <TextSection margin="0">
                                    Data de nascimento: {moment(child.birthday).format("DD/MM/YYYY")} <br />
                                    Sexo: {child.gender === "male" ? "Masculino" : "Feminino"}
                                </TextSection>
                            </CardBox>
                        ))}
                </>
            )}

            {(!currentStep || currentStep > 3) && (
                <>
                    <SectionTitle>3. dados para saque de saldo</SectionTitle>

                    {user.user_pix_address && (
                        <CardBox
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            direction="column"
                            width="fill-available"
                            boxSizing="border-box"
                            key={user.user_pix_address.pix_address_key}
                        >
                            <CardBoxTitle>Chave PIX</CardBoxTitle>

                            <TextSection margin="0">
                                Chave:{" "}
                                {handlePixValueFormatted(
                                    user.user_pix_address.pix_address_key_kind,
                                    user.user_pix_address.pix_address_key
                                )}
                                <br />
                                Tipo: {handlePixTypeDescription(user.user_pix_address.pix_address_key_kind)}
                                <br />
                            </TextSection>
                        </CardBox>
                    )}

                    {!currentStep &&
                        (!user.user_bank_accounts || user.user_bank_accounts.length === 0 || userHasNotPixInfo) && (
                            <CardBox
                                alignItems="flex-start"
                                justifyContent="flex-start"
                                direction="column"
                                width="fill-available"
                                margin="20px 0px 20px 0px"
                            >
                                <EditCard onClick={() => setCurrentStep(3)}>Adicionar</EditCard>

                                <CardBoxTitle>
                                    {(!user.user_bank_accounts || user.user_bank_accounts.length === 0) &&
                                    userHasNotPixInfo
                                        ? "Nenhuma conta bancária ou chave PIX cadastrada"
                                        : userHasNotPixInfo
                                        ? "Nenhuma chave PIX cadastrada"
                                        : "Nenhuma conta bancária cadastrada"}
                                </CardBoxTitle>
                            </CardBox>
                        )}

                    {user.user_bank_accounts &&
                        user.user_bank_accounts.length > 0 &&
                        user.user_bank_accounts.map((bank_account) => (
                            <CardBox
                                alignItems="flex-start"
                                justifyContent="flex-start"
                                direction="column"
                                width="fill-available"
                                margin="20px 0px 40px 0px"
                                key={bank_account.id}
                            >
                                <CardBoxTitle>
                                    {bank_account.bank
                                        ? `${bank_account.bank.code} - Banco ${bank_account.bank.name}`
                                        : banks.find((item) => item.id === bank_account.bank_id)
                                        ? `${banks.find((item) => item.id === bank_account.bank_id).code} - ${
                                              banks.find((item) => item.id === bank_account.bank_id).name
                                          }`
                                        : "Conta"}
                                </CardBoxTitle>

                                <TextSection margin="0">
                                    AG: {bank_account.agency}
                                    <br />
                                    CC: {bank_account.account}
                                    <br />
                                    {capitalize(`${user.first_name} ${user.last_name}`)}
                                    <br />
                                    CPF: {user.cpf.toString().replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}
                                    <br />
                                </TextSection>
                            </CardBox>
                        ))}
                </>
            )}
            <br />

            {!currentStep && (
                <>
                    <SectionTitle>4. Minha senha</SectionTitle>

                    {changingPassword && (
                        <>
                            <Flex alignItems="flex-start" justifyContent="flex-start">
                                <Flex width={isMobile ? "100%" : "50%"}>
                                    <TextInput
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Senha atual"
                                        width="100%"
                                        margin={isMobile ? "0 0 20px 0" : "0 auto 20px 0"}
                                        name="oldPassword"
                                        value={oldPassword}
                                        onChange={({ target: { value } }) => setOldPassword(value)}
                                        append={
                                            <EyeButton onClick={() => setShowPassword(!showPassword)}>
                                                <img
                                                    alt="Olho - Mostrar senha"
                                                    src={showPassword ? EyeIconCut : EyeIcon}
                                                />
                                            </EyeButton>
                                        }
                                    />

                                    <Flex flex="0 0 100%" />

                                    <TextInput
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Senha"
                                        width="100%"
                                        margin={isMobile ? "0 0 20px 0" : "0 auto 20px 0"}
                                        name="password"
                                        value={newPassword}
                                        onChange={({ target: { value } }) => setNewPassword(value)}
                                        error={
                                            errors.password ||
                                            (newPassword && newPassword !== "" && !validatePassword(newPassword))
                                        }
                                        append={
                                            <EyeButton onClick={() => setShowPassword(!showPassword)}>
                                                <img
                                                    alt="Olho - Mostrar senha"
                                                    src={showPassword ? EyeIconCut : EyeIcon}
                                                />
                                            </EyeButton>
                                        }
                                    />

                                    <Flex flex="0 0 100%" />

                                    <TextInput
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Confirmar Senha"
                                        width="100%"
                                        margin={isMobile ? "0 0 20px 0" : "0 auto 20px 0"}
                                        name="confirm_password"
                                        value={confirmNewPassword}
                                        onChange={({ target: { value } }) => setConfirmNewPassword(value)}
                                        error={
                                            errors.confirm_password ||
                                            (confirmNewPassword &&
                                                confirmNewPassword !== "" &&
                                                (!validatePassword(newPassword) ||
                                                    (newPassword !== "" && newPassword !== confirmNewPassword)))
                                        }
                                        append={
                                            <EyeButton onClick={() => setShowPassword(!showPassword)}>
                                                <img
                                                    alt="Olho - Mostrar senha"
                                                    src={showPassword ? EyeIconCut : EyeIcon}
                                                />
                                            </EyeButton>
                                        }
                                    />
                                </Flex>

                                <PasswordHint>
                                    <strong>Fique de olho!</strong>
                                    <br />
                                    <br />
                                    Sua senha deve conter as seguintes características:
                                    <ul>
                                        <PasswordRule wrong={lengthRule}>No minímo 6 caracteres</PasswordRule>
                                        <PasswordRule wrong={caseRule}>
                                            Uma letra minúscula e uma maiúscula
                                        </PasswordRule>
                                        <PasswordRule wrong={numberRule}>Um número</PasswordRule>
                                        <PasswordRule wrong={specialCharRule}>Um caractere especial</PasswordRule>
                                    </ul>
                                </PasswordHint>
                            </Flex>
                        </>
                    )}

                    <Flex>
                        <Button
                            width={isMobile ? "320px" : "200px"}
                            dark
                            filled
                            value={changingPassword ? "Salvar" : "Trocar senha"}
                            margin="0"
                            onClick={() => (changingPassword ? handleChangePassword() : setChangingPassword(true))}
                            disabled={
                                sendingPasswordChangeRequest ||
                                (changingPassword &&
                                    (!validatePassword(newPassword || "") || newPassword !== confirmNewPassword))
                            }
                            loading={sendingPasswordChangeRequest}
                        />
                        {changingPassword && (
                            <Button
                                dark
                                link
                                padding="16px 44px 12px 44px"
                                value="Cancelar"
                                margin="0"
                                onClick={() => handleCancelPasswordChange()}
                                disabled={sendingPasswordChangeRequest}
                            />
                        )}
                    </Flex>
                </>
            )}

            {currentStep === 1 && (
                <>
                    <SectionTitle margin="30px auto 40px 0px">1. Meus Dados Cadastrais e de endereço</SectionTitle>

                    <ProfileForm
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        user={user}
                        handleZipCode={handleZipCode}
                    />

                    <Flex width={isMobile ? "100%" : "911px"} margin="40px auto 45px auto">
                        <Button
                            width={isMobile ? "320px" : "200px"}
                            dark
                            filled
                            borderradius
                            value="Continuar"
                            loading={sendingRequest}
                            disabled={sendingRequest}
                            margin="0"
                            onClick={handleNextStep}
                        />
                        <Button
                            dark
                            link
                            padding="16px 44px 12px 44px"
                            value="Voltar"
                            margin="0"
                            onClick={handlePreviousStep}
                        />
                    </Flex>
                </>
            )}

            {currentStep === 2 && (
                <>
                    <SectionTitle margin="20px auto 40px 0px">2. dados dos meus filhos</SectionTitle>

                    {values.customer_children.length === 0 && (
                        <Flex margin="0 auto 0 0">
                            <ChildButton margin="0 auto 0 0" onClick={() => handleAddItem("customer_children")}>
                                <span>Adicionar um filho</span>
                                <img src={PlusIcon} alt="Adicionar" />
                            </ChildButton>
                        </Flex>
                    )}

                    {values.customer_children.map((child, childIndex) => (
                        <Flex
                            key={`child_${childIndex}`}
                            margin="0 0 16px 0"
                            alignItems="flex-start"
                            width="100%"
                            direction={isMobile ? "column" : "row"}
                        >
                            <Flex flex={isMobile ? "1" : "0.5"} width="100%" direction="column" margin="0 35px 0 0">
                                <TextInput
                                    margin="0 0 20px 0"
                                    placeholder="Nome"
                                    name="name"
                                    value={child.name}
                                    onChange={(e) => handleChangeItem(e, "customer_children", childIndex)}
                                />
                                <TextInput
                                    margin="0 0 22px 0"
                                    placeholder="Data de Nascimento (DD/MM/AAAA)"
                                    type="date"
                                    name="birthday"
                                    value={child.birthday}
                                    onChange={(e) => handleChangeItem(e, "customer_children", childIndex)}
                                />
                            </Flex>
                            <Flex flex={isMobile ? "1" : "0.5"} width="100%" direction="column" margin="0">
                                <SelectInput
                                    margin="0 0 22px 0"
                                    placeholder="Sexo"
                                    name="gender"
                                    value={child.gender}
                                    options={[
                                        {
                                            value: "male",
                                            label: "Masculino",
                                        },
                                        {
                                            value: "female",
                                            label: "Feminino",
                                        },
                                    ]}
                                    onChange={(e) => handleChangeItem(e, "customer_children", childIndex)}
                                />
                            </Flex>
                            <Flex width="100%" justifyContent="space-between">
                                {childIndex === values.customer_children.length - 1 && (
                                    <ChildButton margin="0 auto 0 0" onClick={() => handleAddItem("customer_children")}>
                                        <span>Adicionar mais um filho</span>
                                        <img src={PlusIcon} alt="Adicionar" />
                                    </ChildButton>
                                )}
                                {
                                    <ChildButton
                                        margin="0 0 0 auto"
                                        onClick={() => handleRemoveItem("customer_children", childIndex)}
                                    >
                                        <span>Remover</span>
                                        <img src={RemoveIcon} alt="Remover" />
                                    </ChildButton>
                                }
                            </Flex>
                        </Flex>
                    ))}

                    <Flex width={isMobile ? "100%" : "911px"} margin="40px auto 45px auto">
                        <Button
                            width={isMobile ? "320px" : "200px"}
                            dark
                            filled
                            borderradius
                            value="Continuar"
                            loading={sendingRequest}
                            disabled={sendingRequest}
                            margin="0"
                            onClick={handleNextStep}
                        />
                        <Button
                            dark
                            link
                            padding="16px 44px 12px 44px"
                            value="Voltar"
                            margin="0"
                            onClick={handlePreviousStep}
                        />
                    </Flex>
                </>
            )}

            {currentStep === 3 && (
                <>
                    <SectionTitle margin="20px auto 40px 0px">3. dados para saque de saldo</SectionTitle>

                    {!values.has_user_pix_address && !showUserPixForm && (
                        <Flex margin="0 auto 0 0">
                            <ChildButton margin="0 auto 0 0" onClick={() => setShowUserPixForm(true)}>
                                <span>Adicionar chave PIX</span>
                                <img src={PlusIcon} alt="Adicionar" />
                            </ChildButton>
                        </Flex>
                    )}

                    {showUserPixForm && (
                        <>
                            <Flex direction={isMobile ? "column" : "row"} width="100%" columnGap="16px" rowGap="16px">
                                <SelectInput
                                    disabled={user.has_user_pix_address}
                                    borderRadius="16px"
                                    height="47px"
                                    width={isMobile ? "100%" : "200px"}
                                    placeholder="Tipo de chave"
                                    options={[
                                        {
                                            label: "CPF",
                                            value: "cpf",
                                        },
                                        {
                                            label: "CNPJ",
                                            value: "cnpj",
                                        },
                                        {
                                            label: "Telefone",
                                            value: "phone",
                                        },
                                        {
                                            label: "E-mail",
                                            value: "email",
                                        },
                                        {
                                            label: "Chave Aleatória",
                                            value: "evp",
                                        },
                                    ]}
                                    name="pix_address_key_kind"
                                    value={
                                        user.has_user_pix_address
                                            ? user.user_pix_address.pix_address_key_kind
                                            : values.user_pix_address.pix_address_key_kind
                                    }
                                    onChange={(e) => handleUserPixAddress(e)}
                                />
                                <TextInput
                                    mask={handlePixKeyMask(
                                        user.has_user_pix_address
                                            ? user.user_pix_address.pix_address_key_kind
                                            : values.user_pix_address.pix_address_key_kind
                                    )}
                                    height="47px"
                                    flex={1}
                                    placeholder={`Chave`}
                                    name="pix_address_key"
                                    disabled={
                                        (values.user_pix_address && !values.user_pix_address.pix_address_key_kind) ||
                                        user.has_user_pix_address
                                    }
                                    value={values.user_pix_address && values.user_pix_address.pix_address_key}
                                    error={errors.pix_key}
                                    onChange={(e) => handleUserPixAddress(e)}
                                />
                            </Flex>

                            <p>
                                <b>IMPORTANTE: </b> Por uma questão de segurança a chave PIX cadastrada só poderá ser
                                alterada entrando em contato com nossa equipe.
                            </p>
                            {!user.has_user_pix_address && (
                                <Flex width="100%" justifyContent="space-between">
                                    <ChildButton
                                        margin="0 0 0 auto"
                                        onClick={() => {
                                            setShowUserPixForm(false);
                                            deleteUserPixAddress();
                                        }}
                                    >
                                        <span>Remover</span>
                                        <img src={RemoveIcon} alt="Remover" />
                                    </ChildButton>
                                </Flex>
                            )}
                        </>
                    )}

                    {values.user_bank_accounts.length === 0 && (
                        <Flex margin="0 auto 0 0">
                            <ChildButton margin="0 auto 0 0" onClick={() => handleAddItem("user_bank_accounts")}>
                                <span>Adicionar conta bancária</span>
                                <img src={PlusIcon} alt="Adicionar" />
                            </ChildButton>
                        </Flex>
                    )}

                    {!user.user_bank_accounts[0] &&
                        values.user_bank_accounts.map((account, accountIndex) => (
                            <Flex alignItems="flex-start" width="100%" direction={isMobile ? "column" : "row"}>
                                <Flex
                                    flex={isMobile ? "1" : "0.5"}
                                    width="100%"
                                    direction="column"
                                    margin={isMobile ? "0 0 20px 0" : "0 35px 0 0"}
                                >
                                    <SelectInput
                                        margin="0 0 20px 0"
                                        placeholder="Tipo de conta"
                                        options={[
                                            {
                                                label: "Conta corrente",
                                                value: "checking",
                                            },
                                            {
                                                label: "Conta poupança",
                                                value: "saving",
                                            },
                                        ]}
                                        name="account_type"
                                        value={account.account_type}
                                        onChange={(e) => handleChangeItem(e, "user_bank_accounts", accountIndex)}
                                    />

                                    <SelectInput
                                        margin="0"
                                        placeholder="Banco"
                                        options={banks
                                            .sort((a, b) => a.code - b.code)
                                            .reduce(
                                                (acc, curr) => [
                                                    ...acc,
                                                    {
                                                        label: `${curr.code} - ${curr.name}`,
                                                        value: curr.id,
                                                    },
                                                ],
                                                []
                                            )}
                                        name="bank_id"
                                        value={account.bank_id || (account.bank && account.bank.id)}
                                        onChange={(e) => handleChangeItem(e, "user_bank_accounts", accountIndex)}
                                    />
                                </Flex>

                                <Flex flex={isMobile ? "1" : "0.5"} width="100%" direction="column" margin="0 0 0 0">
                                    <Flex flex="0.5" minWidth={isMobile ? "100%" : "calc(50% - 17.5px)"}>
                                        <TextInput
                                            flex="1"
                                            margin="0 20px 20px 0"
                                            placeholder="AGÊNCIA"
                                            name="agency"
                                            type="number"
                                            value={account.agency}
                                            onChange={(e) => handleChangeItem(e, "user_bank_accounts", accountIndex)}
                                        />

                                        <TextInput
                                            width="40%"
                                            margin="0 0 20px 0"
                                            placeholder="Dígito da agência"
                                            name="agency_digit"
                                            type="number"
                                            value={account.agency_digit}
                                            onChange={(e) => handleChangeItem(e, "user_bank_accounts", accountIndex)}
                                        />
                                    </Flex>

                                    <Flex flex="0.5" minWidth={isMobile ? "100%" : "calc(50% - 17.5px)"}>
                                        <TextInput
                                            flex="1"
                                            margin="0 20px 20px 0"
                                            placeholder="CONTA"
                                            name="account"
                                            type="number"
                                            value={account.account}
                                            onChange={(e) => handleChangeItem(e, "user_bank_accounts", accountIndex)}
                                        />

                                        <TextInput
                                            width="40%"
                                            margin="0 0 20px 0"
                                            placeholder="Dígito da conta"
                                            name="account_digit"
                                            type="number"
                                            value={account.account_digit}
                                            onChange={(e) => handleChangeItem(e, "user_bank_accounts", accountIndex)}
                                        />
                                    </Flex>
                                </Flex>

                                <Flex width="100%" justifyContent="space-between">
                                    {
                                        <ChildButton
                                            margin="0 0 0 auto"
                                            onClick={() => handleRemoveItem("user_bank_accounts", accountIndex)}
                                        >
                                            <span>Remover</span>
                                            <img src={RemoveIcon} alt="Remover" />
                                        </ChildButton>
                                    }
                                </Flex>
                            </Flex>
                        ))}

                    <Flex width={isMobile ? "100%" : "911px"} margin="40px auto 45px auto">
                        <Button
                            width={isMobile ? "320px" : "200px"}
                            dark
                            filled
                            value="Salvar"
                            margin="0"
                            onClick={handleNextStep}
                            disabled={sendingRequest}
                            loading={sendingRequest}
                        />
                        <Button
                            dark
                            link
                            padding="16px 44px 12px 44px"
                            value="Cancelar"
                            margin="0"
                            onClick={() => setCurrentStep(undefined)}
                            disabled={sendingRequest}
                        />
                    </Flex>
                </>
            )}
            {items?.items && values.promotedItems && (
                <PromoModal
                    items={items.items}
                    promotedItems={values.promotedItems}
                    show={showPromoModal}
                    setModal={setModal}
                    callback={(status) => setShowPromoModal(status)}
                    successCallback={() => loadItems()}
                />
            )}
        </Flex>
    );
};

const SectionTitle = styled.h2`
    margin: ${(props) => props.margin || "0px auto 34px 0px"};
    text-transform: uppercase;
    font-family: "SackersGothicStd";
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 2.8px;
    text-align: left;
    color: #34343b;
`;

const CardBoxTitle = styled.h2`
    margin: ${({ margin }) => (margin ? margin : "0px 0px 16px 0px")};
    text-transform: uppercase;
    font-size: 15px;
    font-family: "Graphik";
    font-weight: 600;
    color: #34343b;

    :only-child,
    :last-child {
        margin: 0px;
    }
`;

const CardBox = styled(Flex)`
    padding: ${(props) => (props.padding ? props.padding : "34px 28px")};
    background-color: #f7f9f9;
    box-shadow: 0px 1px 3px 0 rgba(52, 52, 59, 0.1);
    border-bottom: 2px solid #59cd8e;
    position: relative;
`;

const EditCard = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 36px;
    text-decoration: underline;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.28px;
    text-align: left;
    color: #676777;

    ${isMobileMediaQuery} {
        top: initial;
        right: 16px;
        bottom: 16px;
        margin: 0px;
    }
`;

const TextSection = styled.p`
    margin: ${({ margin }) => (margin ? margin : "0px 0px 16px 0px")};
    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #676777;
    white-space: pre-wrap;

    strong {
        font-weight: 600;
    }
`;

const ChildButton = styled.button`
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: ${(props) => props.margin};
    display: flex;
    align-items: center;

    span {
        margin-right: 8px;

        font-family: "Graphik";
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.86;
        letter-spacing: 0.28px;
        text-align: left;
        color: #676777;
    }
`;

const EyeButton = styled.div`
    cursor: pointer;
    img {
        pointer-events: none;
    }
`;

const PasswordHint = styled.p`
    margin: 0 auto 0 16px;
    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    strong {
        font-weight: 600;
    }

    ul {
        padding-left: 25px;
    }
`;

const PasswordRule = styled.li`
    ${({ wrong }) =>
        wrong &&
        css`
            color: red;
        `}
`;

export default MeuCadastro;
