import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { Flex, isMobile, isMobileMediaQuery, sizes } from "../../helpers/styles";
import * as CartActions from "../../redux/actions/cart";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
import Button from "../Button";
import HorizontalProductItem from "../HorizontalProductItem";
import api from "../../services/api";

const userAgent = navigator.userAgent.toLocaleLowerCase();
const isSafari = /safari/.test(userAgent) && !/chrome/.test(userAgent);

const Sacola = ({ active, onProductsAmountChange, showProgressBar, ...props }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const pathname = useSelector((state) => state.category.path);
    const bodyElement = document.querySelector("body");
    const [delivery] = useState(undefined);
    const [showSacola, setShowSacola] = useState(active || false);
    const [eligible, setEligible] = useState();
    const [enabled, setEnabled] = useState();
    const [missingAmount, setMissingAmount] = useState("0");
    const [progress, setProgress] = useState("0");
    const [hasProductWithFalseShipping, setHasProductWithFalseShipping] = useState(false);
    const user = useSelector((state) => state.user);
    const selectorProducts = useSelector((state) => state.cart.products) ?? [];
    const [productsAmount, setProductsAmount] = useState(selectorProducts ? selectorProducts?.length : 0);

    useEffect(() => {
        checkFreeShipping(selectorProducts.map((product) => product.id));
        if (selectorProducts?.length > productsAmount && !selectorProducts.find((item) => item.box_type) && !isMobile) {
            setShowSacola(true);
        } else if (selectorProducts?.length === 0) {
            handleClose();
        }
        setHasProductWithFalseShipping(
            selectorProducts?.some((product) => product.eligible_for_free_shipping === false)
        );
        const newProductsAmount = selectorProducts ? selectorProducts.length : 0;
        setProductsAmount(newProductsAmount);
        onProductsAmountChange(newProductsAmount);
        // eslint-disable-next-line
    }, [selectorProducts]);

    const ProgressBar = ({ duration }) => {
        useEffect(() => {
            const timer = setInterval(() => {
                setProgress((prevWidth) => {
                    if (prevWidth >= 100) {
                        clearInterval(timer);
                        return 100;
                    }
                    return prevWidth + 100 / duration;
                });
            }, 1000);

            return () => {
                clearInterval(timer);
            };
        }, [duration]);
    };

    useEffect(() => {
        active !== showSacola && setShowSacola(active);

        // eslint-disable-next-line
    }, [active]);

    const checkFreeShipping = async (idsProducts) => {
        let response = await api.post("/check_free_shipping", {
            items: idsProducts,
            state: user.user_addresses && user.user_addresses.length > 0 ? user.user_addresses[0].state : "",
            city: user.user_addresses && user.user_addresses.length > 0 ? user.user_addresses[0].city : "",
        });

        if (response.status === 200) {
            setEligible(response.data.eligible);
            setEnabled(response.data.enabled);
            setProductsAmount(selectorProducts?.length);
            setMissingAmount(
                response.data?.missing_amount?.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })
            );
            setProgress(response.data.progress);
        } else {
            console.log("erro");
        }
    };

    useEffect(() => {
        if (showSacola) {
            setHasProductWithFalseShipping(
                selectorProducts?.some((product) => product.eligible_for_free_shipping === false)
            );
            let top = window.scrollY;
            bodyElement.style.position = "sticky";
            bodyElement.style.top = `-${top}px`;
            bodyElement.style.left = "0px";
            bodyElement.style.right = "0px";
        } else {
            const scrollY = bodyElement.style.top;

            bodyElement.style.position = "";
            bodyElement.style.top = "";
            bodyElement.style.left = "";
            bodyElement.style.right = "";

            window.scrollTo(0, -parseInt(scrollY || "0"));
        }

        // eslint-disable-next-line
    }, [showSacola]);

    const handleClose = () => {
        setShowSacola(false);
        props.callback && props.callback(false);
    };

    const handleRemove = (item) => {
        // eslint-disable-next-line
        dataLayer.push({ ecommerce: null });
        // eslint-disable-next-line
        dataLayer.push({
            event: "removeFromCart",
            ecommerce: {
                remove: {
                    products: [
                        {
                            name: item.name,
                            id: item.id,
                            price: item.price_label,
                            brand: item.brand.name,
                            category: item.item_category.name,
                            quantity: 1,
                        },
                    ],
                },
            },
        });

        dispatch(CartActions.removeProduct(item.id));

        if (user.token) {
            const productsTemp = selectorProducts.filter((product) => product.id !== item.id);
            saveAbandonedCart(productsTemp.map((product) => product.id));
        }
    };

    const saveAbandonedCart = async (idsProducts) => {
        try {
            let response = await api.post("/carts", {
                item_ids: idsProducts,
            });
            if (response.status === 204) {
                console.log("saveAbandonedCart: /carts");
            }
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    return (
        showSacola && (
            <>
                <Backdrop onClick={handleClose} />
                <Container>
                    <Title>
                        Minha Sacola ({selectorProducts.filter((item) => !item.box_type).length} Ite
                        {selectorProducts.filter((item) => !item.box_type).length === 1 ? "m" : "ns"})
                        <CloseButton onClick={handleClose} />
                    </Title>
                    <Separator />

                    <ProductList>
                        <Flex direction="column" warp="nowrap">
                            {selectorProducts.map((item, index) => (
                                <HorizontalProductItem
                                    key={index}
                                    item={item}
                                    hideSpecs
                                    handleRemove={handleRemove}
                                    enabledFreeShipping={enabled}
                                />
                            ))}
                        </Flex>
                    </ProductList>

                    {showProgressBar !== false && eligible && parseInt(missingAmount) > 0 && (
                        <>
                            <Text>
                                Com mais R$ {missingAmount} você ganha <b>Frete Grátis</b>
                            </Text>
                            <ProgressContainer>
                                <ProgressBarInner style={{ width: `${progress}%` }} />
                            </ProgressContainer>
                        </>
                    )}

                    {showProgressBar !== false && eligible && parseInt(missingAmount) <= 0 && (
                        <>
                            <Text>
                                Parabéns, você recebeu <b>FRETE GRÁTIS!</b>
                                {hasProductWithFalseShipping && (
                                    <>
                                        <br />
                                        Porém há produtos no seu carrinho que não são aplicáveis ao <b>Frete Grátis</b>.
                                    </>
                                )}
                            </Text>
                            <ProgressContainer>
                                <ProgressBarInner style={{ width: `100%` }} />
                            </ProgressContainer>
                        </>
                    )}

                    <Flex margin="30px 0 0 0" width="calc(100% + 60px)" desktop>
                        <PriceInfo>
                            <span>subtotal</span>
                            <span>
                                R${" "}
                                {selectorProducts
                                    .reduce((acc, curr) => {
                                        return acc + parseFloat(curr.price_label || 0);
                                    }, 0)
                                    .toLocaleString("pt-br", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                            </span>
                        </PriceInfo>

                        {delivery && delivery.price === "free" && (
                            <PriceInfo>
                                <span>FRETE</span>
                                <FreeTag>Grátis</FreeTag>
                            </PriceInfo>
                        )}
                    </Flex>

                    <Flex desktop background="#F7F9F9" width="100%" padding="20px 30px">
                        <Button
                            dark
                            filled
                            fluid
                            borderradius
                            value="Ver Carrinho"
                            margin="0 0 10px 0"
                            onClick={() => navigate("/carrinho")}
                        />
                        <Button
                            type="button"
                            secondary
                            filled
                            fluid
                            borderradius
                            value="Continuar Comprando"
                            margin="0 0 18px 0"
                            onClick={() => window.history.go(-1)}
                        />
                    </Flex>

                    <Flex mobile background="#F7F9F9" width="100%" padding="20px 30px" margin="30px 0 0 0">
                        <Flex width="100%" margin="0 0 17px 0" onClick={() => navigate("/carrinho")}>
                            <Button width="30%" dark filled borderradius value="Ver Carrinho" margin="0" />
                            <Button
                                width="70%"
                                dark
                                value={`SUBTOTAL R$ ${selectorProducts
                                    .reduce((acc, curr) => {
                                        return acc + parseFloat(curr.price_label || 0);
                                    }, 0)
                                    .toLocaleString("pt-br", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}`}
                                margin="0"
                            />
                        </Flex>
                    </Flex>
                </Container>
            </>
        )
    );
};

const Backdrop = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: transparent;
    z-index: 10;

    ${isMobileMediaQuery} {
        z-index: -1;
    }
`;

const Container = styled.div`
    z-index: 20;
    position: absolute;
    top: 100%;
    right: 0px;
    min-width: 420px;
    height: auto;
    max-height: calc(100vh - ${sizes.header}px);
    overflow-y: hidden;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px 0 rgba(52, 52, 59, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 30px 0px 30px;
    box-sizing: border-box;

    ${isMobileMediaQuery} {
        max-height: calc(100vh - ${sizes.header + 55}px);

        box-shadow: 0px 20px 50px 0 rgba(77, 77, 89, 0.3);
        position: static;
        height: auto;
        min-width: initial;

        ${() =>
            isSafari &&
            css`
                max-height: calc(100vh - ${sizes.header + 105}px);
            `}
    }
`;

const CloseButton = styled(CloseIcon)`
    cursor: pointer;
`;

const Title = styled(Flex)`
    width: 100%;
    text-transform: uppercase;
    justify-content: flex-start;

    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: 2.2px;
    text-align: left;
    color: #34343b;

    svg {
        margin-left: auto;
    }
`;

const Separator = styled.hr`
    width: calc(100% + 60px);
    height: 1px;
    min-height: 1px;
    margin-top: 18px;
    margin-bottom: 30px;
    margin-left: -30px;
    background-color: #f4f4f5;
    border: 0px;
`;

const ProductList = styled.div`
    width: 100%;
    flex: 1;
    max-height: 400px;
    overflow-y: auto;
    padding-right: 26px;
    box-sizing: border-box;
    margin-bottom: 15px;
    border-bottom: 1px solid #f4f4f5;

    ::-webkit-scrollbar {
        width: 3px;
    }

    ::-webkit-scrollbar-track {
        background: #f4f4f5;
    }

    ::-webkit-scrollbar-thumb {
        background: #cacad1;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #888;
    }
`;

const PriceInfo = styled(Flex)`
    width: 100%;
    background-color: #f7f9f9;
    justify-content: space-between;
    text-transform: uppercase;
    padding: 23px 30px;

    font-family: "Graphik";
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    :not(:last-of-type) {
        border-bottom: 1px solid #fff;
    }
    :last-of-type {
        margin-bottom: 20px;
    }
`;

const FreeTag = styled.span`
    text-transform: capitalize;

    font-family: "Graphik";
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: right;
    color: #79c992;
`;

const ProgressContainer = styled.div`
    width: 100%;
    height: 8px;
    background-color: #f0f0f0;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #e4858c;
    padding: 1px;
    margin-top: 5px;
`;

const ProgressBarInner = styled.div`
    height: 100%;
    background-color: #e4858c;
    transition: width 0.5s ease-in-out;
    border-radius: 5px;
`;

const Text = styled.p`
    margin-bottom: 4px;
    font-family: "Graphik";
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;
`;

export default Sacola;
