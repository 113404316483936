import React, { useRef } from "react";
import styled, { css } from "styled-components";
import {useNavigate } from "react-router-dom";

import { isMobileMediaQuery } from "../../helpers/styles";
import { useEffect } from "react";

function CarrousselItem({
    title,
    subtitle,
    description,
    id,
    index,
    link_text,
    link_url,
    color,
    image,
    boxPage,
    ...props
}) {
    const navigate = useNavigate();
    const messageRef = useRef(null);

    const handleClick = () => {
        // eslint-disable-next-line
        dataLayer.push({ ecommerce: null });
        // eslint-disable-next-line
        dataLayer.push({
            event: "promotionClick",
            ecommerce: {
                promoClick: {
                    promotions: {
                        id: id,
                        position: index + 1,
                    },
                },
            },
        });
        if (link_url) {
            if (link_url.startsWith("http")) window.open(link_url, "_blank");
            else navigate(link_url);
        }
    };

    useEffect(() => {
        if (messageRef.current && description && description !== ".") {
            messageRef.current.innerHTML = description.replaceAll("\n", "<br>");
        }
    }, [description]);

    return (
        <Container onClick={handleClick} height={props.height} src={image} boxPage={boxPage}>
            {title && title !== "." && (
                <Title color={color} boxPage={boxPage}>
                    {title}
                </Title>
            )}
            {subtitle && subtitle !== "." && (
                <Subtitle color={color} boxPage={boxPage}>
                    {subtitle}
                </Subtitle>
            )}

            {description && description !== "." && (
                <Message ref={messageRef} content={description} color={color} boxPage={boxPage}></Message>
            )}

            {link_url && link_text && link_text !== "." && (
                <ButtonHome margin="0" color={color} as="a" href={link_url}>
                    {link_text}
                </ButtonHome>
            )}
        </Container>
    );
}

export const Container = styled.div`
    background-image: url(${(props) => props.src});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: auto;
    flex: 1;
    height: ${(props) => props.height || 430}px;
    padding: 70px 50px 70px 50px;
    box-sizing: border-box;
    filter: brightness(1.2);

    ${(props) =>
        props.boxPage &&
        css`
            padding: 0px 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        `}

    ${isMobileMediaQuery} {
        max-height: 0px;
        width: calc(100% - 8px);
        display: flex;
        flex-direction: column;
        align-items: left;
        background-position: 100% 35%;
        padding: 25px 10px 40px 12px;
        padding-bottom: calc(11 / 16 * 100%);
        box-sizing: border-box;
        margin: 0 4px 8px 4px;
        background-position: 50% 18%;
        background-size: cover;
    }
`;

export const Title = styled.div`
    color: ${({ color }) => (color ? color : "#34343b")};
    max-width: 60%;
    margin: 0;
    font-size: 61px;
    font-family: "DMSerifDisplay";
    line-height: 0.92;
    letter-spacing: -0.61px;
    padding-bottom: 30px;
    ${(props) =>
        props.boxPage &&
        css`
            font-size: 71.5px;
            line-height: 0.69;
        `}

    ${isMobileMediaQuery} {
        max-width: 70%;
        font-size: 41px;
        text-align: left;
        margin-bottom: 0px;
        padding-bottom: 5px;
        color: ${({ color }) => (color ? color : "#34343b")};
    }
`;

export const Subtitle = styled.div`
    color: ${({ color }) => (color ? color : "#34343b")};
    max-width: 60%;
    margin: 0;
    font-size: 41px;
    line-height: 0.92;
    letter-spacing: -0.61px;
    padding-bottom: 30px;
    ${(props) =>
        props.boxPage &&
        css`
            font-size: 71.5px;
            line-height: 0.69;
        `}

    ${isMobileMediaQuery} {
        max-width: 70%;
        font-size: 31px;
        text-align: left;
        margin-bottom: 0px;
        padding-bottom: 5px;
        color: ${({ color }) => (color ? color : "#34343b")};
    }
`;

export const ButtonHome = styled.button`
    background-color: transparent;
    color: ${({ color }) => (color ? color : "#34343b")};
    border: 1px solid ${({ color }) => (color ? color : "#34343b")};

    display: -webkit-box;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-height: 40px;
    font-family: "SackersGothicStd", sans-serif;
    border-radius: 16px;
    padding: 15px 40px;
    margin: 0;
    text-transform: uppercase;
    -webkit-text-decoration: none;
    text-decoration: none;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 900;
    text-align: center;
    cursor: pointer;
    position: absolute;
    bottom: 50px;
    line-height: 10px;
    ${isMobileMediaQuery} {
        bottom: 25px;
    }
`;

//DESCRIPTION
export const Message = styled.div`
    max-width: 60%;
    margin-bottom: 26px;
    color: ${({ color }) => (color ? color : "#34343b")};
    font-size: 20px;
    line-height: 1.4;
    ::before {
        content: ${({ content }) => content};
    }

    ${(props) =>
        props.boxPage &&
        css`
            margin-bottom: 38px;
            max-width: 325px;
            line-height: 1.25;
        `}

    ${isMobileMediaQuery} {
        max-width: 70%;
        font-size: 15px;
    }
`;

export default CarrousselItem;
