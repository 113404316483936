import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Flex, isMobile } from "../../helpers/styles";
import "pure-react-carousel/dist/react-carousel.es.css";
import CheckBox from "../../components/Inputs/CheckBox";
import ExpandableFilter from "./ExpandableFilter";

const MobileNavFilter = ({
    currentFilter,
    filterTypes,
    filterValues,
    selectedFilters,
    handleSelectItem,
    setCurrentFilter,
    handleClearFilter,
    setFromPrice,
    setToPrice,
    fromPrice,
    toPrice,
    setGenderValue,
    genderValue,
    ...props
}) => {
    const [menuStatus, setMenuStatus] = useState({
        size: false,
        color: false,
        price: false,
        from_price: false,
        to_price: false,
        brand: false,
        style: false,
        type: false,
    });

    const [searchBrand, setSearchBrand] = useState("");
    const [searchValue, setSearchValue] = useState("");

    const handleSearchBrand = () => {
        setSearchBrand(searchValue);
    };

    const renderFilterContent = (type) => {
        switch (type.value) {
            case "size":
                return (
                    <ExpandableFilter
                        filterTypes={filterTypes}
                        selectedFilters={selectedFilters}
                        filterValues={filterValues}
                        handleClearFilter={handleClearFilter}
                        fromPrice={fromPrice}
                        toPrice={toPrice}
                        titlePadding="0 20px"
                        titleBoxSizing="border-box"
                        bodyPadding="10px"
                        title={type.label}
                        type={type.value}
                        borderBottom={true}
                        background="#fff"
                        activeBackground="#fff"
                        maxHeight="160px"
                        value={menuStatus[type.value]}
                        onChange={(e) => {
                            setMenuStatus({
                                size: type.value,
                            });
                            e === true ? setCurrentFilter(type.value) : setCurrentFilter(undefined);
                        }}
                    >
                        <LabelsContainer>
                            {filterValues[type.value].map((item, index) => {
                                return (
                                    <label key={`${type.value}_${index}`}>
                                        <input
                                            checked={
                                                selectedFilters[type.value] &&
                                                    selectedFilters[type.value].find((findItem) => findItem === item)
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) => handleSelectItem(type.value, item, e.target.checked)}
                                            type="checkbox"
                                        />
                                        {item}
                                    </label>
                                );
                            })}
                        </LabelsContainer>
                    </ExpandableFilter>
                );

            case "type":
                return (
                    <ExpandableFilter
                        filterTypes={filterTypes}
                        selectedFilters={selectedFilters}
                        filterValues={filterValues}
                        handleClearFilter={handleClearFilter}
                        fromPrice={fromPrice}
                        toPrice={toPrice}
                        titlePadding="0 20px"
                        titleBoxSizing="border-box"
                        bodyPadding="10px"
                        title={type.label}
                        type={type.value}
                        borderBottom={true}
                        background="#fff"
                        activeBackground="#fff"
                        maxHeight="560px"
                        value={menuStatus[type.value]}
                        onChange={(e) => {
                            //handleClearFilter("type");
                            setMenuStatus({
                                type: type.value,
                            });
                            e === true ? setCurrentFilter(type.value) : setCurrentFilter(undefined);
                        }}
                    >
                        <LabelsContainerRadio>
                            {Object.entries(filterValues["type"]).map((item, index) => (
                                <CheckBox
                                    key={index}
                                    id="radioOpt"
                                    padding="17px 0"
                                    margin="0px 0px 0 15px"
                                    justifyContent="flex-start"
                                    name="gender"
                                    value={item[0]}
                                    defaultChecked={genderValue && genderValue === item[0]}
                                    radio
                                    smaller
                                    onChange={(e) => {
                                        setCurrentFilter("type");
                                        setGenderValue(e.target.value);
                                        handleSelectItem("type", item[0]);
                                    }}
                                >
                                    {item[1]}
                                </CheckBox>
                            ))}
                        </LabelsContainerRadio>
                    </ExpandableFilter>
                );

            case "price":
                return (
                    <ExpandableFilter
                        filterTypes={filterTypes}
                        selectedFilters={selectedFilters}
                        filterValues={filterValues}
                        handleClearFilter={handleClearFilter}
                        fromPrice={fromPrice}
                        toPrice={toPrice}
                        titlePadding="0 20px"
                        titleBoxSizing="border-box"
                        bodyPadding="10px"
                        title={type.label}
                        type={type.value}
                        borderBottom={true}
                        background="#fff"
                        activeBackground="#fff"
                        maxHeight="560px"
                        value={menuStatus[type.value]}
                        onChange={(e) => {
                            setMenuStatus({
                                price: type.value,
                            });
                            e === true ? setCurrentFilter(type.value) : setCurrentFilter(undefined);
                        }}
                    >
                        <Flex padding="20px 0 20px" direction="row" alignItems="flex-center" justifyContent="center">
                            <Flex direction="column" alignItems="flex-start" width="30%">
                                <span>de</span>
                                <PriceInput
                                    name="fromPrice"
                                    onChange={(e) => {
                                        setFromPrice(e.target.value ? parseInt(e.target.value) : 0);
                                    }}
                                />
                            </Flex>
                            <Flex direction="column" alignItems="flex-start" width="30%">
                                <span>até</span>
                                <PriceInput
                                    name="toPrice"
                                    onChange={(e) => {
                                        setToPrice(e.target.value ? parseInt(e.target.value) : 0);
                                    }}
                                    onBlur={() => {
                                        handleSelectItem(type.value, [fromPrice, toPrice], true);
                                    }}
                                />
                            </Flex>
                        </Flex>
                    </ExpandableFilter>
                );

            case "color":
                return (
                    <ExpandableFilter
                        filterTypes={filterTypes}
                        selectedFilters={selectedFilters}
                        filterValues={filterValues}
                        handleClearFilter={handleClearFilter}
                        fromPrice={fromPrice}
                        toPrice={toPrice}
                        titlePadding="0 20px"
                        titleBoxSizing="border-box"
                        bodyPadding="10px"
                        title={type.label}
                        type={type.value}
                        borderBottom={true}
                        background="#fff"
                        activeBackground="#fff"
                        maxHeight="160px"
                        value={menuStatus[type.value]}
                        onChange={(e) => {
                            setMenuStatus({
                                color: type.value,
                            });
                            e === true ? setCurrentFilter(type.value) : setCurrentFilter(undefined);
                        }}
                    >
                        <LabelsContainer>
                            {filterValues[type.value].map((item, index) => {
                                return (
                                    <label key={`${type.value}_${index}`}>
                                        <input
                                            checked={
                                                selectedFilters[type.value] &&
                                                    selectedFilters[type.value].find((findItem) => findItem === item.id)
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) => handleSelectItem(type.value, item.id, e.target.checked)}
                                            type="checkbox"
                                        />
                                        {item.name}
                                    </label>
                                );
                            })}
                        </LabelsContainer>
                    </ExpandableFilter>
                );

            case "style":
                return (
                    <ExpandableFilter
                        filterTypes={filterTypes}
                        selectedFilters={selectedFilters}
                        filterValues={filterValues}
                        handleClearFilter={handleClearFilter}
                        fromPrice={fromPrice}
                        toPrice={toPrice}
                        titlePadding="0 20px"
                        titleBoxSizing="border-box"
                        bodyPadding="10px"
                        title={type.label}
                        type={type.value}
                        borderBottom={true}
                        background="#fff"
                        activeBackground="#fff"
                        maxHeight="160px"
                        value={menuStatus[type.value]}
                        onChange={(e) => {
                            setMenuStatus({
                                style: type.value,
                            });
                            e === true ? setCurrentFilter(type.value) : setCurrentFilter(undefined);
                        }}
                    >
                        <LabelsContainer>
                            {filterValues[type.value].map((item, index) => {
                                return (
                                    <label key={`${type.value}_${index}`}>
                                        <input
                                            checked={
                                                selectedFilters[type.value] &&
                                                    selectedFilters[type.value].find((findItem) => findItem === item.id)
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) => handleSelectItem(type.value, item.id, e.target.checked)}
                                            type="checkbox"
                                        />
                                        {item.name}
                                    </label>
                                );
                            })}
                        </LabelsContainer>
                    </ExpandableFilter>
                );

            case "brand":
                return (
                    <ExpandableFilter
                        filterTypes={filterTypes}
                        selectedFilters={selectedFilters}
                        filterValues={filterValues}
                        handleClearFilter={handleClearFilter}
                        fromPrice={fromPrice}
                        toPrice={toPrice}
                        titlePadding="0 20px"
                        titleBoxSizing="border-box"
                        bodyPadding="10px"
                        title={type.label}
                        type={type.value}
                        borderBottom={true}
                        background="#fff"
                        activeBackground="#fff"
                        maxHeight="360px"
                        marginBottom={"0px"}
                        value={menuStatus[type.value]}
                        onChange={(e) => {
                            setMenuStatus({
                                brand: type.value,
                            });
                            e === true ? setCurrentFilter(type.value) : setCurrentFilter(undefined);
                        }}
                    >
                        <BrandSearchArea>
                            <BrandSearchInput
                                name="brand"
                                onChange={(e) => {
                                    setSearchValue(e.target.value);
                                    if (e.target.value === "") handleSearchBrand();
                                }}
                                placeholder="Pesquisar marca"
                            />
                            <BrandSearchButton type="submit" onClick={() => handleSearchBrand()}>
                                Buscar
                            </BrandSearchButton>
                        </BrandSearchArea>
                        <LabelsContainer>
                            {filterValues[type.value].map((item, index) => {
                                return searchBrand && searchBrand !== "" ? (
                                    item.name.toLowerCase().includes(searchBrand.toLowerCase()) ? (
                                        <label key={`${type.value}_${index}`}>
                                            <input
                                                checked={
                                                    selectedFilters[type.value] &&
                                                        selectedFilters[type.value].find((findItem) => findItem === item.id)
                                                        ? true
                                                        : false
                                                }
                                                onChange={(e) =>
                                                    handleSelectItem(type.value, item.id, e.target.checked)
                                                }
                                                type="checkbox"
                                            />
                                            {item.name}
                                        </label>
                                    ) : (
                                        ""
                                    )
                                ) : (
                                    <label key={`${type.value}_${index}`}>
                                        <input
                                            checked={
                                                selectedFilters[type.value] &&
                                                    selectedFilters[type.value].find((findItem) => findItem === item.id)
                                                    ? true
                                                    : false
                                            }
                                            onChange={(e) => handleSelectItem(type.value, item.id, e.target.checked)}
                                            type="checkbox"
                                        />
                                        {item.name}
                                    </label>
                                );
                            })}
                        </LabelsContainer>
                    </ExpandableFilter>
                );
            default:
                setCurrentFilter(undefined);
                return null;
        }
    };

    return (
        <>
            {filterTypes && filterTypes.filter((item) => item.value !== "order" && filterValues[item.value]).map((type) => <>{renderFilterContent(type)}</>)}
        </>
    );
};

const LabelsContainer = styled.div`
    flex: 1;
    max-height: 170px;
    position: relative;
    width: 100%;
    //overflow-y: scroll;
    overflow-y: auto !important;
    overflow-x: none;
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0%);
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: rgb(163 163 163 / 50%) !important;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0%);
    }
    ::-webkit-scrollbar-thumb:window-inactive {
        background: rgb(163 163 163 / 50%);
    }
    label {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0px 20px;
        text-transform: capitalize;
        box-sizing: border-box;
        cursor: pointer;
        font-family: "Graphik";
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.6;
        letter-spacing: 0.28px;
        text-align: left;
        color: #676778;
        input {
            margin: 0px;
            margin-left: 0;
            margin-right: 5px;
            position: relative;
            appearance: none;
            width: 15px;
            height: 15px;
            border: solid 1px #c9c9cf;
            background-color: #fff;
            transition-duration: 0.3s;
            cursor: pointer;
            &:checked {
                border-color: #e4858c;
                background-color: #e4858c;
                ::after {
                    content: "✓";
                    display: block;
                    text-align: center;
                    color: #fff;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    font-size: 10px;
                    font-weight: bold;
                    transform: translate(-50%, -50%);
                    line-height: 1;
                }
            }
        }
    }
`;

const LabelsContainerRadio = styled.div`
    flex: 1;
    max-height: 260px;
    position: relative;
    width: 100%;
    //overflow-y: scroll;
    overflow-y: auto !important;
    overflow-x: none;
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 8px !important;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0%);
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: rgb(163 163 163 / 50%) !important;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0%);
    }
    ::-webkit-scrollbar-thumb:window-inactive {
        background: rgb(163 163 163 / 50%);
    }
`;

const PriceInput = styled.input`
    margin-top: 6px;
    width: 50%;
    border: 1px solid #d3d3d3;
    padding: 8px;
    height: 30px;
    border-radius: 16px;
    outline: none;
    font-size: 16px;
    color: #676777;
`;

const BrandSearchArea = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    width: 93%;
    border: 1px solid #d3d3d3;
    height: 46px;
    border-radius: 16px 16px 16px 16px;
    margin-bottom: 10px;
    z-index: 2;
`;

const BrandSearchInput = styled.input`
    width: 100%;
    border: none;
    border-right: none;
    padding: 8px;
    height: 30px;
    border-radius: 16px 0 0 16px;
    outline: none;
    font-size: 16px;
    color: #676777;
`;

const BrandSearchButton = styled.button`
    width: 110px;
    height: 46px;
    border: none;
    background: #fff;
    text-align: center;
    color: #e4858c;
    border-radius: 0 16px 16px 0;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
`;

export default MobileNavFilter;
