import React, { useState } from "react";
import styled, { css } from "styled-components";

import excludeIcon from "../../assets/images/exclude.png";

const ColorSelection = ({ checked, onChange, color, title, ...props }) => {
    const [isChecked, setIsChecked] = useState(checked || false);

    const handleToggle = () => {
        onChange && onChange(!isChecked);
        setIsChecked(!isChecked);
    };

    return (
        <Container
            color={color}
            checked={isChecked}
            onClick={handleToggle}
            title={title}
        >
            <img src={excludeIcon} alt="Exclude" />
        </Container>
    );
};

const Container = styled.div`
    background: ${(props) => props.color};
    width: 64px;
    height: 64px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 7px;
    border: 1px solid rgba(0, 0, 0, 0.25);

    :not(:last-child) {
        margin-right: 7px;
    }

    img {
        width: 34px !important;
        height: 34px !important;
        filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.8));
        display: none;
        pointer-events: none;
    }

    ${(props) =>
        props.checked &&
        css`
            img {
                display: block;
            }
        `}
`;

export default ColorSelection;
