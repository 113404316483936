import React from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/user";
import { validSession } from "../../helpers";

const PrivateRoute = ({ children, ...props }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    const authenticated = validSession();

    if (user.token && !authenticated) {
        dispatch(logout());
    }

    return (
        <>
            {authenticated ? (
                children
            ) : (
                <Navigate
                    to={{
                        pathname: "/login",
                        state: { redirectUrl: window.location.pathname },
                    }}
                />
            )}
        </>
    );
};

export default PrivateRoute;
