import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import * as UserActions from "../../redux/actions/user";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Flex, isMobile, isMobileMediaQuery } from "../../helpers/styles";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
import Button from "../Button";
import api from "../../services/api";
import TextInput from "../Inputs/TextInput";
import LoadingSpinner from "../LoadingSpinner";

const PhoneVerificationModal = ({ show, callback, phone, navigateToHome, ...props }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const bodyElement = document.querySelector("body");
    const [modalActive, setModalActive] = useState(false);
    const [code, setCode] = useState("");
    const [isSendingCode, setIsSendingCode] = useState(false);
    const [canResendCode, setCanResendCode] = useState(true);
    const [countdown, setCountdown] = useState(0);
    const [messageBox, setMessageBox] = useState({ type: '', text: '' });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (show) {
            setModalActive(true);
            let top = window.scrollY;
            bodyElement.style.position = "fixed";
            bodyElement.style.top = `-${top}px`;
            bodyElement.style.left = "0px";
            bodyElement.style.right = "0px";
            handleSendCode();
        } else {
            const scrollY = bodyElement.style.top;
            bodyElement.style.position = "";
            bodyElement.style.top = "";
            bodyElement.style.left = "";
            bodyElement.style.right = "";
            window.scrollTo(0, -parseInt(scrollY || "0"));
        }
    }, [show]);

    useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setTimeout(() => setCountdown(countdown - 1), 1000);
        } else if (!canResendCode) {
            setCanResendCode(true);
        }
        return () => clearTimeout(timer);
    }, [countdown, canResendCode]);

    const handleClose = () => {
        setModalActive(false);
        if (navigateToHome) {
            navigate('/');
        }
        callback && callback();
    };

    const handleSendCode = async () => {
        setIsSendingCode(true);
        setCanResendCode(false);
        setCountdown(60);
        try {
            await api.get("/profiles/send-phone-code");
            setMessageBox({ type: 'success', text: 'Código enviado com sucesso!' });
        } catch (err) {
            setMessageBox({ type: 'error', text: err.response ? err.response.data.message : "Erro ao enviar o código. Tente novamente." });
        } finally {
            setIsSendingCode(false);
        }
    };

    const handleValidateCode = async () => {
        setLoading(true);
        try {
            const response = await api.put(`/profiles/verify-phone-code/${code}`);
            setLoading(false);
            if (response.status === 204) {
                setMessageBox({ type: 'success', text: 'Código verificado com sucesso!' });
                dispatch(UserActions.refreshProfile());
                setTimeout(() => {
                    handleClose();
                }, 3000);
            }
        } catch (err) {
            setLoading(false);
            setMessageBox({ type: 'error', text: err.response ? err.response.data.message : "Erro ao validar o código. Tente novamente." });
        }
    };

    return (
        modalActive && (
            <Container>
                <Body>
                    <Flex width={isMobile ? "100%" : "550px"} margin="0" height="auto" alignSelf="stretch">
                        <Flex direction="column" alignItems="flex-start" padding={isMobile ? "29px 25px 30px 25px" : "40px"} boxSizing="border-box">
                            <Title>Verificação</Title>

                            <ModalText>Para validar sua conta, enviamos um código de verificação para o seu telefone {phone}. Por favor, insira o código recebido para concluir o processo de verificação.</ModalText>

                            <TextInput
                                type="number"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                maxLength={5}
                                mask="99999"
                                placeholder="Digite o código de 5 dígitos"
                            />

                            {messageBox.text && <MessageText type={messageBox.type}>{messageBox.text}</MessageText>}

                            <Flex direction={isMobile ? "column" : "row"} wrap="nowrap" margin="15px 0 0 0" width="100%">
                                <Button
                                    width={isMobile ? "100%" : "250px"}
                                    margin={isMobile ? "0 0 5px 0" : "0 5px 0 0"}
                                    dark
                                    filled
                                    value={isSendingCode ? <LoadingSpinner /> : `Reenviar código${countdown > 0 ? ` (${countdown})` : ''}`}
                                    onClick={handleSendCode}
                                    disabled={isSendingCode || !canResendCode}
                                />
                                <Button
                                    width={isMobile ? "100%" : "250px"}
                                    margin={isMobile ? "5px 0 0 0" : "0 0 0 5px"}
                                    pink
                                    filled
                                    value={loading ? <LoadingSpinner /> : "Validar código"}
                                    onClick={handleValidateCode}
                                    disabled={code.length !== 5 || loading}
                                />
                            </Flex>
                        </Flex>
                    </Flex>
                </Body>
            </Container>
        )
    );
};

const Container = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 5;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.7);
    padding: 2rem 0px;

    ${isMobileMediaQuery} {
        padding: 90px 0px;
        align-items: baseline;
        max-height: 100vh;
    }
`;

const Body = styled.div`
    position: relative;
    width: auto;
    height: auto;
    min-height: initial;
    max-height: 80vh;
    max-width: 100vw;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px 0 rgba(52, 52, 59, 0.15);
    padding: 0px;
    margin: auto;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    ${isMobileMediaQuery} {
        padding: 0px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        max-width: 90vw;
        max-width: 330px;
        max-height: initial;
        height: auto;
    }
`;

const Title = styled.h1`
    font-weight: normal;
    text-align: left;
    color: #34343b;
`;

const ModalText = styled.p`
    white-space: pre-line;
    margin: 0 auto 23px 0px;

    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;
`;

const MessageText = styled.div`
    width: 100%;
    box-sizing: border-box;
    background: ${({ type }) => (type === 'error' ? '#f8d7da' : '#d1e7dd')};
    border-left: 4px solid ${({ type }) => (type === 'error' ? '#f5c2c7' : '#badbcc')};
    color: ${({ type }) => (type === 'error' ? '#842029' : '#0f5132')};
    padding: 1rem;
    font-size: .875rem;
    text-shadow: 1px 1px 1px rgba(255, 255, 255, .6);
    margin-top: 15px;
`;



export default PhoneVerificationModal;
