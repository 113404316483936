export const generateUUID = () => {
    let random = [];
    for (let x = 0; x < 8; x++) {
        let r = Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
        random.push(r);
    }
    return (
        random[0] +
        random[1] +
        "-" +
        random[2] +
        "-" +
        random[3] +
        "-" +
        random[4] +
        "-" +
        random[5] +
        random[6] +
        random[7]
    );
};
