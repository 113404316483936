import React from "react";

import styled from "styled-components";
import { isMobileMediaQuery } from "../../helpers/styles";

import InfluencerItem from "../InfluencerItem";

const ProductsGrid = ({ influencers, handleClick }) => {
    return (
        <Container>
            {influencers &&
                influencers.map((influencer, index) => (
                    <InfluencerItem
                        key={`influencer_${index}`}
                        title={influencer.name}
                        instagram={influencer.instagram}
                        slug={influencer.slug}
                        image={influencer.image_url}
                    />
                ))}
        </Container>
    );
};

const Container = styled.div`
    margin-bottom: 40px;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 43px;
    width: 100%;

    ${isMobileMediaQuery} {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 10px;
        grid-row-gap: 20px;
    }
`;

export default ProductsGrid;