import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import api from "../../services/api";
import { ContentDelimiter, Flex, isMobile, isMobileMediaQuery } from "./../../helpers/styles";
import Header from "./../../components/Header";
import SectionTitle from "../../components/SectionTitle";
import Footer from "../../components/Footer";
import BrandsGrid from "../../components/BrandsGrid";
import BackgroundMarcas from "./../../assets/images/background-marcas.jpg";
import BackgroundMarcas2 from "./../../assets/images/background-marcas2.jpg";
import BackgroundMarcasMobile from "./../../assets/images/background-marcas-mobile.jpg";
import BackgroundMarcas2Mobile from "./../../assets/images/background-marcas2-mobile.jpg";
import ImageCriterio1 from "./../../assets/images/criterio-1.jpg";
import ImageCriterio2 from "./../../assets/images/criterio-2.jpg";
import ImageCriterio3 from "./../../assets/images/criterio-3.jpg";
import { capitalize } from "../../helpers";
import Button from "../../components/Button";

const Marcas = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [brands, setBrands] = useState(undefined);
    const [listAll, setListAll] = useState(false);
    const acceptedClothes = [
        "Bata",
        "Biquini",
        "Body",
        "Calça",
        "Calcinha (nova - com etiqueta)",
        "Camisa",
        "Camiseta",
        "Casaco",
        "Colete",
        "Conjuntos",
        "Cueca (nova - com etiqueta)",
        "Fantasia",
        "Jardineira",
        "Kimono",
        "Macacão",
        "Maiô",
        "Meias (novas - com etiqueta)",
        "Mijão",
        "Pijama",
        "Poncho",
        "Romper",
        "Roupão",
        "Saia",
        "Saída de Praia",
        "Shorts",
        "Sunga",
        "Terno",
        "Vestido",
    ];

    const acceptedShoes = [
        "Alpargatas",
        "Bota",
        "Chuteira",
        "Mocassim",
        "Pantufa",
        "Sandália",
        "Sapatilha",
        "Sapatinho",
        "Sapato",
        "Tamanco",
        "Tênis",
    ];

    const acceptedAccessories = [
        "Babador",
        "Bolsa",
        "Boné",
        "Canguru",
        "Capa de Chuva",
        "Chapéu",
        "Gorro",
        "Joelheira",
        "Luva",
        "Mantas",
        "Óculos",
        "Protetor",
        "Swaddler",
    ];

    const acceptedOjbects = [
        "Almofada Amamentação",
        "Almofada para Banho",
        "Amassador Papinha",
        "Aquecedor de Mamadeira",
        "Aspirador Nasal ( na embalagem) ",
        "Assento redutor de privada",
        "Babá Eletrônica",
        "Banheira",
        "Bebê Conforto",
        "Berço Portátil",
        "Bico de Mamadeira ( na embalagem)",
        "Bóia",
        "Bolsa maternidade",
        "Bolsa organizadora para carrinho",
        "Bolsa Térmica",
        "Bomba Extratora de Leite",
        "Cadeira para Alimentação",
        "Cadeira para Bicicleta",
        "Canguru",
        "Capa de chuva carrinho",
        "Capa para bebe conforto",
        "Capa para carrinho",
        "Capa para moises",
        "Carrinho",
        "Cercado",
        "Escoredor",
        "Espelho para carro",
        "Estação de Papinha",
        "Esterelizador de mamadeira",
        "Garrafa",
        "Grade Protetora",
        "Guarda chuva para carrinho",
        "Kit Unha",
        "Lancheira",
        "Mala materidade",
        "Mamadeira",
        "Máquina de fazer papinha",
        "Móbile",
        "Móbile berço",
        "Móbile carrinho",
        "Mochila",
        "Moises",
        "Monito de movimento",
        "Mosquiteiro",
        "Organizador de Carrinho",
        "Porta chupeta",
        "Porta copo carrinho",
        "Porta lenço humidecido",
        "Porta Treco",
        "Pote",
        "Prato",
        "Protetor de assento",
        "Protetor de Berço",
        "Rede",
        "Redutor de Assento",
        "Rolo Protetor",
        "Saco de Dormir",
        "Saco Esterilizador",
        "Skate para carrinho",
        "Sling",
        "Suporte Moisés",
        "Tapa Fraldas",
        "Tapete",
        "Trocador",
        "White Noise",
    ];

    const acceptedToys = [
        "Almofada Atividade",
        "Andador",
        "Bicicleta",
        "Mordedores",
        "Patinete",
        "Tapete de atividade",
        "brinquedos de madeira",
        "brinquedos musicais",
        "brinquedos educativos",
        "brinquedos de 0m a 12m",
    ];

    useEffect(() => {
        loadBrands();

        if (window.location.hash && document.querySelector(window.location.hash)) {
            setTimeout(() => {
                document.querySelector(window.location.hash).scrollIntoView({ behavior: "smooth", block: "start" });
            }, 100);
        }

        // eslint-disable-next-line
    }, []);

    const loadBrands = async () => {
        let response = await api.get("/menu?clearCache=true");

        if (response.status === 200) {
            setBrands(response.data.brands);
        } else {
            setBrands({});
        }
    };

    return (
        <>
            <Header
                navigationItems={[
                    {
                        label: "Como funciona?",
                        active: location.pathname === "/desapegue",
                        action: () => navigate("/desapegue"),
                    },
                    {
                        label: "O que vender?",
                        active: location.pathname === "/o-que-vender",
                        action: () => navigate("/o-que-vender"),
                    },
                    {
                        label: "Pagamentos",
                        active: location.pathname === "/pagamentos",
                        action: () => navigate("/pagamentos"),
                    },
                    {
                        label: "Quero Vender",
                        action: () => navigate("/quero-vender"),
                        secondary: true,
                        borderradius: true,
                    },
                ]}
            />

            <Container>
                <FullSection centered background={isMobile ? BackgroundMarcasMobile : BackgroundMarcas}>
                    <Flex width="386px" direction="column" margin="123px 449px 111px auto" alignItems="flex-end">
                        <h2>{isMobile ? "estilo de vida" : "desapegar também é um estilo de vida"}</h2>
                        <hr />
                        <p>
                            {isMobile ? (
                                "Quem passa para frente uma peça especial, passa também um grande exemplo."
                            ) : (
                                <>
                                    Quem passa para frente uma
                                    <br />
                                    peças especial, passa também
                                    <br />
                                    um grande exemplo.
                                </>
                            )}
                        </p>
                    </Flex>
                    <Flex width="351px" direction="column" margin="154px auto 111px 0" alignItems="flex-start">
                        <h2>O que vender?</h2>
                        <hr />
                        <p>
                            {isMobile ? (
                                "Circulamos peças desejadas e bem conservadas, que ainda têm muita história para contar! Desapegue já!"
                            ) : (
                                <>
                                    Circulamos peças desejadas e bem conservadas, que ainda têm muita história para
                                    contar! Saiba mais sobre os critérios de avaliação e marcas aceitas. Vem circular
                                    com a gente! <Link to="/quero-vender">Desapegue já!</Link>
                                </>
                            )}
                        </p>
                    </Flex>
                </FullSection>

                <ContentDelimiter>
                    <SectionTitle>critérios de aceitação das roupas</SectionTitle>

                    <Flex
                        direction={isMobile ? "column" : "row"}
                        wrap="flex-wrap"
                        margin={isMobile ? "0" : "0 0 60px 0"}
                        alignItems="flex-start"
                        justifyContent="space-around"
                    >
                        <TextColumn>
                            <img src={ImageCriterio1} alt="Criterio 1" />
                            <Flex direction="column">
                                <h2>O que posso vender?</h2>
                                <p>
                                    A Re Petit trabalha com roupas, acessórios, brinquedos, objetos e utensílios. Todos
                                    de marcas originais que prezam pela qualidade e estilo. Fique de olho se a peça que
                                    separou é uma delas! <a href="#produtos">Confira a lista completa</a>
                                    <br />
                                    <br />
                                    <strong>Atenção para os objetos grandes:</strong> Pedimos o envio de fotos para
                                    análise prévia.{" "}
                                    <a href="https://api.whatsapp.com/send?phone=5511976922404">Entre em contato</a> com
                                    a nossa equipe.
                                </p>
                            </Flex>
                        </TextColumn>
                        <TextColumn>
                            <img src={ImageCriterio2} alt="Criterio 2" />
                            <Flex direction="column">
                                <h2>
                                    Regras para roupas,
                                    <br />
                                    acessórios e calçados:
                                </h2>
                                <p>
                                    <strong>1.</strong> Só aceitamos peças bem conservadas e com a etiqueta original da
                                    marca.
                                    <br />
                                    <strong>2.</strong> Peças manchadas, amareladas, desfiadas, furadas, rasgadas ou com
                                    qualquer tipo de avaria não entram na seleção.
                                    <br />
                                    <strong>3.</strong> Peças de tecido animal ou em couro sintético, bem como roupa
                                    íntima e meias já usadas, não são aceitas.
                                    <br />
                                    <br />
                                    <strong>Olho na limpeza!</strong> As peças enviadas já devem estar lavadas e limpas.
                                    Atenção a sola dos calçados.
                                </p>
                            </Flex>
                        </TextColumn>
                        <TextColumn>
                            <img src={ImageCriterio3} alt="Criterio 3" />
                            <Flex direction="column">
                                <h2>Regras para brinquedos, livros e utensílios</h2>
                                <p>
                                    Todos os itens devem ser de marcas originais, estar em perfeito estado e limpos.
                                    <br />
                                    <br />
                                    <strong>Brinquedos:</strong> Aceitamos apenas brinquedos com todas as peças e
                                    funcionando.
                                    <br />
                                    <br />
                                    <strong>Livros:</strong> Livros infantis em perfeito estado.
                                    <br />
                                    <br />
                                    <strong>Utensílios:</strong> Chupetas, mamadeiras e utensílios de boca para bebê
                                    somente novos e na embalagem de origem.
                                </p>
                            </Flex>
                        </TextColumn>
                    </Flex>

                    <Flex
                        margin="48px auto 0 auto"
                        padding="15px 0 12px 0"
                        width="100%"
                        maxWidth="1222px"
                        background="#F9FAFA"
                    >
                        <QuestionText>
                            Ainda tem dúvidas? Entre em <a href="maimailto:equipe@repetit.com.br">Contato</a>.
                        </QuestionText>
                    </Flex>

                    <Flex>
                        <SectionTitle margin={isMobile ? "41px 0 38px 0" : null}>
                            Marcas com as quais trabalhamos
                        </SectionTitle>
                    </Flex>

                    <BrandsContainer id="marcas">
                        <BrandsGrid brands={brands} />
                    </BrandsContainer>

                    <Flex id="produtos">
                        <SectionTitle margin={isMobile ? "41px 0 38px 0" : null}>Produtos que aceitamos</SectionTitle>
                    </Flex>

                    <ProductsList>
                        <ul>
                            <li>
                                <b>Roupas</b>
                            </li>
                            {acceptedClothes.slice(0, listAll ? 999 : 15).map((item) => (
                                <li>{capitalize(item)}</li>
                            ))}
                            {!listAll && <li>...</li>}
                        </ul>
                        <ul>
                            <li>
                                <b>Sapatos</b>
                            </li>
                            {acceptedShoes.slice(0, listAll ? 999 : 15).map((item) => (
                                <li>{capitalize(item)}</li>
                            ))}
                            {!listAll && <li>...</li>}
                        </ul>
                        <ul>
                            <li>
                                <b>Acessórios</b>
                            </li>
                            {acceptedAccessories.slice(0, listAll ? 999 : 15).map((item) => (
                                <li>{capitalize(item)}</li>
                            ))}
                            {!listAll && <li>...</li>}
                        </ul>
                        <ul>
                            <li>
                                <b>Objetos e Utensílios</b>
                            </li>
                            {acceptedOjbects.slice(0, listAll ? 999 : 15).map((item) => (
                                <li>{capitalize(item)}</li>
                            ))}
                            {!listAll && <li>...</li>}
                        </ul>
                        <ul>
                            <li>
                                <b>Brinquedos</b>
                            </li>
                            {acceptedToys.slice(0, listAll ? 999 : 15).map((item) => (
                                <li>{capitalize(item)}</li>
                            ))}
                            {!listAll && <li>...</li>}
                        </ul>
                    </ProductsList>

                    <Button
                        margin="0 auto 65px auto"
                        dark
                        onClick={() => setListAll(!listAll)}
                        value={listAll ? "Mostrar menos" : "Mostrar mais"}
                    />
                </ContentDelimiter>

                <FullSection background={isMobile ? BackgroundMarcas2Mobile : BackgroundMarcas2}>
                    <Flex width="100%" maxWidth="1222px">
                        <Flex
                            maxWidth={isMobile ? "calc(100% - 30px)" : "367px"}
                            direction="column"
                            margin="80px 0px 66px auto"
                            alignItems="flex-start"
                        >
                            <h2>
                                fazendo a<br />
                                alegria das
                                <br />
                                mães e filhos
                            </h2>
                            <hr />
                            <p>
                                Aqui você pode vender copos infantis, cadeirinhas, slings, berços, brinquedos, carrinhos
                                de bebê e muito mais. Tudo o que é útil e está pronto para ser aproveitado! Escolha com
                                carinho o que quer desapegar e deixe o resto com a gente.
                                <br />
                                <br />
                                Ainda tem dúvidas? Saiba mais em <a href="/faq">Perguntas Frequentes</a> ou entre em
                                contato: <a href="mailto:equipe@repetit.com.br">equipe@repetit.com.br</a>
                            </p>
                        </Flex>
                    </Flex>
                </FullSection>
            </Container>

            <Footer />
        </>
    );
};

const Container = styled.div`
    width: 100vw;
`;

const TextColumn = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 100%;
    box-sizing: border-box;
    :not(:last-child) {
        margin-right: 35px;
    }

    & > img {
        width: 384px;
        height: 230px;
        object-fit: cover;
        margin-bottom: 26px;
        object-fit: cover;

        ${isMobileMediaQuery} {
            width: 100%;
            max-width: 100%;
            height: 200px;
        }
    }
    & > ${Flex}> h2 {
        margin: 0;
        margin-bottom: 20px;
        width: 100%;
        font-family: "DMSerifDisplay";
        font-size: 32px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #34343b;
    }
    & > ${Flex}> p {
        margin: 0;
        font-family: "Graphik";
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        text-align: left;
        color: #676777;

        strong {
            font-weight: bold;
            color: #f37f8a;
        }

        a {
            color: #676777;
        }

        a:not(:last-child) {
            color: #34343c;
            text-decoration: none;
            font-weight: bold;
        }
    }

    ${isMobileMediaQuery} {
        :not(:last-child) {
            margin-right: 0px;
            margin-bottom: 25px;
        }
        & > ${Flex} {
            padding: 0 20px;
        }
    }
`;

const QuestionText = styled.p`
    margin: 0;
    font-size: 15px;
    line-height: 1.4;
    text-align: center;
    color: #676778;

    a {
        color: #676778;
    }
`;

const BrandsContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 700px;
    background-color: #f9fafa;
    box-shadow: 0px 1px 2px 0px rgba(52, 52, 59, 0.15);

    ${isMobileMediaQuery} {
        min-height: 600px;
        margin-bottom: 0px;
    }
`;

const FullSection = styled.div`
    background-image: url(${(props) => props.background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 100vw;
    flex: 1;
    min-height: 430px;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    p a {
        font-weight: bold;
        color: #ffffff;
    }

    &:last-of-type > div {
        h2 {
            font-family: "SackersGothicStd";
            font-size: 29px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.19;
            letter-spacing: normal;
            text-transform: uppercase;
            text-align: left;
            color: #34343b;
            span {
                color: #fff;
            }
        }
        hr {
            margin: 0;
            margin-top: 35px;
            margin-bottom: 38px;
            width: 70px;
            margin: 31px 0 37px 0;
            border: none;
            border-top: 5px solid #fff;
        }
        p:not(:last-of-type) {
            font-family: "SackersGothicStd";
            font-size: 16px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            text-transform: uppercase;
            line-height: 2;
            letter-spacing: normal;
            text-align: left;
            color: #34343b;
        }
        p:last-of-type,
        a {
            margin: 0;
            margin-top: 35px;
            font-family: "Graphik";
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            text-align: left;
            color: #ffffff;
        }
    }

    &:first-of-type > div {
        h2 {
            font-family: "SackersGothicStd";
            font-size: 29px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            text-transform: uppercase;
            line-height: 1.05;
            letter-spacing: -0.58px;
            color: #34343b;
        }
        hr {
            width: 70px;
            margin: 31px 0 37px 0;
            border: none;
            border-top: 5px solid #fff;
        }
        p {
            font-family: "Graphik";
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.56;
            letter-spacing: normal;
            text-align: right;
            color: #ffffff;
        }
    }

    ${(props) =>
        props.centered
            ? `
        & > div:nth-child(1) {
            h2,
            p {
                margin: 0;
                padding: 0;
                margin-left: auto;
                text-align: right;
            }
        }

        & > div:nth-child(2) {
            h2,
            p {
                margin: 0;
                padding: 0;
                margin-right: auto;
                text-align: left;
            }
        }

        ${isMobileMediaQuery} {             
            flex-direction: column;
            justify-content: space-between;
            min-height: 620px;

            & > div:nth-child(1) {
                margin: 0;
                padding: 0;
                margin: 35px auto 300px auto;
                max-width: calc(100% - 70px);

                h2 {
                    font-size: 20px;
                }
                p {
                    font-size: 15px;
                    white-space: pre-line;
                    word-break: break-word;
                }
                h2, p {
                    margin: 0 auto;
                    text-align: center;
                }
                hr {
                    margin: 20px auto;
                }
            }

            & > div:nth-child(2) {
                margin: 0;
                padding: 0;
                margin: 0 auto 35px auto;
                max-width: calc(100% - 70px);

                h2 {
                    font-size: 20px;
                }
                p {
                    font-size: 15px;
                    white-space: pre-line;
                    word-break: break-word;
                }
                h2, p {
                    margin: 0 auto;
                    text-align: center;
                }
                hr {
                    margin: 20px auto;
                }
            }
            
        }
    `
            : `
        & > div {
            h2,
            p {
                margin: 0;
                padding: 0;
                margin-right: auto;
                text-align: left;
            }
        }        
    `}

    ${isMobileMediaQuery} {
        &:last-of-type {
            display: flex;
            flex-direction: column;
            min-height: 730px;
            padding: 0px;

            & > div > div {
                margin: 40px auto 0px auto;
                padding: 0px;
                justify-content: flex-start;
                align-items: center;
            }

            & > div > div > h2 {
                font-size: 24px;
                text-align: center;
                white-space: pre-line;
                word-break: break-word;
                max-width: 80%;
                margin-left: auto;
                margin-right: auto;
            }

            & > div > div > p {
                font-size: 16px !important;
                text-align: center !important;
                white-space: pre-line;
                word-break: break-word;

                margin-left: auto;
                margin-right: auto;
            }

            & > div > div > p:last-of-type,
            & > div > div > p:last-of-type a {
                font-size: 15px !important;
                margin-bottom: 360px !important;
            }
        }
    }
`;

const ProductsList = styled(Flex)`
    margin-bottom: 1rem;
    color: #34343b;
    align-items: flex-start;
    justify-content: space-between;
    line-height: 1.5;

    li:first-child {
        list-style: none;
        margin-bottom: 1rem;
        font-size: 18px;
    }

    ${isMobileMediaQuery} {
        flex-direction: column;
        justify-content: flex-start;
    }
`;

export default Marcas;
