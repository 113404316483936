import ReduxThunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers";

const initialState = () => {
    let state = {};

    if (localStorage.getItem("persistedState"))
        try {
            state = JSON.parse(localStorage.getItem("persistedState"));
        } catch (err) {}

    return state;
};

const store = createStore(
    reducers,
    initialState(),
    applyMiddleware(ReduxThunk)
);

store.subscribe(() => {
    let state = { ...store.getState() };

    delete state.ui;

    localStorage.setItem("persistedState", JSON.stringify(state));
});

export default store;
