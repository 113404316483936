import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import * as LoginModalActions from "../../redux/actions/loginModal";

import ButtonLink from "../ButtonLink";
import { isMobileMediaQuery } from "../../helpers/styles";

function SigninLink() {
    const dispatch = useDispatch();

    const handleShowLogin = () => {
        dispatch(LoginModalActions.show(window.location.pathname));
    };

    return <Btn onClick={handleShowLogin} value="Já tem uma conta? Entrar" />;
}

const Btn = styled(ButtonLink)`
    color: #7ca8af;
    margin-left: 16px;

    ${isMobileMediaQuery} {
        text-align: left;
        margin-left: 0;
        margin-top: 12px;
        line-height: 1.5;
        margin-right: auto;
    }
`;

export default SigninLink;
