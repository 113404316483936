import React from "react";
import styled from "styled-components";
import { isMobileMediaQuery } from "../../helpers/styles";
import { useNavigate } from "react-router-dom";

const ProductItem = ({ slug, image, ...props }) => {
    const navigate = useNavigate();
    return (
        <Container>
            <Image
                onClick={() => {
                    navigate(`/loja/${slug}`);
                }}
                src={`${image}?w=200&bg=FFFFFF&auto=format,compress`}
            ></Image>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items: center;
`;

const Image = styled.img`
    object-fit: cover;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    border-radius: 16px;

    width: 200px;
    height: auto;

    cursor: pointer;

    ${isMobileMediaQuery} {
        object-fit: contain;
        width: 100%;
        max-width: 180px;

        height: auto;
    }
`;

export default ProductItem;
