import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import moment from "moment";
import api, { baseURL } from "../../services/api";
import { Flex, isMobile, isMobileMediaQuery } from "./../../helpers/styles";
import * as CartActions from "../../redux/actions/cart";
import Button from "../../components/Button";
import SectionTitle from "../../components/SectionTitle";
import HorizontalProductItem from "../../components/HorizontalProductItem";
import Ilustra1 from "../../assets/images/account-ilustra1.png";
import Ilustra2 from "../../assets/images/account-ilustra2.png";
import Ilustra3 from "../../assets/images/account-ilustra3.png";
import BoxIcon from "../../assets/images/box-icon.png";
import LoadingSpinner from "../../components/LoadingSpinner";
import PromoModal from "../../components/PromoModal";

const MeuBox = ({ item_id, history, setModal }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const box_status = {
        pading: "Pendente",
        sent: "Enviado",
        replied: "Respondido",
        checked: "Conferido",
        rejected: "Rejeitado",
        sold: "Finalizado",
        lost: "Perdido",
        sold_without_checked: "Pago",
    };
    const user = useSelector((state) => state.user) || {};
    const products = useSelector((state) => state.cart.products);
    const main_address = (user.user_addresses && user.user_addresses[0]) || {};
    const [pageState, setPageState] = useState("home");
    const [boxes, setBoxes] = useState(undefined);
    const [currentBox, setCurrentBox] = useState(undefined);
    const [values, setValues] = useState({});
    const [deliveryResponse, setDeliveryResponse] = useState(undefined);
    const [showPromoModal, setShowPromoModal] = useState(false);
    const [items, setItems] = useState(undefined);

    useEffect(() => {
        loadBoxes();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0 });

        // eslint-disable-next-line
    }, [pageState]);

    useEffect(() => {
        if (location && history.pathname === "/minha-conta/box" && boxes && boxes.items.length > 0 && !item_id) {
            handleListBoxes();
        }

        // eslint-disable-next-line
    }, [location && history.pathname]);

    useEffect(() => {
        if (boxes && boxes.items && boxes.items.length > 0) {
            if (item_id && boxes.items.findIndex((item) => item.id.toString() === item_id.toString()) > -1) {
                handleShowBox(boxes.items.findIndex((item) => item.id.toString() === item_id.toString()));
            }
        }

        if (boxes && currentBox !== undefined && boxes.items[currentBox] && boxes.items[currentBox].items) {
            let newValues = { ...values };

            boxes.items[currentBox].items.forEach((item) => {
                if (products.find((product) => product.id === item.id)) {
                    newValues[item.id] = true;
                }
            });

            setValues((prevState) => {
                return { ...prevState, ...newValues };
            });

            calculateDelivery(main_address && main_address.zip);
        }

        // eslint-disable-next-line
    }, [boxes, currentBox, products]);

    const loadBoxes = async () => {
        let response = await api.get(`/profiles/boxes`);

        if (response.status === 200) {
            setBoxes({
                ...response.data,
            });

            if (response.data.items.length > 0 && !item_id) {
                handleListBoxes();
            }
        } else {
            setBoxes({
                items: [],
                pagination: {},
            });
        }
    };

    const loadItems = async () => {
        setItems(null);

        const response = await api.get(`/profiles/entries/items`).catch((err) => err.response);

        if (response.status === 200) {
            setItems({
                ...response.data,
            });
        } else {
            setItems({
                items: [],
                pagination: {},
            });
        }
    };

    const handleLoadMoreBoxes = async () => {
        let response = await api.get(boxes.pagination.next_page_path.replace("/v1/", ""));

        const scrollY = window.scrollY;

        if (response.status === 200) {
            setBoxes({
                ...boxes,
                items: [...boxes.items, ...response.data.items],
                pagination: response.data.pagination,
                scrollY,
            });
        }
    };

    const handleListBoxes = () => {
        setCurrentBox(undefined);
        setPageState("list_boxes");
        navigate("/minha-conta/box");
    };

    const handleShowBox = async (box_index) => {
        if (!boxes.items[box_index] || currentBox === box_index) return;

        if (window.location.pathname !== `/minha-conta/box/${boxes.items[box_index].id}`)
            navigate(`/minha-conta/box/${boxes.items[box_index].id}`);

        dispatch(CartActions.clear());

        setCurrentBox(box_index);

        let response = await api.get(`/profiles/boxes/${boxes.items[box_index].id}/items`).catch((err) => err.response);

        let newBoxes = { ...boxes };

        if (response && response.status === 200) {
            newBoxes.items[box_index] = {
                ...newBoxes.items[box_index],
                ...response.data,
            };
        } else {
            newBoxes.items[box_index] = {
                items: [],
                pagination: {},
            };
        }

        setBoxes(newBoxes);

        setPageState("show_box");
    };

    const handleSubmitRequest = () => {
        if (products.filter((item) => !item.box_type).length === 0) {
            const currentBoxItem = boxes.items[currentBox];

            dispatch(CartActions.addProduct({ ...currentBoxItem, box_item: true }));
        }

        navigate("/checkout");
    };

    const handleSelectBoxItem = (e) => {
        let {
            target: { name, value, checked, type },
        } = e;

        let newValues = {
            ...values,
        };

        if (name !== undefined) {
            if (value !== undefined && (type !== "checkbox" || type === "radio")) {
                newValues = {
                    ...newValues,
                    [name]: value,
                };
            } else if (checked !== undefined) {
                newValues = {
                    ...newValues,
                    [name]: checked,
                };
            }
        }

        const selectedItem = boxes.items[currentBox].items.find((item) => item.id.toString() === name.toString());

        const currentBoxItem = boxes.items[currentBox];

        if (newValues[name] === true && selectedItem) {
            dispatch(CartActions.addProduct({ ...currentBoxItem, box_item: true }));
            dispatch(CartActions.addProduct({ ...selectedItem, box_item: true }));
        } else if (newValues[name] !== true && selectedItem) {
            dispatch(CartActions.removeProduct(name));
        }

        setValues((prevState) => {
            return { ...prevState, ...newValues };
        });
    };

    const calculateDelivery = async () => {
        let zip_code = main_address && main_address.zip;

        if (!zip_code.replace("-", "").match(/^[0-9]{5,5}[0-9]{3,3}$/g)) return;

        zip_code = zip_code.replace("-", "");

        let response = await api
            .post(
                "/delivery/box_calculator",
                {
                    zip_code,
                    box_id: products.find((item) => item.box_type) && products.find((item) => item.box_type).id,
                    item_ids: products.reduce((acc, curr) => {
                        if (curr.box_type) return acc;
                        else return [...acc, curr.id];
                    }, []),
                },
                {
                    baseURL: baseURL.replace("v1", "v2"),
                }
            )
            .catch((err) => err.response);

        if (response && response.status === 200) {
            setDeliveryResponse(response.data);
        } else {
            setDeliveryResponse(response.data);
        }
    };

    let subtotal =
        currentBox !== undefined && boxes.items[currentBox] && boxes.items[currentBox].items
            ? parseFloat(
                  boxes.items[currentBox].subtotal ||
                      boxes.items[currentBox].items.reduce((acc, curr) => {
                          if (values[curr.id] === true) return acc + curr.price_label;
                          else return acc;
                      }, 0)
              )
            : 0;

    return (
        <>
            {["home", undefined].includes(pageState) && boxes === undefined && (
                <>
                    <SectionTitle margin="0 0 36px 0">Carregando</SectionTitle>
                    <LoadingSpinner dark size={15} />
                </>
            )}

            {boxes && boxes.items.length === 0 && pageState === "home" && (
                <>
                    <SectionTitle margin="0 0 36px 0">Faça o pedido do seu box</SectionTitle>

                    <Flex justifyContent="space-between" width="100%" margin="0 0 40px 0">
                        <InfoCard>
                            <Flex desktop>
                                <h1>
                                    curadoria <br /> personalizada
                                </h1>
                            </Flex>

                            <img src={Ilustra1} alt="Ilustração 1" />
                            <hr />

                            <Flex alignItems="flex-start" direction="column">
                                <Flex mobile>
                                    <h1>
                                        curadoria <br /> personalizada
                                    </h1>
                                </Flex>
                                <p>
                                    Roupas, acessórios e utensílios seminovos. Tudo de acordo com a numeração,
                                    preferências e estilo do seu filho!
                                </p>
                            </Flex>
                        </InfoCard>

                        <InfoCard>
                            <Flex desktop>
                                <h1>
                                    quatro tipos de box, <br />
                                    um para cada estilo!
                                </h1>
                            </Flex>

                            <img src={Ilustra2} alt="Ilustração 2" />
                            <hr />

                            <Flex alignItems="flex-start" direction="column">
                                <Flex mobile>
                                    <h1>
                                        quatro tipos de box, <br />
                                        um para cada estilo!
                                    </h1>
                                </Flex>
                                <p>
                                    Bem-vindo, Farra Boa, Na Estica e Descolado. Vem descobrir todos e esoclher aquele
                                    que combina com seu filho.
                                </p>
                            </Flex>
                        </InfoCard>

                        <InfoCard>
                            <Flex desktop>
                                <h1>
                                    pedir o box é<br />
                                    prático e divertido
                                </h1>
                            </Flex>

                            <img src={Ilustra3} alt="Ilustração 3" />
                            <hr />

                            <Flex alignItems="flex-start" direction="column">
                                <Flex mobile>
                                    <h1>
                                        pedir o box é<br />
                                        prático e divertido
                                    </h1>
                                </Flex>
                                <p>É só responder ao QUIZ e em até 7 dias úteis o BOX chega na sua casa!</p>
                            </Flex>
                        </InfoCard>
                    </Flex>

                    <Flex
                        width={isMobile ? "100%" : undefined}
                        direction={isMobile ? "column" : "row"}
                        margin="0 auto 34px auto"
                        boxSizing="border-box"
                    >
                        <Button
                            width={isMobile ? "320px" : "280px"}
                            margin={isMobile ? "0 20px" : "0 auto 0 0"}
                            dark
                            filled
                            value="começar seu quiz já"
                            onClick={() => navigate("/quiz/informacoes")}
                        />
                    </Flex>

                    <FooterText>
                        Fique de olho: o serviço de BOX está disponível apenas para a cidade de São Paulo e algumas
                        cidades da Grande São Paulo (somente Santo André, São Bernardo do Campo, São Caetano,
                        Carapicuíba, Diadema, Mauá, Barueri, Osasco e Guarulhos).
                    </FooterText>
                </>
            )}

            {pageState === "list_boxes" && (
                <>
                    <SectionTitle margin="0 0 36px 0">Meus Boxes</SectionTitle>

                    <BoxesGrid>
                        {boxes.items &&
                            boxes.items.map((box, index) => (
                                <BoxCard
                                    key={box.id}
                                    onClick={() => handleShowBox(index)}
                                    disabled={box.status === "replied"}
                                >
                                    <Flex desktop>
                                        <h1>Box {box.id}</h1>
                                    </Flex>
                                    <img src={BoxIcon} alt="Lote" />
                                    <hr />

                                    <Flex alignItems="flex-start" direction="column">
                                        <Flex mobile>
                                            <h1>Box {box.id}</h1>
                                        </Flex>

                                        <BoxName>{box.customer_child.name.toLowerCase()}</BoxName>
                                        <p>
                                            Início em: {moment(box.created_at).format("DD/MM/YYYY")} <br />
                                            Situação: {box_status[box.status] || box.status}
                                        </p>
                                    </Flex>
                                </BoxCard>
                            ))}
                    </BoxesGrid>

                    <Flex margin="0 auto 34px auto">
                        {boxes.pagination && boxes.pagination.next_page_path && (
                            <Button
                                width="280px"
                                margin="0 35px 0 auto"
                                filled
                                dark
                                value="carregar mais boxes"
                                onClick={handleLoadMoreBoxes}
                            />
                        )}
                    </Flex>
                </>
            )}

            {pageState === "show_box" && currentBox !== undefined && (
                <>
                    <SectionTitle margin="0 0 31px 0">Resumo do seu box</SectionTitle>

                    <Text>
                        <span>
                            {boxes.items[currentBox].box_type.name} com o {boxes.items[currentBox].customer_child.name}
                        </span>
                        <br />
                        Veja tudo o que selecionamos especialmente para {boxes.items[currentBox].customer_child.name}.
                        <br />
                        Prove as peças com tranquilidade, escolha as que gostou e finalize sua compra
                        <br />
                        aqui mesmo. Esperamos que vocês gostem e se divirtam muito
                    </Text>

                    <Separator />

                    <Flex
                        width="100%"
                        justifyContent="flex-start"
                        direction="column"
                        padding={isMobile ? "0px 20px" : undefined}
                        boxSizing="border-box"
                    >
                        {boxes.items[currentBox] &&
                            boxes.items[currentBox].items.map((item, index) => (
                                <HorizontalProductItem
                                    key={index}
                                    showId
                                    item={{
                                        ...item,
                                        status:
                                            item.box_item && item.box_item.length > 0
                                                ? item.box_item[0].status
                                                : item.status,
                                        price: parseFloat(item.price_label),
                                    }}
                                    box
                                    sold={
                                        (item.box_item && item.box_item.length > 0
                                            ? item.box_item[0].status
                                            : item.status) !== "pending"
                                    }
                                    defaultChecked={values[item.id] === true}
                                    checkboxCallback={handleSelectBoxItem}
                                />
                            ))}
                    </Flex>

                    {boxes.items[currentBox].status !== "sold" && (
                        <>
                            <GrayBox>leve o box todo e ganhe 10% de desconto no boleto</GrayBox>

                            <Flex
                                widht="100%"
                                margin={isMobile ? "49px 0 0 0" : "49px auto 0px auto"}
                                padding={isMobile ? "0px 20px" : undefined}
                                maxWidth="750px"
                                boxSizing="border-box"
                            >
                                <Title>sabe como devolver seu box?</Title>

                                <Text>
                                    Coloque as peças que não for comprar dentro da sacola envelope que veio junto com a
                                    caixa. Lacre e envie de volta no BOX original. Pronto!
                                    <br />
                                    <br />
                                    <strong>APROVEITE:</strong>
                                    <br />
                                    <br />
                                    • Frete grátis em compras acima de 500,00 na cidade de São Paulo.
                                    <br />
                                    • Frete grátis acima de R$600,00 nas cidades da Grande SP.
                                    <br />
                                    • Pagamento em 2x sem juros acima de R$1.000 e 3x sem juros acima de R$2.000.
                                    <br />
                                    • PIX 3% de desconto.
                                    <br />
                                    <br />
                                    <FooterText textAlign={isMobile ? "center" : undefined}>
                                        *O serviço de BOX está disponível apenas para a cidade de São Paulo e em algumas
                                        cidades na Grande São Paulo (Somente Santo André, São Bernardo do Campo, São
                                        Caetano, Carapicuíba, Diadema, Mauá, Barueri, Osasco e Guarulhos).
                                    </FooterText>
                                </Text>
                            </Flex>
                        </>
                    )}

                    {boxes.items[currentBox].status !== "rejected" && (
                        <Flex
                            margin="45px auto 0 auto"
                            width={isMobile ? "100%" : "909px"}
                            boxShadow="0px 1px 2px 0 rgba(52, 52, 59, 0.15)"
                        >
                            <ResumeTitle>Resumo do Pedido</ResumeTitle>
                            <PriceInfo>
                                <span>subtotal</span>
                                <span>
                                    R${" "}
                                    {subtotal.toLocaleString("pt-br", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </span>
                            </PriceInfo>

                            {deliveryResponse &&
                                deliveryResponse.services &&
                                deliveryResponse.services[0].amount !== undefined && (
                                    <PriceInfo>
                                        <span>FRETE</span>
                                        {deliveryResponse.services[0].amount === 0 ? (
                                            <FreeTag>Grátis</FreeTag>
                                        ) : (
                                            <span>
                                                R${" "}
                                                {parseFloat(deliveryResponse.services[0].amount).toLocaleString(
                                                    "pt-br",
                                                    {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    }
                                                )}
                                            </span>
                                        )}
                                    </PriceInfo>
                                )}

                            {deliveryResponse &&
                                deliveryResponse.services &&
                                deliveryResponse.services[0].amount !== undefined && (
                                    <PriceInfo>
                                        <span>Total</span>
                                        <span>
                                            R${" "}
                                            {(
                                                subtotal + parseFloat(deliveryResponse.services[0].amount)
                                            ).toLocaleString("pt-br", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </span>
                                    </PriceInfo>
                                )}
                        </Flex>
                    )}

                    <Flex
                        width={isMobile ? "100%" : undefined}
                        direction={isMobile ? "column" : "row"}
                        margin="41px auto 34px auto"
                        boxSizing="border-box"
                    >
                        {(boxes.items[currentBox].status === "pending" ||
                            boxes.items[currentBox].status === "sent") && (
                            <Button
                                width={isMobile ? "320px" : "280px"}
                                margin={isMobile ? "0 20px 20px 20px" : "0 35px 0 auto"}
                                filled
                                dark
                                value="finalizar pedido"
                                onClick={handleSubmitRequest}
                            />
                        )}

                        <Button
                            width={isMobile ? "320px" : "280px"}
                            margin={isMobile ? "0 20px" : "0 auto 0 0"}
                            dark
                            value="voltar"
                            onClick={handleListBoxes}
                        />
                    </Flex>
                </>
            )}

            {items?.items && values.promotedItems && (
                <PromoModal
                    items={items.items}
                    promotedItems={values.promotedItems}
                    show={showPromoModal}
                    setModal={setModal}
                    callback={(status) => setShowPromoModal(status)}
                    successCallback={() => loadItems()}
                />
            )}
        </>
    );
};

const InfoCard = styled(Flex)`
    width: 279px;
    height: 430px;
    padding: 20px 26px 31px 25px;
    box-shadow: 0px 1px 3px 0 rgba(52, 52, 59, 0.1);
    justify-content: flex-start;
    flex-direction: column;
    background-color: #fbfcfc;
    box-sizing: border-box;
    flex-wrap: nowrap;

    h1 {
        text-transform: uppercase;
        margin: 0px auto 29px auto;

        font-family: "SackersGothicStd";
        font-size: 11.5px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.1;
        letter-spacing: 2.3px;
        text-align: center;
        color: #34343b;
    }

    img {
        height: auto;
        max-height: 200px;
        object-fit: contain;
    }

    hr {
        width: 79px;
        height: 1px;
        min-height: 1px;
        margin: 20px auto 24px auto;
        border: none;
        background-color: #313139;
    }

    p {
        margin: 0px;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: center;
        color: #676777;
    }

    ${isMobileMediaQuery} {
        width: 100%;
        height: auto;
        min-height: 170px;
        flex-direction: row;
        margin: 0 20px;

        :not(:last-of-type) {
            margin-bottom: 20px;
        }

        h1 {
            font-size: 11.5px;
            text-align: left;
            margin-bottom: 16px;
        }

        img {
            width: 81px;
            height: 119px;
            object-fit: contain;
        }

        hr {
            width: 1px;
            min-width: 1px;
            height: 100%;
            min-height: 79px;
            margin: auto 20px auto 26px;
        }

        p {
            font-size: 13px;
            text-align: left;
        }
    }
`;

const FooterText = styled.p`
    margin: 0;

    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
    color: #34343b;

    ${isMobileMediaQuery} {
        margin-left: 20px;
        margin-right: auto;
        max-width: 80%;

        text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
    }
`;

const Text = styled.div`
    margin: 0;

    font-size: 15px;
    line-height: 1.4;
    text-align: center;
    color: #676777;

    span {
        font-weight: 600;
        color: #e4858c;
    }

    strong {
        font-weight: 600;
    }

    ${isMobileMediaQuery} {
        max-width: 315px;
    }
`;

const Separator = styled.hr`
    background-color: #cacad1;
    height: 1px;
    width: 100%;
    border: none;
    margin: 56px 0 30px 0;
`;

const GrayBox = styled.div`
    background-color: #e5e5e8;
    padding: 16px 0 14px 0;
    text-transform: uppercase;
    width: 100%;

    font-family: "SackersGothicStd";
    font-size: 11.5px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: 2.3px;
    text-align: center;
    color: #34343b;
    box-sizing: border-box;

    ${isMobileMediaQuery} {
        padding: 16px 20px 14px 20px;
    }
`;

const Title = styled.h1`
    margin: 0 0 21px 0;
    text-transform: uppercase;

    font-family: "SackersGothicStd";
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 2.8px;
    text-align: center;
    color: #34343b;
`;

const ResumeTitle = styled(Flex)`
    width: calc(100% + 60px);
    background-color: #f7f9f9;
    text-transform: uppercase;
    padding: 23px 0px;

    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: 2.2px;
    text-align: center;
    color: #34343b;

    :not(:last-of-type) {
        border-bottom: 1px solid #fff;
    }
`;

const PriceInfo = styled(Flex)`
    width: 100%;
    background-color: #f7f9f9;
    justify-content: space-between;
    text-transform: uppercase;
    padding: 23px 30px;

    font-family: "Graphik";
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    :not(:last-of-type) {
        border-bottom: 1px solid #fff;
    }
`;

const BoxesGrid = styled.div`
    width: 100%;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 36px;
    grid-row-gap: 36px;
    margin-bottom: 57px;

    ${isMobileMediaQuery} {
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 0;
        grid-row-gap: 21px;
    }
`;

const BoxCard = styled(Flex)`
    position: relative;
    width: 279px;
    height: 380px;
    padding: 0px;
    box-shadow: 0px 1px 3px 0 rgba(52, 52, 59, 0.1);
    justify-content: flex-start;
    flex-direction: column;
    background-color: #fbfcfc;
    box-sizing: border-box;
    flex-wrap: nowrap;

    label:first-child {
        position: absolute;
        top: 8px;
        right: 8px;
    }

    h1 {
        text-transform: capitalize;
        margin: 50px auto 40px auto;

        font-family: "DMSerifDisplay";
        font-size: 36px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 0.67;
        letter-spacing: normal;
        text-align: center;
        color: #34343b;
    }

    img {
        width: 140px;
        height: 129px;
        object-fit: contain;
    }

    hr {
        width: 79px;
        height: 1px;
        min-height: 1px;
        margin: 0px auto;
        border: none;
        background-color: #313139;

        margin-top: 40px;
        margin-bottom: 15px;
    }

    p {
        margin: 0px;
        font-family: "Graphik";
        font-size: 15px;
        line-height: 1.07;
        text-align: center;
        color: #676777;
    }

    ${isMobileMediaQuery} {
        width: 100%;
        max-width: 320px;
        height: auto;
        min-height: 170px;
        flex-direction: row;
        margin: 0 auto;
        padding: 42px 20px;

        h1 {
            margin: 0;
            font-size: 28px;
            line-height: 20px;
            text-align: left;
            margin-bottom: 13px;
        }

        h2 {
            margin: 0;
            font-size: 19px;
            line-height: 14px;
            text-align: left;
            margin-bottom: 9px;
        }

        img {
            width: 90px;
            height: 83px;
            object-fit: contain;
        }

        hr {
            width: 1px;
            min-width: 1px;
            min-height: 79px;
            margin: auto 20px auto 26px;
        }

        p {
            font-size: 13px;
            text-align: left;
        }
    }

    ${(props) =>
        props.disabled &&
        css`
            pointer-events: none;
            opacity: 0.75;
        `}
`;

const BoxName = styled.h2`
    margin: 0px auto 12px auto;
    text-transform: capitalize;

    font-family: "DMSerifDisplay";
    font-size: 19px;
    font-style: italic;
    font-weight: normal;
    line-height: 1.11;
    text-align: center;
    color: #34343b;
`;

const FreeTag = styled.span`
    text-transform: capitalize;

    font-family: "Graphik";
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: right;
    color: #79c992;
`;

export default MeuBox;
