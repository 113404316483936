import {
    ADD_PRODUCT,
    REMOVE_PRODUCT,
    CLEAR_CART,
    PUT_PRODUCTS,
} from "../actions/cart";

const initialState = {
    products: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ADD_PRODUCT:
            const storageState = JSON.parse(localStorage.getItem("persistedState"));
            let currentProducts = '';
            if(storageState){
                currentProducts = storageState.cart.products ? storageState.cart.products : state.products || [];
            }else{
                currentProducts = state.products || [];
            }
           

            if (
                action.payload.product.box_item === true &&
                !state.products?.find((item) => item.box_type)
            ) {
                currentProducts = [];
            } else if (
                action.payload.product.box_item !== true &&
                state.products?.find((item) => item.box_type)
            ) {
                currentProducts = [];
            }

            if (
                !state.products?.find(
                    (item) => item.id === action.payload.product.id
                )
            )
                return {
                    ...state,

                    products: [...currentProducts, action.payload.product],
                };
            else {
                return state;
            }

        case REMOVE_PRODUCT:
            return {
                ...state,
                products: state.products.filter((item) =>
                    typeof action.payload.product_id === "object"
                        ? !action.payload.product_id.includes(
                              item.id.toString()
                          ) && !action.payload.product_id.includes(item.id)
                        : item.id.toString() !==
                          action.payload.product_id.toString()
                ),
            };

        case PUT_PRODUCTS:
            if (action.payload.products) {
                return { ...state, products: action.payload.products };
            }
            break;
        case CLEAR_CART:
            return initialState;

        default:
            return state;
    }
};
