import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Flex, isMobile, isMobileMediaQuery } from "../../helpers/styles";

import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";

import Button from "../Button";

const Modal = ({ image, title, description, show, button, buttonAction, ...props }) => {
    const bodyElement = document.querySelector("body");

    const [modalActive, setModalActive] = useState(false);

    useEffect(() => {
        let top = window.scrollY;

        bodyElement.style.position = "fixed";
        bodyElement.style.top = `-${top}px`;
        bodyElement.style.left = "0px";
        bodyElement.style.right = "0px";

        return () => {
            const scrollY = bodyElement.style.top;

            bodyElement.style.position = "";
            bodyElement.style.top = "";
            bodyElement.style.left = "";
            bodyElement.style.right = "";

            window.scrollTo(0, -parseInt(scrollY || "0"));
        };

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        show !== null && show !== undefined && show !== modalActive && setModalActive(show);

        // eslint-disable-next-line
    }, [show]);

    const handleClose = () => {
        setModalActive(false);

        props.callback && props.callback();
    };

    return (
        modalActive && (
            <Container>
                <Backdrop onClick={handleClose} />
                <Body>
                    <CloseButton onClick={handleClose}>
                        <CloseIcon />
                    </CloseButton>

                    <ModalImage src={image} />

                    <Flex width={isMobile ? "100%" : "400px"} margin="0" height="100%">
                        <Flex
                            direction="column"
                            alignItems="flex-start"
                            padding={isMobile ? "29px 25px 30px 25px" : "0 42px 0 48px"}
                            boxSizing="border-box"
                        >
                            <Title>{title}</Title>
                            <Separator />
                            <ModalText>{description}</ModalText>

                            <Button
                                width={isMobile ? "100%" : "180px"}
                                margin={"0 auto 0 0"}
                                dark
                                filled
                                borderradius
                                value={button}
                                onClick={buttonAction}
                            />
                        </Flex>
                    </Flex>
                </Body>
            </Container>
        )
    );
};

const Container = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 5;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    ${isMobileMediaQuery} {
        padding: 90px 0px;
        align-items: baseline;
        max-height: 100vh;
        overflow-y: auto;
    }
`;

const Backdrop = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.7);
`;

const Body = styled.div`
    position: relative;
    width: 800px;
    height: 500px;
    max-width: 90vw;
    max-height: 80vh;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px 0 rgba(52, 52, 59, 0.15);
    padding: 0px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    ${isMobileMediaQuery} {
        padding: 0px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        max-width: 90vw;
        max-width: 330px;
        max-height: initial;
        height: auto;
    }
`;

const CloseButton = styled.button`
    padding: 0px;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 0px;
    right: 0px;
    margin: 19px;
    border: 0px;
    background: transparent;
    cursor: pointer;
    z-index: 20;

    svg {
        transform: scale(0.8);
        path {
            fill: #34343c;
        }
    }
`;

const ModalImage = styled.img`
    width: 400px;
    height: 100%;
    margin-top: auto;
    margin-left: 0px;
    margin-right: auto;
    object-fit: cover;

    ${isMobileMediaQuery} {
        max-width: 100%;
        max-height: 70vh;
    }
`;

const Title = styled.h1`
    margin: 0 auto 0 0;

    font-family: "DMSerifDisplay";
    font-size: 41px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
    text-align: left;
    color: #34343c;
`;

const Separator = styled.hr`
    width: 50px;
    height: 5px;
    border: 0;
    background-color: #e4858c;
    margin: 19px auto 24px 0px;
`;

const ModalText = styled.p`
    margin: 0 auto 30px 0;
    white-space: pre-line;

    font-family: "Graphik";
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    :not(:last-child) {
        margin-bottom: 40px;
    }
`;

export default Modal;
