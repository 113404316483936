import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { isMobile, isMobileMediaQuery } from "../../helpers/styles";
import { useLocation } from "react-router-dom";

export function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Pagination(props) {
    const { totalPages, currentPage, changePage } = props;
    const totalStaticElements = 2;
    const [pagesElement, setPagesElement] = useState(
        new Array(totalPages >= 8 ? 5 : totalPages > 1 ? totalPages - totalStaticElements : 0).fill(<></>)
    );
    const pagesLimit = 5;
    const middlePagesLimit = 3;
    const NextIcon = () => <IndicatorIcon margin="0 0 0 6px">&#62;</IndicatorIcon>;
    const PreviousIcon = () => <IndicatorIcon margin="0 6px 0 0">&#60;</IndicatorIcon>;

    function renderStartPages(index) {
        const pageNumber = index + totalStaticElements;
        if (pageNumber !== 6) return renderPageElement(pageNumber, index);
        return <PageRange key={index} />;
    }

    function renderMiddlePages(middlePageNumber, index) {
        if (index >= 1 && index <= 3) return renderPageElement(middlePageNumber, index);
        return <PageRange key={index} />;
    }

    function renderEndPages(endPageNumber, index) {
        if (index > 0) return renderPageElement(endPageNumber, index);
        return <PageRange key={index} />;
    }

    function renderPageElement(pageNumber, index) {
        return (
            <Page key={index} isCurrentPage={currentPage === pageNumber} onClick={() => changePage(pageNumber)}>
                {pageNumber}
            </Page>
        );
    }

    const PageRange = () => (
        <Page isNotPointer>
            <PageRangeCircle />
            <PageRangeCircle />
            <PageRangeCircle />
        </Page>
    );

    useEffect(() => {
        setPagesElement(
            new Array(totalPages >= 8 ? 5 : totalPages > 1 ? totalPages - totalStaticElements : 0).fill(<></>)
        );
    }, [totalPages]);

    return (
        <Container marginTop={props.marginTop}>
            {totalPages > 1 && (
                <ControllerButton
                    isAvailable={currentPage > 1}
                    borderRadius={"8px 0  0 8px"}
                    onClick={() => currentPage > 1 && changePage(currentPage - 1)}
                >
                    <PreviousIcon /> {!isMobile && "Anterior"}
                </ControllerButton>
            )}
            <Page isCurrentPage={currentPage === 1} onClick={() => changePage(1)}>
                {1}
            </Page>
            {pagesElement.map((page, index) => {
                if (totalPages <= 7) {
                    const pageNumber = index + totalStaticElements;
                    return (
                        <Page
                            key={index}
                            isCurrentPage={currentPage === pageNumber}
                            onClick={() => changePage(pageNumber)}
                        >
                            {pageNumber}
                        </Page>
                    );
                }
                const isStart = currentPage < pagesLimit;
                const isMiddle = currentPage >= pagesLimit && totalPages - currentPage > middlePagesLimit;

                if (isStart) return renderStartPages(index);
                else if (isMiddle) {
                    let middlePageNumber = currentPage - totalStaticElements + index;
                    return renderMiddlePages(middlePageNumber, index);
                } else {
                    let endPageNumber = totalPages - pagesLimit + index;
                    return renderEndPages(endPageNumber, index);
                }
            })}
            {totalPages > 1 && (
                <>
                    <Page isCurrentPage={currentPage === totalPages} onClick={() => changePage(totalPages)}>
                        {totalPages}
                    </Page>
                    <ControllerButton
                        isAvailable={!(currentPage === totalPages)}
                        borderRadius={"0 8px 8px 0"}
                        onClick={() => !(currentPage === totalPages) && changePage(currentPage + 1)}
                    >
                        {!isMobile && "Próximo"} <NextIcon />
                    </ControllerButton>
                </>
            )}
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    color: black;
    margin-bottom: 40px;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : "0")};
`;

const Page = styled.div`
    width: 3em;
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${(props) => (props.isCurrentPage ? "1px solid black" : "1px solid #c8c8c8")};
    border-left: ${(props) => (props.isCurrentPage ? "1px solid black" : "1px solid white")};
    border-right: ${(props) => (props.isCurrentPage ? "1px solid black" : "1px solid white")};
    background-color: white;
    color: black;
    cursor: ${(props) => (props.isNotPointer ? "auto" : "pointer")};
    font-size: 16px;
    user-select: none;
    ${isMobileMediaQuery} {
        width: 2.7em;
        height: 2.7em;
        font-size: 14px;
    }
`;

const PageRangeCircle = styled.div`
    width: 2px;
    height: 2px;
    margin: 0 1.2px;
    background-color: black;
    border-radius: 50%;
`;

const ControllerButton = styled.div`
    width: auto;
    height: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c8c8c8;
    background-color: white;
    color: ${(props) => (props.isAvailable ? "black" : "#828282")};
    cursor: ${(props) => (props.isAvailable ? "pointer" : "auto")};
    font-size: 16px;
    padding: 0 15px;
    border-radius: ${(props) => (props.borderRadius ? props.borderRadius : "0px")};
    user-select: none;
    ${isMobileMediaQuery} {
        padding: 0px;
        height: 2.7em;
        width: 2.7em;
        font-size: 14px;
    }
`;

const IndicatorIcon = styled.div`
    font-size: 24px;
    margin: ${(props) => (props.margin ? props.margin : "0px")};
    margin-bottom: 2px;
    ${isMobileMediaQuery} {
        margin: 0;
    }
`;

export default Pagination;
