import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import api from "../../services/api";
import { ContentDelimiter, Flex, isMobile, isMobileMediaQuery } from "./../../helpers/styles";
import Header from "./../../components/Header";
import BlogItem from "../../components/BlogItem";
import Footer from "../../components/Footer";
import Button from "../../components/Button";
import HeaderCarroussel from "../../components/HeaderCarroussel";
import TextInput from "../../components/Inputs/TextInput";
import RecreioBoxItem from "../../components/RecreioBoxItem";
import Loading from "../../components/Loading";
import Pagination from "../../components/Pagination";
import InternalLoading from "../../components/InternalLoading";
import EmailIcon from "./../../assets/images/email-icon.png";

const Recreio = () => {
    const navigate = useNavigate();

    const [state, setState] = useState(undefined);
    const [newsLetterEmail, setNewsLetterEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const loadMoreButtonRef = useRef();
    const [currentPage, setCurrentPage] = useState(1);
    const handlePage = (page) => setCurrentPage(page);

    useEffect(() => {
        loadRecreio();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        handleLoadMorePosts();
    }, [currentPage]);

    const loadRecreio = async () => {
        let response = await api.get("/blog_posts");

        if (response.status === 200) {
            setState({ ...response.data });
        } else {
            setState({
                carousel_posts: [],
                highlights: [],
                blogPosts: {},
            });
        }
    };

    const handleSubmitNewsletter = async () => {
        if (!newsLetterEmail || newsLetterEmail.length === 0) {
            return;
        }

        let response = await api.post("/newsletter", {
            email: newsLetterEmail,
        });

        if (response.status === 201) {
            alert("Subscrição efetuada com sucesso!");
        } else {
            alert("Algo deu errado em sua subscrição, por favor tente novamente.");
        }
    };

    const handleLoadMorePosts = async () => {
        if (state && state.blogPosts.pagination && state.blogPosts.pagination.base_path) {
            setLoading(true);
            let path = state.blogPosts.pagination.base_path.replace("/v1/", "");
            let response = await api
                .get(path + "?page=" + currentPage)
                .catch((err) => err.response)
                .then(
                    window.scroll({
                        top: isMobile ? 2390 : 1000,
                        behavior: "smooth",
                    })
                );

            const scrollY = window.scrollY;
            let newState = { ...state };

            setLoading(false);
            if (response.status === 200) {
                newState.blogPosts = {
                    ...newState.blogPosts,
                    items: response.data.items,
                    pagination: response.data.pagination,
                    scrollY,
                };
            }
            setState(newState);
        }
    };

    return (
        <>
            {loading && <InternalLoading />}
            <Header />
            <Helmet>
                <title>Acompanhe o blog Re Petit para novidades e assuntos relacionados</title>
                <meta property="og:title" content="Acompanhe o blog Re Petit para novidades e assuntos relacionados" />
                <meta
                    name="description"
                    content="O blog da Re Petit traz tudo para você ficar por dentro da moda infantil, educação, comportamento, maternidade, bem-estar, nutrição e muito mais!"
                />
                <meta
                    property="og:description"
                    content="O blog da Re Petit traz tudo para você ficar por dentro da moda infantil, educação, comportamento, maternidade, bem-estar, nutrição e muito mais!"
                />
                <meta
                    name="keywords"
                    content="brechó infantil, moda infantil, bem-estar, nutrição infantil, maternidade, educação, comportamento, paternidade, pais e filhos, novidades, vestimenta infantil, mãe e bebê"
                />
                <meta property="og:url" content={window.location.href} />
            </Helmet>
            <Container>
                {state === undefined ? (
                    <Loading />
                ) : (
                    <ContentDelimiter>
                        <Flex
                            direction="row"
                            alignItems="flex-start"
                            margin={isMobile ? "0 0 55px 0" : "35px 0 55px 0"}
                        >
                            <Flex margin="0 auto 0 0" direction="column" flex="1">
                                {state.carousel_posts && (
                                    <HeaderCarroussel
                                        margin={isMobile ? "0 0 30px 0" : undefined}
                                        height={isMobile ? 502 : 314}
                                        recreio
                                        items={state.carousel_posts.map((item) => {
                                            item.tag = item.category && item.category.title;
                                            item.description = item.quote;
                                            item.image = item.image_url;
                                            return item;
                                        })}
                                        action={(item) => navigate(`/blog/post/${item.slug}`)}
                                    />
                                )}

                                {state.highlights && (
                                    <Flex
                                        width={"100%"}
                                        margin={!isMobile && "35px 0 0 0"}
                                        justifyContent="space-between"
                                        padding={isMobile && "0 40px"}
                                        direction={isMobile ? "column" : "row"}
                                        boxSizing="border-box"
                                    >
                                        {state.highlights.map(
                                            (item, index) =>
                                                item && (
                                                    <RecreioBoxItem
                                                        key={index}
                                                        tag={item.category && item.category.title}
                                                        title={item.title}
                                                        author={item.author.name}
                                                        instagramUsername={item.author.instagram_username}
                                                        background={item.image_url}
                                                        onClick={() => navigate(`/blog/post/${item.slug}`)}
                                                    />
                                                )
                                        )}
                                    </Flex>
                                )}
                            </Flex>
                        </Flex>

                        <FullSection>
                            <Flex id="newsletter" direction={isMobile ? "column" : "row"} wrap="nowrap">
                                <img src={EmailIcon} width="77px" height="77px" alt="Email Icon" />
                                <p>
                                    quer continuar se inspirando? assine nossa newsletter para acompanhar todas as
                                    matérias, novidades e promoções re petit.
                                </p>
                                <TextInput
                                    width={isMobile ? "calc(100% - 36px)" : "393px"}
                                    maxWidth={isMobile ? "280px" : undefined}
                                    margin={isMobile ? "0 auto 15px auto" : "auto 35px auto 48px"}
                                    placeholder="Email"
                                    value={newsLetterEmail}
                                    onChange={(e) => setNewsLetterEmail(e.target.value)}
                                />
                                <Button
                                    margin="auto"
                                    minWidth="180px"
                                    maxWidth={isMobile ? "280px" : undefined}
                                    dark
                                    filled
                                    fluid={isMobile}
                                    value="Assinar"
                                    onClick={handleSubmitNewsletter}
                                />
                            </Flex>
                        </FullSection>

                        <Flex
                            direction={isMobile ? "column" : "row"}
                            justifyContent={isMobile ? "center" : "space-between"}
                            alignItems={"stretch"}
                            alignLast={!isMobile}
                        >
                            {state.blogPosts &&
                                state.blogPosts.items.map(
                                    (item, index) =>
                                        item && (
                                            <BlogItem
                                                key={index}
                                                category={item.category && item.category.title}
                                                title={item.title}
                                                description={item.quote}
                                                author={item.author && item.author.name}
                                                background={item.image_url}
                                                link={`/blog/post/${item.slug}`}
                                            />
                                        )
                                )}
                        </Flex>
                        {state.blogPosts.pagination.pages > 0 && (
                            <Pagination
                                marginTop={isMobile ? "20px" : "10px"}
                                totalPages={state.blogPosts.pagination.pages}
                                currentPage={currentPage}
                                changePage={handlePage}
                            />
                        )}
                    </ContentDelimiter>
                )}
            </Container>

            <Footer />
        </>
    );
};

const Container = styled.div`
    height: 100%;
    flex-grow: 1;
    min-height: 100%;
    margin-top: -50px;

    ${isMobileMediaQuery} {
        margin-top: 0px;
    }
`;

const FullSection = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f9f9;
    min-width: 100vw;
    width: 100%;
    padding: 60px 0px;
    margin: 55px 0px;

    @media screen and (min-width: 1222px) {
        margin-left: calc((-100vw + 1222px) / 2);
    }

    ${isMobileMediaQuery} {
        padding: 40px 0px;
    }

    & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 1222px;
        width: 100%;

        @media screen and (max-width: 1222px) {
            flex-wrap: wrap;
        }
        ${isMobileMediaQuery} {
            width: calc(100% - 80px);
            padding: 0px 40px;
        }

        img {
            margin-right: 35px;
            ${isMobileMediaQuery} {
                margin: 0px;
                margin-bottom: 30px;
            }
        }

        p {
            margin: 0px;
            max-width: 450px;
            text-transform: uppercase;
            font-family: "SackersGothicStd";
            font-size: 11px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.91;
            letter-spacing: normal;
            text-align: left;
            color: #34343b;

            ${isMobileMediaQuery} {
                word-break: break-all;
                max-width: 280px;
                text-align: center;
                margin-bottom: 30px;
            }
        }
    }
`;

export default Recreio;
