import { combineReducers } from "redux";

import loginModalReducer from "./loginModal";
import modalReducer from "./modal";
import userReducer from "./user";
import cartReducer from "./cart";
import checkoutReducer from "./checkout";
import categoryReducer from './category'

export default combineReducers({
    user: userReducer,
    cart: cartReducer,
    checkout: checkoutReducer,
    category: categoryReducer,
    ui: combineReducers({
        loginModalReducer,
        modalReducer,
    }),
});
