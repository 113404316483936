import { SHOW_LOGIN_MODAL, CLOSE_LOGIN_MODAL } from "../actions/loginModal";

const initialState = {
    show: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SHOW_LOGIN_MODAL:
            return {
                show: true,
                redirectUrl: action.payload && action.payload.redirectUrl,
            };

        case CLOSE_LOGIN_MODAL:
            return {
                show: false,
            };

        default:
            return state;
    }
};
