import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { isMobile, isMobileMediaQuery, sizes } from "../../helpers/styles";

const Backdrop = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: transparent;
`;

const HoverArea = styled.div`
    position: absolute;
    right: 0;
    left: 0;
    bottom: 100%;
    height: 50px;
`;

const Container = styled.div`
    position: absolute;
    top: calc(100% + 15px);
    right: -30px;
    min-width: 156px;
    padding: 16px;
    background-color: #f3f5f5;
    box-shadow: 0px 2px 1px 0 rgba(52, 52, 64, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 5;

    p {
        margin: 0px;

        font-family: "Graphik";
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 17.5px;
        letter-spacing: normal;
        text-align: left;
        color: #8c8ca1;

        :hover {
            color: #e4858c;
        }
    }

    ${isMobileMediaQuery} {
        top: ${sizes.header}px;
        left: 0px;
        right: 0px;
        position: fixed;

        flex-direction: row;
        padding: 13px 20px;

        p {
            :not(:last-of-type) {
                margin-right: 12px;
            }
        }
    }
`;

const Arrow = styled.div`
    position: absolute;
    bottom: 100%;
    right: 30px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #f3f5f5;

    ${isMobileMediaQuery} {
        right: 80px;
    }
`;

function Dropdown({ show, callback, options, ...props }) {
    const [active, setActive] = useState(false);

    useEffect(() => {
        if (show !== active) setActive(show);

        // eslint-disable-next-line
    }, [show]);

    const handleClose = () => {
        callback && callback(false);
        setActive(false);
    };

    return active ? (
        <>
            <Backdrop onClick={handleClose} />
            <Container onMouseLeave={!isMobile ? handleClose : null}>
                <HoverArea />
                <Arrow />
                {options &&
                    options.map((item, index) => (
                        <p
                            key={`${item.label}_${item.index}`}
                            onClick={() => {
                                item.action();
                                handleClose();
                            }}
                        >
                            {item.label}
                        </p>
                    ))}
            </Container>
        </>
    ) : null;
}

export default Dropdown;
