export const LAST_POSITION = "LAST_POSITION";
export const CLEAR_LAST_POSITION = "CLEAR_LAST_POSITION";

export const saveLastPosition = (category, path) => {
    return {
        type: LAST_POSITION,
        payload: {
            category,
            path,
        },
    };
};

export const clearLastPosition = () => {
    return {
        type: CLEAR_LAST_POSITION,
    };
};
