import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { breakpoints } from "../../helpers/styles";
import BtnQuizImage from "./../../assets/images/btn-quiz.png";
import BtnQuizImageHover from "./../../assets/images/btn-quiz-hover.png";

function QuizButton() {
    return (
        <Link to="/quiz">
            <Container />
        </Link> 
    );
}

const Container = styled.button`
    background-image: url(${BtnQuizImage});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 110px;
    height: 110px;
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    position: absolute;
    right: -20px;
    top: calc(100% + 50px);
    cursor: pointer;

    @media screen and (max-width: ${breakpoints.xl}px) {
        right: 0px;
    }

    :hover {
        background-image: url(${BtnQuizImageHover});
    }
`;

export default QuizButton;
