export const SEND_CHECKOUT = "SEND_CHECKOUT";
export const CLEAR_CHECKOUT = "CLEAR_CHECKOUT";

export const sendCheckout = (payment_info) => {
    if (!payment_info || typeof payment_info !== "object") return;

    return {
        type: SEND_CHECKOUT,
        payload: payment_info,
    };
};

export const clear = () => {
    return {
        type: CLEAR_CHECKOUT,
    };
};
