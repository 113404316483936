import React from "react";
import styled from "styled-components";
import { Flex } from "./../../helpers/styles";

import {
    CarouselProvider,
    Slider,
    Slide,
    DotGroup as CarrousselDotGroup,
    Dot as CarrousselDot,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

const MobileImagesCarroussel = ({ images, ...props }) => {
    return (
        <Flex width="100%" height="auto" padding="0px 0px 15px 0px" mobile>
            <CarrousselContainer
                naturalSlideWidth={window.innerWidth}
                naturalSlideHeight={300}
                totalSlides={images ? images.length : 0}
                visibleSlides={1}
            >
                <ContentSlider>
                    {images &&
                        images.map((image, index) => (
                            <StyledSlide key={`slide_${index}`}>
                                <img src={image} alt="Product Preview" />
                                {props.append && props.append}
                            </StyledSlide>
                        ))}
                </ContentSlider>

                <DotGroup
                    renderDots={(e) =>
                        images &&
                        images.map((item, index) => (
                            <Dot
                                key={`slideDot_${index}`}
                                slide={index}
                                current={e.currentSlide === index ? 1 : 0}
                            />
                        ))
                    }
                />
            </CarrousselContainer>
        </Flex>
    );
};

const CarrousselContainer = styled(CarouselProvider)`
    flex: 1;
    height: auto;
    position: relative;
    width: 100%;
`;

const ContentSlider = styled(Slider)`
    height: 380px;

    li > div {
        display: flex;
        flex-direction: column;
    }
`;

const StyledSlide = styled(Slide)`
    width: 100%;
    height: 300px;
`;

const DotGroup = styled(CarrousselDotGroup)`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
`;

const Dot = styled(CarrousselDot)`
    padding: 0px;
    border: 0;
    background-color: #f37f8a;
    border-radius: 100%;
    height: 11px;
    width: 11px;
    flex-basis: 11px;

    &:not(:last-child) {
        margin-right: 6px;
    }

    ${(props) => !props.current && `background-color: #e4e4e7;`}
`;

export default MobileImagesCarroussel;
