import React from "react";
import styled, { css } from "styled-components";
import InputMask from "react-input-mask";

function TextInput({
    type,
    name,
    id,
    prepend,
    append,
    placeholder,
    onChange,
    onBlur,
    readOnly,
    value,
    mask,
    maskPlaceholder,
    error,
    errorMessage,
    ...props
}) {
    return (
        <>
            <Container error={error} errorMessage={errorMessage} {...props}>
                {prepend && <Append pre>{prepend}</Append>}
                {mask ? (
                    <InputMask
                        name={name}
                        id={id}
                        placeholder={placeholder}
                        title={placeholder}
                        onChange={onChange}
                        onBlur={onBlur}
                        prepend={prepend}
                        readOnly={readOnly}
                        value={value}
                        mask={mask}
                        maskPlaceholder={maskPlaceholder}
                        maskChar=""
                        required
                    ></InputMask>
                ) : (
                    <input
                        type={type ? type : "text"}
                        name={name}
                        id={id}
                        placeholder={placeholder}
                        title={placeholder}
                        onChange={onChange}
                        onBlur={onBlur}
                        prepend={prepend}
                        readOnly={readOnly}
                        value={value}
                        required
                    />
                )}
                {append && <Append>{append}</Append>}
            </Container>
        </>
    );
}

const Container = styled.div`
    position: relative;
    display: flex;
    background-color: #fff;
    border: 1px solid #cacad1;
    min-height: 40px;
    height: auto;
    padding: 13px 17px;
    box-sizing: border-box;
    border-radius: 16px;

    width: ${(props) => (props.width ? props.width : "100%")};
    ${({ flex }) => flex && `flex: ${flex};`};
    ${({ margin }) => margin && `margin: ${margin};`};
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`};

    ${({ outlined }) => outlined && `background-color: transparent;`};

    ${({ error, errorMessage }) =>
        (error || errorMessage) &&
        css`
            border-color: tomato !important;
            background: #fff3f0;
        `};

    ${({ bottomLined }) =>
        bottomLined &&
        css`
            background-color: transparent;
            border: 0;
            border-bottom: 1px solid black;
        `};

    ${({ noborder }) =>
        noborder &&
        css`
            border: 0;
        `};

    & > input {
        width: 100%;
        height: 100%;
        border: 0px;

        font-family: "Graphik";
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.75px;
        text-align: left;
        color: #676777;
        background-color: transparent;
        text-overflow: ellipsis;

        ::placeholder {
            color: #4d4d59;
            opacity: 0.7;
        }
    }

    ${({ special }) =>
        special &&
        css`
        min-height: 38px;
        height: auto;
        padding-top: 0px;
        padding-bottom: 0px;
        box-sizing: content-box;

        & > input {
            text-transform: capitalize;
            font-family: "DMSerifDisplay";
            font-size: 28px;
            font-weight: normal;
            font-stretch: normal;
            font-style: italic;
            line-height: 0.75;
            letter-spacing: normal;
            text-align: center;
            color: #000;

            ::placeholder {
                color: #cacad0;
                text-transform: initial;
            }
            
        `};

    ${({ disabled }) =>
        disabled &&
        css`
            pointer-events: none !important;
            opacity: 0.7;
        `}
`;

const Append = styled.div`
    margin-right: 0;
    margin-left: 10px;
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${(props) =>
        props.pre &&
        css`
            margin-right: 10px;
            position: relative;
            left: initial;
            right: initial;
        `}
`;

export default TextInput;
