import React from "react";
import styled, { css } from "styled-components";
import moment from "moment";
import ReactTooltip from "react-tooltip";

import { Flex, isMobile, isMobileMediaQuery } from "../../helpers/styles";

import CheckBox from "../Inputs/CheckBox";
import { capitalize } from "../../helpers";

import iconRemove from "../../assets/images/icon-remove.png";
import productPlaceholder from "../../assets/images/product-placeholder.jpg";

function HorizontalProductItemCheckout({
    item,
    cart,
    box,
    hideSpecs,
    showId,
    onClick,
    hideRemove,
    handleRemove,
    checkboxCallback,
    enabledFreeShipping,
    ...props
}) {
    return !item || typeof item !== "object" || item.box_type ? null : (
        <Container direction="row" box={box} onClick={onClick}>
            <Flex
                width="100%"
                boxSizing="border-box"
                justifyContent="flex-between"
                alignItems="flex-center"
                style={{
                    borderBottom: "1px solid #e5e5e8",
                    paddingBottom: "8px",
                }}
            >
                <RemoveButton onClick={() => handleRemove(item)}>
                    <img
                        src={iconRemove}
                        alt="Remove Button"
                        style={{ width: "15px" }}
                    />
                </RemoveButton>
                <ImageContainer>
                    <img
                        src={
                            (item.images && item.images[0]) ||
                            productPlaceholder
                        }
                        alt="Foto do produto"
                    />
                </ImageContainer>
                
                <ItensList>
                   
                    <span style={{ display: "flex" }}>
                        {item.title ||
                            `${capitalize(
                                item.item_category.name.toLowerCase()
                            )} ${capitalize(item.brand.name.toLowerCase())}`}
                        {showId && ` - Cod.: ${item.id}`}
                        {(item.promotion || item.partner_store) && (
                            <span style={{ marginLeft: 4 }}>
                                <ReactTooltip style={{ width: "256px" }} />
                                <span
                                    data-tip={
                                        item.promotion
                                            ? "Cupom não aplicavel em peças em promoção."
                                            : item.partner_store
                                            ? "Produto de loja parceira"
                                            : "-"
                                    }
                                >
                                    <QuestionContainer>?</QuestionContainer>
                                </span>
                            </span>
                        )}
                    </span>

                    {(item.price || item.price_label) && (
                        <span cart={cart} box={box}>
                            R${" "}
                            {parseFloat(
                                item.price || item.price_label
                            ).toLocaleString("pt-br", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </span>
                    )}
                    {(cart || box) && (
                        <QuantityContainer
                            alignItems="flex-end"
                            direction={box && "column"}
                        >
                            {(item.price || item.price_label) && !isMobile && (
                                <PriceTag cart={cart} box={box}>
                                    R${" "}
                                    {parseFloat(
                                        item.price || item.price_label
                                    ).toLocaleString("pt-BR", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </PriceTag>
                            )}
                            <Flex desktop>
                                {box &&
                                    (!item.status ||
                                    item.status === "in_box" ||
                                    item.status === "pending"
                                        ? !props.sold && (
                                              <CheckBox
                                                  name={item.id}
                                                  defaultChecked={
                                                      props.defaultChecked
                                                  }
                                                  onChange={checkboxCallback}
                                                  margin="57px 0 0 0"
                                              >
                                                  Comprar
                                              </CheckBox>
                                          )
                                        : !props.hideStatus && (
                                              <Status
                                                  onClick={
                                                      moment().diff(
                                                          moment(item.date),
                                                          "days"
                                                      ) < 7 &&
                                                      props.returnProductAction
                                                  }
                                                  color={
                                                      item.status.toLowerCase() ===
                                                      "finalizado"
                                                          ? "#79c992"
                                                          : item.status.toLowerCase() ===
                                                            "devolvido"
                                                          ? "#ff6600"
                                                          : null
                                                  }
                                              >
                                                  {item.date &&
                                                  moment().diff(
                                                      moment(item.date),
                                                      "days"
                                                  ) < 7
                                                      ? `Devolver (${
                                                            7 -
                                                            moment().diff(
                                                                moment(
                                                                    item.date
                                                                ),
                                                                "days"
                                                            )
                                                        } dias restantes)`
                                                      : item.status}
                                              </Status>
                                          ))}
                            </Flex>
                        </QuantityContainer>
                    )}
                    {!item.eligible_for_free_shipping && enabledFreeShipping && (
                        <>
                            <NotFreeShipping>
                                Não aplicável frete grátis
                            </NotFreeShipping>
                        </>
                    )}
                </ItensList>
            </Flex>
            <Flex
                margin="19px 0 0 0"
                width="100%"
                justifyContent="space-between"
                mobile
            >
                <Flex>
                    {box &&
                        (!item.status ||
                        item.status === "in_box" ||
                        item.status === "pending"
                            ? !props.sold && (
                                  <CheckBox
                                      name={item.id}
                                      defaultChecked={props.defaultChecked}
                                      onChange={checkboxCallback}
                                      margin="0"
                                  >
                                      Comprar
                                  </CheckBox>
                              )
                            : !props.hideStatus && (
                                  <Status
                                      onClick={
                                          moment().diff(
                                              moment(item.date),
                                              "days"
                                          ) < 7 && props.returnProductAction
                                      }
                                      color={
                                          item.status.toLowerCase() ===
                                          "finalizado"
                                              ? "#79c992"
                                              : item.status.toLowerCase() ===
                                                "devolvido"
                                              ? "#ff6600"
                                              : null
                                      }
                                  >
                                      {item.date &&
                                      moment().diff(moment(item.date), "days") <
                                          7
                                          ? `Devolver (${
                                                7 -
                                                moment().diff(
                                                    moment(item.date),
                                                    "days"
                                                )
                                            } dias restantes)`
                                          : item.status}
                                  </Status>
                              ))}
                </Flex>
            </Flex>
        </Container>
    );
}
const Container = styled(Flex)`
    width: 100%;
    margin-bottom: 10px;
    position: relative;
    ${(props) =>
        props.box &&
        css`
            :not(:last-of-type) {
                padding-bottom: 24px;
                border-bottom: 1px solid #e5e5e8;
            }
        `}
`;

const PriceInfo = styled(Flex)`
    width: 100%;
    background-color: #f7f9f9;
    text-transform: initial;
    padding: ${({ padding }) => (padding ? padding : "20px 20px")};
    box-sizing: border-box;

    font-family: "Graphik";
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;
`;

const ImageContainer = styled.div`
    margin-right: 19px;
    margin-top: 4px;
    padding: 1px 2px;
    min-height: 25px;
    align-self: stretch;
    border: solid 1px #f4f4f5;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;

    img {
        width: 36px;
        height: 36px;
        object-fit: contain;

        ${isMobileMediaQuery} {
            width: 25px;
            height: 25px;
        }
    }
`;

const QuestionDropDown = styled.div`
    position: absolute;
    top: calc(100% + 18px);
    left: 50%;
    transform: translateX(-50%);
    width: 320px;
    min-width: 156px;
    padding: 16px;
    border-radius: 4px;
    background-color: #f3f5f5;
    color: #34343c;
    box-shadow: 0px 2px 6px 0 rgba(52, 52, 64, 0.16);
    display: block;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 5;
    line-height: 1;

    p {
        margin: 0px;

        font-family: "Graphik";
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 17.5px;
        letter-spacing: normal;
        text-align: left;
        color: #8c8ca1;

        :hover,
        &.active {
            color: #e4858c;
        }
    }
`;

const QuestionContainer = styled.div`
    display: inline-flex;
    background-color: transparent;
    border: 1px solid #34343b;
    color: #34343b;
    font-size: 12px;
    width: 16px;
    height: 16px;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    line-height: 0;
    vertical-align: middle;
    text-align: center;
    margin-left: 0.2rem;
    cursor: pointer;
    position: relative;

    ${QuestionDropDown} {
        display: none;
    }

    :hover ${QuestionDropDown} {
        display: block;
    }
`;

const Title = styled.div`
    margin-bottom: 13px;

    font-family: "DMSerifDisplay";
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    ${isMobileMediaQuery} {
        font-size: 20px;
    }
`;

const Description = styled.div`
    margin-bottom: 4px;

    max-width: calc(100% - 120px);

    font-family: "Graphik";
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;
`;

const Separator = styled.hr`
    width: 100%;
    flex: 1;
    height: 1px;
    background-color: #f4f4f5;
    border: 0px;
`;

const RemoveButton = styled.button`
    background-color: transparent;
    width: 8px;
    height: 8px;
    padding: 0px;
    margin-left: 10px;
    border: 0px;
    z-index: 3;

    ${(props) => props.onClick && "cursor:pointer;"}
`;

const PriceTag = styled.p`
    margin: 0px;

    font-family: "Graphik";
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.85;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    ${(props) =>
        (props.cart || props.box) &&
        css`
            font-size: 17px;
        `}

    ${(props) =>
        props.box &&
        css`
            margin-right: 0px !important;
        `}
`;

const QuantityContainer = styled(Flex)`
    position: absolute;
    top: 6px;
    right: 0px;

    & > :first-child {
        margin-right: 20px;
    }
`;

const Status = styled.div`
    margin-top: 61px;

    text-transform: capitalize;
    font-family: "Graphik";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.86;
    letter-spacing: 0.28px;
    text-align: right;
    color: ${(props) => props.color || "#8c8ca1"};

    ${(props) =>
        props.onClick &&
        css`
            text-decoration: underline;
            cursor: pointer;
        `}

    ${isMobileMediaQuery} {
        margin: 0;
    }
`;

const ItensList = styled(Flex)`
    flex: 1;
    direction: row;
    justify-content: space-between;
    align-items: flex-center;
    color: #34343b;
`;

const NotFreeShipping = styled.div`
    font-family: "Graphik";
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 11px;
    text-align: center;
    background-color: #ff000085;
    /* position: absolute;
    bottom: 0;
    left: 0; */
    color: #ffffff;
    text-transform: uppercase;
    padding: 0.25rem 0.5rem;
    box-sizing: border-box;
    max-width: 175px;
    ${isMobileMediaQuery} {
        margin-top: 3px;
        max-width: 175px;
    }
`;
export default HorizontalProductItemCheckout;
