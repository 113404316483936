import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import moment from "moment";
import api from "../../services/api";
import { Flex, isMobileMediaQuery, isMobile } from "../../helpers/styles";
import Button from "../Button";
import LoadingSpinner from "../LoadingSpinner";
import SellProductItem from "../../pages/Account/SellProductItem";
import ModalSuccess from "../../assets/images/modal-success-hero.jpg";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";

const PromoModal = ({ items, promotedItems, show, setModal, callback, successCallback, ...props }) => {
    const bodyElement = document.querySelector("body");
    const [modalActive, setModalActive] = useState(false);
    const [sendingRequest, setSendingRequest] = useState(false);
    const itemMonthAge = promotedItems.reduce((acc, curr) => {
        const product = items.find((item) => item.id.toString() === curr);
        const thisAge = product ? moment().diff(moment(product.entry_approved_at), "months", true) : 0;
        return thisAge > acc ? thisAge : acc;
    }, 0);

    useEffect(() => {
        show !== null && show !== undefined && show !== modalActive && setModalActive(show);

        if (show) {
            let top = window.scrollY;
            bodyElement.style.position = "fixed";
            bodyElement.style.top = `-${top}px`;
            bodyElement.style.left = "0px";
            bodyElement.style.right = "0px";
        } else {
            const scrollY = bodyElement.style.top;
            bodyElement.style.position = "";
            bodyElement.style.top = "";
            bodyElement.style.left = "";
            bodyElement.style.right = "";
            window.scrollTo(0, -parseInt(scrollY || "0"));
        }

        // eslint-disable-next-line
    }, [show]);

    const handleClose = () => {
        setModalActive(false);

        callback && callback(false);
    };

    const handleSubmitPromotions = async () => {
        setSendingRequest(true);

        const promotions_response = await api
            .post("/profiles/entries/items/promotion", {
                enabled_item_ids: promotedItems.map((id) => parseInt(id)),
            })
            .catch((err) => err.response);

        setSendingRequest(false);

        if (promotions_response.status === 204) {
            successCallback && successCallback();

            handleClose();

            setModal({
                show: true,
                image: ModalSuccess,
                title: "Yeah!",
                message: "Itens em promoção atualizados com sucesso.",
            });
        } else {
            console.error(promotions_response);

            setModal({
                show: true,
                message:
                    promotions_response && promotions_response.data && promotions_response.data.message
                        ? promotions_response.data.message
                        : "Erro inesperado. Aguarde um instante e tente outra vez!",
            });
        }
    };

    return (
        modalActive && (
            <Container onClick={handleClose}>
                <Body onClick={(e) => e.stopPropagation()}>
                    <CloseButton onClick={handleClose}>
                        <CloseIcon />
                    </CloseButton>

                    <ModalTitle>Ativar promoção</ModalTitle>

                    <Flex
                        padding={isMobile ? "0 15px" : "0 30px"}
                        direction="column"
                        minWidth={!isMobile && "500px"}
                        boxSizing="border-box"
                    >
                        <p>
                            Ativando as promoções você aumenta suas chances de vender e faturar.
                            <br />
                            Confira as regras:
                        </p>
                        <ExplainText>
                            <b>Após 6</b> meses = 10% de desconto. É opcional, basta você clicar agora em ativar
                            promoção.
                            <br />
                            <br />
                            <b>Após 10</b> meses = 25% de desconto. O desconto entrará automaticamente.
                        </ExplainText>
                        <p>
                            *Caso tenha alguma dúvida entre em contato com nossa{" "}
                            <a href="https://api.whatsapp.com/send?phone=5511976922404">equipe.</a>
                        </p>
                        <br />
                        {items && (
                            <ProductList>
                                {items
                                    .filter((item) => promotedItems.includes(item.id.toString()))
                                    .map((item, index) => (
                                        <SellProductItem key={index} item={item} />
                                    ))}
                            </ProductList>
                        )}

                        <Flex width="100%" padding="26px 0" justifyContent="space-between">
                            <Button
                                width="calc(50% - 8px)"
                                disabled={sendingRequest}
                                onClick={handleClose}
                                dark
                                link
                                value="Cancelar"
                            />
                            <Button
                                width="calc(50% - 8px)"
                                disabled={sendingRequest}
                                onClick={handleSubmitPromotions}
                                dark
                                filled
                                value={sendingRequest ? <LoadingSpinner /> : "Ativar"}
                            />
                        </Flex>
                    </Flex>
                </Body>
            </Container>
        )
    );
};

const Container = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 5;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.7);
    padding: 2rem 0px;

    ${isMobileMediaQuery} {
        padding: 90px 0px;
        align-items: baseline;
        max-height: 100vh;
    }
`;

const Body = styled.div`
    position: relative;
    width: auto;
    height: auto;
    min-height: initial;
    max-height: initial;
    max-width: 90vw;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px 0 rgba(52, 52, 59, 0.15);
    padding: 0px;
    margin: auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    ${isMobileMediaQuery} {
        padding: 0px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        max-width: 95vw;
        max-height: initial;
        height: auto;
    }

    ${({ fullHero }) =>
        fullHero &&
        css`
            max-width: 90vw !important;
        `}
`;

const ModalTitle = styled.h2`
    padding: 20px 19px 18px 29px;
    text-align: left;
    margin: 0 auto 30px 0;
    width: 100%;
    box-sizing: border-box;

    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 1.45;
    letter-spacing: 2.2px;
    color: #34343b;
    border-bottom: 1px solid #f4f4f5;
`;

const CloseButton = styled.button`
    padding: 0px;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 0px;
    right: 0px;
    margin: 19px;
    border: 0px;
    background: transparent;
    cursor: pointer;
    z-index: 20;

    svg {
        transform: scale(0.8);
        path {
            fill: #34343c;
        }
    }
`;

const WarningContainer = styled(Flex)`
    max-width: 440px;
    margin-bottom: 20px;
    border: 1px solid #db777e;
    border-radius: 40px 0 0 40px;
    padding: 14px;
    box-sizing: border-box;

    img {
        width: 45px;
        height: 45px;
        margin-right: 18px;
    }

    p {
        max-width: calc(100% - 64px);
        margin: 0;
        font-size: 13px;
        line-height: 1.15;
        text-align: left;
        color: #db777e;
    }
`;

const ProductList = styled(Flex)`
    max-height: 300px;
    max-width: 440px;
    overflow-y: auto;

    padding: 26px 0;
    border-top: 1px solid #e5e5e8;
    border-bottom: 1px solid #e5e5e8;

    & > div:not(:last-child) {
        padding-bottom: 26px;
        margin-bottom: 26px;
        border-bottom: 1px solid #e5e5e8;
    }
`;

const ExplainText = styled.p`
    max-width: 372px;
    padding: 15px 0 15px 40px;
    border-left: 4px solid #f3808b;

    object-fit: contain;
    font-family: "Graphik";
    font-size: 13px;
    line-height: 1.15;
    text-align: left;
    color: #34343b;
    box-sizing: border-box;

    b {
        color: #34343b;
        font-weight: 600;
    }
`;

export default PromoModal;
