import React, { useState, useEffect } from "react";
import { Link as RouterLink, useLocation, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import api from "../../services/api";
import * as UserActions from "../../redux/actions/user";
import { Flex, isMobile, isMobileMediaQuery, notMobileMediaQuery } from "./../../helpers/styles";
import Header from "./../../components/Header";
import Footer from "./../../components/Footer";
import Indique from "./Indique";
import Loading from "../../components/Loading";
import AlertModal from "../../components/AlertModal";
import MeuBox from "./MeuBox";
import MeusPedidos from "./MeusPedidos";
import MinhasVendas from "./MinhasVendas";
import MeuCadastro from "./MeuCadastro";
import MeusCreditos from "./MeusCreditos";
import AccountHeader from "./AccountHeader";
import ChevronLeftIcon from "./../../assets/images/seta.svg";
import EntryPromotionModal from "../../components/EntryPromotionModal";

const Account = () => {
    const dispatch = useDispatch();
    const locale = useLocation();
    const navigate = useNavigate();
    const { current_tab, item_id } = useParams();

    const user = useSelector((state) => state.user);

    const [profile, setProfile] = useState(undefined);
    const steps = ["info", "childs", "banking"];
    const [currentStep, setCurrentStep] = useState(undefined);

    const [modal, setModal] = useState(undefined);

    useEffect(() => {
        loadProfile();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        (!current_tab || current_tab === "") && navigate("/minha-conta/box");

        setCurrentStep(undefined);

        if (user.entry_approval_required_id > 0 && window.location.pathname === `/minha-conta/vendas`) {
            navigate(`/minha-conta/vendas/${user.entry_approval_required_id}`);
        }

        // eslint-disable-next-line
    }, [current_tab]);

    const loadProfile = async () => {
        let response = await api.get("/profiles/me").catch((err) => err.response);

        let profileData = {};

        if (response.status === 200) {
            profileData = { ...response.data };
            dispatch(UserActions.login({ ...user, ...response.data }));

            dispatch(UserActions.refreshProfile());
        } else {
            dispatch(UserActions.logout());
        }

        let walletResponse = await api.get("/profiles/wallet").catch((err) => err.response);

        if (walletResponse.status === 200) {
            profileData = { ...profile, ...walletResponse.data };
            profileData = {
                ...profileData,
                total_sales: profileData.total_sales ? parseFloat(profileData.total_sales) : 0,
                total_for_sale: profileData.total_for_sale ? parseFloat(profileData.total_for_sale) : 0,
                future_balance: profileData.future_balance ? parseFloat(profileData.future_balance) : 0,
                balance: profileData.balance ? parseFloat(profileData.balance) : 0,
                credit: profileData.credit ? parseFloat(profileData.credit) : 0,
                withdraw_tax: profileData.withdraw_tax ? parseFloat(profileData.withdraw_tax) : 0,
            };

            dispatch(
                UserActions.wallet({
                    total_sales: parseFloat(profileData.total_sales),
                    total_for_sale: parseFloat(profileData.total_for_sale),
                    future_balance: parseFloat(profileData.future_balance),
                    balance: parseFloat(profileData.balance),
                    credit: parseFloat(profileData.credit),
                    withdraw_tax: parseFloat(profileData.withdraw_tax),
                })
            );
        } else {
            profileData = {
                ...profile,
                total_sales: 0.0,
                total_for_sale: 0.0,
                future_balance: 0.0,
                balance: 0.0,
                credit: 0.0,
            };
        }

        setProfile(profileData);
    };

    const memoizedCurrentTab = React.useMemo(() => {
        switch (current_tab) {
            case "box":
                return <MeuBox item_id={item_id} history={locale} setModal={setModal} />;

            case "pedidos":
                return <MeusPedidos item_id={item_id} history={locale} setModal={setModal} />;

            case "vendas":
                return <MinhasVendas modal={modal} setModal={setModal} item_id={item_id} history={locale} />;

            case "creditos":
                return <MeusCreditos modal={modal} setModal={setModal} item_id={item_id} history={locale} />;

            case "cadastro":
                return (
                    <MeuCadastro
                        steps={steps}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        modal={modal}
                        setModal={setModal}
                        history={locale}
                    />
                );

            case "indique":
                return <Indique history={locale} />;

            default:
                return null;
        }

        // eslint-disable-next-line
    }, [current_tab, currentStep, locale.pathname]);

    return (
        <>
            <Header hideLinks />

            <EntryPromotionModal setModal={setModal} />

            {!profile ? (
                <Loading />
            ) : (
                <Container
                    alignItems="flex-start"
                    justifyContent={isMobile ? "flex-start" : "center"}
                    direction={isMobile ? "column" : "row"}
                    wrap="no-wrap"
                >
                    <AccountHeader user={user} profile={profile} history={locale} currentTab={current_tab} />

                    <Flex
                        width={isMobile ? "100%" : undefined}
                        maxWidth="100%"
                        direction="column"
                        margin={isMobile ? "24px 0 0 0" : "0 auto 0 40px"}
                    >
                        {current_tab === "cadastro" && currentStep > 0 && (
                            <Flex
                                flex="1"
                                width="100%"
                                maxHeight="58px"
                                margin="0"
                                justifyContent="flex-start"
                                direction="column"
                                background="#313139"
                            >
                                <Flex
                                    flex="1"
                                    width={isMobile ? "auto" : "1222px"}
                                    margin={isMobile ? "auto auto auto 0" : "auto"}
                                    justifyContent="flex-start"
                                    padding={isMobile ? "19px 20px 18px 34px" : "19px 0 18px 0"}
                                >
                                    <Link to="/minha-conta/cadastro" onClick={() => setCurrentStep(undefined)}>
                                        <ChevronLeft />
                                        Voltar
                                    </Link>

                                    <Flex flex="1" desktop>
                                        <StepsTitle active={currentStep === 1}>1. meus dados</StepsTitle>
                                        <StepsTitle active={currentStep === 2}>2. meus filhos</StepsTitle>
                                        <StepsTitle active={currentStep === 3}>3. dados bancários</StepsTitle>
                                    </Flex>
                                    <Flex flex="1" margin="0 auto 0 30px" justifyContent="flex-start" mobile>
                                        <StepCircle active={currentStep === 1}>1</StepCircle>
                                        <StepCircle active={currentStep === 2}>2</StepCircle>
                                        <StepCircle active={currentStep === 3}>3</StepCircle>
                                    </Flex>
                                </Flex>
                            </Flex>
                        )}

                        <Flex
                            flex="1"
                            width={isMobile ? "100%" : "909px"}
                            justifyContent="flex-start"
                            direction="column"
                            padding="0px 0 40px 0"
                        >
                            {memoizedCurrentTab}
                        </Flex>
                    </Flex>
                </Container>
            )}

            <Footer mini />

            {modal && (
                <AlertModal
                    {...modal}
                    show={modal.show}
                    title={modal.title}
                    message={modal.message}
                    callback={() => setModal({ ...modal, show: false })}
                />
            )}
        </>
    );
};

const Container = styled(Flex)`
    height: 100%;
    flex-grow: 1;
    min-height: 100%;
    margin-top: 25px;
    ${isMobileMediaQuery} {
        margin-top: 0px;
    }

    ${notMobileMediaQuery} {
        .section-title:first-of-type {
            margin-top: -12px;
        }
    }
`;

const Link = styled(RouterLink)`
    font-size: 15px;
    line-height: 1.4;
    display: flex;
    align-items: center;
    color: #fefeff;
`;

const ChevronLeft = styled.span`
    display: inline-block;
    background-color: #fff;
    mask-size: cover;
    mask-position: center;
    mask-image: url(${ChevronLeftIcon});
    object-fit: contain;
    height: 14px;
    width: 8px;
    margin-right: 9px;
`;

const StepsTitle = styled.p`
    margin: auto 50px auto 0px;
    text-transform: uppercase;

    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 2.2px;
    text-align: left;
    color: #666679;

    ${(props) => props.active && `color: #fff;`}
`;

const StepCircle = styled.div`
    margin: auto 10px auto 0px;
    text-transform: uppercase;

    width: 25px;
    height: 25px;
    padding: 0px 0px 2px 2px;
    box-sizing: border-box;
    background-color: #66667a;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: "SackersGothicStd";
    font-size: 15px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 10px;
    letter-spacing: 3px;
    text-align: center;
    color: #34343c;

    ${(props) => props.active && `background-color: #fff;`}
`;

export default Account;
