import React from "react";
import styled from "styled-components";
import { Flex } from "./../../helpers/styles";

const MobileBrandsList = ({ values, setValues, options, ...props }) => {
    const handleSelectItem = (type, id, checked) => {
        let newValues = { ...values };

        if (checked === true) {
            newValues[type] = [...(newValues[type] || []), id];
        } else {
            newValues[type] = (newValues[type] || []).filter(
                (item) => item !== id
            );
        }

        setValues(newValues);
    };

    return (
        <Container width="100%" height="auto" padding="0px 0px 15px 0px">
            <FilterTitle>
                <span>Marcas</span>
            </FilterTitle>
            <Scroll>
                {options &&
                    options.map((item, index) => (
                        <label key={`${item.label || item}_${index}`}>
                            {item.name || item}
                            <input
                                checked={
                                    values.brands &&
                                    values.brands.find(
                                        (findItem) =>
                                            findItem === (item.slug || item)
                                    )
                                        ? true
                                        : false
                                }
                                onChange={(e) =>
                                    handleSelectItem(
                                        "brands",
                                        item.slug || item,
                                        e.target.checked
                                    )
                                }
                                type="checkbox"
                            />
                        </label>
                    ))}
            </Scroll>
        </Container>
    );
};

const Container = styled(Flex)`
    flex: 1;
    height: auto;
    position: relative;
    width: calc(100% + 40px);
    margin-left: -20px;
    padding-top: 70px;

    label {
        width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px 15px 0px;
        text-transform: capitalize;
        box-sizing: border-box;
        cursor: pointer;

        font-family: "Graphik";
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 3.57;
        letter-spacing: 0.28px;
        text-align: left;
        color: #676778;

        :not(:last-of-type) {
            border-bottom: 1px solid #f4f4f5;
        }

        input {
            margin: 0px;
            margin-left: auto;

            position: relative;
            appearance: none;
            width: 15px;
            height: 15px;
            border: solid 1px #c9c9cf;
            background-color: #fff;
            transition-duration: 0.3s;
            cursor: pointer;

            &:checked {
                border-color: #e4858c;
                background-color: #e4858c;

                ::after {
                    content: "✓";
                    display: block;
                    text-align: center;
                    color: #fff;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    font-size: 10px;
                    font-weight: bold;
                    transform: translate(-50%, -50%);
                    line-height: 1;
                }
            }
        }
    }
`;

const Scroll = styled.div`
    width: 100%;
    max-width: 100%;
    height: 370px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 25px 20px 18px 20px;
    box-sizing: border-box;

    label {
        width: 100%;
    }
`;

const FilterTitle = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 50px;
    background: #cacad0;

    span {
        text-transform: uppercase;
        font-family: "SackersGothicStd";
        font-size: 11px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.82;
        letter-spacing: 2.2px;
        text-align: left;
        color: #ffffff;
    }
`;

export default MobileBrandsList;
