import React, { useState, useEffect } from "react";
import { useLocation, useParams, Link as RouterLink, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { Flex, isMobile, isMobileMediaQuery } from "../../helpers/styles";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import HowToBuy from "./HowToBuy";
import HowToSell from "./HowToSell";
import HeaderBackground from "../../assets/images/background-pagamentos3.jpg";
import WhatsappIcon from "../../assets/images/whatsapp.svg";
import EmailIcon from "../../assets/images/inverted-email.svg";
import ExpandableContainer from "../../components/ExpandableContainer";
import { openPrivacyNTerms } from "../../helpers";

const Faq = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { current_tab } = useParams();
    const tabs = {
        "como-comprar": "Como Comprar",
        "como-vender": "Como Vender",
    };
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    useEffect(() => {
        (!current_tab || current_tab === "") && navigate(`/faq/como-comprar${location.hash}`);
        const element = location.hash && document.querySelector(location.hash);
        if (element) {
            setTimeout(() => {
                const y = element.getBoundingClientRect().top + window.pageYOffset - 120;
                window.scrollTo({ top: y, behavior: "smooth" });
            }, 150);
        }
        // eslint-disable-next-line
    }, [location.pathname, location.hash]);

    return (
        <>
            <Header
                navigationItems={[
                    { label: "Compre", action: () => navigate("/categorias/novidades") },
                    { label: "Quero vender", action: () => navigate("/desapegue") },
                    { label: "Peça seu box", action: () => navigate("/box") },
                    { label: "Lojas Influencers", action: () => navigate("/lojas-influencers") },
                    { label: "Blog", action: () => navigate("/blog") },
                    { label: "Outlet", action: () => navigate("/outlet") },
                    { label: "Sale", action: () => navigate("/promocoes") },
                ]}
            />

            <HeaderContainer background={HeaderBackground}>
                <Flex flex="1" maxWidth="1222px" margin="auto" alignItems="flex-start" direction="column">
                    <HeaderTitle>
                        Ficou com alguma dúvida?
                        <br />A gente te ajuda!
                    </HeaderTitle>
                    <HeaderSubTitle>Confira as perguntas frequentes abaixo ou entre em contato.</HeaderSubTitle>
                </Flex>
            </HeaderContainer>

            <Flex height="auto" width="100%" desktop>
                <SubNavigation>
                    <Link to="/faq/como-comprar" active={current_tab === "como-comprar"}>
                        Como comprar
                    </Link>
                    <Link to="/faq/como-vender" active={current_tab === "como-vender"}>
                        Como vender
                    </Link>
                    <FakeLink onClick={() => openPrivacyNTerms("privacy")}>privacidade</FakeLink>
                    <FakeLink onClick={() => openPrivacyNTerms("terms")}>termos e condições</FakeLink>
                    <Link to="/faq#contato">contato</Link>
                </SubNavigation>
            </Flex>

            <Flex height="auto" width="100%" direction="column" background="#f3f5f5" mobile>
                <ExpandableContainer
                    value={mobileMenuOpen}
                    onChange={(value) => value !== undefined && setMobileMenuOpen(value)}
                    titlePadding="0 20px"
                    titleBoxSizing="border-box"
                    title={tabs[current_tab] || "Como comprar"}
                >
                    <Flex direction="column" alignItems="flex-start">
                        <NavigationItem
                            active={current_tab === "como-comprar"}
                            onClick={() => {
                                navigate("/faq/como-comprar");
                                setMobileMenuOpen(false);
                            }}
                        >
                            Como Comprar
                        </NavigationItem>
                        <NavigationItem
                            active={current_tab === "como-vender"}
                            onClick={() => {
                                navigate("/faq/como-vender");
                                setMobileMenuOpen(false);
                            }}
                        >
                            Como Vender
                        </NavigationItem>
                        <NavigationItem onClick={() => openPrivacyNTerms("privacy")}>Privacidade</NavigationItem>
                        <NavigationItem onClick={() => openPrivacyNTerms("terms")}>Termos de uso</NavigationItem>
                    </Flex>
                </ExpandableContainer>
            </Flex>

            <Flex alignItems="flex-start" padding={isMobile ? "40px 0 40px 0" : "50px 0px"}>
                <Flex
                    width={isMobile ? "100%" : "733px"}
                    padding={isMobile && "0 20px"}
                    direction="column"
                    margin={isMobile ? "0" : "0px 70px 0px 0px"}
                    boxSizing="border-box"
                >
                    {current_tab === "como-vender" ? <HowToSell /> : <HowToBuy history={location} />}
                </Flex>

                <Flex
                    width={isMobile ? "100%" : "419px"}
                    padding={isMobile && "0 20px"}
                    margin={isMobile ? "0" : "0px 0px auto 0px"}
                    direction="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    boxSizing="border-box"
                >
                    <PageTitle>PODE PERGUNTAR</PageTitle>

                    <Flex alignItems="flex-start" justifyContent="flex-start" margin="0 auto 60px 0">
                        <Text>
                            Aqui listamos as respostas para as principais dúvidas de como comprar e vender, política de
                            privacidade, termos e condições de uso e nossos canais de contato.
                        </Text>
                    </Flex>

                    <Flex alignItems="flex-start" justifyContent="flex-start" margin="0 auto 27px 0">
                        <Text>
                            Ainda tem dúvidas?
                            <br />
                            Envie sua mensagem para gente!
                        </Text>
                    </Flex>

                    <Flex direction="column" alignItems="flex-start" justifyContent="flex-start">
                        <Flex margin="0 auto 7px 0">
                            <IconImage src={EmailIcon} />
                            <Text>Compra, BOX e pedidos: vendas@repetit.com.br</Text>
                        </Flex>

                        <Flex margin="0 auto 7px 0">
                            <IconImage src={EmailIcon} />
                            <Text>Desapegos: equipe@repetit.com.br</Text>
                        </Flex>

                        <Flex margin="0 auto 7px 0">
                            <IconImage src={EmailIcon} />
                            <Text>Dúvidas em geral: contato@repetit.com.br</Text>
                        </Flex>

                        <Flex margin="0 auto 7px 0">
                            <IconImage src={WhatsappIcon} />
                            <Text as="a" href="https://api.whatsapp.com/send?phone=5511990085518">
                                (11) 99008-5518
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Footer />
        </>
    );
};

const HeaderContainer = styled.div`
    background-image: url(${(props) => props.background});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 230px;
    padding: 64px 0;
    box-sizing: border-box;

    ${isMobileMediaQuery} {
        padding: 30px;
        min-height: 360px;
    }
`;

const HeaderTitle = styled.h1`
    margin: 0px 0px 25px 0px;
    max-width: 495px;

    text-transform: uppercase;
    font-family: "SackersGothicStd";
    font-size: 20px;
    font-weight: 900;
    line-height: 1.3;
    letter-spacing: 4px;
    text-align: left;
    color: #34343b;

    ${isMobileMediaQuery} {
        text-align: center;
    }
`;

const HeaderSubTitle = styled(HeaderTitle)`
    margin: 0px;
    max-width: 380px;

    font-size: 11px;
    line-height: 1.3;
    letter-spacing: 2.2px;
    color: #ffffff;

    ${isMobileMediaQuery} {
        text-align: center;
    }
`;

const SubNavigation = styled.div`
    background-color: #313139;
    width: 100%;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
`;

const Link = styled(RouterLink)`
    margin: auto 44px auto 0px;
    text-transform: uppercase;
    text-decoration: none;

    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.82;
    letter-spacing: 2.2px;
    text-align: left;
    color: #666679;

    ${(props) => props.active && "color: #ffffff;"}
`;

const FakeLink = styled.a`
    margin: auto 44px auto 0px;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.82;
    letter-spacing: 2.2px;
    text-align: left;
    color: #666679;

    ${(props) => props.active && "color: #ffffff;"}
`;

export const Text = styled.div`
    font-size: 15px;
    line-height: 1.4;
    color: #676777;
    white-space: pre-wrap;

    strong,
    a {
        color: #676777;
        font-weight: 600;
    }
`;

const PageTitle = styled.h1`
    margin: 29px auto 34px 0;
    text-transform: uppercase;

    font-family: "SackersGothicStd";
    font-size: 20px;
    font-weight: 900;
    line-height: 0.8;
    letter-spacing: 4px;
    color: #34343b;
`;

export const IconImage = styled.img`
    width: 28px;
    height: 28px;
    margin-right: 8px;
`;

export const Underscore = styled.span`
    text-decoration: underline;
`;

const NavigationItem = styled.button`
    margin: auto 0;
    text-transform: uppercase;
    border: 0;
    background-color: transparent;
    cursor: pointer;

    object-fit: contain;
    font-family: "SackersGothicStd";
    font-size: 11.5px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: 2.3px;
    text-align: left;
    color: #8c8ca1;

    :not(:last-child) {
        margin-right: 62px;
    }

    ${(props) =>
        props.active &&
        css`
            color: #e4858c;
        `}

    ${isMobileMediaQuery} {
        width: 100%;
        box-sizing: border-box;
        padding: 20px 0;
        :not(:last-child) {
            border-bottom: 1px solid #e6e7e9;
        }
    }
`;

export default Faq;
