import * as React from "react";
import styled from "styled-components";
import { Flex } from "./../../helpers/styles";
import ReactTooltip from "react-tooltip";
import "./styles.css";

const PriceInfo = styled(Flex)`
    width: 100%;
    background-color: #f7f9f9;
    justify-content: space-between;
    text-transform: initial;
    padding: ${({ padding }) => (padding ? padding : "20px 20px")};
    box-sizing: border-box;

    font-family: "Graphik";
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    :not(:last-of-type) {
        border-bottom: 1px solid #fff;
    }
    :last-of-type {
        margin-bottom: 5px;
    }

    ${({ margin }) => margin && `margin: ${margin};`}
`;

const Link = styled.button`
    font-family: Graphik;
    border: none;
    background-color: transparent;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    padding: 0px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: right;
    text-decoration-line: underline;
    color: #676777;
`;

const Input = styled.input`
    display: flex;
    border: 1px solid #cacad1;
    boxsizing: border-box;
    padding-left: 16px;
    padding-right: 16px;
    font-family: Graphik;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    width: 100%;
    min-width: 150px;
    color: #676777;
`;

const Button = styled.button`
    width: 120px;
    height: 36px;
    border: none;
    cursor: pointer;
    font-style: normal;
    padding: 10px;
    font-weight: 850;
    font-size: 12px;
    line-height: 18px;
    text-transform: uppercase;
    background-color: #e4858c;
    color: #000;
`;

const FieldAddCoupon = styled.div`
    width: 100%;
    display: flex;
    flex: 1;
    margin-top: 24px;
    margin-bottom: 4px;
    flex-direction: row;
`;

const Error = styled.span`
    font-family: Graphik;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: #f30202;
`;

const Text = styled.span`
    white-space: nowrap;
    font-family: Graphik;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height, or 120% */

    text-transform: uppercase;

    color: #34343b;
`;

// .

const DiscountCoupon = ({ onClick, startOpened, cupomDiscount, value, isUsingCredit, infoDescription }) => {
    const [addCoupon, setAddCoupon] = React.useState(startOpened || false);
    const [finishCoupon, setFinishCoupon] = React.useState(cupomDiscount ? true : false);
    const [coupon, setCoupon] = React.useState(value || "");
    const [error, setError] = React.useState("");
    const [info, setInfo] = React.useState("");
    const [data, setData] = React.useState(
        cupomDiscount
            ? {
                  amount: cupomDiscount.amount,
                  description: cupomDiscount.description,
              }
            : {}
    );
    const [loading, setLoading] = React.useState(false);

    const removeCoupon = () => {
        setError("");
        setCoupon("");
        setFinishCoupon(false);
        setAddCoupon(false);
    };

    React.useEffect(() => {
        setCoupon(value);
    }, [value]);

    React.useEffect(() => {
        if (isUsingCredit) removeCoupon();
    }, [isUsingCredit]);

    React.useEffect(() => {
        if (cupomDiscount) setData(cupomDiscount);
        else setAddCoupon(true);
    }, [cupomDiscount]);

    React.useEffect(() => {
        setInfo(infoDescription);
    }, [infoDescription]);

    return (
        <PriceInfo>
            <span>Cupom de desconto</span>
            {!addCoupon && !cupomDiscount && <Link onClick={() => setAddCoupon(true)}>Adicionar cupom</Link>}
            {finishCoupon && (
                <Link
                    onClick={async () => {
                        setLoading(true);
                        await onClick("", (description, amount, percentage, err, info) => {
                            if (err) {
                                setError(err);
                            }
                            if (description && amount) {
                                setError("");
                                setCoupon("");
                                setFinishCoupon(false);
                                setAddCoupon(false);
                                setData({
                                    description,
                                    amount,
                                    percentage,
                                });
                            }
                            if (info) setInfo(info);
                        });
                        setLoading(false);
                    }}
                >
                    Remover cupom
                </Link>
            )}
            {!finishCoupon && addCoupon && (
                <>
                    <FieldAddCoupon>
                        <Input
                            placeholder="Cupom de desconto"
                            value={coupon}
                            onChange={(e) => setCoupon(e.target.value)}
                        />
                        <Button
                            disabled={coupon.length === 0}
                            onClick={async () => {
                                setLoading(true);
                                await onClick(coupon, (description, amount, percentage, err, info) => {
                                    if (err) {
                                        setError(err);
                                    }
                                    if (description && amount) {
                                        setError("");
                                        setFinishCoupon(true);
                                        setData({
                                            description,
                                            amount,
                                            percentage,
                                        });
                                    }
                                    if (info) setInfo(info);
                                });
                                setLoading(false);
                            }}
                        >
                            Aplicar
                        </Button>
                    </FieldAddCoupon>
                    <span>Cupom não aplicável para peças em promoção.</span>
                    {error && error !== "" && <Error>{error}</Error>}
                </>
            )}
            {finishCoupon && (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: 27,
                    }}
                >
                    <Text>{`${data?.description}`}</Text>

                    {info && info !== "" && (
                        <>
                            <ReactTooltip className={"tooltip"} />
                            <PriceInfo padding="0px 10px">
                                <span data-tip={info}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        color="#e4858c"
                                        fill="currentColor"
                                        class="bi bi-exclamation-circle-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                    </svg>
                                </span>
                            </PriceInfo>
                        </>
                    )}

                    <Text>{Math.abs(data?.amount) === 0 ? "Grátis" : `R$${data?.amount}`}</Text>
                </div>
            )}
        </PriceInfo>
    );
};

export default DiscountCoupon;
