import {
    USER_LOGIN,
    USER_PROFILE,
    USER_LOGOUT,
    USER_WALLET,
} from "../actions/user";

const initialState = {
    token: undefined,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case USER_LOGIN:
            return {
                ...state,
                ...action.payload,
            };

        case USER_PROFILE:
            return {
                ...state,
                ...action.payload,
            };

        case USER_WALLET:
            return {
                ...state,
                ...action.payload,
            };

        case USER_LOGOUT:
            localStorage.clear();
            return initialState;

        default:
            return state;
    }
};
