import React from "react";
import styled from "styled-components";

import Carousel from "nuka-carousel";

import CarrousselItem from "../CarrousselItem";
import RecreioCarrousselItem from "../RecreioCarrousselItem";
import { isMobile, Flex } from "../../helpers/styles";

const HeaderCarroussel = ({
    items,
    height,
    margin,
    hideIndicators,
    boxPage,
    action,
    ...props
}) => {

    return (
        <Container
            margin={margin}
            height={isMobile && !props.recreio ? "auto" : height}
        >
            {props.title && (
                <Column>
                    <Border top />

                    <Title>{props.title}</Title>

                    <Border />
                </Column>
            )}

            {isMobile && !props.recreio ? (
                <Flex
                    width="100%"
                    justifyContent="flex-start"
                    alignItems="center"
                    direction="column"
                    wrap="no-wrap"
                >
                    {items &&
                        items.map((item, index) => (
                            
                            <CarrousselItem
                                key={`carroussel_item_${index}`}
                                title={item.title}
                                subtitle={item.subtitle}
                                index={index}
                                id={item.id}
                                description={item.item_description}
                                link_text={item.link_text}
                                link_url={item.link_url}
                                color={item.text_color && item.text_color.hex_value ? item.text_color.hex_value : '#34343b'}
                                image={
                                    isMobile
                                        ? item.image_mobile_url ||
                                          item.image_url
                                        : item.image_url
                                }
                                height={height}
                                boxPage={boxPage}
                            />
                        ))}
                </Flex>
            ) : (
                <Carousel
                    autoplay
                    wrapAround
                    heightMode="current"
                    disableEdgeSwiping
                    defaultControlsConfig={{
                        prevButtonStyle: { display: "none" },
                        nextButtonStyle: { display: "none" },
                        pagingDotsStyle: { display: "none" },
                    }}
                    renderBottomCenterControls={(props) => (
                        <DotGroup>
                            {[...Array(items.length).keys()].map(
                                (item, index) => (
                                    <Dot
                                        key={`slide_dot${index}`}
                                        onClick={props.goToSlide.bind(
                                            null,
                                            index
                                        )}
                                        slide={index}
                                        current={
                                            props.currentSlide === index ? 1 : 0
                                        }
                                    />
                                )
                            )}
                        </DotGroup>
                    )}
                >
                    {items && items.map((item, index) => props.recreio ? 
                            (
                                <RecreioCarrousselItem
                                    key={`carroussel_item_${index}`}
                                    tag={item.tag}
                                    title={item.title}
                                    description={item.description}
                                    author={item.author && item.author.name}
                                    instagramUsername={
                                        item.author &&
                                        item.author.instagram_username
                                    }
                                    image={item.image}
                                    height={height}
                                    onClick={() => action(item)}
                                />
                            ) : (
                                <CarrousselItem
                                    key={`carroussel_item_${index}`}
                                    title={item.title}
                                    subtitle={item.subtitle}
                                    index={index}
                                    id={item.id}
                                    description={item.item_description}
                                    link_text={item.link_text}
                                    link_url={item.link_url}
                                    color={item.text_color && item.text_color.hex_value ? item.text_color.hex_value : '#34343b'}
                                    image={
                                        isMobile
                                            ? item.image_mobile_url ||
                                              item.image_url
                                            : item.image_url
                                    }
                                    height={height}
                                    boxPage={boxPage}
                                />
                            )
                        )
                        }
                </Carousel>
            )}
        </Container>
    );
};

const Container = styled.div`
    width: 100%;
    flex: 1;
    display: flex;
    height: ${(props) => props.height || 430}px;
    margin: ${(props) => props.margin};
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 41px;
    margin-bottom: auto;
`;

const Border = styled.span`
    ${(props) =>
        props.top
            ? `
            border-top: 2px solid #313139;
            margin-bottom: auto;
            `
            : `
            border-bottom: 4px solid #f3808b;
            margin-top: auto;
            `}
`;

const Title = styled.span`
    padding: 32px 0 30px 0;
    max-width: 217px;
    font-family: "SackersGothicStd";
    font-size: 32px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: 6.4px;
    color: #34343b;
    margin-left: auto;
    height: auto;
`;

const DotGroup = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 19px;
`;

const Dot = styled.div`
    padding: 0px;
    border: 0;
    background-color: #fff;
    border-radius: 100%;
    height: 11px;
    width: 11px;
    flex-basis: 11px;
    cursor: pointer;

    &:not(:last-child) {
        margin-right: 6px;
    }

    ${(props) => !props.current && `opacity: 0.5`}
`;

export default HeaderCarroussel;
