import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { openPrivacyNTerms } from "../../helpers";
import { Flex } from "../../helpers/styles";
import { Text, Underscore, IconImage } from "./index";
import QuestionContainer from "./QuestionContainer";
import { isMobile } from "./../../helpers/styles";
import WhatsappIcon from "../../assets/images/whatsapp.svg";
import ButtonLink from "../../components/ButtonLink";

const HowToBuy = ({ history }) => {
    const navigate = useNavigate();
    return (
        <>
            <QuestionContainer title="1. Como faço o meu cadastro?">
                <Text>
                    Cadastrar-se no site é simples:
                    <ol>
                        <li>
                            Clique em <strong>MINHA CONTA</strong> (no avatar) do lado direito superior da homepage.
                        </li>
                        <li>
                            Escolha a opção <strong>CRIAR CONTA</strong> ou entre pelo Facebook ou Google.
                        </li>
                        <li>Insira ou confirme seus dados cadastrais.</li>
                    </ol>
                </Text>
            </QuestionContainer>

            <QuestionContainer title="2. Qual a diferença entre Box e Peças Avulsas?">
                <Text>
                    {
                        <>
                            As peças avulsas são todas aquelas que estão disponíveis no site e tem a curadoria Re Petit.
                            Roupas, calçados, livros, brinquedos, objetos e muito mais para uma experiência completa. É
                            só clicar em
                            <strong> COMPRE</strong> no menu para escolher as peças que são a cara das crianças e
                            finalizar sua compra. Entregamos para todo Brasil!
                            <br />
                            <br />
                            Já o BOX é um serviço personalizado para o seu filho. Após responder o nosso QUIZ, a Re
                            Petit seleciona com carinho as peças de acordo com o estilo da criança. Para pedir um BOX só
                            clicar em <RouterLink to="/quiz/informacoes"> PEÇA SEU BOX </RouterLink>
                            <br />
                            O BOX é entregue na sua casa ou no endereço escolhido para provarem tudo com tranquilidade.
                            E o melhor: você fica apenas com o que quiser! <br />
                            Esse é um serviço disponível somente para São Paulo e Grande São Paulo.
                            <br />
                            <br />
                            <strong>Olha só como é fácil comprar no site:</strong>
                            <ol>
                                <li>
                                    Confira todas as peças na seção <strong>COMPRE.</strong>
                                </li>
                                <li>
                                    Gostou de uma? Clique em <strong>COLOCAR NA SACOLA</strong> e siga para{" "}
                                    <strong>CONTINUAR COMPRANDO</strong> ou <strong>FINALIZAR COMPRA.</strong>
                                </li>
                                <li>
                                    Complete seus <strong>DADOS DE ENTREGA</strong> e escolha sua{" "}
                                    <strong>OPÇÃO DE FRETE.</strong>
                                </li>
                                <li>
                                    Selecione uma <strong>FORMA DE PAGAMENTO</strong> e aproveite para{" "}
                                    <strong>CONFERIR O PEDIDO.</strong>
                                </li>
                                <li>
                                    Clique em <strong>FINALIZAR COMPRA</strong> e pronto! Aguarde o pedido chegar na sua
                                    casa.
                                </li>
                            </ol>
                            <strong>Vem descobrir como pedir e comprar seu BOX!</strong>
                            <br />
                            Confira todos os detalhes em <RouterLink to="/box">COMO FUNCIONA O BOX</RouterLink>
                            ?
                            <br />
                            <br />
                            <strong>Fique de olho:</strong>
                            <br />
                            *O serviço de BOX está disponível apenas para a cidade de São Paulo e em cidades pontuais na
                            Grande São Paulo (Santo André, São Bernardo e São Caetano)
                        </>
                    }
                </Text>
            </QuestionContainer>

            <QuestionContainer title="3. Gostei de uma peça, mas não está mais disponível. Vai chegar reposição?">
                <Text>
                    As peças da Re Petit são garimpadas uma a uma, com muito amor e um critério rigoroso de avaliação.
                    Por isso mesmo, são únicas e não contam com opções de cores, modelos e numeração.
                    <br />
                    <br />
                    Se quiser, podemos auxiliar você a encontrar a peça ideal no site ou no BOX. Confira os canais de
                    atendimento em <RouterLink to="/faq/como-comprar#contato">CONTATO</RouterLink>.
                </Text>
            </QuestionContainer>

            <QuestionContainer title="4. Como funciona o BOX?">
                <Text>
                    <strong>Pedir e comprar o BOX é muito fácil e divertido. Olha só:</strong>
                    <br />
                    <ol>
                        <li>
                            <strong>FAÇA SEU ACESSO</strong>.
                            <br />
                            Acesse ou crie sua conta e clique em{" "}
                            <RouterLink to="/quiz/informacoes">PEÇA SEU BOX</RouterLink>.
                        </li>
                        <li>
                            <strong>RESPONDA AO QUIZ</strong>.
                            <br />
                            Em minutos você responde às perguntas do quiz para descobrirmos tudo o que seu filho precisa
                            e gosta. Peças desejadas, numeração e o estilo!
                        </li>
                        <li>
                            <strong>CONFIRA SEU PEDIDO</strong>.
                            <br />
                            No momento de <strong>CONFERIR</strong> fique atento ao endereço de entrega, e você pode{" "}
                            <strong>FINALIZAR</strong> o seu pedido.
                        </li>
                        <li>
                            <strong>RECEBA O BOX</strong>.
                            <br />
                            Em até 7 dias úteis o BOX é entregue com aproximadamente 30 peças únicas em excelente estado
                            e selecionadas com carinho para o seu filho. 
                        </li>
                        <li>
                            <strong>COMPRE SÓ O QUE GOSTAR</strong>.
                            <br />
                            Prove todas as peças com tranquilidade e selecione as que gostou. O valor de cada peça está
                            no formulário. Atenção: Deixamos o box, em média, por 3 dias na casa do cliente para a
                            escolha das peças.
                        </li>
                        <li>
                            <strong>FINALIZE SUA COMPRA</strong>.
                            <br />
                            Para comprar entre com seu login e senha no site no conto superior em{" "}
                            <strong>MINHA CONTA</strong> – clique em <strong>MEU BOX</strong>, as peças estarão listadas
                            com seus valores, selecione as peças que gostou com um clique no botão{" "}
                            <strong>COMPRAR</strong>, e finalize a sua compra.
                        </li>
                    </ol>
                    <br />
                    <strong>Quer calcular o valor do frete do seu frete?</strong>
                    <br />
                    <br />
                    Na nossa calculadora você pode confirmar se entregamos ou não na sua casa e simular o valor do
                    frete. É importante lembrar que:
                    <br />
                    <br />
                    O frete é grátis em todas as compras a partir de R$ 500,00 para a cidade de São Paulo e R$ 600,00
                    para cidades pontuais na Grande São Paulo (Santo André, São Bernardo e São Caetano)
                    <br />
                    <br />
                    Simule em nossa <RouterLink to="/box#calculadora">CALCULADORA</RouterLink>.
                    <br />
                    <br />
                    <strong>DEVOLVER O BOX TAMBÉM É SIMPLES:</strong>
                    <ol>
                        <li>
                            <strong>LACRE O BOX</strong>.
                            <br />
                            Coloque as peças que não for comprar dentro do Box (malinha) novamente, feche a mala com o
                            lacre que está dentro do envelope pardo.
                        </li>
                        <li>
                            <strong>AGENDAMENTO DA RETIRADA</strong>.
                            <br />
                            Aguarde o contato da nossa equipe para marcar o dia e horário da retirada do BOX no seu
                            endereço.
                        </li>
                        <li>
                            <strong>TEM PEÇAS PARA VENDER NA RE PETIT? ENVIE JUNTO NA RETIRADA!</strong>
                            <br />
                            Se tiver peças para desapegar, coloque-as dentro da sacola que enviamos. Assim, quando você
                            for devolver seu BOX, já pode aproveitar a viagem e nos mandar peças para vender.
                            <br />
                            <br />O que aceitamos:
                            <ul>
                                <li>Roupas, sapatos, brinquedo, livros e acessórios de 0 a 14 anos.</li>
                                <li>
                                    Para saber mais detalhes do que aceitamos e as marcas que trabalhamos{" "}
                                    <RouterLink to="/o-que-vender#marcas">CLIQUE AQUI</RouterLink>.
                                </li>
                            </ul>
                            <br />
                            Critérios para aceitação:
                            <ul>
                                <li>Só aceitamos peças e objetos bem conservadas e originais</li>
                                <li>
                                    Peças manchadas, amareladas, desfiadas, furadas, rasgadas ou com qualquer tipo de
                                    avaria não entram na seleção.
                                </li>
                                <li>
                                    Peças de tecido animal ou em couro sintético, bem como roupa íntima e meias já
                                    usadas, não são aceitas.
                                </li>
                                <li>
                                    <strong>Brinquedos e Livros: </strong>
                                    Aceitamos apenas brinquedos com todas as peças e funcionando.
                                </li>
                                <li>
                                    <strong>Utensílios: </strong>
                                    Chupetas, mamadeiras e utensílios de boca para bebê somente novos e na embalagem de
                                    origem.
                                </li>
                                <li>O mínimo de peças para envio são 20 itens.</li>
                                <li>
                                    Preencha o formulário <RouterLink to="/quero-vender">QUERO VENDER</RouterLink>.
                                </li>
                                <br />
                                <strong>Olho na limpeza! </strong>As peças enviadas já devem estar lavadas e limpas.
                                Atenção a sola dos calçados.
                            </ul>
                        </li>
                        <br />
                        <br />
                        Para mais informações, entre em contato:{" "}
                        <a href="https://api.whatsapp.com/send?phone=5511976922404">
                            <img src={WhatsappIcon} height={isMobile ? "16px" : "auto"} alt="Whatsapp logo" /> (11)
                            95376-4930
                        </a>
                    </ol>
                    <strong>Fique de olho:</strong>
                    <ol>
                        <li>
                            O serviço de BOX está disponível apenas para a cidade de São Paulo e em cidades pontuais na
                            Grande São Paulo (Santo André, São Bernardo do Campo, São Caetano, Carapicuíba, Diadema,
                            Mauá, Barueri, Osasco e Guarulhos).
                        </li>
                    </ol>
                </Text>
            </QuestionContainer>

            <QuestionContainer title="5. Eu queria mudar uma resposta no meu QUIZ, como faço?">
                <Text>
                    Se você preencheu o QUIZ e precisa mudar alguma resposta, entre em contato com a nossa equipe no{" "}
                    <a href="mailto:vendas@repetit.com.br">vendas@repetit.com.br</a> ou pelo{" "}
                    <a href="https://api.whatsapp.com/send?phone=5511976922404">
                        <img src={WhatsappIcon} height={isMobile ? "16px" : "auto"} alt="Whatsapp logo" /> (11)
                        95376-4930
                    </a>
                    .
                </Text>
            </QuestionContainer>

            <QuestionContainer id="formas-de-pagamento" title="6. Quais são as formas de pagamento?">
                <Text>
                    <ul>
                        <li>
                            <strong>CARTÃO DE CRÉDITO</strong>. À vista ou parcelado em até 4 vezes (parcela mínima de R$100) 
                            Compras acima de R$1.000 parcelamos em 2x sem juros, compras acima de R$2.000 em 3x sem juros.
                        </li>
                        <li>
                            <strong>PIX</strong>. À vista. 3% de desconto.
                        </li>
                        <li>
                            <strong>SALDO RE PETIT</strong>. Apenas para vendedoras: parcela única descontada dos seus
                            créditos no site. 10% de desconto.
                        </li>
                    </ul>
                </Text>
            </QuestionContainer>

            <QuestionContainer title="7. Qual o prazo de entrega e como rastreio o meu pedido ?">
                <Text>
                    <strong>
                        <Underscore>PRAZO DE ENTREGA</Underscore>
                    </strong>
                    <br />
                    <br />
                    <strong>
                        <Underscore>Para peças avulsas:</Underscore>
                    </strong>
                    <br />
                    <br />O prazo de entrega para peças avulsas varia de acordo com o prazo estipulado pelas empresas de
                    entrega parceiras. Confira o prazo no momento de fechar o seu pedido e escolher o tipo de entrega.
                    <ol>
                        <li>
                            Eco Entrega ( Bicicleta): Válida somente para cidade de São Paulo e Grande SP. Prazo de até
                            3 dias úteis.
                        </li>
                        <li>PAC ou Sedex: Entregas para todo Brasil. Prazo estipulado de acordo com o seu CEP.</li>
                        <li>JAD LOG: Entregas para todo Brasil. Prazo estipulado de acordo com o seu CEP.</li>
                        <li>Entrega Expressa: Válida somente para cidade de São Paulo. Prazo de até 2 dias úteis.</li>
                        <li>
                            Retirada na Re Petit: Pode ser feita em 24h após a aprovação do pagamento.
                            <br /> Endereço: Rua Andréa Paulinetti, 53 - CEP 04707-050, Brooklin, São Paulo/SP
                            <br /> De 2ª à 5ª das 9h as 17h00 6ª das 9h as 16h.
                        </li>
                    </ol>
                    <br />
                    <strong>
                        <Underscore>Para o box:</Underscore>
                    </strong>
                    <br />
                    <br />
                    Já o prazo para entregas do BOX é em até 7 dias úteis, lembrando que este serviço só é válido para
                    São Paulo e para cidades pontuais da Grande São Paulo (Santo André, São Bernardo e São Caetano)
                    <br />
                    <br />
                    <strong>Fique de olho:</strong>
                    <ol>
                        <li>
                            O prazo começa a contar a partir da aprovação do pagamento pela operadora do seu cartão ou
                            da confirmação do boleto.
                        </li>
                        <li>
                            A Re Petit não se responsabiliza por eventuais atrasos na entrega que sejam da
                            responsabilidade dos serviços terceirizado.
                        </li>
                    </ol>
                    <br />
                    <strong>
                        <Underscore>RASTREIO</Underscore>
                    </strong>
                    <br />
                    <br />
                    Quer acompanhar o seu pedido? Você receberá no seu email o código e o link para rastreio, caso não
                    tenha recebido, entre em contato através do email vendas@repetit.com.br
                    <br />
                    <br />
                    <strong>AINDA TEM DÚVIDAS?</strong>
                    <br />
                    Entre em <RouterLink to="/contato">CONTATO</RouterLink>.
                </Text>
            </QuestionContainer>

            <QuestionContainer id="politica-de-troca" title="8. Qual é a Política de Troca e Devolução?">
                <Text>
                    Trocar ou devolver uma peça é simples:
                    <ol>
                        <li>
                            Você tem <strong>7 dias</strong> corridos após receber o seu pedido para entrar em{" "}
                            <ButtonLink onClick={() => navigate("/faq#contato")}>CONTATO</ButtonLink> e pedir a
                            devolução.
                        </li>
                        <li>
                            A Re Petit tem o prazo máximo de até 7 dias (contados a partir da data de entrega da compra)
                            para o recebimento do produto a ser devolvido ou do envio do código de postagem. O mesmo
                            deve ser devolvido em perfeitas condições, sem uso e com a etiqueta da Re Petit.
                        </li>
                        <li>
                            Ao chegar aqui, conferimos a devolução e estornamos o valor em um prazo de 4 dias úteis.
                        </li>
                    </ol>
                    <br />
                    Atenção: Não trocamos peças do BOX e peças compradas no nosso acervo.
                    <br />
                    Saiba mais em{" "}
                    <ButtonLink onClick={() => openPrivacyNTerms("terms")}>POLÍTICA DE TROCA E DEVOLUÇÃO</ButtonLink>.
                </Text>
            </QuestionContainer>

            <QuestionContainer title="9. Como recebo meu estorno?">
                <Text>
                    É só aguardar a peça ser recebida aqui e conferida por nossa equipe. <br />
                    <br />
                    Confira como funciona cada um deles:
                    <ol>
                        <li>
                            <strong>Cartão de crédito</strong>.
                            <br />O crédito será feito pela operadora do seu cartão na próxima fatura seguinte.
                        </li>
                        <li>
                            <strong>Boleto</strong>.
                            <br />
                            Pagamento em parcela única em até 4 dias na conta bancária cadastrada.
                        </li>
                        <li>
                            <strong>Saldo Re Petit</strong>.
                            <br />
                            Valor correspondente à compra é liberado como crédito em sua conta Re Petit.
                        </li>
                        <li>
                            <strong>PIX</strong>.
                            <br />
                            Pagamento em parcela única via PIX.
                        </li>
                    </ol>
                </Text>
            </QuestionContainer>

            <QuestionContainer title="10. Tive problemas na hora do pagamento. E agora?">
                <Text>
                    Confira os possíveis impeditivos para sua compra e fique tranquilo: estamos aqui para ajudar você a
                    solucionar qualquer um deles!
                    <br />
                    <br />
                    <strong>Pagamento em análise.</strong>
                    <br />
                    É parte normal do processo de compra o pagamento ser analisado até o momento da confirmação do
                    débito. Seja em cartão de crédito, débito ou depósito em conta a aprovação pode tardar até 3 dias.
                    Aguarde: você será notificado(a) por e-mail assim que a compra for aprovada.
                    <br />
                    <br />
                    <strong>Pagamento cancelado.</strong>
                    <br />
                    São 3 as principais causas de cancelamento:
                    <br />
                    <br />
                    <strong>- Saldo insuficiente.</strong> Se a conta bancária cadastrada não tiver saldo para realizar
                    a compra, o seu pagamento será cancelado. Neste caso, é só cadastrar uma nova conta no site para o
                    débito do valor.
                    <br />
                    <br />
                    <strong>- Divergência de dados.</strong> O cancelamento também pode ocorrer ao usar dados de
                    pagamento diferentes dos cadastrados no site como o nome titular do cartão, por exemplo. Confira as
                    informações cadastradas na Re Petit e, se preciso, atualize seus dados.
                    <br />
                    <br />
                    <strong>- Suspeita de fraude.</strong> As instituições financeiras estão cada dia mais cuidadosas e
                    em caso de qualquer suspeita de fraude, seu pagamento pode ser bloqueado. Entre em contato com a
                    instituição do seu cartão e verifique se há algum bloqueio.
                    <br />
                    <br />
                    <strong>AINDA TEM DÚVIDAS?</strong>
                    <br />
                    Entre em com nossa equipe: <a href="mailto:vendas@repetit.com.br">vendas@repetit.com.br</a> ou (11)
                    95376-4930
                </Text>
            </QuestionContainer>

            <QuestionContainer title="11. Não recebi meu produto. E agora?">
                <Text>
                    Se a sua entrega é pelos Correios é só usar código rastreador que enviamos por e-mail para localizar
                    o seu pedido.
                    <br />
                    <br />
                    <strong>Rastreamento de produtos dos Correios:</strong>{" "}
                    <a href="www.correios.com.br/rastreamento">www.correios.com.br/rastreamento</a>
                    <br />
                    <br />
                    Se a sua entrega é de bicicleta - Eco Entrega - Entre em contato com a gente, mas conforme detalhado
                    no fechamento da compra o prazo é de até 3 dias após a efetivação do pagamento.
                    <br />
                    <br />
                    Se ainda assim não conseguir localizar a sua peça, entre em contato com a gente. Vamos tomar todas
                    as providências necessárias para você receber seu pedido Re Petit o quanto antes. Confira os canais
                    de atendimento em <RouterLink to="/contato">CONTATO</RouterLink>.
                </Text>
            </QuestionContainer>

            <QuestionContainer title="12. A Re Petit tem loja física?">
                <Text>A Re Petit não tem loja. Atendemos somente online.</Text>
            </QuestionContainer>

            <QuestionContainer id="contato" title="13. Quais os canais de atendimento?">
                <Text>
                    Escolha o canal de atendimento para enviar sua dúvida, crítica ou sugestão!
                    <br />
                    <strong>Compras, BOX e pedidos:</strong>{" "}
                    <a href="mailto:vendas@repetit.com.br">vendas@repetit.com.br</a> ou{" "}
                    <a href="https://api.whatsapp.com/send?phone=5511990085518">(11) 99008-5518</a>
                    <br />
                    <strong>Desapegos:</strong> <a href="mailto:equipe@repetit.com.br">equipe@repetit.com.br</a> ou{" "}
                    <a href="https://api.whatsapp.com/send?phone=5511976922404">(11) 97692-2404</a>
                    <br />
                    <strong>Dúvidas em geral:</strong>{" "}
                    <a href="mailto:contato@repetit.com.br">contato@repetit.com.br</a>
                    <br />
                    <Flex justifyContent="flex-start">
                        <IconImage src={WhatsappIcon} />{" "}
                        <a href="https://api.whatsapp.com/send?phone=5511990085518">(11) 99008-5518</a>
                    </Flex>
                </Text>
            </QuestionContainer>
        </>
    );
};

export default HowToBuy;
