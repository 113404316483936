import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Flex, isMobile, isMobileMediaQuery } from "../../helpers/styles";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import OutletGrid from "../../components/OutletGrid";
import api from "../../services/api";
import Loading from "../../components/Loading";

const Outlet = () => {
    const navigate = useNavigate();
    const [brandsData, setBrands] = useState([]);
    const getBrands = async () => {
        try {
            const response = await api.get("/stores/brands");
            setBrands(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getBrands();
    }, []);

    return (
        <>
            <Header
                navigationItems={[
                    { label: "Compre", action: () => navigate("/categorias/novidades") },
                    { label: "Quero vender", action: () => navigate("/desapegue") },
                    { label: "Peça seu box", action: () => navigate("/box") },
                    { label: "Lojas Influencers", action: () => navigate("/lojas-influencers") },
                    { label: "Blog", action: () => navigate("/blog") },
                    { label: "Outlet", action: () => navigate("/outlet") },
                    { label: "Sale", action: () => navigate("/promocoes") },
                ]}
            />

            <Helmet>
                <title>Re Petit: Outlet</title>
                <meta property="og:url" content={window.location.href} />
            </Helmet>

            <Flex desktop minWidth="1222px" background="#fff">
                <Flex
                    width="1222px"
                    minHeight="230px"
                    direction="column"
                    padding="32px"
                    margin="auto 0 auto 0"
                    alignItems="flex-start"
                    background="url(https://images.repetit.com.br/home_produtos/dest_908x230_meninas.jpg) center / cover no-repeat"
                    boxSizing="border-box"
                >
                    <Flex
                        alignItems="flex-start"
                        direction="column"
                        background="#fff"
                        padding="32px 24px"
                        minWidth="30%"
                        maxWidth="50%"
                    >
                        <HeaderTitle>Outlet</HeaderTitle>

                        <HeaderText>
                            Aqui você encontra nossas marcas parceiras, uma seleção incrível de marcas infantis que
                            estão alinhadas com os propósitos da Re Petit. Garimpe coleções passadas com descontos de
                            até 80%, fizemos uma curadoria especial de peças nunca usadas e cheias de estilo. Vem
                            circular com a gente!
                        </HeaderText>
                    </Flex>
                </Flex>
            </Flex>

            <Flex alignItems="flex-start" direction="column" mobile background="#F7F9F9" padding="16px 0px">
                <Flex width="100%" direction="column" margin="auto auto 16px auto" alignItems="center">
                    <HeaderTitle>Marcas Parceiras</HeaderTitle>
                </Flex>

                <img
                    width="100%"
                    height="auto"
                    src="https://images.repetit.com.br/home_produtos/dest_908x230_meninas.jpg"
                    alt="Criança feliz correndo com braços abertos"
                />

                <Flex
                    width="calc(100% - 80px)"
                    direction="column"
                    margin="30px auto 28px auto"
                    alignItems="center"
                    padding="0px 40px"
                >
                    <HeaderText>
                        Aqui você encontra nossas marcas parceiras, uma seleção incrível de marcas infantis que estão
                        alinhadas com os propósitos da Re Petit. Garimpe coleções passadas com descontos de até 80%,
                        fizemos uma curadoria especial de peças nunca usadas e cheias de estilo. Vem circular com a
                        gente!
                    </HeaderText>
                </Flex>
            </Flex>

            <Flex
                direction="column"
                width={isMobile ? "100%" : "1220px"}
                margin={isMobile ? "10px auto 0px auto" : "40px auto 0px auto"}
            >
                <Flex width="100%" alignItems={isMobile && "flex-start"} direction={isMobile && "column"}>
                    {!brandsData ? <Loading /> : <OutletGrid brands={brandsData} />}
                </Flex>

                <ContactText>
                    Tem interesse em ser uma marca parceira?
                    <a href="https://api.whatsapp.com/send?phone=5511990085518&text=Ol%C3%A1,%20tenho%20interesse%20em%20ser%20uma%20marca%20parceira!" target="_blank">
                        Entre em contato
                    </a>
                </ContactText>

            </Flex>

            <Footer />
        </>
    );
};

const HeaderTitle = styled.h1`
    margin: 0px;
    margin-bottom: 28px;

    font-family: "DMSerifDisplay";
    font-size: 38px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.95;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    ${isMobileMediaQuery} {
        font-size: 32px;
        text-align: center;
        margin: 0px;
    }

    :only-child {
        margin: 0px;
    }
`;

const HeaderText = styled.p`
    margin: 0px;

    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    ${isMobileMediaQuery} {
        font-size: 15px;
        text-align: center;
    }
`;

const ContactText = styled.p`
    margin: 0px;
    padding: 0 5px 20px 5px;
    font-family: "Graphik";
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: #34343b;
    a{
        -webkit-text-decoration: underline;
        text-decoration: underline;
        font-size: inherit;
        font-weight: 600;
        text-align: center;
        color: #34343b;
        cursor: pointer;
        padding-left: 5px;
        ${isMobileMediaQuery} {
           padding-left: 0px;
        }
    }
`;

export default Outlet;
