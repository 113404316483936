import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import api, { baseURL } from "../../services/api";
import * as UserActions from "../../redux/actions/user";
import * as CartActions from "../../redux/actions/cart";
import * as ModalActions from "../../redux/actions/loginModal";
import { Flex, isMobile } from "../../helpers/styles";
import AlertModal from "../AlertModal";
import Button from "../Button";
import TextInput from "../Inputs/TextInput";
import LoginHeroImage from "../../assets/images/login-hero.jpg";
import GooglelDark from "../../assets/images/google-dark.png";
import FacebookDark from "../../assets/images/facebook-dark.png";

function LoginModal() {
    const bodyElement = document.querySelector("body");
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loginModal = useSelector((state) => state.ui.loginModalReducer);
    const selectorProducts = useSelector((state) => state.cart.products) ?? [];
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({ email: "", password: "" });
    const [errors, setErrors] = useState({});
    const [modal, setModal] = useState({});

    useEffect(() => {
        if (loginModal && loginModal.show) {
            let top = window.scrollY;
            bodyElement.style.position = "fixed";
            bodyElement.style.top = `-${top}px`;
            bodyElement.style.left = "0px";
            bodyElement.style.right = "0px";
        } else {
            const scrollY = bodyElement.style.top;
            bodyElement.style.position = "";
            bodyElement.style.top = "";
            bodyElement.style.left = "";
            bodyElement.style.right = "";
            window.scrollTo(0, -parseInt(scrollY || "0"));
            setValues({});
        }
        // eslint-disable-next-line
    }, [loginModal.show, modal.show]);

    useEffect(() => {
        if (modal.title === "Esqueceu sua senha?") {
            handleShowForgotPasswordModal();
        }
        // eslint-disable-next-line
    }, [values.forgot_email]);

    const handleChange = (e) => {
        let {
            target: { name, value, checked, type },
        } = e;
        if (name !== undefined) {
            if (value !== undefined && type !== "checkbox") {
                setValues({
                    ...values,
                    [name]: (value && value.toString()).trim(),
                });
            } else if (type === "checkbox") {
                setValues({
                    ...values,
                    [name]: checked,
                });
            }
        }
    };

    const handleLogin = async (e) => {
        e && e.preventDefault();
        let { email, password } = values;
        if (!email || !password) {
            setErrors({
                ...errors,
                email: !email,
                password: !password,
            });
            return;
        }
        setErrors({});
        setLoading(true);
        let loginResponse = await api
            .post("/auth/login", {
                email,
                password,
            })
            .catch((res) => res.response);
        setLoading(false);
        if (loginResponse.status === 200) {
            dispatch(
                UserActions.login({
                    token: loginResponse.headers.token,
                    ...loginResponse.data,
                })
            );
            setLoading(true);
            await dispatch(UserActions.refreshProfile());
            setLoading(false);
            handleCloseLoginModal();
            localStorage.removeItem("quiz_values");
            if (selectorProducts.length === 0) {
                currentAbandonedCart();
            } else {
                saveAbandonedCart(selectorProducts.map((product) => product.id));
            }
            if (location.pathname === loginModal.redirectUrl) {
                window.location.reload();
            } else {
                navigate(loginModal.redirectUrl || "/");
            }
        } else {
            console.error(loginResponse);
            setValues((values) => ({ ...values, password: null }));
            if (
                loginResponse.status === 401 &&
                loginResponse.data &&
                loginResponse.data.message === "Invalid credentials"
            ) {
                setModal({
                    show: true,
                    message: "O e-mail ou senha digitados estão incorretos, por favor verifique e tente novamente!",
                });
            } else {
                setModal({
                    show: true,
                    message: "Erro inesperado. Aguarde um instante e tente outra vez!",
                });
            }
        }
    };

    const currentAbandonedCart = async () => {
        try {
            let response = await api.get(`/carts/current/items`);

            if (response.status === 200) {
                response.data.forEach((product) => {
                    dispatch(CartActions.addProduct(product));
                });
            } else {
                console.log("Erro em: /carts/current/items");
            }
        } catch (error) {
            console.error("error:", error);
        }
    };

    const saveAbandonedCart = async (idsProducts) => {
        try {
            let response = await api.post("/carts", {
                item_ids: idsProducts,
            });
            if (response.status === 204) {
                console.log("saveAbandonedCart: /carts");
            }
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    const handleCloseLoginModal = () => {
        dispatch(ModalActions.close());
    };

    const handleShowForgotPasswordModal = () => {
        setModal({
            show: true,
            title: "Esqueceu sua senha?",
            modalHeight: "auto",
            modalMinHeight: "360px",
            modalMaxHeight: "auto",
            heroWidth: "600px",
            heroHeight: "auto",
            content: (
                <>
                    <TextInput
                        width={isMobile ? "100%" : undefined}
                        margin="0 0 20px 0"
                        placeholder="E-mail"
                        name="forgot_email"
                        value={values.forgot_email}
                        onChange={handleChange}
                        error={errors.forgot_email}
                    />
                    <ForgotPasswordText>
                        Insira o seu e-mail cadastrado e aguarde as instruções para recuperar sua senha.
                    </ForgotPasswordText>
                    <Flex width="100%" margin="21px 0 28px 0" direction={isMobile ? "column" : "row"}>
                        <Button
                            margin="0 auto 0 0"
                            dark
                            filled
                            value="Enviar"
                            width={isMobile ? "100%" : "150px"}
                            onClick={handleSubmitForgotPassword}
                            disabled={!values.forgot_email}
                        />
                        <Button
                            padding={isMobile ? "0" : undefined}
                            margin={isMobile ? "30px auto 0 auto" : "0 0 0 auto"}
                            dark
                            link
                            value="Cancelar"
                            flex="1"
                            onClick={() => {
                                setModal({ ...modal, show: false });
                            }}
                        />
                    </Flex>
                </>
            ),
        });
    };

    const handleSubmitForgotPassword = async () => {
        const password_response = await api
            .post("/auth/forgot_password", {
                email: values.forgot_email,
            })
            .catch((err) => {
                return err.response;
            });

        if (password_response.status === 201) {
            setModal({
                show: true,
                title: "Yeah!",
                message: "Você receberá em seu e-mail um link para trocar sua senha.",
            });
        } else {
            console.error(password_response);

            setModal({
                show: true,
                message:
                    password_response && password_response.data && password_response.data.message
                        ? password_response.data.message
                        : "Erro inesperado. Aguarde um instante e tente outra vez!",
            });
        }
    };

    return (
        <>
            {loginModal ? (
                <AlertModal
                    show={!modal.show && loginModal.show}
                    title="Log In"
                    image={LoginHeroImage}
                    modalHeight={"auto"}
                    modalMinHeight={"640px"}
                    modalMaxHeight={"auto"}
                    heroWidth={"600px"}
                    heroHeight={"auto"}
                    contentPadding={isMobile ? "0 30px" : undefined}
                    content={
                        <>
                            <SubTitle>
                                vem circular <br />
                                com a re petit
                            </SubTitle>
                            <Separator />
                            <Text>Escolha como quer acessar sua conta.</Text>
                            <Button
                                as="a"
                                href={`${baseURL.replace("/v1/", "")}/auth/facebook`}
                                margin="0 0 20px 0"
                                justifyContent="flex-start"
                                dark
                                outlined
                                borderradius
                                width={isMobile ? "275px" : "300px"}
                                value={
                                    <>
                                        <LoginImage src={FacebookDark} />
                                        Entre com Facebook
                                    </>
                                }
                            />
                            <Button
                                as="a"
                                href={`${baseURL.replace("/v1/", "")}/auth/google_oauth2`}
                                margin="0 0 26px 0"
                                justifyContent="flex-start"
                                dark
                                outlined
                                borderradius
                                width={isMobile ? "275px" : "300px"}
                                value={
                                    <>
                                        <LoginImage src={GooglelDark} />
                                        Entre com Google
                                    </>
                                }
                            />{" "}
                            <SubTitle>Login com e-mail</SubTitle>
                            <Flex alignItems="flex-start" justifyContent="flex-start"></Flex>
                            <Flex
                                width="100%"
                                as="form"
                                onSubmit={handleLogin}
                                alignItems="flex-start"
                                justifyContent="flex-start"
                                margin="24px 0 0 0"
                                direction={isMobile ? "column" : "row"}
                            >
                                <TextInput
                                    width={isMobile ? "100%" : undefined}
                                    margin="0 0 20px 0"
                                    placeholder="E-mail"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    disabled={loading}
                                    error={errors.email}
                                />
                                <TextInput
                                    width={isMobile ? "100%" : undefined}
                                    margin="0 0 15px 0"
                                    type="password"
                                    placeholder="Senha"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    disabled={loading}
                                    error={errors.password}
                                />

                                <ForgotPasswordLink type="button" onClick={handleShowForgotPasswordModal}>
                                    Esqueci minha senha
                                </ForgotPasswordLink>

                                <Flex width="100%" margin="21px 0 28px 0" direction={isMobile ? "column" : "row"}>
                                    <Button
                                        margin="0 auto 0 0"
                                        dark
                                        filled
                                        borderradius
                                        value="Entrar"
                                        width={isMobile ? "100%" : "150px"}
                                        type="submit"
                                        disabled={loading}
                                    />
                                    <Button
                                        padding={isMobile ? "0" : undefined}
                                        margin={isMobile ? "30px auto 0 auto" : "0 0 0 auto"}
                                        dark
                                        link
                                        value="criar conta"
                                        flex="1"
                                        onClick={() => {
                                            handleCloseLoginModal();
                                            navigate("/cadastro/email");
                                        }}
                                        disabled={loading}
                                    />
                                </Flex>
                            </Flex>
                        </>
                    }
                    callback={handleCloseLoginModal}
                />
            ) : null}

            {modal && (
                <AlertModal
                    {...modal}
                    show={modal.show}
                    title={modal.title}
                    message={modal.message}
                    callback={() => setModal({ ...modal, show: false })}
                />
            )}
        </>
    );
}

const SubTitle = styled.h1`
    margin: 0px;
    text-transform: uppercase;

    font-family: "SackersGothicStd";
    font-size: 14px;
    font-weight: 900;
    line-height: 1.29;
    letter-spacing: 2.8px;
    text-align: left;
    color: #34343b;
`;

const Separator = styled.hr`
    width: 40px;
    height: 3px;
    margin: 15px 0px;
    background-color: #e4858c;
    border: none;
`;

const Text = styled.p`
    flex: 1;
    margin: 0 0 23px 0;

    font-family: "Graphik";
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;
`;

const LoginImage = styled.img`
    margin-right: 13px;
    width: 25px;
    height: auto;
`;

const ForgotPasswordLink = styled.button`
    border: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;
    text-decoration: underline;
    padding: 0px;
    margin: 0;

    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.86;
    letter-spacing: 0.28px;
    text-align: left;
    color: #34343b;
`;

const ForgotPasswordText = styled.span`
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;
`;

export default LoginModal;
