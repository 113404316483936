import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import * as CheckoutActions from "../../redux/actions/checkout";
import { generateUUID } from "../../components/Functions";
import { Flex, isMobile, isMobileMediaQuery } from "./../../helpers/styles";
import Header from "./../../components/Header";
import Footer from "../../components/Footer";
import Button from "../../components/Button";
import chevronLeftIcon from "./../../assets/images/seta.svg";
import successHero from "../../assets/images/buy-success.jpg";
import successHeroMobile from "../../assets/images/buy-success-mobile.jpg";
import clockIcon from "../../assets/images/clock-icon.png";
import Boleto from "boleto.js";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "react-qr-code";

const Home = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [copied, setCopied] = useState(false);
    const [pixCode, setPixCode] = useState("");
    const payment_info = useSelector((state) => state.checkout);
    const user = useSelector((state) => state.user);
    if (!payment_info || Object.keys(payment_info).length === 0 || !payment_info.payment_method) {
        navigate("/");
    }
    const { payment_method, sale, warning } = payment_info;
    const number = payment_info && payment_info.boleto_info && payment_info.boleto_info.line_code;

    useEffect(() => {
        window.addEventListener("beforeunload", clearData);
        setPixCode(payment_info.payment_code);

        if (number && payment_method === "boleto") {
            new Boleto(number).toSVG("#boleto");
        }

        if (!payment_info || Object.keys(payment_info).length === 0 || !payment_info.payment_method) {
            return () => {
                dispatch(CheckoutActions.clear());
            };
        }

        if (localStorage.checkout_event !== sale.id) {
            localStorage.setItem("checkout_event", sale.id);

            // eslint-disable-next-line
            dataLayer.push({
                event: "pageview",
                page: {
                    path: "/checkout/sucesso",
                    title: "Checkout Sucesso",
                },
            });

            // eslint-disable-next-line
            dataLayer.push({ ecommerce: null });
            // eslint-disable-next-line
            dataLayer.push({
                event: "virtualPageview",
                page: document.title,
                type: "CheckoutSuccess",
                environment: process.env.REACT_APP_ENV,
                user: [
                    {
                        name: user.first_name + " " + user.last_name,
                        username: user.slug,
                        user_id: user.id,
                        email: user.email,
                        language: "pt-br",
                        returning: JSON.parse(localStorage.getItem("accepted_cookies")) ? true : false,
                    },
                ],
            });

            // eslint-disable-next-line
            dataLayer.push({ ecommerce: null });
            // eslint-disable-next-line
            dataLayer.push({
                event: "transaction",
                ecommerce: {
                    purchase: {
                        actionField: {
                            id: sale.id,
                            affiliation: "Re Petit",
                            revenue: sale.total,
                            tax: 0,
                            shipping: sale.shipping_charged_amount,
                            //coupon: "CUPOM",
                        },
                        products: payment_info.products.reduce((acc, curr) => {
                            return [
                                ...acc,
                                {
                                    name: `${curr.item_category?.name} ${curr.brand?.name}`,
                                    id: curr.id,
                                    price: curr.price_label,
                                    brand: curr.brand?.name,
                                    category: curr.item_category?.name,
                                    quantity: 1,
                                },
                            ];
                        }, []),
                    },
                },
            });
        }

        let contents_purchase = [];
        let valueTotal = 0;
        // payment_info.products.forEach((product) =>
        payment_info.products
            .filter((item) => !item.box_item)
            .map((product) => {
                valueTotal = valueTotal + parseFloat(product.price_label);
                contents_purchase.push({
                    id: product.id,
                    quantity: 1,
                    item_price: product.price_label,
                });
            });
        let userSessionServerData = window.userCurrentServerData(user);
        let event_id = generateUUID();
        let event_time = Math.round(Date.now() / 1000);

        window.fbq(
            "track",
            "Purchase",
            {
                value: parseFloat(valueTotal),
                currency: "BRL",
                contents: contents_purchase,
                content_type: "product",
            },
            { eventID: event_id }
        );

        let facebookEvent = api
            .post("/facebook_ads_event", {
                data: [
                    {
                        action_source: "website",
                        event_id: event_id,
                        event_name: "Purchase",
                        event_time: event_time,
                        user_data: userSessionServerData,
                        contents: contents_purchase,
                        custom_data: {
                            content_type: "product",
                            currency: "BRL",
                            value: parseFloat(valueTotal),
                        },
                    },
                ],
            })
            .then((res) => console.log("EVENTO Purchase", res.data))
            .catch((res) => res.response);

        return () => {
            window.removeEventListener("beforeunload", clearData);
            dispatch(CheckoutActions.clear());
        };

        // eslint-disable-next-line
    }, []);

    const copyToClipboard = () => {
        navigator.clipboard
            .writeText(pixCode)
            .then(() => {
                setCopied(true);
            })
            .catch((error) => {
                console.error("Erro ao copiar para a área de transferência:", error);
            });
    };

    const clearData = () => {
        dispatch(CheckoutActions.clear());
    };

    return (
        <>
            <Helmet>
                <title>Re Petit - Checkout Sucesso</title>
            </Helmet>

            <Header
                hideLinks
                subNavigation={
                    <Flex flex="1" maxWidth={isMobile ? "100%" : "1222px"} margin="auto" justifyContent="flex-start">
                        <AccountLink to="/minha-conta">
                            <ChevronLeft />
                            Voltar para a minha conta
                        </AccountLink>
                    </Flex>
                }
            />

            <Container>
                <Flex>
                    <HeroImage src={isMobile ? successHeroMobile : successHero} />

                    <Flex
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        direction="column"
                        maxWidth="540px"
                        margin="40px auto 40px 0"
                    >
                        <Title>
                            {payment_method === "credit_card" && "SEU PEDIDO ESTA EM ANÁLISE"}

                            {payment_method === "debit" && "Você está quase terminando!"}

                            {payment_method === "boleto" && "Você tem até 24 horas para pagar"}

                            {payment_method === "credit" && "Sua compra foi confirmada com sucesso."}

                            {payment_method === "pix" && `Pague via Pix em até 24h e garanta a sua compra!`}
                        </Title>

                        <Separator />

                        <Message>
                            {warning && (
                                <p style={{ color: "tomato" }}>
                                    {warning}
                                    <br />
                                </p>
                            )}

                            {sale && sale.id && (
                                <>
                                    Número do pedido: {sale.id}
                                    <br />
                                    Valor do pedido: R${" "}
                                    {payment_info.total_sale.toLocaleString("pt-br", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                    <br />
                                </>
                            )}

                            {payment_method === "credit_card" && (
                                <>
                                    O pagamento esta em fase de aprovação junto a operadora de seu cartão.
                                    <br />
                                    <br />
                                    Você recebera por email as confirmações e mais informações sobre entrega em breve.
                                    <br />
                                    <br />
                                    Obrigada por circular com a Re Petit!
                                </>
                            )}

                            {payment_method === "boleto" && (
                                <>
                                    Para pagar pelo Internet Banking, copie a linha digitável ou escaneie o código de
                                    barras. Para pagar em qualquer banco, caixa eletrônico ou lotérica, por favor
                                    imprima o boleto.
                                </>
                            )}

                            {payment_method === "debit" && (
                                <>
                                    Para finalizar sua compra clique no link abaixo e pague com seu cartão de débito
                                    Itau.
                                    <br />
                                    <br />
                                    <a href={payment_info.redirect_url} target="_blank" rel="noopener noreferrer">
                                        {payment_info.redirect_url}
                                    </a>
                                    <br />
                                    <br />
                                    Após finalizado, o pagamento entrará em fase de aprovação junto a operadora de seu
                                    cartão.
                                    <br />
                                    <br />
                                    Você recebera por email as confirmações e mais informações sobre entrega em breve.
                                    <br />
                                    <br />
                                    Obrigada por circular com a Re Petit!{" "}
                                </>
                            )}

                            {payment_method === "credit" && (
                                <>
                                    Comprando com seu saldo Re Petit você está contribuindo ainda mais para a moda
                                    circular.
                                    <br />
                                    <br />
                                    Obrigado pela parceria, o planeta agradece!
                                </>
                            )}
                        </Message>

                        {/* EXIBINDO A DIV COM O QRCODE DO PIX E COPIA E COLA */}
                        {payment_method === "pix" && pixCode && (
                            <>
                                {payment_info.amount_due && (
                                    <>
                                        <TextSection margin="20px 0px 0px 0px">
                                            Valor a pagar: R${" "}
                                            {payment_info.amount_due.toLocaleString("pt-br", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </TextSection>
                                    </>
                                )}
                                <div
                                    style={{
                                        height: "auto",
                                        margin: "20px auto 0px auto",
                                        maxWidth: 150,
                                        width: "100%",
                                    }}
                                >
                                    <QRCode
                                        size={256}
                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                        value={pixCode}
                                        viewBox={`0 0 256 256`}
                                    />
                                </div>
                                <TextSection margin="20px 0px 0px 0px">Pix Copia e Cola:</TextSection>
                                <CardBoxTitle>{pixCode}</CardBoxTitle>
                                <Flex direction="column" width="100%" justifyContent="center" alignItens="flex-between">
                                    <Button
                                        width="260px"
                                        padding="25px 1.25rem"
                                        lower="initial"
                                        pink
                                        filled
                                        borderradius
                                        fontFamily="Graphik"
                                        bigger
                                        fontWeight="600"
                                        value="Copiar para a Área de Transferência"
                                        onClick={copyToClipboard}
                                    />
                                </Flex>
                                {copied && <p>PIX copiado para a área de transferência!</p>}

                                <Message>
                                    Ou se preferir:
                                    <br />
                                    <ol>
                                        <li>Copie o código PIX.</li>
                                        <li>Acesse o app do seu banco e escolha realizar o pagamento via PIX.</li>
                                        <li>Cole o código e finalize o pagamento.</li>
                                    </ol>
                                </Message>
                                <Flex alignItems="center" justifyContent="flex-start" margin="10px 0 0 0" width="100%">
                                    <ClockIcon />
                                    <Hint>
                                        {" "}
                                        Realize o pagamento em até 24 horas.
                                        <br />
                                        Caso contrário, seu pedido será cancelado e você deverá fazer um novo.
                                    </Hint>
                                </Flex>
                            </>
                        )}

                        {payment_method === "boleto" && (
                            <>
                                <Flex alignItems="center" justifyContent="flex-start" margin="29px 0 0 0" width="100%">
                                    <ClockIcon />
                                    <Hint>
                                        {" "}
                                        Pagamento efetuados de segunda a sexta, é creditado no dia seguinte. Se você
                                        paga no fim de semana, será creditado na terça-feira.
                                    </Hint>
                                </Flex>

                                {number && <BarcodeContainer id="boleto"></BarcodeContainer>}

                                <BarcodeNumbers>{number}</BarcodeNumbers>

                                <Flex width="100%" alignItems="flex-start">
                                    <CopyToClipboard text={number}>
                                        <Button
                                            id="copy-btn"
                                            width={isMobile ? "100%" : "260px"}
                                            margin={isMobile ? "0 auto 10px auto" : "0 auto 0 0"}
                                            filled
                                            dark
                                            value="Copiar linha digitável"
                                        />
                                    </CopyToClipboard>
                                    <Button
                                        width={isMobile ? "100%" : "260px"}
                                        margin={isMobile ? "0 auto 0 auto" : "0"}
                                        dark
                                        value="imprimir boleto"
                                        onClick={() => {
                                            window.open(payment_info.redirect_url, "_blank");
                                        }}
                                    />
                                </Flex>
                            </>
                        )}

                        <Separator />

                        {payment_method !== "pix" && (
                            <>
                                <Message>Aguarde. Em breve seu pedido chegará na sua casa!</Message>
                            </>
                        )}
                    </Flex>
                </Flex>
            </Container>

            <Footer mini />
        </>
    );
};

const Container = styled(Flex)`
    flex: 1;
    flex-direction: row;

    ${isMobileMediaQuery} {
        padding: 60px 20px;
        box-sizing: border-box;
    }
`;

const AccountLink = styled(Link)`
    color: #fff;
`;

const ChevronLeft = styled.span`
    display: inline-block;
    background-color: #fff;
    mask-size: cover;
    mask-position: center;
    mask-image: url(${chevronLeftIcon});
    object-fit: contain;
    height: 14px;
    width: 8px;
    margin-right: 9px;
`;

const HeroImage = styled.img`
    margin: 0 115px 7px auto;
    object-fit: contain;

    ${isMobileMediaQuery} {
        max-width: 100%;
        margin: 0 auto 7px -20px;
    }
`;

const Message = styled.p`
    margin: 0;
    font-size: 15px;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #676777;

    a {
        color: #676777;
        font-weight: 600;
    }

    ${isMobileMediaQuery} {
        font-size: 13px;
        line-height: 1.15;
        letter-spacing: normal;
    }
`;

const Title = styled.h1`
    margin: 0;
    text-transform: uppercase;
    font-family: "SackersGothicStd";
    font-size: 20px;
    font-weight: 900;
    line-height: 23px;
    letter-spacing: 4px;
    text-align: left;
    color: #34343c;

    ${isMobileMediaQuery} {
        font-size: 14px;
        line-height: 15px;
        letter-spacing: 2.8px;
    }
`;

const Separator = styled.hr`
    margin: 20px auto 20px 0;
    width: 40px;
    height: 3px;
    background-color: #f37f8a;
    border: none;

    ${isMobileMediaQuery} {
        margin: 20px auto 15px 0;
    }
`;

const Hint = styled.p`
    margin: 0 auto 0 11px;
    font-size: 15px;
    line-height: 1.07;
    color: #b2b2bf;
    flex: 1;

    ${isMobileMediaQuery} {
        font-size: 13px;
        line-height: 1.15;
        letter-spacing: normal;
    }
`;

const BarcodeContainer = styled.div`
    width: 100%;
    margin-top: 29px;
    margin-bottom: 14px;
`;

const BarcodeNumbers = styled.p`
    white-space: nowrap;
    width: 100%;
    margin: 0 0 22px 0;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 2.21px;
    text-align: left;
    color: #231f20;

    ${isMobileMediaQuery} {
        font-size: 8.5px;
        line-height: 1.53;
        letter-spacing: 0.85px;
    }
`;

const ClockIcon = styled.img.attrs(() => ({
    src: clockIcon,
}))`
    width: 29px;
    height: 29px;
    object-fit: contain;
`;

const TextSection = styled.p`
    margin: ${({ margin }) => (margin ? margin : "0px 0px 16px 0px")};
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #676777;
    white-space: pre-wrap;

    strong {
        font-weight: 600;
    }
`;

const CardBoxTitle = styled.h2`
    flex: 1;
    margin: ${({ margin }) => (margin ? margin : "0px 0px 16px 0px")};
    font-size: 15px;
    font-family: "Graphik";
    font-weight: 600;
    color: #34343b;
    word-break: break-word;
`;

export default Home;
