import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import api from "../../services/api";
import { validatePassword } from "../../helpers";
import { ContentDelimiter, Flex, isMobile } from "./../../helpers/styles";
import Header from "./../../components/Header";
import Footer from "./../../components/Footer";
import TextInput from "../../components/Inputs/TextInput";
import Button from "../../components/Button";
import AlertModal from "../../components/AlertModal";
import SectionTitle from "../../components/SectionTitle";
import ModalSuccess from "../../assets/images/modal-success-hero.jpg";
import HeroImage from "../../assets/images/signup-hero.png";
import EyeIcon from "../../assets/images/eye-icon.png";
import EyeIconCut from "../../assets/images/eye-icon-cut.png";

const PasswordReset = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const resetToken = new URLSearchParams(location.search).get("token");
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [modal, setModal] = useState(undefined);

    useEffect(() => {
        if (!resetToken || resetToken.length < 10) {
            navigate("/");
        }
        // eslint-disable-next-line
    }, []);

    const handleChange = (e) => {
        let {
            target: { name, value, checked, type },
        } = e;

        let newValues = { ...values };

        if (name !== undefined) {
            if (value !== undefined && type !== "checkbox") {
                newValues[name] = value;
            } else if (type === "checkbox") {
                newValues[name] = checked;
            }
        }

        setValues((prevValues) => {
            return {
                ...prevValues,
                ...newValues,
            };
        });

        canChangeStep(false, true, newValues);
    };

    const canChangeStep = (scroll = false, ignoreUndefined = false, checkValues = values) => {
        let canChange = true;

        let newErrors = { ...errors };

        const firstStepRequireds = ["password", "confirm_password"];

        firstStepRequireds.forEach((requiredField) => {
            if (
                (!Object.keys(checkValues).includes(requiredField) && !ignoreUndefined) ||
                checkValues[requiredField] === ""
            ) {
                newErrors[requiredField] = true;
            } else if (!newErrors[requiredField] || checkValues[requiredField] !== undefined) {
                newErrors[requiredField] = false;
            }
        });

        canChange = firstStepRequireds.every((requiredField) => {
            const invalidField = document.querySelector(`[name=${requiredField}]`);

            if (invalidField && scroll) {
                invalidField.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }

            return (
                Object.keys(checkValues).includes(requiredField) &&
                checkValues[requiredField] !== undefined &&
                checkValues[requiredField] !== ""
            );
        });

        if (
            !checkValues.password ||
            !checkValues.confirm_password ||
            checkValues.password !== checkValues.confirm_password ||
            !validatePassword(checkValues.password)
        ) {
            canChange = false;
        }

        setErrors({ ...newErrors });

        return canChange;
    };

    const handleSignup = async () => {
        if (!canChangeStep(true)) return;

        setLoading(true);

        let passwordResetResponse = await api
            .post("/auth/reset_password", {
                token: resetToken,
                password: values.password,
            })
            .catch((err) => {
                setLoading(false);
                return err.response;
            });

        setLoading(false);

        if (passwordResetResponse.status === 201) {
            setModal({
                show: true,
                image: ModalSuccess,
                title: "Yeah!",
                message: "Senha alterada com sucesso, você já pode entrar na sua conta!",
            });
        } else {
            console.error(passwordResetResponse);

            setModal({
                show: true,
                message:
                    passwordResetResponse && passwordResetResponse.data && passwordResetResponse.data.message
                        ? passwordResetResponse.data.message
                        : "Erro inesperado. Aguarde um instante e tente outra vez!",
            });
        }
    };

    return (
        <>
            <Header
                navigationItems={[
                    { label: "Compre", action: () => navigate("/categorias/novidades") },
                    { label: "Quero vender", action: () => navigate("/desapegue") },
                    { label: "Peça seu box", action: () => navigate("/box") },
                    { label: "Lojas Influencers", action: () => navigate("/lojas-influencers") },
                    { label: "Blog", action: () => navigate("/blog") },
                    { label: "Outlet", action: () => navigate("/outlet") },
                    { label: "Sale", action: () => navigate("/promocoes") },
                ]}
            />
            <ContentDelimiter>
                <Flex
                    width={isMobile ? "100%" : "910px"}
                    alignItems="flex-start"
                    margin={isMobile ? "0" : "87px auto 54px auto"}
                    direction={isMobile ? "column" : "row"}
                >
                    <Flex width="100%" mobile>
                        <SectionTitle margin="30px 0">Recuperar senha</SectionTitle>
                    </Flex>

                    <Flex width={isMobile ? "100%" : undefined} margin={isMobile ? "0" : "0px 82px 0px 0px"}>
                        <Image
                            src={HeroImage}
                            width={isMobile ? "auto" : "385px"}
                            height={isMobile ? "320px" : "auto"}
                        />
                    </Flex>

                    <Flex
                        as="form"
                        width={isMobile ? "100%" : "440px"}
                        padding={isMobile ? "0 20px" : undefined}
                        direction="column"
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        onSubmit={handleSignup}
                        boxSizing="border-box"
                    >
                        <Flex direction="column" alignItems="flex-start" justifyContent="flex-start" desktop>
                            <Title>Recuperar Senha</Title>
                            <Separator />
                        </Flex>

                        <TextInput
                            type={showPassword ? "text" : "password"}
                            placeholder="Nova Senha"
                            margin="0 0 20px 0"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            error={
                                errors.password ||
                                (values.password && values.password !== "" && !validatePassword(values.password))
                            }
                            append={
                                <EyeButton
                                    onClick={() => setShowPassword(!showPassword)}
                                    src={showPassword ? EyeIconCut : EyeIcon}
                                    alt="Olho - Mostrar senha"
                                />
                            }
                        />

                        <TextInput
                            type={showPassword ? "text" : "password"}
                            placeholder="Confirmar Nova Senha"
                            margin="0"
                            name="confirm_password"
                            value={values.confirm_password}
                            onChange={handleChange}
                            error={
                                errors.confirm_password ||
                                (values.confirm_password &&
                                    values.confirm_password !== "" &&
                                    (!validatePassword(values.password) ||
                                        (values.password !== "" && values.password !== values.confirm_password)))
                            }
                            append={
                                <EyeButton
                                    onMouseDown={() => setShowPassword(true)}
                                    onMouseUp={() => setShowPassword(false)}
                                    src={showPassword ? EyeIconCut : EyeIcon}
                                    alt="Olho - Mostrar senha"
                                />
                            }
                        />

                        <PasswordHint>
                            <strong>Fique de olho!</strong>
                            <br />
                            <br />
                            Sua senha deve ter no minímo 6 caracteres, sendo ao menos uma letra maiúscula, um número e
                            um caractere especial.
                        </PasswordHint>

                        <Flex
                            alignItems="center"
                            justifyContent="flex-start"
                            width="100%"
                            direction={isMobile ? "column" : "row"}
                            margin={isMobile ? "0 0 30px 0" : undefined}
                        >
                            <Button
                                width={isMobile ? "100%" : "200px"}
                                dark
                                filled
                                margin="0"
                                value="Enviar"
                                onClick={handleSignup}
                                disabled={loading}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </ContentDelimiter>

            <Footer mini />

            {modal && (
                <AlertModal
                    {...modal}
                    show={modal.show}
                    title={modal.title}
                    message={modal.message}
                    callback={() => {
                        setModal({ ...modal, show: false });
                        navigate("/");
                    }}
                />
            )}
        </>
    );
};

const Image = styled.img`
    margin: 0 auto;
`;

const Title = styled.h1`
    margin: 0px;
    margin-bottom: 24px;
    text-transform: uppercase;

    font-family: "SackersGothicStd";
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: 4px;
    text-align: left;
    color: #34343b;
`;

const Separator = styled.hr`
    width: 40px;
    height: 3px;
    margin: 0px;
    margin-bottom: 29px;
    background-color: #e4858c;
    border: none;
`;

const EyeButton = styled.img`
    cursor: pointer;
`;

const PasswordHint = styled.p`
    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    strong {
        font-weight: 600;
    }
`;

export default PasswordReset;
