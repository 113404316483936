import React from "react";
import styled from "styled-components";
import { Flex } from "./../../helpers/styles";
import { Link as RouterLink } from "react-router-dom";

import {
    CarouselProvider,
    Slider,
    Slide,
    DotGroup as CarrousselDotGroup,
    Dot as CarrousselDot,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import { StepBox } from "./index.js";
import { StepNumber } from "./index.js";

import StepIcon1 from "./../../assets/images/step-1.png";
import StepIcon2 from "./../../assets/images/step-2.png";
import StepIcon3 from "./../../assets/images/step-3.png";
import StepIcon4 from "./../../assets/images/step-4.png";

const StepsCarrousselMobile = () => {
    return (
        <Flex width="100%" height="430px" padding="0px 0px 40px 0px" mobile>
            <CarrousselContainer
                naturalSlideWidth={280}
                naturalSlideHeight={320}
                totalSlides={5}
                visibleSlides={1}
            >
                <ContentSlider>
                    <StyledSlide>
                        <StepBox>
                            <StepNumber>1</StepNumber>
                            <img
                                width="79px"
                                height="auto"
                                src={StepIcon1}
                                alt="Step 1"
                            />
                            <hr />
                            <h1>Faça seu cadastro</h1>
                            <p>
                                Separe as peças que quer desapegar. Lembrando
                                que o mínimo são 20 itens e tudo precisa estar
                                dentro do{" "}
                                <RouterLink to="/o-que-vender">
                                    nosso padrão
                                </RouterLink>{" "}
                                .
                            </p>
                        </StepBox>
                    </StyledSlide>

                    <StyledSlide>
                        <StepBox>
                            <StepNumber>2</StepNumber>
                            <img
                                width="104px"
                                height="auto"
                                src={StepIcon2}
                                alt="Step 2"
                            />
                            <hr />
                            <h1>Envie suas peças</h1>
                            <p>
                                Você pode enviar suas peças pelo correio,
                                solicitar a retirada (somente para São Paulo) ou
                                deixar em um dos nosso pontos de coleta.
                            </p>
                        </StepBox>
                    </StyledSlide>

                    <StyledSlide>
                        <StepBox>
                            <StepNumber>3</StepNumber>
                            <img
                                width="90px"
                                height="auto"
                                src={StepIcon3}
                                alt="Step 3"
                            />
                            <hr />
                            <h1>Nós avaliamos</h1>
                            <p>
                                As suas peças serão avaliadas em até 20 dias
                                úteis e você será informado(a) sobre a proposta
                                de valor.
                            </p>
                        </StepBox>
                    </StyledSlide>

                    <StyledSlide>
                        <StepBox>
                            <StepNumber>4</StepNumber>
                            <img
                                width="89px"
                                height="auto"
                                src={StepIcon4}
                                alt="Step 4"
                            />
                            <hr />
                            <h1>Suas peças em destaque</h1>
                            <p>
                                A Re Petit fará toda a produção, anúncio e venda
                                para você.
                            </p>
                        </StepBox>
                    </StyledSlide>

                    <StyledSlide>
                        <StepBox>
                            <StepNumber>5</StepNumber>
                            <img
                                width="89px"
                                height="auto"
                                src={StepIcon4}
                                alt="Step 5"
                            />
                            <hr />
                            <h1>Vendeu faturou!</h1>
                            <p>
                                Use seu créditos para comprar com  desconto de 10% no site ou resgate seu dinheiro.
                            </p>
                        </StepBox>
                    </StyledSlide>
                </ContentSlider>

                <DotGroup
                    renderDots={(e) =>
                        [1, 2, 3, 4, 5].map((item, index) => (
                            <Dot
                                key={`slideDot${index}`}
                                slide={index}
                                current={e.currentSlide === index ? 1 : 0}
                            />
                        ))
                    }
                />
            </CarrousselContainer>
        </Flex>
    );
};

const CarrousselContainer = styled(CarouselProvider)`
    flex: 1;
    height: 430px;
    position: relative;
    width: 100%;
`;

const ContentSlider = styled(Slider)`
    height: 430px;
`;

const StyledSlide = styled(Slide)`
    & > div > div {
        margin: auto;
    }
    & > div > div span:last-of-type {
        margin-bottom: 20px;
    }
`;

const DotGroup = styled(CarrousselDotGroup)`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
`;

const Dot = styled(CarrousselDot)`
    padding: 0px;
    border: 0;
    background-color: #f37f8a;
    border-radius: 100%;
    height: 11px;
    width: 11px;
    flex-basis: 11px;

    &:not(:last-child) {
        margin-right: 6px;
    }

    ${(props) => !props.current && `background-color: #e4e4e7;`}
`;

export default StepsCarrousselMobile;
