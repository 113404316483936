import styled from "styled-components";
import { isMobileMediaQuery } from "../../helpers/styles";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    a {
        text-decoration: none;
    }
`;

export const Header = styled.div`
    background-color: #fff;
    height: 141px;
    width: 100vw;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.085);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 62px;

    img {
        height: 51px;
        width: auto;
    }

    p {
        margin: 0px;
        text-transform: uppercase;

        font-family: "SackersGothicStd";
        font-size: 11px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.82;
        letter-spacing: 2.2px;
        text-align: left;
        color: #676777;
        margin-left: 40px;

        ${isMobileMediaQuery} {
            display: none;
        }
    }
`;

export const Hero = styled.img`
    width: 100%;
    height: auto;
    max-width: 594px;
    margin: auto 35px auto auto;

    object-fit: contain;

    ${isMobileMediaQuery} {
        width: 320px;
        height: 340px;
        margin: 0 auto 45px auto;
    }
`;

export const Title = styled.h1`
    margin: 0px auto 35px 0;
    text-transform: uppercase;

    font-family: "SackersGothicStd";
    font-size: 21px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 4.2px;
    text-align: left;
    color: #34343b;

    hr {
        border: 0px;
        margin-top: 18px;
        margin-left: 0px;
        margin-right: auto;
        width: 50px;
        height: 4px;
        background-color: #f3808b;
    }

    ${isMobileMediaQuery} {
        margin: 0px auto 20px auto;
        text-align: center;

        hr {
            width: 40px;
            margin-left: auto;
        }
    }
`;

export const FloatingTitle = styled(Title)`
    position: absolute;
    top: 45px;
    left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 172px;

    span:nth-child(2) {
        align-self: flex-end;
    }

    hr {
        margin-left: -30px;
    }

    ${isMobileMediaQuery} {
        top: -30px;
        margin: 0px;
        text-align: left;

        hr {
            width: 40px;
            margin-left: 0px;
        }
    }
`;

export const TextContainer = styled.div`
    word-break: break-word;
    white-space: pre-line;
    margin-bottom: 50px;
    max-width: 428px;

    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #676777;

    a {
        color: #676777;
        font-weight: bold;
    }

    ${isMobileMediaQuery} {
        text-align: center;
        margin-bottom: 35px;
    }
`;

export const Repetit = styled.h1`
    margin: auto auto auto 12px;
    text-transform: uppercase;

    font-family: "SackersGothicStd";
    font-size: 21px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.02;
    letter-spacing: 2.1px;
    text-align: center;
    color: #34343b;
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    background-color: #f7f9f9;
    margin-top: auto;
    padding: 26px calc((100vw - 1222px) / 2);

    p {
        margin: auto auto auto 0px;
        text-transform: uppercase;

        font-family: "SackersGothicStd";
        font-size: 11px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.82;
        letter-spacing: 2.2px;
        text-align: left;
        color: #676777;
    }

    ${isMobileMediaQuery} {
        justify-content: center;
        align-items: center;
        padding: 5px 15px;
        background-color: #cacad1;

        p {
            margin: auto;
            text-align: center;
            color: #fff;
        }
    }
`;
