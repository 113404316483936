import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Flex, isMobile } from "../../helpers/styles";

import { brazilianStates, validatePassword } from "../../helpers";

import EyeIcon from "../../assets/images/eye-icon.png";
import EyeIconCut from "../../assets/images/eye-icon-cut.png";
import TextInput from "../Inputs/TextInput";
import SelectInput from "../Inputs/SelectInput";
import CheckBox from "../Inputs/CheckBox";

function ProfileForm({ values, errors, user, handleZipCode, ...props }) {
    const handleChange = (...args) => {
        if (
            args &&
            args[0] &&
            args[0].target &&
            args[0].target.name &&
            args[0].target.value
        ) {
            if (
                ["email", "password", "confirm_password"].includes(
                    args[0].target.name
                )
            ) {
                args[0].target.value = args[0].target.value.trim();
            }
        }

        props.handleChange(...args);
    };

    const [showPassword, setShowPassword] = useState(false);

    let password = values.password || "";

    const caseRule =
        password !== "" &&
        (!password.match(/[A-Z]*/g) ||
            password
                .match(/[A-Z]*/g)
                .filter((item) => item !== null && item !== "").length === 0 ||
            !password.match(/[a-a]*/g) ||
            password
                .match(/[a-z]*/g)
                .filter((item) => item !== null && item !== "").length === 0);

    const numberRule =
        password !== "" &&
        (!password.match(/[0-9]*/g) ||
            password
                .match(/[0-9]*/g)
                .filter((item) => item !== null && item !== "").length === 0);

    const specialCharRule =
        password !== "" &&
        (!password.match(/[![\]@#$%^&*(),.?":;{}=+|<>_-]/g) ||
            password
                .match(/[![\]@#$%^&*(),.?":;{}=+|<>_-]/g)
                .filter((item) => item !== null && item !== "").length === 0);

    const lengthRule = password !== "" && password.length < 6;

    return (
        <>
            <Flex
                flex="1"
                as="form"
                alignItems="flex-start"
                width="100%"
                direction={isMobile ? "column" : "row"}
                margin={isMobile ? "0 0 20px 0" : "0"}
                justifyContent="flex-between"
            >
                {!props.noPerson && <>
                    <TextInput
                        flex="0.5"
                        margin={isMobile ? "0 0 20px 0" : "0 17.5px 20px 0"}
                        placeholder="Nome*"
                        name="first_name"
                        value={values.first_name}
                        onChange={handleChange}
                        error={errors.first_name}
                    />

                    <TextInput
                        flex="0.5"
                        margin={isMobile ? "0 0 20px 0" : "0 0 20px 17.5px"}
                        placeholder="Sobrenome*"
                        name="last_name"
                        value={values.last_name}
                        onChange={handleChange}
                        error={errors.last_name}
                    />
                <Flex flex="0 0 100%" />

                <TextInput
                    flex="0.5"
                    maxWidth={isMobile ? "100%" : "calc(50% - 17.5px)"}
                    margin={isMobile ? "0 0 20px 0" : "0 17.5px 20px 0px"}
                    placeholder="CPF*"
                    name="cpf"
                    mask="999.999.999-**"
                    value={values.cpf}
                    onChange={handleChange}
                    error={errors.cpf}
                />

                <TextInput
                    flex="0.5"
                    margin={isMobile ? "0 0 20px 0" : "0 0 20px 17.5px"}
                    placeholder="Data de nascimento (DD/MM/AAAA)"
                    mask="99/99/9999"
                    maskPlaceholder="dd/mm/yyyy"
                    name="birth_date"
                    value={values.birth_date}
                    onChange={handleChange}
                    error={errors.birth_date}
                />
                <Flex flex="0 0 100%" />

                <TextInput
                    flex="0.5"
                    margin={isMobile ? "0 0 20px 0" : "0 72.5px 20px 0"}
                    placeholder="Celular com DDD (XX) XXXXX-XXXX"
                    name="phone_number"
                    value={
                        values.phone_number &&
                        (values.phone_number || "").replace(/undefined/g, "")
                    }
                    onChange={(e) => {
                        e.target.value = e.target.value
                            .replace(/\D+/g, "")
                            .replace(
                                /(\d{2})(\d{1})(\d{4})(\d{4})/,
                                "($1) $2 $3-$4"
                            );
                        handleChange(e);
                    }}
                    error={errors.phone_number}
                />

                <Flex flex="0 0 100%" />
                <TextInput
                    type="email"
                    flex="0.5"
                    margin={isMobile ? "0 0 20px 0" : "0 17.5px 20px 0"}
                    placeholder="E-mail*"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    error={errors.email}
                />

                <CheckBox
                    flex="0.5"
                    minWidth={isMobile ? "100%" : "calc(50% - 17.5px)"}
                    justifyContent="flex-start"
                    margin={isMobile ? "0 0 20px 0" : "0 0 20px 17.5px"}
                    label={
                        <>
                            Oba! Quero receber as novidades <br />e promoções Re
                            Petit por e-mail
                        </>
                    }
                    name="newsletter"
                    value={values.newsletter}
                    onChange={handleChange}
                />
                
                <Flex flex="0 0 100%" />

                </>}
                {!props.noAddress && <>
                <TextInput
                    flex="0.5"
                    margin={isMobile ? "0 0 20px 0" : "0 72.5px 20px 0"}
                    placeholder="CEP*"
                    mask="99999-999"
                    name="zip_code"
                    value={values.zip_code}
                    onChange={handleChange}
                    error={errors.zip_code}
                    onBlur={handleZipCode}
                />
                <Flex flex="0 0 100%" />
                <Flex
                    flex="0.5"
                    minWidth={isMobile ? "100%" : "calc(50% - 17.5px)"}
                    margin={isMobile ? "0 0 20px 0" : "0 17.5px 20px 0"}
                >
                    <TextInput
                        flex="1"
                        margin="0 20px 0 0"
                        placeholder="Endereço*"
                        name="street"
                        value={values.street}
                        onChange={handleChange}
                        error={errors.street}
                    />
                    <TextInput
                        width="80px"
                        margin="0"
                        placeholder="Nº*"
                        name="house_number"
                        value={values.house_number}
                        onChange={handleChange}
                        error={errors.house_number}
                    />
                </Flex>

                <TextInput
                    flex="0.5"
                    margin={isMobile ? "0 0 20px 0" : "0 0 20px 17.5px"}
                    placeholder="Complemento"
                    name="additional_info"
                    value={values.additional_info}
                    onChange={handleChange}
                    error={errors.additional_info}
                />

                <Flex flex="0 0 100%" />
                
                <TextInput
                    flex="0.5"
                    alignItems="flex-start"
                    margin={isMobile ? "0 0 20px 0" : "0 17.5px 20px 0"}
                    placeholder="Bairro*"
                    name="neighborhood"
                    value={values.neighborhood}
                    onChange={handleChange}
                    error={errors.neighborhood}
                />
                {isMobile ? <Flex
                    flex="1"
                    minWidth="100%"
                    margin="0 0 20px 0"
                    justifyContent="space-between"
                >
                <TextInput
                    flex="1"
                    margin="0 20px 0 0"
                    placeholder="Cidade*"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    error={errors.city}
                />

                <SelectInput
                    width="80px"
                    margin="0"
                    placeholder="UF*"
                    mask="aa"
                    name="state"
                    value={values.state}
                    onChange={handleChange}
                    options={brazilianStates}
                    error={errors.state}
                />
                </Flex>
                :<>
                <TextInput
                    flex="0.5"
                    margin="0 20px 0 0"
                    placeholder="Cidade*"
                    name="city"
                    value={values.city}
                    onChange={handleChange}
                    error={errors.city}
                />

                <SelectInput
                    width="80px"
                    margin="0"
                    placeholder="UF*"
                    mask="aa"
                    name="state"
                    value={values.state}
                    onChange={handleChange}
                    options={brazilianStates}
                    error={errors.state}
                />
                </>}

                <Flex flex="0 0 100%" />
                </>}
                {!props.noPerson && <>

                {(!user || !user.token) && (
                    <>
                        <TextInput
                            type={showPassword ? "text" : "password"}
                            placeholder="Senha*"
                            flex="0.5"
                            maxWidth={isMobile ? "100%" : "calc(50% - 17.5px)"}
                            margin={isMobile ? "0 0 20px 0" : "0 auto 20px 0"}
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            error={
                                errors.password ||
                                (values.password &&
                                    values.password !== "" &&
                                    !validatePassword(values.password))
                            }
                            append={
                                <EyeButton
                                    onClick={() =>
                                        setShowPassword(!showPassword)
                                    }
                                >
                                    <img
                                        alt="Olho - Mostrar senha"
                                        src={
                                            showPassword ? EyeIconCut : EyeIcon
                                        }
                                    />
                                </EyeButton>
                            }
                        />

                        <Flex flex="0 0 100%" />

                        <TextInput
                            type={showPassword ? "text" : "password"}
                            placeholder="Confirmar Senha*"
                            flex="0.5"
                            maxWidth={isMobile ? "100%" : "calc(50% - 17.5px)"}
                            margin={isMobile ? "0 0 20px 0" : "0 auto 20px 0"}
                            name="confirm_password"
                            value={values.confirm_password}
                            onChange={handleChange}
                            error={
                                errors.confirm_password ||
                                (values.confirm_password &&
                                    values.confirm_password !== "" &&
                                    (!validatePassword(values.password) ||
                                        (values.password !== "" &&
                                            values.password !==
                                            values.confirm_password)))
                            }
                            append={
                                <EyeButton
                                    onClick={() =>
                                        setShowPassword(!showPassword)
                                    }
                                >
                                    <img
                                        alt="Olho - Mostrar senha"
                                        src={
                                            showPassword ? EyeIconCut : EyeIcon
                                        }
                                    />
                                </EyeButton>
                            }
                        />

                        <Flex
                            flex="0.5"
                            minWidth={isMobile ? "100%" : "calc(50% - 17.5px)"}
                            justifyContent="flex-start"
                            margin={isMobile ? "0 0 20px 0" : "-100px 0 20px 17.5px"}
                        >
                            <PasswordHint>
                                *Os campos sinalizados com * são obrigatórios.
                            </PasswordHint>
                        </Flex>

                        <Flex flex="0 0 100%" />

                        <Flex
                            flex="0.5"
                            minWidth={isMobile ? "100%" : "calc(50% - 17.5px)"}
                            justifyContent="flex-start"
                            margin="0 auto 0 0"
                        >
                            <PasswordHint>
                                <strong>Fique de olho!</strong>
                                <br />
                                <br />
                                Sua senha deve conter as seguintes
                                características:
                                <ul>
                                    <PasswordRule wrong={lengthRule}>
                                        No minímo 6 caracteres
                                    </PasswordRule>
                                    <PasswordRule wrong={caseRule}>
                                        Uma letra minúscula e uma maiúscula
                                    </PasswordRule>
                                    <PasswordRule wrong={numberRule}>
                                        Um número
                                    </PasswordRule>
                                    <PasswordRule wrong={specialCharRule}>
                                        Um caractere especial
                                    </PasswordRule>
                                </ul>
                            </PasswordHint>
                        </Flex>
                    </>
                )}
                </>}
            </Flex>
        </>
    );
}

const EyeButton = styled.div`
    cursor: pointer;
    img {
        pointer-events: none;
    }
`;

const PasswordHint = styled.p`
    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    strong {
        font-weight: 600;
    }
`;

const PasswordRule = styled.li`
    ${({ wrong }) =>
        wrong &&
        css`
            color: red;
        `}
`;

export default ProfileForm;
