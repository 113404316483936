import React from "react";
import styled from "styled-components";
import moment from "moment";

import { Flex, isMobileMediaQuery, isMobile } from "../../helpers/styles";

import { capitalize } from "../../helpers";

import Button from "../../components/Button";
import CheckBox from "../../components/Inputs/CheckBox";

import productPlaceholder from "../../assets/images/product-placeholder.jpg";

const SellProductItem = ({
    item,
    handleChange,
    checked,
    setValues,
    showPromoModal,
    setShowPromoModal,
    showId,
    ...props
}) => {
    const product_status = {
        available: "Disponível",
        in_box: "No BOX",
        reserved: "Reservado",
        returned: "Devolvido",
        sold: "Vendido",
        lost: "Vendido",
        donated: "Doado",
    };

    return (
        <ProductItem width="100%">
            {handleChange && !isMobile && (
                <CheckBox
                    name="promotedItems"
                    value={item.id}
                    checked={checked || item.promotion}
                    disabled={item.promotion}
                    onChange={handleChange}
                />
            )}

            <img alt="Product" src={(item.images && item.images[0]) || productPlaceholder} />

            <Flex flex="1" direction="column" alignItems="flex-start" justifyContent="flex-start" margin="0 auto 0 0">
                <ProductTitle>
                    {item.title ||
                        `${capitalize(item.item_category.name.toLowerCase())} ${capitalize(
                            item.brand.name.toLowerCase()
                        )}`}
                    {showId && ` - Cod.: ${item.id}`}
                </ProductTitle>
                <ProductDescription>
                    A receber: R$ {parseFloat(item.price_supplier)}
                    <br />
                    Status:{" "}
                    <span className={item.status}>
                        {product_status[item.status]}
                        {item.promotion && " - Em promoção"}
                    </span>
                    <br />
                    Data de aprovação:
                    {moment(item.entry_approved_at).format("DD/MM/YYYY")}
                </ProductDescription>
            </Flex>

            {setShowPromoModal && !isMobile && (
                <Button
                    dark
                    margin="auto 0 auto auto"
                    value={item.promotion ? "Promoção ativa" : "Ativar promoção"}
                    disabled={item.promotion_button_disabled}
                    onClick={() => {
                        setValues((oldValues) => ({
                            ...oldValues,
                            promotedItems: [item.id.toString()],
                        }));
                        setShowPromoModal(!showPromoModal);
                    }}
                />
            )}

            {(handleChange || setShowPromoModal) && (
                <Flex
                    mobile
                    justifyContent="space-between"
                    width="100%"
                    padding="0 20px"
                    boxSizing="border-box;"
                    margin="16px 0 0 0"
                >
                    {handleChange && (
                        <CheckBox
                            name="promotedItems"
                            value={item.id}
                            checked={checked}
                            onChange={handleChange}
                            label={"Selecionar"}
                        />
                    )}

                    {setShowPromoModal && (
                        <Button
                            dark
                            margin="auto 0 auto auto"
                            value="Ativar promoção"
                            onClick={() => {
                                setValues((oldValues) => ({
                                    ...oldValues,
                                    promotedItems: [item.id.toString()],
                                }));
                                setShowPromoModal(!showPromoModal);
                            }}
                        />
                    )}
                </Flex>
            )}
        </ProductItem>
    );
};

const ProductItem = styled(Flex)`
    img {
        width: 110px;
        height: 120px;
        object-fit: contain;
        border: 1px solid #f5f5f6;
        background-color: #fff;
        margin: auto 20px;

        ${isMobileMediaQuery} {
            width: 88px;
            height: 96px;
        }
    }

    ${isMobileMediaQuery} {
        /* flex-direction: column; */
        & > ${Flex}:last-of-type {
            border-top: 1px solid #f5f5f6;
            padding-top: 16px;
        }
    }
`;

const ProductTitle = styled.p`
    margin: 9px 0 8px 0;
    font-family: "DMSerifDisplay";
    font-size: 24px;
    line-height: 1;
    text-align: left;
    color: #34343b;

    ${isMobileMediaQuery} {
        font-size: 18px;
    }
`;

const ProductDescription = styled.p`
    margin: 0;
    font-size: 13px;
    line-height: 1.15;
    text-align: left;
    color: #34343b;

    span {
        font-weight: 600;
    }
    span.sold {
        color: #1d95af;
    }
    span.available {
        color: #5dc07c;
    }
`;

export default SellProductItem;
