import React from "react";

import styled, { css, keyframes } from "styled-components";

const RotationAnimation = keyframes`
    0% {
        transform: rotate(0deg);
    }
    
    100% {
        transform: rotate(360deg);
    }
`;

const Container = styled.div`
    min-height: ${({ size }) => size || 14}px;
    min-width: ${({ size }) => size || 14}px;
    height: ${({ size }) => size || 14}px;
    width: ${({ size }) => size || 14}px;
    ${({ margin }) => margin && `margin: ${margin};`}
    border-radius: 100%;
    border: solid ${({ size }) => (size ? size / 4.66666666 : "3")}px #fff;
    border-bottom-color: rgb(255, 255, 255, 0.2);
    background-color: transparent;
    animation: ${RotationAnimation} 2s infinite linear;

    ${({ pinky }) =>
        pinky &&
        css`
            border: solid ${({ size }) => (size ? size / 4.66666666 : "3")}px
                #e4858c;
            border-bottom-color: rgb(228, 133, 140, 0.2);
        `}

    ${({ dark }) =>
        dark &&
        css`
            border: solid ${({ size }) => (size ? size / 4.66666666 : "3")}px
                #34343b;
            border-bottom-color: rgb(255, 255, 255, 0.2);
        `}
`;

function LoadingSpinner({ size, pinky, dark, ...props }) {
    return <Container size={size} pinky={pinky} dark={dark} {...props} />;
}

export default LoadingSpinner;
