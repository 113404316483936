import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

import logo from "./../../assets/images/logo-repetit.png";
import loadingHero from "../../assets/images/loading-hero.jpg";
import loadingBubble from "../../assets/images/loading-bubble.svg";
import { isMobileMediaQuery } from "../../helpers/styles";

const Loading = () => {
    useEffect(() => {
        window.scrollTo(0, 0);

        return () => {
            window.scrollTo(0, 0);
        };

        // eslint-disable-next-line
    }, []);

    return (
        <Container>
            <LoadingBubble>
                <img src={loadingBubble} alt="Loadgin Bubble" />
                <img src={logo} alt="Logo" />
                <span />
            </LoadingBubble>
        </Container>
    );
};
const Container = styled.div`
    width: 100%;
    height: calc(100vh - 120px);
    background: url(${loadingHero});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -50px;

    ${isMobileMediaQuery} {
        margin-top: 0px;
    }
`;

const LoadingSpinner = keyframes`
    0% {
        transform: rotate(0deg);
    }
    
    100% {
        transform: rotate(360deg);
    }
`;

const LoadingBubble = styled.div`
    position: relative;
    margin-bottom: 35vh;
    margin-left: 30%;

    & > img:nth-of-type(1) {
        filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.08));
    }

    & > img:nth-of-type(2) {
        width: 162px;
        height: 55px;
        object-fit: contain;
        position: absolute;
        top: 50%;
        left: 72px;
        transform: translateY(-50%);
    }

    & > span {
        height: 216px;
        width: 216px;
        position: absolute;
        top: 13px;
        left: 40px;
        border-radius: 100%;
        border: solid 3px #e4858c;
        border-bottom-color: rgb(228, 133, 140, 0.2);
        background-color: transparent;
        animation: ${LoadingSpinner} 2s infinite linear;
    }

    ${isMobileMediaQuery} {
        transform: scale(0.8);
    }
`;

export default Loading;
