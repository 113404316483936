import React from "react";
import styled from "styled-components";
import { isMobile, isMobileMediaQuery } from "../../helpers/styles";

const RecreioCarrousselItem = ({
    tag,
    title,
    description,
    author,
    image,
    onClick,
    ...props
}) => {
    return (
        <Container height={props.height} src={image} onClick={onClick}>
            {isMobile && <Tag>{tag}</Tag>}
            <BlurryContainer height={props.height}>
                {!isMobile && <Tag>{tag}</Tag>}
                <Title>{title}</Title>
                <Description>{description}</Description>
                <Separator />
                <Author>
                    por{" "}
                    {props.instagramUsername ? (
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => e.stopPropagation()}
                            href={
                                props.instagramUsername &&
                                `https://www.instagram.com/${props.instagramUsername}`
                            }
                        >{`@${author}`}</a>
                    ) : (
                        `@${author}`
                    )}
                </Author>
            </BlurryContainer>
        </Container>
    );
};

const Container = styled.div`
    background-image: url(${(props) => props.src});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: auto;
    flex: 1;
    height: ${(props) => props.height || 314}px;
    display: flex;
    position: relative;

    cursor: ${({ onClick }) => (onClick ? "pointer" : "initial")};
`;

const BlurryContainer = styled.div`
    width: 314px;
    height: 314px;
    padding: 35px;
    backdrop-filter: blur(5px);
    background-color: rgb(255 255 255 / 60%);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    ${isMobileMediaQuery} {
        width: 100%;
        height: auto;
        max-height: initial;
        margin-top: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

const Tag = styled.p`
    margin: 0px;
    margin-bottom: 17px;
    text-transform: uppercase;
    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: 2.2px;
    text-align: left;
    color: #34343b;

    ${isMobileMediaQuery} {
        position: absolute;
        top: 0px;
        left: 0px;
        margin: 20px;
    }
`;

const Title = styled.div`
    margin-bottom: 22px;
    font-family: "DMSerifDisplay";
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    ${isMobileMediaQuery} {
        text-align: center;
    }
`;

const Description = styled.div`
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -moz-box;
    -moz-line-clamp: 3;
    -moz-box-orient: vertical;
    flex: 1;
    max-height: 58px;

    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    strong {
        display: block;
    }

    ${isMobileMediaQuery} {
        text-align: center;
    }
`;

const Separator = styled.hr`
    width: 100%;
    border: 0;
    border-bottom: 1px solid black;
    opacity: 0.3;
    margin-top: 19px;
    margin-bottom: 15px;
`;

const Author = styled.div`
    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    a {
        color: #34343b;
    }

    ${isMobileMediaQuery} {
        text-align: center;
    }
`;

export default RecreioCarrousselItem;
