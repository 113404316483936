import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";

import { breakpoints, Flex, isMobile, isMobileMediaQuery } from "../../helpers/styles";

import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
import AlertModalHero from "../../assets/images/alert-modal-hero.png";

import Button from "../Button";

const AlertModal = ({
    image,
    title,
    message,
    content,
    contentBackground,
    show,
    button,
    buttonAction,
    callback,
    modalHeight,
    modalMinHeight,
    modalMaxHeight,
    heroWidth,
    heroHeight,
    fullHero,
    ...props
}) => {
    const bodyElement = document.querySelector("body");

    const [modalActive, setModalActive] = useState(false);

    useEffect(() => {
        show !== null && show !== undefined && show !== modalActive && setModalActive(show);

        if (show) {
            let top = window.scrollY;

            bodyElement.style.position = "fixed";
            bodyElement.style.top = `-${top}px`;
            bodyElement.style.left = "0px";
            bodyElement.style.right = "0px";
        } else {
            const scrollY = bodyElement.style.top;

            bodyElement.style.position = "";
            bodyElement.style.top = "";
            bodyElement.style.left = "";
            bodyElement.style.right = "";

            window.scrollTo(0, -parseInt(scrollY || "0"));
        }

        // eslint-disable-next-line
    }, [show]);

    const handleClose = () => {
        setModalActive(false);

        callback && callback();
    };

    return (
        modalActive && (
            <Container onClick={handleClose}>
                <Body
                    modalHeight={modalHeight}
                    modalMinHeight={modalMinHeight}
                    modalMaxHeight={modalMaxHeight}
                    onClick={(e) => e.stopPropagation()}
                    fullHero={fullHero}
                >
                    <CloseButton onClick={handleClose}>
                        <CloseIcon />
                    </CloseButton>

                    <ModalImage
                        src={image || AlertModalHero}
                        heroWidth={heroWidth}
                        heroHeight={heroHeight}
                        fullHero={fullHero}
                    />

                    {!fullHero && (
                        <Flex
                            width={isMobile ? "100%" : "400px"}
                            margin="0"
                            height="auto"
                            alignSelf="stretch"
                            background={contentBackground}
                        >
                            <Flex
                                direction="column"
                                alignItems="flex-start"
                                padding={isMobile ? "29px 25px 30px 25px" : "0 42px 0 48px"}
                                boxSizing="border-box"
                            >
                                {title !== false && <Title>{title || "Oh-oh!"}</Title>}

                                {message && <ModalText>{message}</ModalText>}

                                {button && (
                                    <Button
                                        width={isMobile ? "100%" : "180px"}
                                        margin={"0 auto 0 0"}
                                        dark
                                        filled
                                        value={button || "Ok"}
                                        onClick={buttonAction || handleClose}
                                    />
                                )}

                                {content && content}
                            </Flex>
                        </Flex>
                    )}
                </Body>
            </Container>
        )
    );
};

const Container = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 5;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.7);
    padding: 2rem 0px;

    ${isMobileMediaQuery} {
        padding: 90px 0px;
        align-items: baseline;
        max-height: 100vh;
    }
`;

const Body = styled.div`
    position: relative;
    width: auto;
    height: ${({ modalHeight }) => (modalHeight ? modalHeight : "260px")};
    min-height: ${({ modalMinHeight }) => (modalMinHeight ? modalMinHeight : "initial")};
    max-height: ${({ modalMaxHeight }) => (modalMaxHeight ? modalMaxHeight : "80vh")};
    max-width: 90vw;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px 0 rgba(52, 52, 59, 0.15);
    padding: 0px;
    margin: auto;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    ${isMobileMediaQuery} {
        padding: 0px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        max-width: 90vw;
        max-width: 330px;
        max-height: initial;
        height: auto;
    }

    ${({ fullHero }) =>
        fullHero &&
        css`
            max-width: 90vw !important;
        `}
`;

const CloseButton = styled.button`
    padding: 0px;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 0px;
    right: 0px;
    margin: 19px;
    border: 0px;
    background: transparent;
    cursor: pointer;
    z-index: 20;

    svg {
        transform: scale(0.8);
        path {
            fill: #34343c;
        }
    }
`;

const ModalImage = styled.img`
    width: ${({ heroWidth }) => (heroWidth ? heroWidth : "400px")};
    height: ${({ heroHeight }) => (heroHeight ? heroHeight : "100%")};
    margin: 0px;
    margin-right: auto;
    object-fit: cover;
    align-self: stretch;

    @media screen and (min-width: ${breakpoints.mobile + 1}px) and (max-width: ${breakpoints.md}px) {
        max-width: calc(100% - 400px);
    }

    ${isMobileMediaQuery} {
        max-width: 100%;
    }

    ${({ fullHero }) =>
        fullHero &&
        css`
            margin: 0px;
            max-width: 100% !important;
        `}
`;

const Title = styled.h1`
    margin: 20px auto 31px 0;

    font-family: "DMSerifDisplay";
    font-size: 41px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;
`;

const ModalText = styled.p`
    white-space: pre-line;
    margin: 0 auto 23px 0px;

    font-family: "Graphik";
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;
`;

export default AlertModal;
