import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Flex, isMobile, isMobileMediaQuery, sizes } from "./../../helpers/styles";
import { generateUUID } from "../Functions";
import * as ModalActions from "../../redux/actions/loginModal";
import { ReactComponent as CloseMenu } from "./../../assets/images/close.svg";
import searchIcon from "./../../assets/images/icon-search.png";
import sacolaIcon from "./../../assets/images/icon-shop.png";
import userIcon from "./../../assets/images/icon-user.png";
import logoIcon from "./../../assets/images/logo-repetit.png";
import { ReactComponent as MenuHamburger } from "./../../assets/images/menu-hamburger.svg";
import { ReactComponent as ChevronLeftIcon } from "./../../assets/images/seta.svg";
import { useDispatch, useSelector } from "react-redux";
import { capitalize, validSession } from "../../helpers";
import api from "../../services/api";
import Button from "../Button";
import Dropdown from "../Dropdown";
import NavigationMenu, { SubButtonsRow } from "../NavigationMenu";
import NavigationMenuMobile from "../NavigationMenuMobile";
import Sacola from "../Sacola";
import SellApprovalModal from "../SellApprovalModal";
import TopHeaderCarroussel from "./TopHeaderCarroussel";

function Header({ navigationItems, subNavigation, subNavigationFixed, showProgressBar, ...props }) {
    const location = useLocation();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const isRecreio = location.pathname.indexOf("blog") >= 0;
    const queryParams = new URLSearchParams(location.search);
    let prevPageYOffset = window.pageYOffset + 74;
    const [menuOpen, setMenuOpen] = useState(false);
    const [showSacola, setShowSacola] = useState(false);
    const [menuItem, setMenuItem] = useState(undefined);
    const [values, setValues] = useState({
        search: queryParams.has("search") ? queryParams.get("search") : "",
    });
    const [showUserDropdown, setShowUserDropdown] = useState(false);
    const [showSearchMobile, setShowSearchMobile] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [position, setPosition] = useState("relative");

    const [productsAmount, setProductsAmount] = useState(0);
    const handleProductsAmountChange = (newProductsAmount) => {
        setProductsAmount(newProductsAmount);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleWindowScroll);

        return () => {
            window.removeEventListener("scroll", handleWindowScroll);
        };

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const authenticated = validSession();

        if (
            user.entry_approval_required_id > 0 &&
            authenticated &&
            window.location.pathname !== `/minha-conta/vendas/${user.entry_approval_required_id}`
        ) {
            setShowModal(true);
        }

        /* if (window.location.search && isMobile)
        setShowSearchMobile(true) */

        // eslint-disable-next-line
    }, [user]);

    useEffect(() => {
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    }, []);

    const isSticky = () => {
        const scrollTop = window.scrollY;
        const stickyClass = scrollTop >= 38 ? "sticky" : "relative";
        setPosition(stickyClass);
    };

    const handleWindowScroll = () => {
        const currentScrollPos = window.pageYOffset;

        if (prevPageYOffset === "sticky") {
            prevPageYOffset = currentScrollPos;
            return;
        }

        if (currentScrollPos > 84 && isMobile) {
            setShowUserDropdown(false);
            setShowSearchMobile(false);
        }

        prevPageYOffset = currentScrollPos;

        if (document.body.style.position === "sticky") {
            prevPageYOffset = "sticky";
        }
    };

    const handleChange = (e) => {
        let {
            target: { name, value },
        } = e;
        let newValues = { ...values };

        if (name) {
            newValues[name] = value;

            setValues(newValues);
        }
    };

    const handleSearch = async (e) => {
        e && e.preventDefault();

        if (!values.search || values.search.trim().length === 0) {
            return;
        }

        // eslint-disable-next-line
        let userSessionServerData = window.userCurrentServerData(user);
        let event_id = generateUUID();
        let event_time = Math.round(Date.now() / 1000);

        window.fbq("track", "Search", { search_string: values.search }, { eventID: event_id });

        await api
            .post("/facebook_ads_event", {
                data: [
                    {
                        event_name: "Search",
                        event_id: event_id,
                        event_time: event_time,
                        event_source_url: window.location.href,
                        action_source: "website",
                        user_data: userSessionServerData,
                        custom_data: {
                            search_string: values.search,
                        },
                    },
                ],
            })
            .then((res) => console.log("EVENTO Search", res.data))
            .catch((res) => res.response);

        // eslint-disable-next-line
        dataLayer.push({
            event: "search",
            search_string: values.search,
        });

        window.location.href = `/produtos?search=${encodeURIComponent(values.search)}`;
    };

    const handleShowUserDropdown = () => {
        setShowUserDropdown(!showUserDropdown);
    };

    const handleShowLoginModal = () => {
        dispatch(ModalActions.show());
    };

    const handleToggleMenu = (e, flag = undefined) => {
        if (flag === undefined) {
            setMenuOpen(!menuOpen);
        } else {
            setMenuOpen(flag);
        }
    };

    return (
        <>
            <TopHeaderCarroussel />

            <HeaderContainer position={position}>
                <Container>
                    <Content>
                        {isRecreio && (
                            <Link to="/">
                                <ChevronLeft />
                                <Flex desktop>Voltar para Home</Flex>
                            </Link>
                        )}
                        <Flex margin={isRecreio && "auto 0px auto 0"} desktop as="form" onSubmit={handleSearch}>
                            <SearchArea>
                                <SearchInput
                                    name="search"
                                    value={values.search}
                                    onChange={handleChange}
                                    placeholder="O que você está buscando?"
                                />
                                <SearchButton onClick={handleSearch}>
                                    <ImgSearch src={searchIcon} alt="Buscar" width={22} onClick={handleSearch} />
                                </SearchButton>
                            </SearchArea>
                        </Flex>

                        {!isRecreio && (
                            <Flex margin={isRecreio && "auto 134px auto 0"} mobile>
                                <MenuButton onClick={handleToggleMenu}>
                                    {menuOpen ? <CloseMenu width="28px" /> : <MenuHamburger width="28px" />}
                                </MenuButton>

                                <Button padding="0" margin="0" onClick={() => setShowSearchMobile(!showSearchMobile)}>
                                    <img width="22px" src={searchIcon} alt="Lupa" />
                                </Button>
                            </Flex>
                        )}

                        {!isRecreio ? (
                            <LogoLink to="/">
                                <img src={logoIcon} alt="logo" />
                            </LogoLink>
                        ) : (
                            <Flex onClick={() => navigate("/blog")} direction={isMobile ? "column" : "row"}>
                                <RecreioTitle>Blog</RecreioTitle>
                                <ByRepetit>
                                    Por <span>RE</span>
                                    <span>Petit</span>
                                </ByRepetit>
                            </Flex>
                        )}

                        <Flex order="2">
                            <IconButton
                                onMouseOver={!isMobile ? () => setShowUserDropdown(true) : null}
                                onClick={user && user.token ? handleShowUserDropdown : handleShowLoginModal}
                                onMouseLeave={!isMobile ? () => setShowUserDropdown(false) : null}
                            >
                                <Flex mobile>
                                    {" "}
                                    {user &&
                                        user.token &&
                                        user.first_name &&
                                        capitalize(
                                            `${user.first_name && user.first_name[0] + " "}${
                                                user.last_name && user.last_name[0]
                                            }`,
                                            true
                                        )}
                                </Flex>
                                <Flex desktop>
                                    {" "}
                                    {capitalize(
                                        user && user.token
                                            ? `${user.first_name && user.first_name + " "}${user.last_name}`
                                            : "Minha Conta",
                                        true
                                    )}
                                </Flex>
                                <img height={isMobile ? "22px" : undefined} src={userIcon} alt="Usuario" />

                                {user && user.token && (
                                    <Dropdown
                                        show={showUserDropdown}
                                        callback={() => {
                                            setShowUserDropdown(false);
                                        }}
                                        options={[
                                            {
                                                label: "Meu Box",
                                                action: () => navigate("/minha-conta/box"),
                                            },
                                            {
                                                label: "Meus Pedidos",
                                                action: () => navigate("/minha-conta/pedidos"),
                                            },
                                            {
                                                label: "Meus Produtos",
                                                action: () => navigate("/minha-conta/vendas"),
                                            },
                                            {
                                                label: "Meus Créditos",
                                                action: () => navigate("/minha-conta/creditos"),
                                            },
                                            {
                                                label: "Minha Conta",
                                                action: () => navigate("/minha-conta/cadastro"),
                                            },
                                            {
                                                label: "Sair",
                                                action: () => navigate("/logout"),
                                            },
                                        ]}
                                    />
                                )}
                            </IconButton>

                            <ContainerSacola>
                                {location.pathname.startsWith("/carrinho") ||
                                    (location.pathname !== "/carrinho" && (
                                        <>
                                            <IconButton />
                                            <Flex
                                                position="relative"
                                                onClick={
                                                    isMobile
                                                        ? () => navigate("/carrinho")
                                                        : () => setShowSacola(!showSacola)
                                                }
                                            >
                                                <Flex desktop>Minha Sacola</Flex>
                                                <img
                                                    height={isMobile ? "22px" : undefined}
                                                    src={sacolaIcon}
                                                    alt="Sacola"
                                                />
                                                <Badge>{productsAmount}</Badge>
                                            </Flex>
                                        </>
                                    ))}

                                {!isMobile && !location.pathname.startsWith("/carrinho") && (
                                    <Sacola
                                        showProgressBar={showProgressBar}
                                        onProductsAmountChange={handleProductsAmountChange}
                                        active={showSacola}
                                        callback={setShowSacola}
                                    />
                                )}
                            </ContainerSacola>
                        </Flex>
                    </Content>
                </Container>

                {!isRecreio && !props.hideLinks && (
                    <NavigationMenu
                        navigationItems={navigationItems}
                        subNavigation={subNavigation}
                        onMenuItemSet={menuItem}
                        handleChangeMenuItem={() => {
                            setMenuItem();
                        }}
                        callback={(open) => setMenuOpen(open)}
                    />
                )}

                {!isRecreio && isMobile && (
                    <NavigationMenuMobile
                        navigationItems={navigationItems}
                        menuOpen={menuOpen}
                        handleChangeMenuItem={() => {}}
                        closeMenu={() => handleToggleMenu(false)}
                    />
                )}

                {props.append && !menuOpen && <SubButtonsRow>{props.append}</SubButtonsRow>}

                {(isRecreio || props.hideLinks) && subNavigation && (
                    <SubButtonsRow fixed={subNavigationFixed}>{subNavigation}</SubButtonsRow>
                )}

                {isMobile && location.pathname !== "/carrinho" && (
                    <Sacola
                        active={showSacola}
                        callback={setShowSacola}
                        onProductsAmountChange={handleProductsAmountChange}
                    />
                )}

                {isMobile && showSearchMobile && (
                    <MobileSearch as="form" onSubmit={handleSearch}>
                        <SearchArea>
                            <SearchInput
                                name="search"
                                value={values.search}
                                onChange={handleChange}
                                placeholder="O que você está buscando?"
                            />
                            <SearchButton type="submit">Buscar</SearchButton>
                        </SearchArea>
                    </MobileSearch>
                )}
            </HeaderContainer>

            {showModal && (
                <SellApprovalModal
                    callback={() => {
                        navigate(`/minha-conta/vendas/${user.entry_approval_required_id}`);
                    }}
                    show
                    callToAction
                />
            )}
        </>
    );
}

const HeaderContainer = styled.div`
    width: 100vw;
    top: 0px;
    position: ${(props) => (props.position ? props.position : "relative")};
    z-index: 5;
    transition: top 0.3s ease-in-out;
`;

const Container = styled.div`
    position: relative;
    background-color: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.085);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Content = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    width: 100%;
    height: ${sizes.header}px;
    max-width: 1222px;
    box-sizing: border-box;
    ${isMobileMediaQuery} {
        padding: 0 1rem;
    }
    @media screen and (min-width: 760px) and (max-width: 1222px) {
        padding: 13px 8px;
    }
`;

const LogoLink = styled(RouterLink)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: 425px) {
        max-width: calc(85vw - 170px);
        img {
            max-width: 100%;
        }
    }
    ${isMobileMediaQuery} {
        img {
            max-width: 110px;
        }
    }
`;

const Link = styled(RouterLink)`
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-family: "Graphik";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.86;
    letter-spacing: 0.28px;
    text-align: left;
    color: #676777;
`;

const ChevronLeft = styled(ChevronLeftIcon)`
    object-fit: contain;
    height: 14px;
    width: 8px;
    margin-right: 9px;
`;

const RecreioTitle = styled.h1`
    margin: 0;
    margin-left: auto;
    padding-right: 13px;
    border-right: 1px solid #313139;
    text-transform: uppercase;
    font-family: "SackersGothicStd";
    font-size: 32px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: 6.4px;
    text-align: left;
    color: #34343b;
    ${isMobileMediaQuery} {
        font-size: 18px;
        margin: 0px;
        padding: 0px;
        margin-bottom: 8px;
        border: none;
    }
`;

const ByRepetit = styled.p`
    margin: 0;
    margin-left: 12px;
    margin-right: auto;
    text-transform: uppercase;
    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.95;
    letter-spacing: 2.2px;
    text-align: left;
    color: #34343b;
    & > span:nth-child(1) {
        color: #84a5ac;
    }
    & > span:nth-child(2) {
        color: #e4858c;
    }
    ${isMobileMediaQuery} {
        margin: 0px;
    }
`;

const IconButton = styled.button`
    font-family: "Graphik";
    font-size: 14px;
    line-height: 11px;
    color: #4d4d59;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    border: 0px;
    background-color: transparent;
    height: 28px;
    cursor: pointer;
    :not(:first-child) {
        margin-left: 15px;
    }
    & > ${Flex}, & > ${Flex}:first-child > ${Flex} {
        margin-right: 10px;
    }
`;

const MenuButton = styled.button`
    background-color: transparent;
    border: 0px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
`;

const ContainerSacola = styled(Flex)`
    position: relative;
    font-family: "Graphik";
    font-size: 14px;
    line-height: 11px;
    color: #4d4d59;
    :not(:first-child) {
        margin-left: 15px;
    }
    & > ${Flex}, & > ${Flex}:nth-child(2) > ${Flex} {
        margin-right: 10px;
    }
`;

const Badge = styled(Flex)`
    margin: 0 !important;
    position: absolute;
    top: -6px;
    right: -6px;
    font-size: 13px;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    background-color: #e4858c;
    color: #313139;
    border-radius: 100%;
`;

const MobileSearch = styled.div`
    background-color: #fff;
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 20px;
`;

const SearchArea = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 100%;
    border: 1px solid #d3d3d3;
    height: 46px;
    border-radius: 16px 16px 16px 16px;
`;

const SearchInput = styled.input`
    width: 100%;
    border: none;
    border-right: none;
    padding: 8px;
    height: 30px;
    border-radius: 16px 0 0 16px;
    outline: none;
    font-size: 16px;
`;

const SearchButton = styled.button`
    width: 90px;
    height: 46px;
    border: none;
    background: #fff;
    text-align: center;
    color: #e4858c;
    border-radius: 0 16px 16px 0;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
`;

const ImgSearch = styled.img`
    padding: 5px;
    background: #e4858c;
    border-radius: 50px;
`;

export default Header;
