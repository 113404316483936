import React from "react";
import styled, { css } from "styled-components";

import { Flex, isMobileMediaQuery } from "../../helpers/styles";

function Testimonials({ quote, author, bars, ...props }) {
    return (
        <Container {...props}>
            <div>
                <div>
                    <Quotes bars={bars} vertical={props.vertical}>
                        “
                    </Quotes>
                    <Message>{quote}</Message>
                    <Quotes bars={bars} vertical={props.vertical}>
                        “
                    </Quotes>
                </div>
                {author && <Author>{author}</Author>}
            </div>
        </Container>
    );
}

const Container = styled(Flex)`
    align-self: flex-start;
    & > div {
        display: flex;
        flex-direction: column;

        & > div {
            display: flex;
            flex-direction: ${(props) => (props.vertical ? "column" : "row")};
            justify-content: center;
        }
    }

    ${isMobileMediaQuery} {
        padding-left: 24px;
        padding-right: 24px;
    }
`;

const Quotes = styled.span`
    margin-top: -15px;
    margin-bottom: auto;
    padding: 0px;
    height: auto;
    font-family: "DMSerifDisplay";
    font-size: 120px;
    line-height: 0.85;
    text-align: center;
    color: rgba(228, 133, 140, 0.5);

    ${(props) =>
        props.vertical &&
        `
            margin: 0px auto;
            margin-bottom: -40px;
    `}

    :last-of-type {
        transform: scale(-1);
        margin-bottom: -15px;
        margin-top: auto;

        ${(props) =>
            props.vertical &&
            `
            margin-top: -40px;
        `}
    }

    ${isMobileMediaQuery} {
        font-size: 80.5px;
    }

    ${({ bars }) =>
        bars &&
        css`
            width: 50px;
            height: 5px;
            background-color: #f3808b;
            color: "transparent;";
            overflow: hidden;
            margin: 0 auto 30px auto;

            :last-of-type {
                transform: initial;
                margin: 30px auto 0 auto;
            }
        `}
`;

const Message = styled.div`
    max-width: 510px;
    font-family: "DMSerifDisplay";
    font-size: 28px;
    font-style: italic;
    line-height: 1.29;
    text-align: center;
    color: #34343b;

    ${isMobileMediaQuery} {
        font-size: 21px;
    }
`;

const Author = styled.div`
    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    text-transform: uppercase;
    line-height: 1.82;
    letter-spacing: 2.2px;
    text-align: center;
    color: #4d4d59;
    margin: auto;
    margin-top: 41px;
`;

export default Testimonials;
