export const ADD_PRODUCT = "ADD_PRODUCT";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const CLEAR_CART = "CLEAR_CART";
export const PUT_PRODUCTS = "PUT_PRODUCTS";

export const addProduct = (product) => {
    if (!product || typeof product !== "object") return;

    return {
        type: ADD_PRODUCT,
        payload: {
            product,
        },
    };
};

export const removeProduct = (product_id) => {
    return {
        type: REMOVE_PRODUCT,
        payload: {
            product_id,
        },
    };
};

export const updateProducts = (products) => {
    return {
        type: PUT_PRODUCTS,
        payload: {
            products,
        },
    };
};

export const clear = () => {
    return {
        type: CLEAR_CART,
    };
};
