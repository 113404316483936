import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ContentDelimiter, Flex } from "./../../helpers/styles";
import Header from "./../../components/Header";
import Footer from "./../../components/Footer";
import Button from "../../components/Button";
import HeroImage from "../../assets/images/social-signup-hero.png";
import EmailDark from "../../assets/images/email-dark.png";
import GooglelDark from "../../assets/images/google-dark.png";
import FacebookDark from "../../assets/images/facebook-dark.png";

const SocialSignup = () => {
    const navigate = useNavigate();

    return (
        <>
            <Header
                navigationItems={[
                    { label: "Compre", action: () => navigate("/categorias/novidades") },
                    { label: "Quero vender", action: () => navigate("/desapegue") },
                    { label: "Peça seu box", action: () => navigate("/box") },
                    { label: "Lojas Influencers", action: () => navigate("/lojas-influencers") },
                    { label: "Blog", action: () => navigate("/blog") },
                    { label: "Outlet", action: () => navigate("/outlet") },
                    { label: "Sale", action: () => navigate("/promocoes") },
                ]}
            />
            <ContentDelimiter>
                <Flex width="910px" alignItems="flex-start" margin="87px auto 54px auto">
                    <Flex margin="0px 52px 0px 0px">
                        <Image src={HeroImage} width="385px" />
                    </Flex>
                    <Flex width="300px" direction="column" alignItems="flex-start" justifyContent="flex-start">
                        <Title>
                            cadastre-se
                            <br />
                            e circule com
                            <br />a re petit
                        </Title>
                        <Separator />
                        <Text>
                            Escolha como você prefere se
                            <br />
                            cadastrar e acessar e Re Petit
                        </Text>
                        <Separator />

                        <Button
                            justifyContent="flex-start"
                            dark
                            outlined
                            width="300px"
                            value={
                                <>
                                    <LoginImage src={FacebookDark} />
                                    Acessar com Facebook
                                </>
                            }
                        />
                        <Button
                            justifyContent="flex-start"
                            dark
                            outlined
                            width="300px"
                            value={
                                <>
                                    <LoginImage src={GooglelDark} />
                                    Acessar com Google
                                </>
                            }
                        />
                        <Button
                            justifyContent="flex-start"
                            dark
                            outlined
                            width="300px"
                            value={
                                <>
                                    <LoginImage src={EmailDark} />
                                    Acessar com meu e-mail
                                </>
                            }
                            onClick={() => navigate("/login")}
                        />

                        <Flex alignItems="flex-start" justifyContent="flex-start" width="100%" margin="30px 0 32px 0">
                            <FooterText>
                                Ao criar minha conta eu concordo que li e aceito os <Link>Termos e Condições</Link> e{" "}
                                <Link>Política de Privacidade</Link>.
                            </FooterText>
                        </Flex>

                        <Button
                            width="100%"
                            dark
                            filled
                            margin="0"
                            value="Criar conta"
                            onClick={() => navigate("/cadastro/email")}
                        />
                    </Flex>
                </Flex>
            </ContentDelimiter>

            <Footer mini />
        </>
    );
};

const Image = styled.img``;

const Title = styled.h1`
    margin: 0px;
    margin-bottom: 26px;
    text-transform: uppercase;

    font-family: "SackersGothicStd";
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: 4px;
    text-align: left;
    color: #34343b;
`;

const Separator = styled.hr`
    width: 40px;
    height: 3px;
    margin: 0px;
    margin-bottom: 20px;
    background-color: #e4858c;
    border: none;
`;

const Text = styled.p`
    flex: 1;
    margin: 0 0 20px 0;

    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: #676777;
`;

const FooterText = styled(Text)`
    margin: 0px;
    font-size: 13px;
    &,
    a {
        color: #34343b;
    }
`;

const LoginImage = styled.img`
    margin-right: 13px;
    width: 25px;
    height: auto;
`;

export default SocialSignup;
