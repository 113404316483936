import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Link as RouterLink, useLocation, useParams, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { useDispatch } from "react-redux";
import { generateUUID } from "../../components/Functions";
import api from "../../services/api";
import * as UserActions from "../../redux/actions/user";
import { identifyZipCode, openPrivacyNTerms, validatePassword } from "../../helpers";
import { Flex, isMobile, isMobileMediaQuery } from "./../../helpers/styles";
import Header from "./../../components/Header";
import Footer from "./../../components/Footer";
import Button from "../../components/Button";
import TextInput from "../../components/Inputs/TextInput";
import CheckBox from "../../components/Inputs/CheckBox";
import SelectInput from "../../components/Inputs/SelectInput";
import AlertModal from "../../components/AlertModal";
import TextArea from "../../components/Inputs/TextArea";
import ChevronLeftIcon from "./../../assets/images/seta.svg";
import ModalSuccess from "../../assets/images/modal-success-hero.jpg";
import WhatsappIcon from "../../assets/images/whatsapp.svg";
import DarkEmailIcon from "../../assets/images/dark-mail-icon.png";
import ProfileForm from "../../components/ProfileForm";
import ButtonLink from "../../components/ButtonLink";
import SigninLink from "../../components/SigninLink";

const Account = () => {
    const { current_tab } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [checkedSentMethod, setCheckedSentMethod] = useState("");
    const [selectedPickupPoint, setSelectedPickupPoint] = useState("");
    const [pickupPoints, setPickupPoints] = useState([]);
    const user = useSelector((state) => state.user);
    const main_address = user.user_addresses && user.user_addresses[0];
    const [values, setValues] = useState({
        ...user,
        birth_date: moment(user.birth_date, "YYYY-MM-DD").format("DD/MM/YYYY"),
        ...{
            phone_number: `${user.phone_code_area}${user.phone_number}`,
        },

        ...(main_address
            ? {
                ...main_address,
                zip_code: main_address.zip,
                house_number: main_address.number,
            }
            : {}),
    });
    const [modal, setModal] = useState(undefined);
    const [errors, setErrors] = useState({});
    const [sendingRequest, setSendingRequest] = useState(false);

    useEffect(() => {
        dispatch(UserActions.refreshProfile());

        // eslint-disable-next-line
        dataLayer.push({ ecommerce: null });

        // eslint-disable-next-line
        dataLayer.push({
            event: "virtualPageview",
            page: document.title,
            type: "SellerSignUp",
            environment: process.env.REACT_APP_ENV,
            user: [
                {
                    name: user.first_name && user.last_name ? user.first_name + " " + user.last_name : "",
                    username: user.slug ? user.slug : "",
                    user_id: user.id ? user.id : "",
                    email: user.email ? user.email : "",
                    language: "pt-br",
                    returning: JSON.parse(localStorage.getItem("accepted_cookies")) ? true : false,
                },
            ],
        });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (checkedSentMethod !== "pickup_point") setSelectedPickupPoint(null);
    }, [checkedSentMethod]);

    useEffect(() => {
        if (modal?.show && modal?.success && !(location.pathname.indexOf("/sucesso") > 0)) {
            // eslint-disable-next-line
            dataLayer.push({ ecommerce: null });

            // eslint-disable-next-line
            dataLayer.push({ event: "lead-quero-vender" });
            navigate("/quero-vender/sucesso");
        }

        // eslint-disable-next-line
    }, [modal]);

    useEffect(() => {
        if (location.pathname.indexOf("/sucesso") > 0 && !modal?.show) {
            navigate("/quero-vender");
        }

        // eslint-disable-next-line
    }, [location.pathname]);

    const getPickupPoints = async () => {
        try {
            const response = await api.get("/pickup_points");
            setPickupPoints(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getPickupPoints();
    }, []);

    const handleZipCode = async ({ target: { value, name } }) => {
        let newValues = { ...values };

        if (value && value.replace(/_/g, "").replace(/-/g, "").length >= 8) {
            let zipCodeResponse = await identifyZipCode(value);

            if (zipCodeResponse.street) {
                setErrors((prevState) => {
                    return {
                        ...prevState,
                        zip_code: false,
                    };
                });
            }

            newValues.street = zipCodeResponse.logradouro;
            newValues.state = zipCodeResponse.uf;
            newValues.city = zipCodeResponse.localidade;
            newValues.neighborhood = zipCodeResponse.bairro;
        }

        setValues((prevState) => {
            return { ...prevState, newValues };
        });
    };

    const handleChange = async (e) => {
        let {
            target: { name, value, checked, type },
        } = e;

        let newValues = { ...values };

        if (name !== undefined) {
            if (value !== undefined && (type !== "checkbox" || type === "radio")) {
                newValues = {
                    ...newValues,
                    [name]: value,
                };
            } else if (checked !== undefined) {
                newValues = {
                    ...newValues,
                    [name]: checked,
                };
            }
        }

        setValues((prevState) => {
            return { ...prevState, ...newValues };
        });

        canSubmit(false, true, newValues);
    };

    const many = ["items_age", "how_did_you_meet_repetit"];

    const isChecked = (type, expected) => {
        if (many.includes(type)) {
            return values[type] && values[type].findIndex((item) => item === expected) >= 0;
        } else {
            return values[type] !== undefined ? values[type] === expected : undefined;
        }
    };

    const handleSelectItem = (type, value, checked) => {
        if (many.includes(type)) {
            let newValues = { ...values };

            if (type === "how_did_you_meet_repetit" && values[type] && typeof values[type] !== "object") {
                values[type] = [];
            }

            if (checked === true) {
                newValues[type] = [...(values[type] || []), value];
            } else {
                newValues[type] = (values[type] || []).filter((item) => item !== value);
            }

            setValues(newValues);
        } else {
            checked === true ? setValues({ ...values, [type]: value }) : setValues({ ...values, [type]: undefined });
        }
    };

    const canSubmit = (scroll = false, ignoreUndefined = false, checkValues = values, canSetErrors = true) => {
        let canChange = true;

        let newErrors = { ...errors };

        const firstStepRequireds = [
            "email",
            "password",
            "confirm_password",
            "first_name",
            "last_name",
            "phone_number",
            "cpf",

            "street",
            "house_number",
            "neighborhood",
            "city",
            "state",
            "zip_code",

            "number_of_items",
            "items_age",
        ];

        if (user && user.token) {
            firstStepRequireds.indexOf("password") > -1 &&
                firstStepRequireds.splice(firstStepRequireds.indexOf("password"), 1);

            firstStepRequireds.indexOf("confirm_password") > -1 &&
                firstStepRequireds.splice(firstStepRequireds.indexOf("confirm_password"), 1);
        }

        firstStepRequireds.forEach((requiredField) => {
            if (
                (!Object.keys(checkValues).includes(requiredField) && !ignoreUndefined) ||
                checkValues[requiredField] === ""
            ) {
                newErrors[requiredField] = true;
            } else if (!newErrors[requiredField] || checkValues[requiredField] !== undefined) {
                newErrors[requiredField] = false;
            }
        });

        canChange = firstStepRequireds.every((requiredField) => {
            const invalidField = document.querySelector(`[name=${requiredField}]`);

            if (invalidField && scroll) {
                invalidField.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }

            return (
                Object.keys(checkValues).includes(requiredField) &&
                checkValues[requiredField] !== undefined &&
                checkValues[requiredField] !== ""
            );
        });

        if (
            (!checkValues.password ||
                !checkValues.confirm_password ||
                checkValues.password !== checkValues.confirm_password ||
                !validatePassword(checkValues.password)) &&
            (!user || !user.token)
        ) {
            canChange = false;
        }

        const input_number_of_items = document.querySelector(`[name=number_of_items]`);
        if (input_number_of_items.value < 1) {
            canChange = false;
            newErrors.number_of_items = true;
            const invalidField = document.querySelector(`[name=number_of_items]`);
            if (invalidField && scroll) {
                invalidField.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }
        }

        if (canSetErrors) {
            setErrors({ ...newErrors });
        }

        return canChange;
    };

    const handleSendSellerRequest = async () => {
        if (sendingRequest) return;

        if (!canSubmit(true) && !checkedSentMethod) return;

        const seller_payload = {
            seller_quiz: {
                required_item_quantity: values.required_item_quantity,
                number_of_items: values.number_of_items,
                objects_description: values.objects_description,
                items_age: values.items_age,
                delivery_option: checkedSentMethod,
                pickup_point: selectedPickupPoint || null,
            },

            user: {
                email: values.email,
                password: values.password,
                first_name: values.first_name,
                last_name: values.last_name,
                phone_country_code: values.phone_country_code || "55",
                phone_code_area:
                    values.phone_number && values.phone_number.length > 0 && values.phone_number[0] === "("
                        ? values.phone_number.match(/^\((\d{2})\)/)[1]
                        : values.phone_number.substring(0, 2),
                phone_number:
                    values.phone_number && values.phone_number[0] === "("
                        ? values.phone_number.substring(5).replace(/\s/g, "").replace(/_/g, "").replace(/-/g, "")
                        : values.phone_number.substring(2),
                cpf: values.cpf,
                newsletter: values.newsletter,
                birth_date: values.birth_date,
                how_did_you_meet_repetit: values.how_did_you_meet_repetit
                    ? typeof values.how_did_you_meet_repetit === "string"
                        ? values.how_did_you_meet_repetit
                        : (values.how_did_you_meet_repetit || []).join(", ")
                    : "",
                user_addresses: [
                    {
                        street: values.street,
                        number: values.house_number,
                        kind: "shipping",
                        neighborhood: values.neighborhood,
                        additional_info: values.additional_info,
                        city: values.city,
                        state: values.state,
                        zip: values.zip_code,
                    },
                ],
                source: "seller_quiz",
            },
        };

        setSendingRequest(true);

        let saveResponse = await api.post("/seller_quizzes", seller_payload).catch((err) => err.response);

        let userSessionServerData = window.userCurrentServerData(user);
        let event_id = generateUUID();
        let event_time = Math.round(Date.now() / 1000);

        window.fbq("track", "CompleteRegistration", { registration_source: "quiz_venda" }, { eventID: event_id });

        await api
            .post("/facebook_ads_event", {
                data: [
                    {
                        event_name: "CompleteRegistration",
                        event_id: event_id,
                        event_time: event_time,
                        event_source_url: window.location.href,
                        action_source: "website",
                        user_data: userSessionServerData,
                        custom_data: {
                            registration_source: "quiz_venda",
                        },
                    },
                ],
            })
            .then((res) => console.log("EVENTO CompleteRegistration", res.data))
            .catch((res) => res.response);

        setSendingRequest(false);

        if (saveResponse && saveResponse.status === 201) {
            if (saveResponse.headers && saveResponse.headers.token) {
                dispatch(
                    UserActions.login({
                        token: saveResponse.headers.token,
                        ...saveResponse.data,
                    })
                );
                dispatch(UserActions.refreshProfile());
            }

            setModal({
                success: true,
                show: true,
                image: ModalSuccess,
                title: false,
                contentBackground: "#7CA8AF",
                modalHeight: "auto",
                modalMinHeight: "500px",
                modalMaxHeight: "auto",
                heroWidth: "600px",
                heroHeight: "auto",
                callback: () => {
                    setModal({ ...modal, show: false, success: false });
                    navigate("/");
                },
                contentPadding: isMobile ? "0 49px 0 24px" : "0 42px 0 49px",
                content: (
                    <>
                        <AlertTitle>Cadastro para venda confirmado!</AlertTitle>

                        <AlertSeparator />

                        <AlertText>
                            Lembrando que o mínimo aceito são 20 peças.
                            <br />
                            <br />
                            Use sua Re Petit BAG ou embalagens seguras e lacradas. Não esqueça de identificá-las com o
                            mesmo nome do cadastro e CPF.
                            <br />
                            <br />
                            Para venda de objetos grandes entre em contato com a nossa equipe para análise prévia.
                            Objetos grandes só podem ser deixados no nosso escritório.
                            <br />
                            <br />
                            Se optou pela retirada em casa*, nossa equipe irá entrar em contato para organizar.
                            <br />
                            Válido somente para cidade de São Paulo.
                        </AlertText>

                        <AlertSeparator />

                        <AlertText>
                            QUEM VENDE COM A RE PETIT E USA SEUS CRÉDITOS PARA NOVAS COMPRAS GANHA 10% DE DESCONTO EM
                            TODAS AS COMPRAS
                        </AlertText>
                    </>
                ),
            });
        } else {
            setModal({
                show: true,
                message:
                    saveResponse && saveResponse.data && saveResponse.data.message
                        ? saveResponse.data.message
                        : "Erro inesperado. Aguarde um instante e tente outra vez!",
            });
        }
    };

    return (
        <>
            <Helmet>
                <title>Desapegue na Re Petit, ganhe uma renda extra e ajude o nosso planeta</title>
                <meta
                    property="og:title"
                    content="Desapegue na Re Petit, ganhe uma renda extra e ajude o nosso planeta"
                />
                <meta
                    name="description"
                    content="A Re Petit é um brechó online onde você consegue vender roupas, acessórios, calçados, brinquedos e muito mais. Desapegue já!"
                />
                <meta
                    property="og:description"
                    content="A Re Petit é um brechó online onde você consegue vender roupas, acessórios, calçados, brinquedos e muito mais. Desapegue já!"
                />
                <meta
                    name="keywords"
                    content="brechó, brechó infantil, brechó bebês, brechós, brechós infantis, desapego roupa infantil, desapego, vender roupas infantis, desapego acessórios, venda de usados, segunda mão"
                />
            </Helmet>

            <Header hideLinks />

            <Container>
                <Flex flex="1" width="100%" maxHeight="58px" margin="0" justifyContent="flex-start" direction="column">
                    <Flex
                        flex="1"
                        width={isMobile ? "fill-available" : "1222px"}
                        margin="auto"
                        justifyContent="flex-start"
                        padding={isMobile ? "19px 20px 18px 34px" : "19px 0 18px 0"}
                    >
                        <Link to="/desapegue">
                            <ChevronLeft />
                            Voltar
                        </Link>

                        <Flex flex="1" desktop>
                            <StepsTitle active>ENVIO DE PEÇAS PARA VENDA</StepsTitle>
                        </Flex>
                        <Flex flex="1" margin="0 auto 0 30px" justifyContent="flex-start" mobile>
                            <StepCircle active>1</StepCircle>
                        </Flex>
                    </Flex>
                </Flex>

                <Flex
                    flex="1"
                    width={isMobile ? "100%" : "909px"}
                    margin="auto"
                    justifyContent="flex-start"
                    direction="column"
                    padding={isMobile && current_tab === "cadastro" ? "0px 0 40px 0" : "41px 0 40px 0"}
                >
                    <Flex
                        width="100%"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        padding={isMobile ? "0 20px" : undefined}
                        direction="column"
                        boxSizing="border-box"
                    >
                        <SectionTitle margin="30px auto 40px 0px">
                            1. Meus Dados Cadastrais
                            {!user.token && <SigninLink />}
                        </SectionTitle>

                        <ProfileForm
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                            user={user}
                            handleZipCode={handleZipCode}
                        />

                        <QuestionTitle>
                            2. Quantas peças você irá enviar aproximadamente? Lembrando que mínimo são 20.
                        </QuestionTitle>

                        <TextInput
                            type="number"
                            margin="0"
                            placeholder="Nº de peças"
                            name="number_of_items"
                            value={values.number_of_items}
                            onChange={handleChange}
                            error={errors.number_of_items}
                        />

                        <QuestionTitle>
                            3. Se você quer enviar <strong>OBJETOS</strong> e <strong>UTENSÍLIOS</strong> para vender
                            descreva marca, modelo e ano de cada um deles se souber. Não é necessário listar roupas,
                            calçados, livros e brinquedos.
                        </QuestionTitle>

                        <TextArea
                            margin="0"
                            placeholder="Detalhe seus itens aqui"
                            name="objects_description"
                            value={values.objects_description}
                            onChange={handleChange}
                            error={errors.objects_description}
                        ></TextArea>

                        <QuestionTitle>4. Qual idade das peças que você está enviando?</QuestionTitle>

                        <Flex justifyContent={isMobile && "flex-start"}>
                            {[
                                "0/3",
                                "3/6",
                                "6/9",
                                "9/12",
                                "1",
                                "2",
                                "3",
                                "4",
                                "5",
                                "6",
                                "7",
                                "8",
                                "9",
                                "10",
                                "11",
                                "12",
                                "13",
                                "14",
                            ].map((rate) => (
                                <RoundedButton
                                    key={rate}
                                    checked={isChecked("items_age", rate)}
                                    onClick={(e) => handleSelectItem("items_age", rate, !isChecked("items_age", rate))}
                                >
                                    {rate}
                                </RoundedButton>
                            ))}
                        </Flex>

                        <QuestionTitle>5. Conta pra gente: como conheceu a Re Petit?</QuestionTitle>

                        <Flex width="100%" direction="column" margin="0 35px 0 0">
                            <CheckBox
                                width="100%"
                                justifyContent="flex-start"
                                margin="0px auto 15px 0px"
                                secondary
                                name="how_did_you_meet_repetit"
                                checked={
                                    values.how_did_you_meet_repetit &&
                                        typeof values.how_did_you_meet_repetit === "object"
                                        ? isChecked("how_did_you_meet_repetit", "Instagram")
                                        : false
                                }
                                onChange={(e) =>
                                    handleSelectItem("how_did_you_meet_repetit", "Instagram", e.target.checked)
                                }
                            >
                                Instagram
                            </CheckBox>
                            <CheckBox
                                width="100%"
                                justifyContent="flex-start"
                                margin="0px auto 15px 0px"
                                secondary
                                name="how_did_you_meet_repetit"
                                checked={
                                    values.how_did_you_meet_repetit &&
                                        typeof values.how_did_you_meet_repetit === "object"
                                        ? isChecked("how_did_you_meet_repetit", "Facebook")
                                        : false
                                }
                                onChange={(e) =>
                                    handleSelectItem("how_did_you_meet_repetit", "Facebook", e.target.checked)
                                }
                            >
                                Facebook
                            </CheckBox>
                            <CheckBox
                                width="100%"
                                justifyContent="flex-start"
                                margin="0px auto 15px 0px"
                                secondary
                                name="how_did_you_meet_repetit"
                                checked={
                                    values.how_did_you_meet_repetit &&
                                        typeof values.how_did_you_meet_repetit === "object"
                                        ? isChecked("how_did_you_meet_repetit", "Amigos e familiares")
                                        : false
                                }
                                onChange={(e) =>
                                    handleSelectItem(
                                        "how_did_you_meet_repetit",
                                        "Amigos e familiares",
                                        e.target.checked
                                    )
                                }
                            >
                                Amigos e familiares
                            </CheckBox>
                            <CheckBox
                                width="100%"
                                justifyContent="flex-start"
                                margin="0px auto 15px 0px"
                                secondary
                                name="how_did_you_meet_repetit"
                                checked={
                                    values.how_did_you_meet_repetit &&
                                        typeof values.how_did_you_meet_repetit === "object"
                                        ? isChecked("how_did_you_meet_repetit", "Google")
                                        : false
                                }
                                onChange={(e) =>
                                    handleSelectItem("how_did_you_meet_repetit", "Google", e.target.checked)
                                }
                            >
                                Google
                            </CheckBox>
                        </Flex>

                        <QuestionTitle>
                            6. Método de envio: A quantidade mínima de peças para envio são 20.
                        </QuestionTitle>

                        <Flex
                            padding="23px 16px"
                            border="solid 1px #c9c9cf"
                            justifyContent="flex-start"
                            width="fill-available"
                        >
                            <CheckBox
                                width="100%"
                                justifyContent="flex-start"
                                name="store_pick_up"
                                value={"store_pick_up"}
                                radio
                                checked={checkedSentMethod === "store_pick_up"}
                                small
                                onChange={() => setCheckedSentMethod("store_pick_up")}
                                defaultChecked={values.delivery === true}
                            >
                                <CardBoxTitle>RETIRADA - APENAS VÁLIDO PARA CIDADE DE SÃO PAULO</CardBoxTitle>
                            </CheckBox>
                        </Flex>
                        <Flex
                            padding="23px 16px"
                            border="solid 1px #c9c9cf"
                            justifyContent="flex-start"
                            width="fill-available"
                        >
                            <CheckBox
                                width="100%"
                                justifyContent="flex-start"
                                name="box_return"
                                value={"box_return"}
                                radio
                                checked={checkedSentMethod === "box_return"}
                                small
                                onChange={() => setCheckedSentMethod("box_return")}
                                defaultChecked={values.delivery === false}
                            >
                                <CardBoxTitle>RE PETIT BAG COM A DEVOLUÇÃO DO BOX</CardBoxTitle>
                            </CheckBox>
                        </Flex>

                        <Flex
                            padding="23px 16px"
                            border="solid 1px #c9c9cf"
                            justifyContent="flex-start"
                            width="fill-available"
                        >
                            <CheckBox
                                width="100%"
                                justifyContent="flex-start"
                                name="pickup_point"
                                value={"pickup_point"}
                                radio
                                checked={checkedSentMethod === "pickup_point"}
                                small
                                onChange={() => setCheckedSentMethod("pickup_point")}
                                defaultChecked={values.delivery === false}
                            >
                                <CardBoxTitle>RE PETIT BAG EM UM DOS PONTOS DE COLETA</CardBoxTitle>
                            </CheckBox>
                            {checkedSentMethod === "pickup_point" && (
                                <SelectInput
                                    background="#ffffff"
                                    placeholder="Pontos de coleta"
                                    width="auto"
                                    flex="1"
                                    margin="20px 0 auto 0"
                                    name="category"
                                    options={pickupPoints.map(
                                        (item) =>
                                            item.name +
                                            " - " +
                                            item.address.street +
                                            ", " +
                                            item.address.number +
                                            " - " +
                                            item.address.neighborhood +
                                            ", " +
                                            item.address.city +
                                            "/" +
                                            item.address.state
                                    )}
                                    onChange={(option) =>
                                        setSelectedPickupPoint(
                                            pickupPoints.find(
                                                (item) =>
                                                    item.name +
                                                    " - " +
                                                    item.address.street +
                                                    ", " +
                                                    item.address.number +
                                                    " - " +
                                                    item.address.neighborhood +
                                                    ", " +
                                                    item.address.city +
                                                    "/" +
                                                    item.address.state ===
                                                    option.target.value
                                            ).id
                                        )
                                    }
                                />
                            )}
                        </Flex>

                        <Flex
                            padding="23px 16px"
                            border="solid 1px #c9c9cf"
                            justifyContent="flex-start"
                            width="fill-available"
                        >
                            <CheckBox
                                width="100%"
                                justifyContent="flex-start"
                                name="correios"
                                value={"correios"}
                                checked={checkedSentMethod === "correios"}
                                radio
                                small
                                onChange={() => setCheckedSentMethod("correios")}
                                defaultChecked={values.delivery === false}
                            >
                                <CardBoxTitle>ENVIO PELOS CORREIOS</CardBoxTitle>
                            </CheckBox>
                        </Flex>

                        <QuestionTitle>
                            <p>
                                <b>IMPORTANTE: </b>Para vender com a Re Petit é obrigatório ter uma conta bancária
                                (corrente ou poupança) no mesmo nome e CPF do cadastro. Por questões de segurança, não é
                                possível alterar o CPF cadastrado na conta da Re Petit. Caso você queira usar a conta
                                bancária de um parente ou amigo de confiança, você deve preencher os dados de seu perfil
                                na Re Petit com o CPF do titular da conta bancária.
                                <br></br>Caso haja erro de cadastro a transferência não poderá ser efetuada.
                            </p>
                            Agora é só ler e aceitar os{" "}
                            <ButtonLink onClick={() => openPrivacyNTerms("terms")}>Termos e Condições</ButtonLink> de
                            Uso para finalizar seu cadastro!
                        </QuestionTitle>

                        <CheckBox
                            width="100%"
                            justifyContent="flex-start"
                            name="accept_terms"
                            value={values.accept_terms}
                            onChange={handleChange}
                            defaultChecked={values.accept_terms === true}
                            label="Aceito"
                        ></CheckBox>

                        <Flex width={isMobile ? "100%" : "911px"} margin={"40px auto 45px auto"}>
                            <Button
                                width={isMobile ? "320px" : "220px"}
                                dark
                                filled
                                borderradius
                                value="Finalizar Cadastro"
                                margin={isMobile ? "0" : "0 0 0 auto"}
                                onClick={handleSendSellerRequest}
                                loading={sendingRequest}
                                disabled={
                                    !checkedSentMethod ||
                                    (checkedSentMethod === "pickup_point" && !selectedPickupPoint) ||
                                    sendingRequest ||
                                    (values.number_of_items && values.number_of_items < 1) ||
                                    !values.number_of_items ||
                                    (canSubmit(undefined, undefined, undefined, false) && values.accept_terms !== true)
                                }
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </Container>

            <Footer mini />

            {modal && <AlertModal callback={() => setModal({ ...modal, show: false })} {...modal} />}
        </>
    );
};

const Container = styled.div`
    height: 100%;
    flex-grow: 1;
    min-height: 100%;
    margin-top: -50px;

    ${isMobileMediaQuery} {
        margin-top: 0px;
    }
`;

const Link = styled(RouterLink)`
    font-size: 15px;
    line-height: 1.4;
    display: flex;
    align-items: center;
    color: #fefeff;
`;

const ChevronLeft = styled.span`
    display: inline-block;
    background-color: #fff;
    mask-size: cover;
    mask-position: center;
    mask-image: url(${ChevronLeftIcon});
    object-fit: contain;
    height: 14px;
    width: 8px;
    margin-right: 9px;
`;

const StepsTitle = styled.p`
    margin: auto 50px auto 0px;
    text-transform: uppercase;

    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 2.2px;
    text-align: left;
    color: #666679;

    ${(props) => props.active && `color: #fff;`}
`;

const StepCircle = styled.div`
    margin: auto 10px auto 0px;
    text-transform: uppercase;

    width: 25px;
    height: 25px;
    padding: 0px 0px 2px 2px;
    box-sizing: border-box;
    background-color: #66667a;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: "SackersGothicStd";
    font-size: 15px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 10px;
    letter-spacing: 3px;
    text-align: center;
    color: #34343c;

    ${(props) => props.active && `background-color: #fff;`}
`;

const SectionTitle = styled.h2`
    margin: ${(props) => props.margin || "39px auto 34px 0px"};
    text-transform: uppercase;
    font-family: "SackersGothicStd";
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 2.8px;
    text-align: left;
    color: #34343b;
`;

const QuestionTitle = styled.p`
    margin: 35px auto 22px 0;
    font-family: "Graphik";
    font-size: 15px;
    line-height: 1.4;
    text-align: left;
    color: #34343b;

    a {
        color: #34343b;
    }

    strong {
        font-weight: 600;
    }
`;

const CardBoxTitle = styled.h2`
    margin: ${({ margin }) => (margin ? margin : "0px 0px 16px 0px")};
    text-transform: uppercase;
    font-size: 15px;
    font-family: "Graphik";
    font-weight: 600;
    color: #34343b;
    max-width: calc(100% - 34px);

    :only-child,
    :last-child {
        margin: 0px;
    }
`;

const RoundedButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c9c9cf;
    background-color: #fff;
    box-shadow: none;
    width: 40px;
    height: 40px;
    flex-basis: 40px;
    border-radius: 100%;

    font-family: "Graphik";
    font-size: 15px;
    line-height: 1.4;
    text-align: center;
    color: #676777;

    cursor: pointer;

    ${({ checked }) =>
        checked &&
        css`
            border-color: #676777;
            background-color: #676777;
            color: #fff;
        `}

    :not(:last-child) {
        margin-right: 10px;
    }
`;

const AlertTitle = styled.h1`
    margin: 0 auto 0 0;
    text-transform: uppercase;
    font-family: "SackersGothicStd";
    font-size: 14px;
    font-weight: 900;
    line-height: 1.43;
    letter-spacing: 2.8px;
    text-align: left;
    color: #ffffff;
`;

const AlertText = styled.p`
    margin: 0 auto 0 0;
    font-family: "Graphik";
    font-size: 13px;
    line-height: 1.15;
    text-align: left;
    color: #ffffff;
`;

const AlertSeparator = styled.hr`
    margin: 25px auto 20px 0;
    border: none;
    width: 40px;
    height: 3px;
    background-color: #313139;
`;

const IconImage = styled.img`
    width: 28px;
    height: 28px;
    margin-right: 8px;
`;

const DarkWhatsappIcon = styled(IconImage)`
    background-color: #34343b;
    object-fit: contain;
    mask-size: contain;
    mask-position: center;
    mask-image: url(${WhatsappIcon});
`;

export default Account;
