import React from "react";
import styled from "styled-components";
import { isMobileMediaQuery } from "../../helpers/styles";

function RecreioBoxItem(props) {
    return (
        <Container background={props.background+'?h=419&bg=FFFFFF&auto=format,compress'} onClick={props.onClick}>
            {props.tag && <Tag>{props.tag}</Tag>}
            <BlurryContainer small={props.small}>
                <Title small={props.small}>{props.title}</Title>
                <Author>
                    por{" "}
                    {props.instagramUsername ? (
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => e.stopPropagation()}
                            href={props.instagramUsername && `https://www.instagram.com/${props.instagramUsername}`}
                        >{`@${props.author}`}</a>
                    ) : (
                        `@${props.author}`
                    )}
                </Author>
                <PlusButton>+</PlusButton>
            </BlurryContainer>
        </Container>
    );
}

const Container = styled.div`
    position: relative;
    background-image: url("${({ background }) => background}");
    background-position: 50% 0%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 419px;
    width: auto;
    flex: 0;
    width: auto;
    min-width: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;

    :not(:last-child) {
        margin-right: 1rem;
    }

    ${isMobileMediaQuery} {
        width: 100%;
        min-height: initial;
        max-height: initial;
        height: 0px;
        margin: 0px;
        margin-bottom: 20px;

        padding-bottom: calc(300 / 280 * 100%);

        :not(:last-child) {
            margin-right: 0px;
        }
    }
`;

const Tag = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    text-transform: uppercase;
    writing-mode: vertical-lr;
    transform: scale(-1);
    margin: 20px;

    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #34343b;
`;

const Title = styled.h2`
    margin: 0;
    margin-bottom: 11px;
    font-family: "DMSerifDisplay";
    font-size: 26px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    ${isMobileMediaQuery} {
        font-size: 24px;
    }
`;

const Author = styled.p`
    margin: 0;
    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    a {
        color: #34343b;
    }
`;

const PlusButton = styled.button`
    border: none;
    width: 45px;
    height: 45px;
    border-radius: 0px;
    background-color: #f3808b;
    position: absolute;
    right: 0px;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 42px;
`;

const BlurryContainer = styled.div`
    padding: 30px 35px;
    width: calc(100% - 70px);
    color: #444;
    backdrop-filter: blur(5px);
    background-color: rgb(255 255 255 / 60%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    min-height: 20%;

    ${isMobileMediaQuery} {
        position: absolute;
        bottom: 0px;
    }
`;

export default RecreioBoxItem;
