import React from "react";
import styled, { css } from "styled-components";
import moment from "moment";
import { Flex, isMobile, isMobileMediaQuery } from "../../helpers/styles";
import CheckBox from "../Inputs/CheckBox";
import iconRemove from "../../assets/images/icon-remove.png";
import productPlaceholder from "../../assets/images/product-placeholder.jpg";

function HorizontalProductItem({
    item,
    cart,
    box,
    hideSpecs,
    showId,
    onClick,
    hideRemove,
    handleRemove,
    checkboxCallback,
    enabledFreeShipping,
    ...props
}) {
    const conservation_status = {
        brand_new: "Nunca usado",
        excelent: "Excelente",
        good: "Bom",
        used: "Usado",
    };
    return !item || typeof item !== "object" || item.box_type ? null : (
        <Container direction="column" box={box} onClick={onClick}>
            {item.original_price_label && (
                <SaleTag promo>
                    <span>SALE</span>
                </SaleTag>
            )}
            <Flex width="100%" boxSizing="border-box">
                <ImageContainer>
                    <img src={(item.images && item.images[0]) || productPlaceholder} alt="Foto do produto" />
                    {!item.eligible_for_free_shipping && enabledFreeShipping && (
                        <>
                            <NotFreeShipping>Não aplicável frete grátis</NotFreeShipping>
                        </>
                    )}
                </ImageContainer>
                <Flex flex="1" direction="column" justifyContent="flex-start" alignItems="flex-start">
                    <Title>
                        {item.name}
                        {showId && ` - Cod.: ${item.id}`}
                    </Title>
                    {(item.by ||
                        item.item_category ||
                        item.color ||
                        item.gender ||
                        item.collection ||
                        item.size ||
                        item.bought_in ||
                        item.credit_card) && (
                        <Description>
                            {item.size && (
                                <>
                                    Tamanho: {item.size.toLowerCase()}
                                    <br />
                                </>
                            )}
                            {item.color_list && (
                                <>
                                    Cor:{" "}
                                    {item.color_list &&
                                        item.color_list.map((item) => item.name.toLowerCase()).join(", ")}
                                    <br />
                                </>
                            )}
                            {item.item_design && !hideSpecs && (
                                <>
                                    Estampa: {item.item_design.name}
                                    <br />
                                </>
                            )}
                            {item.brand && !hideSpecs && (
                                <>
                                    Marca: {item.brand.name.toLowerCase()}
                                    <br />
                                </>
                            )}
                            {item.item_fabric && !hideSpecs && (
                                <>
                                    Composição: {item.item_fabric.name}
                                    <br />
                                </>
                            )}
                            {item.conservation && !hideSpecs && (
                                <>
                                    Estado de conservação: {conservation_status[item.conservation]}
                                    <br />
                                </>
                            )}
                            {item.notes && !hideSpecs && <>Observações: {item.notes}</>}
                            {item.bought_in && !hideSpecs && (
                                <>
                                    Comprado: {item.bought_in}
                                    <br />
                                </>
                            )}
                            {item.credit_card && !hideSpecs && (
                                <>
                                    Cartão de Crédito: {item.credit_card}
                                    <br />
                                </>
                            )}
                            {item.description && !hideSpecs && item.description}
                        </Description>
                    )}
                    {!box && !hideRemove && (
                        <Flex width="100%">
                            <Separator />
                            <RemoveButton onClick={() => handleRemove(item)}>
                                <img src={iconRemove} alt="Remove Button" />
                            </RemoveButton>
                        </Flex>
                    )}
                    {(cart || box) && (
                        <QuantityContainer alignItems="flex-end" direction={box && "column"}>
                            {(item.price || item.price_label) && !isMobile && (
                                <PriceTag cart={cart} box={box}>
                                    R${" "}
                                    {parseFloat(item.price || item.price_label).toLocaleString("pt-BR", {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}
                                </PriceTag>
                            )}
                            <Flex desktop>
                                {box &&
                                    (!item.status || item.status === "in_box" || item.status === "pending"
                                        ? !props.sold && (
                                              <CheckBox
                                                  name={item.id}
                                                  defaultChecked={props.defaultChecked}
                                                  onChange={checkboxCallback}
                                                  margin="57px 0 0 0"
                                              >
                                                  Comprar
                                              </CheckBox>
                                          )
                                        : !props.hideStatus && (
                                              <Status
                                                  onClick={
                                                      moment().diff(moment(item.date), "days") < 7 &&
                                                      props.returnProductAction
                                                  }
                                                  color={
                                                      item.status.toLowerCase() === "finalizado"
                                                          ? "#79c992"
                                                          : item.status.toLowerCase() === "devolvido"
                                                          ? "#ff6600"
                                                          : null
                                                  }
                                              >
                                                  {item.date && moment().diff(moment(item.date), "days") < 7
                                                      ? `Devolver (${
                                                            7 - moment().diff(moment(item.date), "days")
                                                        } dias restantes)`
                                                      : item.status}
                                              </Status>
                                          ))}
                            </Flex>
                        </QuantityContainer>
                    )}
                    {!cart && !box && (item.price || item.price_label) && (
                        <PriceTag>
                            R${" "}
                            {parseFloat(item.price || item.price_label).toLocaleString("pt-br", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </PriceTag>
                    )}
                </Flex>
            </Flex>
            <Flex margin="19px 0 0 0" width="100%" justifyContent="space-between" mobile>
                <Flex>
                    {(item.price || item.price_label) && (
                        <PriceTag cart={cart} box={box}>
                            R${" "}
                            {parseFloat(item.price || item.price_label).toLocaleString("pt-br", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </PriceTag>
                    )}
                </Flex>
                <Flex>
                    {box &&
                        (!item.status || item.status === "in_box" || item.status === "pending"
                            ? !props.sold && (
                                  <CheckBox
                                      name={item.id}
                                      defaultChecked={props.defaultChecked}
                                      onChange={checkboxCallback}
                                      margin="0"
                                  >
                                      Comprar
                                  </CheckBox>
                              )
                            : !props.hideStatus && (
                                  <Status
                                      onClick={
                                          moment().diff(moment(item.date), "days") < 7 && props.returnProductAction
                                      }
                                      color={
                                          item.status.toLowerCase() === "finalizado"
                                              ? "#79c992"
                                              : item.status.toLowerCase() === "devolvido"
                                              ? "#ff6600"
                                              : null
                                      }
                                  >
                                      {item.date && moment().diff(moment(item.date), "days") < 7
                                          ? `Devolver (${7 - moment().diff(moment(item.date), "days")} dias restantes)`
                                          : item.status}
                                  </Status>
                              ))}
                </Flex>
            </Flex>
        </Container>
    );
}
const Container = styled(Flex)`
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    ${(props) =>
        props.box &&
        css`
            :not(:last-of-type) {
                padding-bottom: 24px;
                border-bottom: 1px solid #e5e5e8;
            }
        `}
`;

const ImageContainer = styled.div`
    margin-right: 19px;
    border: solid 1px #f4f4f5;
    padding: 10px 20px;
    min-height: 85px;
    align-self: stretch;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 72px;
        height: 103px;
        object-fit: contain;

        ${isMobileMediaQuery} {
            width: 60px;
            height: 85px;
        }
    }
`;

const Title = styled.div`
    margin-bottom: 13px;

    font-family: "DMSerifDisplay";
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    ${isMobileMediaQuery} {
        font-size: 20px;
    }
`;

const Description = styled.div`
    margin-bottom: 4px;

    max-width: calc(100% - 120px);

    font-family: "Graphik";
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;
`;

const Separator = styled.hr`
    width: 100%;
    flex: 1;
    height: 1px;
    background-color: #f4f4f5;
    border: 0px;
`;

const RemoveButton = styled.button`
    background-color: transparent;
    width: 18px;
    height: 18px;
    padding: 0px;
    margin-left: 10px;
    border: 0px;

    ${(props) => props.onClick && "cursor:pointer;"}
`;

const PriceTag = styled.p`
    margin: 0px;

    font-family: "Graphik";
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.85;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    ${(props) =>
        (props.cart || props.box) &&
        css`
            font-size: 17px;
        `}

    ${(props) =>
        props.box &&
        css`
            margin-right: 0px !important;
        `}
`;

const QuantityContainer = styled(Flex)`
    position: absolute;
    top: 6px;
    right: 0px;

    & > :first-child {
        margin-right: 20px;
    }
`;

const Status = styled.div`
    margin-top: 61px;

    text-transform: capitalize;
    font-family: "Graphik";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.86;
    letter-spacing: 0.28px;
    text-align: right;
    color: ${(props) => props.color || "#8c8ca1"};

    ${(props) =>
        props.onClick &&
        css`
            text-decoration: underline;
            cursor: pointer;
        `}

    ${isMobileMediaQuery} {
        margin: 0;
    }
`;

const SaleTag = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    text-align: center;
    background-color: #7ca8af;
    position: absolute;
    top: 13px;
    right: 0px;
    color: #fff;
    text-transform: uppercase;
    padding: 0.25rem 0.5rem;
    padding-left: 16px;
    box-sizing: border-box;
    z-index: 1;

    &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-left: 10px solid #fff;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
    }

    ${({ promo }) =>
        promo &&
        css`
            background-color: #e4858c;
            padding: 0px 15px;
            background-color: transparent;
            transform: rotate(-45deg);
            right: initial;
            left: -12px;

            span {
                z-index: 1;
            }

            &:after {
                z-index: 0;
                border-bottom: 30px solid #e4858c;
                border-left: 30px solid transparent;
                border-right: 30px solid transparent;
                border-top: 30px solid transparent;
            }
        `}
`;

const NotFreeShipping = styled.div`
    font-family: "Graphik";
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 11px;
    text-align: center;
    background-color: #ff000085;
    position: absolute;
    bottom: 0;
    left: 0;
    color: #ffffff;
    text-transform: uppercase;
    padding: 0.25rem 0.5rem;
    box-sizing: border-box;
    max-width: 114px;
    ${isMobileMediaQuery} {
        max-width: 102px;
    }
`;

export default HorizontalProductItem;
