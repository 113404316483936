import { css } from "styled-components";

import {
    DMSerifDisplayRegular,
    DMSerifDisplayItalic,
    SackersGothicStdHeavy,
    SackersGothicStdLight,
    SackersGothicStdMedium,
    GraphikRegular,
    GraphikMedium,
    GraphikBold,
} from "./../assets/fonts";

export const MainFontFace = css`
    @font-face {
        font-family: 'DMSerifDisplay';
        font-weight: normal;
        font-style: normal;
        src: url('${DMSerifDisplayRegular}');
    }
    @font-face {
        font-family: 'DMSerifDisplay';
        font-weight: normal;
        font-style: italic;
        src: url('${DMSerifDisplayItalic}');
    }

    @font-face {
        font-family: 'SackersGothicStd';
        font-weight: 100;
        font-style: normal;
        src: url('${SackersGothicStdLight}');
    }
    @font-face {
        font-family: 'SackersGothicStd';
        font-weight: normal;
        font-style: normal;
        src: url('${SackersGothicStdMedium}');
    }
    @font-face {
        font-family: 'SackersGothicStd';
        font-weight: 700;
        font-style: normal;
        src: url('${SackersGothicStdHeavy}');
    }

    @font-face {
        font-family: 'Graphik';
        font-weight: normal;
        font-style: normal;
        src: url('${GraphikRegular}');
    }
    @font-face {
        font-family: 'Graphik';
        font-weight: 600;
        font-style: normal;
        src: url('${GraphikMedium}');
    }
    @font-face {
        font-family: 'Graphik';
        font-weight: 700;
        font-style: normal;
        src: url('${GraphikBold}');
    }
`;
