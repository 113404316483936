import React, { useEffect, useState } from "react";

import styled, { css } from "styled-components";
import { Flex, isMobileMediaQuery } from "../../helpers/styles";

import HeartIconOff from "../../assets/images/heart-icon-off.png";
import HeartIconOn from "../../assets/images/heart-icon-on.png";

const QuizSelectBox = ({
    image,
    title,
    subtitle,
    description,
    value,
    onChange,
    vertical,
    width,
    flex,
    justifyContent,
    height,
    minHeight,
    margin,
    padding,
    heartType,
    readOnly,
    error,
    ...props
}) => {
    const [checked, setChecked] = useState(value || undefined);

    useEffect(() => {
        value !== checked && setChecked(value);

        // eslint-disable-next-line
    }, [value]);

    const handleCheck = () => {
        if (readOnly) return;

        onChange && onChange(!checked);
        setChecked(!checked);
    };

    return (
        <Container
            vertical={vertical}
            width={width}
            margin={margin}
            padding={padding}
            flex={flex}
            justifyContent={justifyContent}
            height={height}
            minHeight={minHeight}
            onClick={handleCheck}
            active={checked}
            readOnly={readOnly}
            error={error}
            {...props}
        >
            {!readOnly &&
                (!heartType ? (
                    <RadioIcon active={checked}>
                        <span />
                    </RadioIcon>
                ) : (
                    <HeartImg src={checked ? HeartIconOn : HeartIconOff} />
                ))}
            {props.children}
            {props.children && title && <hr />}
            <Flex
                direction={!vertical ? "column" : "row"}
                alignItems={!vertical && "flex-start"}
                justifyContent={!vertical && "flex-start"}
            >
                {title &&
                    (subtitle ? (
                        <Flex alignItems="flex-start" direction="column">
                            <Title hasSubtitle={subtitle !== undefined}>
                                {title}
                            </Title>
                            <SubTitle>{subtitle}</SubTitle>
                        </Flex>
                    ) : (
                        <Title>{title}</Title>
                    ))}
                {!title && subtitle && <SubTitle>{subtitle}</SubTitle>}
                {description && (
                    <Description vertical={vertical}>{description}</Description>
                )}
            </Flex>
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    width: auto;
    display: flex;
    justify-content: ${({ justifyContent }) =>
        justifyContent ? justifyContent : "center"};
    align-items: center;
    padding: 10px 36px;
    background-color: #fbfcfc;
    box-shadow: 0px 1px 3px 0 rgba(52, 52, 59, 0.1);
    ${(props) => !props.readOnly && `cursor: pointer;`}
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid transparent;

    ${({ width }) => width && `width: ${width}`};
    ${({ height }) => height && `height: ${height}`};
    ${({ minHeight }) => minHeight && `min-height: ${minHeight}`};
    ${({ flex }) => flex && `flex: ${flex}`};
    ${({ margin }) => margin && `margin: ${margin}`};
    ${({ padding }) => padding && `padding: ${padding}`};

    hr {
        height: 80px;
        min-width: 1px;
        width: 1px;
        border: none;
        background-color: #000;
        margin: auto 20px;
    }

    ${(props) =>
        props.vertical &&
        css`
            flex-direction: column;
            min-height: ${({ minHeight }) => (minHeight ? minHeight : "430px")};
            hr {
                width: 80px;
                height: 1px;
                margin: 20px auto;
            }
        `}

    img {
        object-fit: cover;
        transition: filter 0.2s ease-in-out;
    }

    ${(props) =>
        props.active === false &&
        css`
            img {
                filter: grayscale(100%);
            }
        `}

    ${isMobileMediaQuery} {
        width: ${({ width }) => (width ? width : "100%")};
        min-width: ${({ minWidth }) =>
            minWidth ? minWidth : "fill-available"};
        height: auto;
        margin: ${({ margin }) => (margin ? margin : "0")};

        ${({ vertical }) =>
            !vertical &&
            css`
                :not(:last-of-type) {
                    margin-bottom: 20px;
                }
            `}
    }

    ${({ error, errorMessage, active }) =>
        (error || errorMessage) &&
        active !== true &&
        active !== false &&
        css`
            border-color: red !important;
        `};
`;

const Title = styled.h1`
    margin: 0px;

    font-family: "DMSerifDisplay";
    font-size: 19px;
    font-style: italic;
    line-height: 1.11;
    text-align: center;
    color: #34343b;

    :not(:last-child) {
        margin-bottom: 17px;
    }

    ${(props) =>
        props.hasSubtitle &&
        `
        :not(:last-child) {
            margin-bottom: 7px;
        }
    `}
`;

const SubTitle = styled.h1`
    margin: 0px;
    font-family: "Graphik";
    font-size: 13px;
    font-weight: normal;
    line-height: 1.15;
    text-align: left;
    color: #34343b;
`;

const Description = styled.p`
    margin: 0px;
    font-size: 13px;
    line-height: 1.23;
    text-align: center;
    color: #676777;

    ${({ vertical }) => !vertical && `text-align: left;`}
`;

const RadioIcon = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 10px;
    width: 27px;
    height: 27px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #cecfd4;
    background-color: transparent;
    border-radius: 100%;

    span {
        display: none;
        background-color: #e4858c;
        width: 15px;
        height: 15px;
        border-radius: 100%;
    }

    ${(props) =>
        props.active &&
        css`
            span {
                display: block;
            }
        `}

    ${isMobileMediaQuery} {
        background-color: #fff;
    }
`;

const HeartImg = styled.img`
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 10px;
    width: 26px;
    height: 23px;
    box-sizing: border-box;
`;

export default QuizSelectBox;
