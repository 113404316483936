import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import api from "../../services/api";
import { Flex, isMobile, isMobileMediaQuery } from "./../../helpers/styles";
import Button from "../../components/Button";
import SectionTitle from "../../components/SectionTitle";
import TextInput from "../../components/Inputs/TextInput";
import CheckBox from "../../components/Inputs/CheckBox";
import ButtonLink from "../../components/ButtonLink";
import { openPrivacyNTerms } from "../../helpers";
import AlertModal from "../../components/AlertModal";
import ModalSuccess from "../../assets/images/modal-success-hero.jpg";

const Indique = ({ title, hideButton, ...props }) => {
    const navigate = useNavigate();
    const [referrals, setReferrals] = useState([{}, {}, {}]);
    const [values, setValues] = useState({});
    const [modal, setModal] = useState(undefined);

    const handleChange = (e, index) => {
        let {
            target: { name, value, checked, type },
        } = e;

        if (name !== undefined) {
            if (value !== undefined && type !== "checkbox") {
                let newReferrals = [...referrals];
                newReferrals[index] = { ...referrals[index], [name]: value };
                setReferrals(newReferrals);
            } else if (type === "checkbox") {
                setValues({
                    ...values,
                    [name]: checked,
                });
            }
        }
    };

    const handleSendReferrals = async () => {
        let response = await api
            .post("/profiles/referrals", {
                referrals: referrals.filter((item) => item.name && item.cell_phone),
            })
            .catch((err) => console.log(err));

        if (response.status === 204) {
            setReferrals([{}, {}, {}]);
            setValues({});

            setModal({
                show: true,
                image: ModalSuccess,
                title: "Yeah!",
                message: "Agradecemos sua colaboração!",
                callback: () => {
                    setModal({ ...modal, show: false });
                    navigate("/");
                },
            });
        } else {
            console.error(response);

            setModal({
                show: true,
                message:
                    response && response.data && response.data.message
                        ? response.data.message
                        : "Erro inesperado. Aguarde um instante e tente outra vez!",
            });
        }
    };

    return (
        <>
            {title ? title : <SectionTitle margin="0 0 30px 0">Indique quem vai amar a Re Petit</SectionTitle>}

            <Text>Se uma indicação comprar com a Re Petit você ganha 10% de desconto na próxima compra.</Text>

            <Flex direction="column" width="100%">
                {referrals.map((item, index) => (
                    <Flex
                        direction={isMobile ? "column" : "row"}
                        width="100%"
                        margin="0 0 20px 0"
                        padding={isMobile && "0 20px"}
                        boxSizing="border-box"
                    >
                        <TextInput
                            width={isMobile ? "100%" : "calc(50% - 17.5px)"}
                            margin={isMobile ? "0 0 20px 0" : "0 35px 0 0"}
                            placeholder="Nome"
                            name="name"
                            onChange={(e) => handleChange(e, index)}
                        />
                        <TextInput
                            mask="(99) 99999-9999"
                            width={isMobile ? "100%" : "calc(50% - 17.5px)"}
                            placeholder="Celular com DDD: (11) XXXXX-XXXX"
                            name="cell_phone"
                            onChange={(e) => handleChange(e, index)}
                        />
                    </Flex>
                ))}
            </Flex>

            <CheckBox
                margin={isMobile ? "25px auto 40px 20px" : "45px auto 40px 0"}
                label={
                    <>
                        Eu li e aceito os{" "}
                        <ButtonLink onClick={() => openPrivacyNTerms("terms")}>Termos e Condições</ButtonLink> e{" "}
                        <ButtonLink onClick={() => openPrivacyNTerms("privacy")}>Política de Privacidade</ButtonLink>
                    </>
                }
                name="accept"
                onChange={handleChange}
            />

            {!hideButton && (
                <Button
                    width="200px"
                    margin="auto auto 0 auto"
                    dark
                    filled
                    value="Enviar"
                    onClick={handleSendReferrals}
                    disabled={!values.accept || !referrals.find((item) => item.name && item.cell_phone)}
                />
            )}

            {modal && <AlertModal {...modal} />}
        </>
    );
};

const Text = styled.p`
    margin: 0 0 37px 0;

    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: center;
    color: #676777;

    ${isMobileMediaQuery} {
        width: 100%;
        box-sizing: border-box;
        padding: 0 45px;
    }
`;

export default Indique;
