export const SHOW_LOGIN_MODAL = "SHOW_LOGIN_MODAL";
export const CLOSE_LOGIN_MODAL = "CLOSE_LOGIN_MODAL";

export const show = (redirectUrl) => {
    return {
        type: SHOW_LOGIN_MODAL,
        payload: {
            redirectUrl,
        },
    };
};

export const close = () => {
    return {
        type: CLOSE_LOGIN_MODAL,
    };
};
