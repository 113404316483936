import React from "react";
import styled, { css } from "styled-components";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Flex, isMobile, isMobileMediaQuery } from "./../../helpers/styles";
import { openPrivacyNTerms } from "../../helpers";
import CheckBox from "../../components/Inputs/CheckBox";
import HorizontalProductItem from "../../components/HorizontalProductItem";
import BoxImage from "../../assets/images/box-icon.png";
import InfoIcon from "../../assets/images/info-icon.png";
import WhatsappIcon from "../../assets/images/whatsapp.svg";
import DarkEmailIcon from "../../assets/images/dark-mail-icon.png";
import ButtonLink from "../../components/ButtonLink";

const InfosContent = ({ values, setValues, errors, setErrors, steps, colors, boxTypes, delivery }) => {
    const navigate = useNavigate();

    const handleChange = async (e) => {
        let {
            target: { name, value, checked, type },
        } = e;

        let newValues = { ...values };

        if (name !== undefined) {
            if (value !== undefined && (type !== "checkbox" || type === "radio")) {
                newValues = {
                    ...newValues,
                    [name]: value,
                };
            } else if (checked !== undefined) {
                newValues = {
                    ...newValues,
                    [name]: checked,
                };
            }
        }

        setValues(newValues);
    };

    return (
        <>
            <SectionTitle>
                4 confira o pedido {values.child_gender === "male" ? "do" : "da"} {values.child_name || "..."}
            </SectionTitle>

            <Flex direction={isMobile ? "column" : "row"} alignItems="flex-start" justifyContent="flex-start">
                <Flex
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    direction="column"
                    width={isMobile ? "100%" : "732px"}
                    margin={isMobile ? "0 0 20px 0" : "0px 70px 20px 0px"}
                >
                    <CardBox
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        direction="column"
                        width="fill-available"
                        margin="0px 0px 20px 0px"
                    >
                        <EditCard onClick={() => navigate(`/quiz/${Object.keys(steps)[0]}`)}>Editar</EditCard>

                        <CardBoxTitle>Perfil</CardBoxTitle>

                        <TextSection>
                            {values.child_gender === "unissex"
                                ? "Criança"
                                : values.child_gender === "male"
                                ? "Menino"
                                : "Menina"}{" "}
                            com {moment().diff(moment(values.child_birthday, "DD-MM-YYYY"), "years")} anos de idade e
                            perfil {values.activity_preferences}
                            .
                            <br />
                            Adora {values.child_loves}.
                            <br />
                            Veste {values.short_or_pants_size} em peças de cima, {values.jumpsuit_or_dress_size} em
                            macacões ou vestidos, {values.t_shirt_or_coat_size} em camisetas ou casacos. <br />
                            {values.likes_design === "Sim"
                                ? "Gosta de roupas estampadas"
                                : "Não gosta de roupas estampadas"}
                            {values.unliked_fabrics && values.unliked_fabrics.length > 0
                                ? `, não gosta dos tecidos ${values.unliked_fabrics.join(", ")}`
                                : ""}
                            {values.unliked_colors && values.unliked_colors.length > 0
                                ? ` e não gosta das cores ${
                                      colors &&
                                      colors
                                          .filter((item) => values.unliked_colors.includes(item.id))
                                          .reduce((acc, curr) => [...acc, curr.name.toLowerCase().trim()], [])
                                          .join(", ")
                                  }`
                                : ""}
                            .
                            <br />
                            Calça número {values.shoe_size}.
                            <br />E a grande supresa do box será: {values.surprise_expected}.
                        </TextSection>
                    </CardBox>

                    <CardBox
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        direction="column"
                        width="fill-available"
                        margin="0px 0px 20px 0px"
                    >
                        <EditCard onClick={() => navigate(`/quiz/${Object.keys(steps)[2]}`)}>Editar</EditCard>

                        <CardBoxTitle>
                            {boxTypes &&
                                boxTypes.find((item) => item.id === values.box_type_id) &&
                                boxTypes.find((item) => item.id === values.box_type_id).title}
                        </CardBoxTitle>

                        <TextSection>Preferências:</TextSection>

                        <TextSection>
                            <strong>Parte de cima: </strong>
                            {(values.top_needs || []).join(", ")}.
                            <br />
                            <strong>Parte debaixo: </strong>
                            {(values.bottom_needs || []).join(", ")}.
                            <br />
                            <strong>Peças únicas: </strong>
                            {(values.essential_pieces || []).join(", ")}.
                            <br />
                            <strong>Calçados: </strong>
                            {(values.shoe_needs || []).join(", ")}.
                            <br />
                            <strong>Acessórios: </strong>
                            {(values.accessory_needs || []).join(", ")}.
                            <br />
                            {values.suggestions && (
                                <>
                                    <strong>Faltou alguma coisa? Sugestões: </strong>
                                    {values.suggestions}
                                    <br />
                                </>
                            )}
                            {(values.brands || []).length > 0 && (
                                <>
                                    <strong>Marcas desejadas: </strong>
                                    {(values.brands || []).join(", ")}.
                                    <br />
                                </>
                            )}
                            <strong>Ocasião especial: </strong>
                            {values.special_occasion}.
                            <br />
                            {values.ideas && (
                                <>
                                    <strong>Compartilhe suas ideias: </strong>
                                    {values.ideas}
                                    <br />
                                </>
                            )}
                            <strong>Grande supresa do box: </strong>
                            {values.surprise_expected}.
                            <br />
                            <strong>Deseja enviar peças para avaliação e venda: </strong>
                            {values.send_items_for_sale === "true" ? "Sim" : "Não"}
                            .
                            <br />
                        </TextSection>
                    </CardBox>

                    <CardBox
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        direction="column"
                        width="fill-available"
                        margin="0px 0px 20px 0px"
                    >
                        <EditCard onClick={() => navigate(`/quiz/${Object.keys(steps)[0]}`)}>Editar</EditCard>

                        <CardBoxTitle>Meus dados</CardBoxTitle>

                        <TextSection>
                            {values.street}, {values.house_number}{" "}
                            {values.additional_info && " - " + values.additional_info}
                            <br />
                            {values.neighborhood && values.neighborhood + " - "} {values.city} - {values.state}
                            <br />
                            CEP: {values.zip_code}
                            <br />
                        </TextSection>
                    </CardBox>

                    <CheckBox
                        label={
                            <>
                                Li e aceito a{" "}
                                <ButtonLink onClick={() => openPrivacyNTerms("privacy")}>
                                    Política de Privacidade
                                </ButtonLink>{" "}
                                e <ButtonLink onClick={() => openPrivacyNTerms("terms")}>Termos e Condições</ButtonLink>
                            </>
                        }
                        name="accept_terms"
                        checked={values.accept_terms}
                        onChange={handleChange}
                    />
                </Flex>

                <Flex
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    direction="column"
                    width={isMobile ? "100%" : "420px"}
                    order={isMobile ? "-1" : undefined}
                    boxSizing="box-sizing"
                >
                    <ResumeCard>
                        <ResumeTitle>RESUMO DO PEDIDO</ResumeTitle>

                        {delivery && delivery.services && (
                            <ShippingRow free={delivery.services[0].amount === 0}>
                                <span>Frete</span>
                                <span>
                                    {delivery.services[0].amount === 0
                                        ? "Grátis"
                                        : `R$ ${(delivery.services[0].amount || 0).toLocaleString("pt-br", {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                          })}`}
                                </span>
                            </ShippingRow>
                        )}

                        {delivery && delivery.services && delivery.services[0].obs && (
                            <Flex width="100%" background="#fff" padding="20px 22px 20px 30px" boxSizing="border-box">
                                <EnjoyRow>
                                    <img src={InfoIcon} alt="Info icon" width="20px" height="20px" />
                                    Aproveite:
                                </EnjoyRow>
                                <EnjoyMessage>{delivery.services[0].obs}</EnjoyMessage>
                            </Flex>
                        )}

                        <Flex width="100%" background="#fff" padding="10px 30px 10px 30px" boxSizing="border-box">
                            <HorizontalProductItem
                                item={{
                                    images: [BoxImage],
                                    title:
                                        (boxTypes &&
                                            boxTypes.find((item) => item.id === values.box_type_id) &&
                                            boxTypes.find((item) => item.id === values.box_type_id).title) ||
                                        "Box",
                                }}
                                hideRemove
                            />
                        </Flex>
                    </ResumeCard>

                    <Flex alignItems={"flex-start"} justifyContent={"flex-start"}>
                        <Text>Está com dúvidas? Envie sua mensagem pra gente!</Text>

                        <Flex margin={isMobile ? "0 0 18px 0" : "0px 0px 70px 0px"}>
                            <Flex margin={isMobile ? "0 auto 12px 0" : "0 20px 0 0"}>
                                <DarkWhatsappIcon />
                                <Text>(11) 95376-4930</Text>
                            </Flex>
                            <Flex margin={isMobile && "0 auto 0 0"}>
                                <IconImage src={DarkEmailIcon} />
                                <Text>vendas@repetit.com.br</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </>
    );
};

const SectionTitle = styled.h2`
    margin: 39px auto 39px 0px;
    text-transform: uppercase;
    font-family: "SackersGothicStd";
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 2.8px;
    text-align: left;
    color: #34343b;

    ${isMobileMediaQuery} {
        line-height: 1.45;
    }
`;

const CardBoxTitle = styled.h2`
    margin: ${({ margin }) => (margin ? margin : "0px 0px 16px 0px")};
    text-transform: uppercase;
    font-size: 15px;
    font-family: "Graphik";
    font-weight: 600;
    color: #34343b;

    :only-child,
    :last-child {
        margin: 0px;
    }
`;

const CardBox = styled(Flex)`
    padding: ${(props) => (props.padding ? props.padding : "34px 28px")};
    background-color: #f7f9f9;
    box-shadow: 0px 1px 3px 0 rgba(52, 52, 59, 0.1);
    border-bottom: 2px solid #59cd8e;
    position: relative;
`;

const EditCard = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 36px;
    text-decoration: underline;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0.28px;
    text-align: left;
    color: #676777;

    ${({ onClick }) =>
        onClick &&
        css`
            cursor: pointer;
        `}
`;

const TextSection = styled.p`
    margin: 0px 0px 16px 0px;
    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #676777;
    white-space: pre-wrap;

    strong {
        font-weight: 600;
    }
`;

const ResumeCard = styled(Flex)`
    flex-direction: column;
    flex: 1;
    width: 100%;
    box-shadow: 0px 1px 3px 0 rgba(52, 52, 59, 0.1);
    background-color: #f7f9f9;
    margin-bottom: 30px;
`;

const ResumeTitle = styled.div`
    padding: 26px 0px 23px 0px;
    width: 100%;
    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    line-height: 1.45;
    letter-spacing: 2.2px;
    text-align: center;
    color: #34343b;
    border-bottom: 1px solid #fff;
`;

const ShippingRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 30px 25px 30px;
    width: 100%;
    box-sizing: border-box;

    span {
        font-family: "Graphik";
        font-size: 15px;
        font-weight: 500;
        line-height: 1.6;
    }

    span:first-child {
        text-transform: uppercase;
        font-weight: 600;
        text-align: left;
        color: #34343b;
    }

    span:last-child {
        text-align: right;

        ${(props) =>
            props.free &&
            css`
                color: #79c992;
            `}
    }
`;

const EnjoyRow = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;

    text-transform: uppercase;
    font-family: "Graphik";
    font-size: 13px;
    line-height: 1.15;
    font-weight: 600;
    color: #79c992;

    img {
        margin-right: 5px;
    }
`;

const EnjoyMessage = styled.div`
    font-family: "Graphik";
    font-size: 13px;
    line-height: 1.15;
    letter-spacing: -0.26px;
    color: #34343b;
`;

const Text = styled.p`
    margin: 0px;
    font-size: 15px;
    line-height: 1;
    display: flex;
    align-items: center;
    color: #34343b;

    :not(:last-child) {
        margin-bottom: 10px;
    }
`;

const IconImage = styled.img`
    width: 28px;
    height: 28px;
    margin-right: 8px;
`;

const DarkWhatsappIcon = styled(IconImage)`
    background-color: #34343b;
    mask-size: cover;
    mask-position: center;
    mask-image: url(${WhatsappIcon});
`;

export default InfosContent;
