import React from "react";
import styled from "styled-components";
import { isMobileMediaQuery } from "../../helpers/styles";

import BackgroundAbout from "./../../assets/images/background-about.png";
import BackgroundAboutMobile from "./../../assets/images/background-about-mobile.png";

function AboutSection() {
    return (
        <Container id="sobre">
            <div />
            <div>
                <Title>
                    Sobre a Re Petit
                </Title>
                <Separator />
                <SubTitle>
                    UM MUNDO MELHOR COMEÇA PELO PEQUENO. PEQUENOS GESTOS,
                    PEQUENOS HÁBITOS E POR QUE NÃO PEQUENAS CRIANÇAS?
                </SubTitle>
                <Message>
                    <p>
                        Criamos a Re Petit para oferecer mais do que um brechó
                        on-line de itens infantis. Somos uma plataforma de
                        compra e venda com curadoria criteriosa em roupas,
                        acessórios, calçados, brinquedos, objetos e utensílios
                        para crianças de 0 a 14 anos.
                    </p>

                    <p>
                        Trabalhamos com as melhores marcas nacionais e
                        internacionais, entregamos qualidade e muito estilo por
                        um valor acessível. E mais: geramos renda extra para as
                        mamães e pais que vendem e ainda poupam os recursos do
                        planeta.
                    </p>

                    <p>
                        Esta é a nossa forma de contribuir com um mundo mais
                        justo para todos. Este é o nosso lifestyle. Descubra
                        esse novo jeito de comprar e vender itens infantis
                        seminovos. Vem circular com a gente!
                    </p>
                </Message>
            </div>
        </Container>
    );
}

const Container = styled.div`
    background-image: url(${BackgroundAbout});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 100%;
    flex: 1;
    height: 478px;
    display: flex;
    justify-content: center;
    align-items: center;

    ${isMobileMediaQuery} {
        height: auto;
        background-image: url(${BackgroundAboutMobile});
        background-position: 50% 100%;
        min-height: 700px;
    }

    & > div:nth-child(1) {
        height: 100%;
        width: 400px;
        margin-left: auto;

        ${isMobileMediaQuery} {
            display: none;
        }
    }

    & > div:nth-child(2) {
        max-width: 812px;
        margin: auto;
        margin-left: 0px;
        padding: 76px 0 71px;
        flex: 1;
        box-sizing: border-box;

        ${isMobileMediaQuery} {
            padding: 0px;
            margin: 0px;
            width: 100%;
            max-width: initial;
            padding: 41px 18px 0px 18px;
            padding-bottom: 50%;
        }
    }
`;

const Title = styled.div`
    font-family: "DMSerifDisplay";
    text-transform: uppercase;
    font-size: 67.5px;
    font-weight: 500;
    line-height: 0.8;
    letter-spacing: 8.34px;
    text-align: left;
    color: #34343b;
    word-break: keep-all;

    & > span {
        color: #34343B;
    }

    ${isMobileMediaQuery} {
        max-width: 314px;

        font-size: 53px;
        line-height: 1;
        letter-spacing: 8.17px;
        /* text-align: justify;
        text-align-last: justify; */
        color: #34343B;
    }
`;

const Separator = styled.div`
    width: 70px;
    height: 5px;
    background-color: #34343B;
    margin-top: 31px;
    margin-bottom: 32px;

    ${isMobileMediaQuery} {
        margin-top: 30px;
        margin-bottom: 28px;
    }
`;

const SubTitle = styled.div`
    margin-bottom: 25px;
    text-transform: uppercase;
    font-family: "Graphik";
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 26px;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    ${isMobileMediaQuery} {
        display: none;
    }
`;

const Message = styled.div`
    font-family: "Graphik";
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 21px;
    letter-spacing: normal;
    text-align: left;
    color: #34343B;

    ${isMobileMediaQuery} {
        font-size: 14px;
        text-align: left;
        color: #34343B;
        text-transform: initial;
        margin-bottom: 284px;
    }

    p {
        margin: 0 0 8px 0;
    }
`;

export default AboutSection;
