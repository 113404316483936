import React from "react";
import styled from "styled-components";
import { Flex } from "./../../helpers/styles";

import {
    CarouselProvider,
    Slider,
    Slide,
    DotGroup as CarrousselDotGroup,
    Dot as CarrousselDot,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import ExampleProductItem from "../../components/ExampleProductItem";

import Example1 from "./../../assets/images/example-1.png";
import Example2 from "./../../assets/images/example-2.png";
import Example3 from "./../../assets/images/example-3.png";

const ExamplesCarrousselMobile = () => {
    return (
        <Flex mobile width="100%" height="570px" padding="0px 0px 0px 0px">
            <CarrousselContainer
                naturalSlideWidth={384}
                naturalSlideHeight={530}
                totalSlides={3}
                visibleSlides={1}
            >
                <ContentSlider>
                    <StyledSlide>
                        <ExampleProductItem
                            image={Example1}
                            title={"Calça"}
                            description={
                                <>
                                    <strong>Marca:</strong> Carters{"\n"}
                                    <strong>Preço de Venda: </strong>R$ 35,00
                                    {"\n"}
                                    <strong>Porcentagem Vendedora: </strong>40%
                                    {"\n"}
                                    <strong>Porcentagem Re Petit: </strong>60%
                                </>
                            }
                        />
                    </StyledSlide>
                    <StyledSlide>
                        <ExampleProductItem
                            image={Example2}
                            title={"Vestido"}
                            description={
                                <>
                                    <strong>Marca: </strong>GUCCI{"\n"}
                                    <strong>Preço de Venda: </strong>R$ 550,00
                                    {"\n"}
                                    <strong>Porcentagem Vendedora: </strong>50%
                                    {"\n"}
                                    <strong>Porcentagem Re Petit: </strong>50%
                                </>
                            }
                        />
                    </StyledSlide>
                    <StyledSlide>
                        <ExampleProductItem
                            image={Example3}
                            title={"Carrinho Bebê"}
                            description={
                                <>
                                    <strong>Marca: </strong>Stokke{"\n"}
                                    <strong>Preço de Venda: </strong>R$ 1.500,00
                                    {"\n"}
                                    <strong>Porcentagem Vendedora: </strong>60%
                                    {"\n"}
                                    <strong>Porcentagem Re Petit: </strong>50%
                                </>
                            }
                        />
                    </StyledSlide>
                </ContentSlider>

                <DotGroup
                    renderDots={(e) =>
                        [1, 2, 3].map((item, index) => (
                            <Dot
                                key={`slideDot${index}`}
                                slide={index}
                                current={e.currentSlide === index ? 1 : 0}
                            />
                        ))
                    }
                />
            </CarrousselContainer>
        </Flex>
    );
};

const CarrousselContainer = styled(CarouselProvider)`
    flex: 1;
    height: 570px;
    position: relative;
    width: 100%;
`;

const ContentSlider = styled(Slider)`
    height: 570px;
`;

const StyledSlide = styled(Slide)`
    box-shadow: 0px 1px 2px 0 rgba(52, 52, 60, 0.15);
    & > div > div {
        margin: auto;
    }
    & > div > div span:last-of-type {
        margin-bottom: 20px;
    }
`;

const DotGroup = styled(CarrousselDotGroup)`
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
`;

const Dot = styled(CarrousselDot)`
    padding: 0px;
    border: 0;
    background-color: #f37f8a;
    border-radius: 100%;
    height: 11px;
    width: 11px;
    flex-basis: 11px;

    &:not(:last-child) {
        margin-right: 6px;
    }

    ${(props) => !props.current && `background-color: #e4e4e7;`}
`;

export default ExamplesCarrousselMobile;
