import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Flex } from "../../helpers/styles";
import Button from "../Button";
import { ReactComponent as ChevronDownIcon } from "../../assets/images/chevron-down.svg";

const ExpandableFilter = ({
    title,
    type,
    mobile,
    full,
    value,
    bodyPadding,
    filterTypes,
    selectedFilters,
    filterValues,
    handleClearFilter,
    fromPrice,
    toPrice,
    ...props
}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [borderBottom, setBorderBottom] = useState(false);

    useEffect(() => {
        if (value !== isOpen) setIsOpen(value);
        if (props.borderBottom !== borderBottom) setBorderBottom(true);
        // eslint-disable-next-line

    }, [value]);

    const handleClick = () => {
        props.onChange && props.onChange(!isOpen);
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Container
                isOpen={isOpen}
                borderBottom={borderBottom}
                full={full}
                margin={props.margin}
                padding={props.padding}
                width={props.width}
                borderBox={props.borderBox}
                background={props.background}
                activeBackground={props.activeBackground}
                boxSizing={props.boxSizing}
            >
                <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                    margin="0 auto"
                    padding="0"
                    boxSizing={props.titleBoxSizing}
                    wrap
                >
                    {(selectedFilters[type] || []).length === 0 && !selectedFilters?.to_price && !selectedFilters?.from_price ? (
                        <>
                            <Button
                                width="100%"
                                light
                                lower="initial"
                                margin="0px"
                                padding="25px 15px 25px 25px"
                                borderradius="16px 0px 0px 16px"
                                justifyContent="space-between"
                                onClick={handleClick}
                            >
                                <span>{title.toUpperCase()}</span>
                            </Button>
                        </>
                    ) : (
                        <>
                            <Button
                                width="100%"
                                light
                                lower="initial"
                                margin="0px"
                                padding="25px 15px 25px 25px"
                                borderradius="16px 0px 0px 16px"
                                justifyContent="space-between"
                            >
                                <Flex
                                    width="100%"
                                    direction="row"
                                    alignItems="flex-end"
                                    justifyContent="flex-between"
                                    onClick={handleClick}
                                >
                                    <Flex direction="column" alignItems="flex-start">
                                        <span>{title.toUpperCase()}</span>
                                        <ResultFilter>
                                            {(() => {
                                                let displayValue;

                                                if (["size"].includes(type) && (selectedFilters[type] || []).length > 0) {
                                                    const sizeNames = selectedFilters[type].slice(0, 5);
                                                    const remainingSizes = selectedFilters[type].length - sizeNames.length;

                                                    if (remainingSizes > 0) {
                                                        displayValue = `${sizeNames.join(', ')}, +${remainingSizes} ${remainingSizes === 1 ? 'tamanho' : 'tamanhos'}...`;
                                                    } else {
                                                        displayValue = sizeNames.join(', ') + " ";
                                                    }
                                                } else if (["price"].includes(type) && selectedFilters?.to_price > 0 && selectedFilters?.from_price > 0) {
                                                    displayValue = `R$ ${fromPrice ? fromPrice : 0} - R$ ${toPrice ? toPrice : 0}`;
                                                } else if (["type"].includes(type) && (selectedFilters[type] || []).length > 0) {
                                                    displayValue = <>{filterValues[type][selectedFilters[type]]}</>;
                                                } else if (["color"].includes(type) && (selectedFilters[type] || []).length > 0) {
                                                    const colorNames = selectedFilters[type].map(colorId => {
                                                        const color = filterValues[type].find(c => c.id === colorId);
                                                        return color ? color.name : '';
                                                    }).filter(name => name);

                                                    if (colorNames.length > 2) {
                                                        displayValue = `${colorNames.slice(0, 2).join(', ')}, +${colorNames.length - 2} ${colorNames.length - 2 === 1 ? 'cor' : 'cores'}...`;
                                                    } else {
                                                        displayValue = colorNames.join(', ') + " ";
                                                    }
                                                } else if (["brand"].includes(type) && (selectedFilters[type] || []).length > 0) {
                                                    const brandNames = selectedFilters[type].map(brandId => {
                                                        const brand = filterValues[type].find(b => b.id === brandId);
                                                        return brand ? brand.name : '';
                                                    }).filter(name => name);

                                                    if (brandNames.length > 2) {
                                                        displayValue = `${brandNames.slice(0, 2).join(', ')}, +${brandNames.length - 2} ${brandNames.length - 2 === 1 ? 'marca' : 'marcas'}...`;
                                                    } else {
                                                        displayValue = brandNames.join(', ') + " ";
                                                    }
                                                } else if (!["order"].includes(type) && (selectedFilters[type] || []).length > 0) {
                                                    displayValue = (selectedFilters[type] || []).length;
                                                } else {
                                                    displayValue = filterValues[type][selectedFilters[type]];
                                                }

                                                return displayValue;
                                            })()}
                                        </ResultFilter>

                                    </Flex>
                                </Flex>
                                {(selectedFilters[type] || []).length > 0 && (
                                    <>
                                        <span onClick={() => handleClearFilter(type)}>X</span>
                                    </>
                                )}

                                {(selectedFilters[type] || []).length === 0 && ["price"].includes(type) && selectedFilters?.to_price > 0 && selectedFilters?.from_price > 0 && (
                                    <>
                                        <span onClick={() => handleClearFilter(type)}>X</span>
                                    </>
                                )}

                            </Button>
                        </>
                    )}
                    <DivChevron onClick={handleClick}>
                        <ChevronDownIcon />
                    </DivChevron>
                </Flex>
                <CollapseArea
                    maxHeight={props.maxHeight}
                    bodyPadding={bodyPadding}
                    overFlowY={props.overFlowY}
                    height={props.height}
                    marginBottom={props.marginBottom}
                    isOpen={isOpen}
                >
                    {props.children}
                </CollapseArea>
            </Container>
        </>
    );
};

export default ExpandableFilter;

const Container = styled.div`
    ${({ width }) => `width: ${width || "100%"};`};
    background-color: ${(props) =>
        props.isOpen
            ? props.activeBackground
                ? props.activeBackground
                : ""
            : props.background
                ? props.background
                : "#f7f9f9"};
    transition: 0.25s background-color ease-in-out;
    ${({ margin }) => margin && `margin: ${margin};`};
    ${({ padding }) => padding && `padding: ${padding};`};
    ${({ borderBox }) => borderBox && `box-sizing: border-box;`};
    ${(props) =>
        props.isOpen &&
        `
            & > div > div > svg:first-of-type {
                transform: scaleY(-1);
            }
    `};
    ${(props) =>
        props.full &&
        `
            width: calc(100% + 8px);
            margin-left: -20px;
            padding: 0px 16px;
    `};
    & > div > svg {
        width: 25px;
    }
`;

const CollapseArea = styled.div`
    max-height: 0px;
    transition: 0.25s all ease-in-out;
    overflow: hidden;
    padding: 0px 5px;
    margin-bottom: 5px;
    ${(props) =>
        props.isOpen &&
        css`
            padding: ${props.bodyPadding ? props.bodyPadding : ""};
            max-height: ${props.maxHeight ? props.maxHeight : "500px"};
            margin-bottom: ${props.marginBottom ? props.marginBottom : "0px"};
            overflow-y: ${props.overFlowY ? props.overFlowY : "hidden"};
            height: ${props.height ? props.height : ""};
        `}
`;

const ResultFilter = styled.span`
    color: #e4858c;
    font-size: 13px;
    font-family: "Graphik";
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    padding-top: 3px;
`;

const DivChevron = styled.div`
    height: 100%;
    border: 1px solid #e9e9e9;
    border-left: 0;
    padding: 16.3px 10px;
    cursor: pointer;
`;

