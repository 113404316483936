import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Carousel from "nuka-carousel";
import { useSelector } from "react-redux";
import { generateUUID } from "../../components/Functions";
import api from "../../services/api";
import { ContentDelimiter, Flex, isMobile, isMobileMediaQuery } from "./../../helpers/styles";
import { HeaderHero } from "../Box";
import Header from "./../../components/Header";
import HeaderItem from "../../components/HeaderItem";
import SectionTitle from "../../components/SectionTitle";
import BlogItem from "../../components/BlogItem";
import AboutSection from "../../components/AboutSection";
import Footer from "../../components/Footer";
import Testimonials from "../../components/Testimonials";
import ProductCarroussel from "../../components/ProductCarroussel";
import HeaderCarroussel from "../../components/HeaderCarroussel";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import ProductModal from "../../components/ProductModal";
import boxHero from "../../assets/images/box_hero.png";
import boxHeroMobile from "../../assets/images/box_hero_mobile.jpg";
import BrandsCarroussel from "../../components/BrandsCarrousel";

const Home = () => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const [state, setState] = useState(undefined);
    const [showModal, setShowModal] = useState(false);
    const [previewProduct] = useState(undefined);

    useEffect(() => {
        loadHome();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (state !== undefined) {
            let promotionsBannerDataLayer = [];
            state.carousel_items.map((item, index) =>
                promotionsBannerDataLayer.push({
                    id: item.id + 1,
                    position: index,
                })
            );

            let featuredProductsDataLayer = [];
            state.featured_products.map((item, index) =>
                featuredProductsDataLayer.push({
                    name: item.name,
                    id: item.id,
                    price: item.price_label,
                    brand: item.brand.name,
                    category: item.item_category.name,
                    position: index + 1,
                    list: "Home_featured_products",
                })
            );

            // eslint-disable-next-line
            dataLayer.push({ ecommerce: null });

            // eslint-disable-next-line
            dataLayer.push({
                event: "productImpression",
                ecommerce: {
                    currencyCode: "BRL",
                    impressions: featuredProductsDataLayer,
                },
            });

            // eslint-disable-next-line
            dataLayer.push({ ecommerce: null });

            // eslint-disable-next-line
            dataLayer.push({
                event: "promotionView",
                ecommerce: {
                    promoView: {
                        promotions: promotionsBannerDataLayer,
                    },
                },
            });

            if (window.location.hash && document.querySelector(window.location.hash)) {
                setTimeout(() => {
                    document.querySelector(window.location.hash).scrollIntoView({ behavior: "smooth", block: "start" });
                }, 100);
            }
        }
    }, [state]);

    const loadHome = async () => {
        let response = await api.get("/home");
        let userSessionServerData = window.userCurrentServerData(user);

        if (response.status === 200) {
            setState({ ...response.data });
            let event_id = generateUUID();
            let event_time = Math.round(Date.now() / 1000);

            window.fbq("trackCustom", "HomeView", {}, { eventID: event_id });

            api.post("/facebook_ads_event", {
                data: [
                    {
                        event_name: "HomeView",
                        event_id: event_id,
                        event_time: event_time,
                        event_source_url: window.location.href,
                        action_source: "website",
                        user_data: userSessionServerData,
                        custom_data: {},
                    },
                ],
            })
                .then((res) => console.log("EVENTO HomeView", res.data))
                .catch((res) => res.response);

            // eslint-disable-next-line
            dataLayer.push({ ecommerce: null });
            // eslint-disable-next-line
            dataLayer.push({
                event: "virtualPageview",
                page: document.title,
                type: "Home",
                environment: process.env.REACT_APP_ENV,
                user: [
                    {
                        name: user.first_name && user.last_name ? user.first_name + " " + user.last_name : "",
                        username: user.slug ? user.slug : "",
                        user_id: user.id ? user.id : "",
                        email: user.email ? user.email : "",
                        language: "pt-br",
                        returning: JSON.parse(localStorage.getItem("accepted_cookies")) ? true : false,
                    },
                ],
            });
        } else {
            setState({
                carousel_items: [],
                box_types: [],
                blog_posts: [],
                featured_products: [],
                banner_ong: {},
            });
        }
    };

    return (
        <>
            <Helmet>
                <title>Re Petit – brechó infantil de roupas e acessórios seminovos para bebês e crianças</title>
                <meta
                    property="og:title"
                    content="Re Petit – brechó infantil de roupas e acessórios seminovos
                    para bebês e crianças"
                />
                <meta
                    name="description"
                    content="Roupas e acessórios infantis usados para bebês, crianças e adolescentes. O brechó infantil com as melhores marcas nacionais e internacionais!"
                />
                <meta
                    property="og:description"
                    content="Roupas e acessórios infantis usados para bebês, crianças e adolescentes. O brechó infantil com as melhores marcas nacionais e internacionais!"
                />
                <meta
                    name="keywords"
                    content="brechó, brechó infantil, brechó bebês, brechós, brechós infantis, roupa, bebê, bebê conforto, criança, adolescente, berço, enxoval, usado, segunda mão, novo, seminovo, comprar, vender"
                />
            </Helmet>

            <Header
                navigationItems={[
                    { label: "Compre", action: () => navigate("/categorias/novidades") },
                    { label: "Quero vender", action: () => navigate("/desapegue") },
                    { label: "Peça seu box", action: () => navigate("/box") },
                    { label: "Lojas Influencers", action: () => navigate("/lojas-influencers") },
                    { label: "Blog", action: () => navigate("/blog") },
                    { label: "Outlet", action: () => navigate("/outlet") },
                    { label: "Sale", action: () => navigate("/promocoes") },
                ]}
            />
            {state === undefined ? (
                <Loading />
            ) : (
                <Container>
                    <Flex>
                        <InfoTitle>
                            Brechó infantil online para bebês, crianças e adolescentes. Compre e venda roupas, objetos e
                            brinquedos seminovos.
                        </InfoTitle>
                    </Flex>
                    <ContentDelimiter>
                        <Flex>
                            {state.carousel_items && (
                                <HeaderCarroussel height={isMobile ? 502 : 430} items={state.carousel_items} />
                            )}

                            {state.banner_ong && <HeaderItem data={state.banner_ong} />}
                        </Flex>

                        <ProductCarroussel
                            title={
                                isMobile
                                    ? `em alta na
                                    re petit!`
                                    : `em
                                    alta na
                                    re petit!`
                            }
                            items={state.featured_products || []}
                            link={`/produto/{item.slug}`}
                            locale={"Home_featured_products"}
                        />

                        <SectionTitle>Box Personalizado</SectionTitle>
                        <HeaderHero
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            height={isMobile ? "655px" : "430px"}
                            background={`url(${isMobile ? boxHeroMobile : boxHero}) center / cover no-repeat`}
                        >
                            <Flex direction="column">
                                <h1>Box Re Petit</h1>
                                <hr />
                                <p>
                                    O BOX Re Petit é um serviço exclusivo do nosso brechó online. Diferente de tudo que
                                    você já viu para compras de segunda mão infantil. Você responde ao nosso QUIZ e
                                    baseado no seu perfil, fazemos uma seleção de peças com carinho e enviamos dentro de
                                    um BOX para sua casa! Você prova e fica somente com o que gostar.
                                </p>
                                <Button
                                    margin={isMobile ? "-15px" : "0"}
                                    onClick={() => navigate("/box")}
                                    width={isMobile ? "230px" : "240px"}
                                    value={"Peça seu BOX"}
                                    dark
                                    borderradius={"true"}
                                />
                            </Flex>
                        </HeaderHero>

                        <SectionTitle>Blog</SectionTitle>

                        <Flex
                            direction={isMobile ? "column" : "row"}
                            alignItems="stretch"
                            justifyContent={isMobile ? "center" : "space-between"}
                            alignLast={!isMobile}
                            desktop
                        >
                            {state.blog_posts &&
                                state.blog_posts.map((item, index) => (
                                    <BlogItem
                                        key={`blogPost_${index}`}
                                        category={item.category.title}
                                        title={item.title}
                                        description={item.quote}
                                        author={item.author.name}
                                        background={item.image_url}
                                        link={`/blog/post/${item.slug}`}
                                    />
                                ))}
                        </Flex>

                        <Flex direction={"column"} justifyContent={"center"} padding={"0 20px"} mobile>
                            <Carousel
                                heightMode="current"
                                disableEdgeSwiping
                                defaultControlsConfig={{
                                    prevButtonStyle: { display: "none" },
                                    nextButtonStyle: { display: "none" },
                                    pagingDotsStyle: { display: "none" },
                                }}
                                renderBottomCenterControls={(props) => (
                                    <DotGroup2>
                                        {[...Array(state.blog_posts.length).keys()].map((item, index) => (
                                            <Dot2
                                                onClick={props.goToSlide.bind(null, index)}
                                                key={`slideDot${index}`}
                                                slide={index}
                                                current={props.currentSlide === index ? 1 : 0}
                                            />
                                        ))}
                                    </DotGroup2>
                                )}
                            >
                                {state.blog_posts &&
                                    state.blog_posts.map((item, index) => (
                                        <BlogItem
                                            key={`blogPost_${index}`}
                                            category={item.category.title}
                                            title={item.title}
                                            description={item.quote}
                                            author={item.author.name}
                                            background={item.image_url}
                                            link={`/blog/post/${item.slug}`}
                                            padding="0 0 40px 0"
                                        />
                                    ))}
                            </Carousel>
                        </Flex>

                        <Button
                            width="180px"
                            as={Link}
                            to="/blog"
                            dark
                            borderradius={"true"}
                            value={"Ver Mais"}
                            margin="16px auto"
                        />

                        <BrandsCarroussel
                            title={`Marcas destaques`}
                            items={state.featured_brands || []}
                            locale={"Home_featured_brands"}
                        />
                    </ContentDelimiter>

                    <AboutSection />

                    <Testimonials
                        quote={state.quotes[0].quote}
                        author={state.quotes[0].quote_author}
                        padding="65px 0"
                    />
                </Container>
            )}
            <Footer />

            <ProductModal show={showModal} callback={setShowModal} product_slug={previewProduct} />
        </>
    );
};

const Container = styled.div``;

const InfoTitle = styled.h1`
    font-family: "DMSerifDisplay";
    font-size: 20px;
    font-style: italic;
    line-height: 1.2;
    text-align: center;
    letter-spacing: 1.5px;
    color: #34343b;
    margin: 30px 0;

    ${isMobileMediaQuery} {
        font-size: 16px;
        margin: 25px 10px;
    }
`;

const DotGroup2 = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
`;

const Dot2 = styled.div`
    padding: 0px;
    border: 0;
    background-color: #f37f8a;
    border-radius: 100%;
    height: 11px;
    width: 11px;
    flex-basis: 11px;
    cursor: pointer;

    &:not(:last-child) {
        margin-right: 6px;
    }

    ${(props) => !props.current && `background-color: #e4e4e7;`}
`;

export default Home;
