import React from "react";
import styled, { keyframes } from "styled-components";
import { isMobileMediaQuery } from "./../../helpers/styles";
function InternalLoading() {
    return (
        <LoadingContainer>
            <LoadingBubble />
        </LoadingContainer>
    );
}

const LoadingContainer = styled.div`
    position: absolute;
    display: fixed;
    width: 100vw;
    height: 100%;
    top: 0;
    background: black;
    opacity: 0.2;
    z-index: 20;
    ${isMobileMediaQuery} {
        width: 100vw;
        height: 100%;
    }
`;

const LoadingSpinner = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const LoadingBubble = styled.div`
    position: sticky;
    top: 50%;
    left: 50%;
    z-index: 21;
    height: 32px;
    width: 32px;
    border-radius: 100%;
    border: solid 6px #e4858c;
    border-bottom-color: rgb(228, 133, 140, 0.2);
    animation: ${LoadingSpinner} 2s infinite linear;
    ${isMobileMediaQuery} {
        left: 45%;
    }
`;

export default InternalLoading;
