import React, { forwardRef } from "react";
import styled, { css } from "styled-components";
import LoadingSpinner from "../LoadingSpinner";

const Button = (
    {
        type,
        value,
        children,
        onClick,
        lower,
        dark,
        filled,
        filledwhite,
        borderradius,
        link,
        secondary,
        red,
        pink,
        fontFamily,
        disabled,
        loading,
        ...props
    },
    ref
) => {
    return (
        <Container
            onClick={!loading ? onClick : null}
            dark={dark ? 1 : null}
            pink={pink ? 1 : null}
            fontFamily={fontFamily ? fontFamily : null}
            filled={filled}
            filledwhite={filledwhite ? 1 : null}
            link={link}
            borderradius={borderradius}
            secondary={secondary}
            red={red}
            lower={lower}
            disabled={disabled}
            type={type || "button"}
            ref={ref}
            {...props}
        >
            {loading ? <LoadingSpinner dark={dark && !filled} /> : value || children}
        </Container>
    );
};

const Container = styled.button`
    display: flex;
    order: ${({ order }) => (order ? order : undefined)};
    justify-content: ${(props) => (props.justifyContent ? props.justifyContent : "center")};
    align-items: center;
    max-height: ${({ maxHeight }) => (maxHeight ? maxHeight : "40px")};
    font-family: "SackersGothicStd", sans-serif;
    border-radius: 16px;
    ${(props) =>
        props.fontFamily
            ? css`
                  font-family: ${props.fontFamily}, sans-serif;
              `
            : css`
                  font-family: "SackersGothicStd", sans-serif;
              `};

    background-color: ${(props) => (props.filled ? (props.dark ? "#34343b" : "#fff") : "transparent")};
    color: ${(props) => (props.filled ? (!props.dark ? "#34343b" : "#fff") : props.dark ? "#34343b" : "#fff")};
    border: 1px solid ${(props) => (props.dark ? "#34343b" : "#fff")};

    ${(props) => props.red ? props.filled
                ? css`
                      border: 1px solid #e21012;
                      background-color: #e21012;
                      color: #fff;
                  `
                : css`
                      color: #e21012;
                      background-color: #fff;
                      border: 1px solid #e21012;
                  `
            : ""};

    ${(props) =>
        props.secondary
            ? props.filled
                ? css`
                      border: 1px solid #676777;
                      background-color: #676777;
                      color: #fff;
                  `
                : css`
                      color: #676777;
                      background-color: #fff;
                      border: 1px solid #676777;
                  `
            : ""};
    ${(props) =>
        props.pink
            ? props.filled
                ? css`
                      background-color: #e4858c;
                      color: #000;
                  `
                : css`
                      color: #e4858c;
                      background-color: #fff;
                      border: 1px solid #e4858c;
                  `
            : ""};
    ${(props) =>
        props.filledwhite &&
        css`
            background-color: #e4858c;
            color: #fff;
        `};
    ${(props) =>
        props.light
            ? props.filled
                ? css`
                      background-color: #7ca8af;
                      color: #fff;
                  `
                : css`
                      color: #7ca8af;
                      background-color: #fff;
                      border: 1px solid #e9e9e9;
                  `
            : ""};
    ${(props) => props.borderradius ? `border-radius: ${props.borderradius};` : css`border-radius: 16px;`};

    padding: ${(props) =>
        props.small && !props.padding ? "10px 20px" : props.padding ? props.padding : "15px 1.25rem"};
    margin: ${(props) => (props.margin ? props.margin : "1rem 0")};
    text-transform: ${(props) => (props.lower ? "initial" : "uppercase")};
    text-decoration: none;
    ${(props) => props.fluid && !props.width && "width: 100%;"};
    ${(props) => props.width && `width: ${props.width};`};
    ${(props) => props.height && `height: ${props.height};`};
    ${(props) => props.maxWidth && `max-width: ${props.maxWidth};`};
    ${(props) => props.minWidth && `min-width: ${props.minWidth};`};
    ${(props) =>
        props.link
            ? props.dark
                ? css`
                      border: none;
                      background-color: transparent;
                      color: #34343b;
                      text-decoration: underline;
                  `
                : css`
                      border: none;
                      background-color: transparent;
                      color: #fff;
                      text-decoration: underline;
                  `
            : ""};

    box-sizing: border-box;

    font-size: ${(props) => (props.small ? "11px" : props.bigger ? "16px" : "12px")};
    font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "900")};
    text-align: center;
    cursor: ${(props) => (props.onClick || props.to || props.type === "submit" || props.href ? "pointer" : "inherit")};

    ${(props) =>
        props.disabled &&
        css`
            opacity: 0.45;
            pointer-events: none;
        `};
`;

export default forwardRef(Button);
