import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { useDispatch } from "react-redux";

import api from "../../services/api";
import * as UserActions from "../../redux/actions/user";

import { ContentDelimiter, Flex } from "./../../helpers/styles";

import Header from "./../../components/Header";
import Footer from "./../../components/Footer";
import TextInput from "../../components/Inputs/TextInput";
import Button from "../../components/Button";

import ChevronLeftIcon from "./../../assets/images/seta.svg";
import QuizLoginHero from "../../assets/images/quiz-login-hero.jpeg";
import WhatsappIcon from "../../assets/images/whatsapp.svg";
import EmailIcon from "../../assets/images/email-icon.png";
import AlertModal from "../../components/AlertModal";

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({ email: "", password: "" });
    const [errors, setErrors] = useState({});
    const [modal, setModal] = useState(undefined);

    const handleChange = (e) => {
        let {
            target: { name, value, checked, type },
        } = e;

        if (name !== undefined) {
            if (value !== undefined && type !== "checkbox") {
                setValues({
                    ...values,
                    [name]: value,
                });
            } else if (type === "checkbox") {
                setValues({
                    ...values,
                    [name]: checked,
                });
            }
        }
    };

    const handleLogin = async (e) => {
        e && e.preventDefault();

        let { email, password } = values;

        if (!email || !password) {
            setErrors({
                ...errors,
                email: !email,
                password: !password,
            });

            return;
        }

        setErrors({});

        setLoading(true);

        let loginResponse = await api
            .post("/auth/login", {
                email,
                password,
            })
            .catch((res) => res.response);

        setLoading(false);

        if (loginResponse.status === 200) {
            dispatch(
                UserActions.login({
                    token: loginResponse.headers.token,
                    ...loginResponse.data,
                })
            );
            dispatch(UserActions.refreshProfile());
            navigate("/");
        } else {
            console.error(loginResponse);

            if (
                loginResponse.status === 401 &&
                loginResponse.data &&
                loginResponse.data.message === "Invalid credentials"
            ) {
                setModal({
                    show: true,
                    message: "O e-mail ou senha digitados estão incorretos, por favor verifique e tente novamente!",
                });
            } else {
                setModal({
                    show: true,
                    message: "Erro inesperado. Aguarde um instante e tente outra vez!",
                });
            }
        }
    };

    return (
        <>
            <Header
                hideLinks
                subNavigation={
                    <Flex flex="1" maxWidth="911px" margin="auto" justifyContent="flex-start">
                        <Text margin="0" onClick={() => navigate(-1)}>
                            <ChevronLeft />
                            Voltar
                        </Text>
                    </Flex>
                }
            />
            <Container>
                <ContentDelimiter>
                    <PinkButton />
                    <Flex margin="0 0 auto auto" maxWidth="440px" direction="column" alignItems="flex-start">
                        <Title>Leve o Box da re petit para circular na sua casa</Title>

                        <Text>Muito estilo, menos gastos e mais tempo para se divertir com o seu filho.</Text>

                        <LoginCard as="form" onSubmit={handleLogin}>
                            <h2>Login</h2>
                            <p>Acesse sua conta para iniciar o quiz ou crie sua conta de forma simples e rápida.</p>

                            <TextInput
                                margin="0 0 20px 0"
                                placeholder="Usuário"
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                                disabled={loading}
                                error={errors.email}
                            />
                            <TextInput
                                margin="0 0 20px 0"
                                type="password"
                                placeholder="Senha"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                disabled={loading}
                                error={errors.password}
                            />

                            <Link to="/esqueci-senha">Esqueci minha senha</Link> 

                            <Flex>
                                <Button
                                    margin="0 auto 0 0"
                                    dark
                                    filled
                                    value="Entrar"
                                    width="90px"
                                    type="submit"
                                    disabled={loading}
                                />
                                <Button
                                    margin="0 0 0 auto"
                                    dark
                                    value="Ainda não sou cadastrado"
                                    flex="1"
                                    onClick={() => navigate("/cadastro")}
                                    disabled={loading}
                                />
                            </Flex>
                        </LoginCard>

                        <Text>Está com dúvidas? Envie sua mensagem pra gente!</Text>

                        <Flex margin="0px 0px 70px 0px">
                            <Flex margin="0 20px 0 0">
                                <WhiteWhatsappIcon />
                                <Text margin="0">(11) 95376-4930</Text>
                            </Flex>
                            <Flex>
                                <IconImage src={EmailIcon} />
                                <Text margin="0">vendas@repetit.com.br</Text>
                            </Flex>
                        </Flex>

                        <Text>
                            Fique de olho: o serviço de BOX está disponível apenas para a cidade de São Paulo e cidades
                            pontuais da Grande São Paulo (Santo André, São Bernardo do Campo, São Caetano, Carapicuíba,
                            Diadema, Mauá, Barueri, Osasco e Guarulhos).
                        </Text>
                    </Flex>
                </ContentDelimiter>
            </Container>

            <Footer mini />

            {modal && (
                <AlertModal
                    show={modal.show}
                    title={modal.title}
                    message={modal.message}
                    callback={() => setModal({ ...modal, show: false })}
                />
            )}
        </>
    );
};

const Container = styled.div`
    background-image: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.8) 85%, black 100%), url(${QuizLoginHero});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex: 1;
    padding: 108px 0px 37px 0px;
`;

const PinkButton = styled.button`
    background-color: #e4858c;
    width: 109px;
    height: 109px;
    position: absolute;
    top: -35px;
    left: -95px;
    border-radius: 100%;
    border: 0px;

    @media screen and (max-width: 1450px) {
        left: 0px;
    }
`;

const Title = styled.h1`
    text-transform: uppercase;
    margin: 0px 0px 28px 0px;

    font-family: "SackersGothicStd";
    font-size: 18px;
    font-weight: 900;
    line-height: 1.33;
    letter-spacing: 3.6px;
    color: #e4858c;
`;

const Text = styled.p`
    margin: 0px 0px 28px 0px;

    &,
    a {
        font-size: 15px;
        line-height: 1.4;
        display: flex;
        align-items: center;
        color: #fefeff;
    }

    &:nth-of-type(2) {
        margin-bottom: 10px;
    }

    ${(props) => props.margin && `margin: ${props.margin};`}

    ${(props) =>
        props.onClick &&
        css`
            text-decoration: underline;
            cursor: pointer;
        `}
`;

const LoginCard = styled.div`
    margin-bottom: 28px;
    background-color: #fff;
    padding: 23px 30px 30px 30px;
    box-shadow: 0px 1px 3px 0 rgba(52, 52, 59, 0.1);

    h2 {
        text-transform: uppercase;
        margin-bottom: 53px;
        font-family: "SackersGothicStd";
        font-size: 11px;
        font-weight: 900;
        line-height: 1.45;
        letter-spacing: 2.2px;
        color: #34343b;
    }

    p {
        margin-bottom: 48px;
        font-size: 15px;
        line-height: 1.4;
        color: #34343b;
    }

    a {
        display: block;
        margin-bottom: 20px;
        font-size: 15px;
        line-height: 1.4;
        color: #676777;
    }
`;

const IconImage = styled.img`
    width: 28px;
    height: 28px;
    margin-right: 8px;
`;

const ChevronLeft = styled.span`
    display: inline-block;
    background-color: #fff;
    mask-size: cover;
    mask-position: center;
    mask-image: url(${ChevronLeftIcon});
    object-fit: contain;
    height: 14px;
    width: 8px;
    margin-right: 9px;
`;

const WhiteWhatsappIcon = styled(IconImage)`
    background-color: #fff;
    mask-size: cover;
    mask-position: center;
    mask-image: url(${WhatsappIcon});
`;

export default Login;
