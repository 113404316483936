import React, { useEffect, useState, useRef } from "react";
import styled, { css } from "styled-components";

import { Flex } from "../../helpers/styles";

import { ReactComponent as ChevronDownIcon } from "../../assets/images/seta-faq.svg";

const QuestionContainer = ({ title, value, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [contentHeight, setContentHeight] = useState(undefined);
    const contentContainerRef = useRef();

    useEffect(() => {
        if (window.location.hash && window.location.hash === `#${props.id}`) {
            setIsOpen(true);
        }

        // eslint-disable-next-line
    }, [window.location.pathname, window.location.hash]);

    useEffect(() => {
        if (value !== isOpen)
            setIsOpen(value || window.location.hash === `#${props.id}`);

        // eslint-disable-next-line
    }, [value]);

    useEffect(() => {
        if (
            contentContainerRef.current &&
            contentContainerRef.current.childNodes.length === 1
        ) {
            const contentElement = contentContainerRef.current.childNodes[0];
            const contentHeight =
                contentElement.getBoundingClientRect &&
                contentElement.getBoundingClientRect().height;

            setContentHeight(contentHeight);
        } else {
            setContentHeight(undefined);
        }

        // eslint-disable-next-line
    }, [contentContainerRef]);

    const handleClick = () => {
        props.onChange && props.onChange(!isOpen);
        setIsOpen(!isOpen);
    };

    return (
        <Container id={props.id} isOpen={isOpen}>
            <Flex
                alignItems="center"
                justifyContent="space-between"
                onClick={handleClick}
                margin="0 auto"
                padding="18px 20px 17px 36px"
            >
                <Title>{title}</Title>
                <ChevronButton>
                    <ChevronDownIcon />
                </ChevronButton>
            </Flex>
            <CollapseArea
                ref={contentContainerRef}
                maxHeight={props.maxHeight}
                contentHeight={contentHeight}
                isOpen={isOpen}
            >
                {props.children}
            </CollapseArea>
        </Container>
    );
};

const Container = styled.div`
    ${({ width }) => `width: ${width || "100%"};`};
    background-color: #f7f9f9;
    box-shadow: 0px 1px 2px 0 rgba(52, 52, 59, 0.15);
    margin-bottom: 10px;

    & > ${Flex} {
        border-bottom: 1px solid #e3efef;
    }

    ${(props) =>
        props.isOpen &&
        css`
            & > ${Flex} > div > svg:first-of-type {
                transform: translate(-50%, -50%) scaleY(-1);
            }
        `};

    & li:not(:last-of-type) {
        margin-bottom: 16px;
    }
`;

const Title = styled.h1`
    margin: 0px;
    text-transform: uppercase;
    max-width: calc(100% - 50px);

    font-family: "Graphik";
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;
`;

const CollapseArea = styled.div`
    max-height: 0px;
    transition: 0.25s all ease-in-out;

    overflow: hidden;
    padding: 0px 22px;

    ${(props) =>
        props.isOpen &&
        css`
            padding: 26px 48px 28px 36px;
            max-height: ${props.maxHeight ? props.maxHeight : "200vh"};
            ${({ contentHeight }) =>
                contentHeight &&
                css`
                    max-height: ${contentHeight + 150}px !important;
                `}
        `}
`;

const ChevronButton = styled.div`
    width: 40px;
    height: 40px;
    background-color: #e4eeee;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

export default QuestionContainer;
