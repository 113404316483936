import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import api from "../../services/api";
import { generateUUID } from "../../components/Functions";
import * as CartActions from "../../redux/actions/cart";
import { openPrivacyNTerms } from "../../helpers";
import { Flex, isMobile } from "./../../helpers/styles";
import Button from "../../components/Button";
import ButtonLink from "../../components/ButtonLink";
import HorizontalProductItem from "../../components/HorizontalProductItem";
import Footer from "./../../components/Footer";
import Header from "./../../components/Header";
import InfoIcon from "../../assets/images/info-icon.png";
import ChevronLeftIcon from "./../../assets/images/seta.svg";

const Cart = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id_cart } = useParams();
    const [delivery] = useState(undefined);
    const user = useSelector((state) => state.user);
    const [eligible, setEligible] = useState();
    const [enabled, setEnabled] = useState();
    const [missingAmount, setMissingAmount] = useState("0");
    const [progress, setProgress] = useState("0");
    const [hasProductWithFalseShipping, setHasProductWithFalseShipping] = useState(false);
    const selectorProducts = useSelector((state) => state.cart.products) ?? [];

    useEffect(() => {
        checkFreeShipping(selectorProducts.map((product) => product.id));
    }, [selectorProducts]);

    useEffect(() => {
        if (id_cart) {
            recoverAbandonedCart(id_cart);
        } else if (selectorProducts.length === 0 && user.token) {
            currentAbandonedCart();
        }
    }, []);

    const currentAbandonedCart = async () => {
        try {
            let response = await api.get(`/carts/current/items`);
            if (response.status === 200) {
                response.data.forEach((product) => {
                    dispatch(CartActions.addProduct(product));
                });
            } else {
                console.log("Erro: /carts/current/items");
            }
        } catch (error) {
            console.error("error:", error);
        }
    };

    const recoverAbandonedCart = async (id_cart) => {
        try {
            let response = await api.get(`/carts/${id_cart}/items`);
            if (response.status === 200) {
                dispatch(CartActions.clear());
                response.data.forEach((product) => {
                    dispatch(CartActions.addProduct(product));
                });
            } else {
                console.log(`Erro em: /carts/${id_cart}/items`);
            }
        } catch (error) {
            console.error("error:", error);
        }
    };

    const ProgressBar = ({ duration }) => {
        useEffect(() => {
            const timer = setInterval(() => {
                setProgress((prevWidth) => {
                    if (prevWidth >= 100) {
                        clearInterval(timer);
                        return 100;
                    }
                    return prevWidth + 100 / duration;
                });
            }, 1000);

            return () => {
                clearInterval(timer);
            };
        }, [duration]);
    };

    const checkFreeShipping = async (idsProducts) => {
        let response = await api.post("/check_free_shipping", {
            items: idsProducts,
            state: user.user_addresses && user.user_addresses.length > 0 ? user.user_addresses[0].state : "",
            city: user.user_addresses && user.user_addresses.length > 0 ? user.user_addresses[0].city : "",
        });

        if (response.status === 200) {
            setEligible(response.data.eligible);
            setEnabled(response.data.enabled);
            setMissingAmount(
                response.data?.missing_amount?.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })
            );
            setProgress(response.data.progress);
        } else {
            console.log("erro");
        }
        setHasProductWithFalseShipping(
            selectorProducts?.some((product) => product.eligible_for_free_shipping === false)
        );
    };

    // eslint-disable-next-line
    dataLayer.push({ ecommerce: null });
    // eslint-disable-next-line
    dataLayer.push({
        event: "virtualPageview",
        page: document.title,
        type: "Carrinho",
        environment: process.env.REACT_APP_ENV,
        user: [
            {
                name: user.first_name && user.last_name ? user.first_name + " " + user.last_name : "",
                username: user.slug ? user.slug : "",
                user_id: user.id ? user.id : "",
                email: user.email ? user.email : "",
                language: "pt-br",
                returning: JSON.parse(localStorage.getItem("accepted_cookies")) ? true : false,
            },
        ],
    });

    const handleRemove = (item) => {
        // eslint-disable-next-line
        dataLayer.push({ ecommerce: null });
        // eslint-disable-next-line
        dataLayer.push({
            event: "removeFromCart",
            ecommerce: {
                remove: {
                    products: [
                        {
                            name: item.name,
                            id: item.id,
                            price: item.price_label,
                            brand: item.brand.name,
                            category: item.item_category.name,
                            quantity: 1,
                        },
                    ],
                },
            },
        });
        dispatch(CartActions.removeProduct(item.id));
        if (user.token) {
            const productsTemp = selectorProducts.filter((product) => product.id !== item.id);
            saveAbandonedCart(productsTemp.map((product) => product.id));
        }
    };

    const saveAbandonedCart = async (idsProducts) => {
        try {
            let response = await api.post("/carts", {
                item_ids: idsProducts,
            });
            if (response.status === 204) {
                console.log("saveAbandonedCart: /carts");
            }
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    const eventFace = async () => {
        let contentIds = [];
        let numItens = 0;
        let valueProds = 0;
        selectorProducts.forEach((prod) => {
            contentIds.push(prod.id);
            valueProds = +prod.price_label;
            numItens++;
        });
        let userSessionServerData = window.userCurrentServerData(user);
        let event_id = generateUUID();
        let event_time = Math.round(Date.now() / 1000);

        window.fbq(
            "track",
            "InitiateCheckout",
            {
                content_ids: contentIds,
                currency: "BRL",
                num_items: numItens,
                value: valueProds,
            },
            { eventID: event_id }
        );

        let facebookEvent = await api
            .post("/facebook_ads_event", {
                data: [
                    {
                        event_name: "InitiateCheckout",
                        event_id: event_id,
                        event_time: event_time,
                        event_source_url: window.location.href,
                        action_source: "website",
                        user_data: userSessionServerData,
                        custom_data: {
                            content_ids: contentIds,
                            currency: "BRL",
                            num_items: numItens,
                            value: valueProds,
                        },
                    },
                ],
            })
            .then((res) => console.log("Evento InitiateCheckout", res.data))
            .catch((res) => res.response);

        navigate("/checkout");
    };

    return (
        <>
            <Header
                hideLinks
                subNavigation={
                    <Flex flex="1" maxWidth="1222px" margin="auto" justifyContent="flex-start">
                        <Link to="/">
                            <ChevronLeft />
                            Voltar para home
                        </Link>

                        <StepsTitle active>
                            Minha Sacola ({selectorProducts ? selectorProducts?.length : 0} Ite
                            {selectorProducts?.length === 1 ? "m" : "ns"})
                        </StepsTitle>
                    </Flex>
                }
            />

            {selectorProducts.length === 0 ? (
                <Container>
                    <Flex
                        flex="1"
                        width={isMobile ? "100%" : "1222px"}
                        alignItems="center"
                        justifyContent="center"
                        direction="column"
                        margin="30px auto 54px auto"
                        text
                    >
                        <ResumeTitle> Seu carrinho está vazio!</ResumeTitle>
                        <Button
                            pink
                            filled
                            borderradius
                            value="Voltar para o Início"
                            margin="0"
                            onClick={() => navigate("/")}
                        />
                    </Flex>
                </Container>
            ) : (
                <Container>
                    <Flex
                        flex="1"
                        width={isMobile ? "100%" : "1222px"}
                        alignItems="flex-start"
                        direction="column"
                        margin="30px auto 54px auto"
                    >
                        {delivery && delivery.price === "free" && (
                            <FreeShippingRow>
                                <img src={InfoIcon} alt="Info" />A sua compra vem com <strong>frete grátis</strong>.
                            </FreeShippingRow>
                        )}

                        <Flex
                            width="100%"
                            height="100%"
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            direction={isMobile ? "column" : "row"}
                        >
                            <Flex
                                flex="1"
                                width={isMobile ? "100%" : null}
                                height="100%"
                                alignItems="flex-start"
                                justifyContent="flex-start"
                                direction="column"
                                margin={isMobile ? "45px 0 35px 0" : "0px 35px 20px auto"}
                                padding={isMobile ? "0 20px" : null}
                                boxSizing="border-box"
                            >
                                <Flex width="100%" justifyContent="flex-start" direction="column" warp="nowrap">
                                    {selectorProducts.map((item, index) => (
                                        <HorizontalProductItem
                                            key={index}
                                            item={item}
                                            cart
                                            hideSpecs
                                            handleRemove={handleRemove}
                                            enabledFreeShipping={enabled}
                                        />
                                    ))}
                                </Flex>
                            </Flex>

                            <Flex
                                alignItems="flex-start"
                                justifyContent="flex-start"
                                direction="column"
                                width={isMobile ? "100%" : "420px"}
                            >
                                <ProgressCard>
                                    {eligible && parseInt(missingAmount) > 0 && (
                                        <>
                                            <Text>
                                                Com mais R$ {missingAmount} você ganha <b>Frete Grátis</b>
                                            </Text>
                                            <ProgressContainer>
                                                <ProgressBarInner
                                                    style={{
                                                        width: `${progress}%`,
                                                    }}
                                                />
                                            </ProgressContainer>
                                        </>
                                    )}

                                    {eligible && parseInt(missingAmount) <= 0 && (
                                        <>
                                            <Text>
                                                Parabéns, você recebeu <b>FRETE GRÁTIS!</b>
                                                {hasProductWithFalseShipping && (
                                                    <>
                                                        <br />
                                                        Porém há produtos no seu carrinho que não são aplicáveis ao{" "}
                                                        <b>Frete Grátis</b>.
                                                    </>
                                                )}
                                            </Text>
                                            <ProgressContainer>
                                                <ProgressBarInner style={{ width: `100%` }} />
                                            </ProgressContainer>
                                        </>
                                    )}
                                </ProgressCard>

                                <ResumeCard>
                                    <ResumeTitle>RESUMO DO PEDIDO</ResumeTitle>

                                    <PriceInfo>
                                        <span>subtotal</span>
                                        <span>
                                            R${" "}
                                            {selectorProducts
                                                .reduce((acc, curr) => {
                                                    return acc + parseFloat(curr.price_label || 0);
                                                }, 0)
                                                .toLocaleString("pt-br", {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })}
                                        </span>
                                    </PriceInfo>

                                    {delivery && delivery.price === "free" && (
                                        <ShippingRow>
                                            <span>Frete</span>
                                            <span>Grátis</span>
                                        </ShippingRow>
                                    )}

                                    <Flex
                                        width="100%"
                                        padding={isMobile ? "20px 30px" : "20px 0"}
                                        background="#fff"
                                        boxSizing="border-box"
                                    >
                                        <Flex width={isMobile ? "100%" : "360px"}>
                                            <Button
                                                disabled={!selectorProducts || selectorProducts?.length === 0}
                                                width="100%"
                                                margin="0 0 10px 0"
                                                dark
                                                filled
                                                borderradius
                                                value="FECHAR PEDIDO"
                                                onClick={() => eventFace()}
                                            />

                                            <Button
                                                secondary
                                                filled
                                                fluid
                                                borderradius
                                                value="Continuar Comprando"
                                                margin="0"
                                                onClick={() => navigate(-1)}
                                            />
                                        </Flex>
                                    </Flex>
                                </ResumeCard>

                                <AgreementText>
                                    Ao clicar em "Finalizar" você afirma que aceita os{" "}
                                    <ButtonLink onClick={() => openPrivacyNTerms("terms")}>
                                        Termos e condições
                                    </ButtonLink>{" "}
                                    e nossa{" "}
                                    <ButtonLink onClick={() => openPrivacyNTerms("privacy")}>
                                        Política de privacidade
                                    </ButtonLink>{" "}
                                </AgreementText>
                            </Flex>
                        </Flex>
                    </Flex>
                </Container>
            )}

            <Footer />
        </>
    );
};

const Container = styled.div`
    flex: 1;
`;

const Link = styled(RouterLink)`
    font-size: 15px;
    line-height: 1.4;
    display: flex;
    align-items: center;
    color: #fefeff;
`;

const ChevronLeft = styled.span`
    display: inline-block;
    background-color: #fff;
    mask-size: cover;
    mask-position: center;
    mask-image: url(${ChevronLeftIcon});
    object-fit: contain;
    height: 14px;
    width: 8px;
    margin-right: 9px;
    margin-left: 18px;
`;

const StepsTitle = styled.p`
    margin: auto 50px auto 0px;
    text-transform: uppercase;

    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.82;
    letter-spacing: 2.2px;
    text-align: left;
    color: #666679;

    &:nth-child(2) {
        margin-left: 60px;
    }

    ${(props) => props.active && `color: #fff;`}
`;

const ResumeCard = styled(Flex)`
    flex-direction: column;
    flex: 1;
    width: 100%;
    box-shadow: 0px 1px 3px 0 rgba(52, 52, 59, 0.1);
    background-color: #f7f9f9;
    margin-bottom: 30px;
`;

const ResumeTitle = styled.div`
    padding: 26px 0px 23px 0px;
    width: 100%;
    text-transform: uppercase;
    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    line-height: 1.45;
    letter-spacing: 2.2px;
    text-align: center;
    color: #34343b;
    border-bottom: 1px solid #fff;
`;

const ShippingRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 30px 25px 30px;
    width: 100%;
    box-sizing: border-box;

    span {
        font-family: "Graphik";
        font-size: 15px;
        font-weight: 500;
        line-height: 1.6;
    }

    span:first-child {
        text-transform: uppercase;
        font-weight: 600;
        text-align: left;
        color: #34343b;
    }

    span:last-child {
        text-align: right;
        color: #79c992;
    }
`;

const PriceInfo = styled(Flex)`
    width: 100%;
    background-color: #f7f9f9;
    justify-content: space-between;
    text-transform: uppercase;
    padding: 23px 30px;
    box-sizing: border-box;

    font-family: "Graphik";
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    :not(:last-of-type) {
        border-bottom: 1px solid #fff;
    }
    :last-of-type {
        margin-bottom: 20px;
    }
`;

const FreeShippingRow = styled(Flex)`
    margin-bottom: 37px;
    font-family: "Graphik";
    font-size: 15px;
    line-height: 2.67;
    letter-spacing: 0.3px;
    text-align: left;
    color: #79c992;

    img {
        margin-right: 9px;
    }

    strong {
        margin-left: 6px;
        text-transform: uppercase;
        font-weight: 600;
    }
`;

const AgreementText = styled.span`
    font-family: "Graphik";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;
    padding: 0 20px;
    a {
        color: #34343b;
    }
`;

const ProgressCard = styled.div`
    width: 95%;
    padding: 0px 10px 10px 10px;
`;

const ProgressContainer = styled.div`
    width: 100%;
    height: 8px;
    background-color: #f0f0f0;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #e4858c;
    padding: 1px;
    margin-top: 5px;
`;

const ProgressBarInner = styled.div`
    height: 100%;
    background-color: #e4858c;
    transition: width 0.5s ease-in-out;
    border-radius: 5px;
`;

const Text = styled.p`
    margin-bottom: 4px;
    font-family: "Graphik";
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;
`;

export default Cart;
