import React from "react";
import styled from "styled-components";

const ExampleProductItem = ({ image, title, description, ...props }) => {
    return (
        <Container>
            <Image src={image} />
            <hr />
            <Title>{title}</Title>
            <Description>{description}</Description>
        </Container>
    );
};

const Container = styled.div`
    width: 384px;
    height: 530px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f9fafa;
    box-shadow: 0px 1px 2px 0px rgba(52, 52, 59, 0.15);

    &:not(:last-child) {
        margin-right: 32px;
    }

    hr {
        width: 146px;
        border: 0;
        border-bottom: 1px solid #313139;
        margin-bottom: 32px;
    }
`;

const Image = styled.div`
    background-image: url(${(props) => props.src});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 95%;
    height: 294px;
    margin: 32px 0;
`;

const Title = styled.span`
    margin-bottom: 27px;
    font-family: "DMSerifDisplay";
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #34343b;
`;

const Description = styled.span`
    font-family: "Graphik";
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: center;
    color: #676777;
    white-space: pre-wrap;
    margin-bottom: 53px;

    strong {
        font-weight: 600 !important;
    }
`;

export default ExampleProductItem;
