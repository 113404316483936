import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink, useLocation, useParams, useNavigate } from "react-router-dom";
import { generateUUID } from "../../components/Functions";
import styled from "styled-components";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import * as UserActions from "../../redux/actions/user";
import api, { baseURL } from "../../services/api";
import { Flex, isMobile, isMobileMediaQuery } from "./../../helpers/styles";
import { validatePassword } from "../../helpers";
import Header from "./../../components/Header";
import Footer from "./../../components/Footer";
import Button from "../../components/Button";
import BoxSuccessModal from "../../components/BoxSuccessModal";
import ChevronLeftIcon from "./../../assets/images/seta.svg";
import StepHero1 from "../../assets/images/quiz-hero-1.jpg";
import StepHero1Mobile from "../../assets/images/quiz-hero-1-mobile.jpg";
import InfoContent from "./InfoContent";
import BoxContent from "./BoxContent";
import AssemblyContent from "./AssemblyContent";
import ReviewContent from "./ReviewContent";
import BoxCancelled from "./BoxCancelled";
import AlertModal from "../../components/AlertModal";

const QuizLogin = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { current_step } = useParams();
    const currentStep = current_step;
    const user = useSelector((state) => state.user);
    const main_address = user.user_addresses && user.user_addresses[0];
    const steps = {
        informacoes: 0,
        box: 1,
        montagem: 2,
        conferencia: 3,
    };
    const currentStepIndex = steps[currentStep || 0];
    const [resultState, setResultState] = useState("");
    const [modal, setModal] = useState(undefined);
    const [sendingRequest, setSendingRequest] = useState(false);
    const [sendingSignupRequest, setSendingSignupRequest] = useState(false);
    const [values, setValues] = useState({
        unliked_colors: [],
    });
    const [errors, setErrors] = useState({});
    const [started, setStarted] = useState(false);
    const [colors, setColors] = useState(undefined);
    const [boxTypes, setBoxTypes] = useState(undefined);
    const [delivery, setDelivery] = useState(undefined);

    useEffect(() => {
        loadColors();
        loadTypes();

        if (localStorage.getItem("quiz_values")) {
            try {
                let savedValues = JSON.parse(localStorage.getItem("quiz_values"));

                if (savedValues.expires_at && moment() > moment(savedValues.expires_at)) {
                    savedValues = undefined;
                    localStorage.removeItem("quiz_values");
                }

                if (
                    savedValues.values &&
                    savedValues.currentStep &&
                    Object.keys(steps).includes(savedValues.currentStep)
                ) {
                    setStarted(true);
                    setValues((prevState) => {
                        return {
                            ...prevState,
                            ...savedValues.values,
                        };
                    });
                }
            } catch (error) {
                loadUserValues();
            }
        } else {
            loadUserValues();
        }

        if (currentStepIndex === 0) {
            setStarted(true);
        }

        if (!currentStep || (currentStepIndex > 0 && !started)) {
            navigate(`/quiz/${Object.keys(steps)[0]}${location.pathname.indexOf("/sucesso") > 0 ? "/sucesso" : ""}`);
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (localStorage.getItem("quiz_values")) {
            try {
                let savedValues = JSON.parse(localStorage.getItem("quiz_values"));

                if (
                    savedValues.values &&
                    savedValues.currentStep &&
                    Object.keys(steps).includes(savedValues.currentStep)
                ) {
                    navigate(
                        `/quiz/${savedValues.currentStep}${location.pathname.indexOf("/sucesso") > 0 ? "/sucesso" : ""}`
                    );
                }
            } catch (error) {}
        }
        // eslint-disable-next-line
    }, [started]);

    useEffect(() => {
        if (currentStepIndex > 0 || Object.keys(values).length > 1) {
            let toSaveValues = JSON.parse(JSON.stringify({ ...values }));

            delete toSaveValues.accept_terms;

            localStorage.setItem(
                "quiz_values",
                JSON.stringify({
                    values: toSaveValues,
                    currentStep,
                })
            );
        }

        // eslint-disable-next-line
    }, [values]);

    useEffect(() => {
        calculateDelivery(values.zip_code);

        // eslint-disable-next-line
    }, [values.zip_code]);

    useEffect(() => {
        if (resultState === "success" && !(location.pathname.indexOf("/sucesso") > 0)) {
            // eslint-disable-next-line
            dataLayer.push({ ecommerce: null });
            // eslint-disable-next-line
            dataLayer.push({ event: "lead-peça-seu-box" });
            navigate(location.pathname.replace("/sucesso", ""));
        }

        // eslint-disable-next-line
    }, [resultState]);

    useEffect(() => {
        if (location.pathname.indexOf("/sucesso") > 0 && resultState !== "success") {
            navigate(location.pathname.replace("/sucesso", ""));
        }

        // eslint-disable-next-line
    }, [location.pathname]);

    const loadUserValues = () => {
        setValues((prevState) => {
            return {
                ...prevState,
                ...user,
                birth_date: moment(user.birth_date, "YYYY-MM-DD").format("DD/MM/YYYY"),
                ...{
                    phone_number: `${user.phone_code_area}${user.phone_number}`,
                },

                ...(main_address
                    ? {
                          ...main_address,
                          zip_code: main_address.zip,
                          house_number: main_address.number,
                      }
                    : {}),

                billing_address: "same_address",
                customer_children: user.customer_children || [],
                user_addresses: user.user_addresses || [],
                user_bank_accounts: user.user_bank_accounts || [],
            };
        });
    };

    const calculateDelivery = async (zip_code) => {
        if (!zip_code || zip_code.replace(/_/g, "").replace(/-/g, "").length < 8) {
            return;
        }

        let delivery_response = await api
            .post(
                "/delivery/box_calculator",
                {
                    zip_code: zip_code && zip_code.replace(/\D+/g, ""),
                },
                {
                    baseURL: baseURL.replace("v1", "v2"),
                }
            )
            .catch((err) => err.response);

        if (delivery_response.status === 200) {
            setDelivery(delivery_response.data);
        } else if (delivery_response.data && delivery_response.data.code === "not_available") {
            setModal({
                show: true,
                message:
                    delivery_response.data && delivery_response.data.code === "not_available"
                        ? "Sentimos muito, o BOX ainda não está disponível para sua região, aguarde ou tente um novo CEP."
                        : delivery_response.data.message
                        ? delivery_response.data.message
                        : "Erro inesperado. Aguarde um instante e tente outra vez!",
            });
        }
    };

    const loadTypes = async () => {
        let response = await api.get("/box_types?clear_cache=true");

        if (response.status === 200) {
            setBoxTypes(response.data || []);
        } else {
            setBoxTypes([]);
        }
    };

    const loadColors = async () => {
        const colors_response = await api.get("/colors").catch((err) => err.response);

        if (colors_response.status === 200) {
            setColors(colors_response.data);
        }
    };

    const many = [
        "top_needs",
        "bottom_needs",
        "essential_pieces",
        "shoe_needs",
        "accessory_needs",
        "brands",
        "unliked_fabrics",
        "how_did_you_meet_repetit",
    ];

    const isChecked = (type, expected) => {
        if (many.includes(type)) {
            return values[type] && values[type].findIndex((item) => item === expected) >= 0;
        } else {
            return values[type] !== undefined ? values[type] === expected : undefined;
        }
    };

    const handleSelectItem = (type, value, checked) => {
        if (many.includes(type)) {
            let newValues = { ...values };

            if (type === "how_did_you_meet_repetit" && values[type] && typeof values[type] !== "object") {
                values[type] = [];
            }

            if (checked === true) {
                newValues[type] = [...(values[type] || []), value];
            } else {
                newValues[type] = (values[type] || []).filter((item) => item !== value);
            }

            setValues(newValues);
        } else {
            checked === true ? setValues({ ...values, [type]: value }) : setValues({ ...values, [type]: undefined });
        }
    };

    const handleChange = async (e) => {
        let {
            target: { name, value, checked, type },
        } = e;

        let newValues = { ...values };

        if (name !== undefined) {
            if (value !== undefined && (type !== "checkbox" || type === "radio")) {
                newValues = {
                    ...newValues,
                    [name]: value,
                };
            } else if (checked !== undefined) {
                newValues = {
                    ...newValues,
                    [name]: checked,
                };
            }
        }

        setValues((prevState) => {
            return { ...prevState, ...newValues };
        });

        canChangeStep(false, true, newValues);
    };

    const renderCurrentStep = () => {
        if (resultState === "cancelled") {
            return <BoxCancelled />;
        }

        switch (currentStep) {
            case "informacoes":
                return (
                    <InfoContent
                        values={values}
                        setValues={setValues}
                        errors={errors}
                        setErrors={setErrors}
                        handleChange={handleChange}
                        isChecked={isChecked}
                        handleSelectItem={handleSelectItem}
                        delivery={delivery}
                    />
                );
            case "box":
                return (
                    <BoxContent
                        values={values}
                        setValues={setValues}
                        errors={errors}
                        setErrors={setErrors}
                        handleChange={handleChange}
                        isChecked={isChecked}
                        handleSelectItem={handleSelectItem}
                        boxTypes={boxTypes}
                    />
                );
            case "montagem":
                return (
                    <AssemblyContent
                        values={values}
                        setValues={setValues}
                        errors={errors}
                        setErrors={setErrors}
                        handleChange={handleChange}
                        isChecked={isChecked}
                        handleSelectItem={handleSelectItem}
                        colors={colors}
                        delivery={delivery}
                    />
                );
            case "conferencia":
                return (
                    <ReviewContent
                        values={values}
                        setValues={setValues}
                        errors={errors}
                        setErrors={setErrors}
                        handleChange={handleChange}
                        isChecked={isChecked}
                        handleSelectItem={handleSelectItem}
                        steps={steps}
                        colors={colors}
                        boxTypes={boxTypes}
                        delivery={delivery}
                    />
                );
            default:
                return null;
        }
    };

    const renderStepHero = () => {
        let heros = [
            isMobile ? StepHero1Mobile : StepHero1,
            isMobile ? StepHero1Mobile : StepHero1,
            isMobile ? StepHero1Mobile : StepHero1,
            isMobile ? StepHero1Mobile : StepHero1,
        ];

        return heros[currentStepIndex || 0];
    };

    const renderHeaderTitle = () => {
        switch (currentStep) {
            case "informacoes":
                return "As peças que são a cara do seu filho em um box na sua casa!";
            case "box":
                return "As peças que são a cara do seu filho em um box na sua casa!";
            case "montagem":
                return "As peças que são a cara do seu filho em um box na sua casa!";
            case "conferencia":
                return "As peças que são a cara do seu filho em um box na sua casa!";
            default:
                return null;
        }
    };

    const canChangeStep = (scroll = false, ignoreUndefined = false, checkValues = values, checkDelivery = false) => {
        let canChange = true;

        let newErrors = { ...errors };

        switch (currentStepIndex + 1) {
            case 1:
                const firstStepRequireds = [
                    "email",
                    "password",
                    "confirm_password",
                    "first_name",
                    "last_name",
                    "phone_number",
                    "cpf",

                    "child_name",
                    "child_birthday",
                    "child_gender",

                    "street",
                    "house_number",
                    "neighborhood",
                    "city",
                    "state",
                    "zip_code",

                    "activity_preferences",
                    "child_loves",
                    "short_or_pants_size",
                    "jumpsuit_or_dress_size",
                    "t_shirt_or_coat_size",
                    "shoe_size",
                    "seasons",
                ];

                if (user && user.token) {
                    firstStepRequireds.indexOf("password") > -1 &&
                        firstStepRequireds.splice(firstStepRequireds.indexOf("password"), 1);

                    firstStepRequireds.indexOf("confirm_password") > -1 &&
                        firstStepRequireds.splice(firstStepRequireds.indexOf("confirm_password"), 1);
                }

                firstStepRequireds.forEach((requiredField) => {
                    if (
                        (!Object.keys(checkValues).includes(requiredField) && !ignoreUndefined) ||
                        checkValues[requiredField] === ""
                    ) {
                        newErrors[requiredField] = true;
                    } else if (!newErrors[requiredField] || checkValues[requiredField] !== undefined) {
                        newErrors[requiredField] = false;
                    }
                });

                canChange = firstStepRequireds.every((requiredField) => {
                    const invalidField = document.querySelector(`[name=${requiredField}]`);

                    const fieldIsValid =
                        Object.keys(checkValues).includes(requiredField) &&
                        checkValues[requiredField] !== undefined &&
                        checkValues[requiredField] !== "";

                    if (invalidField && scroll && !fieldIsValid) {
                        invalidField.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        });
                    }

                    return fieldIsValid;
                });

                if (
                    (!checkValues.password ||
                        !checkValues.confirm_password ||
                        checkValues.password !== checkValues.confirm_password ||
                        !validatePassword(checkValues.password)) &&
                    (!user || !user.token)
                ) {
                    canChange = false;
                }

                if (
                    !delivery ||
                    !delivery.services ||
                    !delivery.services[0].amount ||
                    !delivery.services[0].days ||
                    !delivery.services[0].description ||
                    !delivery.services[0].obs
                ) {
                    if (checkValues.zip_code && checkDelivery) calculateDelivery(checkValues.zip_code);

                    newErrors.zip_code = true;

                    canChange = false;
                }

                const birth_date = moment(checkValues.birth_date, "dd/mm/yyyy");
                const user_age = birth_date && moment().diff(birth_date, "years");

                if (
                    (checkValues.birth_date && checkValues.birth_date.length < 10) ||
                    !birth_date ||
                    !birth_date.isValid() ||
                    user_age < 5 ||
                    user_age > 150
                ) {
                    newErrors.birth_date = true;

                    canChange = false;

                    const invalidField = document.querySelector(`[name=birth_date]`);

                    if (invalidField && scroll) {
                        invalidField.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        });
                    }
                } else newErrors.birth_date = false;

                break;

            case 2:
                canChange = checkValues.box_type_id > 0;
                break;

            case 3:
                const thirdStepRequireds = [
                    "likes_design",
                    "top_needs",
                    "bottom_needs",
                    "essential_pieces",
                    "shoe_needs",
                    "accessory_needs",
                    "brands",
                    "surprise_expected",
                    "accept",
                ];

                canChange = thirdStepRequireds.every((requiredField) => {
                    const invalidField = document.querySelector(`[name=${requiredField}]`);

                    const fieldIsValid =
                        Object.keys(checkValues).includes(requiredField) &&
                        checkValues[requiredField] !== undefined &&
                        checkValues[requiredField] !== "";

                    if (invalidField && scroll && !fieldIsValid) {
                        invalidField.scrollIntoView({
                            behavior: "smooth",
                            block: "center",
                        });
                    }

                    return fieldIsValid;
                });

                if (checkValues.accept !== true) canChange = false;

                break;

            case 4:
                canChange = checkValues.accept_terms === true;

                break;

            default:
                break;
        }

        setErrors({ ...newErrors });

        return canChange;
    };

    const handleContinueNextStep = async () => {
        if (!canChangeStep(true, undefined, undefined, true)) return;

        if (currentStepIndex === 0 && (!user || !user.token)) {
            setSendingSignupRequest(true);

            let signupResponse = await api
                .post("/profiles", {
                    email: values.email,
                    password: !user || !user.token ? values.password : undefined,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    phone_country_code: values.phone_country_code || "55",
                    phone_code_area:
                        values.phone_number && values.phone_number.length > 0 && values.phone_number[0] === "("
                            ? values.phone_number.match(/^\((\d{2})\)/)[1]
                            : values.phone_number.substring(0, 2),
                    phone_number:
                        values.phone_number && values.phone_number[0] === "("
                            ? values.phone_number.substring(5).replace(/\s/g, "").replace(/_/g, "").replace(/-/g, "")
                            : values.phone_number.substring(2),
                    cpf: values.cpf,
                    newsletter: values.newsletter,
                    birth_date: values.birth_date,
                    how_did_you_meet_repetit: (values.how_did_you_meet_repetit || []).join(", "),
                    user_addresses: [
                        {
                            street: values.street,
                            number: values.house_number,
                            kind: "shipping",
                            neighborhood: values.neighborhood,
                            additional_info: values.additional_info,
                            city: values.city,
                            state: values.state,
                            zip: values.zip_code,
                        },
                    ],
                    source: "quiz",
                })
                .catch((err) => {
                    setSendingSignupRequest(false);
                    return err.response;
                });

            setSendingSignupRequest(false);

            if (signupResponse.status === 200) {
                // eslint-disable-next-line
                let userSession = window.userCurrent(user);

                // eslint-disable-next-line
                dataLayer.push({ ecommerce: null });
                // eslint-disable-next-line
                dataLayer.push({
                    event: "virtualPageview",
                    page: document.title,
                    type: "QuizPage",
                    environment: process.env.REACT_APP_ENV,
                    user: [
                        {
                            name: user.first_name && user.last_name ? user.first_name + " " + user.last_name : "",
                            username: user.slug ? user.slug : "",
                            user_id: user.id ? user.id : "",
                            email: user.email ? user.email : "",
                            language: "pt-br",
                            returning: JSON.parse(localStorage.getItem("accepted_cookies")) ? true : false,
                        },
                    ],
                });

                dispatch(
                    UserActions.login({
                        token: signupResponse.headers.token,
                        ...signupResponse.data,
                    })
                );
                dispatch(UserActions.refreshProfile());
            } else {
                console.error(signupResponse);

                setModal({
                    show: true,
                    message:
                        signupResponse && signupResponse.data && signupResponse.data.message
                            ? signupResponse.data.message
                            : "Erro inesperado. Aguarde um instante e tente outra vez!",
                });

                return;
            }
        }

        if (currentStepIndex === 0) {
            // eslint-disable-next-line
            let userSession = window.userCurrent(user);

            let event_id = generateUUID();
            let dados = [
                {
                    event_name: "InitiateQuiz",
                    event_time: Math.round(Date.now() / 1000),
                    event_id: event_id,
                    event_source_url: window.location.href,
                    action_source: "website",
                    user_data: userSession,
                    custom_data: {},
                },
            ];

            const fbHashedEvent = { ...dados[0] };
            fbHashedEvent.user_data = { ...userSession };

            window.fbq("track", "InitiateQuiz", fbHashedEvent, {
                eventID: event_id,
                user_data: { ...userSession },
            });

            api.post("/facebook_ads_event", {
                data: dados,
            })
                .then((res) => console.log("EVENTO InitiateQuiz", res.data))
                .catch((res) => res.response);

            // eslint-disable-next-line
            dataLayer.push({ ecommerce: null });
            // eslint-disable-next-line
            dataLayer.push({
                event: "virtualPageview",
                page: document.title,
                type: "StartQuiz",
                environment: process.env.REACT_APP_ENV,
                user: [
                    {
                        name: user.first_name && user.last_name ? user.first_name + " " + user.last_name : "",
                        username: user.slug ? user.slug : "",
                        user_id: user.id ? user.id : "",
                        email: user.email ? user.email : "",
                        language: "pt-br",
                        returning: JSON.parse(localStorage.getItem("accepted_cookies")) ? true : false,
                    },
                ],
            });
        }

        if (currentStepIndex < Object.keys(steps).length - 1) {
            navigate(`/quiz/${Object.keys(steps)[currentStepIndex + 1]}`);
        } else if (currentStepIndex === 3) {
            let userSessionServerData = window.userCurrentServerData(user);
            let event_id = generateUUID();
            let event_time = Math.round(Date.now() / 1000);

            window.fbq("track", "CompleteRegistration", { registration_source: "quiz_box" }, { eventID: event_id });

            await api
                .post("/facebook_ads_event", {
                    data: [
                        {
                            event_name: "CompleteRegistration",
                            event_id: event_id,
                            event_time: event_time,
                            event_source_url: window.location.href,
                            action_source: "website",
                            user_data: userSessionServerData,
                            custom_data: {
                                registration_source: "quiz_box",
                            },
                        },
                    ],
                })
                .then((res) => console.log("EVENTO CompleteRegistration", res.data))
                .catch((res) => res.response);

            sendQuizRequest();
        }
    };

    const handlePreviousStep = () => {
        if (currentStepIndex > 0) {
            navigate(`/quiz/${Object.keys(steps)[currentStepIndex - 1]}`);
        }
    };

    const sendQuizRequest = async () => {
        setSendingRequest(true);

        let quiz_response = await api
            .post("/quizzes", {
                quiz: {
                    activity_preferences: [values.activity_preferences],
                    child_loves: [values.child_loves],
                    short_or_pants_size: values.short_or_pants_size,
                    jumpsuit_or_dress_size: values.jumpsuit_or_dress_size,
                    t_shirt_or_coat_size: values.t_shirt_or_coat_size,
                    shoe_size: values.shoe_size,
                    seasons: [values.seasons],
                    box_type_id: values.box_type_id,
                    unliked_colors: values.unliked_colors,
                    likes_design: values.likes_design,
                    top_needs: values.top_needs,
                    bottom_needs: values.bottom_needs,
                    essential_pieces: values.essential_pieces,
                    shoe_needs: values.shoe_needs,
                    accessory_needs: values.accessory_needs,
                    brands: values.brands,
                    special_occasion: values.special_occasion,
                    ideas: values.ideas,
                    suggestions: values.suggestions,
                    send_items_for_sale: values.send_items_for_sale,
                    surprise_expected: values.surprise_expected,
                    unliked_fabrics: values.unliked_fabrics,
                },
                user: {
                    email: values.email,
                    password: !user || !user.token ? values.password : undefined,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    phone_country_code: values.phone_country_code || "55",
                    phone_code_area:
                        values.phone_number && values.phone_number.length > 0 && values.phone_number[0] === "("
                            ? values.phone_number.match(/^\((\d{2})\)/)[1]
                            : values.phone_number.substring(0, 2),
                    phone_number:
                        values.phone_number && values.phone_number[0] === "("
                            ? values.phone_number.substring(5).replace(/\s/g, "").replace(/_/g, "").replace(/-/g, "")
                            : values.phone_number.substring(2),
                    cpf: values.cpf,
                    newsletter: values.newsletter,
                    birth_date: values.birth_date,
                    how_did_you_meet_repetit: (values.how_did_you_meet_repetit || []).join(", "),
                    user_addresses: [
                        {
                            street: values.street,
                            number: values.house_number,
                            kind: "shipping",
                            neighborhood: values.neighborhood,
                            additional_info: values.additional_info,
                            city: values.city,
                            state: values.state,
                            zip: values.zip_code,
                        },
                    ],
                    source: "quiz",
                },
                customer_child: {
                    name: values.child_name,
                    birthday: values.child_birthday && moment(values.child_birthday, "DD-MM-YYYY").format("YYYY-MM-DD"),
                    gender: values.child_gender,
                },
            })
            .catch((err) => err.response);

        setSendingRequest(false);

        if (quiz_response.status === 201 && quiz_response.data) {
            // eslint-disable-next-line
            let userSession = window.userCurrent(user);
            let event_id = generateUUID();
            let dados = [
                {
                    event_name: "QuizDone",
                    event_time: Math.round(Date.now() / 1000),
                    event_id: event_id,
                    event_source_url: window.location.href,
                    action_source: "website",
                    user_data: userSession,
                },
            ];

            const fbHashedEvent = { ...dados[0] };
            fbHashedEvent.user_data = { ...userSession };

            window.fbq("track", "QuizDone", fbHashedEvent, {
                eventID: event_id,
                user_data: { ...userSession },
            });

            await api
                .post("/facebook_ads_event", {
                    data: dados,
                })
                .then((res) => console.log("EVENTO QuizDone", res.data));

            // eslint-disable-next-line
            dataLayer.push({
                event: "Quiz Done",
            });

            if (quiz_response.headers && quiz_response.headers.token) {
                dispatch(
                    UserActions.login({
                        token: quiz_response.headers.token,
                        ...quiz_response.data,
                    })
                );
                dispatch(UserActions.refreshProfile());
            }

            localStorage.removeItem("quiz_values");
            setResultState("success");
        } else {
            console.error(quiz_response);

            setModal({
                show: true,
                message:
                    quiz_response && quiz_response.data && quiz_response.data.message
                        ? quiz_response.data.message
                        : "Erro inesperado. Aguarde um instante e tente outra vez!",
            });
        }
    };

    return (
        <>
            <Helmet>
                <title>O Box Re Petit é uma nova proposta em compras de segunda mão</title>
                <meta property="og:title" content="O Box Re Petit é uma nova proposta em compras de segunda mão" />
                <meta
                    name="description"
                    content="No nosso brechó online, baseado no seu perfil montamos uma seleção de até 40 peças do com carinho e enviamos dentro de um BOX para você"
                />
                <meta
                    property="og:description"
                    content="No nosso brechó online, baseado no seu perfil montamos uma seleção de até 40 peças do com carinho e enviamos dentro de um BOX para você"
                />
                <meta
                    name="keywords"
                    content="brechó, brechó infantil, brechó bebês, brechós, brechós infantis, roupa, bebê, bebê conforto, body, berço, enxoval, usado, segunda mão, novo, seminovo, pacote, box, seleção de roupas, box re petit"
                />
            </Helmet>
            <Header
                hideLinks
                subNavigation={
                    <Flex flex="1" maxWidth="911px" margin="auto" justifyContent="flex-start">
                        <Link to="/">
                            <ChevronLeft />
                            Voltar para home
                        </Link>

                        {resultState !== "cancelled" && (
                            <>
                                <Flex flex="1" desktop>
                                    <StepsTitle active={currentStep === "informacoes"}>1. informações</StepsTitle>
                                    <StepsTitle active={currentStep === "box"}>2. box</StepsTitle>
                                    <StepsTitle active={currentStep === "montagem"}>3. montagem</StepsTitle>
                                    <StepsTitle active={currentStep === "conferencia"}>4. conferência</StepsTitle>
                                </Flex>
                                <Flex flex="1" margin="0 auto 0 30px" justifyContent="flex-start" mobile>
                                    <StepCircle active={currentStep === "informacoes"}>1</StepCircle>
                                    <StepCircle active={currentStep === "box"}>2</StepCircle>
                                    <StepCircle active={currentStep === "montagem"}>3</StepCircle>
                                    <StepCircle active={currentStep === "conferencia"}>4</StepCircle>
                                </Flex>
                            </>
                        )}
                    </Flex>
                }
            />
            <Container>
                {resultState !== "cancelled" && (
                    <HeaderContainer background={renderStepHero()}>
                        <Flex
                            flex="1"
                            maxWidth={isMobile ? "100%" : "911px"}
                            minHeight={isMobile ? "380px" : undefined}
                            margin="auto"
                            alignItems="flex-start"
                            direction="column"
                        >
                            <HeaderTitle>{renderHeaderTitle()}</HeaderTitle>
                            <hr />
                        </Flex>
                    </HeaderContainer>
                )}

                <Flex
                    flex="1"
                    maxWidth={currentStepIndex === 3 ? "1222px" : "911px"}
                    margin="auto"
                    padding={isMobile ? "0 20px" : undefined}
                    alignItems="flex-start"
                    direction="column"
                    boxSizing="border-box"
                >
                    {renderCurrentStep()}

                    {resultState !== "cancelled" && (
                        <Flex
                            width={isMobile ? "100%" : "911px"}
                            margin={isMobile ? "auto 0 45px 0" : "auto auto 45px auto"}
                            boxSizing="border-box"
                        >
                            {currentStepIndex === 2 ? (
                                <>
                                    <Button
                                        dark
                                        link
                                        value="Voltar"
                                        width={isMobile ? "100%" : undefined}
                                        padding="16px 0 12px 0"
                                        margin={isMobile ? "12px 0 auto 0" : "auto 216px auto 0"}
                                        onClick={handlePreviousStep}
                                    />
                                    <Button
                                        order={isMobile ? "-1" : undefined}
                                        width={isMobile ? "100%" : "180px"}
                                        dark
                                        filled
                                        value="Revisar pedido"
                                        margin="auto auto auto 0"
                                        disabled={sendingRequest || !values.accept}
                                        onClick={handleContinueNextStep}
                                    />
                                </>
                            ) : (
                                <>
                                    <Button
                                        width={isMobile ? "100%" : currentStepIndex === 3 ? "240px" : "180px"}
                                        dark
                                        filled
                                        borderradius
                                        margin={isMobile ? "0" : undefined}
                                        value={currentStepIndex === 3 ? "Finalizar Pedido" : "Continuar"}
                                        onClick={handleContinueNextStep}
                                        disabled={
                                            (currentStepIndex === 0 && sendingSignupRequest) ||
                                            sendingRequest ||
                                            (currentStepIndex === 3 && (delivery === undefined || !values.accept_terms))
                                        }
                                        loading={sendingRequest}
                                    />
                                    {currentStepIndex > 0 && (
                                        <Button
                                            dark
                                            link
                                            margin={isMobile ? "12px 0 auto 0" : undefined}
                                            padding="16px 72px 12px 72px"
                                            value="Voltar"
                                            onClick={handlePreviousStep}
                                        />
                                    )}
                                </>
                            )}
                        </Flex>
                    )}
                </Flex>
            </Container>

            <Footer mini />

            {modal && (
                <AlertModal
                    show={modal.show}
                    title={modal.title}
                    message={modal.message}
                    callback={() => setModal({ ...modal, show: false })}
                />
            )}

            {resultState === "success" && <BoxSuccessModal show type="request" callback={() => navigate("/")} />}
        </>
    );
};

const Container = styled.div`
    flex: 1;
`;

const HeaderContainer = styled.div`
    background-image: url(${(props) => props.background});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 230px;
    padding: 53px 0px 78px 0px;
    box-sizing: border-box;

    hr {
        background-color: #fff;
        width: 50px;
        height: 5px;
        border: 0px;
        margin: 0px;
        margin-right: auto;
    }
    ${isMobileMediaQuery} {
        padding: 50px 0 0 0;

        hr {
            width: 60px;
            margin-left: auto;
            margin-bottom: auto;
        }
    }
`;

const HeaderTitle = styled.h1`
    margin: 0px 0px 28px 0px;
    max-width: 375px;

    font-family: "DMSerifDisplay";
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;
    box-sizing: border-box;

    ${isMobileMediaQuery} {
        padding: 0 20px;
        max-width: 100%;
        font-size: 32px;
        text-align: center;
        margin-top: 22px;
        margin-left: auto;
        margin-right: auto;
    }
`;

const Link = styled(RouterLink)`
    font-size: 15px;
    line-height: 1.4;
    display: flex;
    align-items: center;
    color: #fefeff;
`;

export const SectionTitle = styled.h2`
    margin: 39px auto 39px 0px;
    text-transform: uppercase;
    font-family: "SackersGothicStd";
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 4.4px;
    text-align: left;
    color: #34343b;

    ${isMobileMediaQuery} {
        line-height: 1.45;
    }
`;

const ChevronLeft = styled.span`
    display: inline-block;
    background-color: #fff;
    mask-size: cover;
    mask-position: center;
    mask-image: url(${ChevronLeftIcon});
    object-fit: contain;
    height: 14px;
    width: 8px;
    margin-right: 9px;
    margin-left: 18px;
`;

const StepsTitle = styled.p`
    margin: auto 50px auto 0px;
    text-transform: uppercase;

    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.82;
    letter-spacing: 2.2px;
    text-align: left;
    color: #666679;

    &:nth-child(2) {
        margin-left: 60px;
    }

    ${(props) => props.active && `color: #fff;`}
`;

const StepCircle = styled.div`
    margin: auto 10px auto 0px;
    text-transform: uppercase;

    width: 25px;
    height: 25px;
    padding: 0px 0px 2px 2px;
    box-sizing: border-box;
    background-color: #66667a;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: "SackersGothicStd";
    font-size: 15px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 10px;
    letter-spacing: 3px;
    text-align: center;
    color: #34343c;

    ${(props) => props.active && `background-color: #fff;`}
`;

export default QuizLogin;
