import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import { Flex, isMobileMediaQuery } from "../../helpers/styles";

import { ReactComponent as CheckIcon } from "../../assets/images/check.svg";

const CheckBox = ({
    checked,
    defaultChecked,
    name,
    value,
    label,
    rtl,
    secondary,
    pinky,
    padding,
    margin,
    radio,
    radioIcon,
    small,
    smaller,
    onChange,
    special,
    disabled,
    ...props
}) => {
    const [isChecked, setIsChecked] = useState(
        defaultChecked || checked || false
    );

    const handleChange = (e) => {
        onChange && onChange(e);

        setIsChecked(e.target.checked);
    };

    useEffect(() => {
        if (checked !== undefined && checked !== isChecked) {
            setIsChecked(checked);
        }

        // eslint-disable-next-line
    }, [checked]);

    return (
        <Label
            as="label"
            margin={margin}
            padding={padding}
            secondary={secondary}
            special={special}
            disabled={disabled}
            {...props}
        >
            {rtl && (label ? <p>{label}</p> : props.children)}
            {radio || radioIcon ? (
                <RadioIcon
                    checked={isChecked}
                    small={small}
                    smaller={smaller}
                    pinky={pinky ? 1 : 0}
                    needMargin={label || props.children}
                >
                    <input
                        type={radio ? "radio" : "checkbox"}
                        name={name}
                        value={value}
                        checked={checked}
                        defaultChecked={defaultChecked}
                        onChange={handleChange}
                    />
                    <span />
                </RadioIcon>
            ) : (
                <Check
                    checked={isChecked}
                    pinky={pinky ? 1 : 0}
                    needMargin={label || props.children}
                >
                    <input
                        type="checkbox"
                        name={name}
                        value={value}
                        checked={checked}
                        defaultChecked={defaultChecked}
                        onChange={handleChange}
                    />
                    <CheckIcon pinky={pinky ? 1 : 0} />
                </Check>
            )}
            {!rtl && (label ? <p>{label}</p> : props.children)}
        </Label>
    );
};

const Label = styled(Flex)`
    font-family: "Graphik";
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;
    cursor: pointer;

    p {
        margin: 0px;
        max-width: calc(100% - 32px);
        display: flex;
        align-items: center;
    }

    a {
        color: #34343b;
    }

    ${(props) =>
        props.secondary &&
        css`
            font-size: 15px;
            line-height: 1.4;
            color: #676777;
        `}

    ${({ special }) =>
        special &&
        css`
            p {
                text-transform: capitalize;
                font-family: "DMSerifDisplay";
                font-size: 19px;
                font-style: italic;
                line-height: 1.11;
                text-align: left;
                color: #34343b;
            }
        `};

    ${(props) =>
        props.disabled &&
        css`
            opacity: 0.55;
            pointer-events: none;
        `}
`;

const Check = styled.div`
    position: relative;
    width: 20px;
    height: 20px;
    margin: 0px;
    border: solid 1px #c9c9cf;
    background-color: #fff;
    ${(props) =>
        props.needMargin &&
        (props.rtl ? "margin-left: 10px;" : "margin-right: 10px;")};

    input {
        appearance: none;
        width: 100%;
        height: 100%;
        padding: 0px;
        margin: 0px;
        cursor: pointer;
    }

    input:not(:checked) + svg {
        display: none;
    }

    input:checked + svg {
        display: block;
        text-align: center;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-25%, -50%);
        pointer-events: none;
    }

    ${(props) =>
        props.pinky &&
        props.checked &&
        css`
            border-color: #e4858c;
            background-color: #e4858c;
        `}

    ${(props) =>
        props.pinky &&
        css`
            width: 10px;
            height: 10px;
            box-sizing: border-box;
            position: relative;

            ${isMobileMediaQuery} {
                width: 18px;
                height: 18px;
            }

            input:checked + svg {
                display: none;
            }

            input:checked {
                ::after {
                    content: "✓";
                    display: block;
                    text-align: center;
                    color: #fff;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    font-size: 9px;
                    font-weight: bold;
                    transform: translate(-50%, -50%);
                    line-height: 1;

                    ${isMobileMediaQuery} {
                        font-size: 14px;
                    }
                }
            }
        `}
`;

export const RadioIcon = styled.div`
    position: relative;
    width: 27px;
    height: 27px;
    margin: 0px;
    border: solid 1px #cecfd4;
    background-color: transparent;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    ${(props) =>
        props.needMargin &&
        (props.rtl ? "margin-left: 10px;" : "margin-right: 10px;")};

    input {
        appearance: none;
        width: 0;
        height: 0;
        padding: 0px;
        margin: 0px;
        cursor: pointer;
    }

    input:not(:checked) + span {
        display: none;
    }

    input:checked + span {
        width: 15px;
        height: 15px;
        border-radius: 100%;
        display: block;
        text-align: center;
        background-color: #676777;
        pointer-events: none;

        ${(props) =>
            props.pinky &&
            css`
                background-color: #e4858c;
            `}
    }

    ${(props) =>
        props.checked &&
        css`
            input:checked + span {
                width: 15px;
                height: 15px;
                border-radius: 100%;
                display: block;
                text-align: center;
                background-color: #676777;
                pointer-events: none;

                ${(props) =>
                    props.pinky &&
                    css`
                        background-color: #e4858c;
                    `}
            }
        `};

    ${(props) =>
        props.small &&
        css`
            width: 20px;
            height: 20px;

            input:checked + span {
                width: 10px;
                height: 10px;
            }
        `};
    
    ${(props) =>
        props.smaller &&
        css`
            width: 16px;
            height: 16px;

            input:checked + span {
                width: 8px;
                height: 8px;
            }
        `};
`;

export default CheckBox;
