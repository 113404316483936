import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { identifyZipCode } from "../../helpers";
import { Flex, isMobile, isMobileMediaQuery } from "./../../helpers/styles";

import TextInput from "../../components/Inputs/TextInput";
import CheckBox from "../../components/Inputs/CheckBox";
import QuizSelectBox from "../../components/QuizSelectBox";
import SelectInput from "../../components/Inputs/SelectInput";

import QuizMenina from "../../assets/images/quiz-menina.png";
import QuizMenino from "../../assets/images/quiz-menino.png";

import QuizAventureiro from "../../assets/images/quiz-aventureiro.png";
import QuizArtistico from "../../assets/images/quiz-artistico.png";
import QuizConectado from "../../assets/images/quiz-conectado.png";

import QuizPraia from "../../assets/images/quiz-praia.png";
import QuizCantar from "../../assets/images/quiz-cantar.png";
import QuizCasa from "../../assets/images/quiz-casa.png";

import QuizPrimavera from "../../assets/images/quiz-primavera.png";
import QuizVerao from "../../assets/images/quiz-verao.png";
import QuizOutono from "../../assets/images/quiz-outono.png";
import QuizInverno from "../../assets/images/quiz-inverno.png";

import ProfileForm from "../../components/ProfileForm";
import SigninLink from "../../components/SigninLink";

const InfoContent = ({
    values,
    setValues,
    errors,
    setErrors,
    handleChange,
    isChecked,
    handleSelectItem,
}) => {
    const user = useSelector((state) => state.user);

    const handleZipCode = async ({ target: { value, name } }) => {
        let newValues = { ...values };

        if (value && value.replace(/_/g, "").replace(/-/g, "").length >= 8) {
            let zipCodeResponse = await identifyZipCode(value);

            if (zipCodeResponse.street) {
                setErrors((prevState) => {
                    return {
                        ...prevState,
                        zip_code: false,
                    };
                });
            }

            newValues.street = zipCodeResponse.logradouro;
            newValues.state = zipCodeResponse.uf;
            newValues.city = zipCodeResponse.localidade;
            newValues.neighborhood = zipCodeResponse.bairro;
        }

        setValues((prevState) => {
            return { ...prevState, ...newValues };
        });
    };

    return (
        <>
            <SectionTitle>
                1.1 Dados Cadastrais
                {!user.token && <SigninLink />}
            </SectionTitle>

            <ProfileForm
                values={values}
                handleChange={handleChange}
                errors={errors}
                user={user}
                handleZipCode={handleZipCode}
            />

            <SectionTitle>
                conta pra gente: como conheceu a re petit?
            </SectionTitle>

            <Flex width="100%" direction="column" margin="0 35px 0 0">
                <CheckBox
                    margin="0px auto 15px 0px"
                    secondary
                    name="how_did_you_meet_repetit"
                    checked={
                        values.how_did_you_meet_repetit &&
                        typeof values.how_did_you_meet_repetit === "object"
                            ? isChecked("how_did_you_meet_repetit", "Instagram")
                            : false
                    }
                    onChange={(e) =>
                        handleSelectItem(
                            "how_did_you_meet_repetit",
                            "Instagram",
                            e.target.checked
                        )
                    }
                >
                    Instagram
                </CheckBox>
                <CheckBox
                    margin="0px auto 15px 0px"
                    secondary
                    name="how_did_you_meet_repetit"
                    checked={
                        values.how_did_you_meet_repetit &&
                        typeof values.how_did_you_meet_repetit === "object"
                            ? isChecked("how_did_you_meet_repetit", "Facebook")
                            : false
                    }
                    onChange={(e) =>
                        handleSelectItem(
                            "how_did_you_meet_repetit",
                            "Facebook",
                            e.target.checked
                        )
                    }
                >
                    Facebook
                </CheckBox>
                <CheckBox
                    margin="0px auto 15px 0px"
                    secondary
                    name="how_did_you_meet_repetit"
                    checked={
                        values.how_did_you_meet_repetit &&
                        typeof values.how_did_you_meet_repetit === "object"
                            ? isChecked(
                                  "how_did_you_meet_repetit",
                                  "Amigos e familiares"
                              )
                            : false
                    }
                    onChange={(e) =>
                        handleSelectItem(
                            "how_did_you_meet_repetit",
                            "Amigos e familiares",
                            e.target.checked
                        )
                    }
                >
                    Amigos e familiares
                </CheckBox>
                <CheckBox
                    margin="0px auto 15px 0px"
                    secondary
                    name="how_did_you_meet_repetit"
                    checked={
                        values.how_did_you_meet_repetit &&
                        typeof values.how_did_you_meet_repetit === "object"
                            ? isChecked("how_did_you_meet_repetit", "Google")
                            : false
                    }
                    onChange={(e) =>
                        handleSelectItem(
                            "how_did_you_meet_repetit",
                            "Google",
                            e.target.checked
                        )
                    }
                >
                    Google
                </CheckBox>
            </Flex>

            <SectionTitle>
                1.1 qual o nome e data de nascimento da criança?
            </SectionTitle>

            <Flex width="100%">
                <CardBox
                    minWidth={isMobile && "100%"}
                    flex={isMobile ? "1" : "0.5"}
                    margin={isMobile ? "0 0 20px 0" : "0px 36px 36px 0px"}
                    boxSizing="border-box"
                    direction={isMobile ? "column" : "row"}
                    alignItems={isMobile ? "flex-start" : null}
                >
                    Nome
                    <TextInput
                        bottomLined
                        special
                        flex="1"
                        width="auto"
                        placeholder="Digite o nome"
                        name="child_name"
                        value={values.child_name}
                        onChange={handleChange}
                        error={errors.child_name}
                    />
                </CardBox>

                <CardBox
                    minWidth={isMobile && "100%"}
                    flex={isMobile ? "1" : "0.5"}
                    margin={isMobile ? "0" : "0px 0px 36px 0px"}
                    boxSizing="border-box"
                    direction={isMobile ? "column" : "row"}
                    alignItems={isMobile ? "flex-start" : null}
                >
                    <p>Data de nascimento</p>
                    <TextInput
                        bottomLined
                        special
                        flex="1"
                        width="auto"
                        placeholder="dd/mm/aaaa"
                        mask="99/99/9999"
                        name="child_birthday"
                        value={values.child_birthday}
                        onChange={handleChange}
                        error={errors.child_birthday}
                    />
                </CardBox>
            </Flex>

            <SectionTitle name="child_gender">
                1.2 este box é para...
            </SectionTitle>

            <Flex width="100%" alignItems="stretch">
                <QuizSelectBox
                    flex={isMobile ? "0.3" : "1"}
                    width="calc(30% - 10px)"
                    minWidth="auto"
                    minHeight="auto"
                    margin={isMobile ? "0 20px 0 0" : "0 36px 0px 0px"}
                    title="Menina"
                    vertical={isMobile}
                    value={isChecked("child_gender", "female")}
                    onChange={(e) =>
                        handleSelectItem("child_gender", "female", e)
                    }
                    error={errors.child_gender}
                >
                    <img src={QuizMenina} alt="" />
                </QuizSelectBox>
                <QuizSelectBox
                    flex={isMobile ? "0.3" : "1"}
                    width="calc(30% - 10px)"
                    minWidth="auto"
                    minHeight="auto"
                    margin={isMobile ? "0 20px 0 0" : "0 36px 0px 0px"}
                    title="Menino"
                    vertical={isMobile}
                    value={isChecked("child_gender", "male")}
                    onChange={(e) =>
                        handleSelectItem("child_gender", "male", e)
                    }
                    error={errors.child_gender}
                >
                    <img src={QuizMenino} alt="" />
                </QuizSelectBox>
                <QuizSelectBox
                    flex={isMobile ? "0.3" : "1"}
                    width="calc(30% - 10px)"
                    minWidth="auto"
                    minHeight="auto"
                    title="Unissex"
                    vertical={isMobile}
                    value={isChecked("child_gender", "unissex")}
                    onChange={(e) =>
                        handleSelectItem("child_gender", "unissex", e)
                    }
                    error={errors.child_gender}
                />
            </Flex>

            <SectionTitle name="activity_preferences">
                1.3 qual perfil é a cara de{" "}
                {values.child_name !== "" ? values.child_name : "..."}?
            </SectionTitle>

            <Flex width="100%">
                <QuizSelectBox
                    flex="1"
                    margin={isMobile ? "0" : "0 36px 0px 0px"}
                    title="Aventureiro"
                    description="Pique lá no alto! Brincadeiras ao ar livre como andar de bicicleta, jogar bola e brincar no parquinho."
                    vertical={!isMobile}
                    value={isChecked("activity_preferences", "aventureiro")}
                    onChange={(e) =>
                        handleSelectItem(
                            "activity_preferences",
                            "aventureiro",
                            e
                        )
                    }
                    error={errors.activity_preferences}
                >
                    <QuizImage src={QuizAventureiro} alt="" />
                </QuizSelectBox>
                <QuizSelectBox
                    flex="1"
                    margin={isMobile ? "0" : "0 36px 0px 0px"}
                    title="Artístico"
                    description="Vive no mundo da imaginação! Adora cantar, dançar, desenhar e inventar histórias."
                    vertical={!isMobile}
                    value={isChecked("activity_preferences", "artistico")}
                    onChange={(e) =>
                        handleSelectItem("activity_preferences", "artistico", e)
                    }
                    error={errors.activity_preferences}
                >
                    <QuizImage src={QuizArtistico} alt="" />
                </QuizSelectBox>
                <QuizSelectBox
                    flex="1"
                    title="Conectado"
                    description="Se liga em livros e tecnologia. Ama jogar videogame, assistir a filmes e fazer experiências."
                    vertical={!isMobile}
                    value={isChecked("activity_preferences", "conectado")}
                    onChange={(e) =>
                        handleSelectItem("activity_preferences", "conectado", e)
                    }
                    error={errors.activity_preferences}
                >
                    <QuizImage src={QuizConectado} alt="" />
                </QuizSelectBox>
            </Flex>

            <SectionTitle name="child_loves">
                1.4 {values.child_name !== "" ? values.child_name : "..."}{" "}
                ama...
            </SectionTitle>

            <Flex width="100%">
                <QuizSelectBox
                    flex="1"
                    margin={isMobile ? "0" : "0 36px 0px 0px"}
                    title="Ir à praia, molhar os
                            pezinhos no mar e brincar
                            toda hora."
                    vertical={!isMobile}
                    value={isChecked("child_loves", "ir à praia")}
                    onChange={(e) =>
                        handleSelectItem("child_loves", "ir à praia", e)
                    }
                    error={errors.child_loves}
                >
                    <QuizImage src={QuizPraia} alt="" />
                </QuizSelectBox>
                <QuizSelectBox
                    flex="1"
                    margin={isMobile ? "0" : "0 36px 0px 0px"}
                    title="Dançar e cantar
                            qualquer que seja
                            a música."
                    vertical={!isMobile}
                    value={isChecked("child_loves", "dançar")}
                    onChange={(e) =>
                        handleSelectItem("child_loves", "dançar", e)
                    }
                    error={errors.child_loves}
                >
                    <QuizImage src={QuizCantar} alt="" />
                </QuizSelectBox>
                <QuizSelectBox
                    flex="1"
                    title="Fazer uma sessão de
                            cinema em casa com
                            a mamãe e o papai."
                    vertical={!isMobile}
                    value={isChecked("child_loves", "ir ao cinema")}
                    onChange={(e) =>
                        handleSelectItem("child_loves", "ir ao cinema", e)
                    }
                    error={errors.child_loves}
                >
                    <QuizImage src={QuizCasa} alt="" />
                </QuizSelectBox>
            </Flex>

            <SectionTitle>
                1.5 qual número{" "}
                {values.child_name !== "" ? values.child_name : "..."} veste?
            </SectionTitle>

            <Flex width="100%" margin={isMobile ? "0" : "0 0 36px 0"}>
                <CardBox
                    width={isMobile ? "100%" : undefined}
                    minWidth={isMobile ? "100%" : undefined}
                    flex="1"
                    margin={isMobile ? "0 0 20px 0" : "0 36px 0 0"}
                    boxSizing="border-box"
                >
                    <p> Shorts, calça ou saia</p>
                    <SelectInput
                        placeholder="Selecione o tamanho"
                        width="auto"
                        flex="1"
                        options={[
                            "0m",
                            "3m",
                            "6m",
                            "9m",
                            "12m",
                            "18m",
                            "24m",
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                            "7",
                            "8",
                            "9",
                            "10",
                        ]}
                        name="short_or_pants_size"
                        value={values.short_or_pants_size}
                        onChange={handleChange}
                    />
                </CardBox>
                <CardBox
                    width={isMobile ? "100%" : undefined}
                    minWidth={isMobile ? "100%" : undefined}
                    flex="1"
                    margin={isMobile ? "0 0 20px 0" : "0"}
                    boxSizing="border-box"
                >
                    <p> Macacão ou vestido</p>
                    <SelectInput
                        placeholder="Selecione o tamanho"
                        width="auto"
                        flex="1"
                        options={[
                            "0m",
                            "3m",
                            "6m",
                            "9m",
                            "12m",
                            "18m",
                            "24m",
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                            "7",
                            "8",
                            "9",
                            "10",
                        ]}
                        name="jumpsuit_or_dress_size"
                        value={values.jumpsuit_or_dress_size}
                        onChange={handleChange}
                    />
                </CardBox>
            </Flex>

            <Flex width="100%">
                <CardBox
                    width={isMobile ? "100%" : undefined}
                    minWidth={isMobile ? "100%" : undefined}
                    flex="1"
                    margin={isMobile ? "0 0 20px 0" : "0 36px 36px 0"}
                    boxSizing="border-box"
                >
                    <p> Camiseta, blusa ou casaco</p>
                    <SelectInput
                        placeholder="Selecione o tamanho"
                        width="auto"
                        flex="1"
                        options={[
                            "0m",
                            "3m",
                            "6m",
                            "9m",
                            "12m",
                            "18m",
                            "24m",
                            "1",
                            "2",
                            "3",
                            "4",
                            "5",
                            "6",
                            "7",
                            "8",
                            "9",
                            "10",
                        ]}
                        name="t_shirt_or_coat_size"
                        value={values.t_shirt_or_coat_size}
                        onChange={handleChange}
                    />
                </CardBox>
                <CardBox
                    width={isMobile ? "100%" : undefined}
                    minWidth={isMobile ? "100%" : undefined}
                    flex="1"
                    margin={isMobile ? "0" : "0 0 36px 0"}
                    boxSizing="border-box"
                >
                    <p> Calçados</p>
                    <SelectInput
                        placeholder="Selecione o tamanho"
                        width="auto"
                        flex="1"
                        options={[
                            "0m",
                            "3m",
                            "6m",
                            "9m",
                            "12m",
                            "14",
                            "15",
                            "16",
                            "17",
                            "18",
                            "19",
                            "20",
                            "21",
                            "22",
                            "23",
                            "24",
                            "25",
                            "26",
                            "27",
                            "28",
                            "29",
                            "30",
                            "31",
                            "32",
                            "33",
                            "34",
                        ]}
                        name="shoe_size"
                        value={values.shoe_size}
                        onChange={handleChange}
                    />
                </CardBox>
            </Flex>

            <SectionTitle name="seasons">
                1.6 as roupas são para qual estação?
            </SectionTitle>

            <Flex
                width="100%"
                wrap={!isMobile && "nowrap"}
                justifyContent="space-between"
                margin="0 0 60px 0"
            >
                <QuizSelectBox
                    margin={isMobile ? "0 20px 20px 0" : "0 35px 0 0"}
                    flex={isMobile ? "0.5" : "0.25"}
                    width="calc(50% - 10px)"
                    minWidth={isMobile && "initial"}
                    minHeight="260px"
                    padding="10px 25px"
                    title="Primavera"
                    vertical
                    value={isChecked("seasons", "primavera")}
                    onChange={(e) =>
                        handleSelectItem("seasons", "primavera", e)
                    }
                    error={errors.seasons}
                >
                    <img src={QuizPrimavera} alt="" />
                </QuizSelectBox>
                <QuizSelectBox
                    margin={isMobile ? "0 0 20px 0" : "0 35px 0 0"}
                    flex={isMobile ? "0.5" : "0.25"}
                    width="calc(50% - 10px)"
                    minWidth={isMobile && "initial"}
                    minHeight="260px"
                    padding="10px 25px"
                    title="Verão"
                    vertical
                    value={isChecked("seasons", "verao")}
                    onChange={(e) => handleSelectItem("seasons", "verao", e)}
                    error={errors.seasons}
                >
                    <img src={QuizVerao} alt="" />
                </QuizSelectBox>
                <QuizSelectBox
                    margin={isMobile ? "0 20px 0 0" : "0 35px 0 0"}
                    flex={isMobile ? "0.5" : "0.25"}
                    width="calc(50% - 10px)"
                    minWidth={isMobile && "initial"}
                    minHeight="260px"
                    padding="10px 25px"
                    title="Outono"
                    vertical
                    value={isChecked("seasons", "outono")}
                    onChange={(e) => handleSelectItem("seasons", "outono", e)}
                    error={errors.seasons}
                >
                    <img src={QuizOutono} alt="" />
                </QuizSelectBox>
                <QuizSelectBox
                    flex={isMobile ? "0.5" : "0.25"}
                    width="calc(50% - 10px)"
                    minWidth={isMobile && "initial"}
                    minHeight="260px"
                    padding="10px 25px"
                    title="Inverno"
                    vertical
                    value={isChecked("seasons", "inverno")}
                    onChange={(e) => handleSelectItem("seasons", "inverno", e)}
                    error={errors.seasons}
                >
                    <img src={QuizInverno} alt="" />
                </QuizSelectBox>
            </Flex>
        </>
    );
};

const SectionTitle = styled.h2`
    margin: 39px auto 39px 0px;
    text-transform: uppercase;
    font-family: "SackersGothicStd";
    font-size: 14px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 2.8px;
    text-align: left;
    color: #34343b;

    ${isMobileMediaQuery} {
        line-height: 1.45;
    }
`;

const CardBox = styled(Flex)`
    padding: 34px 28px;
    background-color: #fbfcfc;
    box-shadow: 0px 1px 3px 0 rgba(52, 52, 59, 0.1);

    &,
    p {
        font-size: 15px;
        line-height: 1.07;
        color: #676777;
    }

    p {
        width: auto;
        flex: 0.3;
        margin: 0px;
        margin-right: 12px;
    }
`;

const QuizImage = styled.img`
    ${isMobileMediaQuery} {
        min-width: 105px;
        height: 102px;
        object-fit: contain !important;
    }
`;

export default InfoContent;
