import React, { useState } from "react";
import styled from "styled-components";

import { ReactComponent as OrderCompletedIcon } from "../../assets/images/order/completed-icon.svg";
import { ReactComponent as OrderIncompleteIcon } from "../../assets/images/order/incomplete-icon.svg";
import { ReactComponent as OrderErrorIcon } from "../../assets/images/order/error-icon.svg";

import { ReactComponent as ClickIcon } from "../../assets/images/clickIcon.svg";

import { ReactComponent as OrderReceivedIcon } from "../../assets/images/order/received-icon.svg";
import { ReactComponent as OrderReceivedActiveIcon } from "../../assets/images/order/received-active-icon.svg";

import { ReactComponent as OrderPaymentApprovedActiveIcon } from "../../assets/images/order/payment-approved-active-icon.svg";
import { ReactComponent as OrderPaymentApprovedIcon } from "../../assets/images/order/payment-approved-icon.svg";

import { ReactComponent as OrderSentActiveIcon } from "../../assets/images/order/sent-active-icon.svg";
import { ReactComponent as OrderSentIcon } from "../../assets/images/order/sent-icon.svg";

import { ReactComponent as OrderDeliveredActiveIcon } from "../../assets/images/order/delivered-active-icon.svg";
import { ReactComponent as OrderDeliveredIcon } from "../../assets/images/order/delivered-icon.svg";

const ProcessStepsOrder = ({ order }) => {
    return (
        <>
            <ProcessStepsWrapper>
                <Step active={order.steps_status[1].active}>
                    <StepVector>
                        {order.steps_status[0].active ? <OrderReceivedActiveIcon /> : <OrderReceivedIcon />}
                    </StepVector>
                    <StepIcon>
                        {order.steps_status[0].success ? order.steps_status[0].active ? <OrderCompletedIcon /> : <OrderIncompleteIcon /> : <OrderErrorIcon />}
                    </StepIcon>
                    <StepLabel>
                        {order.steps_status[0].label}
                    </StepLabel>
                </Step>
                <Step active={order.steps_status[2].active}>
                    <StepVector>
                        {order.steps_status[1].active ? <OrderPaymentApprovedActiveIcon /> : <OrderPaymentApprovedIcon />}
                    </StepVector>
                    <StepIcon>
                        {order.steps_status[1].success ? order.steps_status[1].active ? <OrderCompletedIcon /> : <OrderIncompleteIcon /> : <OrderErrorIcon />}
                    </StepIcon>
                    <StepLabel>
                        {order.steps_status[1].label}
                    </StepLabel>
                </Step>
                <Step active={order.steps_status[3].active}>
                    <StepVector>
                        {order.steps_status[2].active ? <OrderSentActiveIcon /> : <OrderSentIcon />}
                    </StepVector>
                    <StepIcon>
                        {order.steps_status[2].success ? order.steps_status[2].active ? <OrderCompletedIcon /> : <OrderIncompleteIcon /> : <OrderErrorIcon />}
                    </StepIcon>
                    <StepLabel>
                        {order.steps_status[2].label}
                    </StepLabel>
                </Step>
                <Step active={order.steps_status[3].active}>
                    <StepVector>
                        {order.steps_status[3].active ? <OrderDeliveredActiveIcon /> : <OrderDeliveredIcon />}
                    </StepVector>
                    <StepIcon>
                        {order.steps_status[3].success ? order.steps_status[3].active ? <OrderCompletedIcon /> : <OrderIncompleteIcon /> : <OrderErrorIcon />}
                    </StepIcon>
                    <StepLabel>
                        {order.steps_status[3].label}
                    </StepLabel>
                </Step>
            </ProcessStepsWrapper>
            <Text>{order.steps_status_description}</Text>
            {order.tracking_url != '' && (
                <TextLink href={order.tracking_url} target="_blank">
                    <span style={{ paddingRight: "12px" }}>Seu pedido está indo com carinho! Rastreie aqui</span><ClickIcon />
                </TextLink>
            )}
        </>
    );
};

const Text = styled.p`
    font-size: 18px;
    color: #34343B;
    text-align: center;
`;
const TextLink = styled.a`
    font-size: 20px;
    color: #7CA8AF;
    text-decoration: underline;
    cursor: pointer;
    margin-bottom: 5px;
    margin-top: 0px;
`;

const ProcessStepsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 0 24px 0;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
  position: relative;

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    top: 60.5%;
    right: -50%;
    height: 0px;
    width: 100%;
    z-index: -1;
    transform: translateY(50%);
    border-bottom: 2px ${({ active }) => (active ? 'solid #7CA8AF' : 'dashed #CDD5DF')};
  }
`;

const StepVector = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`;

const StepIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StepLabel = styled.span`
color: #121926;
font-size: 16px;
margin-top: 12px;
max-width: 113px;
`;

export default ProcessStepsOrder;
