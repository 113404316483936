import { SHOW_MODAL, CLOSE_MODAL } from "../actions/modal";

const initialState = {
    show: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SHOW_MODAL:
            return {
                show: true,
                ...action.payload,
            };

        case CLOSE_MODAL:
            return {
                show: false,
            };

        default:
            return state;
    }
};
