import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useLocation, useNavigate, Link } from "react-router-dom";
import api from "../../services/api";
import { Flex, isMobile, isMobileMediaQuery } from "./../../helpers/styles";
import BoxItem from "../BoxItem";
import MoreIcon from "../MoreIcon";
import BrandsGrid from "../BrandsGrid";
import QuizButton from "../QuizButton";
import BackgroundMenuCategorias from "./../../assets/images/menu-categorias.jpg";
import BackgroundMenuOcasioes from "./../../assets/images/menu-ocasioes.jpg";

function NavigationMenu({ navigationItems, onMenuItemSet, handleChangeMenuItem, callback, ...props }) {
    const bodyElement = document.querySelector("body");
    const location = useLocation();
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const [currentMenuItem, setCurrentMenuItem] = useState(undefined);
    const [currentSubCategory, setCurrentSubCategory] = useState(undefined);
    const [currentSubMenuItem, setCurrentSubMenuItem] = useState(undefined);
    const [state, setState] = useState({});

    useEffect(() => {
        loadMenu();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        handleCloseMenu();

        // eslint-disable-next-line
    }, [location.pathname]);

    useEffect(() => {
        if (onMenuItemSet !== undefined && onMenuItemSet !== currentMenuItem) {
            setMenuOpen(true);
            setCurrentMenuItem(onMenuItemSet);
        }

        // eslint-disable-next-line
    }, [onMenuItemSet]);

    useEffect(() => {
        if (currentMenuItem !== onMenuItemSet) {
            handleChangeMenuItem && handleChangeMenuItem(currentMenuItem);
        }

        // eslint-disable-next-line
    }, [currentMenuItem]);

    const loadMenu = async () => {
        let response = await api.get("/menu?clearCache=true");

        if (response.status === 200) {
            setState({ ...response.data });
        } else {
            setState({
                categories: {},
                brands: {},
                moments: [],
                boxes: [],
                blog_posts: [],
            });
        }
    };

    const handleOpenMenu = () => {
        if (!menuOpen) {
            let top = window.scrollY;

            bodyElement.style.position = "sticky";
            bodyElement.style.top = `-${top}px`;
            bodyElement.style.left = "0px";
            bodyElement.style.right = "0px";
        }

        setMenuOpen(true);
        setCurrentMenuItem("compre");
        setCurrentSubMenuItem("categorias");
        setCurrentSubCategory("menina");
        callback && callback(true);
    };

    const handleCloseMenu = () => {
        if (menuOpen) {
            const scrollY = bodyElement.style.top;

            bodyElement.style.position = "";
            bodyElement.style.top = "";
            bodyElement.style.left = "";
            bodyElement.style.right = "";

            window.scrollTo(0, -parseInt(scrollY || "0"));
        }

        setMenuOpen(false);
        setCurrentMenuItem(undefined);
        setCurrentSubCategory(undefined);
        callback && callback(false);
    };

    const handleClickMenuItem = (item) => {
        handleOpenMenu();
        setCurrentMenuItem(item);
    };

    const handleClickSubMenuItem = (item) => {
        setMenuOpen(true);
        setCurrentSubCategory(item);
    };

    const renderCompraMenu = () => {
        switch (currentSubMenuItem) {
            case "categorias":
                return (
                    <>
                        <Flex
                            width="calc(100% - 485px)"
                            padding="35px"
                            direction="row"
                            margin="0 auto auto 0"
                            alignSelf="flex-start"
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            {state.categories &&
                                state.categories[currentSubCategory] &&
                                state.categories[currentSubCategory].map((item, index) => (
                                    <LinkItem to={`/categorias/${currentSubCategory}/${item.slug}`} key={index}>
                                        {item.name}
                                    </LinkItem>
                                ))}
                        </Flex>
                        <MenuBackground>
                            <h2>
                                conheça as <br />
                                novidades
                                <br />
                                re petit
                            </h2>
                            <img src={BackgroundMenuCategorias} alt="Categorias" />
                        </MenuBackground>
                    </>
                );

            case "marcas":
                return <BrandsGrid brands={state.brands} subCategory={currentSubCategory} />;

            case "ocasiões":
                return (
                    <>
                        <Flex
                            width="calc(100% - 485px)"
                            padding="35px"
                            direction="row"
                            margin="0 auto auto 0"
                            alignSelf="flex-start"
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            {state.moments &&
                                state.moments.map((item, index) => (
                                    <LinkItem to={`/ocasioes/${currentSubCategory}/${item.slug}`} key={index}>
                                        {item.name}
                                    </LinkItem>
                                ))}
                        </Flex>
                        <MenuBackground>
                            <h2>
                                estilo em <br />
                                todos os
                                <br />
                                momentos
                            </h2>
                            <img src={BackgroundMenuOcasioes} alt="Categorias" />
                        </MenuBackground>
                    </>
                );

            default:
                break;
        }
    };

    const renderMenuContent = () => {
        switch (currentMenuItem) {
            case "compre":
                return (
                    <>
                        <Flex
                            direction="column"
                            padding="47px 80px 47px 33px"
                            margin="0 0 auto 0"
                            alignItems="flex-end"
                            justify-content="flex-start"
                        >
                            <SubMenuItem
                                to={`/categorias/${currentSubCategory}/novidades`}
                                onClick={() => {
                                    handleCloseMenu();
                                }}
                            >
                                Todos produtos
                                <span />
                            </SubMenuItem>

                            <SubMenuItem
                                to={`/nunca-usados`}
                                onClick={() => {
                                    handleCloseMenu();
                                }}
                            >
                                Nunca usados
                                <span />
                            </SubMenuItem>

                            {["Categorias", "Marcas", "Ocasiões"].map((item, index) =>
                                currentSubCategory === "objetos" && item === "Ocasiões" ? null : (
                                    <SubMenuItem
                                        key={`subMenuItem_${index}`}
                                        active={currentSubMenuItem === item.toLowerCase()}
                                        onMouseEnter={() => setCurrentSubMenuItem(item.toLowerCase())}
                                    >
                                        {item}
                                        <span />
                                    </SubMenuItem>
                                )
                            )}

                            <SubMenuItem
                                to={`/categorias/${currentSubCategory}/promocoes`}
                                onClick={() => {
                                    handleCloseMenu();
                                }}
                            >
                                Promoções
                                <span />
                            </SubMenuItem>
                            <SubMenuItem
                                to={"/lojas-influencers"}
                                onClick={() => {
                                    handleCloseMenu();
                                }}
                            >
                                Lojas Influencers
                                <span />
                            </SubMenuItem>
                            <SubMenuItem
                                to={`/outlet`}
                                onClick={() => {
                                    handleCloseMenu();
                                }}
                            >
                                Outlet
                                <span />
                            </SubMenuItem>
                        </Flex>
                        <MenuContent direction="column" compraMenu>
                            {renderCompraMenu()}
                        </MenuContent>
                    </>
                );

            //             case "quero vender":
            //                 return (
            //                     <>
            //                         <Flex
            //                             direction="column"
            //                             padding="47px 33px"
            //                             margin="0 0 auto 0"
            //                             alignItems="flex-end"
            //                             justify-content="flex-start"
            //                         >
            //                             <MenuTitle onClick={() => navigate("/desapegue")}>
            //                                 {`Venda suas
            //                                 roupas com
            //                                  a Re Petit`}
            //                             </MenuTitle>
            //                         </Flex>
            //                         <MenuContent direction="column">
            //                             <Flex
            //                                 width="100%"
            //                                 padding="15px 0 0 35px"
            //                                 justifyContent="space-between"
            //                                 alignItems="flex-start"
            //                                 boxSizing="border-box"
            //                             >
            //                                 <TextColumn minWidth="595px">
            //                                     <h3>Como funciona?</h3>
            //                                     <Flex>
            //                                         <span>
            //                                             {`Nada melhor do que a sensação de desapegar do que não circula mais em casa, abrir espaço para novo e ainda ganhar uma renda extra.

            // Vender suas peças na Re Petit é simples. É só selecionar as roupas, acessórios, livros e brinquedos infantis que estão bem conservados ou sem uso. Você se cadastra no site e envia tudo para a Re Petit!

            // `}
            //                                         </span>
            //                                         <span style={{ marginBottom: "auto" }}>
            //                                             {`Nós cuidamos de tudo. Produzimos as fotos e realizamos a de venda para você. Vendeu faturou! Use seu créditos para comprar com  desconto de 10% no site ou resgate seu dinheiro.`}
            //                                         </span>
            //                                     </Flex>
            //                                 </TextColumn>
            //                                 <TextColumn>
            //                                     <h3>Por que desapegar?</h3>
            //                                     <span>
            //                                         {`Um mundo sustentável começa por pequenas ações que se transformam em grandes hábitos. Desapegar dos itens infantis que não circulam mais em casa é uma delas!

            // Vem fazer parte desse movimento: venda suas peças especiais para outras mães e pais. Você poupa os recursos naturais, ganha uma renda extra e ainda dá um ótimo exemplo para os seus filhos. Afinal, é de pequeno que a mudança começa!
            // `}
            //                                     </span>
            //                                 </TextColumn>
            //                             </Flex>
            //                             <MenuFooter>
            //                                 <FooterButton onClick={() => navigate("/desapegue")}>
            //                                     <span>desapegue já</span>
            //                                     <MoreIcon />
            //                                 </FooterButton>
            //                             </MenuFooter>
            //                         </MenuContent>
            //                     </>
            //                 );

            // case "peça seu box":
            //     return (
            //         <>
            //             <Flex
            //                 direction="column"
            //                 padding="47px 33px"
            //                 margin="0 0 auto 0"
            //                 alignItems="flex-end"
            //                 justify-content="flex-start"
            //             >
            //                 <MenuTitle onClick={() => navigate("/box")}>{`Peça seu BOX
            //                 personalizado`}</MenuTitle>
            //             </Flex>
            //             <MenuContent direction="column">
            //                 <Flex width="calc(100% - 35px)" padding="15px 0 15px 35px" justifyContent="space-between">
            //                     {state.boxes &&
            //                         state.boxes.map((item, index) => (
            //                             <BoxItem
            //                                 key={item.slug}
            //                                 title={item.title}
            //                                 description={item.home_description}
            //                                 button="Saiba Mais"
            //                                 background={item.image_url}
            //                                 small
            //                                 coloredOnHover
            //                                 onClick={() => navigate(`/box#${item.slug}`)}
            //                                 buttonAction={() => navigate(`/box#${item.slug}`)}
            //                             />
            //                         ))}
            //                 </Flex>
            //                 <MenuFooter>
            //                     <FooterButton onClick={() => navigate("/box")}>
            //                         <span>saiba mais</span>
            //                         <MoreIcon />
            //                     </FooterButton>
            //                 </MenuFooter>
            //             </MenuContent>
            //         </>
            //     );

            // case "blog":
            //     return (
            //         <>
            //             <Flex
            //                 direction="column"
            //                 padding="47px 33px"
            //                 margin="0 0 auto 0"
            //                 alignItems="flex-end"
            //                 justify-content="flex-start"
            //             >
            //                 <MenuTitle onClick={() => navigate("/blog")}>{`Ideias para
            //                 circular e
            //                 inspirar!`}</MenuTitle>
            //             </Flex>
            //             <MenuContent direction="column">
            //                 <Flex
            //                     width="calc(100% - 35px)"
            //                     padding="15px 0 15px 35px"
            //                     alignItems="flex-start"
            //                     justifyContent="space-between"
            //                 >
            //                     <Flex alignItems="flex-start" direction="column" flex="1">
            //                         {state.blog_posts &&
            //                             state.blog_posts.slice(0, 2).map((item, index) => (
            //                                 <TextColumn
            //                                     key={item.slug}
            //                                     onClick={() => navigate(`/blog/post/${item.slug}`)}
            //                                     horizontal
            //                                 >
            //                                     <img
            //                                         width="125px"
            //                                         height="125px"
            //                                         src={item.image_url + "?w=300&h=300&bg=FFFFFF&auto=format,compress"}
            //                                         alt="Blog Background"
            //                                     />
            //                                     <div>
            //                                         <h3>{item.title}</h3>
            //                                         <span>
            //                                             {item.summary}
            //                                             <strong> Saiba mais</strong>
            //                                         </span>
            //                                     </div>
            //                                 </TextColumn>
            //                             ))}
            //                     </Flex>
            //                     {state.blog_posts &&
            //                         state.blog_posts.slice(2, 3).map((item, index) => (
            //                             <TextColumn
            //                                 key={item.slug}
            //                                 onClick={() => navigate(`/blog/post/${item.slug}`)}
            //                                 flex="0"
            //                             >
            //                                 <img
            //                                     width="280px"
            //                                     height="125px"
            //                                     src={item.image_url}
            //                                     alt="Blog Background"
            //                                 />
            //                                 <div>
            //                                     <h3>{item.title}</h3>
            //                                     <span>
            //                                         {item.summary}
            //                                         <strong> Saiba mais</strong>
            //                                     </span>
            //                                 </div>
            //                             </TextColumn>
            //                         ))}
            //                 </Flex>
            //                 <MenuFooter>
            //                     <FooterButton onClick={() => navigate("/blog")}>
            //                         <span>inspire-se com a repetit</span>
            //                         <MoreIcon />
            //                     </FooterButton>
            //                 </MenuFooter>
            //             </MenuContent>
            //         </>
            //     );

            default:
                return null;
        }
    };

    const showQuizButton = () => {
        // let {
        //     location: { pathname },
        // } = location;

        return location.pathname === "/";
    };

    return isMobile ? (
        <></>
    ) : (
        <>
            <Container onMouseLeave={handleCloseMenu}>
                <Content menuOpen={menuOpen}>
                    <ButtonsRow>
                        <Flex>
                            {navigationItems &&
                                navigationItems.map((item, index) => (
                                    <MenuItem
                                        key={`menuItem_${index}`}
                                        active={currentMenuItem === item.label.toLowerCase() || item.active}
                                        highlight={item.highlight}
                                        secondary={item.secondary}
                                        borderradius={item.borderradius}
                                        onClick={item.action ? item.action : null}
                                        onMouseEnter={() => handleClickMenuItem(item.label.toLowerCase())}
                                    >
                                        {item.label}
                                    </MenuItem>
                                ))}
                        </Flex>
                    </ButtonsRow>
                </Content>
                <Flex position="relative" margin="0 auto" width="1222px">
                    {showQuizButton() && <QuizButton onClick={() => null} />}
                </Flex>

                {state && menuOpen && currentMenuItem && (
                    <Menu>
                        {currentMenuItem === "compre" && (
                            <SubButtonsRow>
                                {[
                                    {
                                        value: "menina",
                                        name: "MENINA | 2 - 14 ANOS",
                                    },
                                    {
                                        value: "menino",
                                        name: "MENINO | 2 - 14 ANOS",
                                    },
                                    {
                                        value: "bebe-menino",
                                        name: "BEBÊ MENINO | 0 MESES - 2 ANOS",
                                    },
                                    {
                                        value: "bebe-menina",
                                        name: "BEBÊ MENINA | O MESES - 2 ANOS",
                                    },
                                    {
                                        value: "objetos",
                                        name: "Objetos e Brinquedos",
                                    },
                                ].map((item, index) => (
                                    <MenuItem
                                        key={`menuItem_${index}`}
                                        className={currentSubCategory === item.value.toLowerCase() ? "active" : ""}
                                        onMouseEnter={() => handleClickSubMenuItem(item.value.toLowerCase())}
                                    >
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </SubButtonsRow>
                        )}
                        {(currentMenuItem !== "compre" || currentSubCategory) && (
                            <MenuContainer>
                                <Content justifyContent="center" direction="row" maxWidth="1222px">
                                    {renderMenuContent()}
                                </Content>
                            </MenuContainer>
                        )}
                    </Menu>
                )}

                {props.subNavigation && <SubButtonsRow>{props.subNavigation}</SubButtonsRow>}
            </Container>
        </>
    );
}

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    background-color: #fefefe;
    z-index: -1;
    height: 50px;

    ${isMobileMediaQuery} {
        display: none !important;
    }
`;

const Menu = styled.div`
    width: 100%;
    height: auto;
    position: absolute;
    top: 100%;
    z-index: 1;
    box-shadow: 0px 8px 6px rgba(0, 0, 0, 0.16);

    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 146px);
`;

const Content = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: ${(props) => (props.direction ? props.direction : "column")};
    justify-content: ${(props) => (props.justifyContent ? props.justifyContent : "space-between")};
    align-items: center;
    max-width: ${(props) => props.maxWidth && props.maxWidth};
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;
    z-index: 2;

    ${({ menuOpen }) =>
        menuOpen &&
        css`
            box-shadow: 0px 1px 2px 0px rgba(52, 52, 59, 0.15);
        `}
`;

const ButtonsRow = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    max-width: 1222px;
    flex-wrap: wrap;
    box-sizing: border-box;

    @media screen and (min-width: 760px) and (max-width: 1222px) {
        padding: 0 8px;
    }
`;

const MenuItem = styled.div`
    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    text-transform: uppercase;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.82;
    letter-spacing: 2.2px;
    text-align: left;
    color: #4d4d59;
    padding: 8px 10px;
    padding-bottom: 5px;
    border-bottom: 3px solid transparent;
    cursor: pointer;

    :first-child {
        padding-left: 0px;
    }

    :not(:last-child) {
        margin-right: 20px;
    }

    :hover {
        border-bottom: 3px solid #e4858c;
    }

    ${(props) => props.active && "border-bottom: 3px solid #e4858c;"}
    ${(props) => props.highlight && "color: #e4858c"}

    ${(props) =>
        props.secondary &&
        css`
            color: #fff;
            background-color: #7ca8af;

            :hover {
                border-bottom: 3px solid transparent;
            }
        `}
    
    ${(props) =>
        props.borderradius &&
        css`
            border-radius: 16px;
        `}
`;

const MenuContainer = styled.div`
    width: 100%;
    height: auto;
    background-color: #f7f9f9;
`;

export const SubButtonsRow = styled.div`
    width: 100%;
    min-height: 50px;
    background-color: #34343b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & > ${MenuItem} {
        object-fit: contain;
        font-family: "SackersGothicStd";
        font-size: 11px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.82;
        letter-spacing: 2.2px;
        text-align: left;
        color: #8c8ca1;
        border: 0px;
        padding-bottom: 8px;

        &.active {
            color: #e4858c;
        }

        &:hover {
            color: #e4858c;
        }
    }

    ${({ fixed, hide }) =>
        fixed &&
        hide &&
        css`
            position: sticky;
            top: 0px;
            left: 0px;
            right: 0px;
        `}
`;

const MenuContent = styled.div`
    background-color: #fff;
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: ${({ direction }) => (direction ? direction : "row")};
    justify-content: center;
    align-items: center;
    align-self: flex-start;

    ${(props) => props.compraMenu && "min-height: 400px;"}

    ::after {
        content: "";
        width: calc(100vw - 1222px);
        height: 100%;
        left: 100%;
        background: #fff;
        position: absolute;
        bottom: 0px;
    }
`;

const MenuTitle = styled.span`
    font-family: "DMSerifDisplay";
    font-size: 32px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: normal;
    text-align: right;
    color: #34343b;
    min-width: 200px;
    max-width: 250px;
    white-space: pre-line;

    ${(props) =>
        props.onClick &&
        css`
            cursor: pointer;

            :hover {
                color: #e4858c;
            }
        `}

    ${({ sub }) =>
        sub &&
        `
        margin-top: 36px;
        font-family: 'DMSerifDisplay';
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal; 
        line-height: 1;
        letter-spacing: normal;
        text-align: right;
        color: #e4858c;
    `}
`;

const SubMenuItem = styled(Link)`
    position: relative;
    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 2.2px;
    text-align: right;
    margin-bottom: 2rem;
    color: ${(props) => (props.active ? "#34343b" : "#8c8ca1")};
    cursor: pointer;
    text-decoration: none;
    :hover {
        color: #34343b;
        font-weight: 600;
    }

    & > span {
        ${(props) =>
            props.active
                ? `
                position: absolute;
                top: calc(50% - 1.5px);
                transform: translateY(-50%);
                width: 25px;
                height: 3px;
                background-color: #e4858c;
                margin-left: 19px;
        `
                : `
        `}
    }
`;

export const TextColumn = styled.div`
    display: flex;
    flex-direction: ${(props) => (props.horizontal ? "row" : "column")};
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    margin: ${(props) => (props.margin ? props.margin : "0")};
    min-width: ${(props) => props.minWidth && props.minWidth};
    margin-bottom: 15px;
    flex: ${(props) => (props.flex ? props.flex : "1")};
    ${(props) =>
        props.horizontal &&
        `
        max-height: 125px;
    `}
    ${(props) => props.maxHeight && `max-height: ${props.maxHeight};`}
    ${(props) => props.onClick && `cursor:pointer;`}

    ${({ horizontal }) =>
        !horizontal &&
        css`
            & > h3 {
                padding-bottom: 19px;
                border-bottom: 1px solid #b4b4bd;
                width: 100%;
            }
            & ${Flex} > span {
                flex: 0.5;
                &:first-child {
                    margin-right: 61px;
                }
            }
        `}
        
    

    :not(:last-child) {
        ${(props) => (props.horizontal ? "margin-bottom: 20px;" : "margin-right: 41px;")}
    }

    & > h3,
    & > div > h3 {
        font-family: "DMSerifDisplay";
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: left;
        color: #34343b;
        margin: 0;
    }

    & > img:nth-child(1) {
        ${(props) => (!props.horizontal ? "margin-bottom: 27px;" : "margin-right: 19px;")}
    }
    & > img:nth-child(2) {
        ${(props) => !props.horizontal && "margin-top: 23px;"}
    }

    & > span,
    & > div > span {
        white-space: pre-line;
        display: inline-block;
        padding: 23px 0 16px 0;
        font-family: "Graphik";
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        text-align: left;
        color: #676777;
    }

    & > hr {
        width: 27px;
        border: none;
        border-top: 3px solid #f3808b;
        margin: 0;
    }

    & img {
        object-fit: cover;
    }
`;

export const MenuFooter = styled.div`
    margin-top: auto;
    margin-bottom: 0px;
    border-top: 1px solid #eff0f1;
    width: 100%;
    padding: 15px 0 15px 40px;
    box-sizing: border-box;
`;

export const FooterButton = styled.button`
    padding: 0;
    display: flex;
    background-color: transparent;
    border: none;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    cursor: pointer;

    & > span {
        font-family: "SackersGothicStd";
        font-size: 12px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-transform: uppercase;
        text-align: right;
        color: #34343b;
        margin-right: 14px;
    }
`;

const MenuBackground = styled.div`
    top: 0px;
    right: -72px;
    position: absolute;
    margin-left: auto;
    display: flex;
    padding: 30px;
    z-index: 1;
    height: calc(100% - 60px);
    h2 {
        position: absolute;
        top: 85px;
        left: 0px;
        max-width: 50%;

        font-family: "SackersGothicStd";
        font-size: 21px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.52;
        letter-spacing: 4.2px;
        text-align: left;
        color: #34343b;
    }
    img {
        object-fit: cover;
    }
`;
const LinkItem = styled(Link)`
    text-decoration: none;
    width: 50%;
    margin: 0;
    font-family: "Graphik";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.86;
    letter-spacing: 0.28px;
    text-align: left;
    color: #34343b;
    cursor: pointer;

    :hover {
        color: #e4858c;
    }
`;

export default NavigationMenu;
