import React, { useState } from "react";
import styled from "styled-components";
import { openPrivacyNTerms } from "../../helpers";

import { Flex, isMobile, isMobileMediaQuery } from "../../helpers/styles";
import Button from "../Button";
import ButtonLink from "../ButtonLink";

const CookiesModal = () => {
    const [showModal, setShowModal] = useState(
        (localStorage.getItem("accepted_cookies") || false).toString() !==
            "true"
    );

    const handleAcceptCookies = () => {
        setShowModal(false);
        localStorage.setItem("accepted_cookies", "true");
    };

    return !showModal ? null : (
        <>
            <Backdrop />
            <Container>
                <Flex
                    padding={isMobile ? "32px 20px" : "24px 42px"}
                    maxWidth={isMobile ? "100%" : "1222px"}
                    boxSizing="border-box"
                >
                    <p>
                        Nós usamos cookies e outras tecnologias semelhantes para
                        melhorar a sua experiência em nossos serviços,
                        personalizar publicidade e recomendar conteúdo de seu
                        interesse. Ao utilizar nossos serviços, você concorda
                        com tal monitoramento. Conheça nossa{" "}
                        <ButtonLink
                            onClick={() => openPrivacyNTerms("privacy")}
                        >
                            Política de Privacidade
                        </ButtonLink>{" "}
                        e{" "}
                        <ButtonLink onClick={() => openPrivacyNTerms("terms")}>
                            Termos de Uso
                        </ButtonLink>
                        .
                    </p>

                    <Button
                        width={isMobile && "100%"}
                        dark
                        filled
                        margin={isMobile ? "20px 0 0 0" : "0"}
                        onClick={handleAcceptCookies}
                    >
                        Prosseguir
                    </Button>
                </Flex>
            </Container>
        </>
    );
};

const Container = styled(Flex)`
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: #7ba6ad;
    z-index: 20;

    flex-direction: row;
    justify-content: center;
    align-items: center;

    p {
        margin: 0px;
        color: #fff;
        font-size: 14px;
        max-width: 80%;
        margin-right: auto;
        line-height: 1.2;

        a {
            color: #fff;
            font-weight: 600;
        }

        ${isMobileMediaQuery} {
            max-width: 100%;
        }
    }
`;

const Backdrop = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.55);
`;

export default CookiesModal;
