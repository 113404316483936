export const SHOW_MODAL = "SHOW_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export const show = (payload) => {
    return {
        type: SHOW_MODAL,
        payload,
    };
};

export const close = () => {
    return {
        type: CLOSE_MODAL,
    };
};
