import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Flex, isMobileMediaQuery, isMobile } from "../../helpers/styles";
import Button from "../Button";

function BlogItem({
    padding,
    background,
    category,
    title,
    description,
    author,
    button,
    action,
    link,
}) {
    return (
        <Container onClick={action} to={link}>
            <BlogImage src={`${background}?w=384&h=230&bg=FFFFFF&auto=format,compress`} />

            <Flex
                flex="1"
                direction="column"
                width={isMobile ? "calc(100% - 40px)" : "100%"}
                justifyContent="flex-start"
                alignItems="flex-start"
                padding={padding ? padding : isMobile && "6px 20px"}
            >
                <Category>{category}</Category>
                <Title>{title}</Title>
                {description && (
                    <Description>
                        {description} <strong>Ler Mais</strong>
                    </Description>
                )}
                {button && <Button dark value={button} />}
                <Separator />
                <Author>
                    por <span>@{author}</span>
                </Author>
            </Flex>
        </Container>
    );
}

const Title = styled.h2`
    margin: 17px 0 19px 0;
    font-size: 32px;
    line-height: 0.75;
    font-weight: normal;
    text-align: left;
    color: #34343b;
`;

const Container = styled(Link)`
    text-decoration: none;
    background-image: url("${({ background }) => background}");
    background-position: 50% 0%;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 400px;
    min-width: 384px;
    width: auto;
    margin-bottom: 48px;
    flex: 0;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: 1rem;

    ${(props) => (props.onClick || props.link) && "cursor: pointer;"}

    ${isMobileMediaQuery} {
        width: 100%;
        flex: 1;
        margin: 0px;
        padding: 0px;
        min-width: initial;

        :not(:last-child) {
            margin: 0px;
            margin-bottom: 1rem;
        }
    }
`;

const BlogImage = styled.div`
    width: 100%;
    height: 230px;
    min-height: 230px;
    margin-bottom: 1rem;
    background-image: url(${({ src }) => src});
    background-position: 50% 0%;
    background-size: cover;
    background-repeat: no-repeat;
`;

const Category = styled.span`
    font-family: "SackersGothicStd";
    color: #e4858c;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 900;
    line-height: 1.91;
    letter-spacing: 2.2px;
    text-align: left;
`;

const Description = styled.p`
    margin: 0;
    margin-bottom: 18px;
    font-family: "Graphik";
    font-size: 15px;
    line-height: 1.4;
    text-align: left;
    color: #676777;

    & > strong {
        font-weight: 600;
        color: #34343b;
    }
`;

const Separator = styled.hr`
    border: 0px;
    border-top: 1px solid #ccc;
    width: 100%;
    padding: 0px;
    margin-top: auto;
`;

const Author = styled.div`
    color: #676777;
    font-family: "Graphik";
    font-size: 15px;
    line-height: 1.4;
    text-align: left;
    margin-right: auto;
    margin-top: 0.5rem;

    & > span {
        color: #e4858c;
    }
`;

export default BlogItem;
