import React from "react";
import styled from "styled-components";

import { capitalize } from "../../helpers";
import { Flex, isMobile } from "./../../helpers/styles";

import CheckBox from "../../components/Inputs/CheckBox";
import QuizSelectBox from "../../components/QuizSelectBox";
import TextArea from "../../components/Inputs/TextArea";
import ColorSelection from "../../components/ColorSelection";
import MobileBrandsList from "./MobileBrandsList";
import { SectionTitle } from "./index";

import QuizBody from "../../assets/images/quiz-body.png";
import QuizCamisa from "../../assets/images/quiz-camisa.png";
import QuizCasaco from "../../assets/images/quiz-casaco.png";
import QuizCamisetaLonga from "../../assets/images/quiz-camiseta-longa.png";
import QuizCamisaCurta from "../../assets/images/quiz-camiseta-curta.png";
import QuizPolo from "../../assets/images/quiz-polo.png";
import QuizRegata from "../../assets/images/quiz-regata.png";

import QuizShort from "../../assets/images/quiz-short.png";
import QuizCalcaJeans from "../../assets/images/quiz-calca-jeans.png";
import QuizCalcaMoletom from "../../assets/images/quiz-calca-moletom.png";
import QuizCalcaLegging from "../../assets/images/quiz-calca-legging.png";
import QuizCalcaVeludo from "../../assets/images/quiz-calca-veludo.png";
import QuizSaia from "../../assets/images/quiz-saia.png";

import QuizVestido from "../../assets/images/quiz-vestido.png";
import QuizMacacao from "../../assets/images/quiz-macacao.png";
import QuizConjunto from "../../assets/images/quiz-conjunto.png";
import QuizPijama from "../../assets/images/quiz-pijama.png";
import QuizPraia from "../../assets/images/quiz-praia-2.png";
import QuizJardineira from "../../assets/images/quiz-jardineira.png";
import QuizMaternidade from "../../assets/images/quiz-maternidade.png";

import QuizBota from "../../assets/images/quiz-bota.png";
import QuizSapato from "../../assets/images/quiz-sapato.png";
import QuizSandalia from "../../assets/images/quiz-sandalia.png";
import QuizSapatilha from "../../assets/images/quiz-sapatilha.png";
import QuizTenis from "../../assets/images/quiz-tenis.png";
import QuizChinelo from "../../assets/images/quiz-chinelo.png";

import QuizFantasia from "../../assets/images/quiz-fantasia.png";
import QuizBrinquedo from "../../assets/images/quiz-brinquedo.png";
import QuizMeia from "../../assets/images/quiz-meia.png";
import QuizMeicaCalca from "../../assets/images/quiz-meia-calca.png";
import QuizCabelo from "../../assets/images/quiz-cabelo.png";
import QuizNaninha from "../../assets/images/quiz-naninha.png";
import QuizChupeta from "../../assets/images/quiz-chupeta.png";
import QuizMamadeira from "../../assets/images/quiz-mamadeira.png";
import QuizBabador from "../../assets/images/quiz-babador.png";
import QuizCopo from "../../assets/images/quiz-copo.png";
import QuizLuvas from "../../assets/images/quiz-luvas.png";
import QuizGorro from "../../assets/images/quiz-gorro.png";
import QuizChapeu from "../../assets/images/quiz-chapeu.png";
import QuizOculos from "../../assets/images/quiz-oculos.png";
import QuizCachecol from "../../assets/images/quiz-cachecol.png";

import QuizGosta from "../../assets/images/quiz-gosta.png";
import QuizNaoGosta from "../../assets/images/quiz-nao-gosta.png";

const InfosContent = ({
    values,
    setValues,
    handleChange,
    isChecked,
    handleSelectItem,
    colors,
    delivery,
}) => {
    const brands = [
        "Adoro todas!",
        "GAP",
        "Oskhkoh",
        "Silmara",
        "Trousseau",
        "Baby Cottons",
        "Green",
        "Paola Bimbi",
        "Tip Top",
        "Zara",
        "Bonpoint",
        "Jacadi",
        "Petit Bateau",
        "Tip Toey Joey",
        "Reserva Mini",
        "Carters",
        "Janie & Jack",
        "Polo Raph Lauren",
        "Tommy Hilfiger",
        "Touché Bebe",
        "Fabula",
        "Nike",
    ];

    const handleSelectColors = (id, checked) => {
        let newValues = { ...values };

        if (checked === true) {
            newValues.unliked_colors = [...newValues.unliked_colors, id];
        } else {
            newValues.unliked_colors = newValues.unliked_colors.filter(
                (item) => item !== id
            );
        }

        setValues(newValues);
    };

    return (
        <Container>
            <>
                <SectionTitle name="top_needs">
                    3.1 quais peças de cima precisa receber no box?
                </SectionTitle>

                <Flex width="100%" margin="0px 0px 33px 0px">
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Body"
                        value={isChecked("top_needs", "Body")}
                        onChange={(e) =>
                            handleSelectItem("top_needs", "Body", e)
                        }
                    >
                        <img src={QuizBody} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Camisa"
                        value={isChecked("top_needs", "Camisa")}
                        onChange={(e) =>
                            handleSelectItem("top_needs", "Camisa", e)
                        }
                    >
                        <img src={QuizCamisa} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        title="Casaco"
                        value={isChecked("top_needs", "Casaco")}
                        onChange={(e) =>
                            handleSelectItem("top_needs", "Casaco", e)
                        }
                    >
                        <img src={QuizCasaco} alt="" />
                    </QuizSelectBox>
                </Flex>

                <Flex width="100%" margin="0px 0px 33px 0px">
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Camiseta"
                        subtitle="(manga longa)"
                        value={isChecked("top_needs", "Camiseta (manga longa)")}
                        onChange={(e) =>
                            handleSelectItem(
                                "top_needs",
                                "Camiseta (manga longa)",
                                e
                            )
                        }
                    >
                        <img src={QuizCamisetaLonga} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Camiseta"
                        subtitle="(manga curta)"
                        value={isChecked("top_needs", "Camiseta (manga curta)")}
                        onChange={(e) =>
                            handleSelectItem(
                                "top_needs",
                                "Camiseta (manga curta)",
                                e
                            )
                        }
                    >
                        <img src={QuizCamisaCurta} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        title="Polo"
                        value={isChecked("top_needs", "Polo")}
                        onChange={(e) =>
                            handleSelectItem("top_needs", "Polo", e)
                        }
                    >
                        <img src={QuizPolo} alt="" />
                    </QuizSelectBox>
                </Flex>

                <Flex width="100%" margin="0px 0px 45px 0px">
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Regata"
                        value={isChecked("top_needs", "Regata")}
                        onChange={(e) =>
                            handleSelectItem("top_needs", "Regata", e)
                        }
                    >
                        <img src={QuizRegata} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Moletom"
                        value={isChecked("top_needs", "Moletom")}
                        onChange={(e) =>
                            handleSelectItem("top_needs", "Moletom", e)
                        }
                    >
                        <img src={QuizCasaco} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        title="Malha"
                        value={isChecked("top_needs", "Malha")}
                        onChange={(e) =>
                            handleSelectItem("top_needs", "Malha", e)
                        }
                    >
                        <img src={QuizCamisetaLonga} alt="" />
                    </QuizSelectBox>
                </Flex>

                <Flex>
                    <CheckBox
                        margin="0px auto 0px 0px"
                        secondary
                        label="Não precisamos de nenhuma dessas peças no momento."
                        checked={
                            values.top_needs && values.top_needs.length === 0
                        }
                        onChange={(e) =>
                            e.target.checked === true
                                ? setValues({ ...values, top_needs: [] })
                                : setValues({ ...values, top_needs: undefined })
                        }
                    />
                </Flex>
            </>

            <>
                <SectionTitle name="bottom_needs">
                    3.2 e quais peça debaixo não dá para ficar sem?
                </SectionTitle>

                <Flex width="100%" margin="0px 0px 33px 0px">
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Short"
                        value={isChecked("bottom_needs", "Short")}
                        onChange={(e) =>
                            handleSelectItem("bottom_needs", "Short", e)
                        }
                    >
                        <img src={QuizShort} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Calça"
                        subtitle="(jeans)"
                        value={isChecked("bottom_needs", "Calça (jeans)")}
                        onChange={(e) =>
                            handleSelectItem("bottom_needs", "Calça (jeans)", e)
                        }
                    >
                        <img src={QuizCalcaJeans} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        title="Calça"
                        subtitle="(moletom)"
                        value={isChecked("bottom_needs", "Calça (moletom)")}
                        onChange={(e) =>
                            handleSelectItem(
                                "bottom_needs",
                                "Calça (moletom)",
                                e
                            )
                        }
                    >
                        <img src={QuizCalcaMoletom} alt="" />
                    </QuizSelectBox>
                </Flex>

                <Flex width="100%" margin="0px 0px 33px 0px">
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Calça"
                        subtitle="(legging)"
                        value={isChecked("bottom_needs", "Calça (legging)")}
                        onChange={(e) =>
                            handleSelectItem(
                                "bottom_needs",
                                "Calça (legging)",
                                e
                            )
                        }
                    >
                        <img src={QuizCalcaLegging} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Calça"
                        subtitle="(veludo)"
                        value={isChecked("bottom_needs", "Calça (veludo)")}
                        onChange={(e) =>
                            handleSelectItem(
                                "bottom_needs",
                                "Calça (veludo)",
                                e
                            )
                        }
                    >
                        <img src={QuizCalcaVeludo} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        title="Saia"
                        value={isChecked("bottom_needs", "Saia")}
                        onChange={(e) =>
                            handleSelectItem("bottom_needs", "Saia", e)
                        }
                    >
                        <img src={QuizSaia} alt="" />
                    </QuizSelectBox>
                </Flex>

                <Flex>
                    <CheckBox
                        margin="0px auto 0px 0px"
                        secondary
                        label="Não precisamos de nenhuma dessas peças no momento."
                        checked={
                            values.bottom_needs &&
                            values.bottom_needs.length === 0
                        }
                        onChange={(e) =>
                            e.target.checked === true
                                ? setValues({ ...values, bottom_needs: [] })
                                : setValues({
                                      ...values,
                                      bottom_needs: undefined,
                                  })
                        }
                    />
                </Flex>
            </>

            <>
                <SectionTitle name="unliked_fabrics">
                    3.3 tem algum tecido que {values.child_name || "..."} não
                    gosta?
                </SectionTitle>

                <CardBox
                    padding={isMobile ? "17px 20px 0px 20px" : undefined}
                    width="100%"
                    justifyContent="flex-start"
                >
                    Pode marcar mais de uma opção.
                    <Flex
                        flex="1"
                        width={isMobile ? "100%" : undefined}
                        minWidth={isMobile ? "100%" : undefined}
                        margin={isMobile ? "16px 0 0 0" : "auto 40px auto 18px"}
                        justifyContent="space-between"
                    >
                        <CheckBox
                            name="jeans"
                            label="Jeans"
                            special
                            radioIcon
                            margin={isMobile ? "0 0 20px 0" : undefined}
                            value={isChecked("unliked_fabrics", "Jeans")}
                            onChange={(e) =>
                                handleSelectItem(
                                    "unliked_fabrics",
                                    "Jeans",
                                    e.target.checked
                                )
                            }
                        />
                        <CheckBox
                            name="plush"
                            label="Plush"
                            special
                            radioIcon
                            margin={isMobile ? "0 0 20px 0" : undefined}
                            value={isChecked("unliked_fabrics", "Plush")}
                            onChange={(e) =>
                                handleSelectItem(
                                    "unliked_fabrics",
                                    "Plush",
                                    e.target.checked
                                )
                            }
                        />
                        <CheckBox
                            name="veludo"
                            label="Veludo"
                            special
                            radioIcon
                            margin={isMobile ? "0 0 20px 0" : undefined}
                            value={isChecked("unliked_fabrics", "Veludo")}
                            onChange={(e) =>
                                handleSelectItem(
                                    "unliked_fabrics",
                                    "Veludo",
                                    e.target.checked
                                )
                            }
                        />
                        <CheckBox
                            name="nylon"
                            label="Nylon"
                            special
                            radioIcon
                            margin={isMobile ? "0 0 20px 0" : undefined}
                            value={isChecked("unliked_fabrics", "Nylon")}
                            onChange={(e) =>
                                handleSelectItem(
                                    "unliked_fabrics",
                                    "Nylon",
                                    e.target.checked
                                )
                            }
                        />
                        <CheckBox
                            name="tricot"
                            label="Tricot"
                            special
                            radioIcon
                            margin={isMobile ? "0 0 20px 0" : undefined}
                            value={isChecked("unliked_fabrics", "Tricot")}
                            onChange={(e) =>
                                handleSelectItem(
                                    "unliked_fabrics",
                                    "Tricot",
                                    e.target.checked
                                )
                            }
                        />
                    </Flex>
                </CardBox>
            </>

            <>
                <SectionTitle name="essential_pieces">
                    3.4 quais peças são essenciais no box?
                </SectionTitle>

                <Flex width="100%" margin="0px 0px 33px 0px">
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Vestido"
                        value={isChecked("essential_pieces", "Vestido")}
                        onChange={(e) =>
                            handleSelectItem("essential_pieces", "Vestido", e)
                        }
                    >
                        <img src={QuizVestido} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Macacão"
                        value={isChecked("essential_pieces", "Macacão")}
                        onChange={(e) =>
                            handleSelectItem("essential_pieces", "Macacão", e)
                        }
                    >
                        <img src={QuizMacacao} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        title="Conjunto"
                        value={isChecked("essential_pieces", "Conjunto")}
                        onChange={(e) =>
                            handleSelectItem("essential_pieces", "Conjunto", e)
                        }
                    >
                        <img src={QuizConjunto} alt="" />
                    </QuizSelectBox>
                </Flex>

                <Flex width="100%" margin="0px 0px 33px 0px">
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Pijama"
                        value={isChecked("essential_pieces", "Pijama")}
                        onChange={(e) =>
                            handleSelectItem("essential_pieces", "Pijama", e)
                        }
                    >
                        <img src={QuizPijama} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Praia"
                        subtitle="(maiô/biquini/sunga)"
                        value={isChecked(
                            "essential_pieces",
                            "Praia (maiô/biquini/sunga)"
                        )}
                        onChange={(e) =>
                            handleSelectItem(
                                "essential_pieces",
                                "Praia (maiô/biquini/sunga)",
                                e
                            )
                        }
                    >
                        <img src={QuizPraia} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        title="Casaco"
                        value={isChecked("essential_pieces", "Casaco")}
                        onChange={(e) =>
                            handleSelectItem("essential_pieces", "Casaco", e)
                        }
                    >
                        <img src={QuizCasaco} alt="" />
                    </QuizSelectBox>
                </Flex>

                <Flex width="100%" margin="0px 0px 45px 0px">
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Jardineira"
                        value={isChecked("essential_pieces", "Jardineira")}
                        onChange={(e) =>
                            handleSelectItem(
                                "essential_pieces",
                                "Jardineira",
                                e
                            )
                        }
                    >
                        <img src={QuizJardineira} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Maternidade"
                        subtitle="(saída de maternidade)"
                        value={isChecked("essential_pieces", "Maternidade")}
                        onChange={(e) =>
                            handleSelectItem(
                                "essential_pieces",
                                "Maternidade",
                                e
                            )
                        }
                    >
                        <img src={QuizMaternidade} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        title="Moletom"
                        value={isChecked("essential_pieces", "Moletom")}
                        onChange={(e) =>
                            handleSelectItem("essential_pieces", "Moletom", e)
                        }
                    >
                        <img src={QuizCasaco} alt="" />
                    </QuizSelectBox>
                </Flex>

                <Flex>
                    <CheckBox
                        margin="0px auto 0px 0px"
                        secondary
                        label="Não precisamos de nenhuma dessas peças no momento."
                        checked={
                            values.essential_pieces &&
                            values.essential_pieces.length === 0
                        }
                        onChange={(e) =>
                            e.target.checked === true
                                ? setValues({ ...values, essential_pieces: [] })
                                : setValues({
                                      ...values,
                                      essential_pieces: undefined,
                                  })
                        }
                    />
                </Flex>
            </>

            <>
                <SectionTitle name="shoe_needs">
                    3.5 quais calçados{" "}
                    {values.child_gender === "male" ? "o" : "a"}{" "}
                    {values.child_name || "..."} espera no seu box re petit?
                </SectionTitle>

                <Flex width="100%" margin="0px 0px 33px 0px">
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Bota"
                        value={isChecked("shoe_needs", "Bota")}
                        onChange={(e) =>
                            handleSelectItem("shoe_needs", "Bota", e)
                        }
                    >
                        <img src={QuizBota} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Sapato"
                        value={isChecked("shoe_needs", "Sapato")}
                        onChange={(e) =>
                            handleSelectItem("shoe_needs", "Sapato", e)
                        }
                    >
                        <img src={QuizSapato} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        title="Sandália"
                        value={isChecked("shoe_needs", "Sandália")}
                        onChange={(e) =>
                            handleSelectItem("shoe_needs", "Sandália", e)
                        }
                    >
                        <img src={QuizSandalia} alt="" />
                    </QuizSelectBox>
                </Flex>

                <Flex width="100%" margin="0px 0px 33px 0px">
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Sapatilha"
                        value={isChecked("shoe_needs", "Sapatilha")}
                        onChange={(e) =>
                            handleSelectItem("shoe_needs", "Sapatilha", e)
                        }
                    >
                        <img src={QuizSapatilha} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Tênis"
                        value={isChecked("shoe_needs", "Tênis")}
                        onChange={(e) =>
                            handleSelectItem("shoe_needs", "Tênis", e)
                        }
                    >
                        <img src={QuizTenis} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        title="Chinelo"
                        value={isChecked("shoe_needs", "Chinelo")}
                        onChange={(e) =>
                            handleSelectItem("shoe_needs", "Chinelo", e)
                        }
                    >
                        <img src={QuizChinelo} alt="" />
                    </QuizSelectBox>
                </Flex>

                <Flex>
                    <CheckBox
                        margin="0px auto 0px 0px"
                        secondary
                        label="Não precisamos de nenhum desses calçados no momento."
                        checked={
                            values.shoe_needs && values.shoe_needs.length === 0
                        }
                        onChange={(e) =>
                            e.target.checked === true
                                ? setValues({ ...values, shoe_needs: [] })
                                : setValues({
                                      ...values,
                                      shoe_needs: undefined,
                                  })
                        }
                    />
                </Flex>
            </>

            <>
                <SectionTitle name="accessory_needs">
                    3.6 quais acessórios quer receber neste box re petit?
                </SectionTitle>

                <Flex width="100%" margin="0px 0px 33px 0px">
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Fantasia"
                        value={isChecked("accessory_needs", "Fantasia")}
                        onChange={(e) =>
                            handleSelectItem("accessory_needs", "Fantasia", e)
                        }
                    >
                        <img src={QuizFantasia} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Brinquedo"
                        value={isChecked("accessory_needs", "Brinquedo")}
                        onChange={(e) =>
                            handleSelectItem("accessory_needs", "Brinquedo", e)
                        }
                    >
                        <img src={QuizBrinquedo} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        title="Meia"
                        value={isChecked("accessory_needs", "Meia")}
                        onChange={(e) =>
                            handleSelectItem("accessory_needs", "Meia", e)
                        }
                    >
                        <img src={QuizMeia} alt="" />
                    </QuizSelectBox>
                </Flex>

                <Flex width="100%" margin="0px 0px 33px 0px">
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Meia-calça"
                        value={isChecked("accessory_needs", "Meia-calça")}
                        onChange={(e) =>
                            handleSelectItem("accessory_needs", "Meia-calça", e)
                        }
                    >
                        <img src={QuizMeicaCalca} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Cabelo"
                        value={isChecked("accessory_needs", "Cabelo")}
                        onChange={(e) =>
                            handleSelectItem("accessory_needs", "Cabelo", e)
                        }
                    >
                        <img src={QuizCabelo} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        title="Naninha"
                        value={isChecked("accessory_needs", "Naninha")}
                        onChange={(e) =>
                            handleSelectItem("accessory_needs", "Naninha", e)
                        }
                    >
                        <img src={QuizNaninha} alt="" />
                    </QuizSelectBox>
                </Flex>

                <Flex width="100%" margin="0px 0px 33px 0px">
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Chupeta"
                        value={isChecked("accessory_needs", "Chupeta")}
                        onChange={(e) =>
                            handleSelectItem("accessory_needs", "Chupeta", e)
                        }
                    >
                        <img src={QuizChupeta} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Mamadeira"
                        value={isChecked("accessory_needs", "Mamadeira")}
                        onChange={(e) =>
                            handleSelectItem("accessory_needs", "Mamadeira", e)
                        }
                    >
                        <img src={QuizMamadeira} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        title="Babador"
                        value={isChecked("accessory_needs", "Babador")}
                        onChange={(e) =>
                            handleSelectItem("accessory_needs", "Babador", e)
                        }
                    >
                        <img src={QuizBabador} alt="" />
                    </QuizSelectBox>
                </Flex>

                <Flex width="100%" margin="0px 0px 33px 0px">
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Copo"
                        value={isChecked("accessory_needs", "Copo")}
                        onChange={(e) =>
                            handleSelectItem("accessory_needs", "Copo", e)
                        }
                    >
                        <img src={QuizCopo} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Luvas"
                        value={isChecked("accessory_needs", "Luvas")}
                        onChange={(e) =>
                            handleSelectItem("accessory_needs", "Luvas", e)
                        }
                    >
                        <img src={QuizLuvas} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        title="Gorro"
                        value={isChecked("accessory_needs", "Gorro")}
                        onChange={(e) =>
                            handleSelectItem("accessory_needs", "Gorro", e)
                        }
                    >
                        <img src={QuizGorro} alt="" />
                    </QuizSelectBox>
                </Flex>

                <Flex width="100%" margin="0px 0px 33px 0px">
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Chapéu"
                        value={isChecked("accessory_needs", "Chapéu")}
                        onChange={(e) =>
                            handleSelectItem("accessory_needs", "Chapéu", e)
                        }
                    >
                        <img src={QuizChapeu} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Óculos"
                        value={isChecked("accessory_needs", "Óculos")}
                        onChange={(e) =>
                            handleSelectItem("accessory_needs", "Óculos", e)
                        }
                    >
                        <img src={QuizOculos} alt="" />
                    </QuizSelectBox>
                    <QuizSelectBox
                        heartType
                        justifyContent="flex-start"
                        padding="15px"
                        flex="1"
                        title="Cachecol"
                        value={isChecked("accessory_needs", "Cachecol")}
                        onChange={(e) =>
                            handleSelectItem("accessory_needs", "Cachecol", e)
                        }
                    >
                        <img src={QuizCachecol} alt="" />
                    </QuizSelectBox>
                </Flex>

                <Flex direction="column">
                    <CheckBox
                        margin="0px auto 20px 0px"
                        secondary
                        label="Não precisamos de nenhuma dessas peças no momento."
                        checked={
                            values.accessory_needs &&
                            values.accessory_needs.length === 0
                        }
                        onChange={(e) =>
                            e.target.checked === true
                                ? setValues({ ...values, accessory_needs: [] })
                                : setValues({
                                      ...values,
                                      accessory_needs: undefined,
                                  })
                        }
                    />
                </Flex>
            </>

            {colors !== undefined && colors.length > 0 && (
                <>
                    <SectionTitle>
                        3.7 tem alguma cor que{" "}
                        {values.child_gender === "male" ? "o" : "a"}{" "}
                        {values.child_name || "..."} não gosta?
                    </SectionTitle>

                    <CardBox justifyContent="flex-start">
                        {colors.map((item) => (
                            <ColorSelection
                                title={capitalize(item.name)}
                                key={item.id}
                                color={item.hex}
                                checked={
                                    values.unliked_colors &&
                                    values.unliked_colors.includes(item.id)
                                }
                                onChange={(checked) =>
                                    handleSelectColors(item.id, checked)
                                }
                            />
                        ))}
                    </CardBox>
                </>
            )}

            <>
                <SectionTitle name="likes_design">
                    3.8 estampas são bem-vindas?
                </SectionTitle>

                <Flex>
                    <QuizSelectBox
                        vertical={isMobile}
                        width={isMobile ? "calc(50% - 10px)" : "auto"}
                        minWidth="auto"
                        minHeight="215px"
                        justifyContent={isMobile ? "center" : "flex-start"}
                        flex="0.5"
                        margin={isMobile ? "0 20px 0 0" : "0 36px 0px 0px"}
                        title="Sim. Adora!"
                        value={isChecked("likes_design", "Sim")}
                        onChange={(e) =>
                            handleSelectItem("likes_design", "Sim", e)
                        }
                    >
                        <GirlBackground>
                            <img src={QuizGosta} alt="" />
                        </GirlBackground>
                    </QuizSelectBox>
                    <QuizSelectBox
                        vertical={isMobile}
                        width={isMobile ? "calc(50% - 10px)" : "auto"}
                        minWidth="auto"
                        minHeight="215px"
                        justifyContent={isMobile ? "center" : "flex-start"}
                        flex="0.5"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Não, não gosta!"
                        value={isChecked("likes_design", "Não")}
                        onChange={(e) =>
                            handleSelectItem("likes_design", "Não", e)
                        }
                    >
                        <GirlBackground>
                            <img src={QuizNaoGosta} alt="" />
                        </GirlBackground>
                    </QuizSelectBox>
                </Flex>
            </>

            <>
                <SectionTitle name="brands">
                    3.9. quais destas marcas você adoraria receber no box?
                </SectionTitle>

                {!isMobile && (
                    <CardBox
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        wrap="wrap"
                        margin="0 0 40px 0"
                    >
                        <Flex
                            direction="column"
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            margin="0 50px 0 0"
                        >
                            <CheckBox
                                pinky
                                margin="0 0 30px 0"
                                label="Adoro todas!"
                                checked={isChecked("brands", "Adoro todas!")}
                                onChange={(e) =>
                                    handleSelectItem(
                                        "brands",
                                        "Adoro todas!",
                                        e.target.checked
                                    )
                                }
                            />
                            <CheckBox
                                pinky
                                margin="0 0 30px 0"
                                label="GAP"
                                checked={isChecked("brands", "GAP")}
                                onChange={(e) =>
                                    handleSelectItem(
                                        "brands",
                                        "GAP",
                                        e.target.checked
                                    )
                                }
                            />
                            <CheckBox
                                pinky
                                margin="0 0 30px 0"
                                label="Oskhkoh"
                                checked={isChecked("brands", "Oskhkoh")}
                                onChange={(e) =>
                                    handleSelectItem(
                                        "brands",
                                        "Oskhkoh",
                                        e.target.checked
                                    )
                                }
                            />
                            <CheckBox
                                pinky
                                margin="0 0 30px 0"
                                label="Silmara"
                                checked={isChecked("brands", "Silmara")}
                                onChange={(e) =>
                                    handleSelectItem(
                                        "brands",
                                        "Silmara",
                                        e.target.checked
                                    )
                                }
                            />
                            <CheckBox
                                pinky
                                margin="0 0 30px 0"
                                label="Trousseau"
                                checked={isChecked("brands", "Trousseau")}
                                onChange={(e) =>
                                    handleSelectItem(
                                        "brands",
                                        "Trousseau",
                                        e.target.checked
                                    )
                                }
                            />
                        </Flex>

                        <Flex
                            direction="column"
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            margin="0 50px 0 0"
                        >
                            <CheckBox
                                pinky
                                margin="0 0 30px 0"
                                label="Baby Cottons"
                                checked={isChecked("brands", "Baby Cottons")}
                                onChange={(e) =>
                                    handleSelectItem(
                                        "brands",
                                        "Baby Cottons",
                                        e.target.checked
                                    )
                                }
                            />
                            <CheckBox
                                pinky
                                margin="0 0 30px 0"
                                label="Green"
                                checked={isChecked("brands", "Green")}
                                onChange={(e) =>
                                    handleSelectItem(
                                        "brands",
                                        "Green",
                                        e.target.checked
                                    )
                                }
                            />

                            <CheckBox
                                pinky
                                margin="0 0 30px 0"
                                label="Paola Bimbi"
                                checked={isChecked("brands", "Paola Bimbi")}
                                onChange={(e) =>
                                    handleSelectItem(
                                        "brands",
                                        "Paola Bimbi",
                                        e.target.checked
                                    )
                                }
                            />
                            <CheckBox
                                pinky
                                margin="0 0 30px 0"
                                label="Tip Top"
                                checked={isChecked("brands", "Tip Top")}
                                onChange={(e) =>
                                    handleSelectItem(
                                        "brands",
                                        "Tip Top",
                                        e.target.checked
                                    )
                                }
                            />
                            <CheckBox
                                pinky
                                margin="0 0 30px 0"
                                label="Zara"
                                checked={isChecked("brands", "Zara")}
                                onChange={(e) =>
                                    handleSelectItem(
                                        "brands",
                                        "Zara",
                                        e.target.checked
                                    )
                                }
                            />
                        </Flex>

                        <Flex
                            direction="column"
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            margin="0 50px 0 0"
                        >
                            <CheckBox
                                pinky
                                margin="0 0 30px 0"
                                label="Bonpoint"
                                checked={isChecked("brands", "Bonpoint")}
                                onChange={(e) =>
                                    handleSelectItem(
                                        "brands",
                                        "Bonpoint",
                                        e.target.checked
                                    )
                                }
                            />
                            <CheckBox
                                pinky
                                margin="0 0 30px 0"
                                label="Jacadi"
                                checked={isChecked("brands", "Jacadi")}
                                onChange={(e) =>
                                    handleSelectItem(
                                        "brands",
                                        "Jacadi",
                                        e.target.checked
                                    )
                                }
                            />
                            <CheckBox
                                pinky
                                margin="0 0 30px 0"
                                label="Petit Bateau"
                                checked={isChecked("brands", "Petit Bateau")}
                                onChange={(e) =>
                                    handleSelectItem(
                                        "brands",
                                        "Petit Bateau",
                                        e.target.checked
                                    )
                                }
                            />
                            <CheckBox
                                pinky
                                margin="0 0 30px 0"
                                label="Tip Toey Joey"
                                checked={isChecked("brands", "Tip Toey Joey")}
                                onChange={(e) =>
                                    handleSelectItem(
                                        "brands",
                                        "Tip Toey Joey",
                                        e.target.checked
                                    )
                                }
                            />
                            <CheckBox
                                pinky
                                margin="0 0 30px 0"
                                label="Reserva Mini"
                                checked={isChecked("brands", "Reserva Mini")}
                                onChange={(e) =>
                                    handleSelectItem(
                                        "brands",
                                        "Reserva Mini",
                                        e.target.checked
                                    )
                                }
                            />
                        </Flex>

                        <Flex
                            direction="column"
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            margin="0 50px 0 0"
                        >
                            <CheckBox
                                pinky
                                margin="0 0 30px 0"
                                label="Carters"
                                checked={isChecked("brands", "Carters")}
                                onChange={(e) =>
                                    handleSelectItem(
                                        "brands",
                                        "Carters",
                                        e.target.checked
                                    )
                                }
                            />
                            <CheckBox
                                pinky
                                margin="0 0 30px 0"
                                label="Janie & Jack"
                                checked={isChecked("brands", "Janie & Jack")}
                                onChange={(e) =>
                                    handleSelectItem(
                                        "brands",
                                        "Janie & Jack",
                                        e.target.checked
                                    )
                                }
                            />
                            <CheckBox
                                pinky
                                margin="0 0 30px 0"
                                label="Polo Raph Lauren"
                                checked={isChecked(
                                    "brands",
                                    "Polo Raph Lauren"
                                )}
                                onChange={(e) =>
                                    handleSelectItem(
                                        "brands",
                                        "Polo Raph Lauren",
                                        e.target.checked
                                    )
                                }
                            />
                            <CheckBox
                                pinky
                                margin="0 0 30px 0"
                                label="Tommy Hilfiger"
                                checked={isChecked("brands", "Tommy Hilfiger")}
                                onChange={(e) =>
                                    handleSelectItem(
                                        "brands",
                                        "Tommy Hilfiger",
                                        e.target.checked
                                    )
                                }
                            />

                            <CheckBox
                                pinky
                                margin="0 0 30px 0"
                                label="Touché Bebe"
                                checked={isChecked("brands", "Touché Bebe")}
                                onChange={(e) =>
                                    handleSelectItem(
                                        "brands",
                                        "Touché Bebe",
                                        e.target.checked
                                    )
                                }
                            />
                        </Flex>

                        <Flex
                            direction="column"
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            margin="0 50px 0 0"
                        >
                            <CheckBox
                                pinky
                                margin="0 0 30px 0"
                                label="Fabula"
                                checked={isChecked("brands", "Fabula")}
                                onChange={(e) =>
                                    handleSelectItem(
                                        "brands",
                                        "Fabula",
                                        e.target.checked
                                    )
                                }
                            />

                            <CheckBox
                                pinky
                                margin="0 0 30px 0"
                                label="Nike"
                                checked={isChecked("brands", "Nike")}
                                onChange={(e) =>
                                    handleSelectItem(
                                        "brands",
                                        "Nike",
                                        e.target.checked
                                    )
                                }
                            />
                        </Flex>
                    </CardBox>
                )}

                {isMobile && (
                    <MobileBrandsList
                        values={values}
                        setValues={setValues}
                        options={brands}
                    />
                )}

                <Flex>
                    <CheckBox
                        margin="0px auto 0px 0px"
                        secondary
                        label="Não precisamos de nenhuma dessas marcas no momento."
                        checked={values.brands && values.brands.length === 0}
                        onChange={(e) =>
                            e.target.checked === true
                                ? setValues({ ...values, brands: [] })
                                : setValues({
                                      ...values,
                                      brands: undefined,
                                  })
                        }
                    />
                </Flex>
            </>

            <>
                <SectionTitle>
                    3.10 personalize ainda mais o seu box re petit! compartilhe
                    com a gente as suas ideias.
                </SectionTitle>

                <Flex direction="column">
                    <TextArea
                        margin="0 0 20px 0"
                        placeholder="Escreva aqui suas ideias"
                        name="ideas"
                        value={values.ideas}
                        onChange={handleChange}
                    />
                </Flex>
            </>

            <>
                <SectionTitle name="surprise_expected">
                    3.11 O que você espera ser a grande surpresa do seu BOX?
                </SectionTitle>

                <Flex width="100%" margin="0px 0px 33px 0px">
                    <QuizSelectBox
                        padding="45px 0px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Peças estilosas"
                        value={isChecked(
                            "surprise_expected",
                            "Peças estilosas"
                        )}
                        onChange={(e) =>
                            handleSelectItem(
                                "surprise_expected",
                                "Peças estilosas",
                                e
                            )
                        }
                    />

                    <QuizSelectBox
                        padding="45px 0px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Peças de marca"
                        value={isChecked("surprise_expected", "Peças de marca")}
                        onChange={(e) =>
                            handleSelectItem(
                                "surprise_expected",
                                "Peças de marca",
                                e
                            )
                        }
                    />

                    <QuizSelectBox
                        padding="45px 0px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Peças com excelente custo benefício"
                        value={isChecked(
                            "surprise_expected",
                            "Peças com excelente custo benefício"
                        )}
                        onChange={(e) =>
                            handleSelectItem(
                                "surprise_expected",
                                "Peças com excelente custo benefício",
                                e
                            )
                        }
                    />
                </Flex>
            </>

            <>
                <SectionTitle>3.12 Envio</SectionTitle>

                <Flex width="100%" margin="0px 0px 45px 0px">
                    <CardBox
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        direction="column"
                    >
                        <DisclaimerText>
                            {(delivery &&
                                delivery.services &&
                                delivery.services[0].obs) || (
                                <>
                                    O frete para devolução do BOX é grátis nas
                                    compras acima de R$ 500,00 para a cidade de
                                    São Paulo e acima de R$ 600,00 para cidades
                                    pontuais na Grande São Paulo (Santo André,
                                    São Bernardo do Campo, São Caetano,
                                    Carapicuíba, Diadema, Mauá, Barueri, Osasco
                                    e Guarulhos). Abaixo deste valor, a taxa é
                                    cobrada de acordo com o seu endereço.
                                </>
                            )}
                        </DisclaimerText>
                        <DisclaimerText>
                            Ao clicar em SIM você aceita estas condições e vai
                            receber o seu BOX em até 7 dias úteis. Você está de
                            acordo? ?
                        </DisclaimerText>
                        <CheckBox
                            width={"100%"}
                            justifyContent="flex-start"
                            pinky
                            label="SIM! PEDIR O MEU PEDIDO"
                            name="accept"
                            onChange={handleChange}
                        />
                    </CardBox>
                </Flex>
            </>

            {/* <>
                <SectionTitle>
                    3.14 quer enviar peças para avaliação e venda na re petit? é
                    só confirmar e retiramos na sua casa junto com seu box
                </SectionTitle>

                <Flex width="100%" margin="0px 0px 33px 0px">
                    <QuizSelectBox
                        padding="45px 0px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Sim"
                        value={isChecked("send_items_for_sale", "true")}
                        onChange={(e) =>
                            handleSelectItem("send_items_for_sale", "true", e)
                        }
                    />

                    <QuizSelectBox
                        padding="45px 0px"
                        flex="1"
                        margin={isMobile ? "0" : "0 36px 0px 0px"}
                        title="Não"
                        value={isChecked("send_items_for_sale", "false")}
                        onChange={(e) =>
                            handleSelectItem("send_items_for_sale", "false", e)
                        }
                    />
                </Flex>
            </> */}
        </Container>
    );
};

const Container = styled.div`
    div > img:last-of-type:not(:only-of-type) {
        width: 60px;
        height: 60px;
        object-fit: contain;
    }
`;

const CardBox = styled(Flex)`
    padding: ${({ padding }) => (padding ? padding : "34px 28px")};
    background-color: #fbfcfc;
    box-shadow: 0px 1px 3px 0 rgba(52, 52, 59, 0.1);
    box-sizing: border-box;

    &,
    & > p {
        font-size: 15px;
        line-height: 1.07;
        color: #676777;
    }

    & > p {
        width: auto;
        flex: 0.3;
        margin: 0px;
        margin-right: 12px;
    }
`;

const GirlBackground = styled.div`
    width: 100px;
    height: 100px;
    border-radius: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border: solid 1px #f0b257;
    background-color: #ffffff;
    overflow: hidden;

    img {
        width: 97px !important;
        height: 97px !important;
        margin-bottom: -12px;
    }
`;

const DisclaimerText = styled.p`
    margin: 0px 0px 35px 0px !important;
    font-size: 15px !important;
    line-height: 1.4 !important;
    color: #34343b !important;
`;

export default InfosContent;
