import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Flex, isMobile, isMobileMediaQuery } from "../../helpers/styles";
import Button from "../Button";
import MobileNavFilter from "./MobileNavFilter";

import { ReactComponent as ChevronDown } from "../../assets/images/chevron-down.svg";
import { ReactComponent as Sliders } from "../../assets/images/sliders.svg";

function CategoryFilters({ applyCallback, ...props }) {
    const [currentFilter, setCurrentFilter] = useState(undefined);
    const [filterTypes, setFilterTypes] = useState();
    const [filterValues, setFilterValues] = useState({});
    const [showOrderContainer, setShowOrderContainer] = useState(false);
    const [numFiltereds, setNumFiltereds] = useState(0);
    const [fromPrice, setFromPrice] = useState(props.filters.from_price);
    const [toPrice, setToPrice] = useState(props.filters.to_price);
    const [genderValue, setGenderValue] = useState("");

    // Modal Sheet
    const [showModalRight, setShowModalRight] = useState(false);
    const handleModal = () => {
        setShowModalRight(!showModalRight);
        handleClose(selectedFilters);
    };

    const [selectedFilters, setSelectedFilters] = useState(
        props.defaultSelectedFilters || {
            brand: [],
            size: [],
            color: [],
            style: [],
            price: [],
            from_price: "",
            to_price: "",
            type: "",
            order: "",
        }
    );

    const [defaultFilters, setDefaultFilters] = useState(
        props.defaultSelectedFilters || {
            brand: [],
            size: [],
            color: [],
            style: [],
            price: [],
            from_price: "",
            to_price: "",
            type: "",
            order: "",
        }
    );

    useEffect(() => {
        setFilterTypes([
            {
                label: "Tamanho",
                value: "size",
            },
            {
                label: "Gênero",
                value: "type",
            },
            {
                label: "Preço",
                value: "price",
            },
            {
                label: "Cor",
                value: "color",
            },
            {
                label: "Marca",
                value: "brand",
            },
            {
                label: "Ordenar por",
                value: "order",
            },
        ]);

        setFilterValues({
            brand: props.filters.brands || [],
            size: props.filters.sizes || [],
            color: props.filters.colors || [],
            style: [],
            price: props.filters.prices || [],
            from_price: props.filters.from_price || "",
            to_price: props.filters.to_price || "",
            type: props.filters.types || [],
            order: {
                ...props.filters.sort,
                "brands.name.asc": "Marca",
                "items.created_at.desc": "Mais Recente",
                "items.price_label.asc": "Preço (Menor - Maior)",
                "items.price_label.desc": "Preço (Maior - Menor)",
            },
        });

    }, [props?.filters]);

    const toggleFilter = (filter) => {
        if (currentFilter === filter) {
            handleClose();
        } else {
            setCurrentFilter(filter);
        }
    };

    const handleClose = (newDefaultFilters = undefined) => {
        setCurrentFilter(undefined);
        setSelectedFilters(newDefaultFilters || defaultFilters);
    };

    const handleSelectItem = (type, item, active = null) => {
        let newSelectedFilters = { ...selectedFilters };

        if (type === "order" || type === "type") {
            if (currentFilter === "type") {
                setGenderValue(item);
            }
            newSelectedFilters = {
                ...selectedFilters,
                [type]: active === false || selectedFilters[currentFilter] === item ? "" : item,
            };

            setSelectedFilters(newSelectedFilters);
            applyCallback(newSelectedFilters, currentFilter);
            setDefaultFilters(newSelectedFilters);
            handleClose(newSelectedFilters);

            return;
        }

        if (type === "price") {
            newSelectedFilters.from_price = fromPrice
            newSelectedFilters.to_price = toPrice
        }

        if (
            (currentFilter !== undefined &&
                selectedFilters[type] &&
                selectedFilters[type].find((findItem) => findItem === item)) ||
            active === false
        ) {
            newSelectedFilters[type] = newSelectedFilters[type].filter((filterItem) => filterItem !== item);
        } else {
            newSelectedFilters[type] = [...(newSelectedFilters[type] || []), item];
        }
        setSelectedFilters(newSelectedFilters);
    };

    const handleApply = () => {
        applyCallback(selectedFilters);
        setDefaultFilters(selectedFilters);
        handleClose(selectedFilters);
        countFiltereds();
        setShowModalRight(false);
    };

    const countFiltereds = () => {
        let num = 0;
        num += selectedFilters["type"]?.length > 0 ? 1 : 0;
        num += selectedFilters["brand"]?.length || 0;
        num += selectedFilters["size"]?.length || 0;
        num += selectedFilters["color"]?.length || 0;
        num += selectedFilters["style"]?.length || 0;
        num += selectedFilters["from_price"] || selectedFilters["to_price"] ? 1 : 0;
        setNumFiltereds(num);
    };

    const handleClear = () => {
        handleClose();
        setNumFiltereds(0)
        let newSelectedFilters = { ...selectedFilters };
        if (currentFilter === "order") {
            newSelectedFilters = {
                ...selectedFilters,
                [currentFilter]: "",
            };
        } else {
            newSelectedFilters = {
                ...selectedFilters,
                ["brand"]: [],
                ["type"]: [],
                ["size"]: [],
                ["color"]: [],
                ["style"]: [],
                ["price"]: [],
                ["from_price"]: [],
                ["to_price"]: []
            };
        }

        setFromPrice(undefined)
        setToPrice(undefined);
        setSelectedFilters(newSelectedFilters);
        setDefaultFilters(newSelectedFilters);
        applyCallback(newSelectedFilters, currentFilter);
        setCurrentFilter(undefined);
        setGenderValue("");
        let radios = document.getElementsByName("gender");
        radios.forEach((element) => {
            element.checked = false;
        });
    };

    const handleClearFilter = (filter) => {
        handleClose();
        setShowOrderContainer(false);

        let newSelectedFilters = { ...selectedFilters };

        newSelectedFilters = {
            ...selectedFilters,
            [filter]: [],
        };
        if (filter == 'price') {
            setFromPrice(undefined)
            setToPrice(undefined);
            newSelectedFilters = {
                ...selectedFilters,
                ["price"]: [],
                ["from_price"]: [],
                ["to_price"]: []
            };
        }

        setSelectedFilters(newSelectedFilters);
        setDefaultFilters(newSelectedFilters);
        applyCallback(newSelectedFilters, currentFilter);
        setCurrentFilter(undefined);
    };

    return (
        <Flex width="100%" direction="row" alignItems="center" justifyContent="space-between" margin={isMobile ? "10px" : "10px 0 10px 0"}>

            <Flex position="relative">
                {selectedFilters?.type?.length > 0 ||
                    selectedFilters?.brand?.length ||
                    selectedFilters?.size?.length ||
                    selectedFilters?.color?.length ||
                    selectedFilters?.from_price ||
                    selectedFilters?.to_price ? (
                    <>
                        <Button
                            pink
                            filled
                            borderradius
                            lower="initial"
                            padding="25px"
                            width="160px"
                            onClick={() => handleModal()}
                        >
                            <Sliders /> {`Filtros ${numFiltereds ? "(" + numFiltereds + ")" : ""}`}
                        </Button>
                    </>
                ) : (
                    <Button
                        pink
                        borderradius
                        lower="initial"
                        padding="25px"
                        width="160px"
                        onClick={() => handleModal()}
                    >
                        <Sliders /> Filtros
                    </Button>
                )}
            </Flex>

            {filterTypes && filterTypes.map((item, index) => (
                <>
                    {item.value === "order" ? (
                        <Flex position="relative" justifyContent="space-between" >
                            <Button light borderradius padding="25px" lower="initial">
                                {(selectedFilters[item.value] || []).length === 0 ? (
                                    <FilterItem
                                        key={index}
                                        id={`filterButton${item.value}`}
                                        onClick={() => {
                                            toggleFilter(item.value);
                                            setShowOrderContainer(!showOrderContainer);
                                        }}
                                        margin={null}
                                        disabled={!filterValues[item.value] || filterValues[item.value].length === 0}
                                    >
                                        {!["order", "type"].includes(item.value) && (
                                            <span>[{(selectedFilters[item.value] || []).length}]</span>
                                        )}

                                        {item.label}

                                        {["order", "type"].includes(item.value) && (
                                            <span>{filterValues[item.value][selectedFilters[item.value]]}</span>
                                        )}
                                        <ChevronDown />
                                    </FilterItem>
                                ) : (
                                    <FilterItem>
                                        <div
                                            onClick={() => {
                                                setShowOrderContainer(!showOrderContainer);
                                            }}
                                        >
                                            {filterValues[item.value][selectedFilters[item.value]]}
                                        </div>
                                        <span onClick={() => handleClearFilter(item.value)}>x</span>
                                    </FilterItem>
                                )}
                            </Button>
                        </Flex>
                    ) : (
                        ""
                    )}
                </>
            )
            )}

            {showModalRight === true && (
                <>
                    <Backdrop onClick={handleModal} />
                    <ContainerModalRight>
                        <Dialog>
                            <ContentModalRight>
                                <Flex
                                    padding="20px 10px 0 20px"
                                    direction="row"
                                    alignItems="flex-center"
                                    justifyContent="space-between"
                                >
                                    <div>
                                        <Sliders /> Filtrar
                                    </div>
                                    <span onClick={handleModal}>X</span>
                                </Flex>

                                <Flex padding="10px 0 0 20px" direction="column" alignItems="flex-start">

                                    <MobileNavFilter
                                        currentFilter={currentFilter}
                                        filterTypes={filterTypes}
                                        filterValues={filterValues}
                                        selectedFilters={selectedFilters}
                                        handleSelectItem={handleSelectItem}
                                        setCurrentFilter={setCurrentFilter}
                                        handleClearFilter={handleClearFilter}
                                        handleApply={handleApply}
                                        setFromPrice={setFromPrice}
                                        setToPrice={setToPrice}
                                        setGenderValue={setGenderValue}
                                        genderValue={genderValue}
                                        fromPrice={fromPrice}
                                        toPrice={toPrice}
                                    />
                                </Flex>
                                <Flex
                                    width="100%"
                                    justifyContent="center"
                                    margin="50px 0 0 0"
                                    flexDirection="column"
                                    alignItems="center"
                                >
                                    {!["order"].includes(currentFilter) && (
                                        <>
                                            <Button
                                                width="55%"
                                                pink
                                                filled
                                                borderradius
                                                padding="25px 42px"
                                                margin="0 0 10px 0"
                                                value="Aplicar"
                                                onClick={handleApply}
                                            />
                                            <Button
                                                width="55%"
                                                pink
                                                borderradius
                                                padding="25px 0px"
                                                margin="0 0 18px 0"
                                                value="Limpar"
                                                onClick={handleClear}
                                            />
                                        </>
                                    )}
                                </Flex>
                            </ContentModalRight>
                        </Dialog>
                    </ContainerModalRight>
                </>
            )}

            {showOrderContainer && (
                <OrderContainer>
                    <Arrow />
                    {filterValues &&
                        filterValues.order &&
                        Object.keys(filterValues.order).map(
                            (item) =>
                                // hide brand order filter
                                item !== "brands.name.asc" &&
                                item !== "items.images_updated_at.desc" && (
                                    <p
                                        className={selectedFilters.order === item ? "active" : ""}
                                        onClick={() => {
                                            handleSelectItem("order", item);
                                            setShowOrderContainer(false);
                                        }}
                                    >
                                        {filterValues.order[item]}
                                    </p>
                                )
                        )}
                </OrderContainer>
            )}

        </Flex>
    );
}

const FilterItem = styled(Flex)`
    cursor: pointer;
    justify-content: space-between;
    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.82;
    letter-spacing: 2.2px;
    text-align: left;
    color: #7ca8af;
    span {
        color: #7ca8af;
        margin: 0px;
        :first-of-type {
            margin-right: 4px;
        }
        :last-of-type {
            margin-left: 4px;
        }
    }
    svg {
        path {
            fill: #7ca8af;
        }
    }
    ${(props) =>
        props.disabled &&
        css`
            pointer-events: none;
            cursor: initial;
            opacity: 0.3;
        `}
`;

export const SizeButton = styled(Button).attrs((props) => ({
    dark: 1,
    filled: props.active,
    fluid: 1,
}))`
    border-radius: 15px;
    margin: 0px;
    padding: 10px 20px;
    font-family: "Graphik";
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.24px;
    text-align: center;
    color: #676777;
    ${(props) =>
        props.active &&
        css`
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            color: #fff;
            border: 2px solid #fff;
            outline: 1px solid #34343b !important;
            :active,
            :focus {
                outline: 1px solid #34343b !important;
            }
        `}
`;

export const ColorButton = styled(SizeButton)`
    position: relative;
    background: ${(props) => props.color};
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    color: #fff;
    border: 2px solid #fff;
    outline: 1px solid #c9c9cf !important;
    :active,
    :focus {
        outline: 1px solid #c9c9cf !important;
    }
    span {
        position: absolute;
        top: calc(100% + 6px);
        left: -2px;
        color: #676777;
    }
    ${(props) =>
        props.active &&
        css`
            outline-color: black !important;
            :active,
            :focus {
                outline: 1px solid black !important;
            }
        `}
`;

export const OrderButton = styled.p`
    margin-top: 0px;
    margin-bottom: 28px;
    font-family: "Graphik";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.28px;
    text-align: left;
    color: #676777;
    cursor: pointer;
    ${(props) =>
        props.active &&
        css`
            color: #e4858c;
        `}
    ${isMobileMediaQuery} {
        margin: 0px;
        text-decoration: underline;
        font-family: "Graphik";
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #4d4d5a;
        span {
            color: #e4858c;
            text-decoration: none;
        }
    }
`;

const OrderContainer = styled.div`
    position: absolute;
    top: calc(100% - 6px);
    right: 30px;
    width: 100px;
    min-width: 156px;
    padding: 16px;
    border-radius: 4px;
    background-color: #f3f5f5;
    box-shadow: 0px 2px 1px 0 rgba(52, 52, 64, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 5;
    p {
        margin: 0px;
        font-family: "Graphik";
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 17.5px;
        letter-spacing: normal;
        text-align: left;
        color: #8c8ca1;
        :hover,
        &.active {
            color: #e4858c;
        }
    }
`;

const Arrow = styled.div`
    position: absolute;
    bottom: 100%;
    right: 30px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #f3f5f5;
    filter: drop-shadow(0px -2px 1px rgba(52, 52, 64, 0.1));
    ${isMobileMediaQuery} {
        right: 80px;
    }
`;

const ContainerModalRight = styled(Flex)`
    position: fixed;
    right: 0px;
    top: 50%;
    z-index: 20;
    flex-direction: collumn;
    justify-content: end;
`;

const Backdrop = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.15);
`;

const Dialog = styled.div`
    background: white;
    color: black;
    position: fixed;
    margin: auto;
    width: 400px;
    overflow-y: auto;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
`;

const ContentModalRight = styled.div`
    height: 100%;
    padding: 10px;
`;

export default CategoryFilters;
