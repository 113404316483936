import api from "../../services/api";

export const USER_LOGIN = "USER_LOGIN";
export const USER_PROFILE = "USER_PROFILE";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_WALLET = "USER_WALLET";

export const login = (payload) => {
    localStorage.removeItem("persistedState");

    return {
        type: USER_LOGIN,
        payload: {
            ...payload,
        },
    };
};

export const refreshProfile = () => {
    return async (dispatch, getState) => {
        let response = await api.get("/profiles/me").catch((err) => err.response);
        if (response && response.status === 200) {
            dispatch({
                type: USER_PROFILE,
                payload: {
                    token: response.headers.token,
                    ...response.data,
                },
            });
        }
    };
};

export const wallet = (payload) => {
    return {
        type: USER_WALLET,
        payload: {
            ...payload,
        },
    };
};

export const logout = () => {
    localStorage.clear();

    return {
        type: USER_LOGOUT,
    };
};
