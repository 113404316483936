import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import api from "../../services/api";
import * as UserActions from "../../redux/actions/user";
import { ContentDelimiter, Flex, isMobile } from "./../../helpers/styles";
import Header from "./../../components/Header";
import Footer from "./../../components/Footer";
import TextInput from "../../components/Inputs/TextInput";
import Button from "../../components/Button";
import CheckBox from "../../components/Inputs/CheckBox";
import AlertModal from "../../components/AlertModal";
import SectionTitle from "../../components/SectionTitle";
import HeroImage from "../../assets/images/signup-hero.png";
import { isValidCPF, openPrivacyNTerms } from "../../helpers";

const LoginVerification = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);

    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const [modal, setModal] = useState(undefined);

    useEffect(() => {
        handleAuthValues();
    }, []);

    useEffect(() => {
        let user_payload = {};
        if (values.cpf && values.cpf.length === 14) {
            user_payload = { cpf: values.cpf };
            validateRequiredFields(user_payload);
        }
        if (values.phone_number && values.phone_number.length === 16) {
            user_payload = {
                phone_country_code: values.phone_country_code || "55",
                phone_code_area:
                    values.phone_number && values.phone_number.length > 0 && values.phone_number[0] === "("
                        ? values.phone_number.match(/^\((\d{2})\)/)[1]
                        : values.phone_number.substring(0, 2),
                phone_number:
                    values.phone_number && values.phone_number[0] === "("
                        ? values.phone_number.substring(5).replace(/\s/g, "").replace(/_/g, "").replace(/-/g, "")
                        : values.phone_number.substring(2),
            };
            validateRequiredFields(user_payload);
        }
        if (values.email && values.email.includes(".com") && values.email !== user.email) {
            user_payload = { email: values.email };
            validateRequiredFields(user_payload);
        }
    }, [values]);

    const handleAuthValues = () => {
        setValues({
            email: user.email && user.email,
            first_name: user.first_name && user.first_name,
            last_name: user.last_name && user.last_name,
        });
    };

    const handleChange = (e) => {
        let {
            target: { name, value, checked, type },
        } = e;

        let newValues = { ...values };

        if (name !== undefined) {
            if (value !== undefined && type !== "checkbox") {
                if (["email", "password", "confirm_password"].includes(name) && value) {
                    value = value.trim();
                }

                newValues[name] = value;
            } else if (type === "checkbox") {
                newValues[name] = checked;
            }
        }

        setValues((prevValues) => {
            return {
                ...prevValues,
                ...newValues,
            };
        });

        canChangeStep(false, true, newValues);
    };

    const canChangeStep = (scroll = false, ignoreUndefined = false, checkValues = values) => {
        let canChange = true;

        let newErrors = { ...errors };

        const firstStepRequireds = ["first_name", "last_name", "cpf", "phone_number", "email"];

        firstStepRequireds.forEach((requiredField) => {
            if (
                (!Object.keys(checkValues).includes(requiredField) && !ignoreUndefined) ||
                checkValues[requiredField] === ""
            ) {
                newErrors[requiredField] = true;
            } else if (!newErrors[requiredField] || checkValues[requiredField] !== undefined) {
                newErrors[requiredField] = false;
            }
        });

        canChange = firstStepRequireds.every((requiredField) => {
            const invalidField = document.querySelector(`[name=${requiredField}]`);

            if (invalidField && scroll) {
                invalidField.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }

            return (
                Object.keys(checkValues).includes(requiredField) &&
                checkValues[requiredField] !== undefined &&
                checkValues[requiredField] !== ""
            );
        });

        const birth_date = values.birth_date && moment(values.birth_date, "dd/mm/yyyy");
        const user_age = birth_date && moment().diff(birth_date, "years");

        if (
            !values.birth_date ||
            values.birth_date.length < 10 ||
            !birth_date ||
            !birth_date.isValid() ||
            user_age < 5 ||
            user_age > 150
        ) {
            newErrors.birth_date = true;

            canChange = false;

            const invalidField = document.querySelector(`[name=birth_date]`);

            if (invalidField && scroll) {
                invalidField.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            } else newErrors.birth_date = false;
        }
        setErrors({ ...newErrors });

        return canChange;
    };

    const handleVerification = async () => {
        if (!canChangeStep(true)) return;

        let newErrors = {
            ...errors,
            first_name: !values.first_name,
            email: !values.email,
            last_name: !values.last_name,
            phone_number:
                !values.phone_number ||
                values.phone_number.replace(/_/g, "").replace(/-/g, "").replace(/\(/g, "").replace(/\)/g, "").length <
                10,
            birth_date: !values.birth_date,
            cpf: !isValidCPF(values.cpf),
        };

        setErrors((prevErrors) => {
            return {
                ...prevErrors,
                ...newErrors,
            };
        });

        if (Object.values(newErrors).find((item) => item === true) !== undefined) {
            return;
        }

        let user_payload = {
            cpf: values.cpf,
            birth_date: values.birth_date,
            email: values.email,
            first_name: values.first_name,
            last_name: values.last_name,
            phone_country_code: values.phone_country_code || "55",
            phone_code_area:
                values.phone_number && values.phone_number.length > 0 && values.phone_number[0] === "("
                    ? values.phone_number.match(/^\((\d{2})\)/)[1]
                    : values.phone_number.substring(0, 2),
            phone_number:
                values.phone_number && values.phone_number[0] === "("
                    ? values.phone_number.substring(5).replace(/\s/g, "").replace(/_/g, "").replace(/-/g, "")
                    : values.phone_number.substring(2),
        };

        let verifyResponse = await api.post("/auth/validate", user_payload).catch((err) => err.response);

        if (verifyResponse.status === 204) {
            handleEditProfile(user_payload);
        } else {
            setModal({
                show: true,
                message:
                    verifyResponse && verifyResponse.data && verifyResponse.data.message
                        ? verifyResponse.data.message
                        : "Erro inesperado. Aguarde um instante e tente outra vez!",
            });
        }
    };

    const validateRequiredFields = async (user_payload) => {
        let verifyResponse = await api.post("/auth/validate", user_payload).catch((err) => err.response);
        if (verifyResponse.status === 400) {
            setModal({
                show: true,
                message:
                    verifyResponse && verifyResponse.data && verifyResponse.data.message
                        ? verifyResponse.data.message
                        : "Erro inesperado. Aguarde um instante e tente outra vez!",
            });
            if (user_payload.cpf) setValues({ ...values, cpf: "" });
            if (user_payload.phone_number) setValues({ ...values, phone_number: "" });
            if (user_payload.email) setValues({ ...values, email: "" });
        }
    };

    const handleEditProfile = async (user_payload) => {
        let saveResponse = await api.put("/profiles/me", user_payload).catch((err) => err.response);

        if (saveResponse.status === 200) {
            dispatch(UserActions.refreshProfile());
            navigate("/");
        } else {
            setModal({
                show: true,
                message:
                    saveResponse && saveResponse.data && saveResponse.data.message
                        ? saveResponse.data.message
                        : "Erro inesperado. Aguarde um instante e tente outra vez!",
            });
        }
    };

    return (
        <>
            <Header
                navigationItems={[
                    { label: "Compre", action: () => navigate("/categorias/novidades") },
                    { label: "Quero vender", action: () => navigate("/desapegue") },
                    { label: "Peça seu box", action: () => navigate("/box") },
                    { label: "Lojas Influencers", action: () => navigate("/lojas-influencers") },
                    { label: "Blog", action: () => navigate("/blog") },
                    { label: "Outlet", action: () => navigate("/outlet") },
                    { label: "Sale", action: () => navigate("/promocoes") },
                ]}
            />
            <ContentDelimiter>
                <Flex
                    width={isMobile ? "100%" : "910px"}
                    alignItems="flex-start"
                    margin={isMobile ? "0" : "87px auto 54px auto"}
                    direction={isMobile ? "column" : "row"}
                >
                    <Flex width="100%" mobile>
                        <SectionTitle margin="30px 0">Cadastro por e-mail</SectionTitle>
                    </Flex>

                    <Flex width={isMobile ? "100%" : undefined} margin={isMobile ? "0" : "0px 82px 0px 0px"}>
                        <Image
                            src={HeroImage}
                            width={isMobile ? "auto" : "385px"}
                            height={isMobile ? "320px" : "auto"}
                        />
                    </Flex>

                    <Flex
                        as="form"
                        width={isMobile ? "100%" : "440px"}
                        padding={isMobile ? "0 20px" : undefined}
                        direction="column"
                        alignItems="flex-start"
                        justifyContent="flex-start"
                        onSubmit={handleVerification}
                        boxSizing="border-box"
                    >
                        <Flex direction="column" alignItems="flex-start" justifyContent="flex-start" desktop>
                            <Title>Valide seu cadastro</Title>
                            <Separator />
                        </Flex>

                        <TextInput
                            placeholder="Nome"
                            margin="0 0 20px 0"
                            name="first_name"
                            value={values.first_name}
                            onChange={handleChange}
                            error={errors.first_name}
                        />

                        <TextInput
                            placeholder="Sobrenome"
                            margin="0 0 20px 0"
                            name="last_name"
                            value={values.last_name}
                            onChange={handleChange}
                            error={errors.last_name}
                        />

                        <TextInput
                            mask="999.999.999-**"
                            placeholder="CPF"
                            margin="0 0 20px 0"
                            name="cpf"
                            value={values.cpf}
                            onChange={handleChange}
                            error={errors.cpf}
                        />

                        <TextInput
                            placeholder="Celular (XX) XXXXX-XXXX"
                            margin="0 0 20px 0"
                            name="phone_number"
                            value={values.phone_number}
                            onChange={(e) => {
                                e.target.value = e.target.value
                                    .replace(/\D+/g, "")
                                    .replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4");
                                handleChange(e);
                            }}
                            error={errors.phone_number}
                        />

                        <TextInput
                            margin="0 0 20px 0"
                            placeholder="Data de nascimento (DD/MM/AAAA)"
                            type="date"
                            name="birth_date"
                            mask="99/99/9999"
                            maskPlaceholder="dd/mm/yyyy"
                            value={values.birth_date}
                            onChange={handleChange}
                            error={errors.birth_date}
                        />

                        <TextInput
                            placeholder="E-mail"
                            margin="0 0 20px 0"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            error={errors.email}
                        />

                        <Info>
                            <strong>Fique de olho!</strong>
                            <br />
                            <br />
                            Sempre que logar utilizando o Facebook ou Google você deve informar os mesmos dados do
                            primeiro acesso
                        </Info>

                        <CheckBox
                            margin="0 0 26px 0"
                            name="newsletter"
                            checked={values.newsletter}
                            onChange={handleChange}
                        >
                            <Text>Oba! Quero receber as novidades e promoções Re Petit por e-mail.</Text>
                        </CheckBox>

                        <Text>
                            Ao criar minha conta eu concordo que li e aceito os{" "}
                            <Link onClick={() => openPrivacyNTerms("terms")}>Termos e Condições</Link> e{" "}
                            <Link onClick={() => openPrivacyNTerms("privacy")}>Política de Privacidade</Link>.
                        </Text>

                        <Flex
                            alignItems="center"
                            justifyContent="flex-start"
                            width="100%"
                            direction={isMobile ? "column" : "row"}
                            margin={isMobile ? "0 0 30px 0" : undefined}
                        >
                            <Button
                                width={isMobile ? "100%" : "200px"}
                                dark
                                filled
                                margin="0"
                                value="Validar conta"
                                onClick={handleVerification}
                                disabled={loading}
                            />
                            <Button
                                padding="15px 44px 12px 44px"
                                dark
                                link
                                margin="0"
                                value="Voltar"
                                onClick={() => navigate("/login")}
                                disabled={loading}
                            />
                        </Flex>
                    </Flex>
                </Flex>
            </ContentDelimiter>

            <Footer mini />

            {modal && (
                <AlertModal
                    show={modal.show}
                    title={modal.title}
                    message={modal.message}
                    callback={() => setModal({ ...modal, show: false })}
                />
            )}
        </>
    );
};

const Image = styled.img`
    margin: 0 auto;
`;

const Title = styled.h1`
    margin: 0px;
    margin-bottom: 24px;
    text-transform: uppercase;

    font-family: "SackersGothicStd";
    font-size: 20px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: 4px;
    text-align: left;
    color: #34343b;
`;

const Separator = styled.hr`
    width: 40px;
    height: 3px;
    margin: 0px;
    margin-bottom: 29px;
    background-color: #e4858c;
    border: none;
`;

const Text = styled.p`
    flex: 1;
    margin: 0 0 36px 0;

    font-family: "Graphik";
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    a {
        color: #34343b;
    }
`;

const Info = styled.p`
    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    strong {
        font-weight: 600;
    }
`;

export default LoginVerification;
