import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Flex, isMobile, isMobileMediaQuery } from "../../helpers/styles";
import modalHero from "../../assets/images/buy-success.jpg";
import modalHeroRequest from "../../assets/images/box-sucesso.jpg";
import modalHeroRequestMobile from "../../assets/images/box-sucesso-mobile.jpg";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
import Button from "../Button";

const BoxSuccessModal = ({ show, type, ...props }) => {
    const bodyElement = document.querySelector("body");
    const navigate = useNavigate();
    const [modalActive, setModalActive] = useState(false);

    useEffect(() => {
        return () => {
            const scrollY = bodyElement.style.top;

            bodyElement.style.position = "";
            bodyElement.style.top = "";
            bodyElement.style.left = "";
            bodyElement.style.right = "";

            window.scrollTo(0, -parseInt(scrollY || "0"));
        };

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        show !== null && show !== undefined && show !== modalActive && setModalActive(show);

        // eslint-disable-next-line
    }, [show]);

    useEffect(() => {
        if (modalActive) {
            let top = window.scrollY;

            bodyElement.style.position = "fixed";
            bodyElement.style.top = `-${top}px`;
            bodyElement.style.left = "0px";
            bodyElement.style.right = "0px";
        } else {
            const scrollY = bodyElement.style.top;

            bodyElement.style.position = "";
            bodyElement.style.top = "";
            bodyElement.style.left = "";
            bodyElement.style.right = "";

            window.scrollTo(0, -parseInt(scrollY || "0"));
        }

        // eslint-disable-next-line
    }, [modalActive]);

    const handleClose = () => {
        setModalActive(false);
        props.callback && props.callback(modalActive);
    };

    return (
        modalActive && (
            <Container>
                <Backdrop onClick={handleClose} />
                <Modal>
                    <CloseButton onClick={handleClose}>
                        <CloseIcon />
                    </CloseButton>

                    <Flex
                        width={isMobile ? "100%" : undefined}
                        flex="1"
                        height="100%"
                        direction="column"
                        margin={isMobile ? "auto" : "auto 0px auto 0"}
                    >
                        <ModalImage
                            src={
                                type === "request" ? (isMobile ? modalHeroRequestMobile : modalHeroRequest) : modalHero
                            }
                            alt="Pedido realizado com sucesso!"
                        />
                    </Flex>

                    <Flex
                        width={isMobile ? "100%" : "400px"}
                        height="100%"
                        background="#7CA8AF"
                        direction="column"
                        alignItems="flex-start"
                        padding={isMobile ? "30px 25px" : "102px 60px 100px 48px"}
                        boxSizing="border-box"
                    >
                        {type === "request" && (
                            <HintMessage>Aguarde. Em até 7 dias o BOX vai chegar na sua casa.</HintMessage>
                        )}
                        <Title>olho no agendamento</Title>
                        <Separator />
                        <ModalText>
                            Seu BOX será retirado em até 2 dias úteis após o pagamento. Entraremos em contato para
                            agendar o dia e horário.
                        </ModalText>

                        <Title>conferência do box</Title>
                        <Separator />
                        <ModalText>
                            Após retirarmos o BOX faremos a conferência das peças e da compra. Em caso de divergência
                            entraremos em contato.
                        </ModalText>

                        <Button
                            width={isMobile ? "100%" : "240px"}
                            maxWidth={isMobile ? "270px" : undefined}
                            margin={isMobile ? "0 auto" : "0"}
                            dark
                            filled
                            borderradius
                            value={type === "request" ? "Voltar para a home" : "Continuar"}
                            onClick={() => (type === "request" ? navigate("/") : navigate("/checkout/sucesso"))}
                        />
                    </Flex>
                </Modal>
            </Container>
        )
    );
};

const Container = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 5;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    overflow-y: auto;
    padding: 1rem;

    ${isMobileMediaQuery} {
        padding: 1rem;
        align-items: baseline;
        max-height: 100vh;
        overflow-y: auto;
    }
`;

const Backdrop = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.7);
`;

const Modal = styled.div`
    margin: auto;
    position: relative;
    width: 1000px;
    height: auto;
    min-height: 500px;
    max-width: 90vw;
    max-height: initial;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px 0 rgba(52, 52, 59, 0.15);
    padding: 0px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    ${isMobileMediaQuery} {
        padding: 0px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        max-width: 90vw;
        max-width: 380px;
        max-height: initial;
        height: auto;
    }
`;

const CloseButton = styled.button`
    padding: 0px;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 0px;
    right: 0px;
    margin: 19px;
    border: 0px;
    background: transparent;
    cursor: pointer;
    z-index: 20;

    svg {
        transform: scale(0.8);
        path {
            fill: #fff;
        }
    }

    ${isMobileMediaQuery} {
        svg path {
            fill: #34343c;
        }
    }
`;

const ModalImage = styled.img`
    padding: 30px;
    max-width: 80%;
    margin: auto;

    ${isMobileMediaQuery} {
        margin-top: 40px;
    }
`;

const HintMessage = styled.p`
    margin: 0 auto 0 0 16px;
    font-size: 16px;
    line-height: 1.15;
    text-align: left;
    color: #fff;

    ${isMobileMediaQuery} {
        max-width: 180px;
        color: #000000;
    }
`;

const Title = styled.h1`
    margin: 0px auto 16px 0px;
    text-transform: uppercase;
    font-family: "SackersGothicStd";
    font-size: 14px;
    font-weight: 900;
    line-height: 1;
    letter-spacing: 2.8px;
    text-align: left;
    color: #ffffff;
`;

const Separator = styled.hr`
    width: 40px;
    height: 3px;
    border: 0;
    background-color: #313139;
    margin: 0px auto 15px 0px;
`;

const ModalText = styled.p`
    margin: 0px;
    font-size: 13px;
    line-height: 1.15;
    text-align: left;
    color: #ffffff;

    :not(:last-child) {
        margin-bottom: 40px;
    }
`;

export default BoxSuccessModal;
