import React from "react";
import styled from "styled-components";
import { isMobileMediaQuery } from "../../helpers/styles";
import Button from "./../Button";

function HeaderItem({ data }) {
    return (
        <Container background={data.image_url} onClick={() => data.link_url && window.location.replace(data.link_url)}>
            {data.title && <Title>{data.title}</Title>}

            {data.link_url && (
                <Button margin="0 16px" fluid borderradius>
                    Saiba mais
                </Button>
            )}
        </Container>
    );
}

export const Container = styled.div`
    cursor: pointer;
    background-image: url(${(props) => props.background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 280px;
    height: 430px;
    padding: 30px 23px;
    margin-left: 35px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;

    ${isMobileMediaQuery} {
        display: none;
    }
`;

export const Title = styled.div`
    margin: 23px 0;
    color: #fff;
    font-size: 38px;
    line-height: 0.95;
    font-family: "DMSerifDisplay";
`;

export const Separator = styled.div`
    width: 12.5%;
    height: 6px;
    background-color: #34343b;
    margin-top: 30px;
    margin-bottom: 26px;
`;

export const Message = styled.div`
    font-size: 15px;
    max-width: 60%;
    color: #fff;
    margin-bottom: 26px;
`;

export default HeaderItem;
