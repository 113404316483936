import { LAST_POSITION, CLEAR_LAST_POSITION } from "../actions/category";

const initialState = {
    state: {
        items: [],
        pagination: {},
        filters: {},
    },
    path: "",
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LAST_POSITION:
            return {
                ...action.payload,
            };

        case CLEAR_LAST_POSITION:
            return initialState;

        default:
            return state;
    }
};
