import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { Flex, isMobileMediaQuery, isMobile } from "../../helpers/styles";
import Button from "../Button";
import LoadingSpinner from "../LoadingSpinner";
import CheckBox from "../Inputs/CheckBox";
import SellProductItem from "../../pages/Account/SellProductItem";
import ExclamationIcon from "../../assets/images/exclamation-icon-white.png";
import ModalSuccess from "../../assets/images/modal-success-hero.jpg";
import EntryPromotionHero from "../../assets/images/entry-promotion-hero.jpg";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
import PromoIcon from "../../assets/images/promo-icon.png";

const EntryPromotionModal = ({ setModal }) => {
    const navigate = useNavigate();
    const bodyElement = document.querySelector("body");
    const user = useSelector((state) => state.user);

    const [modalActive, setModalActive] = useState(false);
    const [sendingRequest, setSendingRequest] = useState(false);
    const [entryItems, setEntryItems] = useState(null);
    const [currentEntry, setCurrentEntry] = useState(null);
    const [values, setValues] = useState({ promotedItems: [] });

    useEffect(() => {
        if (user.entry_promotion_banner_pending && !modalActive && !sendingRequest && !entryItems) {
            handleLoadEntry(user.entry_promotion_banner_pending);
        }

        // eslint-disable-next-line
    }, [user, modalActive, sendingRequest]);

    useEffect(() => {
        if (modalActive) {
            let top = window.scrollY;

            bodyElement.style.position = "fixed";
            bodyElement.style.top = `-${top}px`;
            bodyElement.style.left = "0px";
            bodyElement.style.right = "0px";
        } else {
            const scrollY = bodyElement.style.top;

            bodyElement.style.position = "";
            bodyElement.style.top = "";
            bodyElement.style.left = "";
            bodyElement.style.right = "";

            window.scrollTo(0, -parseInt(scrollY || "0"));
        }

        // eslint-disable-next-line
    }, [modalActive]);

    const handleChange = async (e) => {
        const {
            target: { name, value, checked, type },
        } = e;

        let newValues = { ...values };

        if (name !== undefined) {
            if (value !== undefined && (type !== "checkbox" || type === "radio")) {
                newValues = {
                    ...newValues,
                    [name]: value,
                };
            } else if (checked !== undefined) {
                if (name === "promotedItems") {
                    if (checked && !newValues.promotedItems?.includes(value)) {
                        newValues = {
                            ...newValues,
                            [name]: [...(newValues.promotedItems || []), value],
                        };
                    } else if (!checked) {
                        newValues = {
                            ...newValues,
                            [name]: (newValues.promotedItems || []).filter((item) => item !== value),
                        };
                    }
                } else {
                    newValues = {
                        ...newValues,
                        [name]: checked,
                    };
                }

                if (name === "promotedAll") {
                    newValues.promotedItems = checked
                        ? entryItems.reduce((acc, curr) => {
                              return curr.promotion ? acc : [...acc, curr.id.toString()];
                          }, [])
                        : [];
                }
            }
        }

        setValues((prevState) => ({ ...prevState, ...newValues }));
    };

    const handleClose = () => {
        setModalActive(false);
        api.put(`/profiles/entries/${user.entry_promotion_banner_pending}/promotion_acknowledge`);
    };

    const handleLoadEntry = async (entry_id) => {
        setSendingRequest(true);

        const entry_response = await api.get(`/profiles/entries/${entry_id}`).catch((err) => err.response);

        if (entry_response.status === 200 && entry_response.data) {
            if (entry_response.data.items?.length > 0) {
                const condition =
                    !JSON.parse(localStorage.getItem("persistedState")).user.cnpj ||
                    JSON.parse(localStorage.getItem("persistedState")).user.id !== 2246
                        ? true
                        : false;
                const { items } = entry_response.data;
                setCurrentEntry(entry_response.data);
                setEntryItems(items);
                setModalActive(condition);
            } else {
                console.error("Empty or expired box: ", entry_id);
                await api.put(`/profiles/entries/${entry_id}/promotion_acknowledge`);
            }
        } else {
            console.error(entry_response);
        }

        setSendingRequest(false);
    };

    const handleSubmitPromotions = async (type = null) => {
        setSendingRequest(true);

        const promotions_response = await api
            .post(
                "/profiles/entries/items/promotion",
                type === "all"
                    ? {
                          entry_id: parseInt(user.entry_promotion_banner_pending),
                      }
                    : {
                          enabled_item_ids: values.promotedItems.map((id) => parseInt(id)),
                      }
            )
            .catch((err) => err.response);

        setSendingRequest(false);

        if (promotions_response.status === 204) {
            handleClose();

            setModal({
                show: true,
                image: ModalSuccess,
                title: "Yeah!",
                message: "Itens em promoção atualizados com sucesso.",
            });
        } else {
            console.error(promotions_response);

            setModal({
                show: true,
                message:
                    promotions_response && promotions_response.data && promotions_response.data.message
                        ? promotions_response.data.message
                        : "Erro inesperado. Aguarde um instante e tente outra vez!",
            });
        }
    };

    const handleDonate = () => {
        window.open(
            `https://api.whatsapp.com/send?phone=5511976922404&text=Tenho%20um%20lote:%20${user.entry_promotion_banner_pending}%20e%20gostaria%20de%20doar%20os%20produtos.`,
            "_blank",
            "noopener"
        );
    };

    const handleTake = () => {
        window.open(
            `https://api.whatsapp.com/send?phone=5511976922404&text=Tenho%20um%20lote:%20${user.entry_promotion_banner_pending}%20e%20gostaria%20de%20retirar%20os%20produtos.`,
            "_blank",
            "noopener"
        );
    };

    const handleContact = () => {
        window.open(
            `https://api.whatsapp.com/send?phone=5511976922404&text=Ol%C3%A1%2C%20estou%20com%20d%C3%BAvidas%20sobre%20a%20regra%20de%20plano%20de%20descontos.`,
            "_blank",
            "noopener"
        );
    };

    return (
        modalActive && (
            <Container onClick={handleClose}>
                <Body onClick={(e) => e.stopPropagation()}>
                    <CloseButton onClick={handleClose}>
                        <CloseIcon />
                    </CloseButton>

                    <Flex height="54px" />

                    {currentEntry.promotion_banner_type >= 6 && currentEntry.promotion_banner_type < 10 && (
                        <>
                            <WarningContainer>
                                <img alt="Exclamação" src={ExclamationIcon} />
                                <p>
                                    Seus produtos estão à venda há{" "}
                                    <strong>{currentEntry.promotion_banner_type} meses</strong>. Ative a promoção!
                                </p>
                            </WarningContainer>
                            <Flex
                                padding={isMobile ? "0 15px" : "0 30px"}
                                direction="column"
                                minWidth={!isMobile && "500px"}
                                boxSizing="border-box"
                            >
                                <ExplainText>
                                    <p>
                                        Criamos um plano de descontos progressivos para aumentar suas chances de vender.
                                        <br />
                                        Confira as regras:
                                    </p>
                                    <ExplainText>
                                        <b>Após 6</b> meses = 10% de desconto. É opcional, basta você clicar agora em
                                        ativar promoção.
                                        <br />
                                        <br />
                                        <b>Após 10</b> meses = 25% de desconto. O desconto entrará automaticamente.
                                    </ExplainText>
                                    <p>
                                        *Caso tenha alguma dúvida entre em contato com nossa{" "}
                                        <a href="https://api.whatsapp.com/send?phone=5511976922404">equipe.</a>
                                    </p>
                                    <hr />
                                </ExplainText>

                                <Flex justifyContent="flex-start" width="100%" margin="0 auto 20px 0">
                                    <CheckBox
                                        justifyContent="flex-start"
                                        width="100%"
                                        checked={values.promotedAll}
                                        name="promotedAll"
                                        onChange={handleChange}
                                        label={
                                            <>
                                                <PromoImage />
                                                Selecionar todos
                                            </>
                                        }
                                    />
                                </Flex>

                                {entryItems && (
                                    <ProductList>
                                        {entryItems.map((item, index) => (
                                            <SellProductItem
                                                key={index}
                                                item={item}
                                                handleChange={handleChange}
                                                checked={values.promotedItems?.includes(item.id.toString())}
                                                values={values}
                                                setValues={setValues}
                                            />
                                        ))}
                                    </ProductList>
                                )}

                                <Flex width="100%" padding="20px 0" justifyContent="space-between">
                                    <Button
                                        width="calc(50% - 8px)"
                                        margin="0"
                                        disabled={sendingRequest}
                                        onClick={() => handleSubmitPromotions("selected")}
                                        dark
                                        value={sendingRequest ? <LoadingSpinner /> : "Ativar"}
                                    />
                                    <Button
                                        width="calc(50% - 8px)"
                                        margin="0"
                                        disabled={sendingRequest}
                                        onClick={() => handleSubmitPromotions("all")}
                                        dark
                                        value={sendingRequest ? <LoadingSpinner /> : "Ativar em todos"}
                                    />
                                </Flex>
                            </Flex>
                        </>
                    )}
                    {/* {currentEntry.promotion_banner_type >= 6 && currentEntry.promotion_banner_type < 9 && (
                        <>
                            <WarningContainer>
                                <img alt="Exclamação" src={ExclamationIcon} />
                                <p>
                                    Seus produtos estão à venda há{" "}
                                    <strong>{currentEntry.promotion_banner_type} meses</strong>.
                                </p>
                            </WarningContainer>
                            <Flex
                                padding={isMobile ? "0 15px" : "0 30px"}
                                direction="column"
                                minWidth={!isMobile && "500px"}
                                boxSizing="border-box"
                            >
                                <ExplainText>
                                    <p>
                                        Que tal ativar a promoção para acelerar a venda das suas peças?
                                        <br />
                                        Criamos um plano de descontos progressivos para aumentar suas chances de vender.
                                        <br />
                                        Confira as regras:
                                    </p>
                                    <ExplainText>
                                        <b>Após 6</b> meses = 20% de desconto. É opcional, basta você clicar em ativar
                                        promoção.
                                        <br />
                                        <br />
                                        <b>Após 9</b> meses = 50% de desconto. O desconto entrará automaticamente.
                                    </ExplainText>
                                    <p>
                                        *Caso tenha alguma dúvida entre em contato com nossa{" "}
                                        <a href="https://api.whatsapp.com/send?phone=5511976922404">equipe.</a>
                                    </p>
                                    <hr />
                                </ExplainText>

                                <Flex justifyContent="flex-start" width="100%" margin="0 auto 20px 0">
                                    <CheckBox
                                        justifyContent="flex-start"
                                        width="100%"
                                        checked={values.promotedAll}
                                        name="promotedAll"
                                        onChange={handleChange}
                                        label={
                                            <>
                                                <PromoImage />
                                                Selecionar todos
                                            </>
                                        }
                                    />
                                </Flex>

                                {entryItems && (
                                    <ProductList>
                                        {entryItems.map((item, index) => (
                                            <SellProductItem
                                                key={index}
                                                item={item}
                                                handleChange={handleChange}
                                                checked={values.promotedItems?.includes(item.id.toString())}
                                                values={values}
                                                setValues={setValues}
                                            />
                                        ))}
                                    </ProductList>
                                )}

                                <Flex width="100%" padding="20px 0" justifyContent="space-between">
                                    <Button
                                        width="calc(50% - 8px)"
                                        margin="0"
                                        disabled={sendingRequest}
                                        onClick={() => handleSubmitPromotions("selected")}
                                        dark
                                        value={sendingRequest ? <LoadingSpinner /> : "Ativar"}
                                    />
                                    <Button
                                        width="calc(50% - 8px)"
                                        margin="0"
                                        disabled={sendingRequest}
                                        onClick={() => handleSubmitPromotions("all")}
                                        dark
                                        value={sendingRequest ? <LoadingSpinner /> : "Ativar em todos"}
                                    />
                                </Flex>
                            </Flex>
                        </>
                    )} */}
                    {currentEntry.promotion_banner_type >= 10 && currentEntry.promotion_banner_type < 18 && (
                        <>
                            <WarningContainer>
                                <img alt="Exclamação" src={ExclamationIcon} />
                                <p>
                                    Seus produtos estão à venda há{" "}
                                    <strong>{currentEntry.promotion_banner_type} meses</strong>.
                                </p>
                            </WarningContainer>

                            <Flex
                                padding={isMobile ? "0 15px" : "0 30px"}
                                direction="column"
                                minWidth={!isMobile && "500px"}
                                boxSizing="border-box"
                            >
                                <ExplainText>
                                    <p>
                                        Seguindo nossa regra de plano de descontos suas peças receberão desconto
                                        automático de 25%. Essa é sua chance de vender as suas peças que ainda não
                                        circularam.
                                        <br />
                                        <br />
                                        Confira os produtos:
                                    </p>
                                    <hr />
                                </ExplainText>

                                {entryItems && (
                                    <ProductList>
                                        {entryItems.map((item, index) => (
                                            <SellProductItem
                                                key={index}
                                                item={item}
                                                checked={values.promotedItems?.includes(item.id.toString())}
                                                values={values}
                                                setValues={setValues}
                                            />
                                        ))}
                                    </ProductList>
                                )}

                                <Flex width="100%" margin="2px 0 0 0" padding="0 0 20px 0">
                                    <Button
                                        width="100%"
                                        filled
                                        dark
                                        value="Dúvidas entre em contato "
                                        margin="0"
                                        onClick={handleContact}
                                    />
                                </Flex>
                            </Flex>
                        </>
                    )}
                    {currentEntry.promotion_banner_type >= 19 && (
                        <>
                            <WarningContainer>
                                <img alt="Exclamação" src={ExclamationIcon} />
                                <p>Você tem peças que passaram do prazo de contrato.</p>
                            </WarningContainer>

                            <Flex
                                padding={isMobile ? "0 15px" : "0 30px"}
                                direction="column"
                                minWidth={!isMobile && "500px"}
                                boxSizing="border-box"
                            >
                                <ExplainText>
                                    <p>
                                        Retire-as ou encaminhe para nossa ONG parceira para doação.
                                        <br />
                                        Lembrando que: O custo de retirada é por sua conta.
                                        <br />
                                        Optando pela retirada o prazo é de 10 dias corridos, após 10 dias as peças serão
                                        automaticamente doadas.
                                    </p>
                                    <hr />
                                </ExplainText>

                                {entryItems && (
                                    <ProductList>
                                        {entryItems.map((item, index) => (
                                            <SellProductItem
                                                key={index}
                                                item={item}
                                                checked={values.promotedItems?.includes(item.id.toString())}
                                                values={values}
                                                setValues={setValues}
                                            />
                                        ))}
                                    </ProductList>
                                )}
                            </Flex>

                            <Flex
                                alignItems="flex-start"
                                justifyContent="flex-start"
                                direction="column"
                                width="100%"
                                padding="30px"
                                margin="0 auto"
                                background="#F3F5F7"
                                boxSizing="border-box"
                            >
                                <img
                                    width="440px"
                                    src={EntryPromotionHero}
                                    alt="Juntos para fazer o bem!"
                                    onClick={() => navigate("/desapegue#ongs")}
                                />

                                <Flex width="100%" margin="2px 0 0 0">
                                    <Button
                                        width="calc(50% - 1px)"
                                        filled
                                        dark
                                        value="Quero doar"
                                        margin="0 2px 0 0"
                                        onClick={handleDonate}
                                    />
                                    <Button
                                        width="calc(50% - 1px)"
                                        filled
                                        dark
                                        value="Quero retirar"
                                        margin="0"
                                        onClick={handleTake}
                                    />
                                </Flex>
                            </Flex>
                        </>
                    )}
                </Body>
            </Container>
        )
    );
};

const Container = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 5;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
    overflow-y: auto;
    background: rgba(0, 0, 0, 0.7);
    padding: 2rem 0px;

    ${isMobileMediaQuery} {
        padding: 90px 0px;
        align-items: baseline;
        max-height: 100vh;
    }
`;

const Body = styled.div`
    position: relative;
    width: auto;
    height: auto;
    min-height: initial;
    max-height: initial;
    max-width: 90vw;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px 0 rgba(52, 52, 59, 0.15);
    padding: 0px;
    margin: auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    ${isMobileMediaQuery} {
        padding: 0px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        max-width: 95vw;
        max-height: initial;
        height: auto;
    }

    ${({ fullHero }) =>
        fullHero &&
        css`
            max-width: 90vw !important;
        `}
`;

const CloseButton = styled.button`
    padding: 0px;
    position: absolute;
    width: 18px;
    height: 18px;
    top: 0px;
    right: 0px;
    margin: 19px;
    border: 0px;
    background: transparent;
    cursor: pointer;
    z-index: 20;

    svg {
        transform: scale(0.8);
        path {
            fill: #34343c;
        }
    }
`;

const WarningContainer = styled(Flex)`
    max-width: 500px;
    width: 100%;
    height: 120px;
    background-color: #f3f5f7;
    padding: 30px 120px 30px 28px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;

    img {
        top: 50%;
        right: -36px;
        transform: translateY(-50%);
        position: absolute;
        width: 58px;
        height: 58px;
        background-color: #f3808b;
        padding: 42px;
        border-radius: 100%;
    }

    p {
        font-family: "SackersGothicStd";
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #34343b;
        margin: 0;

        strong {
            color: #e4858c;
            font-weight: 900;
        }
    }
`;

const ProductList = styled(Flex)`
    max-height: 300px;
    max-width: 440px;
    overflow-y: auto;

    padding: 26px 0;
    border-top: 1px solid #e5e5e8;
    border-bottom: 1px solid #e5e5e8;

    & > div:not(:last-child) {
        padding-bottom: 26px;
        margin-bottom: 26px;
        border-bottom: 1px solid #e5e5e8;
    }
`;

const ExplainText = styled.p`
    max-width: 440px;
    padding: 15px 0px 15px 0px;

    object-fit: contain;
    font-family: "Graphik";
    font-size: 13px;
    line-height: 1.15;
    text-align: left;
    color: #34343b;
    box-sizing: border-box;

    b {
        color: #34343b;
        font-weight: 600;
    }

    strong {
        color: #e4858c;
        font-weight: 600;
    }

    hr {
        margin-top: 22px;
        border: none;
        height: 5px;
        width: 70px;
        background-color: #e4858c;
        margin-left: 0;
        margin-right: auto;
    }
`;

const PromoImage = styled.img.attrs(() => ({
    src: PromoIcon,
    alt: "Promo",
}))`
    width: 20px;
    height: auto;
    margin-right: 7px;
`;

export default EntryPromotionModal;
