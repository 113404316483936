import React from "react";

import styled from "styled-components";
import { isMobileMediaQuery } from "../../helpers/styles";
import ProductItem from "../ProductItem";

const ProductsGrid = ({ locale, products, handleItemAction, handleScrollYPosition, handleItemPreview, ...props }) => {
    const handleClick = (item, index) => {
        // eslint-disable-next-line
        dataLayer.push({ ecommerce: null });
        // eslint-disable-next-line
        dataLayer.push({
            event: "productClick",
            ecommerce: {
                click: {
                    actionField: { list: locale ? locale : "" },
                    products: [
                        {
                            name: item.name,
                            id: item.id,
                            price: item.price_label,
                            brand: item.brand.name,
                            category: item.item_category.name,
                            position: index + 1,
                        },
                    ],
                },
            },
        });
    };

    return (
        <Container>
            {products &&
                products.map((item, index) => (
                    <ProductItem
                        key={`product_${index}`}
                        link={`/produto/{item.slug}`}
                        title={<>{item.name.toLowerCase()}</>}
                        tag={item.brand.name}
                        slug={item.slug}
                        price={parseFloat(item.price_label)}
                        image={item.images[0]}
                        item={item}
                        card
                        onClick={(e) => {
                            handleClick(item, index);
                            handleScrollYPosition(window.scrollY);
                        }}
                    />
                ))}
        </Container>
    );
};

const Container = styled.div`
    margin-top: 50px;
    margin-bottom: 40px;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 36px;
    grid-row-gap: 43px;

    ${isMobileMediaQuery} {
        margin-top: 17px;
        padding: 5px;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 10px;
    }
`;

export default ProductsGrid;
