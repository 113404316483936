import React, { useState, useEffect } from "react";
import { useLocation, useParams} from "react-router-dom";
import styled from "styled-components";
import moment from "moment";
import "moment/locale/pt-br";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import api from "../../services/api";
import { generateUUID } from "../../components/Functions";
import { Flex, isMobile, isMobileMediaQuery } from "./../../helpers/styles";
import Header from "./../../components/Header";
import BlogItem from "../../components/BlogItem";
import Footer from "../../components/Footer";
import Testimonials from "../../components/Testimonials";
import SectionTitle from "../../components/SectionTitle";
import Loading from "../../components/Loading";
import PinterestIcon from "./../../assets/images/pinterest.svg";
import ShareIcon from "./../../assets/images/share-icon.png";
import FacebookIcon from "./../../assets/images/facebook.svg";
import ClockIcon from "./../../assets/images/icon-clock.png";

const BlogPost = () => {
    const location = useLocation();
    const { post_slug } = useParams();
    const [blogPostData, setBlogPostData] = useState(undefined);
    const user = useSelector((state) => state.user);

    useEffect(() => {
        setBlogPostData(undefined);
        loadBlogPost();

        // eslint-disable-next-line
    }, [location.pathname]);

    const sendEvent = async (blogPost) => {
        let userSessionServerData = window.userCurrentServerData(user);
        let event_id = generateUUID();
        let event_time = Math.round(Date.now() / 1000);

        window.fbq(
            "trackCustom",
            "ViewContent",
            {
                content_name: blogPost.title,
                content_ids: [post_slug],
            },
            { eventID: event_id }
        );

        await api
            .post("/facebook_ads_event", {
                data: [
                    {
                        event_name: "ViewContent",
                        event_id: event_id,
                        event_time: event_time,
                        event_source_url: window.location.href,
                        action_source: "website",
                        user_data: userSessionServerData,
                        custom_data: {
                            content_name: blogPost.title,
                            content_ids: [post_slug],
                        },
                    },
                ],
            })
            .then((res) => console.log("EVENTO ViewContent", res.data))
            .catch((res) => res.response);

        // eslint-disable-next-line
        dataLayer.push({ ecommerce: null });
        // eslint-disable-next-line
        dataLayer.push({
            event: "virtualPageview",
            page: document.title,
            type: "Blog",
            environment: process.env.REACT_APP_ENV,
            user: [
                {
                    name: user.first_name && user.last_name ? user.first_name + " " + user.last_name : "",
                    username: user.slug ? user.slug : "",
                    user_id: user.id ? user.id : "",
                    email: user.email ? user.email : "",
                    language: "pt-br",
                    returning: JSON.parse(localStorage.getItem("accepted_cookies")) ? true : false,
                },
            ],
        });
    };

    const loadBlogPost = async () => {
        let response = await api.get(`/blog_posts/${post_slug}?clearCache=true`);

        if (response.status === 200) {
            setBlogPostData({ ...response.data });
            sendEvent(response.data);
        } else {
            setBlogPostData({});
        }
    };

    return (
        <>
            {blogPostData && (
                <Helmet>
                    <title>Re Petit: {blogPostData.title}</title>
                    <meta property="og:title" content={blogPostData.title} />
                    <meta property="og:type" content="blog" />
                    <meta name="description" content={blogPostData.quote} />
                    <meta property="og:description" content={blogPostData.quote} />
                    <meta property="og:image" content={blogPostData.image_url} />
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:site_name" content="Re Petit" />

                    <meta name="twitter:title" content={blogPostData.title} />
                    <meta name="twitter:description" content={blogPostData.quote} />
                    <meta name="twitter:image" content={blogPostData.image_url} />
                </Helmet>
            )}

            <Header />
            <Container>
                {!blogPostData ? (
                    <Loading />
                ) : (
                    <ContentDelimiter>
                        <Flex
                            direction="column"
                            alignItems="flex-start"
                            justifyContent="flex-start"
                            order="-1"
                            padding={isMobile && "0px 18px"}
                        >
                            <Category>{blogPostData.category.title}</Category>
                            <Title>{blogPostData.title}</Title>
                        </Flex>
                        <Flex
                            order={isMobile ? "0" : "-1"}
                            width="calc(100% - 36px)"
                            padding={isMobile ? "10px 18px" : ""}
                            margin="0px auto 20px 0"
                            justifyContent="flex-start"
                        >
                            <img src={ClockIcon} width="21px" height="21px" alt="Clock" />

                            <PublicationDate>
                                Publicado {moment(blogPostData.published_at).fromNow()}, por
                            </PublicationDate>
                            <Author
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={(e) => e.stopPropagation()}
                                as={blogPostData.author && blogPostData.author.instagram_username && "a"}
                                href={
                                    blogPostData.author &&
                                    blogPostData.author.instagram_username &&
                                    `https://www.instagram.com/${blogPostData.author.instagram_username}`
                                }
                            >
                                @{blogPostData.author.name.trim().replace(/\s/g, "_")}
                            </Author>
                        </Flex>

                        <PostImage src={blogPostData.image_url+'?w=1222&auto=format,compress'} width="100%" alt="Blog cover" />

                        <Flex direction={isMobile ? "column" : "row"} margin={isMobile ? "0px" : "35px 0 35px 0"}>
                            <Testimonials
                                bars
                                width={isMobile ? "calc(100% - 36px)" : "240px"}
                                margin={isMobile ? "30px 0px" : "0 35px 0 0"}
                                padding={isMobile ? "40px 18px" : "40px"}
                                vertical
                                background="#F7F9F9"
                                quote={blogPostData.quote}
                            />
                            <TextSecion padding={isMobile ? "0px 18px" : "10px 0"} flex="1">
                                {documentToReactComponents(blogPostData.body)}
                                <span />
                            </TextSecion>
                        </Flex>

                        <ShareArticle>
                            <hr />
                            <p>Gostou? Compartilhe!</p>
                            <a
                                href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                Facebook Logo
                            </a>

                            {navigator && navigator.share !== undefined && (
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();

                                        if (navigator && navigator.share !== undefined && navigator.share) {
                                            navigator
                                                .share({
                                                    title: blogPostData.title,
                                                    text: blogPostData.summary || "",
                                                    url: window.location.href,
                                                })
                                                .catch((error) => console.log("Error sharing", error));
                                        } else {
                                            console.log("Share not supported on this browser, do it the old way.");
                                        }
                                    }}
                                >
                                    Share Icon
                                </button>
                            )}
                        </ShareArticle>

                        {isMobile ? (
                            <SectionTitle>Leia Também</SectionTitle>
                        ) : (
                            <ReadMoreTitle>Leia Também</ReadMoreTitle>
                        )}

                        <Flex
                            direction={isMobile ? "column" : "row"}
                            justifyContent="space-between"
                            alignItems="flex-start"
                        >
                            {blogPostData &&
                                blogPostData.related_posts &&
                                blogPostData.related_posts.map((item, index) => (
                                    <BlogItem
                                        key={index}
                                        category={item.category.title}
                                        title={item.title}
                                        description={item.quote}
                                        author={item.author.name}
                                        background={item.image_url}
                                        link={`/blog/post/${item.slug}`}
                                    />
                                ))}
                        </Flex>
                    </ContentDelimiter>
                )}
            </Container>

            <Footer />
        </>
    );
};

const Container = styled.div`
    height: 100%;
    flex-grow: 1;
    min-height: 100%;
    margin-top: -50px;

    ${isMobileMediaQuery} {
        margin-top: 0px;
    }
`;

const ContentDelimiter = styled.div`
    width: 100%;
    margin: auto;
    max-width: 1222px;
    padding-top: 34px;

    display: flex;
    flex-direction: column;
`;

const PostImage = styled.img`
    order: -1;

    ${isMobileMediaQuery} {
        height: 200px;
        object-fit: cover;
    }
`;

const Category = styled.div`
    order: -1;
    margin: 0;
    margin-bottom: 13px;
    text-transform: uppercase;
    font-family: "SackersGothicStd";
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.9;
    letter-spacing: 3.2px;
    text-align: left;
    color: #e4858c;

    ${isMobileMediaQuery} {
        font-size: 11px;
        margin-bottom: 15px;
    }
`;

const Title = styled.h1`
    order: -1;
    margin: 0;
    margin-bottom: 9px;
    font-family: "DMSerifDisplay";
    font-size: 54px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    ${isMobileMediaQuery} {
        font-size: 32px;
        margin-top: 0px;
        margin-bottom: 19px;
        max-width: 80%;
        line-height: 0.85;
    }
`;

const PublicationDate = styled.p`
    margin: 0px;
    margin-left: 9px;
    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #676777;
`;

const Author = styled(PublicationDate)`
    margin-left: 5px;
    color: #e4858c;
`;

const ReadMoreTitle = styled.h1`
    margin: 0px;
    margin-top: 30px;
    margin-bottom: 47px;
    text-transform: uppercase;
    font-family: "SackersGothicStd";
    font-size: 21px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.95;
    letter-spacing: 4.2px;
    text-align: left;
    color: #34343b;
`;

const TextSecion = styled(Flex)`
    white-space: pre-line;
    font-family: "Graphik";
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #676777;
    display: inline-block;

    & > :nth-last-child(2) {
        display: inline;
    }

    span {
        margin-left: 10px;
        display: inline-block;
        background-color: #e4858c;
        width: 10px;
        height: 10px;
    }

    ${isMobileMediaQuery} {
        order: -1;
    }
`;

const ShareArticle = styled(Flex)`
    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #676777;

    a {
        object-fit: contain;
        margin: auto;
        width: 29px;
        height: 29px;
        color: transparent;
        overflow: hidden;
        background-color: #e4858c;
        mask-size: cover;
        mask-position: center;

        :nth-of-type(1) {
            mask-image: url(${FacebookIcon});
        }
        :nth-of-type(2) {
            mask-image: url(${ShareIcon});
        }
        :nth-of-type(3) {
            mask-image: url(${PinterestIcon});
        }
        :first-of-type {
            margin-left: 20px;
        }
        :not(:last-child) {
            margin-right: 7px;
        }
    }

    button {
        object-fit: contain;
        margin: auto;
        width: 29px;
        height: 29px;
        color: transparent;
        overflow: hidden;
        background-color: #e4858c;
        mask-size: cover;
        mask-position: center;
        border: none;
        padding: 0;

        :nth-of-type(1) {
            mask-image: url(${ShareIcon});
        }
    }

    ${isMobileMediaQuery} {
        background-color: #f7f9f9;

        padding: 0px 19px;
        p {
            margin-right: auto;
        }

        a {
            margin: auto 0px;

            :first-of-type {
                margin-left: auto;
            }
            :not(:last-of-type) {
                margin-right: 7px;
            }
        }
    }

    hr {
        flex: 1;
        border: 0;
        border-top: 1px solid #eff0f1;
        margin-right: 20px;

        ${isMobileMediaQuery} {
            display: none;
        }
    }
`;

export default BlogPost;
