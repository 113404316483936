import React from "react";
import styled from "styled-components";
import { isMobileMediaQuery } from "../../helpers/styles";

function SectionTitle({ margin, barWidth, ...props }) {
    return (
        <Container className="section-title" margin={margin} {...props}>
            <Bar barWidth={barWidth} />
            {props.title || props.children}
            <Bar barWidth={barWidth} />
        </Container>
    );
}

const Container = styled.div`
    display: flex;
    align-items: center;
    margin: ${({ margin }) => (margin ? margin : "40px 0")};
    width: 100%;

    font-family: "SackersGothicStd";
    text-transform: uppercase;
    font-size: 21px;
    font-weight: 900;
    letter-spacing: 4.2px;
    text-align: center;
    color: #34343b;

    ${isMobileMediaQuery} {
        font-size: 14px;
        letter-spacing: 2.8px;
        color: #34343c;
    }
`;

const Bar = styled.div`
    flex: 1;
    background-color: #34343b44;
    height: 1px;
    min-width: ${(props) => (props.barWidth ? props.barWidth : "10%")};
    :first-child {
        margin-right: 2rem;
    }
    :last-child {
        margin-left: 2rem;
    }

    ${isMobileMediaQuery} {
        :first-child {
            margin-right: 1.5rem;
        }
        :last-child {
            margin-left: 1.5rem;
        }
    }
`;

export default SectionTitle;
