import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Flex } from "../../helpers/styles";
import Button from "../../components/Button";
import BoxCancelledImage from "../../assets/images/box-cancelado.jpg";

function BoxCancelled(props) {
    const navigate = useNavigate();
    return (
        <Flex direction="column" width="911px" height="100%" margin="170px auto 166px auto">
            <HeroImage src={BoxCancelledImage} alt="Box Cancelado" />
            <Button
                padding="0"
                margin="0px auto 48px auto"
                dark
                link
                value="Espiar a loja"
                onClick={() => navigate("/")}
            />
            <Button width="250px" margin="0" dark filled value="Voltar para a home" onClick={() => navigate("/")} />
        </Flex>
    );
}

const HeroImage = styled.img`
    margin-top: auto;
    margin-bottom: 49px;
`;

export default BoxCancelled;
