import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { Flex, isMobile, isMobileMediaQuery, notMobileMediaQuery } from "./../../helpers/styles";
import { capitalize } from "../../helpers";
import IconMinus from "../../assets/images/icon-minus.png";
import IconPlus from "../../assets/images/icon-plus.png";
import AvatarPlaceholder from "../../assets/images/avatar-placeholder.jpg";
import ExpandableContainer from "../../components/ExpandableContainer";

function AccountHeader({ user, profile, history, currentTab }) {
    const tabs = {
        box: "Meu Box",
        pedidos: "Meus Pedidos",
        vendas: "Meus Produtos",
        creditos: "Meus Créditos",
        cadastro: "Meu Cadastro",
        indique: "Indique a Re Petit",
    };
    const [expandAccountDetails, setExpandAccountDetails] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setExpandAccountDetails(false);
        setMobileMenuOpen(false);
    }, [currentTab]);

    return (
        <Container
            flex="1"
            width={isMobile ? "100%" : "350px"}
            margin="0 0 0 auto"
            justifyContent="flex-start"
            direction="column"
            background={
                !isMobile && "linear-gradient(90deg, #7ca8af 0%, #7ca8af 25%, #67677a 25%, #67677a 50%, #5d5d6c 50%)"
            }
            wrap="no-wrap"
        >
            <Flex width="100%" direction={isMobile ? "column" : "row"}>
                <Flex
                    justifyContent={!isMobile ? "center" : "flex-start"}
                    width={!isMobile ? "280px" : "100%"}
                    margin="0"
                    background="#67677a"
                    padding={!expandAccountDetails && isMobile ? "16px" : "32px 16px"}
                    boxSizing="border-box"
                    position="relative"
                    direction={expandAccountDetails || !isMobile ? "column" : "row"}
                >
                    <Flex
                        position="initial"
                        alignItems={expandAccountDetails ? "center" : "flex-start"}
                        direction="column"
                    >
                        <AvatarBackground expanded={expandAccountDetails} />
                        <Avatar
                            expanded={expandAccountDetails}
                            margin={isMobile && !expandAccountDetails ? null : "0 0 31px 0"}
                            placeholder={user.profile_picture ? true : undefined}
                            src={user.profile_picture || AvatarPlaceholder}
                        />
                    </Flex>
                    <Flex alignItems={expandAccountDetails || !isMobile ? "center" : "flex-start"} direction="column">
                        <HeaderText center={expandAccountDetails || !isMobile}>
                            <p>Olá, {capitalize(user.first_name || user.last_name)}</p>
                            <span>Juntos desde {moment(profile.created_at).format("YYYY")}</span>
                        </HeaderText>
                        <HeaderText>
                            <Link to="/logout">Sair</Link>
                        </HeaderText>
                    </Flex>

                    {isMobile && (
                        <ExpandDetailsButton onClick={() => setExpandAccountDetails(!expandAccountDetails)}>
                            <img src={expandAccountDetails ? IconMinus : IconPlus} alt="Expandir" />
                        </ExpandDetailsButton>
                    )}
                </Flex>

                <Flex
                    width="100%"
                    margin="0"
                    padding={isMobile ? "23px 0 25px 0" : "45px 36px"}
                    boxSizing="border-box"
                    background="#5D5D6C"
                    desktop={!expandAccountDetails}
                >
                    <HeaderText center={!isMobile}>
                        <div>
                            De olho na sua conta!{" "}
                            <QuestionContainer>
                                ?{" "}
                                <QuestionDropDown>
                                    <Backdrop />
                                    <Arrow />
                                    <strong>Saldo futuro</strong> = saldo aguardando liberação, para ter certeza que a
                                    peça não foi devolvida, prazo de até 30 dias. <br />
                                    <strong>Saldo liberado para resgate</strong> = saldo que já pode ser utilizado em
                                    compras ou sacado. <br />
                                    <strong>Vendido</strong> = valor total de vendas já aprovadas até o momento.
                                    <br />
                                    <strong>À venda</strong> = saldo das suas peças ainda à venda
                                    <br />
                                    <strong>Crédito</strong> = valor estornado de produto(s) devolvido(s)
                                </QuestionDropDown>
                            </QuestionContainer>
                        </div>
                        <span>Vendido: </span>R${" "}
                        {profile.total_sales.toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}
                        <br />
                        <span>À venda: </span>
                        R${" "}
                        {profile.total_for_sale.toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}
                        <br />
                        <span>Liberado para resgate: </span>R$
                        {profile.balance.toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}
                        <br />
                        <span>Saldo futuro: </span>
                        R${" "}
                        {profile.future_balance.toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}
                        <br />
                        <span>Crédito: </span>R$
                        {profile.credit.toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}
                    </HeaderText>
                </Flex>
            </Flex>

            <Flex
                alignItems="flex-start"
                justifyContent="flex-start"
                height="auto"
                width="100%"
                margin="0 0 0 0"
                background="#f3f5f5"
                desktop
            >
                <NavigationItem active={currentTab === "box"} onClick={() => navigate("/minha-conta/box")}>
                    Meu Box
                </NavigationItem>
                <NavigationItem active={currentTab === "pedidos"} onClick={() => navigate("/minha-conta/pedidos")}>
                    Meus pedidos
                </NavigationItem>
                <NavigationItem active={currentTab === "vendas"} onClick={() => navigate("/minha-conta/vendas")}>
                    Meus Produtos
                </NavigationItem>
                <NavigationItem active={currentTab === "creditos"} onClick={() => navigate("/minha-conta/creditos")}>
                    Meus Créditos
                </NavigationItem>
                <NavigationItem active={currentTab === "cadastro"} onClick={() => navigate("/minha-conta/cadastro")}>
                    Meu cadastro
                </NavigationItem>
                <NavigationItem active={currentTab === "indique"} onClick={() => navigate("/minha-conta/indique")}>
                    indique a re petit
                </NavigationItem>
            </Flex>

            <Flex height="auto" width="100%" direction="column" background="#f3f5f5" mobile>
                <ExpandableContainer
                    value={mobileMenuOpen}
                    onChange={(value) => value !== undefined && setMobileMenuOpen(value)}
                    titlePadding="0 20px"
                    titleBoxSizing="border-box"
                    title={tabs[currentTab] || "Meu Box"}
                    bodyPadding="0 22px"
                >
                    <Flex direction="column" alignItems="flex-start">
                        <NavigationItem active={currentTab === "box"} onClick={() => navigate("/minha-conta/box")}>
                            Meu Box
                        </NavigationItem>
                        <NavigationItem
                            active={currentTab === "pedidos"}
                            onClick={() => navigate("/minha-conta/pedidos")}
                        >
                            Meus pedidos
                        </NavigationItem>
                        <NavigationItem
                            active={currentTab === "vendas"}
                            onClick={() => navigate("/minha-conta/vendas")}
                        >
                            Meus Produtos
                        </NavigationItem>
                        <NavigationItem
                            active={currentTab === "creditos"}
                            onClick={() => navigate("/minha-conta/creditos")}
                        >
                            Meus Créditos
                        </NavigationItem>
                        <NavigationItem
                            active={currentTab === "cadastro"}
                            onClick={() => navigate("/minha-conta/cadastro")}
                        >
                            Meu cadastro
                        </NavigationItem>
                        <NavigationItem
                            active={currentTab === "indique"}
                            onClick={() => navigate("/minha-conta/indique")}
                        >
                            indique a re petit
                        </NavigationItem>
                    </Flex>
                </ExpandableContainer>
            </Flex>
        </Container>
    );
}

const Container = styled(Flex)`
    box-shadow: 0px 1px 2px 0 rgba(52, 52, 59, 0.15);
    flex-grow: 0;
    border-bottom: 5px solid #7ca8af;
`;

const AvatarBackground = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 91px;
    background: #7ca8af;

    ${isMobileMediaQuery} {
        ${({ expanded }) =>
            !expanded &&
            css`
                right: 0px;
                height: auto;
                bottom: 0px;
                width: 47px;
            `};
    }
`;

const Avatar = styled.img`
    z-index: 1;
    width: 110px;
    height: 110px;
    margin: ${(props) => (props.margin ? props.margin : "auto 25px auto 0")};
    object-fit: contain;
    border: solid 1px #ffffff;
    background-color: #aab8b6;
    border-radius: 100%;
    overflow: hidden;
    box-sizing: border-box;

    ${({ placeholder }) =>
        placeholder &&
        css`
            object-
        `};

    ${isMobileMediaQuery} {
        width: 60px;
        height: 60px;

        ${({ expanded }) =>
            expanded &&
            css`
                width: 110px;
                height: 110px;
            `};
    }
`;

const QuestionDropDown = styled.div`
    position: absolute;
    top: calc(100% + 18px);
    left: 50%;
    transform: translateX(-50%);
    width: 320px;
    min-width: 156px;
    padding: 16px;
    border-radius: 4px;
    background-color: #f3f5f5;
    color: #34343c;
    box-shadow: 0px 2px 6px 0 rgba(52, 52, 64, 0.16);
    display: block;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 5;
    line-height: 1;

    p {
        margin: 0px;

        font-family: "Graphik";
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 17.5px;
        letter-spacing: normal;
        text-align: left;
        color: #8c8ca1;

        :hover,
        &.active {
            color: #e4858c;
        }
    }
`;

const QuestionContainer = styled.div`
    display: inline-flex;
    border: 2px solid #fff;
    font-size: 13px;
    width: 23px;
    height: 23px;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    line-height: 0;
    vertical-align: middle;
    text-align: center;
    margin-left: 0.5rem;
    cursor: pointer;
    position: relative;

    ${QuestionDropDown} {
        display: none;
    }

    :hover ${QuestionDropDown} {
        display: block;
    }
`;

const HeaderText = styled.div`
    font-size: 15px;
    line-height: 17px;
    color: #ffffff;

    & > ${QuestionContainer}, & > p {
        margin: 0 0 6px 0;
    }

    & > a,
    & > span {
        font-size: 13px;
    }

    & > a {
        color: #fff;
    }

    & > span {
        color: #b1b1c9;
    }

    ${({ padding }) => padding && `padding: ${padding};`}

    ${(props) =>
        props.center &&
        css`
            &,
            & > * {
                text-align: center;
            }
        `}
`;

export const NavigationItem = styled.button`
    margin: auto 0;
    text-transform: uppercase;
    border: 0;
    background-color: transparent;
    cursor: pointer;

    object-fit: contain;
    font-family: "SackersGothicStd";
    font-size: 11.5px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: 2.3px;
    text-align: left;
    color: #8c8ca1;

    ${(props) =>
        props.active &&
        css`
            color: #e4858c;
        `}

    ${notMobileMediaQuery} {
        width: 100%;
        padding: 16px;

        :not(:last-child) {
            border-bottom: 1px solid #fff;
        }
    }

    ${isMobileMediaQuery} {
        width: 100%;
        box-sizing: border-box;
        padding: 20px 0;
        :not(:last-child) {
            border-bottom: 1px solid #e6e7e9;
        }
    }
`;

const Backdrop = styled.div`
    background-color: transparent;
    position: absolute;
    top: -46px;
    bottom: 0px;
    left: 0px;
    right: 0px;
`;

const ExpandDetailsButton = styled.button`
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: none;

    box-sizing: border-box;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
`;

const Arrow = styled.div`
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #f3f5f5;
    filter: drop-shadow(0px -2px 1px rgba(52, 52, 64, 0.1));

    ${isMobileMediaQuery} {
        right: 80px;
    }
`;

export default AccountHeader;
