import React from "react";
import styled, { css } from "styled-components";
import { Flex } from "../../helpers/styles";

const CustomTable = ({ columns, rows, width, ...props }) => {
    return (
        <Flex
            justifyContent="flex-start"
            width={width}
            maxWidth="100%"
            overflow="auto visible"
            {...props}
        >
            <Container>
                <thead>
                    <tr>
                        {columns &&
                            columns.map((column, index) => (
                                <ColumnHead
                                    key={`column_${index}`}
                                    align={column.align}
                                    width={column.width}
                                >
                                    {column.label}
                                </ColumnHead>
                            ))}
                    </tr>
                </thead>
                <tbody>
                    {rows &&
                        rows.map((row, row_index) => (
                            <tr key={`row_${row_index}`}>
                                {columns &&
                                    columns.map((column, column_index) => (
                                        <TableCell
                                            key={`cell_${column_index}`}
                                            align={column.align}
                                            width={column.width}
                                        >
                                            {row[column.name]}
                                        </TableCell>
                                    ))}
                            </tr>
                        ))}
                </tbody>
            </Container>
        </Flex>
    );
};

const Container = styled.table`
    width: 100%;
    border-spacing: 0px;
    border-collapse: separate;
    box-shadow: 0px 1px 2px 0px rgba(52, 52, 59, 0.15);
    min-width: 560px;

    th {
        font-family: "SackersGothicStd";
        font-size: 16px;
        font-weight: 900;
        text-transform: uppercase;
        line-height: 2;
        color: #84a5ac;

        padding: 0px;
        margin: 0px;
        border: 0px;
        border-bottom: 3px solid #bed4d7;
    }

    th,
    td {
        padding: 18px 20px;

        :not(:last-child) {
            border-right: 1px solid #bed4d7;
        }
    }

    td {
        height: 14px;
        object-fit: contain;
        font-family: Graphik;
        font-size: 18px;
        line-height: 1.56;
        color: #676777;
    }

    tbody tr:nth-child(even) {
        background-color: #f4f4f5;
    }
`;

const ColumnHead = styled.th`
    ${(props) => css`
        text-align: ${props.align || "left"};
        width: ${props.width || "auto"};
    `}
`;

const TableCell = styled.td`
    ${(props) => css`
        text-align: ${props.align || "left"};
        width: ${props.width || "auto"};
    `}
`;

export default CustomTable;
