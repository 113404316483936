import React from "react";
import styled from "styled-components";

import { isMobileMediaQuery } from "../../helpers/styles";

import CrossIcon from "../../assets/images/cross.svg";
import Button from "../Button";

function BoxItem(props) {
    return (
        <Container
            id={props.id}
            coloredOnHover={props.coloredOnHover}
            small={props.small}
            background={props.background}
            horizontal={props.horizontal}
            onClick={props.onClick}
        >
            <BlurryContainer small={props.small} horizontal={props.horizontal}>
                <Title small={props.small} horizontal={props.horizontal}>
                    {props.horizontal
                        ? props.title.replace(" ", "\n")
                        : props.title}
                </Title>
                {props.description && (
                    <Description
                        small={props.small}
                        horizontal={props.horizontal}
                    >
                        {props.description}
                    </Description>
                )}
                {props.button && !props.horizontal && (
                    <Button
                        small={props.small}
                        dark
                        borderradius
                        fluid={!props.small}
                        value={props.button}
                        onClick={props.buttonAction}
                    />
                )}
                {props.horizontal && (
                    <PlusButton>
                        <div />
                    </PlusButton>
                )}
            </BlurryContainer>
        </Container>
    );
}

const Container = styled.div`
    background-image: url("${({ background }) => background}");
    background-position: 50% 0%;
    background-size: cover;
    background-repeat: no-repeat;
    height: ${({ small }) => (small ? "300px" : "400px")};
    width: auto;
    flex: 0;
    width: auto;
    min-width: ${({ small }) => (small ? "201px" : "279px")};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;

    :not(:last-child) {
        margin-right: 1rem;
    }

    ${({ coloredOnHover }) =>
        coloredOnHover &&
        `
        transition: 0.125s filter ease-in-out;
        filter: grayscale(1);
        :hover {
            filter: grayscale(0);
        }
    `}

    ${(props) =>
        props.horizontal &&
        `
        width: 100%;
        height: 200px;

        :not(:last-child) {
            margin-right: 0;
            margin-bottom: 20px;
        }
    `};
`;

const Title = styled.h2`
    margin-bottom: 20px;
    font-size: ${({ small }) => (small ? "24px" : "32px")};
    line-height: 0.63;
    font-weight: normal;
    text-align: center;
    color: #34343b;

    ${isMobileMediaQuery} {
        width: 100%;
        font-size: 24px;
    }

    ${(props) =>
        props.horizontal &&
        `
        text-align: left;
        margin-top: 0px;
        line-height: 1;
        white-space: pre-wrap;
    `}
`;

const Description = styled.p`
    line-height: 1.2;
    text-align: center;
    color: #34343b;
    font-size: ${({ small }) => (small ? "13px" : "15px")};
    margin: auto;
    margin-bottom: 4px;

    ${isMobileMediaQuery} {
        width: 100%;
        margin: 0px;
        font-size: 13px;
    }

    ${(props) =>
        props.horizontal &&
        `
        text-align: left;
    `}
`;

const BlurryContainer = styled.div`
    padding: 0 ${({ small }) => (small ? "0px" : "20px")};
    width: calc(100% - ${({ small }) => (small ? "0px" : "40px")});
    min-height: 40%;
    color: #444;
    backdrop-filter: blur(5px);
    background-color: rgb(255 255 255 / 60%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${(props) =>
        props.horizontal &&
        `
        width: calc(50% - ${props.small ? "0px" : "40px"});
        height: 100%;
        margin-left: auto;
        padding-top: 24px;
        padding-bottom: 24px;
        justify-content: flex-start;
    `};
`;

const PlusButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0px;
    right: 0px;
    height: 40px;
    width: 40px;
    background-color: #f37f8a;

    div {
        width: 24px;
        height: 24px;
        background-color: #fff;
        mask-size: cover;
        mask-position: center;
        mask-image: url(${CrossIcon});
    }
`;

export default BoxItem;
