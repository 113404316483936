import { SEND_CHECKOUT, CLEAR_CHECKOUT } from "../actions/checkout";

const initialState = {};

export default (state = initialState, action) => {
    switch (action.type) {
        case SEND_CHECKOUT:
            return { ...action.payload };

        case CLEAR_CHECKOUT:
            return initialState;

        default:
            return state;
    }
};
