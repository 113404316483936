import React, { forwardRef } from "react";
import styled, { css } from "styled-components";
import LoadingSpinner from "../LoadingSpinner";

const ButtonLink = (
    { value, children, onClick, dark, disabled, loading, ...props },
    ref
) => {
    return (
        <Container
            as="a"
            onClick={!loading ? onClick : null}
            dark={dark ? 1 : null}
            disabled={disabled}
            ref={ref}
            {...props}
        >
            {loading ? <LoadingSpinner dark={dark} /> : value || children}
        </Container>
    );
};

const Container = styled.button`
    display: inline-flex;
    order: ${({ order }) => (order ? order : undefined)};
    justify-content: ${(props) => props.justifyContent && props.justifyContent};
    align-items: center;
    max-height: 40px;
    font-family: inherit;
    background-color: transparent;
    color: ${(props) => (props.dark ? "#34343b" : "#fff")};
    border: none;

    padding: ${(props) => (props.padding ? props.padding : "0")};
    margin: ${(props) => (props.margin ? props.margin : "0")};
    text-decoration: underline;
    ${(props) => props.fluid && !props.width && "width: 100%;"};
    ${(props) => props.width && `width: ${props.width};`};
    ${(props) => props.maxWidth && `max-width: ${props.maxWidth};`};
    ${(props) => props.minWidth && `min-width: ${props.minWidth};`};
    box-sizing: border-box;

    font-size: inherit;
    font-weight: 600;
    text-align: center;
    cursor: ${(props) =>
        props.onClick || props.to || props.type === "submit" || props.href
            ? "pointer"
            : "inherit"};

    ${(props) =>
        props.disabled &&
        css`
            opacity: 0.45;
            pointer-events: none;
        `};
`;

export default forwardRef(ButtonLink);
