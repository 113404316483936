import React from "react";
import styled, { css } from "styled-components";

import "pure-react-carousel/dist/react-carousel.es.css";

import Carousel from "nuka-carousel";

import {
    breakpoints,
    isMobileMediaQuery,
    isMobile,
    notMobileMediaQuery,
} from "../../helpers/styles";


import { ReactComponent as ChevronLeft } from "./../../assets/images/chevron-left.svg";
import { Link , useNavigate } from "react-router-dom";

const BrandsCarroussel = ({
    title,
    items,
    locale,
    ...props
}) => {
    const visibleSlides = isMobile ? 1 : items.length > 4 ? 4 : items.length;

    return (
        <Container>
            {title && (
                <Column>
                    <Border top />

                    <Title>{title}</Title>

                    <Border />
                </Column>
            )}

            <Carousel
                autoplay
                wrapAround
                disableEdgeSwiping
                cellSpacing={16}
                slidesToShow={isMobile ? 1 : undefined}
                slideWidth={!isMobile ? "200px" : undefined}
                defaultControlsConfig={{
                    prevButtonStyle: { display: "none" },
                    nextButtonStyle: { display: "none" },
                    pagingDotsStyle: { display: "none" },
                }}
                renderBottomLeftControls={(props) => (
                    <ButtonBack
                        disabled={props.currentSlide <= 0}
                        onClick={props.previousSlide}
                    >
                        <ChevronLeft />
                    </ButtonBack>
                )}
                renderBottomRightControls={(props) => (
                    <ButtonNext
                        disabled={
                            props.currentSlide >= items.length - visibleSlides
                        }
                        onClick={props.nextSlide}
                    >
                        <ChevronLeft />
                    </ButtonNext>
                )}
            >
                {items &&
                    items.map((item) => (
                        <LinkStyled key="{item}" to={`/marcas/${item.slug}`}>
                            <Image src={`${item.image}?w=600&h=600&bg=FFFFFF&auto=format,compress`} loading="lazy"  />
                        </LinkStyled>
                        
                    ))}
            </Carousel>
        </Container>
    );
};

const LinkStyled = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    ${isMobileMediaQuery} {
        height: 200px;
    }
`;

const Container = styled.div`
    display: flex;
    height: 337px;
    margin: ${({ margin }) => (margin ? margin : "60px 0")};
    width: 100%;
    box-sizing: border-box;

    ${isMobileMediaQuery} {
        flex-direction: column;
        margin: ${({ margin }) => (margin ? margin : "30px 0")};
        display: block;
        height: auto;
    }

    ${({ normalButtons }) =>
        normalButtons &&
        css`
            .slider-frame {
                margin: 0px 47px !important;
            }
        `}
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${notMobileMediaQuery} {
        margin-top: 41px;
        margin-bottom: auto;
        margin-right: 15px;
    }

    ${isMobileMediaQuery} {
        width: max-content;
        margin: 0 auto;
        max-width: 80%;
        margin-bottom: 28px;
    }
`;

const Border = styled.span`
    ${(props) =>
        props.top
            ? `
            border-top: 2px solid #313139;
            margin-bottom: auto;
            `
            : `
            border-bottom: 4px solid #f3808b;
            margin-top: auto;
            `}
`;

const Title = styled.span`
    padding: 32px 36px 30px 0;
    width: 279px;
    font-family: "SackersGothicStd";
    font-size: 32px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: 6.4px;
    color: #34343b;
    margin-left: auto;
    height: auto;
    text-transform: uppercase;
    white-space: pre-line;

    ${isMobileMediaQuery} {
        width: 100%;
        margin: 0 auto;
        font-size: 24px;
        white-space: normal;
        text-align: center;
        padding: 32px 0 30px 0;
    }
`;

const ButtonBack = styled.div`
    background-color: ${(props) => (props.disabled ? "white" : "#34343b")};
    border: 1px solid #34343b;
    position: absolute;
    bottom: 40px;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    left: -60px;
    svg {
        width: 17px;
        height: 27px;
        path {
            fill: ${(props) => (props.disabled ? "#34343b" : "white")};
        }
    }

    ${isMobileMediaQuery} {
        left: 0px;
    }

    ${({ disabled }) =>
        disabled &&
        css`
            pointer-events: none;
            cursor: initial;
        `}

    ${({ normalButtons }) =>
        normalButtons &&
        css`
            left: initial;
        `}
`;

const ButtonNext = styled(ButtonBack)`
    left: initial;

    right: -60px;
    @media screen and (max-width: ${breakpoints.xl}px) {
        left: initial;
        right: 0px;
    }

    svg {
        transform: scaleX(-1);
    }

    ${({ normalButtons }) =>
        normalButtons &&
        css`
            right: 47px;
        `}
`;

const Image = styled.img`
    object-fit: contain;
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin-bottom: 10px;

    ${isMobileMediaQuery} {
        margin-bottom: 9px;
    }
`;

export default BrandsCarroussel;
