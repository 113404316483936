import React from "react";
import styled from "styled-components";
import { isMobileMediaQuery } from "../../helpers/styles";
import { useNavigate } from "react-router-dom";

const ProductItem = ({ title, instagram, slug, image, ...props }) => {
    const navigate = useNavigate();
    return (
        <Container >
            <Image onClick={() => {navigate(`/loja/${slug}`)}} src={image}></Image>
            <Title onClick={() => {navigate(`/loja/${slug}`)}} >{title}</Title>
            <Instagram href={`https://www.instagram.com/${instagram}`} target="_blank">
                @{instagram}
            </Instagram>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    align-items: center;
    
`;

const Image = styled.img`
    width: auto;
    height: 430px;
    object-fit: cover;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    border-radius: 16px;

    width: auto;
    height: 430px;

    cursor: pointer;

    ${isMobileMediaQuery} {
        object-fit: contain;
        width: 100%;
        max-width: 180px;

        height: auto;
    }
`;

const Title = styled.div`
    margin: 10px 0 7px 0;
    font-size: 17px;
    font-weight: normal;
    text-align: center;
    color: #34343b;
    cursor: pointer;
`;

const Instagram = styled.a`
    font-family: "Graphik";
    font-size: 14px;
    color: #e4858c;
    cursor: pointer;
`;

export default ProductItem;