import React, { useEffect, useState, useRef } from "react";
import ReactTooltip from "react-tooltip";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { MoipCreditCard, MoipValidator } from "moip-sdk-js";
import JSEncrypt from "jsencrypt";
import { generateUUID } from "../../components/Functions";
import api, { baseURL } from "../../services/api";
import * as UserActions from "../../redux/actions/user";
import * as CartActions from "../../redux/actions/cart";
import * as CheckoutActions from "../../redux/actions/checkout";
import { Flex, isMobile, isMobileMediaQuery, breakpoints } from "./../../helpers/styles";
import Header from "./../../components/Header";
import Footer from "./../../components/Footer";
import Button from "../../components/Button";
import CheckBox from "../../components/Inputs/CheckBox";
import HorizontalProductItemCheckout from "../../components/HorizontalProductItemCheckout";
import ExpandableContainer from "../../components/ExpandableContainer";
import TextInput from "../../components/Inputs/TextInput";
import SelectInput from "../../components/Inputs/SelectInput";
import AlertModal from "../../components/AlertModal";
import DiscountCoupon from "../../components/DiscountCoupon";
import ChevronLeftIcon from "./../../assets/images/seta.svg";
import AmericanExpressLogo from "../../assets/images/logo-ae.png";
import VisaLogo from "../../assets/images/logo-visa.png";
import MasterCardLogo from "../../assets/images/logo-mc.png";
import EloLogo from "../../assets/images/logo-elo.png";
import ItauLogo from "../../assets/images/logo-itau.png";
import BarcodeLogo from "../../assets/images/logo-barcode.png";
import BoxImage from "../../assets/images/box-icon.png";
import RepetitLogo from "../../assets/images/logo-repetit.png";
import PixLogo from "../../assets/images/logo-pix.png";
import CardIcon from "../../assets/images/icon-card.png";
import AskIcon from "../../assets/images/icon-ask.png";
import { ReactComponent as ChevronIcon } from "../../assets/images/chevron-up.svg";
import { ReactComponent as ChevronDownIcon } from "../../assets/images/chevron-down-white.svg";
import { brazilianStates, capitalize, identifyZipCode, isValidCPF, isValidEmail } from "../../helpers";
import ProfileForm from "../../components/ProfileForm";
import BoxSuccessModal from "../../components/BoxSuccessModal";
import LoadingSpinner from "../../components/LoadingSpinner";
import SigninLinkNew from "../../components/SigninLinkNew";
import PhoneVerificationModal from "../../components/PhoneVerificationModal";

const Checkout = () => {
    const dispatch = useDispatch();

    const [promoCode, setPromoCode] = React.useState("");
    const history = useLocation();
    const navigate = useNavigate();
    const products = useSelector((state) => state.cart.products);
    const user = useSelector((state) => state.user);
    const userDefaultAddress =
        user && user.user_addresses && user.user_addresses.length > 0 ? user.user_addresses[0] : {};
    const steps = ["info", "delivery", "payment", "review"];
    const payment_methods = {
        credit_card: "Cartão de crédito",
        boleto: "Boleto",
        credit: "Créditos Re Petit",
    };
    const [info, setInfo] = useState("");
    const [currentStep, setCurrentStep] = useState(1);
    const [delivery, setDelivery] = useState(undefined);
    const [details, setDetails] = useState(undefined);
    const [showAddress, setShowAddress] = useState(false);
    const [values, setValues] = useState({
        first_name: user.first_name,
        street: userDefaultAddress.street,
        additional_info: userDefaultAddress.additional_info,
        house_number: userDefaultAddress.number,
        city: userDefaultAddress.city,
        neighborhood: userDefaultAddress.neighborhood,
        state: userDefaultAddress.state,
        email: user.email,
        last_name: user.last_name,
        zip_code: userDefaultAddress.zip,
        phone_number: `${user.phone_code_area}${user.phone_number}`,
        birth_date: moment(user.birth_date, "YYYY-MM-DD").format("DD/MM/YYYY"),
        cpf: user.cpf,
        payment_method: undefined,
        billing_address: "same_address",
        customer_children: [],
        user_addresses: [],
        user_bank_accounts: [],
        self_holder: true,
    });
    const [errors, setErrors] = useState({});
    const [modal, setModal] = useState(undefined);
    const [sendingCheckout, setSendingCheckout] = useState(false);
    const [sendingSignupRequest, setSendingSignupRequest] = useState(false);
    const [boxCheckoutDone, setBoxCheckoutDone] = useState(false);
    const [showModalBottom, setShowModalBottom] = useState(false);
    const [deliveryPrice, setDeliveryPrice] = useState(0);
    const [cupomDiscount, setCupomDiscount] = useState("");
    const [eligible, setEligible] = useState();
    const [enabled, setEnabled] = useState();
    const [missingAmount, setMissingAmount] = useState("0");
    const [progress, setProgress] = useState("0");
    const [hasProductWithFalseShipping, setHasProductWithFalseShipping] = useState(false);
    const line_items = details?.line_items
        ? details?.line_items?.filter((line) => line.description.toLowerCase().indexOf("cupom"))
        : [];
    const handleModal = () => {
        setShowModalBottom(!showModalBottom);
    };
    const isBoxCheckout = products.filter((item) => item.box_type).length > 0;
    const isOnlyBoxDelivery = products.length === 1 && isBoxCheckout;
    const currentCartBox = products.find((item) => item.box_type);
    const pathname = useSelector((state) => state.category.path);
    const currentInstallment =
        details &&
        details.payment_options &&
        values.payment_method &&
        details.payment_options[values.payment_method].installments[values.installments - 1 || 0];
    const [phoneVerificationModal, setPhoneVerificationModal] = useState(undefined);

    useEffect(() => {
        if (delivery && delivery.current && delivery.current.amount) setDeliveryPrice(delivery.current.amount);
    }, [delivery]);

    useEffect(() => {
        if (promoCode !== "" && details && details.line_items[1] && Math.abs(details.line_items[1].amount) >= 0)
            setCupomDiscount(details.line_items[1]);
        else setCupomDiscount(undefined);
    }, [details]);

    useEffect(() => {
        let dataLayerProducts = [];
        products
            .filter((item) => !item.box_item)
            .map((product) =>
                dataLayerProducts.push({
                    name: product.name,
                    id: product.id,
                    price: product.price_label,
                    brand: product.brand.name,
                    category: product.item_category.name,
                    quantity: 1,
                })
            );
        // eslint-disable-next-line
        dataLayer.push({ ecommerce: null });
        // eslint-disable-next-line
        dataLayer.push({
            event: "checkout",
            ecommerce: {
                checkout: {
                    actionField: {
                        step: currentStep || 1,
                        option: values.payment_method || "",
                    },
                    products: dataLayerProducts,
                },
            },
        });
    }, [currentStep]);

    useEffect(() => {
        setValues((values) => ({ ...values, useCredit: false }));
        if (currentStep === 4) {
            setValues((values) => ({ ...values, useCredit: true }));
        }
        if (!products || products.length === 0) {
            navigate(-1);
        } else {
            // eslint-disable-next-line
            dataLayer.push({
                event: "pageview",
                page: {
                    path: "/checkout",
                    title: "Checkout",
                },
            });

            // eslint-disable-next-line
            dataLayer.push({ ecommerce: null });
            // eslint-disable-next-line
            dataLayer.push({
                event: "virtualPageview",
                page: document.title,
                type: "Checkout",
                environment: process.env.REACT_APP_ENV,
                user: [
                    {
                        name: user.first_name && user.last_name ? user.first_name + " " + user.last_name : "",
                        username: user.slug ? user.slug : "",
                        user_id: user.id ? user.id : "",
                        email: user.email ? user.email : "",
                        language: "pt-br",
                        returning: JSON.parse(localStorage.getItem("accepted_cookies")) ? true : false,
                    },
                ],
            });
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        checkFreeShipping(products?.map((product) => product.id));
        if (!products || products.length === 0) {
            navigate("/");
        } else {
            if (currentStep === 4) {
                return;
            }

            setDelivery(undefined);
            setValues((values) => ({ ...values, delivery_method: null }));

            if (isBoxCheckout) {
                calculateBoxDelivery();
                setCurrentStep(3);
            } else {
                calculateDelivery(values.zip_code);
            }

            if ((user?.token || currentStep >= 2) && !isBoxCheckout) {
                setCurrentStep(2);
            }
        }

        // eslint-disable-next-line
    }, [products]);

    useEffect(() => {
        window.scrollTo({ top: 0 });
        if (!details?.balance && currentStep === 3 && !isBoxCheckout) {
            setCurrentStep(2);
        }
        // eslint-disable-next-line
    }, [currentStep]);

    useEffect(() => {
        if (values.payment_method === "credit_card") {
            if (values.card_number && values.expires_at && values.card_name && values.ccv) {
                if (
                    values.expires_at.length < 5 ||
                    values.expires_at.replace(/_/g, "").split("/").length !== 2 ||
                    values.ccv.replace(/_/g, "").length < 3 ||
                    values.card_number.length < 16
                ) {
                    if (values.card_hash !== undefined)
                        setValues((prevState) => {
                            return { ...prevState, card_hash: undefined };
                        });
                    return;
                }

                const validNumber = MoipValidator.isValidNumber(values.card_number);
                const validSecurityCode = MoipValidator.isSecurityCodeValid(values.card_number, values.ccv);
                const validExpiryDate = MoipValidator.isExpiryDateValid(
                    values.expires_at.split("/")[0],
                    values.expires_at.split("/")[1]
                );

                validNumber && validSecurityCode && validExpiryDate && hashCreditCard();
            } else if (values.card_hash !== undefined) {
                setValues((prevState) => {
                    return { ...prevState, card_hash: undefined };
                });
            }
        } else if (
            values.payment_method !== undefined &&
            values.payment_method !== "credit_card" &&
            values.card_hash !== undefined
        ) {
            setValues((prevState) => {
                return { ...prevState, card_hash: undefined };
            });
        }

        if (values.zip_code && isBoxCheckout) {
            calculateBoxDelivery();
        }

        // eslint-disable-next-line
    }, [values]);

    useEffect(() => {
        calculateDelivery(values.zip_code);

        // eslint-disable-next-line
    }, [values.first_name, values.last_name, values.phone_number]);

    useEffect(() => {
        if (delivery && delivery.current && isBoxCheckout) {
            handleDetailsRequest(delivery.current.service);
        }

        // eslint-disable-next-line
    }, [delivery]);

    useEffect(() => {
        setValues((val) => ({ ...val, payment_method: undefined }));
        if (delivery && delivery.current) {
            handleDetailsRequest(delivery.current.service);
        }
        // eslint-disable-next-line
    }, [values.useCredit]);

    const handleChange = async (e) => {
        let {
            target: { name, value, checked, type },
        } = e;

        let newValues = { ...values };
        if (name !== undefined) {
            if (value !== undefined && (type !== "checkbox" || type === "radio")) {
                newValues = {
                    ...newValues,
                    [name]: value,
                };
            } else if (checked !== undefined) {
                newValues = {
                    ...newValues,
                    [name]: checked,
                };
            }
        }

        if (name === "card_number") {
            if (newValues.card_number && newValues.card_number.length >= 16) {
                if (MoipValidator.isValidNumber(newValues.card_number.replace(/_/g, ""))) {
                    const cardType = MoipValidator.cardType(newValues.card_number.replace(/_/g, ""));
                    newValues.card_type = cardType && cardType.brand;
                } else {
                    newValues.card_type = undefined;
                }
            } else {
                newValues.card_type = undefined;
            }
        }

        if (name === "delivery_method") {
            let currDeliveryMethod =
                delivery &&
                delivery.services &&
                delivery.services.find((item) => item.service === newValues.delivery_method);
            if (currDeliveryMethod !== null && currDeliveryMethod !== undefined) {
                setDelivery({ ...delivery, current: currDeliveryMethod });
            }

            if (currDeliveryMethod !== null && currDeliveryMethod !== undefined) {
                await handleDetailsRequest(newValues.delivery_method);
            }
        }

        if (name === "payment_method") {
            if (newValues.payment_method === "credit_card") {
                newValues.self_holder = true;
            }
            newValues.installments = 1;
        }

        setValues((prevState) => {
            return { ...prevState, ...newValues };
        });
    };

    const handleNextStep = async () => {
        if (currentStep === 1) {
            const birth_date = values.birth_date && moment(values.birth_date, "dd/mm/yyyy");
            const user_age = birth_date && moment().diff(birth_date, "years");
            if (
                !values.first_name ||
                !values.email ||
                !isValidEmail(values.email) ||
                !isValidCPF(values.cpf) ||
                !values.last_name ||
                !values.phone_number ||
                values.phone_number == 'undefinedundefined' ||
                values.phone_number.replace(/\D+/g, "").length < 11 ||
                !values.birth_date ||
                values.birth_date.length < 10 ||
                !birth_date ||
                !birth_date.isValid() ||
                user_age < 5 ||
                user_age > 150 ||
                values.cpf.replace(/\D+/g, "").length < 11 ||
                !values.password ||
                !values.confirm_password
            ) {
                setErrors({
                    ...errors,
                    first_name: !values.first_name,
                    email: !values.email,
                    email: !isValidEmail(values.email),
                    last_name: !values.last_name,
                    phone_number: !values.phone_number || values.phone_number == 'undefinedundefined' || values.phone_number.replace(/\D+/g, "").length < 11,
                    birth_date:
                        !values.birth_date ||
                        values.birth_date.length < 10 ||
                        !birth_date ||
                        !birth_date.isValid() ||
                        user_age < 5 ||
                        user_age > 150,
                    cpf: !isValidCPF(values.cpf),
                    password: !values.password,
                    confirm_password: !values.confirm_password
                });
                return;
            }

            setErrors({});
        }

        if (currentStep === 2) {
            if (
                !values.street ||
                !values.house_number ||
                !values.neighborhood ||
                !values.city ||
                !values.state ||
                !values.zip_code ||
                values.zip_code.replace(/\D+/g, "").length < 8
            ) {
                setErrors({
                    ...errors,
                    street: !values.street,
                    house_number: !values.house_number,
                    city: !values.city,
                    state: !values.state,
                    neighborhood: !values.neighborhood,
                    zip_code: !values.zip_code || values.zip_code.replace(/\D+/g, "").length < 8,
                });

                return;
            }

            setErrors({});
        }

        if (currentStep === 3) {
            let step3Errors = {};

            if (values.payment_method === "credit_card" && values.self_holder !== true) {
                step3Errors = {
                    holder_name: !values.holder_name,
                    holder_birth_date: !values.holder_birth_date,
                    holder_cpf: !values.holder_cpf,
                    holder_phone_number: !values.holder_phone_number,
                };
            } else {
                step3Errors = {
                    holder_name: false,
                    holder_birth_date: false,
                    holder_cpf: false,
                    holder_phone_number: false,
                };
            }

            if (values.billing_address === "another_address") {
                step3Errors = {
                    ...step3Errors,
                    another_address_first_name: !values.another_address_first_name,
                    another_address_street: !values.another_address_street,
                    another_address_house_number: !values.another_address_house_number,
                    another_address_neighborhood: !values.another_address_neighborhood,
                    another_address_city: !values.another_address_city,
                    another_address_state: !values.another_address_state,
                    another_address_last_name: !values.another_address_last_name,
                    another_address_zip_code:
                        !values.another_address_zip_code ||
                        values.another_address_zip_code.replace(/\D+/g, "").length < 8,
                };
            } else {
                step3Errors = {
                    ...step3Errors,
                    another_address_first_name: false,
                    another_address_street: false,
                    another_address_house_number: false,
                    another_address_neighborhood: false,
                    another_address_city: false,
                    another_address_state: false,
                    another_address_last_name: false,
                    another_address_zip_code: false,
                };
            }

            setErrors({ ...errors, ...step3Errors });

            if (Object.values(step3Errors).find((item) => item === true)) return;
        }

        if (currentStep === 1 && (!user || !user.token)) {
            setSendingSignupRequest(true);
            let signupResponse = await api
                .post("/profiles", {
                    email: values.email,
                    password: !user || !user.token ? values.password : undefined,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    phone_country_code: values.phone_country_code || "55",
                    phone_code_area:
                        values.phone_number && values.phone_number.length > 0 && values.phone_number[0] === "("
                            ? values.phone_number.match(/^\((\d{2})\)/)[1]
                            : values.phone_number.substring(0, 2),
                    phone_number:
                        values.phone_number && values.phone_number[0] === "("
                            ? values.phone_number.substring(5).replace(/\s/g, "").replace(/_/g, "").replace(/-/g, "")
                            : values.phone_number.substring(2),
                    cpf: values.cpf,
                    newsletter: values.newsletter,
                    birth_date: values.birth_date,
                    how_did_you_meet_repetit: (values.how_did_you_meet_repetit || []).join(", "),
                    user_addresses: [],
                    source: "checkout",
                })
                .catch((err) => {
                    setSendingSignupRequest(false);
                    return err.response;
                });

            setSendingSignupRequest(false);

            if (signupResponse.status === 200) {
                // eslint-disable-next-line
                dataLayer.push({
                    event: "sign_up",
                    source: "checkout",
                });

                dispatch(
                    UserActions.login({
                        token: signupResponse.headers.token,
                        ...signupResponse.data,
                    })
                );
                dispatch(UserActions.refreshProfile());
                if (signupResponse.data.needs_phone_verification === true) {
                    setPhoneVerificationModal({
                        show: true,
                        phone: values.phone_number.toString().replace(/(\d{4,5})(\d{4})/, "$1-$2"),
                    });
                }
                setCurrentStep(2);
            } else {
                console.error(signupResponse);

                setModal({
                    show: true,
                    message:
                        signupResponse && signupResponse.data && signupResponse.data.message
                            ? signupResponse.data.message
                            : "Erro inesperado. Aguarde um instante e tente outra vez!",
                });

                return;
            }
        }

        if (currentStep < steps.length) {
            if (currentStep == 1) {
                let contents_purchase = [];
                products.forEach((product) => {
                    contents_purchase.push({
                        currency: "BRL",
                        content_type: "product",
                        id: product.id,
                        item_price: product.price_label,
                        item_name: product.name,
                        item_category: product.item_category.name,
                    });
                });
                let userSessionServerData = window.userCurrentServerData(user);
                let event_id = generateUUID();
                let event_time = Math.round(Date.now() / 1000);

                window.fbq("track", "CustomizeProduct", { contents_purchase }, { eventID: event_id });

                let facebookEvent = api
                    .post("/facebook_ads_event", {
                        data: [
                            {
                                event_name: "CustomizeProduct",
                                event_id: event_id,
                                event_time: event_time,
                                event_source_url: window.location.href,
                                action_source: "website",
                                user_data: userSessionServerData,
                                custom_data: contents_purchase,
                            },
                        ],
                    })
                    .then((res) => console.log("EVENTO CustomizeProduct", res.data))
                    .catch((res) => res.response);
            }

            if (currentStep == 3) {
                let contentIds = [];
                let numItens = 0;
                let valueProds = 0;
                products.forEach((prod) => {
                    contentIds.push(prod.id);
                    valueProds = +prod.price_label;
                    numItens++;
                });

                let userSessionServerData = window.userCurrentServerData(user);
                let event_id = generateUUID();
                let event_time = Math.round(Date.now() / 1000);

                window.fbq(
                    "track",
                    "AddPaymentInfo",
                    {
                        content_ids: contentIds,
                        currency: "BRL",
                        num_items: numItens,
                        value: valueProds,
                    },
                    { eventID: event_id }
                );

                let facebookEvent = await api
                    .post("/facebook_ads_event", {
                        data: [
                            {
                                event_name: "AddPaymentInfo",
                                event_id: event_id,
                                event_time: event_time,
                                event_source_url: window.location.href,
                                action_source: "website",
                                user_data: userSessionServerData,
                                custom_data: {
                                    content_ids: contentIds,
                                    currency: "BRL",
                                    num_items: numItens,
                                    value: valueProds,
                                },
                            },
                        ],
                    })
                    .then((res) => console.log("Evento AddPaymentInfo", res.data))
                    .catch((res) => res.response);
            }

            if (isBoxCheckout) {
                if (currentStep === 1) {
                    setCurrentStep(3);
                    return;
                }
            }

            setCurrentStep(currentStep + 1);
        } else if (currentStep === steps.length) {
            handleSendRequest();
        }
    };

    const canChangeStep = () => {
        let disabled = false;

        switch (currentStep) {
            case 1:
                break;

            case 2:
                disabled = !values.delivery_method;
                break;

            case 3:
                disabled = !values.payment_method || !values.billing_address;

                if (values.payment_method === "credit_card") {
                    if (!values.card_number || !values.expires_at || !values.card_name || !values.ccv) {
                        disabled = true;
                    } else {
                        disabled = values.card_hash === undefined;
                    }
                }

                if (!(values.installments > 0)) {
                    disabled = true;
                }

                if (!details && isBoxCheckout) {
                    disabled = true;
                }

                if (values.useCredit && details?.total === 0 && details?.credit_used > 0) {
                    disabled = false;
                }

                break;

            default:
                break;
        }

        return disabled;
    };

    const handleZipCode = async ({ target: { value, name } }) => {
        let newValues = { ...values };

        if (value && value.replace(/_/g, "").length === 9) {
            let zipCodeResponse = await identifyZipCode(value);

            if (name === "another_address_zip_code") {
                if (zipCodeResponse.street) {
                    setErrors((prevState) => {
                        return {
                            ...prevState,
                            another_address_zip_code: false,
                        };
                    });
                }

                newValues.another_address_street = zipCodeResponse.logradouro;
                newValues.another_address_state = zipCodeResponse.uf;
                newValues.another_address_city = zipCodeResponse.localidade;
                newValues.another_address_neighborhood = zipCodeResponse.bairro;
            } else {
                if (zipCodeResponse.street) {
                    setErrors((prevState) => {
                        return {
                            ...prevState,
                            zip_code: false,
                        };
                    });
                }

                newValues.street = zipCodeResponse.logradouro;
                newValues.state = zipCodeResponse.uf;
                newValues.city = zipCodeResponse.localidade;
                newValues.neighborhood = zipCodeResponse.bairro;
            }

            calculateDelivery(newValues.zip_code);
            checkFreeShipping(products?.map((product) => product.id), zipCodeResponse.localidade, zipCodeResponse.uf)
        }

        setValues((prevState) => {
            return { ...prevState, ...newValues };
        });
    };

    const calculateDelivery = async (zip_code) => {
        if (
            isBoxCheckout ||
            !values.first_name ||
            !values.last_name ||
            !values.phone_number ||
            !zip_code ||
            zip_code.replace(/_/g, "").replace(/-/g, "").length < 8
        ) {
            return;
        }

        const deliveryProducts = products.reduce((acc, curr) => {
            if (!curr.box_type) return [...acc, curr.id];
            else return acc;
        }, []);

        if (deliveryProducts.length === 0) return;

        const delivery_payload = {
            zip_code: zip_code && zip_code.replace(/\D+/g, ""),
            item_ids: deliveryProducts,
            addressee: {
                name: `${values.first_name} ${values.last_name}`,
                phone: values.phone_number && values.phone_number.replace(/\D+/g, ""),
            },
        };

        if (
            delivery &&
            !delivery.error &&
            delivery.payload &&
            delivery.payload.zip_code === delivery_payload.zip_code &&
            delivery.payload.item_ids.every((item) => delivery_payload.item_ids.includes(item))
        ) {
            return;
        }

        setDelivery(undefined);

        let delivery_response = await api
            .post("/delivery/unit_calculator", delivery_payload, {
                baseURL: baseURL.replace("v1", "v2"),
            })
            .catch((err) => err.response);

        if (delivery_response?.status === 200) {
            setDelivery({
                ...delivery_response.data,
                payload: delivery_payload,
            });
        } else {
            setDelivery({
                error: true,
                code: delivery_response.data.code,
                message: delivery_response.data.message,
            });

            let remove_product_message = "";

            if (
                delivery_response.data &&
                delivery_response.data.code === "item_not_available" &&
                delivery_response.data.details &&
                delivery_response.data.details.items &&
                delivery_response.data.details.items.length > 0
            ) {
                const { items } = delivery_response.data.details;
                items.forEach((item) => {
                    const findProduct = products.find((product) => product.id === item);
                    if (findProduct && (findProduct.title || (findProduct.item_category && findProduct.brand))) {
                        remove_product_message =
                            remove_product_message +
                            (findProduct.title ||
                                `${capitalize(findProduct.item_category.name.toLowerCase())} ${capitalize(
                                    findProduct.brand.name.toLowerCase()
                                )}${findProduct.size ? ` - Tam.: ${findProduct.size}` : ""}, `);
                    }
                });
                setModal({
                    show: true,
                    callback: () => {
                        dispatch(CartActions.removeProduct(items))
                    },
                    message: (
                        <>
                            {delivery_response &&
                                delivery_response.data &&
                                delivery_response.data.message
                                ? delivery_response.data.message
                                : "Erro inesperado. Aguarde um instante e tente outra vez!"}

                            {remove_product_message !== "" && (
                                <>
                                    <br />
                                    <br />
                                    Removemos {remove_product_message} do seu
                                    carrinho.
                                </>
                            )}
                        </>
                    ),
                });
                return
            }
            setModal({
                show: true,
                message: (
                    <>
                        {delivery_response && delivery_response.data && delivery_response.data.message
                            ? delivery_response.data.message
                            : "Erro inesperado. Aguarde um instante e tente outra vez!"}

                        {remove_product_message !== "" && (
                            <>
                                <br />
                                <br />
                                Removemos {remove_product_message} do seu carrinho.
                            </>
                        )}
                    </>
                ),
            });
        }
    };

    const calculateBoxDelivery = async () => {
        if (!values.zip_code || !isBoxCheckout) {
            return;
        }

        let { zip_code } = values;

        if (!zip_code.replace("-", "").match(/^[0-9]{5,5}[0-9]{3,3}$/g)) return;

        zip_code = zip_code.replace("-", "");

        const delivery_payload = {
            zip_code,
            box_id: currentCartBox && currentCartBox.id,
            item_ids: products.reduce((acc, curr) => {
                if (curr.box_type) return acc;
                else return [...acc, curr.id];
            }, []),
        };

        if (
            delivery &&
            !delivery.error &&
            delivery.payload &&
            delivery.payload.zip_code === delivery_payload.zip_code &&
            delivery.payload.item_ids.every((item) => delivery_payload.item_ids.includes(item))
        ) {
            return;
        }

        setDelivery(undefined);

        let response = await api
            .post("/delivery/box_calculator", delivery_payload, {
                baseURL: baseURL.replace("v1", "v2"),
            })
            .catch((err) => err.response);

        if (response && response.status === 200) {
            setDelivery({
                ...response.data,
                current: response.data.services[0],
                payload: delivery_payload,
            });
        } else {
            setDelivery({
                error: true,
                code: response.data.code,
                message: response.data.message,
            });
        }
    };

    const hashCreditCard = async () => {
        if (values.card_hash) return;

        let isValid = false;

        // need to change
        const pubKey =
            process.env.REACT_APP_ENV === "prod"
                ? `-----BEGIN PUBLIC KEY-----
        MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0lb/AAiMc1OTBRWTNfqG
        kFfeddw43QkTLahpfhqklLM8mYe36r0hOY4FJUngYaaXaugWN3wAcucwtL2TRLdq
        x7AGJzc1DNN4vA2zGRP46VoOlNrCQDhMvoEWpT3iFxm+cgXjhSomSZD+2t21PuO8
        81kRjKGca6wWMgh+6GymSpzdI65thSpe4wUw76fDCDn144cErtf9dZI7CoNLSdk5
        cjGMevWgyNsENQVYKvKoB8q5nxEpLv92lD6d9j4LjHT6pezoTWencWcfCYPqQGFW
        A/1dyrMIKOmKAr9vcjzQLtpfxioW01gDdLe2S+TBQRRN91uT79feBNVCiJGRi4AJ
        uQIDAQAB
        -----END PUBLIC KEY-----`
                : `-----BEGIN PUBLIC KEY-----
        MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA7YPk8T9liFTymd7Oewg+
        c/P4q72cR5kJor9oC846nseaHL7T55HbD/2WNZEeuiR7K1mCifBo+KFs38NUBeQp
        dT1Mo7h8SmHotijndUlqvH/wpJ7+cJyF5sBT6oR1BmkJvPDXWb/pSNdVlZhylun1
        rsJZ37jNr4Y4zDAWfL2B54gtmvYmpWkgm0prq8ZAAS09FnRG7NxaRdpKNir7FRqQ
        pj7yUxYs+vORrFlTMi+gdowtABtt28E9ZuP7Zjco7vslfbbk34Ad4VpSrKsw+hF5
        TZ9aF9lfBLnFxxtpNa0Aly6Hy35ODoAn/5wu6VJ1jLJIfOkpnVeUurS+abl8PA9K
        KwIDAQAB
        -----END PUBLIC KEY-----`;

        await MoipCreditCard.setEncrypter(JSEncrypt, "node")
            .setPubKey(pubKey)
            .setCreditCard({
                number: values.card_number,
                cvc: values.ccv,
                expirationMonth: values.expires_at.split("/")[0],
                expirationYear: values.expires_at.split("/")[1],
            })
            .hash()
            .then((hash) => {
                setValues((prevState) => {
                    return { ...prevState, card_hash: hash };
                });

                isValid = true;
            })
            .catch((err) => {
                setValues((prevState) => {
                    return { ...prevState, card_hash: undefined };
                });

                isValid = false;

                alert("Invalid credit card. Verify parameters: number, cvc, expiration Month, expiration Year");
            });

        return isValid;
    };

    const calcTotal = (delivery) => {
        let total = parseFloat(delivery);

        products.forEach((element) => {
            total += parseFloat(element.price_label);
        });

        return total;
    };

    const handleDetailsRequest = async (delivery_method = values.delivery_method, promo_code = promoCode) => {
        if (!delivery || !delivery.hash || !delivery.signature || !delivery_method) return;
        let details_response = await api
            .post(
                "/checkout",
                {
                    delivery: {
                        hash: delivery && delivery.hash,
                        sign: delivery && delivery.signature,
                        selected: delivery_method,
                    },
                    use_credit: values.useCredit,
                    box_id: currentCartBox && currentCartBox.id,
                    promo_code: promo_code,
                    item_ids: products.reduce((acc, curr) => {
                        if (curr.box_type) return acc;
                        return [...acc, curr.id];
                    }, []),
                },
                {
                    baseURL: baseURL.replace("v1", "v2"),
                }
            )
            .catch((err) => err.response);

        if (
            details_response &&
            details_response.data &&
            details_response.data.code &&
            details_response.data.code.search("promo_code")
        ) {
            if (details_response?.status !== 200) {
                setModal({
                    show: true,
                    message:
                        details_response && details_response.data && details_response.data.message
                            ? details_response.data.message
                            : "Erro inesperado. Aguarde um instante e tente outra vez!",
                });
                setPromoCode("");
                setValues((values) => ({ ...values, useCredit: undefined }));
            }
            return;
        }

        if (details_response?.status === 200) {
            setDetails(details_response.data);
            if (
                details_response.data?.balance?.total_balance > 0 &&
                (values.useCredit === null || values.useCredit === undefined)
            ) {
                setValues((old) => ({ ...old, useCredit: true }));
            }
        } else {
            setDetails(undefined);

            if (details_response.data.message !== "Signature has expired") {
                setModal({
                    show: true,
                    message:
                        details_response && details_response.data && details_response.data.message
                            ? details_response.data.message
                            : "Erro inesperado. Aguarde um instante e tente outra vez!",
                });
            } else {
                setDelivery(undefined);
                setCurrentStep(1);
            }
        }
        return details_response;
    };

    const handleSendRequest = async () => {
        let paymentMethod = values.payment_method;

        if (values.useCredit && details?.total === 0 && details?.credit_used > 0) {
            paymentMethod = "boleto";
        }

        let payment_payload = {
            payment_method: {
                use_credit: values.useCredit,
                method: paymentMethod,
                installments: parseInt(values.installments || 1),
                debit: values.payment_method === "debit" ? { bank_number: 341 } : undefined,
                credit_card:
                    values.payment_method === "credit_card"
                        ? {
                            card: {
                                number: values.card_number,
                                cvv: values.ccv,
                                expiry_month: values.expires_at.split("/")[0],
                                expiry_year: values.expires_at.split("/")[1],
                            },
                            holder: {
                                name: values.card_name,
                                birth_date: moment(
                                    values.self_holder !== true ? values.holder_birth_date : values.birth_date,
                                    "DD/MM/YYYY"
                                ).format("YYYY-MM-DD"),
                                cpf: values.self_holder !== true ? values.holder_cpf : values.cpf,
                                email: values.email,
                                ...(values.self_holder !== true
                                    ? {
                                        phone_country_code: values.phone_country_code || "55",
                                        phone_code_area:
                                            values.holder_phone_number &&
                                                values.holder_phone_number.length > 0 &&
                                                values.holder_phone_number[0] === "("
                                                ? values.holder_phone_number.match(/^\((\d{2})\)/)[1]
                                                : values.holder_phone_number.substring(0, 2),
                                        phone_number:
                                            values.holder_phone_number && values.holder_phone_number[0] === "("
                                                ? values.holder_phone_number
                                                    .substring(5)
                                                    .replace(/\s/g, "")
                                                    .replace(/_/g, "")
                                                    .replace(/-/g, "")
                                                : values.holder_phone_number.substring(2),
                                    }
                                    : {
                                        phone_country_code: values.phone_country_code || "55",
                                        phone_code_area:
                                            values.phone_number &&
                                                values.phone_number.length > 0 &&
                                                values.phone_number[0] === "("
                                                ? values.phone_number.match(/^\((\d{2})\)/)[1]
                                                : values.phone_number.substring(0, 2),
                                        phone_number:
                                            values.phone_number && values.phone_number[0] === "("
                                                ? values.phone_number
                                                    .substring(5)
                                                    .replace(/\s/g, "")
                                                    .replace(/_/g, "")
                                                    .replace(/-/g, "")
                                                : values.phone_number.substring(2),
                                    }),
                                address:
                                    values.billing_address === "another_address"
                                        ? {
                                            street: values.another_address_street,
                                            number: values.another_address_house_number,
                                            zip_code: values.another_address_zip_code,
                                            neighborhood: values.another_address_neighborhood,
                                            city: values.another_address_city,
                                            state: values.another_address_state,
                                            country: "br",
                                            additional_info: values.additional_info,
                                        }
                                        : {
                                            street: values.street,
                                            number: values.house_number,
                                            zip_code: values.zip_code,
                                            neighborhood: values.neighborhood,
                                            city: values.city,
                                            state: values.state,
                                            country: "br",
                                            additional_info: values.additional_info,
                                        },
                            },
                        }
                        : undefined,
            },
            delivery: {
                hash: delivery && delivery.hash,
                sign: delivery && delivery.signature,
                selected: isBoxCheckout ? delivery && delivery.current.service : values.delivery_method,
            },

            hash: details && details.hash,
            sign: details && details.signature,

            box_id: isBoxCheckout && currentCartBox ? currentCartBox.id : undefined,
            promo_code: promoCode,
            item_ids: products.reduce((acc, curr) => {
                if (curr.box_type) return acc;
                return [...acc, curr.id];
            }, []),

            user: {
                email: values.email,
                password: !user || !user.token ? values.password : undefined,
                first_name: values.first_name,
                last_name: values.last_name,
                phone_country_code: values.phone_country_code || "55",
                phone_code_area:
                    values.phone_number && values.phone_number.length > 0 && values.phone_number[0] === "("
                        ? values.phone_number.match(/^\((\d{2})\)/)[1]
                        : values.phone_number.substring(0, 2),
                phone_number:
                    values.phone_number && values.phone_number[0] === "("
                        ? values.phone_number.substring(5).replace(/\s/g, "").replace(/_/g, "").replace(/-/g, "")
                        : values.phone_number.substring(2),
                birth_date: values.birth_date,
                cpf: values.cpf,
                newsletter: values.newsletter,
                user_addresses: [
                    {
                        kind: "shipping",
                        street: values.street,
                        number: values.house_number,
                        zip: values.zip_code,
                        neighborhood: values.neighborhood,
                        city: values.city,
                        state: values.state,
                        country: "br",
                        additional_info: values.additional_info,
                    },
                    values.billing_address === "another_address"
                        ? {
                            kind: "billing",
                            street: values.another_address_street,
                            number: values.another_address_house_number,
                            zip: values.another_address_zip_code,
                            neighborhood: values.another_address_neighborhood,
                            city: values.another_address_city,
                            state: values.another_address_state,
                            country: "br",
                            additional_info: values.additional_info,
                        }
                        : {
                            kind: "billing",
                            street: values.street,
                            number: values.house_number,
                            zip: values.zip_code,
                            neighborhood: values.neighborhood,
                            city: values.city,
                            state: values.state,
                            country: "br",
                            additional_info: values.additional_info,
                        },
                ],
            },
        };

        setSendingCheckout(true);

        let checkout_response = await api
            .post("/checkout/payment", payment_payload, {
                baseURL: baseURL.replace("v1", "v2"),
            })
            .catch((err) => err.response);

        if (checkout_response.status === 200 && checkout_response.data) {
            if (checkout_response.data.is_final_status) {
                setSendingCheckout(false);

                finalStatus(checkout_response);
            } else {
                startCheckoutPooling(checkout_response.data.sale.id, checkout_response, Date.now());
            }
        } else {
            setSendingCheckout(false);

            console.error(checkout_response);

            if (checkout_response.data.message === "Signature has expired") {
                setDelivery(undefined);
                setCurrentStep(1);
            } else {
                setModal({
                    show: true,
                    message:
                        checkout_response && checkout_response.data && checkout_response.data.message
                            ? checkout_response.data.message
                            : "Erro inesperado. Aguarde um instante e tente outra vez!",
                });
            }
        }
    };

    const startCheckoutPooling = async (sale_id, checkout_response, poolingStartTime) => {
        const poolingMaxWaitMilliseconds = 20000;
        const poolingDelayMilliseconds = 2000;

        let pooling_response = await api.get(`/profiles/orders/${sale_id}`).catch((err) => err.response);

        if (
            pooling_response.data.payment_status !== "processing" ||
            Date.now() > poolingStartTime + poolingMaxWaitMilliseconds ||
            pooling_response.data.is_final_status === true ||
            (values.payment_method === "pix" && pooling_response.data?.payment_info?.pix_info?.payment_code)
        ) {
            if (pooling_response.data.status === "failed" || pooling_response.data.payment_status === "canceled") {
                setSendingCheckout(false);

                if (pooling_response.data.message === "Signature has expired") {
                    setDelivery(undefined);
                    setCurrentStep(1);
                } else {
                    setModal({
                        show: true,
                        message:
                            pooling_response?.data?.fail_reason ||
                            pooling_response?.data?.message ||
                            "Erro inesperado. Aguarde um instante e tente outra vez!",
                    });
                }
            } else {
                finalStatus({
                    ...pooling_response,
                    data: {
                        ...pooling_response.data,
                        sale: checkout_response.data?.sale || checkout_response.sale,
                        warning: checkout_response.warning || checkout_response.data?.warning,
                        payment_code:
                            values.payment_method === "pix"
                                ? pooling_response.data?.payment_info?.pix_info?.payment_code
                                : "",
                        total_sale: pooling_response.data.total_sale,
                        amount_due:
                            values.payment_method === "pix"
                                ? pooling_response.data?.payment_info?.pix_info?.amount
                                : undefined,
                    },
                });
            }
        } else {
            setTimeout(() => {
                startCheckoutPooling(sale_id, checkout_response, poolingStartTime);
            }, poolingDelayMilliseconds);
        }
    };

    const finalStatus = async (checkout_response) => {
        // eslint-disable-next-line
        if (checkout_response.headers && checkout_response.headers.token) {
            dispatch(
                UserActions.login({
                    token: checkout_response.headers.token,
                })
            );
            dispatch(UserActions.refreshProfile());
        }

        dispatch(
            CheckoutActions.sendCheckout({
                ...checkout_response.data.payment_info,
                payment_method: values.useCredit && details?.total === 0 ? "credit" : values.payment_method,
                sale: checkout_response.data.sale,
                warning: checkout_response.data.warning,
                products,
                payment_code: checkout_response.data.payment_code,
                total_sale: checkout_response.data.total_sale,
                amount_due: checkout_response.data.amount_due,
            })
        );

        if (!isBoxCheckout) {
            // eslint-disable-next-line
            dataLayer.push({ ecommerce: null });
            // eslint-disable-next-line
            dataLayer.push({ event: "lead-checkout-success" });
            navigate("/checkout/sucesso");

            dispatch(CartActions.clear());
        } else {
            setBoxCheckoutDone(true);
        }
    };

    const handleRemove = (item) => {
        // eslint-disable-next-line
        dataLayer.push({ ecommerce: null });
        // eslint-disable-next-line
        dataLayer.push({
            event: "removeFromCart",
            ecommerce: {
                remove: {
                    products: [
                        {
                            name: item.name,
                            id: item.id,
                            price: item.price_label,
                            brand: item.brand.name,
                            category: item.item_category.name,
                            quantity: 1,
                        },
                    ],
                },
            },
        });

        dispatch(CartActions.removeProduct(item.id));
        if (user.token) {
            const productsTemp = products.filter((product) => product.id !== item.id);
            saveAbandonedCart(productsTemp.map((product) => product.id));
        }
    };

    const saveAbandonedCart = async (idsProducts) => {
        try {
            let response = await api.post("/carts", {
                item_ids: idsProducts,
            });
            if (response.status === 204) {
                console.log("saveAbandonedCart: /carts");
            }
        } catch (error) {
            console.error("Error: ", error);
        }
    };

    const keepBuying = () => (
        <KeepBuying
            secondary={1}
            filled={1}
            fluid={1}
            borderradius
            value="Continuar Comprando"
            margin={isMobile ? "0 auto 18px auto" : "0 18px 0 0"}
            to={isBoxCheckout ? `/minha-conta/box/${currentCartBox.id}` : pathname}
        >
            {isBoxCheckout ? "Escolher mais produtos" : "Continuar comprando"}
        </KeepBuying>
    );

    const ProgressBar = ({ duration }) => {
        useEffect(() => {
            const timer = setInterval(() => {
                setProgress((prevWidth) => {
                    if (prevWidth >= 100) {
                        clearInterval(timer);
                        return 100;
                    }
                    return prevWidth + 100 / duration;
                });
            }, 1000);
            return () => {
                clearInterval(timer);
            };
        }, [duration]);
    };

    const checkFreeShipping = async (idsProducts, cityAddress, stateAddress) => {
        const stateParam = stateAddress ? stateAddress : user.user_addresses && user.user_addresses.length > 0 ? user.user_addresses[0].state : ""
        const cityParam = cityAddress ? cityAddress : user.user_addresses && user.user_addresses.length > 0 ? user.user_addresses[0].city : ""

        let response = await api.post("/check_free_shipping", {
            items: idsProducts,
            state: stateParam,
            city: cityParam,
        });

        if (response.status === 200) {
            setEligible(response.data.eligible);
            setEnabled(response.data.enabled);
            setMissingAmount(
                response.data?.missing_amount?.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })
            );
            setProgress(response.data.progress);
        } else {
            console.log("erro");
        }
        setHasProductWithFalseShipping(products?.some((product) => product.eligible_for_free_shipping === false));
    };

    const handlePhoneModal = () => {
        setPhoneVerificationModal({
            show: true,
            phone: '(' + user.phone_code_area + ') ' + user.phone_number.toString().replace(/(\d{4,5})(\d{4})/, "$1-$2")
        })
    };

    return (
        <>
            <Header
                hideLinks
                subNavigationFixed
                showProgressBar={eligible}
                subNavigation={
                    <Flex
                        flex="1"
                        maxWidth={isMobile ? "100%" : "1222px"}
                        margin="auto"
                        justifyContent="flex-start"
                        padding="0px 16px"
                    >
                        <Link to={isBoxCheckout ? `/minha-conta/box/${currentCartBox.id}` : "/"}>
                            <ChevronLeft />
                            Voltar para {isBoxCheckout ? "o BOX" : "home"}
                        </Link>
                        <Flex flex="1" desktop>
                            {!isBoxCheckout && <StepsTitle active={currentStep === 1}>1. Dados pessoais</StepsTitle>}
                            {!isBoxCheckout && <StepsTitle active={currentStep === 2}>2. Dados de entrega</StepsTitle>}
                            <StepsTitle active={currentStep === 3}>
                                {isBoxCheckout ? "1" : "3"}. Formas de pagamento
                            </StepsTitle>
                            <StepsTitle active={currentStep === 4}>
                                {isBoxCheckout ? "2" : "4"}. Conferir e finalizar
                            </StepsTitle>
                        </Flex>
                        <Flex flex="1" margin="0 auto 0 30px" justifyContent="flex-start" mobile>
                            {!isBoxCheckout && (
                                <StepCircle active={currentStep === 1} next={currentStep < 1}>
                                    1
                                </StepCircle>
                            )}
                            {!isBoxCheckout && (
                                <StepCircle active={currentStep === 2} next={currentStep < 2}>
                                    2
                                </StepCircle>
                            )}
                            <StepCircle active={currentStep === 3} next={currentStep < 3}>
                                {isBoxCheckout ? "1" : "3"}
                            </StepCircle>
                            <StepCircle active={currentStep === 4} next={currentStep < 4}>
                                {isBoxCheckout ? "2" : "4"}
                            </StepCircle>
                        </Flex>
                    </Flex>
                }
            />

            {/* ## MOBILE INÍCIO ## */}
            {isMobile && (
                <Flex mobile flex="1">
                    <Flex
                        flex="1"
                        width={isMobile ? "100%" : "1222px"}
                        alignItems="flex-start"
                        direction="column"
                        margin={isMobile ? "50px 0 0 0" : "30px auto 54px auto"}
                        padding={isMobile ? "30px 0 0 0" : null}
                    >
                        <Flex
                            width="100%"
                            height="100%"
                            direction={isMobile ? "column" : "row"}
                            alignItems="flex-start"
                            justifyContent="flex-start"
                        >
                            <Flex
                                width={isMobile ? "100%" : "732px"}
                                flex="1"
                                height="100%"
                                alignItems="flex-start"
                                justifyContent="flex-start"
                                direction="column"
                                margin="0px 35px 20px 0"
                                padding={isMobile ? "0px 20px" : null}
                                boxSizing="border-box"
                            >
                                {(!currentStep || currentStep > 1) && !isBoxCheckout && (
                                    <>
                                        <SectionDataTitle margin="15px 0px 30px 0px" fontSize="20px">
                                            1. Dados pessoais{" "}
                                            {!user.token || (!values.cpf && (
                                                <>
                                                    {" "}<br /><SigninLinkNew />{" "}
                                                </>
                                            ))}
                                        </SectionDataTitle>
                                        <CardBox
                                            alignItems="flex-start"
                                            justifyContent="flex-start"
                                            direction="column"
                                            width="fill-available"
                                            padding="0"
                                        >
                                            <Flex
                                                padding={"15px"}
                                                background={"#f7f9f9"}
                                                direction="column"
                                                alignItems="flex-start"
                                                justifyContent="flex-start"
                                                width="fill-available"
                                                maxHeight={"600px"}
                                                overflow="hidden"
                                                transition="max-height, padding 0.25s ease-in-out"
                                            >
                                                <Flex justifyContent="flex-between" width="100%">
                                                    <CardBoxTitle>
                                                        {user.first_name} {user.last_name}
                                                    </CardBoxTitle>
                                                    <EditCardDelivery onClick={() => navigate('/minha-conta/cadastro')}>
                                                        Editar
                                                    </EditCardDelivery>
                                                </Flex>
                                                <TextSection margin="0">
                                                    {user.email} <br />
                                                    {user.cpf} <br />
                                                    ({user.phone_code_area}){" "}
                                                    {user.phone_number.toString().replace(/(\d{4,5})(\d{4})/, "$1-$2")}
                                                </TextSection>
                                            </Flex>
                                        </CardBox>
                                    </>
                                )}

                                {(!currentStep || (currentStep === 2 && user?.token)) &&
                                    currentStep !== 4 &&
                                    !isBoxCheckout && (
                                        <>
                                            <SectionDataTitle margin="50px 0px 30px 0px" fontSize="20px">
                                                2. Dados de entrega
                                            </SectionDataTitle>

                                            <ProfileForm
                                                values={values}
                                                handleChange={handleChange}
                                                errors={errors}
                                                user={user}
                                                handleZipCode={handleZipCode}
                                                noPerson={true}
                                            />
                                        </>
                                    )}

                                {currentStep > 2 && (
                                    <>
                                        <SectionDataTitle margin="50px 0px 30px 0px" fontSize="20px">
                                            2. Dados de entrega
                                        </SectionDataTitle>
                                        <CardBox
                                            alignItems="flex-start"
                                            justifyContent="flex-start"
                                            direction="column"
                                            width="fill-available"
                                            margin="0px 0px 20px 0px"
                                            padding="15px"
                                        >
                                            <Flex justifyContent="flex-between" width="100%">
                                                <TextSection margin="0">
                                                    {values.street}, {values.house_number}{" "}
                                                    {values.additional_info && " - " + values.additional_info}
                                                    <br />
                                                    {values.neighborhood && values.neighborhood + " - "} {values.city} -{" "}
                                                    {values.state}
                                                    <br />
                                                    CEP: {values.zip_code}
                                                    <br />
                                                </TextSection>

                                                <EditCardDelivery margin="10px 0px" onClick={() => setCurrentStep(2)}>
                                                    Editar
                                                </EditCardDelivery>
                                            </Flex>

                                            <PriceInfo padding="10px 1px" margin="0px" alignItems="flex-center">
                                                <Flex alignItems="flex-center" justifyContent="flex-start">
                                                    <Flex flex="0.5" margin="0px 20px 0px 0px">
                                                        {delivery && delivery.current && (
                                                            <DeliveryServiceLogoMin src={delivery.current.image_url} />
                                                        )}
                                                    </Flex>
                                                    <InfoDelivery direction="column" alignItems="flex-start">
                                                        <InfoDeliveryName>
                                                            {isBoxCheckout
                                                                ? "Box Realizada"
                                                                : (delivery &&
                                                                    delivery.current &&
                                                                    delivery.current.description) ||
                                                                "-"}
                                                        </InfoDeliveryName>
                                                        {delivery && delivery.current && (
                                                            <span>{`Até ${delivery.current.days} dia${delivery.current.days > 1 ? "s úteis" : " útil"
                                                                }`}</span>
                                                        )}
                                                    </InfoDelivery>
                                                </Flex>
                                            </PriceInfo>
                                        </CardBox>
                                    </>
                                )}

                                {/* FORMA DE PAGAMENTO MOBILE */}
                                {currentStep > 3 && (
                                    <>
                                        <SectionDataTitle margin="50px 0px 30px 0px" fontSize="20px">
                                            3. Forma de pagamento
                                        </SectionDataTitle>
                                        <CardBox
                                            alignItems="flex-start"
                                            justifyContent="flex-start"
                                            direction="column"
                                            width="fill-available"
                                            margin="0px 0px 20px 0px"
                                            padding="15px"
                                        >
                                            <Flex justifyContent="flex-between" width="100%">
                                                <CardBoxTitle alignItems="flex-center">
                                                    <span>
                                                        {values.payment_method === "boleto" && (
                                                            <span>
                                                                <img src={BarcodeLogo} alt="Boleto" />
                                                            </span>
                                                        )}
                                                        {values.payment_method === "debit" && (
                                                            <span>
                                                                <img src={ItauLogo} alt="Itaú" />
                                                            </span>
                                                        )}
                                                        {values.payment_method === "credit_card" && (
                                                            <>
                                                                {values.card_type === "AMEX" && (
                                                                    <span>
                                                                        <img
                                                                            src={AmericanExpressLogo}
                                                                            alt="American Express"
                                                                        />
                                                                    </span>
                                                                )}
                                                                {values.card_type === "MASTERCARD" && (
                                                                    <span>
                                                                        <img src={MasterCardLogo} alt="Master Card" />
                                                                    </span>
                                                                )}
                                                                {values.card_type === "VISA" && (
                                                                    <span>
                                                                        <img src={VisaLogo} alt="Visa" />
                                                                    </span>
                                                                )}
                                                                {values.card_type === "ELO" && (
                                                                    <span>
                                                                        <img src={EloLogo} alt="Elo" />
                                                                    </span>
                                                                )}
                                                            </>
                                                        )}
                                                        {values.payment_method === "pix" && (
                                                            <span>
                                                                <img src={PixLogo} alt="Logo PIX" />
                                                            </span>
                                                        )}
                                                        {"    "}
                                                        {values.useCredit && (
                                                            <span>
                                                                Créditos Re Petit{details?.total > 0 && " +"}&nbsp;
                                                            </span>
                                                        )}
                                                        {values.payment_method &&
                                                            payment_methods[values.payment_method]}
                                                        {values.payment_method === "boleto" &&
                                                            details &&
                                                            details.payment_options.boleto &&
                                                            details.payment_options.boleto.label &&
                                                            ` - ${details.payment_options.boleto.label}`}
                                                        {values.payment_method === "pix" &&
                                                            details &&
                                                            details.payment_options.pix &&
                                                            details.payment_options.pix.label &&
                                                            `PIX - ${details.payment_options.pix.label}`}
                                                    </span>
                                                </CardBoxTitle>
                                                <EditCard onClick={() => setCurrentStep(3)}>Editar</EditCard>
                                            </Flex>
                                            <TextSection margin="0">
                                                {values.payment_method === "credit_card" && (
                                                    <>
                                                        {values.card_number}
                                                        {values.card_name} <br />
                                                        {values.expires_at} <br />
                                                        {details &&
                                                            details.payment_options.credit_card.installments.map(
                                                                (element) => {
                                                                    if (
                                                                        parseInt(values.installments) ===
                                                                        element.installments
                                                                    ) {
                                                                        return (
                                                                            <>
                                                                                {`${element.installments} 
                                                                                    R$ ${parseFloat(
                                                                                    element.installment_amount
                                                                                ).toLocaleString("pt-br", {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2,
                                                                                })}
                                                                                     - TOTAL: R$ ${parseFloat(
                                                                                    element.calculated_amount
                                                                                ).toLocaleString("pt-br", {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2,
                                                                                })}
                                                                                `}
                                                                            </>
                                                                        );
                                                                    }
                                                                }
                                                            )}
                                                        <br />
                                                        <br />
                                                    </>
                                                )}
                                                {values?.billing_address === "same_address"
                                                    ? "Endereco de cobrança - mesmo endereço de entrega"
                                                    : ""}
                                                {values?.billing_address === "another_address" ? (
                                                    <>
                                                        {values?.another_address_first_name}{" "}
                                                        {values?.another_address_last_name}
                                                        <br />
                                                        {values?.another_address_city},{" "}
                                                        {values?.another_address_house_number}
                                                        <br />
                                                        {values?.another_address_additional}
                                                        <br />
                                                        {values?.another_address_neighborhood}
                                                        <br />
                                                        {values?.another_address_street}
                                                        <br />
                                                        {values?.another_address_state}
                                                        <br />
                                                        {values?.another_address_zip_code}
                                                        <br />
                                                    </>
                                                ) : (
                                                    ""
                                                )}
                                            </TextSection>
                                        </CardBox>
                                    </>
                                )}

                                {currentStep === 1 && (
                                    <>
                                        <SectionDataTitle margin="15px 0px" fontSize="20px">
                                            1. Dados pessoais{" "}
                                            {!user.token && (
                                                <>
                                                    <br />
                                                    <SigninLinkNew />
                                                </>
                                            )}
                                        </SectionDataTitle>
                                        {!user || !user.token ? (
                                            <ProfileForm
                                                values={values}
                                                handleChange={handleChange}
                                                errors={errors}
                                                user={user}
                                                handleZipCode={handleZipCode}
                                                noAddress={true}
                                            />
                                        ) : (
                                            <CardBox
                                                alignItems="flex-start"
                                                justifyContent="flex-start"
                                                direction="column"
                                                width="fill-available"
                                                padding="0"
                                            >
                                                <Flex
                                                    padding={"15px"}
                                                    background={"#f7f9f9"}
                                                    direction="column"
                                                    alignItems="flex-start"
                                                    justifyContent="flex-start"
                                                    width="fill-available"
                                                    maxHeight={"600px"}
                                                    overflow="hidden"
                                                    transition="max-height, padding 0.25s ease-in-out"
                                                >
                                                    <CardBoxTitle>
                                                        {user.first_name} {user.last_name}
                                                    </CardBoxTitle>
                                                    <TextSection margin="0">
                                                        {(values.cpf || "-")
                                                            .toString()
                                                            .replace(
                                                                /(\d{3})(\d{3})(\d{3})(\d{2})/,
                                                                "$1.$2.$3-$4"
                                                            )}{" "}
                                                        <br />
                                                        {values.email} <br />
                                                        {values.phone_number ? (
                                                            <>
                                                                ({values.phone_code_area}){" "}
                                                                {values.phone_number
                                                                    .substring(2)
                                                                    .toString()
                                                                    .replace(/(\d{4,5})(\d{4})/, "$1-$2")}
                                                            </>
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </TextSection>
                                                </Flex>
                                            </CardBox>
                                        )}
                                    </>
                                )}

                                {currentStep === 2 && (
                                    <>
                                        {/* Usuário Logado */}
                                        {user?.token && (
                                            <>
                                                <Flex
                                                    direction={isMobile ? "column" : "row"}
                                                    width="100%"
                                                    margin={isMobile ? "5px auto 5px 0" : "40px auto 45px 0"}
                                                >
                                                    <ContainerHideSmall>
                                                        {keepBuying()}

                                                        <Button
                                                            disabled={
                                                                canChangeStep() ||
                                                                (!details && isBoxCheckout) ||
                                                                sendingSignupRequest ||
                                                                isBoxCheckout
                                                            }
                                                            width={isMobile ? "320px" : "260px"}
                                                            padding="25px 1.25rem"
                                                            lower="initial"
                                                            dark
                                                            filled
                                                            value={
                                                                !sendingSignupRequest ? (
                                                                    isBoxCheckout ? (
                                                                        "Selecionar pagamento"
                                                                    ) : (
                                                                        "Escolher entrega"
                                                                    )
                                                                ) : (
                                                                    <LoadingSpinner />
                                                                )
                                                            }
                                                            margin={isMobile ? "0 auto 0 auto" : "0 0 0 auto"}
                                                            onClick={handleNextStep}
                                                        />
                                                    </ContainerHideSmall>
                                                </Flex>

                                                <CardBox
                                                    alignItems="flex-start"
                                                    justifyContent="flex-start"
                                                    direction="column"
                                                    width="fill-available"
                                                    margin="0px 0px 20px 0px"
                                                    padding="0"
                                                >
                                                    <Flex
                                                        padding="0px 16px 0px 16px"
                                                        width="100%"
                                                        boxSizing="border-box"
                                                        justifyContent="flex-start"
                                                    >
                                                        {(!delivery || !delivery.services) && (
                                                            <Flex
                                                                padding="17px 0"
                                                                margin="auto 0 auto 0"
                                                                justifyContent="flex-start"
                                                                alignItems="flex-start"
                                                                direction="column"
                                                            >
                                                                <CardBoxTitle>
                                                                    {!delivery || !delivery.error
                                                                        ? "Carregando..."
                                                                        : "erro ao calcular entrega"}
                                                                </CardBoxTitle>
                                                                {delivery && <p>{delivery.message}</p>}
                                                            </Flex>
                                                        )}

                                                        {delivery &&
                                                            !delivery.error &&
                                                            delivery.services.map((item, index) => (
                                                                <div key={index}>
                                                                    <CheckBox
                                                                        padding="17px 0"
                                                                        margin="auto 0 auto 0"
                                                                        name="delivery_method"
                                                                        value={item.service}
                                                                        radio
                                                                        small
                                                                        onChange={handleChange}
                                                                        defaultChecked={
                                                                            delivery.current &&
                                                                            delivery.current.service === item.service
                                                                        }
                                                                    >
                                                                        {item.image_url && (
                                                                            <DeliveryServiceLogo src={item.image_url} />
                                                                        )}
                                                                        <CardBoxTitle>
                                                                            {item.description}
                                                                            {item?.amount > 0 && !item?.free_shipping
                                                                                ? ` - R$ ${(
                                                                                    item?.amount || 0
                                                                                ).toLocaleString("pt-br", {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2,
                                                                                })}`
                                                                                : ` - GRÁTIS`}
                                                                            {item.obs && (
                                                                                <>
                                                                                    <br />
                                                                                    <span>{item.obs}</span>
                                                                                </>
                                                                            )}
                                                                            {item.service !== "store_pickup" && (
                                                                                <span>{`Até ${item.days} dia${item.days > 1 ? "s úteis" : " útil"
                                                                                    }`}</span>
                                                                            )}
                                                                        </CardBoxTitle>
                                                                    </CheckBox>
                                                                    {delivery.current &&
                                                                        delivery.current.service === item.service &&
                                                                        delivery.current.warning && (
                                                                            <PartnerContentInfo>
                                                                                {delivery.current.warning}
                                                                            </PartnerContentInfo>
                                                                        )}
                                                                    <WhiteSeparator />
                                                                </div>
                                                            ))}
                                                    </Flex>
                                                </CardBox>

                                                <DiscountCoupon
                                                    cupomDiscount={cupomDiscount}
                                                    infoDescription={info}
                                                    isUsingCredit={values.useCredit}
                                                    startOpened
                                                    value={promoCode}
                                                    padding={"0px 20px 0px 20px"}
                                                    onClick={async (e, cb) => {
                                                        if (!values.delivery_method) {
                                                            cb(
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                "Por favor, selecione um método de entrega para adicionar um cupom de desconto."
                                                            );
                                                            return;
                                                        }
                                                        setPromoCode(e);
                                                        let { data, status } = await handleDetailsRequest(
                                                            values.delivery_method,
                                                            e
                                                        );

                                                        if (status !== 200) {
                                                            setPromoCode("");
                                                            cb(
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                "Este cupom não existe, expirou ou está inativo"
                                                            );
                                                        } else {
                                                            const { total, line_items } = data;
                                                            const { amount, description } = line_items[1];

                                                            setCupomDiscount(line_items[1]);

                                                            const formtAmount = Number(
                                                                amount.toString().replace("-", "")
                                                            );
                                                            const percentage =
                                                                (formtAmount * 100) / (total + formtAmount);
                                                            const info = data.line_items[1].info;
                                                            cb(
                                                                description,
                                                                amount || "0",
                                                                percentage,
                                                                undefined,
                                                                info !== "" && info
                                                            );

                                                            setInfo(info);
                                                            if (data && data.delivery && data.delivery.delivery_data) {
                                                                setDelivery({
                                                                    ...delivery,
                                                                    current: data.delivery.delivery_data,
                                                                });
                                                                setValues({
                                                                    ...values,
                                                                    delivery_method: data.delivery.selected,
                                                                });
                                                            }
                                                        }
                                                    }}
                                                />

                                                <Flex
                                                    direction={isMobile ? "column" : "row"}
                                                    width={isMobile ? "100%" : "fill-available"}
                                                    margin={isMobile ? "5px auto 5px 0" : "40px auto 45px 0"}
                                                >
                                                    <Button
                                                        disabled={canChangeStep() || !details || user.needs_phone_verification}
                                                        width={isMobile ? "320px" : "260px"}
                                                        padding="25px 1.25rem"
                                                        lower="initial"
                                                        pink
                                                        filled
                                                        borderradius
                                                        fontFamily="Graphik"
                                                        bigger
                                                        fontWeight="600"
                                                        value="Selecionar pagamento"
                                                        margin={isMobile ? "10px auto 10px auto" : "0 0 0 auto"}
                                                        onClick={handleNextStep}
                                                    />
                                                    {user.needs_phone_verification && (
                                                        <Button
                                                            margin="0px"
                                                            width={isMobile ? "320px" : "260px"}
                                                            padding="25px 1.25rem"
                                                            lower="initial"
                                                            dark
                                                            filled
                                                            borderradius
                                                            fontFamily="Graphik"
                                                            bigger
                                                            fontWeight="600"
                                                            value="Valide sua conta"
                                                            onClick={handlePhoneModal}
                                                        />
                                                    )}
                                                    {keepBuying()}
                                                </Flex>
                                            </>
                                        )}
                                    </>
                                )}

                                {currentStep === 3 && (
                                    <>
                                        <SectionDataTitle margin="50px 0px 30px 0px" fontSize="20px">
                                            {isBoxCheckout ? "1" : "3"}. Formas de pagamento
                                        </SectionDataTitle>
                                        <CardBox
                                            alignItems="flex-start"
                                            justifyContent="flex-start"
                                            direction="column"
                                            width="fill-available"
                                            margin="0px 0px 20px 0px"
                                            padding="0"
                                        >
                                            {details?.balance?.total_balance > 0 && (
                                                <Flex padding="9px 20px 9px 16px" width="100%" boxSizing="border-box">
                                                    <CheckBox
                                                        margin="auto auto auto 0"
                                                        name="useCredit"
                                                        value={values.useCredit}
                                                        defaultChecked={values.useCredit}
                                                        small
                                                        onChange={handleChange}
                                                    >
                                                        <CardBoxTitleMin margin="0" flex="0.4">
                                                            <p>USAR CRÉDITOS -</p>
                                                            R${" "}
                                                            {parseFloat(
                                                                details?.balance?.total_balance || 0
                                                            ).toLocaleString("pt-br", {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            })}{" "}
                                                            - DESCONTO DE 10%
                                                        </CardBoxTitleMin>
                                                        <img
                                                            width="auto"
                                                            height="28px"
                                                            src={RepetitLogo}
                                                            alt="Re Petit"
                                                        />
                                                    </CheckBox>
                                                    <TextSection margin={"10px 12px 0px 0px"} fontSize={"12px"}>
                                                        *Desconto válido somente para crédito não gerado por devolução
                                                        de produto.
                                                    </TextSection>
                                                </Flex>
                                            )}

                                            {details?.balance?.user_balance > 0 &&
                                                details?.total + details?.credit_used >
                                                details?.balance?.total_balance && (
                                                    <>
                                                        <WarningBox>
                                                            <strong>ATENÇÃO: </strong> O valor do crédito é menor do que
                                                            o valor total a ser pago. Favor escolher também outra forma
                                                            de pagamento.
                                                        </WarningBox>
                                                    </>
                                                )}
                                        </CardBox>

                                        <CardBox
                                            alignItems="flex-start"
                                            justifyContent="flex-start"
                                            direction="column"
                                            width="fill-available"
                                            margin="0px 0px 20px 0px"
                                            padding="0px"
                                        >
                                            {details?.total > 0 && (
                                                <>
                                                    {/* CARTÃO CRÉDITO MOBILE */}
                                                    {details && details.payment_options.credit_card && (
                                                        <>
                                                            <WhiteSeparator />
                                                            <Flex
                                                                padding="20px 10px"
                                                                justifyContent="flex-start"
                                                                width="100%"
                                                                boxSizing="border-box"
                                                            >
                                                                <CheckBox
                                                                    margin="auto 17px auto 0"
                                                                    name="payment_method"
                                                                    value="credit_card"
                                                                    defaultChecked={
                                                                        values.payment_method === "credit_card"
                                                                    }
                                                                    radio
                                                                    small
                                                                    onChange={handleChange}
                                                                >
                                                                    <CardBoxTitleMin margin="0">
                                                                        Cartão de crédito{" "}
                                                                        {details &&
                                                                            details.payment_options.credit_card &&
                                                                            details.payment_options.credit_card.label &&
                                                                            ` - ${details?.payment_options.credit_card.label}`}
                                                                    </CardBoxTitleMin>
                                                                    <CreditCardLogo>
                                                                        <img
                                                                            src={AmericanExpressLogo}
                                                                            alt="American Express"
                                                                        />
                                                                    </CreditCardLogo>
                                                                    <CreditCardLogo>
                                                                        <img src={MasterCardLogo} alt="Master Card" />
                                                                    </CreditCardLogo>
                                                                    <CreditCardLogo>
                                                                        <img src={VisaLogo} alt="Visa" />
                                                                    </CreditCardLogo>
                                                                    <CreditCardLogo
                                                                        margin={isMobile ? "0 auto 0 0" : null}
                                                                    >
                                                                        <img src={EloLogo} alt="Elo" />
                                                                    </CreditCardLogo>
                                                                </CheckBox>
                                                            </Flex>
                                                            {values.payment_method === "credit_card" && (
                                                                <Flex
                                                                    flex="1"
                                                                    width="100%"
                                                                    direction={isMobile ? "column" : "row"}
                                                                    padding="23px 20px 14px 16px"
                                                                    boxSizing="border-box"
                                                                    alignItems="flex-start"
                                                                >
                                                                    <TextInput
                                                                        flex="1"
                                                                        margin={
                                                                            isMobile ? "0 0 16px 0" : "0 16px 16px 0"
                                                                        }
                                                                        prepend={
                                                                            <>
                                                                                <CreditCardLogo
                                                                                    cardField
                                                                                    blank
                                                                                    active={!values.card_type}
                                                                                >
                                                                                    <img
                                                                                        src={CardIcon}
                                                                                        alt="Credit Card"
                                                                                    />
                                                                                </CreditCardLogo>
                                                                                <CreditCardLogo
                                                                                    cardField
                                                                                    active={values.card_type === "AMEX"}
                                                                                >
                                                                                    <img
                                                                                        src={AmericanExpressLogo}
                                                                                        alt="American Express"
                                                                                    />
                                                                                </CreditCardLogo>
                                                                                <CreditCardLogo
                                                                                    cardField
                                                                                    active={
                                                                                        values.card_type ===
                                                                                        "MASTERCARD"
                                                                                    }
                                                                                >
                                                                                    <img
                                                                                        src={MasterCardLogo}
                                                                                        alt="Master Card"
                                                                                    />
                                                                                </CreditCardLogo>
                                                                                <CreditCardLogo
                                                                                    cardField
                                                                                    active={values.card_type === "VISA"}
                                                                                >
                                                                                    <img src={VisaLogo} alt="Visa" />
                                                                                </CreditCardLogo>
                                                                                <CreditCardLogo
                                                                                    cardField
                                                                                    active={values.card_type === "ELO"}
                                                                                >
                                                                                    <img src={EloLogo} alt="Elo" />
                                                                                </CreditCardLogo>
                                                                            </>
                                                                        }
                                                                        placeholder="Número do cartão"
                                                                        name="card_number"
                                                                        mask="9999 9999 9999 9999"
                                                                        value={values.card_number}
                                                                        onChange={handleChange}
                                                                    />

                                                                    <TextInput
                                                                        flex="1"
                                                                        margin="auto 0 16px 0"
                                                                        placeholder="Nome no cartão"
                                                                        name="card_name"
                                                                        value={values.card_name}
                                                                        onChange={handleChange}
                                                                    />

                                                                    <Flex flex="0 0 100%">
                                                                        <Flex flex="0 0 100%">
                                                                            <Flex
                                                                                flex="0 0 auto"
                                                                                width="200px"
                                                                                maxWidth="50%"
                                                                                margin="0 0 16px 0"
                                                                            >
                                                                                <Text>Validade</Text>
                                                                                <TextInput
                                                                                    flex="0 0 100px"
                                                                                    margin="auto 0 0 14px"
                                                                                    placeholder="MM/YY"
                                                                                    mask="99/99"
                                                                                    name="expires_at"
                                                                                    value={values.expires_at}
                                                                                    onChange={handleChange}
                                                                                />
                                                                            </Flex>

                                                                            <Flex
                                                                                flex="0 0 auto"
                                                                                width="200px"
                                                                                maxWidth="49%"
                                                                                margin="0 auto 16px 0"
                                                                            >
                                                                                <Text>Código</Text>
                                                                                <TextInput
                                                                                    flex="0 0 115px"
                                                                                    margin="auto 0 0 14px"
                                                                                    prepend={
                                                                                        <img src={AskIcon} alt="Ask" />
                                                                                    }
                                                                                    mask="9999"
                                                                                    placeholder="CCV"
                                                                                    name="ccv"
                                                                                    value={values.ccv}
                                                                                    onChange={handleChange}
                                                                                />
                                                                            </Flex>
                                                                        </Flex>

                                                                        <Flex flex="0 0 100%">
                                                                            <SelectInput
                                                                                width={isMobile ? "100%" : "auto"}
                                                                                margin="0 auto 16px 0"
                                                                                name="installments"
                                                                                value={values.installments}
                                                                                onChange={handleChange}
                                                                                options={
                                                                                    details &&
                                                                                    details.payment_options.credit_card.installments.reduce(
                                                                                        (acc, curr) => {
                                                                                            return [
                                                                                                ...acc,
                                                                                                {
                                                                                                    label: `${curr.installments
                                                                                                        }x R$ ${parseFloat(
                                                                                                            curr.installment_amount
                                                                                                        ).toLocaleString(
                                                                                                            "pt-br",
                                                                                                            {
                                                                                                                minimumFractionDigits: 2,
                                                                                                                maximumFractionDigits: 2,
                                                                                                            }
                                                                                                        )} - TOTAL: R$ ${parseFloat(
                                                                                                            curr.calculated_amount
                                                                                                        ).toLocaleString(
                                                                                                            "pt-br",
                                                                                                            {
                                                                                                                minimumFractionDigits: 2,
                                                                                                                maximumFractionDigits: 2,
                                                                                                            }
                                                                                                        )}`,
                                                                                                    value: curr.installments,
                                                                                                },
                                                                                            ];
                                                                                        },
                                                                                        []
                                                                                    )
                                                                                }
                                                                                error={errors.another_address_state}
                                                                            />
                                                                        </Flex>

                                                                        <Flex flex="0 0 100%">
                                                                            <CheckBox
                                                                                margin={
                                                                                    !values.self_holder
                                                                                        ? "0 auto 20px 0"
                                                                                        : "0 auto 0 0"
                                                                                }
                                                                                name="self_holder"
                                                                                value={values.self_holder}
                                                                                onChange={handleChange}
                                                                                defaultChecked={values.self_holder}
                                                                                label={
                                                                                    <CardBoxTitle>
                                                                                        Sou o titular do cartão
                                                                                    </CardBoxTitle>
                                                                                }
                                                                            />

                                                                            {!values.self_holder && (
                                                                                <>
                                                                                    <Flex
                                                                                        flex="1"
                                                                                        minWidth="100%"
                                                                                        margin={
                                                                                            isMobile
                                                                                                ? "0 0 20px 0"
                                                                                                : "0 17.5px 20px 0"
                                                                                        }
                                                                                    >
                                                                                        <TextInput
                                                                                            flex="1"
                                                                                            margin="0 17.5px 0 0"
                                                                                            placeholder="Nome do titular"
                                                                                            name="holder_name"
                                                                                            value={values.holder_name}
                                                                                            onChange={handleChange}
                                                                                            error={errors.holder_name}
                                                                                        />
                                                                                        <TextInput
                                                                                            width="150px"
                                                                                            margin={
                                                                                                isMobile
                                                                                                    ? "0 0 0 0"
                                                                                                    : "0 0 0 17.5px"
                                                                                            }
                                                                                            placeholder="Data de nascimento (DD/MM/AAAA)"
                                                                                            mask="99/99/9999"
                                                                                            maskPlaceholder="dd/mm/yyyy"
                                                                                            name="holder_birth_date"
                                                                                            value={
                                                                                                values.holder_birth_date
                                                                                            }
                                                                                            onChange={handleChange}
                                                                                            error={
                                                                                                errors.holder_birth_date
                                                                                            }
                                                                                        />
                                                                                    </Flex>

                                                                                    <Flex
                                                                                        flex="1"
                                                                                        minWidth="100%"
                                                                                        margin="0"
                                                                                    >
                                                                                        <TextInput
                                                                                            flex="0.5"
                                                                                            maxWidth={
                                                                                                isMobile
                                                                                                    ? "100%"
                                                                                                    : "calc(50% - 17.5px)"
                                                                                            }
                                                                                            margin="0 17.5px 0 0"
                                                                                            placeholder="CPF do Titular do cartão"
                                                                                            name="holder_cpf"
                                                                                            mask="999.999.999-**"
                                                                                            value={values.holder_cpf}
                                                                                            onChange={handleChange}
                                                                                            error={errors.holder_cpf}
                                                                                        />

                                                                                        <TextInput
                                                                                            flex="0.5"
                                                                                            margin={
                                                                                                isMobile
                                                                                                    ? "0"
                                                                                                    : "0 0 0 17.5px"
                                                                                            }
                                                                                            placeholder="Celular do Titular do cartão com DDD (XX) XXXXX-XXXX"
                                                                                            mask="(99) 9 9999-9999"
                                                                                            name="holder_phone_number"
                                                                                            value={
                                                                                                values.holder_phone_number &&
                                                                                                (
                                                                                                    values.holder_phone_number ||
                                                                                                    ""
                                                                                                ).replace(
                                                                                                    /undefined/g,
                                                                                                    ""
                                                                                                )
                                                                                            }
                                                                                            onChange={handleChange}
                                                                                            error={
                                                                                                errors.holder_phone_number
                                                                                            }
                                                                                        />
                                                                                    </Flex>
                                                                                </>
                                                                            )}
                                                                        </Flex>
                                                                    </Flex>
                                                                </Flex>
                                                            )}
                                                        </>
                                                    )}

                                                    {/* BOLETO MOBILE*/}
                                                    {details && details.payment_options.boleto && (
                                                        <>
                                                            <WhiteSeparator />
                                                            <Flex
                                                                width="100%"
                                                                justifyContent="flex-start"
                                                                padding="20px 10px"
                                                                boxSizing="border-box"
                                                            >
                                                                <CheckBox
                                                                    margin="auto 17px auto 0"
                                                                    name="payment_method"
                                                                    value="boleto"
                                                                    defaultChecked={values.payment_method === "boleto"}
                                                                    radio
                                                                    small
                                                                    onChange={handleChange}
                                                                >
                                                                    <CardBoxTitleMin margin="0">
                                                                        Boleto bancário
                                                                        {details &&
                                                                            details.payment_options.boleto &&
                                                                            details.payment_options.boleto.label &&
                                                                            ` - ${details.payment_options.boleto.label}`}
                                                                    </CardBoxTitleMin>

                                                                    <CreditCardLogo>
                                                                        <img src={BarcodeLogo} alt="Boleto" />
                                                                    </CreditCardLogo>
                                                                </CheckBox>

                                                                {values.payment_method === "boleto" && (
                                                                    <WarningBox>
                                                                        <strong>ATENÇÃO:</strong> Emita o boleto apenas
                                                                        se for pagar! Boletos não pagos prejudicam os
                                                                        vendedores e outros interessados. Trabalhamos
                                                                        com peças únicas e não garantimos a
                                                                        disponibilidade caso você não pague e queira
                                                                        resgatá-las com outra forma de pagamento.
                                                                    </WarningBox>
                                                                )}
                                                            </Flex>
                                                        </>
                                                    )}

                                                    {/* PIX MOBILE*/}
                                                    {details && details.payment_options.pix && (
                                                        <>
                                                            <WhiteSeparator />
                                                            <Flex
                                                                width="100%"
                                                                justifyContent="flex-start"
                                                                padding="20px 10px"
                                                                boxSizing="border-box"
                                                            >
                                                                <CheckBox
                                                                    margin="auto 17px auto 0"
                                                                    name="payment_method"
                                                                    value="pix"
                                                                    defaultChecked={values.payment_method === "pix"}
                                                                    radio
                                                                    small
                                                                    onChange={handleChange}
                                                                >
                                                                    <CardBoxTitleMin margin="0">
                                                                        PIX
                                                                        {details &&
                                                                            details.payment_options.pix &&
                                                                            details.payment_options.pix.label &&
                                                                            ` - ${details.payment_options.pix.label}`}
                                                                    </CardBoxTitleMin>

                                                                    <CreditCardLogo>
                                                                        <img src={PixLogo} alt="Logo PIX" />
                                                                    </CreditCardLogo>
                                                                </CheckBox>
                                                            </Flex>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </CardBox>

                                        {(values.payment_method !== undefined ||
                                            (details?.total === 0 && details?.credit_used > 0)) && (
                                                <>
                                                    <CardBoxTitle>Endereço de Cobrança</CardBoxTitle>

                                                    <Flex
                                                        padding="9px 20px 9px 16px"
                                                        margin="0"
                                                        border="solid 1px #c9c9cf"
                                                        justifyContent="flex-start"
                                                        width="fill-available"
                                                    >
                                                        <CheckBox
                                                            name="billing_address"
                                                            value="same_address"
                                                            radio
                                                            small
                                                            defaultChecked={values.billing_address === "same_address"}
                                                            onChange={handleChange}
                                                        >
                                                            <CardBoxTitle>Mesmo endereço da entrega</CardBoxTitle>
                                                        </CheckBox>
                                                        <Chevron
                                                            active={showAddress}
                                                            onClick={() => setShowAddress(!showAddress)}
                                                        />
                                                    </Flex>
                                                    <Flex
                                                        padding={showAddress ? "28px 0px 10px 57px" : "0"}
                                                        margin="0 0 20px 0"
                                                        border={showAddress ? "solid 1px #c9c9cf" : ""}
                                                        direction="column"
                                                        alignItems="flex-start"
                                                        justifyContent="flex-start"
                                                        width="fill-available"
                                                        maxHeight={showAddress ? "600px" : "0px"}
                                                        overflow="hidden"
                                                        transition="max-height, padding 0.25s ease-in-out"
                                                    >
                                                        <CardBoxTitle margin="0 0 4px 0">
                                                            {`${values.first_name} ${values.last_name}`}
                                                        </CardBoxTitle>

                                                        <TextSection margin="0">
                                                            {values.street}, {values.house_number}{" "}
                                                            {values.additional_info && " - " + values.additional_info}
                                                            <br />
                                                            {values.neighborhood && values.neighborhood + " - "}{" "}
                                                            {values.city} - {values.state}
                                                            <br />
                                                            CEP: {values.zip_code}
                                                            <br />
                                                        </TextSection>
                                                    </Flex>

                                                    <Flex
                                                        padding="23px 16px"
                                                        margin="0 0 20px 0"
                                                        border="solid 1px #c9c9cf"
                                                        justifyContent="flex-start"
                                                        width="fill-available"
                                                    >
                                                        <CheckBox
                                                            name="billing_address"
                                                            value="another_address"
                                                            radio
                                                            small
                                                            onChange={handleChange}
                                                            defaultChecked={values.billing_address === "another_address"}
                                                        >
                                                            <CardBoxTitle>outro endereço de cobrança</CardBoxTitle>
                                                        </CheckBox>
                                                    </Flex>

                                                    {values.billing_address === "another_address" && (
                                                        <Flex
                                                            width="100%"
                                                            alignItems="flex-start"
                                                            direction={isMobile ? "column" : "row"}
                                                        >
                                                            <Flex
                                                                flex={isMobile ? "1" : "0.5"}
                                                                width="100%"
                                                                direction="column"
                                                                margin={isMobile ? "0 0 20px 0" : "0 35px 0 0"}
                                                            >
                                                                <TextInput
                                                                    margin="0 0 20px 0"
                                                                    placeholder="Nome"
                                                                    name="another_address_first_name"
                                                                    value={values.another_address_first_name}
                                                                    onChange={handleChange}
                                                                    error={errors.another_address_first_name}
                                                                />
                                                                <TextInput
                                                                    margin="0 0 20px 0"
                                                                    placeholder="CEP"
                                                                    name="another_address_zip_code"
                                                                    mask="99999-999"
                                                                    value={values.another_address_zip_code}
                                                                    onChange={handleChange}
                                                                    onBlur={handleZipCode}
                                                                    error={errors.another_address_zip_code}
                                                                />

                                                                <Flex width="100%">
                                                                    <TextInput
                                                                        flex="1"
                                                                        margin="0 20px 20px 0"
                                                                        placeholder="Endereço"
                                                                        name="another_address_street"
                                                                        value={values.another_address_street}
                                                                        onChange={handleChange}
                                                                        error={errors.another_address_street}
                                                                    />
                                                                    <TextInput
                                                                        width="80px"
                                                                        margin="0 0 20px 0"
                                                                        placeholder="Nº"
                                                                        name="another_address_house_number"
                                                                        value={values.another_address_house_number}
                                                                        onChange={handleChange}
                                                                        error={errors.another_address_house_number}
                                                                    />
                                                                </Flex>

                                                                <TextInput
                                                                    margin="0 0 20px 0"
                                                                    placeholder="Bairro"
                                                                    name="another_address_neighborhood"
                                                                    value={values.another_address_neighborhood}
                                                                    onChange={handleChange}
                                                                    error={errors.another_address_neighborhood}
                                                                />
                                                            </Flex>

                                                            <Flex
                                                                flex={isMobile ? "1" : "0.5"}
                                                                width="100%"
                                                                direction="column"
                                                                margin="0"
                                                            >
                                                                <TextInput
                                                                    margin="0 0 20px 0"
                                                                    placeholder="Sobrenome"
                                                                    name="another_address_last_name"
                                                                    value={values.another_address_last_name}
                                                                    onChange={handleChange}
                                                                    error={errors.another_address_last_name}
                                                                />

                                                                <Flex width="100%">
                                                                    <TextInput
                                                                        flex="1"
                                                                        margin="0 20px 20px 0"
                                                                        placeholder="Cidade"
                                                                        name="another_address_city"
                                                                        value={values.another_address_city}
                                                                        onChange={handleChange}
                                                                        error={errors.another_address_city}
                                                                    />
                                                                    <SelectInput
                                                                        width="80px"
                                                                        margin="0 0 20px 0"
                                                                        placeholder="UF"
                                                                        mask="aa"
                                                                        name="another_address_state"
                                                                        value={values.another_address_state}
                                                                        onChange={handleChange}
                                                                        options={brazilianStates}
                                                                        error={errors.another_address_state}
                                                                    />
                                                                </Flex>

                                                                <TextInput
                                                                    margin="0 0 20px 0"
                                                                    placeholder="Complemento"
                                                                    name="another_address_additional"
                                                                    value={values.another_address_additional}
                                                                    onChange={handleChange}
                                                                    error={errors.another_address_additional}
                                                                />
                                                            </Flex>
                                                        </Flex>
                                                    )}
                                                </>
                                            )}

                                        <Flex
                                            direction={isMobile ? "column" : "row"}
                                            width={isMobile ? "100%" : "fill-available"}
                                            margin={isMobile ? "5px auto 5px 0" : "40px auto 45px 0"}
                                        ></Flex>
                                    </>
                                )}

                                {currentStep === 4 && (
                                    <>
                                        <Flex width="100%" justifyContent="center" margin="0px 0px 20px 0px">
                                            <ProgressCard>
                                                {eligible && parseInt(missingAmount) > 0 && (
                                                    <>
                                                        <Text>
                                                            Com mais R$ {missingAmount} você ganha <b>Frete Grátis</b>
                                                        </Text>
                                                        <ProgressContainer>
                                                            <ProgressBarInner style={{ width: `${progress}%` }} />
                                                        </ProgressContainer>
                                                    </>
                                                )}

                                                {eligible && parseInt(missingAmount) <= 0 && (
                                                    <>
                                                        <Text>
                                                            Parabéns, você recebeu <b>FRETE GRÁTIS!</b>
                                                            {hasProductWithFalseShipping && (
                                                                <>
                                                                    <br />
                                                                    Porém há produtos no seu carrinho que não são
                                                                    aplicáveis ao <b>Frete Grátis</b>.
                                                                </>
                                                            )}
                                                        </Text>
                                                        <ProgressContainer>
                                                            <ProgressBarInner style={{ width: `100%` }} />
                                                        </ProgressContainer>
                                                    </>
                                                )}
                                            </ProgressCard>
                                            <SectionDataTitle margin="30px 0px 30px 0px" fontSize="20px">
                                                Resumo do Pedido ({products?.length || 0})
                                            </SectionDataTitle>
                                        </Flex>
                                        <Flex
                                            width="100%"
                                            justifyContent="flex-start"
                                            direction="column"
                                            warp="nowrap"
                                            mobile
                                        >
                                            {isOnlyBoxDelivery && (
                                                <HorizontalProductItemCheckout
                                                    item={{
                                                        images: [BoxImage],
                                                        title: `Box ${currentCartBox && currentCartBox.box_type.name}`,
                                                    }}
                                                    hideRemove
                                                    cart
                                                    hideSpecs
                                                />
                                            )}
                                            {products.map((item, index) => (
                                                <HorizontalProductItemCheckout
                                                    showId={isBoxCheckout}
                                                    key={index}
                                                    item={item}
                                                    cart
                                                    hideSpecs
                                                    hideRemove={sendingCheckout}
                                                    handleRemove={handleRemove}
                                                    enabledFreeShipping={enabled}
                                                />
                                            ))}
                                        </Flex>

                                        {currentInstallment && currentInstallment.discount_amount > 0 && (
                                            <PriceInfo padding="20px 20px">
                                                <span>Desconto</span>
                                                <span>
                                                    R$ -{" "}
                                                    {currentInstallment.discount_amount.toLocaleString("pt-br", {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 2,
                                                    })}
                                                </span>
                                            </PriceInfo>
                                        )}

                                        {details ? (
                                            line_items.map((line, index) =>
                                                line.description.toLowerCase() === "entrega" ? (
                                                    <ShippingRow
                                                        key={index}
                                                        free={line.amount === 0}
                                                        padding="20px 20px"
                                                    >
                                                        <span>Entrega</span>
                                                        <span>
                                                            {line.amount === 0
                                                                ? "Grátis"
                                                                : `R$ ${line.amount.toLocaleString("pt-br", {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                })}`}
                                                        </span>
                                                    </ShippingRow>
                                                ) : (
                                                    <>
                                                        <ReactTooltip />
                                                        <PriceInfo padding="20px 20px">
                                                            {line.info && line.info != "" ? (
                                                                <span data-tip={line.info}>
                                                                    {line.description}{" "}
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        width="16"
                                                                        height="16"
                                                                        color="#e4858c"
                                                                        fill="currentColor"
                                                                        class="bi bi-exclamation-circle-fill"
                                                                        viewBox="0 0 16 16"
                                                                    >
                                                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                                                    </svg>
                                                                </span>
                                                            ) : (
                                                                <span>{line.description}</span>
                                                            )}
                                                            <span>
                                                                R${" "}
                                                                {line.amount.toLocaleString("pt-br", {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                })}
                                                            </span>
                                                        </PriceInfo>
                                                    </>
                                                )
                                            )
                                        ) : (
                                            <></>
                                        )}

                                        {currentStep >= 3 && !isBoxCheckout && (
                                            <>
                                                <DiscountCoupon
                                                    cupomDiscount={cupomDiscount}
                                                    isUsingCredit={values.useCredit}
                                                    infoDescription={info}
                                                    padding="20px 20px"
                                                    value={promoCode}
                                                    onClick={async (e, cb) => {
                                                        setPromoCode(e);
                                                        let { data, status } = await handleDetailsRequest(
                                                            values.delivery_method,
                                                            e
                                                        );
                                                        if (status !== 200) {
                                                            cb(
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                "Este cupom não existe, expirou ou está inativo"
                                                            );
                                                        } else {
                                                            const { total, line_items } = data;
                                                            const { amount, description } = line_items[1];
                                                            const formtAmount = Number(
                                                                amount.toString().replace("-", "")
                                                            );
                                                            const percentage =
                                                                (formtAmount * 100) / (total + formtAmount);
                                                            const info = data.line_items[1].info;
                                                            setInfo(info);
                                                            cb(
                                                                description,
                                                                amount || "0",
                                                                percentage,
                                                                undefined,
                                                                info !== "" && info
                                                            );

                                                            if (
                                                                data &&
                                                                data.delivery &&
                                                                data.delivery.delivery_data &&
                                                                data.delivery.selected
                                                            ) {
                                                                setDelivery({
                                                                    ...delivery,
                                                                    current: data.delivery.delivery_data,
                                                                });
                                                            }
                                                        }
                                                    }}
                                                />
                                            </>
                                        )}

                                        {delivery && delivery.current && (
                                            <PriceInfo padding="20px 20px">
                                                <span>total</span>
                                                <span>
                                                    R${" "}
                                                    {details && values.payment_method === undefined
                                                        ? details.total.toLocaleString("pt-br", {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        })
                                                        : currentInstallment
                                                            ? currentInstallment.calculated_amount.toLocaleString("pt-br", {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            })
                                                            : (
                                                                products.reduce((acc, curr) => {
                                                                    return acc + parseFloat(curr.price_label || 0);
                                                                }, 0) + delivery.current.amount
                                                            ).toLocaleString("pt-br", {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            })}
                                                </span>
                                            </PriceInfo>
                                        )}

                                        <Flex width="100%" margin="40px 0 45px auto">
                                            <Button
                                                disabled={canChangeStep() || sendingCheckout}
                                                width={isMobile ? "320px" : "260px"}
                                                padding="25px 1.25rem"
                                                lower="initial"
                                                pink
                                                filled
                                                borderradius
                                                fontFamily="Graphik"
                                                bigger
                                                fontWeight="600"
                                                margin={isMobile ? "0 auto 0 auto" : "0 0 0 auto"}
                                                value={isOnlyBoxDelivery ? "Finalizar pagamento" : "Finalizar pedido"}
                                                onClick={handleNextStep}
                                                loading={sendingCheckout}
                                            />
                                        </Flex>
                                    </>
                                )}
                            </Flex>

                            <Flex
                                width={isMobile ? "100%" : "420px"}
                                maxWidth={isMobile ? "100%" : "420px"}
                                flex="1"
                                alignItems="flex-start"
                                justifyContent="flex-start"
                                direction="column"
                                margin={isMobile ? "0" : "54px 0 0 0"}
                                boxSizing="border-box"
                            >
                                <ResumeCard>
                                    {currentStep !== 4 ? (
                                        <>
                                            <ContainerShowSmall>
                                                {currentStep === 1 && (
                                                    <>
                                                        {!user || !user.token ? (
                                                            <Button
                                                                disabled={false}
                                                                width={isMobile ? "320px" : "260px"}
                                                                lower="initial"
                                                                pink
                                                                filled
                                                                borderradius
                                                                fontFamily="Graphik"
                                                                bigger
                                                                fontWeight="600"
                                                                margin={isMobile ? "0 auto 0 auto" : "0 0 0 auto"}
                                                                value="Criar conta"
                                                                onClick={handleNextStep}
                                                            />
                                                        ) : (
                                                            <Button
                                                                disabled={canChangeStep() || !details}
                                                                width={isMobile ? "320px" : "260px"}
                                                                padding="25px 1.25rem"
                                                                lower="initial"
                                                                pink
                                                                margin={isMobile ? "0 auto 0 auto" : "0 0 0 auto"}
                                                                filled
                                                                borderradius
                                                                fontFamily="Graphik"
                                                                bigger
                                                                fontWeight="600"
                                                                value="Selecionar pagamento"
                                                                onClick={handleNextStep}
                                                            />
                                                        )}
                                                        {keepBuying()}
                                                    </>
                                                )}

                                                {currentStep === 3 && (
                                                    <>
                                                        <Button
                                                            disabled={canChangeStep()}
                                                            width={isMobile ? "320px" : "260px"}
                                                            lower="initial"
                                                            padding="25px 1.25rem"
                                                            pink
                                                            filled
                                                            borderradius
                                                            fontFamily="Graphik"
                                                            bigger
                                                            fontWeight="600"
                                                            margin={isMobile ? "30px auto 30px auto" : "0 0 0 auto"}
                                                            value="Conferir e finalizar"
                                                            onClick={handleNextStep}
                                                        />
                                                        {keepBuying()}
                                                    </>
                                                )}
                                            </ContainerShowSmall>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </ResumeCard>
                            </Flex>
                            {currentStep !== 4 && (
                                <ResumeOpen>
                                    <SectionTitleMobile margin="25px 0px 25px 0px" onClick={handleModal}>
                                        Resumo do Pedido ({products?.length || 0})
                                        <ChevronModal active={false} />
                                    </SectionTitleMobile>
                                </ResumeOpen>
                            )}
                            {isMobile && currentStep !== 4 && showModalBottom === true && (
                                <>
                                    <ContainerModalMobile>
                                        <DialogMobile>
                                            <Flex
                                                direction="row"
                                                alignItems="flex-center"
                                                justifyContent="center"
                                                background="rgb(124 168 175)"
                                            >
                                                <SectionTitleMobile margin="20px 0px 20px 0px" onClick={handleModal}>
                                                    Resumo do Pedido ({products?.length || 0})
                                                    <ChevronModal active={true} />
                                                </SectionTitleMobile>
                                            </Flex>
                                        </DialogMobile>
                                        <FooterModalMobile>
                                            <Flex
                                                width="100%"
                                                justifyContent="center"
                                                flexDirection="column"
                                                alignItems="center"
                                            >
                                                {line_items.map((line, index) =>
                                                    line.description.toLowerCase() === "entrega" ? (
                                                        <PriceInfo
                                                            key={index}
                                                            free={line.amount === 0}
                                                            padding="10px 20px"
                                                        >
                                                            <span>Entrega </span>
                                                            <span>
                                                                {line.amount === 0
                                                                    ? "Grátis"
                                                                    : `R$ ${line.amount.toLocaleString("pt-br", {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    })}`}
                                                            </span>
                                                        </PriceInfo>
                                                    ) : (
                                                        line.description.toLowerCase() === "sub total" && (
                                                            <PriceInfo padding="10px 20px">
                                                                <span>{line.description}</span>
                                                                <span>
                                                                    R${" "}
                                                                    {line.amount.toLocaleString("pt-br", {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    })}
                                                                </span>
                                                            </PriceInfo>
                                                        )
                                                    )
                                                )}

                                                {cupomDiscount && Math.abs(cupomDiscount.amount) !== 0 && (
                                                    <PriceInfo padding="10px 20px">
                                                        <span>Desconto</span>
                                                        <span>R$ {cupomDiscount.amount}</span>
                                                    </PriceInfo>
                                                )}

                                                <PriceInfo padding="10px 20px">
                                                    <span>total</span>
                                                    <span>
                                                        R${" "}
                                                        {details && values.payment_method === undefined
                                                            ? details.total.toLocaleString("pt-br", {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            })
                                                            : currentInstallment
                                                                ? currentInstallment.calculated_amount.toLocaleString(
                                                                    "pt-br",
                                                                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                                )
                                                                : (
                                                                    products.reduce((acc, curr) => {
                                                                        return acc + parseFloat(curr.price_label || 0);
                                                                    }, 0) + deliveryPrice
                                                                ).toLocaleString("pt-br", {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                })}
                                                    </span>
                                                </PriceInfo>
                                            </Flex>
                                        </FooterModalMobile>
                                    </ContainerModalMobile>
                                </>
                            )}
                        </Flex>
                    </Flex>
                </Flex>
            )}
            {/*## MOBILE FIM ## */}

            {/* ## DESKTOP INÍCIO ##*/}
            {!isMobile && (
                <>
                    <Container>
                        <Flex
                            flex="1"
                            width={isMobile ? "100%" : "1222px"}
                            alignItems="flex-start"
                            direction="column"
                            margin={isMobile ? "50px 0 0 0" : "30px auto 54px auto"}
                            padding={isMobile ? "30px 0 0 0" : null}
                        >
                            <Flex
                                width="100%"
                                height="100%"
                                direction={isMobile ? "column" : "row"}
                                alignItems="flex-start"
                                justifyContent="flex-start"
                            >
                                <Flex
                                    width={isMobile ? "100%" : "732px"}
                                    flex="1"
                                    height="100%"
                                    alignItems="flex-start"
                                    justifyContent="flex-start"
                                    direction="column"
                                    margin="0px 35px 20px 0"
                                    padding={isMobile ? "0px 20px" : null}
                                    boxSizing="border-box"
                                >
                                    {(!currentStep || currentStep > 1) && !isBoxCheckout && (
                                        <>
                                            <SectionDataTitle margin="39px auto 34px 0px">
                                                1. Dados pessoais
                                            </SectionDataTitle>

                                            <CardBox
                                                alignItems="flex-start"
                                                justifyContent="flex-start"
                                                direction="column"
                                                width="fill-available"
                                                margin="0px 0px 20px 0px"
                                            >
                                                <Flex
                                                    justifyContent="flex-between"
                                                    width="100%"
                                                    margin="0px 0px 20px 0px"
                                                >
                                                    <CardBoxTitle>
                                                        {`${values.first_name} ${values.last_name}`}
                                                    </CardBoxTitle>
                                                    {!user?.token && (
                                                        <EditCard onClick={() => setCurrentStep(1)}></EditCard>
                                                    )}
                                                    <EditCardDelivery onClick={() => navigate('/minha-conta/cadastro')}>
                                                        Editar
                                                    </EditCardDelivery>
                                                </Flex>
                                                <TextSection margin="0">
                                                    {(values.cpf || "-")
                                                        .toString()
                                                        .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}{" "}
                                                    <br />
                                                    {values.email}
                                                    <br />
                                                    {values.phone_number ? (
                                                        <>
                                                            ({user.phone_code_area}){" "}
                                                            {user.phone_number.toString().replace(/(\d{4,5})(\d{4})/, "$1-$2")}
                                                        </>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </TextSection>
                                            </CardBox>
                                        </>
                                    )}

                                    {(!currentStep || currentStep > 2) && !isBoxCheckout && (
                                        <>
                                            <SectionDataTitle>2. Dados de entrega</SectionDataTitle>
                                            <CardBox
                                                alignItems="flex-start"
                                                justifyContent="flex-start"
                                                direction="column"
                                                width="fill-available"
                                                margin="0px 0px 20px 0px"
                                            >
                                                <Flex justifyContent="flex-between" width="100%">
                                                    <TextSection margin="0">
                                                        {values.street}, {values.house_number}{" "}
                                                        {values.additional_info && " - " + values.additional_info}
                                                        <br />
                                                        {values.neighborhood && values.neighborhood + " - "}{" "}
                                                        {values.city} - {values.state}
                                                        <br />
                                                        CEP: {values.zip_code}
                                                        <br />
                                                    </TextSection>

                                                    <EditCardDelivery onClick={() => setCurrentStep(2)}>
                                                        Editar
                                                    </EditCardDelivery>
                                                </Flex>

                                                <PriceInfo padding="10px 1px" margin="0px" alignItems="flex-center">
                                                    <Flex alignItems="flex-center" justifyContent="flex-between">
                                                        <Flex flex="0.5" margin="0px 20px 0px 0px">
                                                            {delivery.current && (
                                                                <DeliveryServiceLogoMin
                                                                    src={delivery.current.image_url}
                                                                />
                                                            )}
                                                        </Flex>
                                                        <InfoDelivery direction="row">
                                                            <InfoDeliveryName>
                                                                {isBoxCheckout
                                                                    ? "Box Realizada"
                                                                    : (delivery &&
                                                                        delivery.current &&
                                                                        delivery.current.description) ||
                                                                    "-"}
                                                            </InfoDeliveryName>
                                                            -
                                                            {delivery.current && (
                                                                <span>
                                                                    {`Até ${delivery.current.days} dia${delivery.current.days > 1 ? "s úteis" : " útil"
                                                                        }`}
                                                                </span>
                                                            )}
                                                        </InfoDelivery>
                                                    </Flex>
                                                </PriceInfo>
                                            </CardBox>
                                        </>
                                    )}

                                    {(!currentStep || currentStep > 3) && currentStep !== 4 && (
                                        <>
                                            <SectionDataTitle>3. dados para saque de saldo</SectionDataTitle>

                                            {!currentStep &&
                                                (!user.user_bank_accounts || user.user_bank_accounts.length === 0) && (
                                                    <CardBox
                                                        alignItems="flex-start"
                                                        justifyContent="flex-start"
                                                        direction="column"
                                                        width="fill-available"
                                                        margin="0px 0px 20px 0px"
                                                    >
                                                        <EditCard onClick={() => setCurrentStep(3)}>Editar</EditCard>
                                                        <CardBoxTitle>Nenhuma conta bancária cadastrada</CardBoxTitle>
                                                    </CardBox>
                                                )}

                                            {user.user_bank_accounts &&
                                                user.user_bank_accounts.map((bank_account, index) => (
                                                    <CardBox
                                                        key={bank_account.id}
                                                        alignItems="flex-start"
                                                        justifyContent="flex-start"
                                                        direction="column"
                                                        width="fill-available"
                                                        margin="0px 0px 40px 0px"
                                                    >
                                                        <EditCard onClick={() => setCurrentStep(3)}>Editar</EditCard>

                                                        <CardBoxTitle>
                                                            {bank_account.bank.code} - Banco {bank_account.bank.name}
                                                        </CardBoxTitle>

                                                        <TextSection margin="0">
                                                            AG: {bank_account.agency}
                                                            <br />
                                                            CC: {bank_account.account}
                                                            <br />
                                                            {user.first_name} {user.last_name}
                                                            <br />
                                                            CPF:{" "}
                                                            {user.cpf
                                                                .toString()
                                                                .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}
                                                            <br />
                                                        </TextSection>
                                                    </CardBox>
                                                ))}
                                        </>
                                    )}

                                    {currentStep === 1 && (
                                        <>
                                            <SectionDataTitle margin="0 auto 40px 0px">
                                                1. Dados pessoais {!user.token && <SigninLinkNew />}
                                            </SectionDataTitle>

                                            <ProfileForm
                                                values={values}
                                                handleChange={handleChange}
                                                errors={errors}
                                                user={user}
                                                handleZipCode={handleZipCode}
                                                noAddress={true}
                                            />
                                        </>
                                    )}

                                    {currentStep === 2 && !isBoxCheckout && (
                                        <>
                                            <SectionDataTitle margin="20px auto 40px 0px">
                                                2. Dados de entrega
                                            </SectionDataTitle>

                                            <ProfileForm
                                                values={values}
                                                handleChange={handleChange}
                                                errors={errors}
                                                user={user}
                                                handleZipCode={handleZipCode}
                                                noPerson={true}
                                            />

                                            {user.token && (
                                                <CardBox
                                                    alignItems="flex-start"
                                                    justifyContent="flex-start"
                                                    direction="column"
                                                    width="fill-available"
                                                    margin="0px 0px 20px 0px"
                                                    padding="0"
                                                >
                                                    <DeliveryItens>
                                                        {(!delivery || !delivery.services) && (
                                                            <Flex
                                                                padding="17px 0"
                                                                margin="auto 0 auto 0"
                                                                justifyContent="flex-start"
                                                                alignItems="flex-start"
                                                                direction="column"
                                                            >
                                                                <CardBoxTitle>
                                                                    {!delivery || !delivery.error
                                                                        ? "Carregando..."
                                                                        : "erro ao calcular entrega"}
                                                                </CardBoxTitle>
                                                                {delivery && <p>{delivery.message}</p>}
                                                            </Flex>
                                                        )}

                                                        {delivery &&
                                                            !delivery.error &&
                                                            delivery.services.map((item, index) => (
                                                                <div key={index}>
                                                                    <CheckBox
                                                                        padding="17px 0"
                                                                        margin="auto 0 auto 0"
                                                                        name="delivery_method"
                                                                        value={item.service}
                                                                        radio
                                                                        small
                                                                        onChange={handleChange}
                                                                        defaultChecked={
                                                                            delivery.current &&
                                                                            delivery.current.service === item.service
                                                                        }
                                                                    >
                                                                        {item.image_url && (
                                                                            <DeliveryServiceLogo src={item.image_url} />
                                                                        )}
                                                                        <CardBoxTitle>
                                                                            {item.description}
                                                                            {item?.amount > 0 && !item?.free_shipping
                                                                                ? ` - R$ ${(
                                                                                    item?.amount || 0
                                                                                ).toLocaleString("pt-br", {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2,
                                                                                })}`
                                                                                : ` - GRÁTIS`}
                                                                            {item.obs && (
                                                                                <>
                                                                                    <br />
                                                                                    <span>{item.obs}</span>
                                                                                </>
                                                                            )}
                                                                            {item.service !== "store_pickup" && (
                                                                                <span>
                                                                                    {`Até ${item.days} dia${item.days > 1
                                                                                        ? "s úteis"
                                                                                        : " útil"
                                                                                        }`}
                                                                                </span>
                                                                            )}
                                                                            <br />
                                                                        </CardBoxTitle>
                                                                    </CheckBox>

                                                                    {delivery.current &&
                                                                        delivery.current.service === item.service &&
                                                                        delivery.current.warning && (
                                                                            <PartnerContentInfo>
                                                                                {delivery.current.warning}
                                                                            </PartnerContentInfo>
                                                                        )}
                                                                    <WhiteSeparator />
                                                                </div>
                                                            ))}
                                                    </DeliveryItens>
                                                </CardBox>
                                            )}

                                            <Flex
                                                direction={isMobile ? "column" : "row"}
                                                width={isMobile ? "100%" : "fill-available"}
                                                margin={isMobile ? "5px auto 5px 0" : "40px auto 45px 0"}
                                            >
                                                <ContainerHideSmall>
                                                    {/* <Button
                                                disabled={
                                                    canChangeStep() || !details
                                                }
                                                width={
                                                    isMobile ? "320px" : "260px"
                                                }
                                                dark
                                                filled
                                                value="Selecionar pagamento"
                                                margin={
                                                    isMobile
                                                        ? "0 auto 0 auto"
                                                        : "0 0 0 auto"
                                                }
                                                onClick={handleNextStep}
                                            /> */}
                                                </ContainerHideSmall>
                                            </Flex>
                                        </>
                                    )}

                                    {currentStep === 3 && (
                                        <>
                                            <SectionDataTitle margin="20px auto 40px 0px">
                                                {isBoxCheckout ? "1" : "3"}. Formas de pagamento
                                            </SectionDataTitle>
                                            {details?.balance?.total_balance > 0 && (
                                                <>
                                                    <CardBox
                                                        alignItems="flex-start"
                                                        justifyContent="flex-start"
                                                        direction="column"
                                                        width="fill-available"
                                                        margin="0px 0px 20px 0px"
                                                        padding="0"
                                                    >
                                                        <Flex
                                                            padding="9px 20px 9px 16px"
                                                            width="100%"
                                                            margin="12px 0px"
                                                            boxSizing="border-box"
                                                        >
                                                            <CheckBox
                                                                margin="auto auto auto 0"
                                                                name="useCredit"
                                                                value={values.useCredit}
                                                                defaultChecked={values.useCredit}
                                                                small
                                                                onChange={handleChange}
                                                            >
                                                                <CardBoxTitle margin="0">
                                                                    USAR CRÉDITOS - R${" "}
                                                                    {parseFloat(
                                                                        details?.balance?.total_balance || 0
                                                                    ).toLocaleString("pt-br", {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    })}{" "}
                                                                    - DESCONTO DE 10%
                                                                </CardBoxTitle>
                                                                <img
                                                                    width="auto"
                                                                    height="38px"
                                                                    src={RepetitLogo}
                                                                    alt="Re Petit"
                                                                />
                                                            </CheckBox>
                                                            <TextSection margin={"10px 8px 0px 0px"}>
                                                                *Desconto válido somente para crédito não gerado por
                                                                devolução de produto.
                                                            </TextSection>
                                                        </Flex>
                                                        {details?.balance?.user_balance > 0 &&
                                                            details?.total + details?.credit_used >
                                                            details?.balance?.total_balance && (
                                                                <>
                                                                    <WarningBox>
                                                                        <strong>ATENÇÃO:</strong> O valor do crédito é
                                                                        menor do que o valor total a ser pago. Favor
                                                                        escolher também outra forma de pagamento.
                                                                    </WarningBox>
                                                                </>
                                                            )}
                                                    </CardBox>
                                                </>
                                            )}

                                            {details?.total > 0 && (
                                                <>
                                                    <CardBox
                                                        alignItems="flex-start"
                                                        justifyContent="flex-start"
                                                        direction="column"
                                                        width="fill-available"
                                                        margin="0px 0px 20px 0px"
                                                        padding="0"
                                                    >
                                                        {/* CARTÃO CRÉDITO DESK */}
                                                        {details && details.payment_options.credit_card && (
                                                            <>
                                                                <WhiteSeparator />
                                                                <Flex
                                                                    padding="9px 20px 9px 16px"
                                                                    width="100%"
                                                                    margin="12px 0px"
                                                                    boxSizing="border-box"
                                                                    justifyContent="flex-start"
                                                                >
                                                                    <CheckBox
                                                                        margin="auto 17px auto 0"
                                                                        name="payment_method"
                                                                        value="credit_card"
                                                                        defaultChecked={
                                                                            values.payment_method === "credit_card"
                                                                        }
                                                                        radio
                                                                        small
                                                                        onChange={handleChange}
                                                                    >
                                                                        <CardBoxTitle margin="0">
                                                                            Cartão de crédito
                                                                            {details &&
                                                                                details.payment_options.credit_card &&
                                                                                details.payment_options.credit_card
                                                                                    .label &&
                                                                                ` - ${details?.payment_options.credit_card.label}`}
                                                                        </CardBoxTitle>

                                                                        <Flex
                                                                            mobile
                                                                            minWidth="fill-available"
                                                                            height="12px"
                                                                            flex="1"
                                                                        ></Flex>

                                                                        <CreditCardLogo>
                                                                            <img
                                                                                src={AmericanExpressLogo}
                                                                                alt="American Express"
                                                                            />
                                                                        </CreditCardLogo>
                                                                        <CreditCardLogo>
                                                                            <img
                                                                                src={MasterCardLogo}
                                                                                alt="Master Card"
                                                                            />
                                                                        </CreditCardLogo>
                                                                        <CreditCardLogo>
                                                                            <img src={VisaLogo} alt="Visa" />
                                                                        </CreditCardLogo>
                                                                        <CreditCardLogo
                                                                            margin={isMobile ? "0 auto 0 0" : null}
                                                                        >
                                                                            <img src={EloLogo} alt="Elo" />
                                                                        </CreditCardLogo>
                                                                    </CheckBox>
                                                                </Flex>
                                                                {values.payment_method === "credit_card" && (
                                                                    <Flex
                                                                        flex="1"
                                                                        width="100%"
                                                                        direction={isMobile ? "column" : "row"}
                                                                        padding="23px 20px 14px 16px"
                                                                        boxSizing="border-box"
                                                                        alignItems="flex-start"
                                                                    >
                                                                        <TextInput
                                                                            flex="1"
                                                                            margin={
                                                                                isMobile
                                                                                    ? "0 0 16px 0"
                                                                                    : "0 16px 16px 0"
                                                                            }
                                                                            prepend={
                                                                                <>
                                                                                    <CreditCardLogo
                                                                                        cardField
                                                                                        blank
                                                                                        active={!values.card_type}
                                                                                    >
                                                                                        <img
                                                                                            src={CardIcon}
                                                                                            alt="Credit Card"
                                                                                        />
                                                                                    </CreditCardLogo>
                                                                                    <CreditCardLogo
                                                                                        cardField
                                                                                        active={
                                                                                            values.card_type === "AMEX"
                                                                                        }
                                                                                    >
                                                                                        <img
                                                                                            src={AmericanExpressLogo}
                                                                                            alt="American Express"
                                                                                        />
                                                                                    </CreditCardLogo>
                                                                                    <CreditCardLogo
                                                                                        cardField
                                                                                        active={
                                                                                            values.card_type ===
                                                                                            "MASTERCARD"
                                                                                        }
                                                                                    >
                                                                                        <img
                                                                                            src={MasterCardLogo}
                                                                                            alt="Master Card"
                                                                                        />
                                                                                    </CreditCardLogo>
                                                                                    <CreditCardLogo
                                                                                        cardField
                                                                                        active={
                                                                                            values.card_type === "VISA"
                                                                                        }
                                                                                    >
                                                                                        <img
                                                                                            src={VisaLogo}
                                                                                            alt="Visa"
                                                                                        />
                                                                                    </CreditCardLogo>
                                                                                    <CreditCardLogo
                                                                                        cardField
                                                                                        active={
                                                                                            values.card_type === "ELO"
                                                                                        }
                                                                                    >
                                                                                        <img src={EloLogo} alt="Elo" />
                                                                                    </CreditCardLogo>
                                                                                </>
                                                                            }
                                                                            placeholder="Número do cartão"
                                                                            name="card_number"
                                                                            mask="9999 9999 9999 9999"
                                                                            value={values.card_number}
                                                                            onChange={handleChange}
                                                                        />

                                                                        <TextInput
                                                                            flex="1"
                                                                            margin="auto 0 16px 0"
                                                                            placeholder="Nome no cartão"
                                                                            name="card_name"
                                                                            value={values.card_name}
                                                                            onChange={handleChange}
                                                                        />

                                                                        <Flex flex="0 0 100%">
                                                                            <Flex flex="0 0 100%">
                                                                                <Flex
                                                                                    flex="0 0 auto"
                                                                                    width="200px"
                                                                                    maxWidth="50%"
                                                                                    margin="0 0 16px 0"
                                                                                >
                                                                                    <Text>Validade</Text>
                                                                                    <TextInput
                                                                                        flex="0 0 100px"
                                                                                        margin="auto 0 0 14px"
                                                                                        placeholder="MM/YY"
                                                                                        mask="99/99"
                                                                                        name="expires_at"
                                                                                        value={values.expires_at}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                </Flex>

                                                                                <Flex
                                                                                    flex="0 0 auto"
                                                                                    width="200px"
                                                                                    maxWidth="50%"
                                                                                    margin="0 auto 16px 0"
                                                                                >
                                                                                    <Text>Código</Text>
                                                                                    <TextInput
                                                                                        flex="0 0 125px"
                                                                                        margin="auto 0 0 14px"
                                                                                        prepend={
                                                                                            <img
                                                                                                src={AskIcon}
                                                                                                alt="Ask"
                                                                                            />
                                                                                        }
                                                                                        mask="9999"
                                                                                        placeholder="CCV"
                                                                                        name="ccv"
                                                                                        value={values.ccv}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                                </Flex>
                                                                            </Flex>

                                                                            <Flex flex="0 0 100%">
                                                                                <SelectInput
                                                                                    width={isMobile ? "100%" : "auto"}
                                                                                    margin="0 auto 16px 0"
                                                                                    name="installments"
                                                                                    value={values.installments}
                                                                                    onChange={handleChange}
                                                                                    options={
                                                                                        details &&
                                                                                        details.payment_options.credit_card.installments.reduce(
                                                                                            (acc, curr) => {
                                                                                                return [
                                                                                                    ...acc,
                                                                                                    {
                                                                                                        label: `${curr.installments
                                                                                                            }x R$ ${parseFloat(
                                                                                                                curr.installment_amount
                                                                                                            ).toLocaleString(
                                                                                                                "pt-br",
                                                                                                                {
                                                                                                                    minimumFractionDigits: 2,
                                                                                                                    maximumFractionDigits: 2,
                                                                                                                }
                                                                                                            )} - TOTAL: R$ ${parseFloat(
                                                                                                                curr.calculated_amount
                                                                                                            ).toLocaleString(
                                                                                                                "pt-br",
                                                                                                                {
                                                                                                                    minimumFractionDigits: 2,
                                                                                                                    maximumFractionDigits: 2,
                                                                                                                }
                                                                                                            )}`,
                                                                                                        value: curr.installments,
                                                                                                    },
                                                                                                ];
                                                                                            },
                                                                                            []
                                                                                        )
                                                                                    }
                                                                                    error={errors.another_address_state}
                                                                                />
                                                                                <Flex flex="0 0 100%">
                                                                                    <CheckBox
                                                                                        margin={
                                                                                            values.self_holder !== true
                                                                                                ? "0 auto 20px 0"
                                                                                                : "0 auto 0 0"
                                                                                        }
                                                                                        name="self_holder"
                                                                                        value={values.self_holder}
                                                                                        onChange={handleChange}
                                                                                        defaultChecked={
                                                                                            values.self_holder
                                                                                        }
                                                                                        label={
                                                                                            <CardBoxTitle>
                                                                                                Sou o titular do cartão
                                                                                            </CardBoxTitle>
                                                                                        }
                                                                                    />
                                                                                    {values.self_holder !== true && (
                                                                                        <>
                                                                                            <Flex
                                                                                                flex="1"
                                                                                                minWidth="100%"
                                                                                                margin={
                                                                                                    isMobile
                                                                                                        ? "0 0 20px 0"
                                                                                                        : "0 17.5px 20px 0"
                                                                                                }
                                                                                            >
                                                                                                <TextInput
                                                                                                    flex="1"
                                                                                                    margin="0 17.5px 0 0"
                                                                                                    placeholder="Nome do titular"
                                                                                                    name="holder_name"
                                                                                                    value={
                                                                                                        values.holder_name
                                                                                                    }
                                                                                                    onChange={
                                                                                                        handleChange
                                                                                                    }
                                                                                                    error={
                                                                                                        errors.holder_name
                                                                                                    }
                                                                                                />
                                                                                                <TextInput
                                                                                                    width="150px"
                                                                                                    margin={
                                                                                                        isMobile
                                                                                                            ? "0 0 0 0"
                                                                                                            : "0 0 0 17.5px"
                                                                                                    }
                                                                                                    placeholder="Data de nascimento (DD/MM/AAAA)"
                                                                                                    mask="99/99/9999"
                                                                                                    maskPlaceholder="dd/mm/yyyy"
                                                                                                    name="holder_birth_date"
                                                                                                    value={
                                                                                                        values.holder_birth_date
                                                                                                    }
                                                                                                    onChange={
                                                                                                        handleChange
                                                                                                    }
                                                                                                    error={
                                                                                                        errors.holder_birth_date
                                                                                                    }
                                                                                                />
                                                                                            </Flex>

                                                                                            <Flex
                                                                                                flex="1"
                                                                                                minWidth="100%"
                                                                                                margin="0"
                                                                                            >
                                                                                                <TextInput
                                                                                                    flex="0.5"
                                                                                                    maxWidth={
                                                                                                        isMobile
                                                                                                            ? "100%"
                                                                                                            : "calc(50% - 17.5px)"
                                                                                                    }
                                                                                                    margin="0 17.5px 0 0"
                                                                                                    placeholder="CPF do Titular do cartão"
                                                                                                    name="holder_cpf"
                                                                                                    mask="999.999.999-**"
                                                                                                    value={
                                                                                                        values.holder_cpf
                                                                                                    }
                                                                                                    onChange={
                                                                                                        handleChange
                                                                                                    }
                                                                                                    error={
                                                                                                        errors.holder_cpf
                                                                                                    }
                                                                                                />

                                                                                                <TextInput
                                                                                                    flex="0.5"
                                                                                                    margin={
                                                                                                        isMobile
                                                                                                            ? "0"
                                                                                                            : "0 0 0 17.5px"
                                                                                                    }
                                                                                                    placeholder="Celular do Titular do cartão com DDD (XX) XXXXX-XXXX"
                                                                                                    mask="(99) 9 9999-9999"
                                                                                                    name="holder_phone_number"
                                                                                                    value={
                                                                                                        values.holder_phone_number &&
                                                                                                        (
                                                                                                            values.holder_phone_number ||
                                                                                                            ""
                                                                                                        ).replace(
                                                                                                            /undefined/g,
                                                                                                            ""
                                                                                                        )
                                                                                                    }
                                                                                                    onChange={
                                                                                                        handleChange
                                                                                                    }
                                                                                                    error={
                                                                                                        errors.holder_phone_number
                                                                                                    }
                                                                                                />
                                                                                            </Flex>
                                                                                        </>
                                                                                    )}
                                                                                </Flex>
                                                                            </Flex>
                                                                        </Flex>
                                                                    </Flex>
                                                                )}
                                                            </>
                                                        )}

                                                        {/* BOLETO DESK */}
                                                        {details && details.payment_options.boleto && (
                                                            <>
                                                                <WhiteSeparator />
                                                                <Flex
                                                                    width="100%"
                                                                    justifyContent="flex-start"
                                                                    padding="9px 20px 9px 16px"
                                                                    margin="12px 0px"
                                                                    boxSizing="border-box"
                                                                >
                                                                    <CheckBox
                                                                        margin="auto 17px auto 0"
                                                                        name="payment_method"
                                                                        value="boleto"
                                                                        defaultChecked={
                                                                            values.payment_method === "boleto"
                                                                        }
                                                                        radio
                                                                        small
                                                                        onChange={handleChange}
                                                                    >
                                                                        <CardBoxTitle margin="0">
                                                                            Boleto bancário
                                                                            {details &&
                                                                                details.payment_options.boleto &&
                                                                                details.payment_options.boleto.label &&
                                                                                ` - ${details.payment_options.boleto.label}`}
                                                                        </CardBoxTitle>

                                                                        <CreditCardLogo>
                                                                            <img src={BarcodeLogo} alt="Boleto" />
                                                                        </CreditCardLogo>
                                                                    </CheckBox>

                                                                    {values.payment_method === "boleto" && (
                                                                        <WarningBox>
                                                                            <strong>ATENÇÃO:</strong> Emita o boleto
                                                                            apenas se for pagar! Boletos não pagos
                                                                            prejudicam os vendedores e outros
                                                                            interessados. Trabalhamos com peças únicas e
                                                                            não garantimos a disponibilidade caso você
                                                                            não pague e queira resgatá-las com outra
                                                                            forma de pagamento.
                                                                        </WarningBox>
                                                                    )}
                                                                </Flex>
                                                            </>
                                                        )}

                                                        {/* PIX DESK */}
                                                        {details && details.payment_options.pix && (
                                                            <>
                                                                <WhiteSeparator />
                                                                <Flex
                                                                    width="100%"
                                                                    justifyContent="flex-start"
                                                                    padding="9px 20px 9px 16px"
                                                                    margin="12px 0px"
                                                                    boxSizing="border-box"
                                                                >
                                                                    <CheckBox
                                                                        margin="auto 17px auto 0"
                                                                        name="payment_method"
                                                                        value="pix"
                                                                        defaultChecked={values.payment_method === "pix"}
                                                                        radio
                                                                        small
                                                                        onChange={handleChange}
                                                                    >
                                                                        <CardBoxTitle margin="0">
                                                                            PIX
                                                                            {details &&
                                                                                details.payment_options.pix &&
                                                                                details.payment_options.pix.label &&
                                                                                ` - ${details.payment_options.pix.label}`}
                                                                        </CardBoxTitle>

                                                                        <CreditCardLogo>
                                                                            <img src={PixLogo} alt="Logo PIX" />
                                                                        </CreditCardLogo>
                                                                    </CheckBox>
                                                                </Flex>
                                                            </>
                                                        )}
                                                    </CardBox>
                                                </>
                                            )}

                                            {(values.payment_method !== undefined ||
                                                (details?.total === 0 && details?.credit_used > 0)) && (
                                                    <>
                                                        <CardBoxTitle>Endereço de Cobrança</CardBoxTitle>

                                                        <Flex
                                                            padding="9px 20px 9px 16px"
                                                            margin="0"
                                                            border="solid 1px #c9c9cf"
                                                            justifyContent="flex-start"
                                                            width="fill-available"
                                                        >
                                                            <CheckBox
                                                                name="billing_address"
                                                                value="same_address"
                                                                radio
                                                                small
                                                                defaultChecked={values.billing_address === "same_address"}
                                                                onChange={handleChange}
                                                            >
                                                                <CardBoxTitle>Mesmo endereço da entrega</CardBoxTitle>
                                                            </CheckBox>
                                                            <Chevron
                                                                active={showAddress}
                                                                onClick={() => setShowAddress(!showAddress)}
                                                            />
                                                        </Flex>
                                                        <Flex
                                                            padding={showAddress ? "28px 0px 10px 57px" : "0"}
                                                            margin="0 0 20px 0"
                                                            border={showAddress ? "solid 1px #c9c9cf" : ""}
                                                            direction="column"
                                                            alignItems="flex-start"
                                                            justifyContent="flex-start"
                                                            width="fill-available"
                                                            maxHeight={showAddress ? "600px" : "0px"}
                                                            overflow="hidden"
                                                            transition="max-height, padding 0.25s ease-in-out"
                                                        >
                                                            <CardBoxTitle margin="0 0 4px 0">
                                                                {`${values.first_name} ${values.last_name}`}
                                                            </CardBoxTitle>

                                                            <TextSection margin="0">
                                                                {values.street}, {values.house_number}{" "}
                                                                {values.additional_info && " - " + values.additional_info}
                                                                <br />
                                                                {values.neighborhood && values.neighborhood + " - "}{" "}
                                                                {values.city} - {values.state}
                                                                <br />
                                                                CEP: {values.zip_code}
                                                                <br />
                                                            </TextSection>
                                                        </Flex>

                                                        <Flex
                                                            padding="23px 16px"
                                                            margin="0 0 20px 0"
                                                            border="solid 1px #c9c9cf"
                                                            justifyContent="flex-start"
                                                            width="fill-available"
                                                        >
                                                            <CheckBox
                                                                name="billing_address"
                                                                value="another_address"
                                                                radio
                                                                small
                                                                onChange={handleChange}
                                                                defaultChecked={
                                                                    values.billing_address === "another_address"
                                                                }
                                                            >
                                                                <CardBoxTitle>outro endereço de cobrança</CardBoxTitle>
                                                            </CheckBox>
                                                        </Flex>

                                                        {values.billing_address === "another_address" && (
                                                            <Flex
                                                                width="100%"
                                                                alignItems="flex-start"
                                                                direction={isMobile ? "column" : "row"}
                                                            >
                                                                <Flex
                                                                    flex={isMobile ? "1" : "0.5"}
                                                                    width="100%"
                                                                    direction="column"
                                                                    margin={isMobile ? "0 0 20px 0" : "0 35px 0 0"}
                                                                >
                                                                    <TextInput
                                                                        margin="0 0 20px 0"
                                                                        placeholder="Nome"
                                                                        name="another_address_first_name"
                                                                        value={values.another_address_first_name}
                                                                        onChange={handleChange}
                                                                        error={errors.another_address_first_name}
                                                                    />

                                                                    <TextInput
                                                                        margin="0 0 20px 0"
                                                                        placeholder="CEP"
                                                                        name="another_address_zip_code"
                                                                        mask="99999-999"
                                                                        value={values.another_address_zip_code}
                                                                        onChange={handleChange}
                                                                        onBlur={handleZipCode}
                                                                        error={errors.another_address_zip_code}
                                                                    />

                                                                    <Flex width="100%">
                                                                        <TextInput
                                                                            flex="1"
                                                                            margin="0 20px 20px 0"
                                                                            placeholder="Endereço"
                                                                            name="another_address_street"
                                                                            value={values.another_address_street}
                                                                            onChange={handleChange}
                                                                            error={errors.another_address_street}
                                                                        />
                                                                        <TextInput
                                                                            width="80px"
                                                                            margin="0 0 20px 0"
                                                                            placeholder="Nº"
                                                                            name="another_address_house_number"
                                                                            value={values.another_address_house_number}
                                                                            onChange={handleChange}
                                                                            error={errors.another_address_house_number}
                                                                        />
                                                                    </Flex>

                                                                    <TextInput
                                                                        margin="0 0 20px 0"
                                                                        placeholder="Bairro"
                                                                        name="another_address_neighborhood"
                                                                        value={values.another_address_neighborhood}
                                                                        onChange={handleChange}
                                                                        error={errors.another_address_neighborhood}
                                                                    />
                                                                </Flex>

                                                                <Flex
                                                                    flex={isMobile ? "1" : "0.5"}
                                                                    width="100%"
                                                                    direction="column"
                                                                    margin="0"
                                                                >
                                                                    <TextInput
                                                                        margin="0 0 20px 0"
                                                                        placeholder="Sobrenome"
                                                                        name="another_address_last_name"
                                                                        value={values.another_address_last_name}
                                                                        onChange={handleChange}
                                                                        error={errors.another_address_last_name}
                                                                    />

                                                                    <Flex width="100%">
                                                                        <TextInput
                                                                            flex="1"
                                                                            margin="0 20px 20px 0"
                                                                            placeholder="Cidade"
                                                                            name="another_address_city"
                                                                            value={values.another_address_city}
                                                                            onChange={handleChange}
                                                                            error={errors.another_address_city}
                                                                        />
                                                                        <SelectInput
                                                                            width="80px"
                                                                            margin="0 0 20px 0"
                                                                            placeholder="UF"
                                                                            mask="aa"
                                                                            name="another_address_state"
                                                                            value={values.another_address_state}
                                                                            onChange={handleChange}
                                                                            options={brazilianStates}
                                                                            error={errors.another_address_state}
                                                                        />
                                                                    </Flex>

                                                                    <TextInput
                                                                        margin="0 0 20px 0"
                                                                        placeholder="Complemento"
                                                                        name="another_address_additional"
                                                                        value={values.another_address_additional}
                                                                        onChange={handleChange}
                                                                        error={errors.another_address_additional}
                                                                    />
                                                                </Flex>
                                                            </Flex>
                                                        )}
                                                    </>
                                                )}
                                        </>
                                    )}

                                    {/* FORMA DE PAGAMENTO DESK */}
                                    {currentStep === 4 && (
                                        <>
                                            <SectionDataTitle margin="50px 0px 30px 0px">
                                                3. Forma de pagamento
                                            </SectionDataTitle>
                                            <CardBox
                                                alignItems="flex-start"
                                                justifyContent="flex-start"
                                                direction="column"
                                                width="fill-available"
                                            >
                                                <Flex justifyContent="flex-between" width="100%" margin="20px 0px">
                                                    <Flex justifyContent="flex-between" width="100%">
                                                        <CardBoxTitle alignItems="flex-center">
                                                            {values.payment_method === "boleto" && (
                                                                <span>
                                                                    <img src={BarcodeLogo} alt="Boleto" />
                                                                </span>
                                                            )}
                                                            {values.payment_method === "debit" && (
                                                                <span>
                                                                    <img src={ItauLogo} alt="Itaú" />
                                                                </span>
                                                            )}
                                                            {values.payment_method === "credit_card" && (
                                                                <>
                                                                    {values.card_type === "AMEX" && (
                                                                        <span>
                                                                            <img
                                                                                src={AmericanExpressLogo}
                                                                                alt="American Express"
                                                                            />
                                                                        </span>
                                                                    )}
                                                                    {values.card_type === "MASTERCARD" && (
                                                                        <span>
                                                                            <img
                                                                                src={MasterCardLogo}
                                                                                alt="Master Card"
                                                                            />
                                                                        </span>
                                                                    )}
                                                                    {values.card_type === "VISA" && (
                                                                        <span>
                                                                            <img src={VisaLogo} alt="Visa" />
                                                                        </span>
                                                                    )}
                                                                    {values.card_type === "ELO" && (
                                                                        <span>
                                                                            <img src={EloLogo} alt="Elo" />
                                                                        </span>
                                                                    )}
                                                                </>
                                                            )}
                                                            {values.payment_method === "pix" && (
                                                                <span>
                                                                    <img src={PixLogo} alt="Logo PIX" />
                                                                </span>
                                                            )}
                                                            {"    "}
                                                            {values.useCredit && (
                                                                <span>
                                                                    Créditos Re Petit{details?.total > 0 && " +"}&nbsp;
                                                                </span>
                                                            )}
                                                            <span>
                                                                {values.payment_method &&
                                                                    payment_methods[values.payment_method]}
                                                                {values.payment_method === "boleto" &&
                                                                    details &&
                                                                    details.payment_options.boleto &&
                                                                    details.payment_options.boleto.label &&
                                                                    ` - ${details.payment_options.boleto.label}`}
                                                                {values.payment_method === "pix" &&
                                                                    details &&
                                                                    details.payment_options.pix &&
                                                                    details.payment_options.pix.label &&
                                                                    `PIX - ${details.payment_options.pix.label}`}
                                                            </span>
                                                        </CardBoxTitle>
                                                    </Flex>
                                                    <EditCard onClick={() => setCurrentStep(3)}>Editar</EditCard>
                                                </Flex>
                                                <TextSection margin="0">
                                                    {values.payment_method === "credit_card" && (
                                                        <>
                                                            {values.card_number}
                                                            {values.card_name} <br />
                                                            {values.expires_at} <br />
                                                            {details &&
                                                                details.payment_options.credit_card.installments.map(
                                                                    (element) => {
                                                                        if (
                                                                            parseInt(values.installments) ===
                                                                            element.installments
                                                                        ) {
                                                                            return (
                                                                                <>
                                                                                    {`${element.installments
                                                                                        }x R$ ${parseFloat(
                                                                                            element.installment_amount
                                                                                        ).toLocaleString("pt-br", {
                                                                                            minimumFractionDigits: 2,
                                                                                            maximumFractionDigits: 2,
                                                                                        })} - TOTAL: R$ ${parseFloat(
                                                                                            element.calculated_amount
                                                                                        ).toLocaleString("pt-br", {
                                                                                            minimumFractionDigits: 2,
                                                                                            maximumFractionDigits: 2,
                                                                                        })}`}
                                                                                </>
                                                                            );
                                                                        }
                                                                    }
                                                                )}
                                                            <br />
                                                            <br />
                                                        </>
                                                    )}
                                                    {values?.billing_address === "same_address"
                                                        ? "Endereco de cobrança - mesmo endereço de entrega"
                                                        : ""}
                                                    {values?.billing_address === "another_address" ? (
                                                        <>
                                                            {values?.another_address_first_name}{" "}
                                                            {values?.another_address_last_name}
                                                            <br />
                                                            {values?.another_address_city},{" "}
                                                            {values?.another_address_house_number}
                                                            <br />
                                                            {values?.another_address_additional}
                                                            <br />
                                                            {values?.another_address_neighborhood}
                                                            <br />
                                                            {values?.another_address_street}
                                                            <br />
                                                            {values?.another_address_state}
                                                            <br />
                                                            {values?.another_address_zip_code}
                                                            <br />
                                                        </>
                                                    ) : (
                                                        ""
                                                    )}
                                                </TextSection>
                                            </CardBox>
                                        </>
                                    )}
                                </Flex>

                                <Flex
                                    width={isMobile ? "100%" : "420px"}
                                    maxWidth={isMobile ? "100%" : "420px"}
                                    flex="1"
                                    alignItems="flex-start"
                                    justifyContent="flex-start"
                                    direction="column"
                                    margin={"92px 0 0 0"}
                                    boxSizing="border-box"
                                >
                                    <ProgressCard>
                                        {eligible && parseInt(missingAmount) > 0 && (
                                            <>
                                                <Text>
                                                    Com mais R$ {missingAmount} você ganha <b>Frete Grátis</b>
                                                </Text>
                                                <ProgressContainer>
                                                    <ProgressBarInner style={{ width: `${progress}%` }} />
                                                </ProgressContainer>
                                            </>
                                        )}

                                        {eligible && parseInt(missingAmount) <= 0 && (
                                            <>
                                                <Text>
                                                    Parabéns, você recebeu <b>FRETE GRÁTIS!</b>
                                                    {hasProductWithFalseShipping && (
                                                        <>
                                                            <br />
                                                            Porém há produtos no seu carrinho que não são aplicáveis ao{" "}
                                                            <b>Frete Grátis</b>.
                                                        </>
                                                    )}
                                                </Text>
                                                <ProgressContainer>
                                                    <ProgressBarInner style={{ width: `100%` }} />
                                                </ProgressContainer>
                                            </>
                                        )}
                                    </ProgressCard>
                                    <ResumeCard>
                                        <ResumeTitle>
                                            {currentStep === 4 ? "Dados do Pedido" : "Resumo do Pedido"}
                                        </ResumeTitle>
                                        <>
                                            <Flex width="100%" padding="0px 0px" background="#fff">
                                                <ExpandableContainer
                                                    title={
                                                        <ResumeItemTitle>
                                                            Itens no seu pedido (
                                                            {isOnlyBoxDelivery
                                                                ? 1
                                                                : products.filter((item) => !item.box_type).length}
                                                            )
                                                        </ResumeItemTitle>
                                                    }
                                                    width="calc(100% - 40px)"
                                                    maxHeight="360px"
                                                    padding="0px"
                                                    background="transparent"
                                                    value="itens"
                                                    overFlowY="auto"
                                                    alignTitle="center"
                                                >
                                                    <Flex
                                                        justifyContent="flex-start"
                                                        direction="column"
                                                        warp="nowrap"
                                                        width="100%"
                                                        boxSizing="border-box"
                                                        fontFamily="Graphik"
                                                    >
                                                        {isBoxCheckout && isOnlyBoxDelivery && (
                                                            <HorizontalProductItemCheckout
                                                                item={{
                                                                    images: [BoxImage],
                                                                    title: `Frete Box ${currentCartBox && currentCartBox.box_type.name
                                                                        } cod. ${currentCartBox.id}`,
                                                                }}
                                                                hideRemove
                                                                cart
                                                                hideSpecs
                                                            />
                                                        )}

                                                        {products.map((item, index) => (
                                                            <HorizontalProductItemCheckout
                                                                showId={isBoxCheckout}
                                                                hideSpecs
                                                                key={index}
                                                                item={item}
                                                                handleRemove={handleRemove}
                                                                enabledFreeShipping={enabled}
                                                            />
                                                        ))}
                                                    </Flex>
                                                </ExpandableContainer>
                                            </Flex>
                                            {details ? (
                                                line_items.map((line, index) =>
                                                    line.description.toLowerCase() === "entrega" ? (
                                                        <ShippingRow key={index} free={line.amount === 0}>
                                                            <span>Entrega </span>
                                                            <span>
                                                                {line.amount === 0
                                                                    ? "Grátis"
                                                                    : `R$ ${line.amount.toLocaleString("pt-br", {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    })}`}
                                                            </span>
                                                        </ShippingRow>
                                                    ) : (
                                                        <>
                                                            <ReactTooltip />
                                                            <PriceInfo key={index}>
                                                                {line.info && line.info != "" ? (
                                                                    <span data-tip={line.info}>
                                                                        {line.description}{" "}
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="16"
                                                                            height="16"
                                                                            color="#e4858c"
                                                                            fill="currentColor"
                                                                            class="bi bi-exclamation-circle-fill"
                                                                            viewBox="0 0 16 16"
                                                                        >
                                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                                                        </svg>
                                                                    </span>
                                                                ) : (
                                                                    <span>{line.description}</span>
                                                                )}
                                                                <span>
                                                                    R${" "}
                                                                    {line.amount.toLocaleString("pt-br", {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2,
                                                                    })}
                                                                </span>
                                                            </PriceInfo>
                                                        </>
                                                    )
                                                )
                                            ) : (
                                                <></>
                                            )}

                                            {currentInstallment && currentInstallment.discount_amount > 0 && (
                                                <PriceInfo>
                                                    <span>Desconto</span>
                                                    <span>
                                                        R$ -
                                                        {currentInstallment.discount_amount.toLocaleString("pt-br", {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        })}
                                                    </span>
                                                </PriceInfo>
                                            )}

                                            {currentStep >= 3 && !isBoxCheckout && (
                                                <DiscountCoupon
                                                    value={promoCode}
                                                    infoDescription={info}
                                                    isUsingCredit={values.useCredit}
                                                    onClick={async (e, cb) => {
                                                        setPromoCode(e);
                                                        let { data, status } = await handleDetailsRequest(
                                                            values.delivery_method,
                                                            e
                                                        );
                                                        if (status !== 200) {
                                                            cb(
                                                                undefined,
                                                                undefined,
                                                                undefined,
                                                                "Este cupom não existe, expirou ou está inativo"
                                                            );
                                                        } else {
                                                            const { total, line_items } = data;
                                                            const { amount, description } = line_items[1];
                                                            const formtAmount = Number(
                                                                amount.toString().replace("-", "")
                                                            );
                                                            const percentage =
                                                                (formtAmount * 100) / (total + formtAmount);
                                                            const info = data.line_items[1].info;

                                                            setInfo(info);
                                                            cb(
                                                                description,
                                                                amount || "0",
                                                                percentage,
                                                                undefined,
                                                                info !== "" && info
                                                            );

                                                            if (
                                                                data &&
                                                                data.delivery &&
                                                                data.delivery.delivery_data &&
                                                                data.delivery.selected
                                                            ) {
                                                                setDelivery({
                                                                    ...delivery,
                                                                    current: data.delivery.delivery_data,
                                                                });
                                                            }
                                                        }
                                                    }}
                                                />
                                            )}
                                            {delivery && delivery.current && (
                                                <PriceInfo>
                                                    <span>total</span>
                                                    <span>
                                                        {" "}
                                                        R${" "}
                                                        {details && values.payment_method === undefined
                                                            ? details.total.toLocaleString("pt-br", {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            })
                                                            : currentInstallment
                                                                ? currentInstallment.calculated_amount.toLocaleString(
                                                                    "pt-br",
                                                                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                                )
                                                                : (
                                                                    products.reduce((acc, curr) => {
                                                                        return acc + parseFloat(curr.price_label || 0);
                                                                    }, 0) + delivery.current.amount
                                                                ).toLocaleString("pt-br", {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2,
                                                                })}
                                                    </span>
                                                </PriceInfo>
                                            )}
                                        </>
                                    </ResumeCard>

                                    {currentStep === 1 && (
                                        <Flex
                                            direction="column"
                                            width="100%"
                                            justifyContent="center"
                                            alignItens="flex-between"
                                        >
                                            <Button
                                                disabled={canChangeStep() || (!details && isBoxCheckout) || sendingSignupRequest || isBoxCheckout}
                                                width="260px"
                                                padding="25px 1.25rem"
                                                lower="initial"
                                                pink
                                                filled
                                                borderradius
                                                fontFamily="Graphik"
                                                bigger
                                                fontWeight="600"
                                                value={!sendingSignupRequest ? (isBoxCheckout ? ("Selecionar pagamento") : ("Criar conta")) : (<LoadingSpinner />)}
                                                onClick={handleNextStep}
                                            />
                                            {keepBuying()}
                                        </Flex>
                                    )}

                                    {currentStep === 2 && (
                                        <Flex
                                            direction="column"
                                            width="100%"
                                            justifyContent="center"
                                            alignItens="flex-between"
                                        >
                                            <Button
                                                disabled={canChangeStep() || !details || user.needs_phone_verification}
                                                width="260px"
                                                padding="25px 1.25rem"
                                                lower="initial"
                                                pink
                                                filled
                                                borderradius
                                                fontFamily="Graphik"
                                                bigger
                                                fontWeight="600"
                                                value="Selecionar pagamento"
                                                onClick={handleNextStep}
                                            />
                                            {user.needs_phone_verification && (
                                                <Button
                                                    margin="0px"
                                                    width="260px"
                                                    padding="25px 1.25rem"
                                                    lower="initial"
                                                    dark
                                                    filled
                                                    borderradius
                                                    fontFamily="Graphik"
                                                    bigger
                                                    fontWeight="600"
                                                    value="Valide sua conta"
                                                    onClick={handlePhoneModal}
                                                />
                                            )}
                                            {keepBuying()}
                                        </Flex>
                                    )}

                                    {currentStep === 3 && (
                                        <Flex
                                            direction={isMobile ? "column" : "row"}
                                            width={isMobile ? "100%" : "fill-available"}
                                            margin={isMobile ? "5px auto 5px 0" : "40px auto 45px 0"}
                                        >
                                            <Button
                                                disabled={canChangeStep()}
                                                width={isMobile ? "320px" : "260px"}
                                                lower="initial"
                                                padding="25px 1.25rem"
                                                pink
                                                filled
                                                borderradius
                                                fontFamily="Graphik"
                                                bigger
                                                fontWeight="600"
                                                value="Conferir e finalizar"
                                                onClick={handleNextStep}
                                            />
                                            {keepBuying()}
                                        </Flex>
                                    )}

                                    {currentStep === 4 && (
                                        <Flex
                                            direction={isMobile ? "column" : "row"}
                                            width={isMobile ? "100%" : "fill-available"}
                                            margin={isMobile ? "5px auto 5px 0" : "40px auto 45px 0"}
                                        >
                                            <Button
                                                disabled={canChangeStep() || sendingCheckout}
                                                width={isMobile ? "320px" : "260px"}
                                                padding="25px 1.25rem"
                                                lower="initial"
                                                pink
                                                filled
                                                borderradius
                                                fontFamily="Graphik"
                                                bigger
                                                fontWeight="600"
                                                value={
                                                    isOnlyBoxDelivery ? "Finalizar pagamento" : "Conferir e finalizar"
                                                }
                                                onClick={handleNextStep}
                                                loading={sendingCheckout}
                                            />
                                            {keepBuying()}
                                        </Flex>
                                    )}
                                </Flex>
                            </Flex>
                        </Flex>
                    </Container>
                </>
            )}
            {/* ## DESKTOP FIM ## */}

            <Footer />

            {boxCheckoutDone && (
                <BoxSuccessModal
                    show
                    callback={() => {
                        navigate("/checkout/sucesso");
                        dispatch(CartActions.clear());
                    }}
                />
            )}

            {modal && (
                <AlertModal
                    show={modal.show}
                    title={modal.title}
                    message={modal.message}
                    callback={() => setModal({ ...modal, show: false })}
                />
            )}

            {phoneVerificationModal && (
                <PhoneVerificationModal
                    show={phoneVerificationModal.show}
                    phone={phoneVerificationModal.phone}
                    navigateToHome={false}
                    callback={() => setPhoneVerificationModal({ ...phoneVerificationModal, show: false, navigateToHome: false })}
                />
            )}
        </>
    );
};

const ContainerShowSmall = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0px 0px;
    background-color: #fff;
    display: none;
    height: 0px;
    ${isMobileMediaQuery} {
        display: flex;
        height: 100px;
    }
`;

const ContainerHideSmall = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    ${isMobileMediaQuery} {
        display: none;
        height: 0px;
    }
`;

const Container = styled.div`
    flex: 1;
`;

const Link = styled(RouterLink)`
    font-size: 15px;
    line-height: 1.4;
    display: flex;
    align-items: center;
    color: #fefeff;
`;

const ChevronLeft = styled.span`
    display: inline-block;
    background-color: #fff;
    mask-size: cover;
    mask-position: center;
    mask-image: url(${ChevronLeftIcon});
    object-fit: contain;
    height: 14px;
    width: 8px;
    margin-right: 9px;
`;

const StepsTitle = styled.p`
    margin: auto 50px auto 0;

    font-family: "DMSerifDisplay" !important;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.82;
    letter-spacing: 2.2px;
    text-align: left;
    color: #666679;

    :first-child {
        margin-left: auto;
    }

    :last-child {
        margin-right: auto;
    }

    ${(props) => props.active && `color: #fff;`}
`;

const StepCircle = styled.div`
    margin: auto 10px auto 0px;
    text-transform: uppercase;

    width: 25px;
    height: 25px;
    padding: 0px 0px 2px 2px;
    box-sizing: border-box;
    background-color: #66667a;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: "SackersGothicStd";
    font-size: 15px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 10px;
    letter-spacing: 3px;
    text-align: center;
    color: #fff;
    ${(props) => props.active && `color: #fff;`}
    ${(props) => props.next && `color: #838383;`}

    ${(props) => props.active && `background-color: #7ca8af;`}
`;

const PartnerContentInfo = styled.p`
    margin-bottom: 10px;
    margin-top: -10px;
    padding-right: 20px;
    font-size: 15px;
    color: #e48590;
    text-transform: none;
    font-weight: 500;
`;

const CardBoxTitle = styled.h2`
    flex: 1;
    margin: ${({ margin }) => (margin ? margin : "0px 0px 16px 0px")};
    text-transform: uppercase;
    font-size: 15px;
    font-family: "Graphik";
    font-weight: 600;
    color: #34343b;

    span {
        margin: 0px;
        font-size: 13px;
        font-weight: normal;
        line-height: 1.38;
        color: #676777;
    }

    :only-child,
    :last-child {
        margin: 0px;
    }

    :not(:last-child) {
        margin-right: 20px;
    }
`;

const CardBoxTitleMin = styled.span`
    flex: 1;
    margin: ${({ margin }) => (margin ? margin : "0px 0px 16px 0px")};
    text-transform: uppercase;
    font-size: 12px;
    font-family: "Graphik";
    font-weight: 600;
    color: #34343b;

    span {
        margin: 0px;
        font-size: 13px;
        font-weight: normal;
        line-height: 1.38;
        color: #676777;
    }

    :only-child,
    :last-child {
        margin: 0px;
    }

    :not(:last-child) {
        margin-right: 20px;
    }
`;

const ResumeCard = styled(Flex)`
    flex-direction: column;
    flex: 1;
    width: 100%;
    box-shadow: 0px 1px 3px 0 rgba(52, 52, 59, 0.1);
    background-color: #f7f9f9;
    margin-bottom: 30px;
`;

const ShippingRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${({ padding }) => (padding ? padding : "20px 40px")};
    text-transform: capitalize;
    width: 100%;
    box-sizing: border-box;
    color: #34343b;

    span {
        font-family: "Graphik";
        font-size: 15px;
        font-weight: 600;
        line-height: 1.6;
        color: #34343b;
    }

    span:first-child {
        text-transform: capitalize;
        font-weight: 600;
        text-align: left;
        color: #34343b;
    }

    span:last-child {
        text-align: right;

        ${(props) =>
        props.free &&
        css`
                color: #79c992;
            `}
    }

    :not(:last-of-type) {
        border-bottom: 1px solid #fff;
    }
`;

const CreditCardLogo = styled(Flex)`
    width: 40px;
    height: 30px;
    border-radius: 5px;
    border: solid 1px ${({ background }) => (background ? background : "#f4f4f5")};
    margin: ${({ margin }) => margin && margin};
    background-color: ${({ background }) => (background ? background : "#ffffff")};
    :not(:last-child) {
        margin-right: 15px;
    }

    ${({ cardField, active, blank }) =>
        cardField &&
        !blank &&
        css`
            position: absolute;
            left: -8px;
            width: 40px;
            height: 26px;
            box-sizing: border-box;
            padding: 0px 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: scaleX(0);
            transition: transform 0.3s ease-in-out;

            img {
                max-width: 40%;
                object-fit: contain;
            }

            ${active &&
            css`
                transform: scaleX(1);
            `}
        `}

    ${({ blank, active }) =>
        blank &&
        css`
            width: auto;
            height: auto;
            border: none;
            transform: scaleX(0);
            transition: transform 0.6s ease-in-out;

            img {
                max-width: 100%;
                object-fit: contain;
            }

            ${active &&
            css`
                transform: scaleX(1);
            `}
        `}

        ${isMobileMediaQuery} {
        width: 30px;
        img {
            width: 90%;
            object-fit: contain;
        }
    }
`;

const PriceInfo = styled(Flex)`
    width: 100%;
    background-color: #f7f9f9;
    justify-content: space-between;
    text-transform: capitalize;
    padding: ${({ padding }) => (padding ? padding : "20px 40px")};

    box-sizing: border-box;

    font-family: "Graphik";
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    :not(:last-of-type) {
        border-bottom: 1px solid #fff;
    }
    :last-of-type {
        margin-bottom: 5px;
    }

    ${({ margin }) => margin && `margin: ${margin};`}
`;

const KeepBuying = styled(RouterLink)`
    margin: ${({ margin }) => (margin ? margin : "18px auto auto auto")};
    text-decoration: underline;
    font-family: "Graphik";
    font-size: 15px;
    line-height: 2.86;
    letter-spacing: 0.28px;
    text-align: left;
    color: #9dbec3;
    :hover,
    &.active {
        color: #676777;
    }
`;

const CardBox = styled(Flex)`
    padding: ${(props) => (props.padding ? props.padding : "34px 28px")};
    background-color: #f7f9f9;
    box-shadow: 0px 1px 3px 0 rgba(52, 52, 59, 0.1);
    border-bottom: 2px solid #59cd8e;
    position: relative;
`;

const EditCard = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 0px 5px;
    text-decoration: underline;
    font-size: 15px;
    line-height: 1;
    letter-spacing: 0.28px;
    text-align: left;
    color: #9dbec3;
    :hover,
    &.active {
        color: #676777;
        cursor: pointer;
    }

    ${isMobileMediaQuery} {
        top: initial;
        right: 0px;
        bottom: 55px;
        margin: 0px;
    }

    :hover,
    &.active {
        color: #676777;
    }
`;

const EditCardDelivery = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 0px 5px;
    text-decoration: underline;
    font-size: 15px;
    line-height: 1;
    letter-spacing: 0.28px;
    text-align: left;
    color: #9dbec3;
    :hover,
    &.active {
        color: #676777;
        cursor: pointer;
    }

    ${isMobileMediaQuery} {
        top: 0;
        right: 16px;
        bottom: 16px;
        margin: 0px;
    }

    :hover,
    &.active {
        color: #676777;
    }
`;

const ResumeTitle = styled.div`
    position: relative;
    padding: 26px 0px 23px 0px;
    width: 100%;
    font-family: "DMSerifDisplay";
    font-size: 24px;
    font-weight: 500;
    line-height: 1.45;
    text-align: center;
    color: #34343b;
    border-bottom: 1px solid #fff;
`;

const ResumeItemTitle = styled.div`
    position: relative;
    padding: 26px 15px 23px 0px;
    width: 100%;
    font-family: "Graphik";
    font-size: 16px;
    font-weight: 500;
    line-height: 1.45;
    letter-spacing: 0px;
    text-align: center;
    color: #34343b;
    border-bottom: 1px solid #fff;
`;

const TextSection = styled.p`
    margin: ${({ margin }) => (margin ? margin : "0px 0px 16px 0px")};
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #676777;
    white-space: pre-wrap;

    strong {
        font-weight: 600;
    }
`;

const SectionTitle = styled.h2`
    margin: ${(props) => props.margin || "39px auto 34px 0px"};
    font-family: "DMSerifDisplay";
    font-size: 26px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 2.8px;
    text-align: left;
    color: #34343b;
`;

const SectionDataTitle = styled.h2`
    margin: ${(props) => props.margin || "39px auto 34px 0px"};
    font-family: "DMSerifDisplay";
    font-size: ${(props) => props.fontSize || "24px"};
    font-weight: 550;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    text-align: left;
    color: #34343b;
`;

const Text = styled.p`
    margin-bottom: 4px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;

    :not(:last-child) {
        margin-bottom: 10px;
    }
`;

const Chevron = styled(ChevronIcon)`
    margin: auto 0px auto auto;
    transform: scale(-1);
    ${(props) => props.active && `transform: scale(1);`}
    cursor: pointer;
`;

const WhiteSeparator = styled.hr`
    background-color: #fff;
    width: 100%;
    height: 1px;
    border: 0;
    margin: 0px;
`;

const DeliveryServiceLogo = styled.img`
    width: 70px;
    object-fit: contain;
    height: 28px;
    margin: 0px 40px 0 4px;
`;

const DeliveryServiceLogoMin = styled.img`
    width: 80px;
    object-fit: contain;
    height: 20px;
    margin: 0px 12px 0 0px;
`;

const DeliveryItens = styled.div`
    direction: column;
    padding: 0px 16px 0px 16px;
    width: 100%;
    boxsizing: border-box;
    justifycontent: flex-start !important;
`;

const InfoDelivery = styled(Flex)`
    font-weight: 200 !important;
    font-size: 12px !important;
`;

const InfoDeliveryName = styled.span`
    text-transform: uppercase;
`;

const WarningBox = styled.div`
    margin-top: 16px;
    margin-bottom: 16px;
    padding: 16px;
    color: #795548;
    border: 1px solid #ffc107;
    background-color: #fff3cd;
    border-radius: 4px;
    font-size: 14px;
    line-height: 1.25;

    strong {
        font-weight: 600;
    }
`;

const Badge = styled(Flex)`
    margin: 0 !important;
    position: relative;
    top: -6px;
    right: -6px;
    font-size: 13px;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    background-color: #e4858c;
    color: #313139;
    border-radius: 100%;
`;

const DiscountContainer = styled.div`
    width: 100%;
    margin-top: 10px;
    align-items: center;
    span {
        ${isMobileMediaQuery} {
            font-size: 13px;
        }
    }
    b {
        background: #0b5;
        color: white;
        border-radius: 4px;
        font-size: 14px;
        padding: 6px 24px;
        line-height: 2.38;

        ${isMobileMediaQuery} {
            padding: 10px 10px;
            border-radius: 9px;
            height: 13px;
            font-size: 13px;
            width: 14em;
        }
    }
`;

const ContainerModalMobile = styled(Flex)`
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    top: auto;
    z-index: 100;
    flex-direction: collumn;
    justify-content: end;
`;

const BackdropMobile = styled.div`
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.15);
`;

const DialogMobile = styled.div`
    background: white;
    color: black;
    position: relative;
    margin: auto;
    width: 100%;
    overflow-y: auto;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
`;

const ContentModalMobile = styled.div`
    height: auto;
    max-height: ${(props) => (props.show ? "250px" : "420px")};
    padding: 10px;
    overflow-y: auto;
`;

const ChevronModal = styled(ChevronDownIcon)`
    margin: auto 0px auto auto;
    color: #fff;
    transform: scale(-1);
    ${(props) => props.active && `transform: scale(1);`}
    cursor: pointer;
`;

const ResumeOpen = styled(Flex)`
    direction: row;
    align-items: flex-center;
    justify-content: center;
    background: rgb(124 168 175);
    width: 100%;
    position: fixed;
    z-index: 15;
    bottom: 0;
`;

const SectionTitleMobile = styled.h2`
    margin: ${(props) => props.margin || "39px auto 34px 0px"};
    font-family: "DMSerifDisplay";
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 2.8px;
    text-align: left;
    color: #fff;
    cursor: pointer;
`;

const FooterModalMobile = styled.div`
    max-height: 250px;
    padding-top: 5px;
    width: 100%;
    background-color: #f7f9f9;
    z-index: 500000;
    position: revert;
    bottom: 0;
    overflow-y: auto;

    @media screen and (max-width: ${breakpoints.mobile}px) {
        & > div:first-of-type {
            width: calc(100vw - 40px);
        }
    }

    & > div:first-of-type {
        height: 100%;
        margin: 0 auto;
        max-width: 1222px;
    }
`;

const ProgressCard = styled.div`
    width: 95%;
    padding: 0px 10px 10px 10px;
`;

const ProgressContainer = styled.div`
    width: 100%;
    height: 8px;
    background-color: #f0f0f0;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #e4858c;
    padding: 1px;
    margin-top: 5px;
`;

const ProgressBarInner = styled.div`
    height: 100%;
    background-color: #e4858c;
    transition: width 0.5s ease-in-out;
    border-radius: 5px;
`;

export default Checkout;
