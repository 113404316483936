import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import CustomTable from "../../components/CustomTable";
import ExampleProductItem from "../../components/ExampleProductItem";
import Footer from "../../components/Footer";
import SelectInput from "../../components/Inputs/SelectInput";
import SectionTitle from "../../components/SectionTitle";
import Header from "./../../components/Header";
import { ContentDelimiter, Flex, isMobile, isMobileMediaQuery } from "./../../helpers/styles";
import BackgroundDesapegaMobile from "./../../assets/images/background-desapega-mobile.jpg";
import BackgroundDesapega from "./../../assets/images/background-desapega.jpg";
import BackgroundDesapega2Mobile from "./../../assets/images/background-desapega2-mobile.jpg";
import BackgroundDesapega2 from "./../../assets/images/background-desapega2.jpg";
import CalcIcon from "./../../assets/images/calc.png";
import Example1 from "./../../assets/images/example-1.png";
import Example2 from "./../../assets/images/example-2.png";
import Example3 from "./../../assets/images/example-3.png";
import TextInput from "../../components/Inputs/TextInput";
import api from "../../services/api";
import ExamplesCarrousselMobile from "./ExamplesCarrousselMobile";

const Pagamentos = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [values, setValues] = useState({});
    const [brands, setBrands] = useState(undefined);
    const [categories, setCategories] = useState(undefined);
    const [subcategories, setSubCategories] = useState(undefined);
    const [result, setResult] = useState(undefined);

    useEffect(() => {
        getBrands();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (values.brand) {
            getCategories();
        }

        // eslint-disable-next-line
    }, [values.brand]);

    useEffect(() => {
        if (values.category) {
            getSubcategories();
        }

        // eslint-disable-next-line
    }, [values.category]);

    const handleChange = (e) => {
        let {
            target: { name, value, checked, type },
        } = e;

        if (name !== undefined) {
            if (value !== undefined && type !== "checkbox") {
                setValues({
                    ...values,
                    [name]: value,
                });
            } else if (type === "checkbox") {
                setValues({
                    ...values,
                    [name]: checked,
                });
            }
        }
    };

    const getBrands = async () => {
        let brandsResponse = await api.get(`/calculator/brands`);

        if (brandsResponse.status === 200) {
            setBrands(brandsResponse.data);
        } else {
            setBrands([]);
        }
    };

    const getCategories = async () => {
        let categoriesResponse = await api.get(`/calculator/${values.brand}/categories`);

        if (categoriesResponse.status === 200) {
            setCategories(categoriesResponse.data);
        } else {
            setCategories([]);
        }
    };

    const getSubcategories = async () => {
        let subcategoriesResponse = await api.get(`/calculator/${values.brand}/${values.category}/subcategories`);

        if (subcategoriesResponse.status === 200) {
            setSubCategories(subcategoriesResponse.data);
        } else {
            setSubCategories([]);
        }
    };

    const handleSubmitCalculator = async () => {
        if (!result) {
            let brandsResponse = await api.post("/calculator", {
                brand: values.brand,
                category: values.category,
                subcategory: values.subcategory,
            });

            if (brandsResponse.status === 200) {
                setResult(brandsResponse.data);
            }
        } else {
            setResult(undefined);
        }
    };

    return (
        <>
            <Helmet>
                <title>Venda roupas usadas de bebê ou roupas infantis na Re Petit</title>
                <meta
                    name="description"
                    content="A Re Petit é a melhor opção para você desapegar das peças do seu filho de forma fácil e ainda garantir uma renda extra. É só enviar as peças para a gente e esperar as vendas!"
                />
            </Helmet>

            <Header
                navigationItems={[
                    {
                        label: "Como funciona?",
                        active: location.pathname === "/desapegue",
                        action: () => navigate("/desapegue"),
                    },
                    {
                        label: "O que vender?",
                        active: location.pathname === "/o-que-vender",
                        action: () => navigate("/o-que-vender"),
                    },
                    {
                        label: "Pagamentos",
                        active: location.pathname === "/pagamentos",
                        action: () => navigate("/pagamentos"),
                    },
                    {
                        label: "Quero Vender",
                        action: () => navigate("/quero-vender"),
                        secondary: true,
                        borderradius: true,
                    },
                ]}
            />
            <Container>
                <FullSection centered background={isMobile ? BackgroundDesapegaMobile : BackgroundDesapega}>
                    <Flex maxWidth="440px" direction="column" margin="130px 450px 111px auto" alignItems="flex-end">
                        <h2>{isMobile ? "renda extra!" : "desapegue e ganhe uma renda extra!"}</h2>
                        <hr />
                        <p>
                            {isMobile
                                ? `Que tal uma renda extra? É muito simples: você envia as peças que adora e seus filhos não usam mais e a Re Petit produz, anuncia e vende para você. `
                                : `Que tal recuperar parte do valor investido no guarda-roupa das crianças? É muito simples: você envia as peças que adora e seus filhos não usam mais e a Re Petit produz, anuncia e vende para você.`}
                        </p>
                    </Flex>
                    <Flex maxWidth="440px" direction="column" margin="130px auto 111px 0" alignItems="flex-start">
                        <h2>{isMobile ? "pagamentos" : "formas de pagamento"}</h2>
                        <hr />
                        <p>
                            {isMobile
                                ? `Vendeu faturou! Use seus créditos para comprar com desconto de 10% no site ou resgate seu dinheiro. `
                                : `Vendeu faturou! Use seus créditos para comprar com desconto de 10% no site ou resgate seu dinheiro. `}
                            <Link to="/novidades">Saiba mais</Link>
                        </p>
                    </Flex>
                </FullSection>

                <ContentDelimiter>
                    <SectionTitle>tabela de valores</SectionTitle>

                    <CustomTable
                        columns={[
                            {
                                label: "Valor da venda da peça",
                                name: "valor",
                                width: "50%",
                            },
                            {
                                label: "% Re petit",
                                name: "repetit",
                                width: "25%",
                            },
                            {
                                label: "% vendedora",
                                name: "vendedora",
                                width: "25%",
                            },
                        ]}
                        rows={[
                            {
                                valor: "Até R$ 40,00",
                                repetit: "80%",
                                vendedora: "20%",
                            },
                            {
                                valor: "De R$ 40,01 até R$ 80,00",
                                repetit: "75%",
                                vendedora: "25%",
                            },
                            {
                                valor: "De R$ 80,01 até R$ 100,00",
                                repetit: "70%",
                                vendedora: "30%",
                            },
                            {
                                valor: "De R$ 100,01 até R$ 150,00",
                                repetit: "65%",
                                vendedora: "35%",
                            },
                            {
                                valor: "De R$ 150,01 até R$ 300,00",
                                repetit: "60%",
                                vendedora: "40%",
                            },
                            {
                                valor: "De R$ 300,01 até R$ 500,00",
                                repetit: "55%",
                                vendedora: "45%",
                            },
                            {
                                valor: "De R$ 500,01 até R$ 800,00",
                                repetit: "50%",
                                vendedora: "50%",
                            },
                            {
                                valor: "De R$ 800,01 até R$ 1.000,00",
                                repetit: "45%",
                                vendedora: "55%",
                            },
                            {
                                valor: "Acima de R$ 1.000,01",
                                repetit: "35%",
                                vendedora: "65%",
                            },
                        ]}
                    />

                    <Text>Tabela de valores para roupas, calçados, acessórios, objetos, utensílios e brinquedos.</Text>

                    <SectionTitle>simulador de preço</SectionTitle>

                    <CalculatorSection>
                        <CalculatorIcon>
                            <img src={CalcIcon} alt="Calculadora" />
                            <p>
                                Quer ter uma prévia das suas vendas? Faça a simulação e descubra o quanto pode ganhar.
                            </p>
                        </CalculatorIcon>
                        <Flex margin={isMobile ? "auto 45px 20px 0" : "auto 45px auto 0"} flex="1" position="relative">
                            {!result && (
                                <>
                                    <SelectInput
                                        placeholder="Marca"
                                        width="auto"
                                        flex="1"
                                        margin="auto 35px auto 0"
                                        name="brand"
                                        disabled={!brands}
                                        options={
                                            brands &&
                                            brands.filter((item) => {
                                                item.value = item.id;
                                                item.label = item.name;
                                                return true;
                                            })
                                        }
                                        onChange={handleChange}
                                    />
                                    <SelectInput
                                        placeholder="Tipo de peça"
                                        width="auto"
                                        flex="1"
                                        margin="auto 35px auto 0"
                                        name="category"
                                        disabled={!categories}
                                        options={
                                            categories &&
                                            categories.filter((item) => {
                                                item.value = item.id;
                                                item.label = item.name;
                                                return true;
                                            })
                                        }
                                        onChange={handleChange}
                                    />
                                    <SelectInput
                                        placeholder="Subcategoria"
                                        width="auto"
                                        flex="1"
                                        margin="auto 0px auto 0"
                                        name="subcategory"
                                        disabled={!subcategories || subcategories.length === 0}
                                        options={
                                            subcategories &&
                                            subcategories.filter((item) => {
                                                item.value = item.id;
                                                item.label = item.name;
                                                return true;
                                            })
                                        }
                                        onChange={handleChange}
                                    />
                                </>
                            )}
                            {result && (
                                <>
                                    <TextInput
                                        width="auto"
                                        flex="1"
                                        margin="auto 35px auto 0"
                                        value={`Valor de venda: R$ ${result.price_label.toLocaleString("pt-br", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}`}
                                        readOnly
                                    />
                                    <TextInput
                                        width="auto"
                                        flex="1"
                                        margin="auto 0px auto 0"
                                        value={`Valor recebido: R$ ${result.price_supplier.toLocaleString("pt-br", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })}`}
                                        readOnly
                                    />
                                </>
                            )}
                        </Flex>
                        <CalculateButton
                            disabled={
                                !values.brand ||
                                !values.category ||
                                (!values.subcategory && subcategories ? subcategories.length > 0 : false)
                            }
                            onClick={handleSubmitCalculator}
                        >
                            {!result ? "Simular já" : "Limpar Cálculo"}
                        </CalculateButton>
                    </CalculatorSection>

                    <SubTitle>Exemplos</SubTitle>

                    <Flex margin="0" justifyContent="space-around" desktop>
                        <ExampleProductItem
                            image={Example1}
                            title={"Calça"}
                            description={
                                <>
                                    <strong>Marca:</strong> Carters{"\n"}
                                    <strong>Preço de Venda: </strong>R$ 80,00
                                    {"\n"}
                                    <strong>Seu Pagamento: </strong>30%
                                    {"\n"}
                                    <strong>Pagamento Re Petit: </strong>70%
                                </>
                            }
                        />
                        <ExampleProductItem
                            image={Example2}
                            title={"Vestido"}
                            description={
                                <>
                                    <strong>Marca: </strong>GUCCI{"\n"}
                                    <strong>Preço de Venda: </strong>R$ 550,00
                                    {"\n"}
                                    <strong>Seu Pagamento: </strong>50%
                                    {"\n"}
                                    <strong>Pagamento Re Petit: </strong>50%
                                </>
                            }
                        />
                        <ExampleProductItem
                            image={Example3}
                            title={"Carrinho Bebê"}
                            description={
                                <>
                                    <strong>Marca: </strong>Stokke{"\n"}
                                    <strong>Preço de Venda: </strong>R$ 1.500,00
                                    {"\n"}
                                    <strong>Seu Pagamento: </strong>65%
                                    {"\n"}
                                    <strong>Pagamento Re Petit: </strong>35%
                                </>
                            }
                        />
                    </Flex>

                    <ExamplesCarrousselMobile />

                    <Flex margin="0 0 46px 0">
                        <Text>
                            ***Os valores apresentados nas tabelas de exemplos acima e no Simulador de Preço são
                            estimados e podem sofrer alterações após a avaliação da peça e seu estado de conservação.
                        </Text>
                    </Flex>
                </ContentDelimiter>

                <FullSection background={isMobile ? BackgroundDesapega2Mobile : BackgroundDesapega2}>
                    <Flex width="100%" maxWidth="1222px">
                        <Flex maxWidth="367px" direction="column" margin="70px 50px 64px 0" alignItems="flex-start">
                            <h2>
                                De olho no
                                <br />
                                pagamento
                            </h2>
                            <hr />
                            <p>
                                Ao vender uma peça o valor é liberado no site em 30 dias. Acesse a sua conta para
                                acompanhar o status das suas peças em <a href="/minha-conta/vendas">Minhas Vendas</a> e
                                o valor atual disponível em <a href="/minha-conta">Meu Saldo</a>.
                            </p>
                        </Flex>
                        <Flex maxWidth="367px" direction="column" margin="70px auto 74px 0" alignItems="flex-start">
                            <h2>
                                COMPRE COM SEU CRÉDITO <br />
                                RE PETIT
                            </h2>
                            <hr />
                            <p>
                                O saldo das suas vendas pode ser usado para novas compras. Pagamentos com crédito Re
                                Petit têm 10% de desconto. Toda semana chegam novidades incríveis. {isMobile && <br />}
                                <a href="/minha-conta">Compre já!</a>.
                            </p>
                        </Flex>
                    </Flex>
                </FullSection>

                <Flex
                    width="100%"
                    height={isMobile ? "auto" : "171px"}
                    direction={isMobile ? "column" : "row"}
                    justifyContent={isMobile ? "flex-start" : "center"}
                    alignItems={isMobile ? "flex-start" : "center"}
                >
                    <Flex
                        width={isMobile ? "100%" : "38%"}
                        height={isMobile ? "135px" : "100%"}
                        background="#1174AF"
                        boxSizing="border-box"
                    >
                        <DiscountBannerTitle>aumente suas chances de vender e ganhar</DiscountBannerTitle>
                    </Flex>
                    <Flex
                        width={isMobile ? "100%" : "62%"}
                        height={isMobile ? "292px" : "100%"}
                        padding={isMobile ? "0px 25px" : ""}
                        background="#FECA00"
                        boxSizing="border-box"
                    >
                        <DiscountBannerText>
                            {`Oferecemos um plano de descontos progressivos para estimular as suas vendas. Confira:`}
                            <small>
                                <br />
                                <br />
                                Após 6 meses = possível ativar 10% de desconto
                                <br />
                                Após 10 meses = automaticamente entra 25% de desconto
                            </small>
                        </DiscountBannerText>
                    </Flex>
                </Flex>
            </Container>

            <Footer />
        </>
    );
};

const Container = styled.div``;

const SubTitle = styled.div`
    font-family: "SackersGothicStd";
    font-size: 16px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    text-transform: uppercase;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #34343b;
    margin: 31px auto 29px auto;
    text-align: center;
`;

const CalculatorSection = styled(Flex)`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f9fafa;
    box-shadow: 0px 1px 2px 0px rgba(52, 52, 59, 0.15);

    ${isMobileMediaQuery} {
        flex-direction: column;
        align-items: flex-start;

        & > ${Flex} {
            width: calc(100% - 40px);
            padding: 0px 20px 20px 20px;
            flex-direction: column;

            & > div,
            button {
                flex: 1;
                width: 100%;
                margin: 0px;

                :not(:last-child) {
                    margin-bottom: 20px;
                }
            }
        }
    }
`;

const CalculatorIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #7ca8af;
    max-width: 384px;
    margin-right: 35px;

    ${isMobileMediaQuery} {
        width: 100%;
        max-width: 100%;
        margin: 0px;
        margin-bottom: 20px;
    }

    img {
        background-color: #f6b924;
        padding: 20px 19px;
        width: 74px;
        height: 74px;
    }
    p {
        font-family: "Graphik";
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.27;
        letter-spacing: -0.15px;
        text-align: left;
        color: #ffffff;
        padding: 0px 21px;
    }
`;

const FullSection = styled.div`
    background-image: url(${(props) => props.background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 100vw;
    flex: 1;
    min-height: 430px;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    & > div {
        h2 {
            font-family: "SackersGothicStd";
            font-size: 32px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            text-transform: uppercase;
            line-height: 1.19;
            letter-spacing: -0.5px;
            color: #34343b;
        }
        hr {
            width: 70px;
            margin: 31px 0 37px 0;
            border: none;
            border-top: 5px solid #fff;
        }
        p {
            font-family: "Graphik";
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.56;
            letter-spacing: normal;
            text-align: right;
            color: #ffffff;

            a {
                font-weight: bold;
                color: #fff;
            }
        }
    }

    ${(props) =>
        props.centered
            ? `
        & > div:nth-child(1) {
            h2,
            p {
                margin: 0;
                padding: 0;
                margin-left: auto;
                text-align: right;
            }
        }

        & > div:nth-child(2) {
            h2,
            p {
                margin: 0;
                padding: 0;
                margin-right: auto;
                text-align: left;
            }
        }

        ${isMobileMediaQuery} {             
            flex-direction: column;
            justify-content: space-between;
            min-height: 620px;

            & > div:nth-child(1) {
                margin: 0;
                padding: 0;
                margin: 35px auto 300px auto;
                max-width: calc(100% - 70px);

                h2 {
                    font-size: 20px;
                }
                p {
                    font-size: 15px;
                    white-space: pre-line;
                    word-break: break-word;
                }
                h2, p {
                    margin: 0 auto;
                    text-align: center;
                }
                hr {
                    margin: 20px auto;
                }
            }

            & > div:nth-child(2) {
                margin: 0;
                padding: 0;
                margin: 0 auto 35px auto;
                max-width: calc(100% - 70px);

                h2 {
                    font-size: 20px;
                }
                p {
                    font-size: 15px;
                    white-space: pre-line;
                    word-break: break-word;
                }
                h2, p {
                    margin: 0 auto;
                    text-align: center;
                }
                hr {
                    margin: 20px auto;
                }
            }
            
        }
    `
            : `
        & > div {
            h2,
            p {
                margin: 0;
                padding: 0;
                margin-right: auto;
                text-align: left;
            }
        }        
    `}

    ${isMobileMediaQuery} {
        &:last-of-type {
            min-height: 920px;
            background-position: 50% 100%;
        }

        & > div {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            & > div {
                margin: 0px;
                padding: 0px;
                padding: 0px 22px;
                :first-child {
                    padding-top: 40px;
                    margin-bottom: 48px;
                }
                :last-child {
                    margin-bottom: 316px;
                }
            }

            h2 {
                text-align: center;
                margin-left: auto;
                margin-right: auto;
            }
            hr {
                margin-left: auto;
                margin-right: auto;
            }
            p {
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                padding-bottom: 20px;
            }
        }
    }
`;

const Text = styled.div`
    margin-top: 20px;

    font-family: "Graphik";
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #676777;

    ${isMobileMediaQuery} {
        padding: 0 20px;
    }
`;

const CalculateButton = styled.button`
    border: none;
    background-color: #7ca8af;
    border-radius: 10px 10px 0px 0px;
    position: absolute;
    bottom: 0px;
    right: 45px;
    height: 20px;
    width: 182px;

    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 3;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    cursor: pointer;

    ${(props) =>
        props.disabled &&
        css`
            pointer-events: none;
            opacity: 0.45;
        `}

    ${isMobileMediaQuery} {
        left: 50%;
        right: initial;
        transform: translateX(-50%);
    }
`;

const DiscountBannerTitle = styled.h1`
    text-transform: uppercase;
    margin: 0 91px 0 auto;
    max-width: 296px;

    font-family: "SackersGothicStd";
    font-size: 19.5px;
    font-weight: 900;
    line-height: 1.25;
    text-align: left;
    color: #ffffff;

    ${isMobileMediaQuery} {
        font-size: 14px;
        margin: 40px auto;
        text-align: center;
    }
`;

const DiscountBannerText = styled.p`
    white-space: pre-line;
    margin: 0 auto 0 34px;

    font-family: "Graphik";
    font-size: 18px;
    line-height: 1;
    text-align: left;
    color: #ffffff;

    small {
        font-size: 15px;
        line-height: 1.3;
    }

    ${isMobileMediaQuery} {
        font-size: 15px;
        margin: 36px auto;
    }
`;

export default Pagamentos;
