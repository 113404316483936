import React from "react";
import styled, { css } from "styled-components";

import { ReactComponent as SelectArrowIcon } from "./../../assets/images/select-arrow.svg";

function SelectInput({ append, options, name, id, placeholder, error, ...props }) {
    return (
        <Container {...props} error={error}>
            <select
                {...props}
                name={name}
                id={id}
                defaultValue={placeholder}
                style={{ backgroundColor: props.backgroundColor || "#fff", borderRadius: props.borderRadius }}
            >
                {placeholder && (
                    <option value={placeholder} disabled>
                        {placeholder}
                    </option>
                )}
                {options &&
                    options.map((item, index) => (
                        <option
                            key={`${name}_option_${item.value || item}`}
                            value={item.value || item}
                            disabled={item.disabled}
                        >
                            {item.label || item}
                        </option>
                    ))}
            </select>
            <SelectArrow />
            {append && <Append>{append}</Append>}
        </Container>
    );
}

const SelectArrow = styled(SelectArrowIcon)`
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
`;

const Container = styled.div`
    position: relative;
    display: flex;
    background-color: "#fff";
    border: 1px solid #cacad1;
    height: ${(props) => (props.height ? props.height : "40px")};
    box-sizing: border-box;
    ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius};`};

    width: ${(props) => (props.width ? props.width : "100%")};
    ${({ flex }) => flex && `flex: ${flex};`};
    ${({ margin }) => margin && `margin: ${margin};`};
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`};

    ${({ error, errorMessage }) =>
        (error || errorMessage) &&
        css`
            border-color: tomato !important;
            background: #fff3f0;
        `};

    & > select {
        width: 100%;
        height: 100%;
        border: 0px;
        padding: 0px 40px 0 17px;
        cursor: ${({ disabled }) => (!disabled ? "pointer" : "auto")};
        background-color: "#fff";
        font-family: "Graphik";
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.75px;
        text-align: left;
        color: #676777;
        appearance: none;

        &:focus + ${SelectArrow} {
            transform: scaleY(-1) translateY(50%);
        }
    }

    option:disabled {
        color: rgba(0, 0, 0, 0.4);
    }
`;

const Append = styled.div`
    position: absolute;
    right: 18px;
    top: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default SelectInput;
