import React from "react";
import styled from "styled-components";

import { ReactComponent as CrossIcon } from "./../../assets/images/cross.svg";

const MoreIcon = () => {
    return (
        <Container>
            <CrossIcon />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 23px;
    height: 23px;
    border-radius: 100%;
    border: 1px solid #c9c9cf;
    background-color: #fff;
    svg {
        width: 15px;
        height: 15px;
    }
`;

export default MoreIcon;
