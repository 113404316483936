import React from "react";
import styled from "styled-components";

import {
    Flex,
    isMobile,
    isMobileMediaQuery,
    notMobileMediaQuery,
} from "./../../helpers/styles";

import QuizSelectBox from "../../components/QuizSelectBox";
import { SectionTitle } from "./index";

const InfosContent = ({ values, setValues, boxTypes }) => {
    return (
        <>
            <SectionTitle>2. Escolha um estilo de Box</SectionTitle>

            <Flex width="100%" margin="0px 0px 30px 0px">
                {boxTypes &&
                    boxTypes.map((item) => (
                        <BoxSelection
                            height="300px"
                            flex="0.5"
                            margin={isMobile ? "0 0 20px 0" : "0 36px 20px 0px"}
                            padding="0"
                            value={
                                values.box_type_id !== undefined
                                    ? values.box_type_id === item.id
                                    : undefined
                            }
                            onChange={(e) =>
                                e === true
                                    ? setValues({
                                          ...values,
                                          box_type_id: item.id,
                                      })
                                    : setValues({
                                          ...values,
                                          box_type_id: undefined,
                                      })
                            }
                        >
                            <BoxImage
                                width="180px"
                                height="300px"
                                src={item.image_url}
                                alt=""
                            />
                            <Flex
                                height="100%"
                                direction="column"
                                alignItems="flex-start"
                                margin={
                                    isMobile
                                        ? "27px 20px 34px 20px"
                                        : "53px 19px 30px 28px"
                                }
                            >
                                <BoxTitle>{item.title}</BoxTitle>
                                <BoxSubTitle>{item.headline}</BoxSubTitle>
                                <Separator />
                                <Description>{item.description}</Description>
                            </Flex>
                        </BoxSelection>
                    ))}
            </Flex>
        </>
    );
};

const BoxSelection = styled(QuizSelectBox)`
    ${notMobileMediaQuery} {
        align-self: stretch;
        height: auto;
    }

    ${isMobileMediaQuery} {
        flex-direction: column;
    }
`;

const BoxTitle = styled.h1`
    margin: 0px;
    margin-bottom: 20px;
    text-transform: capitalize;

    font-family: "DMSerifDisplay";
    font-size: 32px;
    line-height: 0.75;
    text-align: left;
    color: #34343b;
`;

const BoxSubTitle = styled.h2`
    margin: 0px;
    margin-bottom: 22px;
    text-transform: uppercase;

    font-family: "SackersGothicStd";
    font-size: 11px;
    font-weight: 900;
    line-height: 1.45;
    letter-spacing: 2.2px;
    text-align: left;
    color: #e4858c;
`;

const Separator = styled.hr`
    margin: 0px 0px 20px 0px !important;
    width: 100% !important;
    height: 1px !important;
    background-color: #cacad1 !important;
    border: 0;
`;

const Description = styled.p`
    margin: 0px;

    font-family: "Graphik";
    font-size: 15px;
    line-height: 1.2;
    text-align: left;
    color: #676777;
`;

const BoxImage = styled.img`
    ${notMobileMediaQuery} {
        height: 100%;
        min-height: 300px;
    }

    ${isMobileMediaQuery} {
        object-position: center 20%;
        flex-direction: column;
        width: 100%;
        height: 150px;
        margin-bottom: 20px;
    }
`;

export default InfosContent;
